// import React, { Component } from "react";
// import { compose, withProps, withState, withHandlers } from "recompose";
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView } from "react-google-maps";
import React from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import './PlacesOfInterestMap.css';

const PlacesOfInterestMap = compose(
	withProps({
		// googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAQLc6w5KmdVjL-RdS5wkZeIQ5IAOslk1w&v=3.24&libraries=geometry,drawing,places",
		googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAQLc6w5KmdVjL-RdS5wkZeIQ5IAOslk1w&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div className="PlacesOfInterestMap" style={{ flex: 1 }} />,
		// containerElement: <div className="sunMapWrapper" style={{ height: Math.min(document.body.offsetWidth, document.body.offsetHeight) / 2 + 40 }} />,
		mapElement: <div style={{ height: `100%`, minHeight: '220px' }} />
	}),
    withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap
		zoom={10}
		center={{
			lat: props.currentSearchReducer.latitude,
			lng: props.currentSearchReducer.longitude
		}}
	>
		<Marker
			key={props.currentSearchReducer.id}
			position={{
				lat: props.currentSearchReducer.latitude,
				lng: props.currentSearchReducer.longitude
			}}
		/>
	</GoogleMap>
));

export default PlacesOfInterestMap;