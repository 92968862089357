import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';

import './Options.css';
import timeZoneConverterOptions from '../../../../../../actions/action_timeZoneConverter/action_timeZoneConverterOptions.js';

class Options extends Component {
    state = {
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false
    }
    updateCheck(value) {
        const { timeZoneConverterOptions } = this.props;
        switch(value){
            case '1':
                return timeZoneConverterOptions('1')
            case '2':
                return timeZoneConverterOptions('2')
            case '3':
                return timeZoneConverterOptions('3')
            case '4':
                return timeZoneConverterOptions('4')
            default:
                return timeZoneConverterOptions('')
        }
    }
    render() {
        const { timeZoneConverterOptionsReducer } = this.props;
        return (
            <div className='options'>
                <MuiThemeProvider>
                    <Checkbox
                        className='optionBlock'
                        label="Include UTC"
                        checked={timeZoneConverterOptionsReducer.checked1}
                        onCheck={this.updateCheck.bind(this, '1')}
                        // style={styles.checkbox}
                    />
                </MuiThemeProvider>
                <MuiThemeProvider>
                    <Checkbox
                        className='optionBlock'
                        label="Show timezones"
                        checked={timeZoneConverterOptionsReducer.checked2}
                        onCheck={this.updateCheck.bind(this, '2')}
                        // style={styles.checkbox}
                    />
                </MuiThemeProvider>
                <MuiThemeProvider>               
                    <Checkbox
                        className='optionBlock'
                        label="Show time difference"
                        checked={timeZoneConverterOptionsReducer.checked3}
                        onCheck={this.updateCheck.bind(this, '3')}
                        // style={styles.checkbox}
                    />
                </MuiThemeProvider>
                <MuiThemeProvider>
                    <Checkbox
                        className='optionBlock'
                        label="Show working hours"
                        checked={timeZoneConverterOptionsReducer.checked4}
                        onCheck={this.updateCheck.bind(this, '4')}
                        // style={{borderColor: '#000'}}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        timeZoneConverterOptionsReducer: state.timeZoneConverterOptionsReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        timeZoneConverterOptions: bindActionCreators(timeZoneConverterOptions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);