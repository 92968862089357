import React from "react";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";
import "./privacy.css";

class Privacy extends React.Component {
	componentDidMount() {
		Api.getPrivacy().then(result => {
			document.querySelector("#privacyContent").innerHTML = result.data;
		});
	}

	render() {
		return (
			<div className="privacy">
				<MetaTags>
					<title>GeoTimeDate – Privacy Policy</title>
				</MetaTags>
				<div id="privacyContent"></div>
			</div>
		);
	}
}

export default Privacy;
