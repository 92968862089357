import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone/index.js';

import planetesInSigns from '../../../../../../actions/action_planetesInSigns';

import Api from '../../../../../../services/api.js';

class PlanetSignsLinks extends Component {
    ephemerisArr(){
        const { currentSearchReducer } = this.props;
        return currentSearchReducer.ephemeris.filter(elem => {
            return elem.name !== 'Sun' && elem.name !== 'Moon' &&
            elem.name !== 'mean Node' && elem.name !== 'true Node';
        });
    }
    async pageNewLoad(planet) {        
        const { planetesInSigns, planetesInSignsReducer } = this.props;        
        const periodList = this.periodCalc(planet);
        const start = periodList[1].split(' - ')[0];
        const end = periodList[1].split(' - ')[1];
        const tz = 'Greenwich';
        let planetesInSignsRequest;
        const startDate = moment().tz(tz).format();
        if(planet === 'Moon'){
            const endDate = moment().tz(tz).add(3, 'months').format();
            planetesInSignsRequest = await Api.userCityList(`planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`);
        }else {
            const endDate = moment().tz(tz).add(this.periodVal(planet), 'years').format();
            planetesInSignsRequest = await Api.userCityList(`planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`)
        }
        planetesInSigns({
            planet: planet,
            start: start,
            month: planetesInSignsReducer.month,
            end: end,
            period: periodList[0],
            periodName: periodList[0],
            periodList: periodList,
            year: moment().tz(tz).format('YYYY'),
            planetesInSignsList: planetesInSignsRequest.data.data,
            current_sign: planetesInSignsRequest.data.current_sign,
            next_sign: planetesInSignsRequest.data.next_sign,
            individualText: planetesInSignsRequest.data.individual_text,
        });
    }
    periodCalc(planet){
        const tz = 'Greenwich';
        const yearListCreation = years =>{
            const yearList = [];
            yearList.push(`Next ${years} years`);
            for(let i = 1917; i < 2089; i += years){
                yearList.push(`${moment().tz(tz).set('year', i).format()} - ${moment().tz(tz).set('year', i + years).format()}`);
            }
            return yearList;
        }
        return yearListCreation(this.periodVal(planet));
    }
    periodVal(planet){
        if(planet === 'Sun') return 1;
        else if(planet === 'Moon') return 10;
        else if(planet === 'Mercury' ||planet === 'Venus' || planet ===  'Mars') return 2;
        else if(planet === 'Jupiter') return 5;
        else if(planet === 'Saturn' || planet === 'Uranus') return 10;
        else if(planet === 'Neptune' ||planet === 'Pluto' || planet ===  'Chiron') return 20;
    }
    clearState = () => {
        const { planetesInSigns } = this.props;
        planetesInSigns({
            month: 'Next Quarter',
            planet: '',
            planetesInSignsList: []
        });
    }
    currentPlanet(elem){
        const { planetesInSignsReducer } = this.props;
        return elem.name === planetesInSignsReducer.planet ? { fontWeight: 'bold', textDecoration: 'underline' } : {};
    }
    render() {
        const { currentSearchReducer } = this.props;
        // console.log(window.location.pathname.split('/')[1].split('-').slice(1).join('-') === 'in-signs')
        const url = currentSearchReducer.url.split('/').slice(1).join('/');
        if(window.location.pathname.split('/')[1].split('-').slice(1).join('-') === 'in-signs'){
            return (
                <div className='PlanetRetrogradesSignsLinks'>
                    <div className='stations-list sun-moon-list'>
                        <Link to={`/Sun-in-signs/${url}`} onClick={this.pageNewLoad.bind(this, 'Sun')} style={this.currentPlanet({name: 'Sun'})}>                                        
                            <img src={`/${currentSearchReducer.ephemeris[0].body_image}`} alt={`${currentSearchReducer.ephemeris[0].name} astrology symbol`}/> {currentSearchReducer.ephemeris[0].name}
                        </Link>
                        <Link to={`/Moon-in-signs/${url}`} onClick={this.pageNewLoad.bind(this, 'Moon')} style={this.currentPlanet({name: 'Moon'})}>
                            <img src={`/${currentSearchReducer.ephemeris[1].body_image}`} alt={`${currentSearchReducer.ephemeris[1].name} astrology symbol`}/> {currentSearchReducer.ephemeris[1].name}
                        </Link>
                    </div>
                    <div className='stations-list'>
                        {this.ephemerisArr().map((elem, index) =>
                            <Link key={index} to={`/${elem.name}-in-signs/${url}`} onClick={this.pageNewLoad.bind(this, elem.name)} style={this.currentPlanet(elem)}>
                                <img src={`/${elem.body_image}`} alt={`${elem.name} astrology symbol`}/> {elem.name}
                            </Link>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className='PlanetRetrogradesSignsLinks'>
                    <div className='stations-list sun-moon-list'>
                        <Link to={`/Sun-in-signs/${url}`} onClick={this.clearState}>                                        
                            <img src={`/${currentSearchReducer.ephemeris[0].body_image}`} alt={`${currentSearchReducer.ephemeris[0].name} astrology symbol`}/> {currentSearchReducer.ephemeris[0].name}
                        </Link>
                        <Link to={`/Moon-in-signs/${url}`} onClick={this.clearState}>
                            <img src={`/${currentSearchReducer.ephemeris[1].body_image}`} alt={`${currentSearchReducer.ephemeris[1].name} astrology symbol`}/> {currentSearchReducer.ephemeris[1].name}
                        </Link>
                    </div>
                    <div className='stations-list'>
                        {this.ephemerisArr().map((elem, index) =>
                            <Link key={index} to={`/${elem.name}-in-signs/${url}`} onClick={this.clearState}>
                                <img src={`/${elem.body_image}`} alt={`${elem.name} astrology symbol`}/> {elem.name}
                            </Link>
                        )}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetesInSignsReducer: state.planetesInSignsReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        planetesInSigns: bindActionCreators(planetesInSigns, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanetSignsLinks);