import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Api from "../../../../services/api";

class Articles extends Component {
    state = {
        articles: []
    };
    async componentDidMount() {
        const data = await Api.userCityList(`news/?limit=5&category=panchang`);
        this.setState({ articles: data.data });
    }
    render() {
        return (            
            <div className="planet-signs">
                <div className='head' style={{background: '#ffb61e'}}>
                    <h5><b>Articles</b></h5>
                </div>                    
                <div className='PlanetRetrogradesSignsLinks'>
                    {this.state.articles.map((elem, index) =>
                        <div className="articles-block" key={index}>
                            <b><Link to={`/articles/panchang/${elem.slug}`}>{elem.title}</Link></b>
                        </div>
                    )}
                    <b><Link to={'/articles#panchang'}>...more</Link></b>
                </div>
            </div>
        );
    }
}

export default Articles;