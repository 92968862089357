import React, { Component } from 'react';
import { connect } from 'react-redux';

import './ComponentTitle.css';

class ComponentTitle extends Component {
    state = {
        fontStyle: 'Arial',
        eventName: '',
        selectDefoult: 'defaultValue',
        previewTitle: 'Preview title',
        style: {fontFamily: 'Arial'},
    };

    formatChange = event => {
        this.setState({
            fontStyle: event.target.value,
            style: {fontFamily: event.target.value},
        })
        this.props.setTitleDesignRedux(this.state.eventName, event.target.value);
    };
    eventNameChange = event => {
        this.props.setTitleDesignRedux(event.target.value, this.state.fontStyle);
        this.setState({
            eventName: event.target.value
        })
    };
    // count = 0;
    // arr = ['', '.', '..', '...', ''];
    // timerId = setInterval(()=> {
    //     this.count+=1;
    //      this.setState({
    //          previewTitle: `Preview title${this.arr[this.count]}`
    //      })
    //     if(this.count === 4) this.count=0
    // },1000);

    render() {
        //console.log('state', this.state)
        return (
            <div className="block-countdown-comp">
                <div className="row-creat-title">
                    <div className='column-creat-title'>
                        <p className="title-countdown-comp">Countdown title:</p>
                        <div className='input'>
                             <input  value={this.eventName} placeholder='Event Name' onChange={this.eventNameChange}></input>
                        </div>
                    </div>
                    <div className='column-style-font'>
                        <p className="title-countdown-comp">Font style:</p>
                        <div className='input'>
                             <select selected={this.state.selectDefoult} onChange={this.formatChange}>
                                  <option value="Arial">Arial</option>
                                  <option value="Serif">Serif</option>
                                  <option value="Cursive">Cursive</option>
                                  <option value="Sawarabi Mincho">Sawarabi Mincho</option>
                                  <option value="Courier New">Courier New</option>Raleway
                                  <option value="Oswald">Oswald</option>
                                  <option value="Raleway">Raleway</option>
                                  <option value="Indie Flower">Indie Flower</option>
                                  <option value="Anton">Anton</option>
                                  <option value="Oxygen">Oxygen</option>
                                  <option value="Lobster">Lobster</option>
                                  <option value="Pacifico">Pacifico</option>
                                  <option value="Hanalei Fill">Hanalei Fill</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row-creat-title">
                    <div className='blokc-preview-title'>
                        <p style={this.state.style}>{this.state.eventName ? this.state.eventName : this.state.previewTitle}</p>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        countdownReducer: state.countdownReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
         setTitleDesignRedux: (nameevent, style) => {
            //console.log('name', name)
            const payload = {
                nameevent,
                style
          };
          dispatch({ type: 'PASS_TITLE_DESIGN', payload });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentTitle);
