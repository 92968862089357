import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "material-ui";
import Chip from "material-ui/Chip";

import { setCurrentGroup } from "../../../../../actions/action_selectedCities";

const chipStyle = {
  maxWidth: 130,
  height: 30,
  marginRight: "14px",
  backgroundColor: "#f5f5f5",
  border: "1px solid #7b7b7b",
  cursor: "pointer"
};
const chipLinkStyle = {
  maxWidth: 130,
  height: 30,
  marginRight: 0,
  backgroundColor: "#f5f5f5",
  border: "1px solid #7b7b7b",
  cursor: "pointer"
};
const chipLabelStyle = {
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "28px"
};

class ChipsCarousel extends Component {
  state = {
    hoveredId: false
    // showCarousel: false
  };

  // componentDidMount() {
  // 	const carouselWrapper = document.querySelector(".group-list");
  // 	const carouselList = carouselWrapper.querySelector(".chips-group");

  // 	if (carouselWrapper.offsetWidth > carouselList.offsetWidth) {
  // 		this.setState({ showCarousel: true });
  // 	}
  // }

  handlerGroupChange = e => {
    const { setCurrentGroup, selectedCitiesReducer } = this.props;
    const { token } = selectedCitiesReducer;
    if (token) {
      setCurrentGroup(e);
    }
  };

  isHovered = id => {
    this.setState({ hoveredId: id });
  };

  notHovered = () => {
    this.setState({ hoveredId: false });
  };

  render() {
    // const { showCarousel } = this.state;
    const { authorizationReducer } = this.props;
    const { groupList } = this.props.selectedCitiesReducer;

    return (
      <div className="chips-group">
        {/* {showCarousel && (
					<Fragment>
						<div className="chips-group_arrow chips-group_arrow__left">
							&lt;
						</div>
						<div className="chips-group_arrow chips-group_arrow__right">
							&gt;
						</div>
					</Fragment>
				)} */}

        {groupList.map(elem => (
          <MuiThemeProvider key={elem.id}>
            <Chip
              data-name={elem.name}
              onClick={() => this.handlerGroupChange(elem.id)}
              onMouseEnter={() => this.isHovered(elem.id)}
              onMouseLeave={this.notHovered}
              style={chipStyle}
              labelStyle={
                elem.id === this.props.selectedCitiesReducer.currentGroup.id
                  ? {
                      ...chipLabelStyle,
                      fontWeight: 700
                    }
                  : {
                      ...chipLabelStyle,
                      fontWeight: 400
                    }
              }
            >
              {elem === "DEFAULT" ? (
                "Group 1"
              ) : elem.name.length > 12 ? (
                this.state.hoveredId && this.state.hoveredId === elem.id ? (
                  <marquee>{elem.name}</marquee>
                ) : (
                  <span>{elem.name}</span>
                )
              ) : (
                <span>{elem.name}</span>
              )}
            </Chip>
          </MuiThemeProvider>
        ))}

        {authorizationReducer.username ? (
          <Link to="/account#groups">
            <MuiThemeProvider>
              <Chip style={chipLinkStyle} labelStyle={chipLabelStyle}>
                +
              </Chip>
            </MuiThemeProvider>
          </Link>
        ) : (
          <Link to="/#">
            <MuiThemeProvider>
              <Chip style={chipLinkStyle} labelStyle={chipLabelStyle}>
                +
              </Chip>
            </MuiThemeProvider>
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCitiesReducer: state.selectedCitiesReducer,
    authorizationReducer: state.authorizationReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentGroup: bindActionCreators(setCurrentGroup, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChipsCarousel);
