import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MetaTags from "react-meta-tags";

import "./SignUp.css";
import SignUpFormValidation from "./SignUpFormValidation";

import Api from "../../../../services/api.js";
import newRegisteredEmail from "../../../../actions/action_newRegisteredEmail";
import SocialButtonList from "../SocialButtonList";
import { Link } from "react-router-dom";

class SignUp extends Component {
	state = {
		loader: false
	};
	componentDidMount() {
		this.setState({ loader: false });
	}
	async signUpSubmit() {
		this.setState({ loader: true });

		const { form } = this.props;
		const firstName = form.SignUpForm.values.firstName;
		const lastName = form.SignUpForm.values.lastName;
		const email = form.SignUpForm.values.email;

		let tmp = "";
		if (form.SignUpForm.values.countryCode !== undefined)
			tmp = form.SignUpForm.values.countryCode;
		const countryCode = tmp;
		tmp = "";
		if (form.SignUpForm.values.phone !== undefined)
			tmp = form.SignUpForm.values.phone;
		const phone = tmp;

		const password1 = form.SignUpForm.values.password1;
		const password2 = form.SignUpForm.values.password2;

		const signUp = await Api.authorization("rest-auth/registration/", {
			first_name: firstName,
			last_name: lastName,
			username: email,
			email: email,
			phone_country_code: countryCode,
			phone: phone,
			password1: password1,
			password2: password2
		}).catch(error => {
			if (error.response.status !== 200) {
				const errors = Object.values(error.response.data);
				this.setState({
					loader: false,
					errorText: errors[0]
				});
			} else {
				this.setState({
					errorText: ""
				});
			}
		});
		if (signUp) {
			// const userInfo = await Api.userInfo('user-profile/', signUp.data.key, {
			//     username: email,
			//     first_name: firstName,
			//     last_name: lastName
			// })
			this.props.newRegisteredEmail(email);
			this.props.history.push("/thank-you-for-registration");
			this.setState({ loader: false });
		}
	}

	/*
    static async getCountryCode() {
          if(localStorage.getItem('CountryInfo')){
             console.log('CountryInfo - ready');
          } else {
           const CountryInfo = await Api.simpleRequest(`get-country-info/`);
           if(CountryInfo){
               // sessionStorage.removeItem('CountryCode');
               // sessionStorage.setItem('CountryCode', JSON.stringify(CountryCode.data));
               localStorage.setItem('CountryInfo', JSON.stringify(CountryInfo.data));
               console.log('update CountryInfo');
           }
          }
    }
*/

	render() {
		//SignUp.getCountryCode();
		return (
			<div className="authorization-wrapper">
				<MetaTags>
					<title>GeoTimeDate – Create your account</title>
				</MetaTags>
				<section className="authorization">
					<h4>Create your account</h4>
					<MuiThemeProvider muiTheme={getMuiTheme()}>
						<div style={{ padding: "15px 0" }}>
							{this.state.errorText ? (
								<span className="authorization-error">
									{this.state.errorText}
								</span>
							) : null}
							<SignUpFormValidation
								onSubmit={this.signUpSubmit.bind(this)}
							/>
						</div>
					</MuiThemeProvider>
					<div className="social-login">
						<div className="authorization-middle-title">
							<div className="middle-line"></div>
							<b>OR SIGN IN USING</b>
							<div className="middle-line"></div>
						</div>
						<SocialButtonList />
					</div>
					<Link to="/signin" className="authorization-signin-link">
						Already have an account? Click here to sign in
					</Link>
					<p className="agreement">
						By signing in, you agree to geotimedate.org`s{" "}
						<Link to="/terms-of-service"> Terms of Service, </Link>
						<Link to="/privacy">Privacy Policy</Link> and{" "}
						<Link to="/privacy">Cookie policy.</Link>
					</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form
	};
};

const mapDispatchToProps = dispatch => {
	return {
		newRegisteredEmail: bindActionCreators(newRegisteredEmail, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
