import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import "./ResetPassConfirm.css";
import ResetPassConfirmFormValidation from "./ResetPassConfirmFormValidation";
import authorization from "../../../../actions/authorization";
import Api from "../../../../services/api.js";

class ResetPassConfirm extends Component {
	state = {
		errorText: ""
	};

	async resetSubmit() {
		// const query = new URLSearchParams(window.location.search);

		const email = Api.getQueryVariable("email");
		const token = Api.getQueryVariable("code");
		const { form } = this.props;
		const new_password1 = form.ResetPassConfirmForm.values.new_password1;

		await Api.Reset_Pass("/set-new-password-post/", {
			email: email,
			code: token,
			password: new_password1
		})
			.then(res => {
				if (res.data.ok) {
					this.props.history.push("/reset-password-confirmation");
				} else {
					this.setState({
						errorText: "Something went wrong, try again"
					});
				}
			})
			.catch(error => {
				if (error.response.status !== 200) {
					const errors = Object.values(error.response.data);
					const text =
						errors[0][0] === "Invalid value"
							? "Link is expired"
							: errors[0];
					this.setState(prevState => {
						return {
							errorText: text
						};
					});
				} else {
					this.setState(prevState => {
						return {
							errorText: ""
						};
					});
					this.props.history.push("/reset-password-confirmation");
				}
			});
	}
	render() {
		return (
			<div className="authorization-wrapper">
				<section className="authorization">
					<h4>Reset password</h4>
					<MuiThemeProvider muiTheme={getMuiTheme()}>
						<div style={{ padding: "15px 0" }}>
							{this.state.errorText ? (
								<span className="authorization-error">
									{this.state.errorText}
								</span>
							) : null}
							<ResetPassConfirmFormValidation
								onSubmit={this.resetSubmit.bind(this)}
							/>
						</div>
					</MuiThemeProvider>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPassConfirm);
