import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	MuiThemeProvider,
	DatePicker,
	IconButton,
	RaisedButton
} from "material-ui";
import moment from "moment-timezone/index.js";

import "./CityCalendarBlock.css";
import sunCalc from "../../../../actions/action_sunRise/action_sunCalc";
import sunRisePosition from "../../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../../actions/action_sunRise/action_sunRiseCoords";
import sunRiseTableList from "../../../../actions/action_sunRise/action_sunRiseTableList";
import sunRiseDate from "../../../../actions/action_sunRise/action_sunRiseDate";
import sunLoaderShow from "../../../../actions/action_sunRise/action_sunLoaderShow";
import calendarDateSun from "../../../../actions/action_sunRise/action_calendarDateSun";

import Api from "../../../../services/api.js";

class CityCalendarBlock extends Component {
	componentDidMount() {
		const { sunCalcReducer, sunRiseDate } = this.props;
		if (sunCalcReducer.id) {
			const year = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("year");
			const month = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("month");
			const day = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("date");
			const hour = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("hour");
			const minute = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("minute");
			const second = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("second");
			const millisecond = moment(sunCalcReducer.time)
				.tz(sunCalcReducer.timezone)
				.get("millisecond");
			const time = new Date(
				year,
				month,
				day,
				hour,
				minute,
				second,
				millisecond
			);
			sunRiseDate(moment(time).format("YYYY-MM-DDTHH:mm:ss"));
		}
	}
	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}
	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}
	async newData(date) {
		const { sunRiseDate } = this.props;
		sunRiseDate(date);
	}
	previousMonth = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { sunRiseDateReducer } = this.props;
			const year = moment(sunRiseDateReducer).get("year");
			const month = moment(sunRiseDateReducer).get("month");
			const day = moment(sunRiseDateReducer).get("date");
			const hour = moment(sunRiseDateReducer).get("hour");
			const minute = moment(sunRiseDateReducer).get("minute");
			const second = moment(sunRiseDateReducer).get("second");
			const millisecond = moment(sunRiseDateReducer).get("millisecond");
			const newDate = new Date(
				year,
				month - 1,
				day,
				hour,
				minute,
				second,
				millisecond
			);
			const momentTime = moment(newDate).format("YYYY-MM-DDTHH:mm:ss");
			this.dateChange(momentTime);
			this.newData(momentTime);
		}, 1000);
	};
	previousDay = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { sunRiseDateReducer } = this.props;
			const year = moment(sunRiseDateReducer).get("year");
			const month = moment(sunRiseDateReducer).get("month");
			const day = moment(sunRiseDateReducer).get("date");
			const hour = moment(sunRiseDateReducer).get("hour");
			const minute = moment(sunRiseDateReducer).get("minute");
			const second = moment(sunRiseDateReducer).get("second");
			const millisecond = moment(sunRiseDateReducer).get("millisecond");
			const newDate = new Date(
				year,
				month,
				day - 1,
				hour,
				minute,
				second,
				millisecond
			);
			const momentTime = moment(newDate).format("YYYY-MM-DDTHH:mm:ss");
			this.dateChange(momentTime);
			this.newData(momentTime);
		}, 1000);
	};
	currentDay = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { sunCalcReducer } = this.props;
			const year = moment()
				.tz(sunCalcReducer.timezone)
				.get("year");
			const month = moment()
				.tz(sunCalcReducer.timezone)
				.get("month"); // 0 to 11
			const day = moment()
				.tz(sunCalcReducer.timezone)
				.get("date");
			const hour = moment()
				.tz(sunCalcReducer.timezone)
				.get("hour");
			const minute = moment()
				.tz(sunCalcReducer.timezone)
				.get("minute");
			const second = moment()
				.tz(sunCalcReducer.timezone)
				.get("second");
			const millisecond = moment()
				.tz(sunCalcReducer.timezone)
				.get("millisecond");
			this.setState(prevState => {
				const newDate = new Date(
					year,
					month,
					day,
					hour,
					minute,
					second,
					millisecond
				);
				const momentTime = moment(newDate).format(
					"YYYY-MM-DDTHH:mm:ss"
				);
				this.dateChange(momentTime);
				this.newData(momentTime);
				return {
					defaultDate: new Date(
						year,
						month,
						day,
						hour,
						minute,
						second,
						millisecond
					)
				};
			});
		}, 1000);
	};
	nextDay = e => {
		const { sunRiseDateReducer } = this.props;
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const year = moment(sunRiseDateReducer).get("year");
			const month = moment(sunRiseDateReducer).get("month");
			const day = moment(sunRiseDateReducer).get("date");
			const hour = moment(sunRiseDateReducer).get("hour");
			const minute = moment(sunRiseDateReducer).get("minute");
			const second = moment(sunRiseDateReducer).get("second");
			const millisecond = moment(sunRiseDateReducer).get("millisecond");
			const newDate = new Date(
				year,
				month,
				day + 1,
				hour,
				minute,
				second,
				millisecond
			);
			const momentTime = moment(newDate).format("YYYY-MM-DDTHH:mm:ss");
			this.dateChange(momentTime);
			this.newData(momentTime);
		}, 1000);
	};
	nextMonth = () => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { sunRiseDateReducer } = this.props;
			const year = moment(sunRiseDateReducer).get("year");
			const month = moment(sunRiseDateReducer).get("month");
			const day = moment(sunRiseDateReducer).get("date");
			const hour = moment(sunRiseDateReducer).get("hour");
			const minute = moment(sunRiseDateReducer).get("minute");
			const second = moment(sunRiseDateReducer).get("second");
			const millisecond = moment(sunRiseDateReducer).get("millisecond");
			const newDate = new Date(
				year,
				month + 1,
				day,
				hour,
				minute,
				second,
				millisecond
			);
			const momentTime = moment(newDate).format("YYYY-MM-DDTHH:mm:ss");
			this.dateChange(momentTime);
			this.newData(momentTime);
		}, 1000);
	};
	async dateChange(date) {
		const {
			sunCalcReducer,
			sunCalc,
			sunRisePosition,
			calendarDateSun,
			sunRiseCoords,
			sunLoaderShow
		} = this.props;
		sunLoaderShow(true);
		const sunCoordinats = await Api.userCityList(
			`geo_names/${sunCalcReducer.id}/?sun_app=true&date=${date}`
		);
		sunCalc(sunCoordinats.data);
		localStorage.setItem(
			"sunCalcCityInfo",
			JSON.stringify(sunCoordinats.data)
		);
		calendarDateSun(sunCoordinats.data.time);
		const hourCurrent = moment(sunCoordinats.data.time)
			.tz(sunCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(sunCoordinats.data.time)
			.tz(sunCoordinats.data.timezone)
			.get("minute");
		for (let key in sunCoordinats.data.sun_data.position_during_day) {
			const hourDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				sunRiseCoords(+key);
				sunRisePosition(
					sunCoordinats.data.sun_data.position_during_day[+key]
				);
			}
		}
		sunLoaderShow(false);
	}
	equinoxSolstice(date) {
		const { sunCalcReducer } = this.props;
		const year = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("year");
		const month = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("month");
		const day = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("date");
		const hour = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("hour");
		const minute = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("minute");
		const second = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("second");
		const millisecond = moment(date)
			.tz(sunCalcReducer.timezone)
			.get("millisecond");
		const newDate = new Date(
			year,
			month,
			day,
			hour,
			minute,
			second,
			millisecond
		);
		const momentTime = moment(newDate).format("YYYY-MM-DDTHH:mm:ss");
		this.dateChange(momentTime);
		this.newData(momentTime);
	}
	monthName(name) {
		const { sunCalcReducer } = this.props;
		switch (name) {
			case "Spring equinox":
				return sunCalcReducer.latitude > 0
					? "Spring equinox"
					: "Autumn equinox";
			case "Summer solstice":
				return sunCalcReducer.latitude > 0
					? "Summer solstice"
					: "Winter solstice";
			case "Autumn equinox":
				return sunCalcReducer.latitude > 0
					? "Autumn equinox"
					: "Spring equinox";
			case "Winter solstice":
				return sunCalcReducer.latitude > 0
					? "Winter solstice"
					: "Summer solstice";
			default:
				return "geotimedate";
		}
	}
	month(date) {
		const { sunCalcReducer } = this.props;
		return moment(date)
			.tz(sunCalcReducer.timezone)
			.format("ll");
	}
	monthTitle(date) {
		const { sunCalcReducer } = this.props;
		return `
            ${moment(date)
				.tz("Greenwich")
				.format("MM-DD-YYYY")} ${moment(date)
			.tz("Greenwich")
			.format("HH:mm")} UTC
${moment(date)
	.tz(sunCalcReducer.timezone)
	.format("MM-DD-YYYY")} ${moment(date)
			.tz(sunCalcReducer.timezone)
			.format("HH:mm")} Local Time ${moment(date)
			.tz(sunCalcReducer.timezone)
			.format("Z")}
        `;
	}
	calendarChange(event, date) {
		const { sunCalcReducer, sunRiseDate } = this.props;
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const timeGet = moment(sunCalcReducer.time).tz(sunCalcReducer.timezone);
		const hour = timeGet.get("hour");
		const minute = timeGet.get("minute");
		const second = timeGet.get("second");
		const millisecond = timeGet.get("millisecond");
		const time = new Date(
			year,
			month,
			day,
			hour,
			minute,
			second,
			millisecond
		);
		const momentTime = moment(time).format("YYYY-MM-DDTHH:mm:ss");
		sunRiseDate(momentTime);
		this.dateChange(momentTime);
	}
	calendarValue() {
		const { sunRiseDateReducer } = this.props;
		const year = moment(sunRiseDateReducer).get("year");
		const month = moment(sunRiseDateReducer).get("month");
		const day = moment(sunRiseDateReducer).get("date");
		const hour = moment(sunRiseDateReducer).get("hour");
		const minute = moment(sunRiseDateReducer).get("minute");
		const second = moment(sunRiseDateReducer).get("second");
		const millisecond = moment(sunRiseDateReducer).get("millisecond");
		const time = new Date(
			year,
			month,
			day,
			hour,
			minute,
			second,
			millisecond
		);
		return time;
	}
	render() {
		const { sunCalcReducer } = this.props;
		return (
			<Fragment>
				<div className="CalendarPickerBlock">
					<div className="left-calendar-block">
						<MuiThemeProvider>
							<div className="calendar-date-picker-wrapper">
								<DatePicker
									className="calendar-date-picker"
									hintText="Select Date"
									value={this.calendarValue()}
									onChange={this.calendarChange.bind(this)}
									autoOk={true}
									formatDate={
										new Intl.DateTimeFormat("en-US", {
											day: "2-digit",
											month: "long",
											year: "numeric"
										}).format
									}
								/>
								<i className="material-icons">today</i>
							</div>
						</MuiThemeProvider>
						<div className="calendar-date-picker-buttons">
							<MuiThemeProvider>
								<IconButton
									onClick={this.previousMonth}
									tooltip="Previous Month"
									tooltipPosition="bottom-right"
								>
									<i className="material-icons">
										fast_rewind
									</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.previousDay}
									tooltip="Previous Day"
									tooltipPosition="bottom-right"
								>
									<i className="material-icons prev_date">
										play_arrow
									</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.currentDay}
									tooltip="Current Day"
									tooltipPosition="bottom-center"
								>
									<i className="material-icons">stop</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.nextDay}
									tooltip="Next Day"
									tooltipPosition="bottom-left"
								>
									<i className="material-icons">play_arrow</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.nextMonth}
									tooltip="Next Month"
									tooltipPosition="bottom-left"
								>
									<i className="material-icons">
										fast_forward
									</i>
								</IconButton>
							</MuiThemeProvider>
						</div>
					</div>
					<div className="right-calendar-block">
						<div
							className="equinox-solstice-name"
							title={this.monthTitle(
								sunCalcReducer.sun_data.spring_equinox
							)}
						>
							<span>{this.monthName("Spring equinox")}</span>
							<MuiThemeProvider>
								<RaisedButton
									onClick={this.equinoxSolstice.bind(
										this,
										sunCalcReducer.sun_data.spring_equinox
									)}
									className="RaisedButton"
									label={this.month(
										sunCalcReducer.sun_data.spring_equinox
									)}
									primary={true}
								/>
							</MuiThemeProvider>
						</div>
						<div
							className="equinox-solstice-name"
							title={this.monthTitle(
								sunCalcReducer.sun_data.summer_solstice
							)}
						>
							<span>{this.monthName("Summer solstice")}</span>
							<MuiThemeProvider>
								<RaisedButton
									onClick={this.equinoxSolstice.bind(
										this,
										sunCalcReducer.sun_data.summer_solstice
									)}
									className="RaisedButton"
									label={this.month(
										sunCalcReducer.sun_data.summer_solstice
									)}
									primary={true}
								/>
							</MuiThemeProvider>
						</div>
						<div
							className="equinox-solstice-name"
							title={this.monthTitle(
								sunCalcReducer.sun_data.autumn_equinox
							)}
						>
							<span>{this.monthName("Autumn equinox")}</span>
							<MuiThemeProvider>
								<RaisedButton
									onClick={this.equinoxSolstice.bind(
										this,
										sunCalcReducer.sun_data.autumn_equinox
									)}
									className="RaisedButton"
									label={this.month(
										sunCalcReducer.sun_data.autumn_equinox
									)}
									primary={true}
								/>
							</MuiThemeProvider>
						</div>
						<div
							className="equinox-solstice-name"
							title={this.monthTitle(
								sunCalcReducer.sun_data.winter_solstice
							)}
						>
							<span>{this.monthName("Winter solstice")}</span>
							<MuiThemeProvider>
								<RaisedButton
									onClick={this.equinoxSolstice.bind(
										this,
										sunCalcReducer.sun_data.winter_solstice
									)}
									className="RaisedButton"
									label={this.month(
										sunCalcReducer.sun_data.winter_solstice
									)}
									primary={true}
								/>
							</MuiThemeProvider>
						</div>
					</div>
				</div>

				{moment(this.props.sunRiseDateReducer).isBefore(
					"2039-01-01"
				) ? null : (
					<p className="geotimedate-table_sun-notice geotimedate-table_sun-notice__under-datepicker">
						Showing local times for{" "}
						{this.emptyName(sunCalcReducer.name)}{" "}
						{this.emptyAbr(
							sunCalcReducer.country_name_abbr,
							sunCalcReducer.country_name
						)}{" "}
						based on standard time due to{" "}
						<span title="Many Unix and other operating systems store Unix time as a signed 32-bit integer. After January 19, 2038, the 32 bit signed UNIX time will overflow, taking the count negative. For more information, please visit - https://en.wikipedia.org/wiki/Year_2038_problem">
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							"Year 2038 Problem"
						</span>
						.
					</p>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer,
		sunRiseDateReducer: state.sunRiseDateReducer,
		sunRisePositionReducer: state.sunRisePositionReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		sunCalc: bindActionCreators(sunCalc, dispatch),
		sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
		sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch),
		sunRiseTableList: bindActionCreators(sunRiseTableList, dispatch),
		sunRiseDate: bindActionCreators(sunRiseDate, dispatch),
		calendarDateSun: bindActionCreators(calendarDateSun, dispatch),
		sunLoaderShow: bindActionCreators(sunLoaderShow, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CityCalendarBlock);
