export default function(values) {
    const errors = {};
    const requiredFields = [
        'email',
        'password'
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
        errors[field] = 'Required';
        }
    });
    if(values.password){
        if(values.password.length < 8){
            errors.password = 'Password must contain at least 8 characters';
        }
    }
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }
    return errors;
}
  