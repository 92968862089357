import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

const validate = values => {
	const errors = {};
	if (!values) errors[values] = "Required";
	return errors;
};

class LastNameBlock extends Component {
	state = {
		disabled: "disabled",
		value: this.props.userInfo.last_name
	};
	renderField = ({
		input,
		label,
		type,
		meta: { touched, error, warning }
	}) => {
		const { userInfo } = this.props;

		return (
			<div className="authorisation-form-input">
				<label>{label}</label>
				<div>
					<input
						{...input}
						placeholder={userInfo.last_name}
						type={type}
						disabled={this.state.disabled}
						value={this.state.value}
						onChange={this.handleChange}
					/>
					{touched &&
						((error && <span>{error}</span>) ||
							(warning && <span>{warning}</span>))}
				</div>
			</div>
		);
	};
	editeAvalible = e => {
		this.setState({ disabled: "" });
	};
	handleChange = e => {
		this.setState({ value: e.target.value });
	};
	render() {
		const { handleSubmit } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<div className="profile-block">
					<Field
						name="lastName"
						type="text"
						label="Last Name"
						component={this.renderField}
						disabled={this.state}
					/>
					<button type="button" onClick={this.editeAvalible}>
						Edit
					</button>
					<button
						type="submit"
						onMouseUp={() => {
							this.setState({ disabled: "disabled" });
						}}
					>
						Save
					</button>
				</div>
			</form>
		);
	}
}

export default reduxForm({ form: "EditPersonalInfo", validate })(LastNameBlock);
