import React from "react";

import bottomAds from "../../../../assets/taboola/long-ad1.png";
import "./ads.css";

function Ads() {
  return (
    <div className="calculators-ads">
      <img src={bottomAds} alt="Ads" />
      <img src={bottomAds} alt="Ads" />
    </div>
  );
}

export default Ads;
