import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone/index.js';

import './CityBlocks.css';
import timeZoneConverter from '../../../../../../actions/action_timeZoneConverter/action_timeZoneConverter.js';
import timeZoneConverterTime from '../../../../../../actions/action_timeZoneConverter/action_timeZoneConverterTime.js';
import timeZoneConverterOptions from '../../../../../../actions/action_timeZoneConverter/action_timeZoneConverterOptions.js';

import Api from '../../../../../../services/api.js';

class CityBlocks extends Component {
    toRaiseTheCity(elem, index, arr){
        if(index > 1){
            const { timeZoneConverter } = this.props;
            const newArr = arr.filter((elemArr, indexArr) => indexArr !== index);
            newArr.splice(index - 1, 0, elem);
            timeZoneConverter(newArr);
            localStorage.setItem('timeZoneConverterList', JSON.stringify(newArr));
        }
    }
    omitTheCity(elem, index, arr){
        if(index !== arr.length - 1 && index !== 0){
            const { timeZoneConverter } = this.props;
            const newArr = arr.filter((elemArr, indexArr) => indexArr !== index);
            newArr.splice(index + 1, 0, elem);
            timeZoneConverter(newArr);
            localStorage.setItem('timeZoneConverterList', JSON.stringify(newArr));
        }
    }
    dellCity(index){
        const { timeZoneConverter, timeZoneConverterReducer } = this.props;
            if(index !== 0){
            const cityStart = timeZoneConverterReducer.slice(0, index);
            const cityEnd = timeZoneConverterReducer.slice(index + 1);
            const cityList = cityStart.concat(cityEnd);
            timeZoneConverter(cityList);
            localStorage.setItem('timeZoneConverterList', JSON.stringify(cityList));
        }
    }
    async setAsDefault(elem, index) {
        const { timeZoneConverter, timeZoneConverterReducer } = this.props;
        if(index !== 0){
            const cityStart = timeZoneConverterReducer.slice(0, index);
            const cityEnd = timeZoneConverterReducer.slice(index + 1);
            const cityList = [elem].concat(cityStart.concat(cityEnd));
            timeZoneConverter(cityList);
            localStorage.setItem('timeZoneConverterList', JSON.stringify(cityList));
            this.resetTimeCityList(cityList);
        }
    }
    emptyName(name){
        return name !== null ? `${name} ` : '';
    }
    emptyAbr(abr, name){
        if(name === 'United States' && abr !== null) return abr;
        else if(name !== null) return name;
        else return '';
    }
    timeDifference(cityZone){
        const { timeZoneConverterReducer } = this.props;
        const hours = +moment.tz(cityZone).format('Z').split(':')[0] - +moment.tz(timeZoneConverterReducer[0].timezone).format('Z').split(':')[0];
        let minutes = moment.tz(cityZone).get('minutes') - moment.tz(timeZoneConverterReducer[0].timezone).get('minutes') + '';
        if(minutes === '0') minutes = '00';
        return (minutes).slice(0, 1) ==='-' ? `${hours}.${(minutes).slice(1)}` : `${hours}.${minutes}`;
    }
    cityTimeCalendar(elem, attr){
        const { timeZoneConverterReducer, timeZoneConverterTimeReducer, format12, format24 } = this.props;
        if(timeZoneConverterReducer.length) {
            const zone = moment().tz(timeZoneConverterReducer[0].timezone).format('Z');
            const noZoneTime = moment(timeZoneConverterTimeReducer.calendarDate).format(`YYYY-MM-DDTHH:mm:ss${zone}`);
            const time = moment(noZoneTime).tz(elem.timezone);
            switch(attr){
                case 'time':
                    if(format12 === true) return time.format('LT');
                    else if(format24 === true) return time.format('HH:mm');
                    else{
                        if(elem.time_format === 'AM_PM') return time.format('LT');
                        else return time.format('HH:mm');
                    }
                case 'date':
                    return time.format('ddd ll');
                case 'zoneAbr':
                    return time.format('z');
                case 'zone':
                    return time.format('Z');
                default:
                    return time.format();
            }
        }
    }    
    resetTimeCityList(cityList){
        const { timeZoneConverterTime } = this.props;
        if(cityList.length){
            const city = cityList[0];
            const zeroTime = +moment().tz(city.timezone).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format('x');
            const cityTime = +moment().tz(city.timezone).format('x');
            const timeMoment = moment().tz(cityList[0].timezone);
            const year = timeMoment.get('year');
            const month = timeMoment.get('month');  // 0 to 11
            const day = timeMoment.get('date');
            const hour = timeMoment.get('hour');
            const minute = timeMoment.get('minute');
            const second = timeMoment.get('second');
            const millisecond = timeMoment.get('millisecond');
            const time = new Date(year, month, day, hour, minute, second, millisecond);
            timeZoneConverterTime({
                calendarDate: time,
                sliderDot: Math.round((cityTime - zeroTime) / 60000)
            })
        }
    }
    utcTimeShow(){
        const { timeZoneConverterOptionsReducer } = this.props;
        if(timeZoneConverterOptionsReducer.checked1 === true) return { display: 'flex' };
    }
    timezoneShow(){
        const { timeZoneConverterOptionsReducer } = this.props;
        if(timeZoneConverterOptionsReducer.checked2 === true) return { opacity: 1 };
    }
    differenceShow(){
        const { timeZoneConverterOptionsReducer } = this.props;
        if(timeZoneConverterOptionsReducer.checked3 === true) return { opacity: 1 };
    }
    render() {
        const { timeZoneConverterReducer } = this.props;
        return (
            <div className='CityBlocksList'>
                <div className='CityBlocks'>
                    {timeZoneConverterReducer.map((elem, index, arr) => (
                        <div className="cityBlock city-list" key={index}>
                            <div className="name-block">
                                <div className="add-and-dell-city reorder-cities">
                                    <i className="material-icons raise" 
                                        onClick={this.toRaiseTheCity.bind(this, elem, index, arr)}
                                    >play_arrow</i>
                                    <i className="material-icons omit" 
                                        onClick={this.omitTheCity.bind(this, elem, index, arr)}
                                    >play_arrow</i>
                                </div>
                                <div className="add-and-dell-city">
                                    <i className="dell_city material-icons"
                                        onClick={this.dellCity.bind(this, index)}
                                    >clear</i>
                                    <i className="as_default_city material-icons"
                                        onClick={this.setAsDefault.bind(this, elem, index)}
                                    >home</i>
                                </div>
                                <div className="flag">
                                    <img src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`} alt="citydateandtime"/>
                                </div>
                                <div className="city">
                                    <p>{this.emptyName(elem.name)}</p>
                                    <span>
                                        {this.emptyAbr(elem.country_name_abbr, elem.country_name)}
                                    </span>
                                </div>
                            </div>
                            <div className="time-block">
                                <div className="time-zone">
                                    <p style={this.timezoneShow()}>{this.cityTimeCalendar(elem, 'zoneAbr')}</p>
                                    <span style={this.timezoneShow()}>{this.cityTimeCalendar(elem, 'zone')}</span>
                                    <em className="time-difference" style={this.differenceShow()}>
                                        {this.timeDifference(elem.timezone).replace('.', ':')}
                                    </em>
                                                    
                                    {/* <p>{moment.tz(elem.timezone).format('z')}</p>
                                    <span>{moment.tz(elem.timezone).format('Z')}</span>
                                    <em className="time-difference">{this.timeDifference(elem.timezone).replace('.', ':')}</em> */}
                                </div>   
                                <div className="time">
                                    <p>{this.cityTimeCalendar(elem, 'time')}</p>
                                    <span>{this.cityTimeCalendar(elem, 'date')}</span>
                                    {/* <span>{moment.tz(elem.timezone).format('ddd ll')}</span>
                                    <p>{moment.tz(elem.timezone).format('LT')}</p> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>            
                <div className="cityBlock utc-time" style={this.utcTimeShow()}>
                    <div className="name-block">
                        <div className="add-and-dell-city reorder-cities">
                            <i className="material-icons raise">play_arrow</i>
                            <i className="material-icons omit">play_arrow</i>
                        </div>
                        <div className="add-and-dell-city">
                            <i className="dell_city material-icons">clear</i>
                            <i className="as_default_city material-icons">home</i>
                        </div>
                        <div className="flag">
                            <i className="material-icons">language</i>
                        </div>
                        <div className="city">
                            <p>UTC</p>
                        </div>
                    </div>
                    <div className="time-block">
                        <div className="time-zone">
                            {/* <p>{this.cityTimeCalendar(elem, 'zoneAbr')}</p>
                            <span>{this.cityTimeCalendar(elem, 'zone')}</span>
                            <em className="time-difference">{this.timeDifference(elem.timezone).replace('.', ':')}</em> */}
                        </div>   
                        <div className="time">
                            <p>{this.cityTimeCalendar({timezone: 'Greenwich'}, 'time')}</p>
                            <span>{this.cityTimeCalendar({timezone: 'Greenwich'}, 'date')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        timeZoneConverterReducer: state.timeZoneConverterReducer,
        timeZoneConverterTimeReducer: state.timeZoneConverterTimeReducer,
        timeZoneConverterOptionsReducer: state.timeZoneConverterOptionsReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        timeZoneConverter: bindActionCreators(timeZoneConverter, dispatch),
        timeZoneConverterTime: bindActionCreators(timeZoneConverterTime, dispatch),
        timeZoneConverterOptions: bindActionCreators(timeZoneConverterOptions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CityBlocks);