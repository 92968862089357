import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";

import "./CityName.css";

class CityName extends Component {
  cityName() {
    const { currentSearchReducer, planetesInSignsReducer } = this.props;
    const tz = currentSearchReducer.timezone;
    // const periodArr = planetesInSignsReducer.periodName.split(' ');
    // let start, end;
    // if(periodArr[0] === 'Next') {
    //     start = moment().tz(tz).format();
    //     end = moment().tz(tz).add(+periodArr[1], 'years').format();
    // }else{
    //     start = planetesInSignsReducer.periodName.split(' - ')[0];
    //     end = planetesInSignsReducer.periodName.split(' - ')[1];
    // }
    const name =
      currentSearchReducer.name !== null ? `${currentSearchReducer.name}` : "";
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    const planet = window.location.pathname.split("/")[1].split("-")[0];
    const start = moment(planetesInSignsReducer.current_sign.dt)
      .tz(tz)
      .format("ll");
    const end = moment(planetesInSignsReducer.next_sign.dt).tz(tz).format("ll");
    const sign = planetesInSignsReducer.current_sign.sign.name;
    return (
      <h4>
        <b>
          {planet} is in {sign} for {name}, {state} {country}
        </b>
        <b>
          from {start} to {end}
        </b>
        {/* <b>from {moment(start).tz(tz).format('ll')} to {moment(end).tz(tz).format('ll')}</b> */}
      </h4>
    );
  }
  render() {
    const {
      currentSearchReducer,
      planetaryStyle,
      planetesInSignsReducer,
    } = this.props;
    const planetElem = planetesInSignsReducer.planetesInSignsList[0];
    return (
      <div className="city-whith-planet-img">
        <img
          src={`/static/planetes_photo/${planetElem.planet.name}.jpg`}
          alt={`${planetElem.planet.name}`}
        />
        <div className="citySunRiseSet" style={planetaryStyle}>
          <div className="country_flag">
            <img
              className="cityMainImg"
              src={`/static/flags/flags-iso/flat/64/${currentSearchReducer.country_code}.png`}
              alt="citydateandtime"
            />
          </div>
          <div className="city_name_date">{this.cityName()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    planetesInSignsReducer: state.planetesInSignsReducer,
  };
};

export default connect(mapStateToProps)(CityName);
