import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone/index.js';

import './LinksBlock';

class LinksBlock extends Component {
    render() {
        const { currentSearchReducer } = this.props;
        return (
            <div className="world-clock-links">
                <h4>Calculators and Timers</h4>
                <div className="currentCityInfo">
                    <div className="hover_effect" title={`Calculate the duration between two dates`}>
                        <Link to={'/date-to-date-calculator'}>
                            <b>Date to date calculator</b>
                        </Link>
                    </div>
                    <div className="hover_effect" title={`Calculate the duration between two dates`}>
                        <Link to={'/'}>
                            <b>Next business week start-end</b>
                        </Link>
                    </div>
                    <div className="hover_effect" title={`Calculate the duration between two dates`}>
                        <Link to={'/'}>
                            <b>Next business month start-end</b>
                        </Link>
                    </div>
                    <div className="hover_effect" title={`Calculate the week start and end for business`}>
                        <Link to={'/'}>
                            <b>Next business quarter start-end</b>
                        </Link>
                    </div>
                    {/* <div className="hover_effect">
                        <Link to={'/countdown/newyear'}>
                            <b>Countdown to New Year {moment.tz(currentSearchReducer.timezone).get('year') + 1} for {currentSearchReducer.name}</b>
                        </Link>
                    </div>
                    <div className="hover_effect" title={`Find the arrival and departure times in the city of departure and arrival`}>
                        <Link to={'/countdown-timer'}>
                            <b>Countdown Timer</b>
                        </Link>
                    </div> */}
                    <div className="hover_effect" title={`Find the arrival and departure times in the city of departure and arrival`}>
                        <Link to={'/flight-time-calculator'}>
                            <b>Flight time calculator</b>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};

export default connect(mapStateToProps)(LinksBlock);