import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MetaTags from "react-meta-tags";

import "./SignIn.css";
import SignInFormValidation from "./SignInFormValidation";
import authorization from "../../../../actions/authorization";
import SocialButtonList from "../SocialButtonList";

import Api from "../../../../services/api.js";
import { resendMail } from "../../../../utils/sharedFunctions";

class SignIn extends Component {
	state = {
		errorText: "",
		isSent: false
	};

	async signInSubmit() {
		const { form, authorization } = this.props;
		const email = form.SignInForm.values.email;
		const password = form.SignInForm.values.password;
		const signIn = await Api.authorization("rest-auth/login/", {
			username: email,
			password: password
		}).catch(error => {
			if (error.response.status !== 200) {
				const errors = Object.values(error.response.data);
				const text =
					errors[0][0] ===
					"Unable to log in with provided credentials."
						? "Please enter a correct email and password. Note that both fields may be case-sensitive."
						: errors[0];
				this.setState(prevState => {
					return {
						errorText: text
					};
				});
			} else {
				this.setState(prevState => {
					return {
						errorText: ""
					};
				});
			}
		});
		if (signIn) {
			const userInfo = await Api.userInfo(
				"user-profile/",
				signIn.data.key
			);
			authorization({ ...userInfo.data.data, token: signIn.data.key });
			const rememberMe = form.SignInForm.values.employed;
			if (rememberMe === false) {
				sessionStorage.setItem(
					"authorization",
					JSON.stringify(userInfo.data.data)
				);
				sessionStorage.setItem("token", signIn.data.key);
			} else {
				localStorage.setItem(
					"authorization",
					JSON.stringify(userInfo.data.data)
				);
				localStorage.setItem("token", signIn.data.key);
			}
			this.props.history.push("/");
		}
	}

	handleResend = async () => {
		const { form } = this.props;
		const email = form.SignInForm.values.email;
		const result = await resendMail(email);
		if (result.status === 200) {
			this.setState({ isSent: true });
		}
	};

	render() {
		const { errorText, isSent } = this.state;

		return (
			<div className="authorization-wrapper">
				<MetaTags>
					<title>GeoTimeDate – Sign in</title>
				</MetaTags>
				<section className="authorization">
					<div className="authorization-title authorization-title__short">
						<h4>Sign In</h4>
						<div className="br-bottom"></div>
					</div>

					<MuiThemeProvider muiTheme={getMuiTheme()}>
						<div style={{ padding: "15px 0" }}>
							{errorText ? (
								<Fragment>
									<span className="authorization-error">
										{errorText}
									</span>

									{errorText.includes(
										"E-mail is not verified."
									) ? (
										<Link
											to="#"
											onClick={this.handleResend}
											className="email-note"
										>
											Click here to resend the
											verification link
										</Link>
									) : null}
									{isSent && (
										<div className="re-sent_note">
											The letter sent successfully
										</div>
									)}
								</Fragment>
							) : null}

							<SignInFormValidation
								onSubmit={this.signInSubmit.bind(this)}
							/>
						</div>
					</MuiThemeProvider>
					<div className="social-login">
						<div className="authorization-middle-title">
							<div className="middle-line"></div>
							<b>OR SIGN IN USING</b>
							<div className="middle-line"></div>
						</div>
						<SocialButtonList />
					</div>
					<Link to="/signup" className="authorization-signup-link">
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						Don't have an account? Click here to sign up
					</Link>
					<p className="agreement">
						By signing in, you agree to geotimedate.org`s{" "}
						<Link to="/terms-of-service"> Terms of Service, </Link>
						<Link to="/privacy">Privacy Policy</Link> and{" "}
						<Link to="/privacy">Cookie policy.</Link>
					</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form,
		newRegisteredEmail: state.newRegisteredEmailReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
