import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import Api from "../../../../services/api.js";
import { resendMail } from "../../../../utils/sharedFunctions";
import "./ThankYouForRegistration.css";

class ThankYouForRegistration extends Component {
	state = {
		isSent: false
	};

	handleResend = async () => {
		const result = await resendMail(this.props.newRegisteredEmail);
		if (result.status === 200) {
			this.setState({ isSent: true });
		}
	};

	render() {
		const { isSent } = this.state;

		return (
			<div className="authorization-wrapper">
				<section className="authorization">
					<h4>Registration successful</h4>
					<div className="authorization-text">
						<p className="no-bottom-margin">
							You are almost there. Please click on the
							verification link that has been sent to your email
							account to&nbsp;complete the registration process.
						</p>
					</div>
					<div className="authorization-text">
						<span>
							The verification link is only valid for 24 hours.
						</span>
						<span>
							If you do not find the email in the inbox, please
							check your spam folder.
						</span>
						<span>
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							Didn't receive the mail?{" "}
							<Link to="#" onClick={this.handleResend}>
								Click here to resend the verification link
							</Link>
						</span>
						{isSent && (
							<span className="re-sent_note">
								The letter sent successfully
							</span>
						)}
					</div>
				</section>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		newRegisteredEmail: state.newRegisteredEmailReducer
	};
};
export default connect(mapStateToProps)(ThankYouForRegistration);
