import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import 'react-sticky-table/dist/react-sticky-table.css';
import moment from 'moment-timezone';
import './TableHolidays.css';
import Api from '../../../../../../../services/api.js';

class TableHolidays extends Component {
    tableHeader = [
        ['Date'],
        ['Weekday'],
        ['Holiday Name'],
        // ['Holiday Type'],
    ];
    state = {
        holidaysYear: [],
    };
    async updateAfterSearch(){
        const { currentSearchReducer } = this.props;
        //console.log(this.state.name, "VS", currentSearchReducer.name);
        if(this.state.name !== currentSearchReducer.name) {
            // const holidays = new Holidays(`${currentSearchReducer.country_code}`, 'la', 'no');
            // const year = moment(currentSearchReducer.time).tz(currentSearchReducer.time).get('year');
            // holidays.getHolidays(year);
            const holidays = await Api.userCityList(`holidays/?country=${currentSearchReducer.country_code}`);
            this.setState({ holidaysYear: holidays.data.holidays_list, name : currentSearchReducer.name});
        }
    }
    async componentDidMount(){
        const{ currentSearchReducer } = this.props;
        //console.log('/?country', currentSearchReducer.country_code);
        const holidays = await Api.userCityList(`holidays/?country=${currentSearchReducer.country_code}`);
        this.setState({ holidaysYear: holidays.data.holidays_list, name : currentSearchReducer.name});
    }

    geotimedateTable(){
        if(document.body.offsetWidth > 1199) {
            return this.state.holidaysYear.length * 35 + 47 + 48 + 50;
        } else return document.body.offsetHeight < 1260 ? document.body.offsetHeight : 1260;
    }
    // formatDateHodidays(value){
    //     value = value.split('T')[0];
    //     let date = new Date(value.split('-')[0], value.split('-')[1], value.split('-')[2]);
    //     const months = ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov'];
    //     return ` ${months[+date.getMonth()]} ${date.getDate()} `
    // }
    // formatWeekdayHodidays(value){
    //     value = value.split('T')[0];
    //     let date = new Date(value.split('-')[0], value.split('-')[1], value.split('-')[2]);
    //     const weekdays = ["Sunday", "Monday" ,"Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    //     return `${weekdays[+date.getDay()]}`
    // }
    render () {
         //const{ currentSearchReducer } = this.props;
         //console.log('name', currentSearchReducer.country_name);
         //console.log('holidaysYear', this.state.holidaysYear);
        // const { currentSearchReducer } = this.props;
        this.updateAfterSearch();
        return (

                <div className='table-data-period'>
                    {/*<div className="rowHolidaysTitleTable ">*/}
                        {/*<h4>*/}
                            {/*| Future possible settings for the table |*/}
                        {/*</h4>*/}
                    {/*</div>*/}
                    {/*<hr/>*/}
                    <div className='geotimedate-table' style={{width: '100%', height: this.geotimedateTable() + 'px'}}>
                        <StickyTable stickyColumnCount={0} stickyHeaderCount={1}>
                            <Row>
                                {this.tableHeader.map((elem, index) =>
                                    <Cell key={index}>
                                        <div className='cell-wrapper'>
                                            <b>
                                                <em>{elem[0]}</em>
                                                <em>{elem[1]}</em>
                                            </b>
                                        </div>
                                    </Cell>
                                )}
                            </Row>
                            <Row>
                                <Cell></Cell>
                                <Cell></Cell>
                                <Cell></Cell>
                                {/*<Cell></Cell>*/}
                            </Row>
                            {this.state.holidaysYear.map((elem, index, arr) =>
                                <Row key={index} className="rowHolidays">
                                    <Cell className="cellHolidays_date">
                                        {/*<p>{this.formatDateHodidays(elem.start_date)}</p>*/}
                                        {/*<p>{moment(elem.start_date).tz(currentSearchReducer.timezone).format('MMM D')}</p>*/}
                                        <p>{moment(elem.start_date).tz(elem.time_zone).format('MMM D')}</p>
                                    </Cell>
                                    <Cell className="cellHolidays week">
                                        {/*<p>{this.formatWeekdayHodidays(elem.start_date)} </p>*/}
                                        {/*<p>{moment(elem.start_date).tz(currentSearchReducer.timezone).format('dddd')}</p>*/}
                                        <p>{moment(elem.start_date).tz(elem.time_zone).format('dddd')}</p>
                                    </Cell>
                                    <Cell className="cellHolidays name">
                                        <p>{elem.title}</p>
                                    </Cell>
                                    {/*<Cell className="cellHolidays">*/}
                                    {/*<p>{elem.category}</p>*/}
                                    {/*</Cell>*/}
                                </Row>
                            )}
                        </StickyTable>
                    </div>
                </div>


        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        weatherInfoReducer: state.weatherInfoReducer
    };
};

export default connect(mapStateToProps)(TableHolidays);