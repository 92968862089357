import axios from "axios";

class Api {
  state = {
    data: "",
    login: "",
    password: ""
  };
  // url = window.location.origin === 'https://citydateandtime.stemsc.com' ? 'https://rest-citydateandtime.stemsc.com/' : 'https://api.geotimedate.com/';
  //  url = window.location.origin === 'https://api.geotimedate.com/' ? 'https://api.geotimedate.com/' : 'https://rest-citydateandtime.stemsc.com/';
  // url = window.location.origin === 'https://citydateandtime.stemsc.com' ? 'https://rest-citydateandtime.stemsc.com/' : 'https://dev-api.geotimedate.org/';
  // url = window.location.origin === 'https://dev-site.geotimedate.org' ? 'https://dev-api.geotimedate.org/' : 'https://api.geotimedate.org/';

  static staticUrl =
    window.location.origin === "https://geotimedate.org"
      ? "https://geotimedate.org/api/"
      : "https://dev-site.geotimedate.org/api/";
  // static staticUrl = 'http://localhost:8000/';
  // For local
  // static staticUrl = `${window.location.origin}/api/`;
  url = Api.staticUrl;

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  simpleRequest(address) {
    return axios
      .get(Api.staticUrl + address, {
        headers: {
          Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(
        response => {
          return response;
        },
        error => {
          console.log(error);
        }
      );
  }

  UserRequestGetPost(address, token, data) {
    const isToken = token || "9b09b8c1f47f22498206143bc1ff33797e9d1c50";
    if (data) {
      return axios.post(Api.staticUrl + address, data, {
        headers: {
          Authorization: `Token ${isToken}`
        }
      });
    } else {
      return axios.get(Api.staticUrl + address, {
        headers: {
          Authorization: `Token ${isToken}`
        }
      });
    }
  }

  userCityList(address) {
    return axios
      .get(Api.staticUrl + address, {
        headers: {
          Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(
        response => {
          return response;
        },
        error => {
          console.log(error);
        }
      );
  }

  updateCityList(address, data) {
    return axios
      .post(Api.staticUrl + address, data, {
        headers: {
          Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
          "Content-Type": "application/json"
        }
      })
      .then(
        response => {
          return response;
        },
        error => {
          console.log(error);
        }
      );
  }

  async passwordReset(email) {
    try {
      const response = await axios.post(
        Api.staticUrl + "reset-my-password/",
        { email: email },
        {
          headers: {
            // Authorization:
            // 	"Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
            "Content-Type": "application/json"
          }
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async resendEmailVerification(email) {
    let result = null;
    await axios
      .post(
        Api.staticUrl + "rest-auth/resend-email-verification/",
        { email: email },
        {
          headers: {
            Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => (result = res))
      .catch(error => (result = error.response));
    return result;
  }

  userCityDell(address, token) {
    return axios
      .get(Api.staticUrl + address, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(
        response => {
          return response;
        },
        error => {
          console.log(error);
        }
      );
  }

  test(address) {
    return axios.get(address, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "application/x-www-form-urlencoded"
      }
    });
  }

  autoRegistKey(address, data) {
    return axios.post(Api.staticUrl + address, data, {
      headers: {
        Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
        "Content-Type": "application/json"
      }
    });
  }

  authorization(address, data) {
    return axios.post(Api.staticUrl + address, data);
  }

  Reset_Pass(address, data) {
    return axios.post(Api.staticUrl + address, data, {
      headers: {
        Authorization: "Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50",
        "Content-Type": "application/json"
      }
    });
  }

  userInfo(address, token, data) {
    if (data) {
      return axios.put(Api.staticUrl + address, data, {
        headers: {
          Authorization: `Token ${token}`
        }
      });
    } else {
      return axios.get(Api.staticUrl + address, {
        headers: {
          Authorization: `Token ${token}`
        }
      });
    }
  }

  changeInfo(address, token, data) {
    return axios.post(Api.staticUrl + address, data, {
      headers: {
        Authorization: `Token ${token}`
      }
    });
  }

  getAllEvents(token) {
    const isToken = token || "9b09b8c1f47f22498206143bc1ff33797e9d1c50";
    return axios.get(Api.staticUrl + "all-events/", {
      headers: {
        Authorization: `Token ${isToken}`
      }
    });
  }

  currentCoordinars(lat, lng) {
    return axios.get(Api.staticUrl + "search/latlng/" + lat + "/" + lng + "/", {
      headers: {
        Authorization: `Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50`
      }
    });
  }

  //=================================================

  localStorageSpace() {
    let allStrings = "";
    for (var key in window.sessionStorage) {
      // eslint-disable-next-line no-prototype-builtins
      if (window.sessionStorage.hasOwnProperty(key)) {
        allStrings += window.sessionStorage[key];
      }
    }
    return allStrings
      ? 3 + (allStrings.length * 16) / (8 * 1024) + " KB"
      : "Empty (0 KB)";
  }

  clearStorage() {
    let key, value;
    let now = new Date() - 60 * 60 * 30 * 1000;
    for (let i = 0; i < sessionStorage.length; i++) {
      key = sessionStorage.key(i);
      value = JSON.parse(sessionStorage.getItem(key));
      if (value._cache_set_time_ && Date.parse(value._cache_set_time_) < now) {
        sessionStorage.removeItem(key);
      }
    }
  }

  async getAwait(key, realFunction, p1, p2, p3, p4) {
    const data = await realFunction(p1, p2, p3, p4);
    data._cache_set_time_ = new Date().getTime();
    // console.log("Save cache - " + key);
    // console.log(this.localStorageSpace());
    sessionStorage.setItem(key, JSON.stringify(data));
    return data;
  }

  async getCache(realFunction, p1, p2, p3, p4) {
    let key =
      ("" + realFunction).substring(9, 25) +
      "*" +
      (p1 ? p1 : "") +
      "_" +
      (p2 ? p2 : "") +
      "_" +
      (p3 ? p3 : "") +
      "_" +
      (p4 ? p4 : "");
    // console.log(key);

    let data;
    if (sessionStorage.getItem(key)) {
      data = JSON.parse(sessionStorage.getItem(key));
      if (new Date().getTime() - data._cache_set_time_ > 108000)
        //60 * 60 * 30  half-hour
        data = await this.getAwait(key, realFunction, p1, p2, p3, p4);
    } else data = await this.getAwait(key, realFunction, p1, p2, p3, p4);
    return data;
  }

  /* ============== Static Pages =================*/

  getPrivacy() {
    return axios.get(Api.staticUrl + "pages/privacy/", {
      headers: {
        Authorization: `Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50`
      }
    });
  }

  getTerms() {
    return axios.get(Api.staticUrl + "pages/terms-of-service/", {
      headers: {
        Authorization: `Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50`
      }
    });
  }

  getAbout() {
    return axios.get(Api.staticUrl + "pages/about-us/", {
      headers: {
        Authorization: `Token 9b09b8c1f47f22498206143bc1ff33797e9d1c50`
      }
    });
  }

  getCountry() {
    return axios.get(Api.staticUrl + "get-country/");
  }

  getState(code) {
    return axios.get(Api.staticUrl + "get-state/?code=" + code);
  }
  getCities(code) {
    return axios.get(Api.staticUrl + "get-substate/?code=" + code);
  }
  getCounty(code) {
    return axios.get(Api.staticUrl + "get-county/?code=" + code);
  }
  getCitiesUS(code) {
    return axios.get(Api.staticUrl + "get-subcounty/?code=" + code);
  }
  getUrls(code) {
    return axios.get(Api.staticUrl + "get-sitemap-url/?code=" + code);
  }
  getGoogleCalendars(token) {
    return axios.get(Api.staticUrl + "google-calendar/", {
      headers: {
        Authorization: `Token ${
          token ? token : "9b09b8c1f47f22498206143bc1ff33797e9d1c50"
        }`,
        "Content-Type": "application/json"
      }
    });
  }
  syncBackendGoogleCalendar(uri, token) {
    return axios.get(Api.staticUrl + uri, {
      headers: {
        Authorization: `Token ${
          token ? token : "9b09b8c1f47f22498206143bc1ff33797e9d1c50"
        }`,
        "Content-Type": "application/json"
      }
    });
  }
  /*
  getLocatoin() {
    return axios.get("https://get.geojs.io/v1/ip/geo.js");
  }
*/
  // getLocatoinJSON() {
  // 	return axios.get("https://get.geojs.io/v1/ip/geo.json");
  // }
  getLocatoinJSON() {
    return axios.get(Api.staticUrl + "get-location/");
  }

  /* ============== END Static Pages =================*/
}

/*
class GetCache {
    constructor(realFunction, p1, p2, p3, p4) {
        this.realFunction = realFunction;
        this.key = ('' + realFunction).substring(9, 30) + p1 + p2 + p3 + p4;
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.p4 = p4;
    }

    async getAwait() {
        this.data = await this.realFunction(Api.url, this.p1, this.p2, this.p3, this.p4);
        this.data._cache_set_time_ = new Date().getTime();
        console.log('getAwait');
        localStorage.setItem(this.key, JSON.stringify(this.data));
    }

    async getData() {
        console.log(this.key);
        if (localStorage.getItem(this.key)) {
            this.data = JSON.parse(localStorage.getItem(this.key));
            if (new Date().getTime() - this.data._cache_set_time_ > 60 * 60 * 30 * 1000) await this.getAwait();
        } else await this.getAwait();
        return this.data;
    }
}
*/
export default new Api();
