import React, {Component} from 'react';
import {connect} from 'react-redux';

import Api from "../../../../../../services/api";

import "./ComponentLocationEvent.css";

class ComponentLocationEvent extends Component {

    state = {
        selectedCity1: '',
        searchPlaceholder1: 'City or Country',
        cities: [],
    }

    highlight(highlight) {
        return highlight === true ? {} : {'fontWeight': 'normal'};
    }

    notEmptyName(param) {
        return param ? `${param}` : '';
    }

    notEmptyState(param) {
        return param ? `${param}, ` : '';
    }

    notEmptyFeature(param) {
        return param ? ` (${param})` : '';
    }

    searchMouseEventMouseOver = (index, e) => {
        let mouseTop = e.screenY;
        let mouseLeft = e.screenX;
        this.setState(prevState => {
            if (mouseTop !== prevState.mousePosTop || mouseLeft !== prevState.mousePosLeft) {
                return {
                    listElemIndex: index,
                    mousePosTop: mouseTop,
                    mousePosLeft: mouseLeft
                };
            }
        });
    }
    searchListHover = (index, e) => {
        this.setState(prevState => {
            return {
                selectedElemIndex: index
            };
        });
    }

    selectedCity(id, name, state, county, country, feature, index, searchNumm, e) {
        if (e) {
            if (e.button === 1 || e.button === 2) return;
        }
        let newName = '', newState = '', newCounty = '', newCountry = '', newFeature = '';
        name ? newName = `${name}, ` : newName = '';
        state ? newState = `${state}, ` : newState = '';
        county ? newCounty = `${county}, ` : newCounty = '';
        country ? newCountry = `${country}` : newCountry = '';
        feature ? newFeature = `(${feature})` : newFeature = '';
        this.setState(prevState => {
            return this.state.selectedCityNumm === 1 ? {
                searchPlaceholder1: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`
            } : {searchPlaceholder2: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`};
        });
        //console.log('look id', id);
        this.props.setLocationRedux(id);
        this.setState(prevState => {
            return {
                // selectedCity: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`,
                selectedCity1: ``,
                selectedCity2: ``,
                selectedCityId: id,
                selectedCityIndex: index,
                // searchPlaceholder: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`,
                searchPlaceholder: 'select another city or country',
                cities: [],
                searchOpen: {border: 'none', height: 0, margin: '0'}
            };
        });
        this.selectedCityInfo(id, searchNumm,);
    }

    async selectedCityInfo(id, searchNumm) {
        const data = await Api.userCityList(`geo_names/${id}/`);
        this.setState(prevState => {
            return searchNumm === 1 ? {selectedCityInfo1: data.data} : {selectedCityInfo2: data.data};
        });
    }

    async searchValue(searchNumm, e) {
        const value = e.target.value;
        // const searchActive = searchNumm === 1
        this.setState(prevState => {
            return searchNumm === 1 ? {selectedCity1: value, selectedCityNumm: 1} : {selectedCity2: value, selectedCityNumm: 2};
        });
        if (e.target.value.length > 2) {
            const data = await Api.userCityList(`search/?q=${e.target.value}&feature_classes=P`);
            this.setState(prevState => {
                if (data.data.locations_list.length > 0) {
                    document.body.querySelector(`.search_form_list${this.state.searchActive}`).scrollTo(0, 0);
                    return {
                        listElemIndex: 0,
                        cities: data.data.locations_list,
                        searchOpen: {border: '1px solid rgba(210, 213, 213, 1)', height: 'auto', margin: '5px 0 0'}
                    };
                } else {
                    return {
                        cities: [],
                        searchOpen: {border: 'none', height: 0, margin: '0'}
                    };
                }
            });
        } else {
            this.setState(prevState => {
                return {
                    cities: [],
                    searchOpen: {border: 'none', height: 0, margin: '0'}
                };
            });
        }
    }

    searchFocus = (e) => {
        if (this.state.cities.length > 0 && e.button === 0) {
            this.setState(prevState => {
                return {
                    searchOpen: {border: '1px solid rgba(210, 213, 213, 1)', height: 'auto', margin: '5px 0 0'}
                }
            });
        }
    };

    searchListFocus(searchNumm) {

        this.setState(prevState => {
            // const selectedCity = 'selectedCity' + searchNumm;
            if (searchNumm === 1) {
                return {
                    searchActive: 1,
                    selectedCity1: '',
                    selectedCity2: '',
                    cities: [],
                    searchOpen: {border: 'none', height: 0, margin: '0'},
                    searchPlaceholder1: 'City or Country',
                }
            } else {
                return {
                    searchActive: 2,
                    selectedCity1: '',
                    selectedCity2: '',
                    cities: [],
                    searchOpen: {border: 'none', height: 0, margin: '0'},
                    searchPlaceholder1: 'City or Country',
                }
            }
        });
    }

    searchListOpen(searchNumm) {
        return this.state.searchActive === searchNumm ? this.state.searchOpen : {display: 'none'};
    }

    searchStyle(index) {
        if (this.state.selectedElemIndex === index) {
            return {background: '#7ea7d8', color: '#fff'};
        } else if (this.state.listElemIndex === index) {
            return {background: 'rgba(103, 150, 206, 1)', color: '#fff'};
        } else {
            return {background: '#fff', color: 'rgba(68, 68, 68, 1)'};
        }
    }


    render() {
        return (
            <div className="search_form_select">
                <section className="Search searcj-block-loc_event">
                    <div className="search_form">
                        <div className="search_form_select">
                            <input
                                className="search_field"
                                type="text"
                                value={this.state.selectedCity1}
                                placeholder={this.state.searchPlaceholder1.toUpperCase()}
                                onChange={this.searchValue.bind(this, 1)}
                                onMouseUp={this.searchFocus}
                                onFocus={this.searchListFocus.bind(this, 1)}
                            />
                            <ul style={this.searchListOpen(1)} className="search_form_list search_form_list1">
                                {this.state.cities.map((elem, index) =>
                                    <li key={index}>
                                        <div className="selectCityWrapper selectCityWrapper1"
                                             style={this.searchStyle(index)}
                                             onMouseDown={this.selectedCity.bind(this, elem.id, elem.name, elem.state, elem.county, elem.country_name, elem.feature, elem.index, 1)}
                                             onClick={this.searchListHover.bind(this, index)}
                                             onMouseMove={this.searchMouseEventMouseOver.bind(this, index)}
                                        >
                                            <div className="mainNameWrapper">
                                                <b className="cityMainName"
                                                   style={this.highlight(elem.highlight)}>{this.notEmptyName(elem.name)}</b>{this.notEmptyFeature(elem.feature)}
                                                <img className="cityMainImg"
                                                     src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                                                     alt="citydateandtime"/>
                                            </div>
                                            {this.notEmptyState(elem.state)}{this.notEmptyState(elem.county)}{this.notEmptyName(elem.country_name)}
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </section>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        countdownReducer: state.countdownReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setLocationRedux: locationId => {
            //console.log('locationId', locationId)
            const payload = {
                locationId,
            };
            dispatch({type: 'PASS_LOCATION_EVENT', payload});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentLocationEvent);
