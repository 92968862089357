import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";
import Select from "react-select";
import { MuiThemeProvider, DatePicker, TimePicker } from "material-ui";
import Checkbox from "material-ui/Checkbox";

import customStyleSelect from "../../DateCalculator/Calculator/customStyleSelect";
import flightTimeInfo from "../../../../../actions/action_flightTimeInfo.js";
import Loader from "../../../../../components/Main/Loader/Loader";
import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import Ads from "../../Ads/Ads";

import Api from "../../../../../services/api.js";
import "./Calculator.css";

class Calculator extends Component {
  state = {
    cities: [],
    selectedCity1: "",
    selectedCity2: "",
    selectedCityId1: 0,
    selectedCityId2: 0,
    selectedCityInfo: [],
    selectedCityInfo1: {
      timezone: "UTC"
    },
    selectedCityInfo2: {
      timezone: "UTC"
    },
    options: [],
    searchPlaceholder1: "City or Country",
    searchPlaceholder2: "City or Country",
    selectedCityIndex: 0,
    searchOpen: { border: "none", height: 0, margin: "0" },
    addedCity: {},
    listElemIndex: "none",
    selectedElemIndex: "null",
    mousePosTop: 0,
    mousePosLeft: 0,
    departureDateDate: "",
    departureDateTime: "",
    arriveDateDate: "",
    arriveDateTime: "",
    showBlock: false,

    result: "",
    id: "6955103",
    style: {
      open: { display: "none" }
    },
    warning: { display: "none" },
    loadStart: false,
    classError: "",
    classError2: "",
    timeState: false,
    hh: "",
    mm: "",
    ss: "",
    hh2: "",
    mm2: "",
    ss2: "",
    part1: { value: "am", label: "am" },
    part2: { value: "am", label: "am" },
    timeFormat: "AM/PM",
    optionsPartTime: [
      { value: "am", label: "am" },
      { value: "pm", label: "pm" }
    ],
    checkboxTime: false,
    checkboxDate: false,
    checkboxTimezone: false
  };

  async componentDidMount() {
    this.searchClose = document.addEventListener("click", e => {
      if (
        (e.target.className !== "search_form_list1" ||
          e.target.className !== "search_form_list2") &&
        e.target.className !== "search_field"
      ) {
        this.setState({
          searchOpen: { border: "none", height: 0, margin: "0" }
        });
      }
    });
    this.keydownSearchClose = document.body.addEventListener("keydown", e => {
      if (e.keyCode === 27) {
        this.setState({
          searchOpen: { border: "none", height: 0, margin: "0" }
        });
      }
    });
    this.setState({ id: this.props.currentSearchReducer.id });
    this.searchKeyPress();
    this.searchKeyEnter();
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.searchClose);
    document.removeEventListener("keydown", this.keydownSearchClose);
  }
  async searchValue(searchNumm, e) {
    const value = e.target.value;
    // const searchActive = searchNumm === 1
    this.setState(prevState => {
      return searchNumm === 1
        ? { selectedCity1: value, selectedCityNumm: 1 }
        : { selectedCity2: value, selectedCityNumm: 2 };
    });
    if (e.target.value.length > 2) {
      const data = await Api.userCityList(
        `search/?q=${e.target.value}&feature_classes=P`
      );
      this.setState(prevState => {
        if (data.data.locations_list.length > 0) {
          document.body
            .querySelector(`.search_form_list${this.state.searchActive}`)
            .scrollTo(0, 0);
          return {
            listElemIndex: 0,
            cities: data.data.locations_list,
            searchOpen: {
              border: "1px solid rgba(210, 213, 213, 1)",
              height: "auto",
              margin: "5px 0 0"
            }
          };
        } else {
          return {
            cities: [],
            searchOpen: { border: "none", height: 0, margin: "0" }
          };
        }
      });
    } else {
      this.setState(prevState => {
        return {
          cities: [],
          searchOpen: { border: "none", height: 0, margin: "0" }
        };
      });
    }
  }
  selectedCity(
    id,
    name,
    state,
    county,
    country,
    feature,
    index,
    searchNumm,
    e
  ) {
    if (e) {
      if (e.button === 1 || e.button === 2) return;
    }
    let newName = "",
      newState = "",
      newCounty = "",
      newCountry = "",
      newFeature = "";
    name ? (newName = `${name}, `) : (newName = "");
    state ? (newState = `${state}, `) : (newState = "");
    county ? (newCounty = `${county}, `) : (newCounty = "");
    country ? (newCountry = `${country}`) : (newCountry = "");
    feature ? (newFeature = `(${feature})`) : (newFeature = "");
    this.setState(prevState => {
      return this.state.selectedCityNumm === 1
        ? {
            searchPlaceholder1: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`
          }
        : {
            searchPlaceholder2: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`
          };
    });
    this.setState(prevState => {
      return {
        selectedCity1: ``,
        selectedCity2: ``,
        selectedCityId: id,
        selectedCityIndex: index,
        searchPlaceholder: "select another city or country",
        cities: [],
        searchOpen: { border: "none", height: 0, margin: "0" }
      };
    });
    this.selectedCityInfo(id, searchNumm);
  }
  async selectedCityInfo(id, searchNumm) {
    const data = await Api.userCityList(`geo_names/${id}/`);
    this.setState(prevState => {
      return searchNumm === 1
        ? { selectedCityInfo1: data.data }
        : { selectedCityInfo2: data.data };
    });
  }
  // search: handling of pressing the arrows (up, down)
  searchKeyPress() {
    document.body.addEventListener("keydown", e => {
      const cityList = [
        ...document.body.querySelectorAll(
          `.selectCityWrapper${this.state.searchActive}`
        )
      ];
      const scrollBlock = document.body.querySelector(
        `.search_form_list${this.state.searchActive}`
      );
      let height;
      const pressNext = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top <= scrollBlock.offsetHeight - height
          ? 0
          : top - scrollBlock.offsetHeight + height;
      };
      const pressPrev = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top;
      };
      if (
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex >= cityList.length - 1)
      ) {
        this.setState(prevState => {
          scrollBlock.scrollTo(0, pressNext(0));
          return { listElemIndex: 0 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 40 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressNext(prevState.listElemIndex + 1);
          if (scrollBlock.scrollTop < topPos) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex + 1 };
        });
      } else if (
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex <= 0)
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(cityList.length - 1);
          scrollBlock.scrollTo(0, topPos);
          return { listElemIndex: cityList.length - 1 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 38 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(prevState.listElemIndex - 1);
          if (topPos < scrollBlock.scrollTop) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex - 1 };
        });
      }
    });
  }
  // search: enter press
  searchKeyEnter() {
    document.body.addEventListener("keydown", e => {
      if (this.state.cities.length > 0 && e.keyCode === 13) {
        const selectedCity = this.state.cities.filter((elem, index) => {
          return index === this.state.listElemIndex;
        });
        const city = selectedCity[0];
        // selectedCityNumm
        this.selectedCity(
          city.id,
          city.name,
          city.state,
          city.county,
          city.country,
          city.feature,
          city.index,
          this.state.selectedCityNumm
        );

        this.setState(prevState => {
          return {
            searchOpen: { border: "none", height: 0, margin: "0" }
          };
        });
      }
    });
  }
  // search: mouse pointer processing
  searchMouseEventMouseOver = (index, e) => {
    let mouseTop = e.screenY;
    let mouseLeft = e.screenX;
    this.setState(prevState => {
      if (
        mouseTop !== prevState.mousePosTop ||
        mouseLeft !== prevState.mousePosLeft
      ) {
        return {
          listElemIndex: index,
          mousePosTop: mouseTop,
          mousePosLeft: mouseLeft
        };
      }
    });
  };
  // search: click/select
  searchListHover = (index, e) => {
    this.setState(prevState => {
      return {
        selectedElemIndex: index
      };
    });
  };
  searchStyle(index) {
    if (this.state.selectedElemIndex === index) {
      return { background: "#7ea7d8", color: "#fff" };
    } else if (this.state.listElemIndex === index) {
      return { background: "rgba(103, 150, 206, 1)", color: "#fff" };
    } else {
      return { background: "#fff", color: "rgba(68, 68, 68, 1)" };
    }
  }
  highlight(highlight) {
    return highlight === true ? {} : { fontWeight: "normal" };
  }
  notEmptyName(param) {
    return param ? `${param}` : "";
  }
  notEmptyState(param) {
    return param ? `${param}, ` : "";
  }
  notEmptyFeature(param) {
    return param ? ` (${param})` : "";
  }
  searchFocus = e => {
    if (this.state.cities.length > 0 && e.button === 0) {
      this.setState(prevState => {
        return {
          searchOpen: {
            border: "1px solid rgba(210, 213, 213, 1)",
            height: "auto",
            margin: "5px 0 0"
          }
        };
      });
    }
  };
  searchListOpen(searchNumm) {
    return this.state.searchActive === searchNumm
      ? this.state.searchOpen
      : { display: "none" };
  }
  searchListFocus(searchNumm) {
    this.setState(prevState => {
      if (searchNumm === 1) {
        return {
          searchActive: 1,
          selectedCity1: "",
          selectedCity2: "",
          cities: [],
          searchOpen: { border: "none", height: 0, margin: "0" }
        };
      } else {
        return {
          searchActive: 2,
          selectedCity1: "",
          selectedCity2: "",
          cities: [],
          searchOpen: { border: "none", height: 0, margin: "0" }
        };
      }
    });
  }

  calendarChange(startEnd, event, date) {
    this.setState(
      startEnd === "start" ? { dateStart: date } : { dateEnd: date }
    );
  }

  dateChange(startEnd, val, event) {
    let value = +event.target.value;
    if (isNaN(value)) return;
    this.focusControl(startEnd, val, event.target.value);
    const switchFunc = date => {
      const fullDate = date !== undefined ? date : new Date();
      // console.log(fullDate, startEnd);
      switch (val) {
        case "day":
          if (value < 1) value = 1;
          else if (value > moment(fullDate).daysInMonth())
            value = moment(fullDate).daysInMonth();
          return new Date(fullDate.setDate(value));
        case "month":
          if (value < 1) value = 1;
          else if (value > 12) value = 12;
          return new Date(fullDate.setMonth(value - 1));
        case "year":
          if (value < 0) value = 0;
          else if (value > moment().get("year") + 99)
            value = moment().get("year") + 99;
          return new Date(fullDate.setFullYear(value));
        default:
          return "";
      }
    };
    if (startEnd === "start") {
      this.setState({ dateStart: switchFunc(this.state.dateStart) });
    } else {
      this.setState({ dateEnd: switchFunc(this.state.dateEnd) });
    }
  }
  focusControl(startEnd, val, value) {
    const monthStart =
        // eslint-disable-next-line react/no-string-refs
      startEnd === "start" ? this.refs.monthStart : this.refs.monthEnd;
    const yearStart =
        // eslint-disable-next-line react/no-string-refs
      startEnd === "start" ? this.refs.yearStart : this.refs.yearEnd;
    const hoursStart =
        // eslint-disable-next-line react/no-string-refs
      startEnd === "start" ? this.refs.hoursStart : this.refs.hoursEnd;
    const minutesStart =
        // eslint-disable-next-line react/no-string-refs
      startEnd === "start" ? this.refs.minutesStart : this.refs.minutesEnd;
    const secondsStart =
        // eslint-disable-next-line react/no-string-refs
      startEnd === "start" ? this.refs.secondsStart : this.refs.secondsEnd;
    switch (val) {
      case "day":
        if (value.length >= 2) {
          monthStart.focus();
          monthStart.select();
        }
        break;
      case "month":
        if (value.length >= 2) {
          yearStart.focus();
          yearStart.select();
        }
        break;
      case "year":
        if (value.length >= 4) {
          hoursStart.focus();
          hoursStart.select();
        }
        break;
      case "hours":
        if (value.length >= 2) {
          minutesStart.focus();
          minutesStart.select();
        }
        break;
      case "minutes":
        if (value.length >= 2) {
          secondsStart.focus();
          secondsStart.select();
        }
        break;
      default:
        break;
    }
  }
  dateShow(startEnd, val) {
    const switchFunc = date => {
      switch (val) {
        case "DD":
          if (date) return date.getDate();
          break;
        case "MM":
          if (date) return date.getMonth() + 1;
          break;
        case "YYYY":
          if (date) return date.getFullYear();
          break;
        default:
          return "";
      }
    };
    if (startEnd === "start") {
      return this.state.dateStart === undefined
        ? ""
        : switchFunc(this.state.dateStart);
    } else {
      return this.state.dateEnd === undefined
        ? ""
        : switchFunc(this.state.dateEnd);
    }
  }

  timeChange(startEnd, event, time) {
    let hours, minutes, second, part;

    if (this.state.timeFormat === "AM/PM") {
      if (time.getHours() > 12) {
        hours = time.getHours() - 12;
        part = "pm";
      } else {
        hours = time.getHours();
        part = "am";
      }
      minutes = time.getMinutes();
      second = time.getSeconds();
    } else {
      hours = time.getHours();
      minutes = time.getMinutes();
      second = time.getSeconds();
    }

    this.setState(
      startEnd === "start"
        ? {
            timeStart: time,
            part1: { value: part, label: part },
            hh: hours,
            mm: minutes,
            ss: second
          }
        : {
            timeEnd: time,
            part2: { value: part, label: part },
            hh2: hours,
            mm2: minutes,
            ss2: second
          }
    );
  }

  startCalculation = () => {
    const {
      checkboxTimezone,
      checkboxTime,
      selectedCityInfo1,
      selectedCityInfo2
    } = this.state;
    const errorRequest = () => {
      return this.setState({
        calculationError: { display: "block" },
        classError: "input-error",
        classError2: "input-error"
      });
    };
    if (
      checkboxTimezone === true &&
      (selectedCityInfo1 === [] || selectedCityInfo2 === [])
    )
      return errorRequest();
    else if (checkboxTime === true && !this.state.timeStart)
      return errorRequest();
    else if (checkboxTime === true && !this.state.timeEnd)
      return errorRequest();
    if (this.state.dateStart && this.state.dateEnd) {
      this.setState({ loadStart: true });

      let dateEnd = moment(this.state.dateEnd);
      let dateTimeEnd = moment(this.state.timeEnd);
      dateEnd = dateEnd.format("YYYY-MM-DD");
      dateTimeEnd = dateTimeEnd.format("HH:mm:ss");
      let endFormat = `${dateEnd}T${dateTimeEnd}`;

      let dateStart = moment(this.state.dateStart);
      let dateTimeStart = moment(this.state.timeStart);
      dateStart = dateStart.format("YYYY-MM-DD");
      dateTimeStart = dateTimeStart.format("HH:mm:ss");
      let startFormat = `${dateStart}T${dateTimeStart}`;

      let msUTCStart = Date.parse(startFormat);
      let msUTCStart2 = Date.parse(endFormat);
      if (msUTCStart > msUTCStart2) {
        this.caslculationRequest(endFormat, startFormat);
        this.setState({
          dateStart: this.state.dateEnd,
          timeStart: this.state.timeEnd,
          dateEnd: this.state.dateStart,
          timeEnd: this.state.timeStart,
          hh: this.state.hh2,
          mm: this.state.mm2,
          ss: this.state.ss2,
          hh2: this.state.hh,
          mm2: this.state.mm,
          ss2: this.state.ss,
          warning: { display: "block" }
        });
      } else {
        this.caslculationRequest(startFormat, endFormat);
        this.setState({
          warning: { display: "none" }
        });
      }
      this.setState({
        calculationError: { display: "none" },
        style: { open: { display: "block" } },
        loadStart: true
      });
    } else {
      this.setState({
        calculationError: { display: "block" },
        classError: "input-error",
        classError2: "input-error"
      });
    }
  };

  async caslculationRequest(startFormat, endFormat) {
    const { id } = this.state;
    let geoID1, geoID2;

    if (
      this.state.selectedCityInfo1.id &&
      this.state.selectedCityInfo2.id &&
      this.state.checkboxTimezone
    ) {
      geoID1 = this.state.selectedCityInfo1.id;
      geoID2 = this.state.selectedCityInfo2.id;
    } else {
      geoID1 = id;
      geoID2 = id;
    }

    const data = await Api.userCityList(
      `time_calculation/?dt=${startFormat}&dt2=${endFormat}&geo_name=${geoID1}&geo_name_2=${geoID2}`
    );

    if (data.data) {
      if (this.state.checkboxDate) {
        data.data.data.checkboxDateEnd = true;
      } else {
        data.data.data.checkboxDateEnd = false;
      }

      if (this.state.checkboxTime) {
        data.data.data.checkboxTime = true;
      } else {
        data.data.data.checkboxTime = false;
      }

      if (
        this.state.selectedCityInfo1.id &&
        this.state.selectedCityInfo2.id &&
        this.state.checkboxTimezone
      ) {
        data.data.data.checkboxTimezone = true;
      } else {
        data.data.data.checkboxTimezone = false;
      }

      if (
        this.state.selectedCityInfo1.name &&
        this.state.selectedCityInfo2.name
      ) {
        data.data.data.selectedCityInfo1 = {
          name: this.state.selectedCityInfo1.name
        };
        data.data.data.selectedCityInfo2 = {
          name: this.state.selectedCityInfo2.name
        };
      } else {
        data.data.data.selectedCityInfo1 = { name: null };
        data.data.data.selectedCityInfo2 = { name: null };
      }

      if (this.state.timeFormat) {
        data.data.data.timeFormat = this.state.timeFormat;
      }

      this.setState({
        result: data.data.data,
        loadStart: false
      });
    }
  }

  setCurrentDate = () => {
    const date = new Date();
    this.setState({
      dateStart: date,
      timeStart: date,
      hh: date.getHours(),
      mm: date.getMinutes(),
      ss: date.getSeconds(),
      classError: ""
    });
  };

  dateTimeChange(startEnd, val, event) {
    event.preventDefault();
    if (!isNaN(+event.target.value)) {
      this.focusControl(startEnd, val, event.target.value);
      // timeStart
      switch (event.target.id) {
        case "hh":
        case "hh2":
          // eslint-disable-next-line no-case-declarations
          let hour;
          if (this.state.timeFormat !== "AM/PM") {
            hour = +event.target.value <= 23 ? event.target.value : "23";
          } else hour = +event.target.value <= 11 ? event.target.value : "11";
          if (event.target.name === "start") {
            this.setState({
              hh: hour,
              classError: "",
              timeState: true
            });
          } else {
            this.setState({
              hh2: hour,
              classError2: "",
              timeState: true
            });
          }
          break;
        case "mm":
        case "mm2":
          // eslint-disable-next-line no-case-declarations
          let minute = +event.target.value <= 59 ? event.target.value : "59";
          if (event.target.name === "start") {
            this.setState({
              mm: minute,
              classError: "",
              timeState: true
            });
          } else {
            this.setState({
              mm2: minute,
              classError2: "",
              timeState: true
            });
          }
          break;
        case "ss":
        case "ss2":
          // eslint-disable-next-line no-case-declarations
          let second = +event.target.value <= 59 ? event.target.value : "59";
          if (event.target.name === "start") {
            this.setState({
              ss: second,
              classError: "",
              timeState: true
            });
          } else {
            this.setState({
              ss2: second,
              classError2: "",
              timeState: true
            });
          }
          break;
        default:
      }
      this.setState({
        errorNoNumberDate: { display: "none" },
        classError: "",
        classError2: ""
      });
    } else if (isNaN(+event.target.value)) {
      if (event.target.name === "start") {
        this.setState({
          errorNoNumberDate: { display: "block" },
          classError: "input-error"
        });
      } else {
        this.setState({
          errorNoNumberDate: { display: "block" },
          classError2: "input-error"
        });
      }
    }
    event.preventDefault();
  }

  setTimeState() {
    const { timeFormat, part1, part2 } = this.state;
    let today = new Date();
    let today2 = new Date();
    let hours1, hours2;
    if (timeFormat === "AM/PM") {
      hours1 = part1.value === "am" ? this.state.hh : +this.state.hh + 12;
      hours2 = part2.value === "am" ? this.state.hh2 : +this.state.hh2 + 12;
    } else {
      hours1 = this.state.hh;
      hours2 = this.state.hh2;
    }
    if (this.state.timeState) {
      today.setHours(hours1, this.state.mm, this.state.ss, 0);
      today2.setHours(hours2, this.state.mm2, this.state.ss2, 0);
      this.setState({
        timeStart: today,
        classError: "",
        timeEnd: today2,
        classError2: "",
        timeState: false
      });
    }
  }

  closeResult = () => {
    this.setState({
      style: { open: { display: "none" } },
      warning: { display: "none" },
      dateStart: undefined,
      timeStart: undefined,
      dateEnd: undefined,
      timeEnd: undefined,
      hh: "",
      mm: "",
      ss: "",
      hh2: "",
      mm2: "",
      ss2: "",
      classError: "",
      classError2: "",
      loadStart: false
    });
  };

  getDateResult(value) {
    if (this.state.result) {
      let tz1 = this.state.result.from.city.timezone;
      let tz2 = this.state.result.to.city.timezone;

      if (value === "from") {
        let dateFrom = moment(this.state.result.from.date).tz(tz1);
        dateFrom = dateFrom.format("dddd, LL");

        let timeFrom;
        if (this.state.result.checkboxTime === true) {
          timeFrom = moment(this.state.result.from.date).tz(tz1);
          if (this.state.result.timeFormat === "AM/PM") {
            timeFrom = timeFrom.format("LTS");
          } else {
            timeFrom = timeFrom.format("HH:mm:ss");
          }
        } else {
          timeFrom = "";
        }

        let cityFrom;
        if (this.state.result.checkboxTimezone) {
          cityFrom = `${this.state.result.selectedCityInfo1.name} ${moment(
            this.state.result.from.date
          )
            .tz(tz1)
            .format("z")}`;
        } else {
          cityFrom = "";
        }

        return `${dateFrom} ${timeFrom} ${cityFrom}`;
      } else {
        let dateTo = moment(this.state.result.to.date).tz(tz2);
        dateTo = dateTo.format("dddd, LL");

        let timeTo;
        if (this.state.result.checkboxTime === true) {
          timeTo = moment(this.state.result.to.date).tz(tz2);
          if (this.state.result.timeFormat === "AM/PM") {
            timeTo = timeTo.format("LTS");
          } else {
            timeTo = timeTo.format("HH:mm:ss");
          }
        } else {
          timeTo = "";
        }

        let cityTo;
        if (this.state.result.checkboxTimezone) {
          cityTo = `${this.state.result.selectedCityInfo2.name} ${moment(
            this.state.result.to.date
          )
            .tz(tz2)
            .format("z")}`;
        } else {
          cityTo = "";
        }

        return `${dateTo} ${timeTo} ${cityTo}`;
      }
    }
    let date, week_day;
    const week = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    const months = [
      "December",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    if (this.state.result) {
      if (value === "from") {
        date = this.state.result.from.date;
        week_day = this.state.result.from.week_day;
        return `${week[week_day]}, ${+date.substring(8, 10)}  ${
          months[+date.substring(6, 7)]
        }, ${date.substring(0, 4)}, ${date.substring(11, 19)}`;
      } else if (value === "to") {
        date = this.state.result.to.date;
        week_day = this.state.result.to.week_day;
        return `${week[week_day]}, ${+date.substring(8, 10)} ${
          months[+date.substring(6, 7)]
        }, ${date.substring(0, 4)}, ${date.substring(11, 19)}`;
      }
    }
  }

  getResult(value) {
    if (this.state.result) {
      let seconds = this.state.result.delta_seconds;
      if (
        this.state.result.checkboxTimezone ||
        this.state.result.checkboxTime
      ) {
        let days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        const mnts = Math.floor(seconds / 60);
        seconds -= mnts * 60;

        if (this.state.result.checkboxDateEnd) days += 1;

        return `${days} ${
          days === 1 ? "day" : "days"
        } ${hrs} hours, ${mnts} minutes, ${seconds} seconds`;
      } else if (!this.state.result.checkboxTime) {
        let days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hrs = Math.floor(seconds / 3600);
        if (hrs > 12) days += 1;

        if (this.state.result.checkboxDateEnd) days += 1;

        return `${days} ${days === 1 ? "day" : "days"}`;
      }
    }
  }

  startLoader() {
    if (!this.state.result && this.state.loadStart) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
  }
  timeFormat(format) {
    this.setState({
      timeFormat: format,
      hh: "",
      mm: "",
      ss: "",
      hh2: "",
      mm2: "",
      ss2: ""
    });
  }
  timeFormatStyle(format) {
    return format === this.state.timeFormat
      ? { background: "rgb(238, 149, 69)" }
      : { background: "#008854" };
  }
  updateCheck(checkbox) {
    if (checkbox === "checkboxDate") {
      this.setState(prevState => {
        return { checkboxDate: !prevState.checkboxDate };
      });
    } else if (checkbox === "checkboxTime") {
      this.setState(prevState => {
        return { checkboxTime: !prevState.checkboxTime };
      });
    } else if (checkbox === "checkboxTimezone") {
      this.setState(prevState => {
        return { checkboxTimezone: !prevState.checkboxTimezone };
      });
    }
  }
  searchTimePart1 = value => {
    this.setState({
      part1: value,
      hh: "",
      mm: "",
      ss: ""
    });
  };
  searchTimePart2 = value => {
    this.setState({
      part2: value,
      hh2: "",
      mm2: "",
      ss2: ""
    });
  };
  ampmShow() {
    if (this.state.timeFormat !== "AM/PM") return { display: "none" };
  }
  blockStyle(block) {
    if (this.state.checkboxTimezone === false && block === "timezone")
      return { display: "none" };
    else if (this.state.checkboxTime === false && block === "time")
      return { display: "none" };
  }
  render() {
    this.setTimeState();
    return (
      <div className="CalculatorBlock">
        {this.startLoader()}
        <div className="header">
          <h3>Date to date calculator</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <button className="btn active">Duration between two dates</button>
          <Link to={"/date-calculator"}>
            <button className="btn ">Add/Substract days to a date</button>
          </Link>
        </div>

        <div className="Calculator-wrapper">
          <div className="Calculator-content">
            <div className="Calculator">
              <div className="content">
                <h3 className="calculator_title">
                  Please enter start and end date information to calculate the
                  duration between them.
                </h3>
                <div className="content-info">
                  <div className="left-block">
                    <h3 className="calculator_title">Start Date</h3>
                    <h4 style={this.blockStyle("timezone")}>Timezone</h4>
                    <div
                      className="info-block"
                      style={this.blockStyle("timezone")}
                    >
                      <div className="image">
                        <i className="material-icons">timelapse</i>
                      </div>
                      <div className="info">
                        <section className="Search">
                          <div className="search_form">
                            <div className="search_form_select">
                              <input
                                className="search_field"
                                type="text"
                                value={this.state.selectedCity1}
                                placeholder={this.state.searchPlaceholder1.toUpperCase()}
                                onChange={this.searchValue.bind(this, 1)}
                                onMouseUp={this.searchFocus}
                                onFocus={this.searchListFocus.bind(this, 1)}
                              />
                              <ul
                                style={this.searchListOpen(1)}
                                className="search_form_list search_form_list1"
                              >
                                {this.state.cities.map((elem, index) => (
                                  <li key={index}>
                                    <div
                                      className="selectCityWrapper selectCityWrapper1"
                                      style={this.searchStyle(index)}
                                      onMouseDown={this.selectedCity.bind(
                                        this,
                                        elem.id,
                                        elem.name,
                                        elem.state,
                                        elem.county,
                                        elem.country_name,
                                        elem.feature,
                                        elem.index,
                                        1
                                      )}
                                      onClick={this.searchListHover.bind(
                                        this,
                                        index
                                      )}
                                      onMouseMove={this.searchMouseEventMouseOver.bind(
                                        this,
                                        index
                                      )}
                                    >
                                      <div className="mainNameWrapper">
                                        <b
                                          className="cityMainName"
                                          style={this.highlight(elem.highlight)}
                                        >
                                          {this.notEmptyName(elem.name)}
                                        </b>
                                        {this.notEmptyFeature(elem.feature)}
                                        <img
                                          className="cityMainImg"
                                          src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                                          alt="citydateandtime"
                                        />
                                      </div>
                                      {this.notEmptyState(elem.state)}
                                      {this.notEmptyState(elem.county)}
                                      {this.notEmptyName(elem.country_name)}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <h4>Date</h4>
                    <div className="info-block">
                      <div className="image">
                        <i className="material-icons">insert_invitation</i>
                        <MuiThemeProvider>
                          <DatePicker
                            className="calendar-date-picker"
                            hintText=" "
                            value={this.state.dateStart}
                            onChange={this.calendarChange.bind(this, "start")}
                            autoOk={true}
                            formatDate={
                              new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "long",
                                year: "numeric"
                              }).format
                            }
                          />
                        </MuiThemeProvider>
                      </div>
                      <div className="info">
                        <div className="calendar-date-picker-wrapper">
                          <div className="datetodate-row unix-row">
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="dateStart"
                                type="text"
                                name="start"
                                id="DD"
                                className={this.state.classError}
                                placeholder="DD"
                                value={this.dateShow("start", "DD")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "start",
                                  "day"
                                )}

                              ></input>
                            </div>
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="monthStart"
                                type="text"
                                name="start"
                                id="MM"
                                className={this.state.classError}
                                placeholder="MM"
                                value={this.dateShow("start", "MM")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "start",
                                  "month"
                                )}

                              ></input>
                            </div>
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="yearStart"
                                type="text"
                                name="start"
                                id="YYYY"
                                className={this.state.classError}
                                placeholder="YYYY"
                                value={this.dateShow("start", "YYYY")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "start",
                                  "year"
                                )}

                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 style={this.blockStyle("time")}>Time</h4>
                    <div className="info-block" style={this.blockStyle("time")}>
                      <div className="image">
                        <i className="material-icons">access_time</i>
                        <MuiThemeProvider>
                          <TimePicker
                            className="calendar-date-picker"
                            format={
                              this.state.timeFormat === "AM/PM"
                                ? "ampm"
                                : "24hr"
                            }
                            hintText=" "
                            value={this.state.timeStart}
                            onChange={this.timeChange.bind(this, "start")}
                          />
                        </MuiThemeProvider>
                      </div>
                      <div className="info">
                        <div className="datetodate-row unix-row">
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="hoursStart"
                              type="text"
                              name="start"
                              id="hh"
                              className={this.state.classError}
                              placeholder="hh"
                              value={this.state.hh}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "start",
                                "hours"
                              )}

                            ></input>
                          </div>
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="minutesStart"
                              type="text"
                              name="start"
                              id="mm"
                              className={this.state.classError}
                              placeholder="mm"
                              value={this.state.mm}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "start",
                                "minutes"
                              )}

                            ></input>
                          </div>
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="secondsStart"
                              type="text"
                              name="start"
                              id="ss"
                              className={this.state.classError}
                              placeholder="ss"
                              value={this.state.ss}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "start",
                                "seconds"
                              )}

                            ></input>
                          </div>
                          <div
                            className="input-date time_select"
                            style={this.ampmShow()}
                          >
                            <Select
                              styles={customStyleSelect()}
                              options={this.state.optionsPartTime}
                              clearable={false}
                              name="selected-state"
                              disabled={false}
                              value={this.state.part1}
                              onChange={this.searchTimePart1}
                              searchable={false}
                              placeholder={this.state.part1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="info-block">
                      <div className="info">
                        <div className="blockCurrentButton">
                          <button
                            className="currentButton"
                            onClick={this.setCurrentDate}
                          >
                            CURRENT DATE/TIME
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-block">
                    <h3 className="calculator_title">End Date</h3>
                    <h4 style={this.blockStyle("timezone")}>Timezone</h4>
                    <div
                      className="info-block"
                      style={this.blockStyle("timezone")}
                    >
                      <div className="image">
                        <i className="material-icons">timelapse</i>
                      </div>
                      <div className="info">
                        <section className="Search">
                          <div className="search_form">
                            <div className="search_form_select">
                              <input
                                className="search_field"
                                type="text"
                                value={this.state.selectedCity2}
                                placeholder={this.state.searchPlaceholder2.toUpperCase()}
                                onChange={this.searchValue.bind(this, 2)}
                                onMouseUp={this.searchFocus}
                                onFocus={this.searchListFocus.bind(this, 2)}
                              />
                              <ul
                                style={this.searchListOpen(2)}
                                className="search_form_list search_form_list2"
                              >
                                {this.state.cities.map((elem, index) => (
                                  <li key={index}>
                                    <div
                                      className="selectCityWrapper selectCityWrapper2"
                                      style={this.searchStyle(index)}
                                      onMouseDown={this.selectedCity.bind(
                                        this,
                                        elem.id,
                                        elem.name,
                                        elem.state,
                                        elem.county,
                                        elem.country_name,
                                        elem.feature,
                                        elem.index,
                                        2
                                      )}
                                      onClick={this.searchListHover.bind(
                                        this,
                                        index
                                      )}
                                      onMouseMove={this.searchMouseEventMouseOver.bind(
                                        this,
                                        index
                                      )}
                                    >
                                      <div className="mainNameWrapper">
                                        <b
                                          className="cityMainName"
                                          style={this.highlight(elem.highlight)}
                                        >
                                          {this.notEmptyName(elem.name)}
                                        </b>
                                        {this.notEmptyFeature(elem.feature)}
                                        <img
                                          className="cityMainImg"
                                          src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                                          alt="citydateandtime"
                                        />
                                      </div>
                                      {this.notEmptyState(elem.state)}
                                      {this.notEmptyState(elem.county)}
                                      {this.notEmptyName(elem.country_name)}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <h4>Date</h4>
                    <div className="info-block">
                      <div className="image">
                        <i className="material-icons">insert_invitation</i>
                        <MuiThemeProvider>
                          <DatePicker
                            className="calendar-date-picker"
                            hintText=" "
                            // hintText="Select Date"
                            value={this.state.dateEnd}
                            onChange={this.calendarChange.bind(this, "end")}
                            autoOk={true}
                            formatDate={
                              new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "long",
                                year: "numeric"
                              }).format
                            }
                          />
                        </MuiThemeProvider>
                      </div>
                      <div className="info">
                        <div className="calendar-date-picker-wrapper">
                          <div className="datetodate-row unix-row">
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="dateEnd"
                                type="text"
                                name="end"
                                id="hh2"
                                className={this.state.classError2}
                                placeholder="DD"
                                value={this.dateShow("end", "DD")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "end",
                                  "day"
                                )}

                              ></input>
                            </div>
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="monthEnd"
                                type="text"
                                name="end"
                                id="mm2"
                                className={this.state.classError2}
                                placeholder="MM"
                                value={this.dateShow("end", "MM")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "end",
                                  "month"
                                )}

                              ></input>
                            </div>
                            <div className="input-date">
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <input ref="yearEnd"
                                type="text"
                                name="end"
                                id="ss2"
                                className={this.state.classError2}
                                placeholder="YYYY"
                                value={this.dateShow("end", "YYYY")}
                                onChange={this.dateChange.bind(
                                  this,
                                  "end",
                                  "year"
                                )}

                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 style={this.blockStyle("time")}>Time</h4>
                    <div className="info-block" style={this.blockStyle("time")}>
                      <div className="image">
                        <i className="material-icons">access_time</i>
                        <MuiThemeProvider>
                          <TimePicker
                            className="calendar-date-picker"
                            format={
                              this.state.timeFormat === "AM/PM"
                                ? "ampm"
                                : "24hr"
                            }
                            hintText=" "
                            value={this.state.timeEnd}
                            onChange={this.timeChange.bind(this, "end")}
                          />
                        </MuiThemeProvider>
                      </div>
                      <div className="info">
                        <div className="datetodate-row unix-row">
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="hoursEnd"
                              type="text"
                              name="end"
                              id="hh2"
                              className={this.state.classError2}
                              placeholder="hh"
                              value={this.state.hh2}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "end",
                                "hours"
                              )}

                            ></input>
                          </div>
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="minutesEnd"
                              type="text"
                              name="end"
                              id="mm2"
                              className={this.state.classError2}
                              placeholder="mm"
                              value={this.state.mm2}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "end",
                                "minutes"
                              )}

                            ></input>
                          </div>
                          <div className="input-date">
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <input ref="secondsEnd"
                              type="text"
                              name="end"
                              id="ss2"
                              className={this.state.classError2}
                              placeholder="ss"
                              value={this.state.ss2}
                              onChange={this.dateTimeChange.bind(
                                this,
                                "end",
                                "seconds"
                              )}

                            ></input>
                          </div>
                          <div
                            className="input-date time_select"
                            style={this.ampmShow()}
                          >
                            <Select
                              styles={customStyleSelect()}
                              options={this.state.optionsPartTime}
                              clearable={false}
                              name="selected-state"
                              disabled={false}
                              value={this.state.part2}
                              onChange={this.searchTimePart2}
                              searchable={false}
                              placeholder={this.state.part2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="options">
                  <div className="time-format">
                    <div
                      className="format"
                      style={this.timeFormatStyle("AM/PM")}
                      onClick={this.timeFormat.bind(this, "AM/PM")}
                    >
                      <span>am</span>
                      <span>pm</span>
                    </div>
                    <div
                      className="format"
                      style={this.timeFormatStyle("24")}
                      onClick={this.timeFormat.bind(this, "24")}
                    >
                      <span>24</span>
                    </div>
                  </div>
                  <MuiThemeProvider>
                    <Checkbox
                      label="Include end date"
                      checked={this.state.checkboxDate}
                      onCheck={this.updateCheck.bind(this, "checkboxDate")}
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Checkbox
                      label="Include time"
                      checked={this.state.checkboxTime}
                      onCheck={this.updateCheck.bind(this, "checkboxTime")}
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <Checkbox
                      label="Include timezone-city"
                      checked={this.state.checkboxTimezone}
                      onCheck={this.updateCheck.bind(this, "checkboxTimezone")}
                      // style={styles.checkboxTimezone}
                    />
                  </MuiThemeProvider>
                </div>
                <b style={this.state.calculationError}>
                  Fill in all the fields
                </b>
              </div>
              <div className="footer">
                <button
                  className="start-calculation"
                  onClick={this.startCalculation}
                >
                  CALCULATE
                </button>
                <button className="close-button" onClick={this.closeResult}>
                  RESET
                </button>
              </div>
              <div className="time-result" style={this.state.style.open}>
                <b>Duration</b>
                <div
                  className="blockDateISO datetodate-warning"
                  style={this.state.warning}
                >
                  <p>Warning !</p>
                  <p>
                    The Start date was after the End date, so the Start and End
                    fields were swapped.
                  </p>
                </div>
                <div className="blockDateISO time-show">
                  <div className="fromISO">
                    <p>
                      <b>From:</b> <i>{this.getDateResult("from")}</i>
                    </p>
                    <p>
                      <b>To:</b> <i>{this.getDateResult("to")}</i>
                    </p>
                  </div>
                  <div className="hr-self"></div>
                  <div className="resultISO">
                    <p>
                      <b>Result:</b> <i>{this.getResult()}</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Ads />
          </div>
          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flightTimeInfoReducer: state.flightTimeInfoReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    flightTimeInfo: bindActionCreators(flightTimeInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
