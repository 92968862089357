const initState = {
  planet: '',
  period: 'Next 10 Years',
  periodes: [],
  retrogradeList: []
}
const planetesRetrogradeReducer = (state = initState, action) => {
  switch (action.type) {
    case 'PLANETESRETROGRADE':
      return action.data;
    default:
      return state;
  }
};
  
export default planetesRetrogradeReducer;