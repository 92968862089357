import React, { Component } from "react";

import Calculator from "./Calculator/Calculator";

import "./FlightTimeCalculator.css";

class FlightTimeCalculator extends Component {
  render() {
    return (
      <div className="FlightTimeCalculator">
        <Calculator />
      </div>
    );
  }
}

export default FlightTimeCalculator;
