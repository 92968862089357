import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Swiper, Slide } from 'react-dynamic-swiper/lib'
import 'react-dynamic-swiper/lib/styles.css';
import moment from 'moment-timezone/index.js';

import './MoonRiseDayParts.css';
import moonCalc from '../../../../../../actions/action_moonRise/action_moonCalc';
import moonRiseDate from "../../../../../../actions/action_moonRise/action_moonRiseDate";
import moonRisePosition from "../../../../../../actions/action_moonRise/action_moonRisePosition";
import moonRiseCoords from "../../../../../../actions/action_moonRise/action_moonRiseCoords";

import Api from '../../../../../../services/api.js';

class MoonRiseDayParts extends Component {
    state = {
        options: {
            navigation: true,
            pagination: true,
            scrollBar: false,
        }
    };
    settings = {
        paginationClickable: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
        noSwiping: true,
        breakpoints: {
            1439: {
              slidesPerView: 6,
              slidesPerGroup: 6
            },
            1299: {
              slidesPerView: 5,
              slidesPerGroup: 5
            },
            1099: {
              slidesPerView: 4,
              slidesPerGroup: 4
            },
            991: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            649: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            499: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            349: {
              slidesPerView: 1,
              slidesPerGroup: 1
            }
        }
    };
    nightPartsTime(time){
        const { moonCalcReducer } = this.props;
        return time ? moment(time).tz(moonCalcReducer.timezone).format('LT') : 'N/A';
    }
    async dayPeriodClick(elem){
        const { moonCalcReducer, moonCalc, moonRiseDate, moonRiseCoords, moonRisePosition } = this.props;
        const time = moment(elem).tz(moonCalcReducer.timezone).format('YYYY-MM-DDTHH:mm:ss');
        moonRiseDate(moment(time).format('YYYY-MM-DDTHH:mm:ss'));
        const moonCoordinats = await Api.userCityList(`geo_names/${moonCalcReducer.id}/?moon_app=true&date=${time}`);
        moonCalc(moonCoordinats.data);

        const hourCurrent = moment(moonCoordinats.data.time).tz(moonCoordinats.data.timezone).get('hour');
        const minuteCurrent = moment(moonCoordinats.data.time).tz(moonCoordinats.data.timezone).get('minute');
        for(let key in moonCoordinats.data.moon_data.position_during_day){         
            const hourDot = moment(moonCoordinats.data.moon_data.position_during_day[key].loc_time).tz(moonCoordinats.data.timezone).get('hour');
            const minuteDot = moment(moonCoordinats.data.moon_data.position_during_day[key].loc_time).tz(moonCoordinats.data.timezone).get('minute');
            if(hourDot === hourCurrent && minuteDot === minuteCurrent) {
                moonRiseCoords(+key)
                moonRisePosition(moonCoordinats.data.moon_data.position_during_day[+key]);
            }
        }
    }
    render () {
        const { moonCalcReducer } = this.props;
        return (
            <Swiper
                className="SunRiseDayParts MoonRiseDayParts swiper-no-swiping"
                swiperOptions={this.settings}{...this.state.options}
                nextButton={swiper => 
                    <div className="swiper-button-next">
                        <i className="material-icons" onClick={() => swiper.slideNext()} >chevron_right</i>
                    </div>
                }
                prevButton={swiper =>
                    <div className="swiper-button-prev">
                        <i className="material-icons" onClick={() => swiper.slidePrev()}>chevron_left</i>
                    </div>
                }
            >
                {moonCalcReducer.moon_data.night_parts.map((elem, index) => (
                    <Slide className="day_parts" key={index} onClick={this.dayPeriodClick.bind(this, elem.time)}>
                        <h5>{elem.name}</h5>
                        <span>
                            {this.nightPartsTime(elem.time)}
                            <span style={{ background: '#538b88' }}></span>
                        </span>
                        <div className="day_parts_info">
                            <span>Altitude {Math.round(elem.alt)}&deg;</span>
                            <span>Azimuth {Math.round(elem.az)}&deg; {elem.rhumb}</span>
                        </div>
                    </Slide>
                ))}
            </Swiper>
        )
    }
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer
	};
};
const mapDispatchToProps = dispatch => {
    return {
        moonCalc: bindActionCreators(moonCalc, dispatch),
        moonRiseDate: bindActionCreators(moonRiseDate, dispatch),
        moonRisePosition: bindActionCreators(moonRisePosition, dispatch),
        moonRiseCoords: bindActionCreators(moonRiseCoords, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoonRiseDayParts);