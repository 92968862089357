import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DatePicker, MuiThemeProvider} from "material-ui";

import './ComponentDateEvent.css';

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear());
    minDate.setHours(0, 0, 0, 0);

class ComponentDateEvent extends Component {
    state = {
        minDateStart: minDate,
        dateStart: '',
        hh: '',
        mm: '',
        ss: '',
        passDateEvent: '',
    }
    calendarChange(startEnd, event, date) {
        this.setState(prevState => {
            if(startEnd === 'start') return { dateStart: date };
            else return { dateEnd: date }
        });
        this.setDateEvent();
    }
    dateTimeChange = (event) => {
        event.preventDefault();
        //console.log('---->', event.target.name, event.target.placeholder, event.target.value);
        if(!isNaN(+event.target.value)){
            switch (event.target.id) {
              case 'hh':
                  // eslint-disable-next-line no-case-declarations
                  let hour = +event.target.value <= 23 ? event.target.value : '23';
                      this.setState({
                          hh: hour,
                          classError: '',
                          timeState: true,
                      });
                break;
              case 'mm':
                  // eslint-disable-next-line no-case-declarations
                let minute = +event.target.value <= 59 ? event.target.value : '59';
                      this.setState({
                          mm: minute,
                          classError: '',
                          timeState: true,
                      });
                break;
              case 'ss':
                  // eslint-disable-next-line no-case-declarations
                let second = +event.target.value <= 59 ? event.target.value : '59';
                      this.setState({
                          ss: second,
                          classError: '',
                          timeState: true,
                      });
                break;
              default:
                this.setState({
                    ss: 'second',
                    classError: '',
                    timeState: false,
                });
                break;
            }
            this.setState({
                errorNoNumberDate: {display: 'none'},
                classError: '',
                classError2: '',
            })
        }else if(isNaN(+event.target.value)){
                this.setState({
                    errorNoNumberDate: {display: 'block'},
                    classError: 'input-error',

                })
        }
        this.setDateEvent();
    };
    setDateEvent(){
        let year, mounth, date, hh, mm, ss, passDateEvent;
        if (this.state.dateStart ){
            year = this.state.dateStart.getFullYear();
            mounth = this.state.dateStart.getMonth();
            date = this.state.dateStart.getDate();
            hh = +this.state.hh ? +this.state.hh : '00';
            mm = +this.state.mm ? +this.state.mm : '00';
            ss = +this.state.ss ? +this.state.ss : '00';
            passDateEvent = `${year}-${mounth}-${date}T${hh}:${mm}:${ss}`;
            this.setState({
                passDateEvent,
            })
            this.props.setDateEventRedux(passDateEvent)
        }else{
            year = '';
            mounth = '';
            date = '';
            hh = '';
            mm = '';
            ss = '';
        }
    }

    render() {
        return (
            <div className="block-countdown-comp">
                <p className="title-countdown-comp">Countdown date event:</p>
                <div className='speace-date-event'></div>
                <div className='info-block '>
                    <div className="row-block-event">
                        <div className='image'>
                            <i className="material-icons">insert_invitation</i>
                        </div>
                        <div className='info'>
                            <h5>Event Date</h5>
                            <div className='calendar-date-picker-wrapper'>
                                <MuiThemeProvider>
                                    <DatePicker
                                        minDate={this.state.minDateStart}
                                        className="calendar-date-picker"
                                        hintText="Select Date"
                                        value={this.state.dateStart !== '' ? this.state.dateStart : null}
                                        onChange={this.calendarChange.bind(this, 'start')}
                                        autoOk={true}
                                        formatDate={new Intl.DateTimeFormat('en-US', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                        }).format}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                     </div>
                </div>
                <div className='info-block '>
                    <div className="row-block-event">
                        <div className='image'>
                            <i className="material-icons">access_time</i>
                        </div>
                        <div className='info'>
                            <h5>Event Time</h5>
                            <div className="row-block-event">
                                <div className='input-date'>
                                     <input  name='start' id='hh' className={this.state.classError} placeholder="hh" value={this.state.hh} onChange={this.dateTimeChange}></input>
                                </div>
                                <div className='input-date'>
                                     <input  name='start' id='mm' className={this.state.classError} placeholder="mm" value={this.state.mm} onChange={this.dateTimeChange}></input>
                                </div>
                                <div className='input-date'>
                                     <input  name='start' id='ss' className={this.state.classError} placeholder="ss" value={this.state.ss} onChange={this.dateTimeChange}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        countdownReducer: state.countdownReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
         setDateEventRedux: (dateEvent) => {
            //console.log('name', name)
            const payload = {
                dateEvent
          };
          dispatch({ type: 'PASS_DATE_EVENT', payload });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentDateEvent);