import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import { Link } from "react-router-dom";

import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import BlockSettings from "./BlockSettings/BlockSettings";
import Loader from "../../../../../components/Main/Loader/Loader";
import SimpleDatePicker from "../../../Calculators/DateCalculator/Calculator/SimpleDatePicker";
import Ads from "../../Ads/Ads";

import Api from "../../../../../services/api.js";
import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";
import "./Calculator.css";

class Calculator extends Component {
  state = {
    result: "",
    in_ex_daysText: "",
    id: this.props.currentSearchReducer.id,
    style: {
      open: { display: "none" }
    },
    warning: false,
    loadStart: false,
    classError: "",
    classError2: "",
    timeState: false,
    businesDTDReducer: this.props.businesDTDReducer,
    currentDate: "",
    activeCalcClass: "",
    calculationError: { display: "none" },
    reset: false
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({
      businesDTDReducer: nextProps.businesDTDReducer,
      id: nextProps.currentSearchReducer.id
    });
  }

  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;
    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
    }

    this.setState(prevState => {
      return { id: this.props.currentSearchReducer.id };
    });
  }

  calendarChange(startEnd, event, date) {
    this.setState(prevState => {
      if (startEnd === "start") return { dateStart: date };
      else return { dateEnd: date };
    });
  }

  timeChange(startEnd, event, time) {
    this.setState(prevState => {
      if (startEnd === "start") return { timeStart: time };
      else return { timeEnd: time };
    });
  }

  startCalculation = () => {
    if (this.state.dateStart && this.state.dateEnd) {
      this.setState({ loadStart: true });

      const startFormat = moment(this.state.dateStart).format("YYYY-MM-DD");
      const endFormat = moment(this.state.dateEnd).format("YYYY-MM-DD");

      let msUTCStart = Date.parse(startFormat);
      let msUTCStart2 = Date.parse(endFormat);
      if (msUTCStart > msUTCStart2) {
        this.caslculationRequest(endFormat, startFormat);
        this.setState({
          dateStart: this.state.dateEnd,
          timeStart: this.state.timeEnd,
          dateEnd: this.state.dateStart,
          timeEnd: this.state.timeStart,
          warning: true
        });
      } else {
        this.caslculationRequest(startFormat, endFormat);
        this.setState({
          warning: false
        });
      }
      this.setState(prevState => {
        return {
          calculationError: { display: "none" },
          style: { open: { display: "block" } },
          loadStart: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          calculationError: { display: "block" },
          classError: "input-error",
          classError2: "input-error"
        };
      });
    }
  };

  async caslculationRequest(startFormat, endFormat) {
    const { id, businesDTDReducer } = this.state;
    let week = businesDTDReducer.standard
      ? businesDTDReducer.standard.exclude_weekends
      : false;
    let holi = businesDTDReducer.standard
      ? businesDTDReducer.standard.exclude_holidays
      : false;
    let in_ex_days = businesDTDReducer.custom
      ? `&in_ex_days=${businesDTDReducer.custom.in_ex_days}`
      : "";
    let public_holidays = businesDTDReducer.custom
      ? `&public_holidays=${businesDTDReducer.custom.public_holidays}`
      : "";
    let data;

    let in_ex_daysText = businesDTDReducer.custom
      ? businesDTDReducer.custom.in_ex_days
      : "";

    if (businesDTDReducer.typeRQ === "CUSTOM SETTINGS") {
      data = await Api.userCityList(
        `work_days/?dt=${startFormat.substring(
          0,
          10
        )}&dt2=${endFormat.substring(
          0,
          10
        )}&geo_name=${id}&weekends=${week}&public_holidays=${holi}`
      );
    } else {
      data = await Api.userCityList(
        `work_days/?dt=${startFormat.substring(
          0,
          10
        )}&dt2=${endFormat.substring(
          0,
          10
        )}&geo_name=${id}${in_ex_days}${this.getCustomWeekDays()}${public_holidays}`
      );
    }

    this.setState({
      result: data.data.data,
      loadStart: false,
      activeCalcClass: "active",
      reset: false,
      in_ex_daysText: in_ex_daysText
    });
  }

  getCustomWeekDays() {
    const { businesDTDReducer } = this.state;
    let exclude_days = businesDTDReducer.custom
      ? businesDTDReducer.custom.exclude_days
      : [];
    let arr = exclude_days.map(item => {
      return `&custom_week_days=${item}`;
    });
    return arr.join("");
  }

  setCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    if (this.state.currentDate === "") {
      this.setState({
        dateStart: date,
        timeStart: date,
        selectedCurrentDate: true
      });
    }
  };

  setTimeState() {
    let today = new Date();
    let today2 = new Date();
    if (this.state.timeState) {
      today.setHours(this.state.hh, this.state.mm, this.state.ss, 0);
      today2.setHours(this.state.hh2, this.state.mm2, this.state.ss2, 0);
      this.setState({
        timeStart: today,
        classError: "",
        timeEnd: today2,
        classError2: "",
        timeState: false
      });
    }
  }

  closeResult = () => {
    this.setState({
      style: { open: { display: "none" } },
      warning: false,
      dateStart: "",
      timeStart: "",
      dateEnd: "",
      timeEnd: "",
      classError: "",
      classError2: "",
      loadStart: false,
      activeCalcClass: "",
      reset: true
    });
  };

  getDateResult(value) {
    let date;
    if (this.state.result) {
      if (value === "from") {
        date = this.state.result.from.date;
        return `${moment(date).format(" dddd, MMMM Do YYYY")}`;
      } else if (value === "to") {
        date = this.state.result.to.date;
        return `${moment(date).format(" dddd, MMMM Do YYYY")}`;
      }
    } else return ``;
  }

  getExclud() {
    if (this.state.result) {
      const { weekends, public_holidays } = this.state.result;
      if (weekends && public_holidays) return `Weekends and Holidays`;
      else if (weekends) return `Weekends`;
      else if (public_holidays) return `Holidays`;
      else return `No days`;
    } else return `No days`;
  }

  getAllAndSkippedDays() {
    if (this.state.result) {
      const { calendar_days, skipped } = this.state.result;

      const calendarDays = calendar_days
        ? `${calendar_days} calendar days `
        : "";
      const skipp = skipped ? `- ${+skipped} days skipped` : "";
      return `${calendarDays}${skipp}`;
    } else return `No days`;
  }

  showExcludedWeekHoli(status) {
    const { weekends, public_holidays, result } = this.state.result;
    switch (status) {
      case "weeks":
        // eslint-disable-next-line no-case-declarations
        let saturdayCount = 0,
          sundayCount = 0,
          saturday,
          sunday;
        if (weekends) {
          weekends.days.forEach(item => {
            if (item.week_day === 5) ++saturdayCount;
            if (item.week_day === 6) ++sundayCount;
          });
          saturday = `${saturdayCount} Saturday. `;
          sunday = `${sundayCount} Sunday`;
        }
        saturday = `${saturdayCount} Saturday. `;
        sunday = `${sundayCount} Sunday`;
        if (saturdayCount !== 0 && sundayCount !== 0)
          return `Excluded ${saturday}${sunday}`;
        break;
      case "holidays":
        if (public_holidays)
          return `Excluded ${public_holidays.days.length} holidays:`;
        break;
      case "list_holidays":
        if (public_holidays) {
          return public_holidays.days.map(item => (
            <li key={item.external_id}>
              {item.title} ({moment(item.start_date).format("ll")})
            </li>
          ));
        }
        break;
      case "resultDay":
        return `${result} days`;

      default:
        return "";
    }
  }

  showExcludedCustomWeek(in_ex_days) {
    const { custom_week_days } = this.state.result;

    const week = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    let arrRender = [];
    if (custom_week_days) {
      if (custom_week_days.count !== 0)
        for (let key in custom_week_days) {
          if (key !== "count" && key !== "days") {
            arrRender.push(
              `${in_ex_days.charAt(0).toUpperCase() + in_ex_days.substr(1)} ${
                custom_week_days[key]
              } ${week[key]}`
            );
          }
        }
    }
    return arrRender.map(item => (
      <div key={item} className="title_result_content">
        <p>{item}</p>
      </div>
    ));
  }

  setDateStartFromChild(date, status) {
    this.setState(prevState => {
      if (status === "start") {
        return {
          dateStart: date,
          timeStart: date,
          selectedCurrentDate: true,
          reset: false
        };
      } else {
        return {
          dateEnd: date,
          timeEnd: date,
          selectedCurrentDate: true,
          reset: false
        };
      }
    });
  }

  startLoader() {
    if (!this.state.result && this.state.loadStart) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
  }

  render() {
    this.setTimeState();
    return (
      <div className="CalculatorBlock">
        {this.startLoader()}
        <div className="header">
          <h3>Business date to date calculator</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <button className="btn active">Duration between two dates</button>
          <Link to={"/business-date-calculator/"}>
            <button className="btn ">Add/Subtract days to a date</button>
          </Link>
        </div>

        <div className="Calculator-wrapper">
          <div className="Calculator-content">
            <div className="Calculator">
              <div className="content">
                <span className="calculator_title">
                  Please select start and end dates to calculate how much years,
                  months, weeks and dates are between: <br />
                  You can also exclude: weekends, holidays, or make custom own
                  choice.
                </span>
                <div className="content-info">
                  <div className="left-block ">
                    <div className="info-block">
                      <span className="calculator_title block_title">
                        Start Date
                      </span>
                    </div>
                    <SimpleDatePicker
                      getDate={e => this.setDateStartFromChild(e, "start")}
                      date={this.state.dateStart} // required parameter
                      reset={this.state.reset} // required parameter
                    />
                  </div>
                  <div className="right-block">
                    <div className="info-block">
                      <span className="calculator_title block_title">
                        End Date
                      </span>
                    </div>
                    <SimpleDatePicker
                      getDate={e => this.setDateStartFromChild(e, "end")}
                      date={this.state.dateEnd} // required parameter
                      reset={this.state.reset} // required parameter
                    />
                  </div>
                </div>
              </div>
              <BlockSettings />
              <div className="row_select_buttom">
                <button
                  className={`btn w-197p ${this.state.activeCalcClass}`}
                  onClick={this.startCalculation}
                >
                  CALCULATE
                </button>
                <button
                  className={`btn w-197p ${this.state.activeResetClass}`}
                  onClick={this.closeResult}
                >
                  RESET
                </button>
                <b style={this.state.calculationError}>
                  Fill in all the fields
                </b>
              </div>

              {this.state.warning && (
                <div className="blockDateISO datetodate-warning">
                  <div className="resultISO">
                    <p>Warning !</p>
                    <br />
                  </div>
                  <div className="hr-self"></div>
                  <div className="fromISO">
                    <p>
                      The Start date was after the End date, so the Start and
                      End fields were swapped
                    </p>
                  </div>
                </div>
              )}
              <div style={this.state.style.open}>
                <div className="row_result">
                  <div className="title_result">
                    Business date to date calculator
                  </div>
                  <div className="content_result">
                    <div className="title_result_content">
                      <span>From: </span>&nbsp;{" "}
                      <p> {this.getDateResult("from")}</p>
                    </div>
                    <div className="title_result_content">
                      <span>To: </span>&nbsp;<p> {this.getDateResult("to")}</p>
                    </div>
                    <div className="title_result_content">
                      <p>{this.getAllAndSkippedDays()}</p>
                    </div>
                    {this.showExcludedCustomWeek(this.state.in_ex_daysText)}
                    <div className="title_result_content">
                      <p>{this.showExcludedWeekHoli("weeks")}</p>
                    </div>
                    <div className="title_result_content">
                      <p>{this.showExcludedWeekHoli("holidays")}</p>
                    </div>
                    <div className="title_result_content">
                      <ul>{this.showExcludedWeekHoli("list_holidays")}</ul>
                    </div>
                  </div>
                  <div className="title_result_below">
                    <span>Result:</span>&nbsp;
                    <p>{this.showExcludedWeekHoli("resultDay")}</p>
                  </div>
                </div>
              </div>
            </div>
            <Ads />
          </div>
          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    businesDTDReducer: state.businesDTDReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
