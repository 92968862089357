import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

class Choghadiya extends Component {
  state = { dataFromUrl: {} };

  UNSAFE_componentWillMount() {
    // UNSAFE_componentWillMount only for this.parseUrl
    this.parseUrl();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise ||
      this.state.dataFromUrl.city !== nextState.dataFromUrl.city
    );
  }

  componentDidUpdate() {
    const cityFromUrl = this.capitalizeWholeString(
      window.location.pathname.split("/")[4].replace(/-/g, " ")
    );
    if (
      this.state.dataFromUrl.city &&
      this.state.dataFromUrl.city !== cityFromUrl
    ) {
      this.parseUrl();
    }
  }

  parseUrl = () => {
    const parsedUrl = window.location.pathname.split("/");
    const parsedCountry = parsedUrl[2];
    const currentCountry =
      parsedCountry === "usa"
        ? parsedCountry.toUpperCase()
        : this.capitalizeString(parsedCountry.replace(/-/g, " "));
    const currentCity = this.capitalizeWholeString(
      parsedUrl[4].replace(/-/g, " ")
    );
    const data = {
      country: currentCountry,
      city: currentCity,
    };
    this.setState({ dataFromUrl: data });
  };

  capitalizeString = (str) => str[0].toUpperCase() + str.slice(1);

  capitalizeWholeString = (str) => {
    const strArray = str.split(" ");
    const capitalizeStr = strArray.map(
      (item) => item[0].toUpperCase() + item.slice(1)
    );
    return capitalizeStr.join(" ");
  };

  renderTableRow(arr) {
    const { timezone } = this.props.currentSearchReducer;
    let newArr = [...arr];
    return newArr.map((obj, index) => {
      let background = "white";
      switch (obj.type) {
        case "Inauspicious":
          background = "#dc3023";
          break;
        case "Good":
          background = "#22a7f0";
          break;
        case "Auspicious":
          background = "#26a65b";
          break;
        default:
          background = "white";
      }

      let color = "white";
      let text = "";
      if (obj.subtype !== "") {
        color = "black";
        text = ` - ${obj.subtype}`;
      }

      return (
        <div
          key={obj.name + index}
          className="row_table"
          style={{ background }}
        >
          <div className="col_table" style={{ color }}>
            <h5>
              {obj.name}
              <span className="subtype">{text}</span>
            </h5>
          </div>
          <div className="col_table" style={{ color }}>
            <h5>
              {moment(obj.start).tz(timezone).format("hh:mm A")} -{" "}
              {moment(obj.end).tz(timezone).format("hh:mm A")}
            </h5>
          </div>
          <div className="col_table" style={{ color }}>
            <h5>{obj.type}</h5>
          </div>
        </div>
      );
    });
  }

  renderBelievedName(arr) {
    let newArr = [...arr];
    let text = newArr.reduce((accum, item) => {
      if (item.subtype !== "") accum.push(item.subtype);
      return accum;
    }, []);
    return text.join(", ");
  }

  renderBelieved(arr) {
    let text = "";
    arr.forEach((item) => {
      if (item.subtype !== "")
        text = "It is believed that no auspicious work should be done during";
    });
    if (text !== "")
      return (
        <p>
          {text} {this.renderBelievedName(arr)}.
        </p>
      );
  }

  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    return `${name}, ${state} ${country}`;
  }

  render() {
    const { dataFromUrl } = this.state;
    const { day_choghadiya, night_choghadiya } = this.props.panchang;
    return (
      <div className="constellation">
        <div className="row_center pt-2">
          <h4>Choghadiya for {this.cityName()}</h4>
          <div className="row_choghadiya">
            <div className="choghadiya_col">
              <div className="title day">
                <h5>Day Choghadiya</h5>
              </div>
              {this.renderTableRow(day_choghadiya)}
            </div>
            <div className="choghadiya_col ">
              <div className="title night">
                <h5>Night Choghadiya</h5>
              </div>
              {this.renderTableRow(night_choghadiya)}
            </div>
          </div>
        </div>
        <div className="describe pt-2">
          {/*{this.renderBelieved([...day_choghadiya, ...night_choghadiya])}*/}
          <p>
            It is believed that no auspicious work should be done during Kaal
            Vela, Vaar Vela and Kaal Ratri.
          </p>
        </div>
        <div className="describe pt-2">
          <h4 className="row_center pt-2">
            Panchang for {`${dataFromUrl.city}, ${dataFromUrl.country}`}
          </h4>
          <p className="pt-1 longer">
            Panchang is a Hindu calendar and Almanac. The above Panchang
            contains five angas or parts of information: lunar day (tithi),
            solar day (vara), asterism (nakshatra), planetary joining (yoga) and
            astronomical period (karana) for{" "}
            {`${dataFromUrl.city}, ${dataFromUrl.country}`}. According to
            Panchang, the day for{" "}
            {`${dataFromUrl.city}, ${dataFromUrl.country}`} is calculated from
            sunrise to next day sunrise. Panchang calculations require exact
            planetary positions and precise daily motions of Sun (Ravi), Moon
            (Chandra) and other heavenly bodies for{" "}
            {`${dataFromUrl.city}, ${dataFromUrl.country}`}. Ancient sages
            established the relationship between planetary movements and their
            influence on an individual, predicting auspicious and inauspicious
            time to perform various activities in daily life and to check
            various Hindu festivals. It is used to synchronize our actions with
            good times so as to reduce obstacles and increase our chances for
            success.
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(Choghadiya);
