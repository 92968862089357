import React, { Component } from "react";
import Moment from "moment-timezone";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

class ChartItem extends Component {
	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		this.forceUpdate();
	};

	render() {
		const {
			exchangeName,
			exchangeStartTime,
			exchangeEndTime,
			userTime,
			selectedDate,
			positionFromStart,
			handleGetStartExchangeTime,
			handleGetTimeRange
		} = this.props;

		const chartHour = document.querySelector(".timetable span");
		const chartHourWidth = chartHour
			? parseFloat(window.getComputedStyle(chartHour).width)
			: 46.25;

		return (
			<p
				className={
					moment(userTime).isBetween(
						exchangeStartTime,
						exchangeEndTime
					) &&
					(moment(selectedDate).day() !== 0 &&
						moment(selectedDate).day() !== 6)
						? "timetable_item timetable_item_active"
						: "timetable_item"
				}
				style={{
					width: positionFromStart
						? `calc(${chartHourWidth}px * ${handleGetStartExchangeTime(
							userTime,
							exchangeStartTime
						) +
						handleGetTimeRange(
							exchangeStartTime,
							exchangeEndTime
						) -
						24})`
						: `calc(${chartHourWidth}px * ${handleGetTimeRange(
							exchangeStartTime,
							exchangeEndTime
						)})`,
					// left: width of calendar day * time of event start
					left: positionFromStart
						? 0
						: `calc(${chartHourWidth}px * ${handleGetStartExchangeTime(
							userTime,
							exchangeStartTime
						)})`
				}}
				title={exchangeName}
			>
				{exchangeName}
			</p>
		);
	}
}

export default ChartItem;
