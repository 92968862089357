import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { Link } from 'react-router-dom';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import ReactAnimatedWeather from "react-animated-weather";
import moment from "moment-timezone";
import Toggle from "material-ui/Toggle";

import "./WeatherBlock.css";
// import weatherLogo from '../../../../../assets/Weather/weather_logo.png';
// import partlyCloudy from '../../../../../assets/Weather/SVG/Cloud.svg';
import Loader from "../../../../../../components/Main/Loader/Loader";
import windDirection from "../../../../../../assets/Weather/Wind0.png";
import humidity from "../../../../../../assets/Weather/Humidity.png";
import weatherInfo from "../../../../../../actions/action_weatherInfo";

import Api from "../../../../../../services/api.js";

class WeatherBlock extends Component {
  state = {
    temperatureIsFahrenheit: true
  };
  async componentDidMount() {
    const { currentSearchReducer, weatherInfo } = this.props;
    // // const data = await Api.weatherInfo(currentSearchReducer.latitude, currentSearchReducer.longitude);
    // // // const data = await Api.weatherInfo('37.8267', '-122.4233');

    if (currentSearchReducer.expand_weather === true) {
      const weather = await Api.userCityList(
        `weather/${currentSearchReducer.latitude},${currentSearchReducer.longitude}/`
      );
      weatherInfo(weather.data);
    }
  }

  handleToggleTemperatureUnits = () => {
    this.setState({
      temperatureIsFahrenheit: !this.state.temperatureIsFahrenheit
    });
  };

  toCelsius = value => {
    return ((value - 32) / 1.8).toFixed(0);
  };

  windBearing(bearing) {
    if (348.75 < bearing && bearing <= 11.25) return "N";
    else if (11.25 < bearing && bearing <= 37.5) return "NNE";
    else if (37.5 < bearing && bearing <= 56.25) return "NE";
    else if (56.25 < bearing && bearing <= 78.75) return "ENE";
    else if (78.75 < bearing && bearing <= 101.25) return "E";
    else if (101.25 < bearing && bearing <= 123.75) return "ESE";
    else if (123.75 < bearing && bearing <= 146.25) return "SE";
    else if (146.25 < bearing && bearing <= 168.75) return "SSE";
    else if (168.75 < bearing && bearing <= 191.25) return "S";
    else if (191.25 < bearing && bearing <= 213.75) return "NNE";
    else if (11.25 < bearing && bearing <= 236.25) return "SW";
    else if (236.25 < bearing && bearing <= 258.75) return "WSW";
    else if (258.75 < bearing && bearing <= 281.25) return "W";
    else if (281.25 < bearing && bearing <= 303.75) return "WNW";
    else if (303.75 < bearing && bearing <= 326.25) return "NW";
    else if (326.25 < bearing && bearing <= 348.75) return "NNW";
  }
  timeOfDay() {
    const { weatherInfoReducer } = this.props;
    const time = moment().tz(weatherInfoReducer.timezone);
    const zero = time
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .format("x");
    const six = time
      .set("hour", 6)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .format("x");
    const twelve = time
      .set("hour", 12)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .format("x");
    const eighteen = time
      .set("hour", 18)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .format("x");

    const time1 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +zero
    );
    const time2 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +six
    );
    const time3 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +twelve
    );
    const time4 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +eighteen
    );
    const nextDayTime1 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +zero + 24 * 60 * 60 * 1000
    );
    const nextDayTime2 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +six + 24 * 60 * 60 * 1000
    );
    const nextDayTime3 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +twelve + 24 * 60 * 60 * 1000
    );
    const nextDayTime4 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +eighteen + 24 * 60 * 60 * 1000
    );
    const inaDay1 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +zero + 48 * 60 * 60 * 1000
    );
    const inaDay2 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +six + 48 * 60 * 60 * 1000
    );
    const inaDay3 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +twelve + 48 * 60 * 60 * 1000
    );
    const inaDay4 = weatherInfoReducer.hourly.data.filter(
      elem => elem.time * 1000 === +eighteen + 48 * 60 * 60 * 1000
    );

    const timeArr = time1.concat(
      time2,
      time3,
      time4,
      nextDayTime1,
      nextDayTime2,
      nextDayTime3,
      nextDayTime4,
      inaDay1,
      inaDay2,
      inaDay3,
      inaDay4
    );

    // console.log(timeArr.forEach(elem => console.log('elem', moment(elem.time * 1000).tz(weatherInfoReducer.timezone).format())))
    // console.log(timeArr)
    const timeFirst = +moment(timeArr[0].time * 1000)
      .tz(weatherInfoReducer.timezone)
      .format("HH");
    const timeNow = +moment()
      .tz(weatherInfoReducer.timezone)
      .format("HH");
    let periodNow;
    if (6 <= timeNow && timeNow < 12) periodNow = 6;
    else if (12 <= timeNow && timeNow < 18) periodNow = 12;
    else if (18 <= timeNow && timeNow < 24) periodNow = 18;
    else periodNow = 0;
    // else if(0 <= timeNow && timeNow < 6) periodNow = 0;
    // if(timeFirst === periodNow) return timeArr.slice(1, 5);
    // else return timeArr.slice(0, 4);
    return timeFirst === periodNow ? timeArr.slice(1, 5) : timeArr.slice(0, 4);
    // Morning – 6 am - 12 pm - (data for 6 am)
    // Afternoon – 12 pm - 6 pm - (data for 12 pm)
    // Evening – 6 pm - 12 am - (data for 6 pm)
    // Night – 12 am - 6 am - (data for 12 am)
    // return timeArr.slice(0, 4);
  }
  timeOfDayStyle(numm) {
    if (numm === 0) return { background: "rgba(244, 244, 244, 1)" };
    else if (numm === 1) return { background: "rgba(228, 228, 228, 1)" };
    else if (numm === 2) return { background: "rgba(215, 215, 215, 1)" };
  }
  weatherBlockName(weatherTime, style) {
    const { weatherInfoReducer } = this.props;
    // console.log(moment(weatherTime * 1000).tz(weatherInfoReducer.timezone).format())
    const currentlyHours = moment(weatherTime * 1000)
      .tz(weatherInfoReducer.timezone)
      .format("HH");
    if (currentlyHours === "00") {
      return style === "style"
        ? { background: "rgb(204, 202, 202, 1)" }
        : "Night";
    } else if (currentlyHours === "06") {
      return style === "style"
        ? { background: "rgba(244, 244, 244, 1)" }
        : "Morning";
    } else if (currentlyHours === "12") {
      return style === "style"
        ? { background: "rgba(228, 228, 228, 1)" }
        : "Afternoon";
    } else if (currentlyHours === "18") {
      return style === "style"
        ? { background: "rgba(215, 215, 215, 1)" }
        : "Evening";
    } else {
      // console.log('111111111111111111111111111', moment(weatherTime * 1000).tz(weatherInfoReducer.timezone).format('HH'))
    }
  }
  weatherIconSize() {
    return document.body.offsetWidth <= 991 ? 60 : 100;
  }
  render() {
    const { currentSearchReducer, weatherInfoReducer } = this.props;
    if (!weatherInfoReducer.currently)
      return (
        <div className="loader-wrap weather-block">
          <Loader />
        </div>
      );
    return (
      <div className="weather-block">
        <div className="WeatherBlock world-clock">
          <div className="toggle-temperature-units">
            <span>&#176;F</span>
            <MuiThemeProvider>
              <Toggle
                onToggle={this.handleToggleTemperatureUnits}
                trackSwitchedStyle={{ backgroundColor: "#bdbdbd" }}
                trackStyle={{ backgroundColor: "#bdbdbd" }}
                thumbSwitchedStyle={{ backgroundColor: "#f5f5f5" }}
              />
            </MuiThemeProvider>
            <span>&#176;C</span>
          </div>
          <div className="weather-block-content">
            <div className="weather-block-current">
              <h3 className="weather-block-day">
                Now
                <span>{weatherInfoReducer.currently.summary}</span>
              </h3>

              <div className="weather-block-temperature">
                <div className="weather-block-img">
                  <ReactAnimatedWeather
                    icon={weatherInfoReducer.currently.icon
                      .toUpperCase()
                      .replace(/-/g, "_")}
                    color={"#444f4f"}
                    size={100}
                    animate={true}
                  />
                </div>
                <div className="weather-block-number">
                  {this.state.temperatureIsFahrenheit
                    ? weatherInfoReducer.currently.temperature.toFixed(0)
                    : this.toCelsius(
                        weatherInfoReducer.currently.temperature.toFixed(0)
                      )}

                  {this.state.temperatureIsFahrenheit ? (
                    <sup>&#176;F</sup>
                  ) : (
                    <sup>&#176;C</sup>
                  )}
                </div>
                <div className="weather-block-info">
                  <span>Feels like</span>
                  <span>
                    {this.state.temperatureIsFahrenheit
                      ? weatherInfoReducer.currently.apparentTemperature.toFixed(
                          0
                        )
                      : this.toCelsius(
                          weatherInfoReducer.currently.apparentTemperature.toFixed(
                            0
                          )
                        )}
                  </span>
                </div>
              </div>

              <div className="weather-block-wind">
                <div className="weather-part">
                  <div className="weather-part-img">
                    <img
                      src={windDirection}
                      alt="geotimedate"
                      style={{
                        transform: `rotate(${weatherInfoReducer.currently.windBearing}deg)`
                      }}
                    />
                  </div>
                  <span>
                    Wind{" "}
                    {this.windBearing(weatherInfoReducer.currently.windBearing)}
                  </span>
                  <span>
                    {weatherInfoReducer.currently.windSpeed.toFixed(0)} mph
                  </span>
                </div>
                <div className="weather-part">
                  <div className="weather-part-img">
                    <img src={humidity} alt="geotimedate" />
                  </div>
                  <span>Humidity</span>
                  <span>
                    {(weatherInfoReducer.currently.humidity * 100).toFixed(0)}%
                  </span>
                </div>
              </div>
            </div>
            {this.timeOfDay().map((elem, index) => (
              <div
                className="weather-block-next next-days"
                key={index}
                style={this.weatherBlockName(elem.time, "style")}
              >
                <h3 className="weather-block-day">
                  {this.weatherBlockName(elem.time)}
                  <p>
                    {moment(elem.time * 1000)
                      .tz(currentSearchReducer.timezone)
                      .format("MMM DD")}
                  </p>
                </h3>
                <div className="weather-block-day-top">
                  <div className="weather-block-img">
                    <ReactAnimatedWeather
                      icon={elem.icon.toUpperCase().replace(/-/g, "_")}
                      color={"#444f4f"}
                      size={100}
                      animate={true}
                    />
                  </div>
                  <div className="weather-block-temperature">
                    <div className="weather-block-number">
                      {this.state.temperatureIsFahrenheit
                        ? elem.temperature.toFixed(0)
                        : this.toCelsius(elem.temperature.toFixed(0))}
                      {this.state.temperatureIsFahrenheit ? (
                        <sup>&#176;F</sup>
                      ) : (
                        <sup>&#176;C</sup>
                      )}
                    </div>
                  </div>
                  <span className="weather-block-feels">
                    <span>Feels like</span>
                    <span>
                      {this.state.temperatureIsFahrenheit
                        ? elem.apparentTemperature.toFixed(0)
                        : this.toCelsius(elem.apparentTemperature.toFixed(0))}
                    </span>
                  </span>
                </div>
                <div className="weather-block-wind">
                  <div className="weather-part">
                    <div className="weather-part-img">
                      <img
                        src={windDirection}
                        alt="geotimedate"
                        style={{
                          transform: `rotate(${elem.windBearing}deg)`
                        }}
                      />
                    </div>
                    <span>Wind {this.windBearing(elem.windBearing)}</span>
                    <span>{elem.windSpeed.toFixed(0)} mph</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sunMoonLine"></div>
        <div className="weekly-weather">
          {weatherInfoReducer.daily.data.map((elem, index) => {
            if (index > 0 && index < 7) {
              return (
                <div key={index}>
                  <div className="weekly-weather-title">
                    <h3 className="weather-block-day">
                      {moment(elem.time * 1000)
                        .tz(currentSearchReducer.timezone)
                        .format("ddd")}
                    </h3>
                    <h5 className="weather-block-day">
                      {moment(elem.time * 1000)
                        .tz(currentSearchReducer.timezone)
                        .format("MMM DD")}
                    </h5>
                  </div>
                  <div className="weather-block-img">
                    <ReactAnimatedWeather
                      icon={elem.icon.toUpperCase().replace(/-/g, "_")}
                      color={"#444f4f"}
                      size={this.weatherIconSize()}
                      animate={true}
                    />
                  </div>

                  {this.state.temperatureIsFahrenheit ? (
                    <div className="weatherTemperature">
                      <span>{Math.round(elem.apparentTemperatureMax)}</span> /{" "}
                      <span>{Math.round(elem.apparentTemperatureMin)}</span>
                    </div>
                  ) : (
                    <div className="weatherTemperature">
                      <span>
                        {this.toCelsius(
                          Math.round(elem.apparentTemperatureMax)
                        )}
                      </span>{" "}
                      /{" "}
                      <span>
                        {this.toCelsius(
                          Math.round(elem.apparentTemperatureMin)
                        )}
                      </span>
                    </div>
                  )}
                </div>
              );
            } else return "";
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    weatherInfoReducer: state.weatherInfoReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    weatherInfo: bindActionCreators(weatherInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WeatherBlock);
