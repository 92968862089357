import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom';
import moment from "moment-timezone/index.js";
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { StickyTable, Row, Cell } from "react-sticky-table";
import "react-sticky-table/dist/react-sticky-table.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import "./SunData.css";
import sunRiseTableList from "../../../../../../actions/action_sunRise/action_sunRiseTableList";
import sunRiseDate from "../../../../../../actions/action_sunRise/action_sunRiseDate";
import calendarDateTableSun from "../../../../../../actions/action_sunRise/action_calendarDateTableSun";

import { getCityUrlById } from "../../../../../../utils/sharedFunctions";
import Api from "../../../../../../services/api.js";

class SunData extends Component {
	monthsList = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];
	sunData = [
		"Date",
		"Sunrise",
		"Sunset",
		"Solar Noon",
		`Solar Noon To 12 Noon`,
		"Daylight Duration",
		"Astronomical Twilight",
		"Nautical Twilight",
		"Civil Twilight",
		`Distance To The Sun (miles)`
	];
	state = {
		years: [2016, 2017, 2018]
	};
	async componentDidMount() {
		const {
			sunCalcReducer,
			sunRiseTableList,
			calendarDateTableSun
		} = this.props;
		const time = moment(sunCalcReducer.time)
			.tz(sunCalcReducer.timezone)
			.format("YYYY-MM-DDTHH:mm:ss");
		calendarDateTableSun(time);
		const yearList = [];
		for (let i = 1917; i < 2099; i++) {
			yearList.push(i);
		}
		const sunInfo = await Api.userCityList(
			`sun_data/?geoname_id=${sunCalcReducer.id}&dt=${time}`
		);
		if (sunInfo) {
			sunRiseTableList(sunInfo.data.sun_data);
			this.setState({
				years: yearList
			});
		}

		this.getCityUrl();
	}

	async getCityUrl() {
		const { sunCalcReducer } = this.props;
		const cityUrl = await getCityUrlById(sunCalcReducer.id, "sun_app=true");
		this.setState({ currentCityUrl: cityUrl });
	}

	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}
	emptyState(state, abr) {
		if (isNaN(+abr) && abr !== null) return `${abr}, `;
		else if (state !== null) return `${state}, `;
		else return "";
	}
	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}
	yearChange = (event, index, value) => {
		const {
			calendarDateTableSun,
			calendarDateTableSunReducer,
			sunCalcReducer
		} = this.props;
		const time = moment(calendarDateTableSunReducer)
			.tz(sunCalcReducer.timezone)
			.set("year", value)
			.format();
		calendarDateTableSun(time);
		this.setState({
			year: value
		});
	};
	monthChange = (event, index, value) => {
		const {
			calendarDateTableSun,
			calendarDateTableSunReducer,
			sunCalcReducer
		} = this.props;
		const time = moment(calendarDateTableSunReducer)
			.tz(sunCalcReducer.timezone)
			.set("month", index)
			.format();
		calendarDateTableSun(time);
		this.setState({
			month: value
		});
	};
	async tableChange() {
		const {
			sunCalcReducer,
			sunRiseTableList,
			calendarDateTableSunReducer
		} = this.props;
		const date = calendarDateTableSunReducer;
		const sunInfo = await Api.userCityList(
			`sun_data/?geoname_id=${sunCalcReducer.id}&dt=${moment(date).format(
				"YYYY-MM-DDTHH:mm:ss"
			)}`
		);
		sunRiseTableList(sunInfo.data.sun_data);
	}
	tHeadDurationTwilight(elem, position) {
		if (elem.split(" ")[1] === "Twilight") return position;
		else if (elem === "Daylight Duration")
			return position === "Start" ? "Length" : "Difference";
		else return "";
	}
	tHeadDurationTwilightStyle(elem, position) {
		if (document.body.offsetWidth > 640) {
			if (
				elem.split(" ")[1] === "Twilight" ||
				elem === "Daylight Duration"
			) {
				// if(position === 'Start') return { borderRight: '1px solid #ccc', padding: '2px'};
				if (position === "Start") return {};
				else return {};
			} else return { height: 0 };
		} else return {};
	}
	tHeadDurationBorderStyle(elem, position) {
		if (document.body.offsetWidth > 640) {
			if (
				elem.split(" ")[1] === "Twilight" ||
				elem === "Daylight Duration"
			)
				return { borderBottom: "1px solid #ccc" };
			else return {};
		} else return {};
	}
	sunRiseSetNoon(elem) {
		const { sunCalcReducer } = this.props;
		const time = moment(elem.time)
			.tz(sunCalcReducer.timezone)
			.format("LT");
		const azimuth = Math.round(elem.azimuth);
		return `${time} (${azimuth})`;
	}
	daylightDuration(daylight_duration, param) {
		if (
			daylight_duration.diff !== null &&
			daylight_duration.length !== null
		) {
			// console.log(daylight_duration.diff)
			// const diffArr = daylight_duration.diff.split(':');
			// const fixed = +`${diffArr[1]}.${(+diffArr[2]).toFixed(0)}`;
			// const remainder = `${diffArr[1]}${diffArr[2]}`.replace('.', '');
			// console.log('daylight_duration.diff', diffArr[0], fixed.toFixed(0))

			// console.log('length', daylight_duration.length)
			// console.log('diff', daylight_duration.diff)
			// console.log('daylight_duration.diff',(+(`${diffArr[0]}.${remainder}`)).toFixed(2))

			// console.log('daylight_duration.diff', remainder)
			if (param === "length") {
				return daylight_duration.length !== null
					? daylight_duration.length.slice(0, -3)
					: "-";
			} else {
				// return daylight_duration.diff !== null ? fixed.toFixed(2) : '-';
				return daylight_duration.diff !== null
					? daylight_duration.diff.slice(0, -10)
					: "-";
			}
		} else return "-";
	}
	invalidDate(param) {
		const { sunCalcReducer } = this.props;
		if (param !== null) {
			return moment(param)
				.tz(sunCalcReducer.timezone)
				.format("LT");
		} else return "-";
	}
	riseSetTime(param) {
		if (param !== null) {
			return Math.round(param) + String.fromCharCode(176);
		} else return "";
	}
	meridianPassingDistance(val) {
		const { sunCalcReducer } = this.props;
		const sunDist = (val + "").split(".")[0];
		if (sunCalcReducer.time_format === "AM_PM") {
			return sunDist.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return (+sunDist * 1.60934 + "")
				.split(".")[0]
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
	tableDateStyle(elem) {
		const { sunCalcReducer } = this.props;
		const currentDate = moment()
			.tz(sunCalcReducer.timezone)
			.format("DD MM YYYY");
		const elemDate = moment(elem.date)
			.tz(sunCalcReducer.timezone)
			.format("DD MM YYYY");
		return elemDate === currentDate ? { background: "#e9e9e9" } : {};
	}
	geotimedateTable() {
		const { sunRiseTableListReducer } = this.props;
		if (document.body.offsetWidth > 1199) {
			return sunRiseTableListReducer.length * 35 + 47 + 8;
			// return 925
		} else
			return document.body.offsetHeight < 1260
				? document.body.offsetHeight
				: 1260;
	}
	sunDataHeaderTop(elem) {
		if (
			elem === "Solar Noon To 12 Noon" ||
			elem === "Distance To The Sun (miles)"
		) {
			return elem
				.split(" ")
				.slice(0, 2)
				.join(" ");
		} else return elem;
	}
	sunDataHeaderBottom(elem) {
		const { sunCalcReducer } = this.props;
		if (
			elem === "Solar Noon To 12 Noon" ||
			elem === "Distance To The Sun (miles)"
		) {
			return sunCalcReducer.time_format === "AM_PM"
				? elem
						.split(" ")
						.slice(2)
						.join(" ")
				: elem
						.split(" ")
						.slice(2)
						.join(" ")
						.replace("(miles)", "(km)");
			// return elem.split(' ').slice(2).join(' ');
		}
	}
	render() {
		const {
			sunCalcReducer,
			sunRiseTableListReducer,
			calendarDateTableSunReducer
		} = this.props;
		if (!sunRiseTableListReducer.length) return <div></div>;
		return (
			<div className="SunData">
				<div className="sun-month-year-change">
					<h4>
						Monthly Sunrise, Sunset, Solar Noon in{" "}
						{this.emptyName(sunCalcReducer.name)}
						{this.emptyState(
							sunCalcReducer.state,
							sunCalcReducer.state_code
						)}
						{this.emptyAbr(
							sunCalcReducer.country_name_abbr,
							sunCalcReducer.country_name
						)}
					</h4>
					<div className="month-year-change">
						<MuiThemeProvider>
							<SelectField
								className="sun-year-select"
								// value={this.state.year}
								value={moment(calendarDateTableSunReducer).get(
									"year"
								)}
								onChange={this.yearChange}
								maxHeight={200}
							>
								{this.state.years.map((elem, index) => (
									<MenuItem
										value={elem}
										key={index}
										primaryText={elem}
									/>
								))}
							</SelectField>
						</MuiThemeProvider>
						<MuiThemeProvider>
							<SelectField
								className="sun-month-select"
								// value={this.state.month}
								value={moment(
									calendarDateTableSunReducer
								).format("MMMM")}
								onChange={this.monthChange}
								maxHeight={200}
							>
								{this.monthsList.map((elem, index) => (
									<MenuItem
										value={elem}
										key={index}
										primaryText={elem}
									/>
								))}
							</SelectField>
						</MuiThemeProvider>
						<button
							className="month-chnge"
							onClick={this.tableChange.bind(this)}
						>
							Go
						</button>
					</div>
				</div>
				<div
					className="geotimedate-table"
					style={{
						width: "100%",
						height: this.geotimedateTable() + "px"
					}}
				>
					<StickyTable stickyColumnCount={1} stickyHeaderCount={1}>
						<Row>
							{this.sunData.map((elem, index) => (
								<Cell key={index}>
									<div className="cell-wrapper">
										<b
											style={this.tHeadDurationBorderStyle(
												elem,
												"Start"
											)}
										>
											<em>
												{this.sunDataHeaderTop(elem)}
											</em>
											<em>
												{this.sunDataHeaderBottom(elem)}
											</em>
										</b>
										<b
											className="sun-twilight-info"
											style={this.tHeadDurationTwilightStyle(
												elem,
												"Start"
											)}
										>
											<span
												style={this.tHeadDurationTwilightStyle(
													elem,
													"Start"
												)}
											>
												{this.tHeadDurationTwilight(
													elem,
													"Start"
												)}
											</span>
											<span
												style={this.tHeadDurationTwilightStyle(
													elem,
													"End"
												)}
											>
												{this.tHeadDurationTwilight(
													elem,
													"End"
												)}
											</span>
										</b>
									</div>
								</Cell>
							))}
						</Row>
						<Row>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
						</Row>
						{sunRiseTableListReducer.map((elem, index) => (
							<Row key={index} style={this.tableDateStyle(elem)}>
								<Cell>
									<b>
										{moment(elem.date)
											.tz(sunCalcReducer.timezone)
											.format("DD")}
									</b>
								</Cell>
								<Cell>
									<p>{this.invalidDate(elem.sunrise.time)}</p>
									<p>
										{this.riseSetTime(elem.sunrise.azimuth)}
									</p>
								</Cell>
								<Cell>
									<p>{this.invalidDate(elem.sunset.time)}</p>
									<p>
										{this.riseSetTime(elem.sunset.azimuth)}
									</p>
								</Cell>
								<Cell>
									<p>
										{this.invalidDate(elem.solar_noon.time)}
									</p>
									<p>
										{this.riseSetTime(
											elem.solar_noon.altitude
										)}
									</p>
								</Cell>
								<Cell className="sun-table-noon">
									<b>
										{elem.solar_noon_to_12_noon.slice(
											0,
											-3
										)}
									</b>
								</Cell>
								<Cell>
									<span>
										{this.daylightDuration(
											elem.daylight_duration,
											"length"
										)}
									</span>
									<span>
										{this.daylightDuration(
											elem.daylight_duration,
											"diff"
										)}
									</span>
								</Cell>
								<Cell className="sun-table-twilight">
									<span>
										{this.invalidDate(
											elem.astronomical_twilight.start
										)}
									</span>
									<span>
										{this.invalidDate(
											elem.astronomical_twilight.end
										)}
									</span>
								</Cell>
								<Cell className="sun-table-twilight">
									<span>
										{this.invalidDate(
											elem.nautical_twilight.start
										)}
									</span>
									<span>
										{this.invalidDate(
											elem.nautical_twilight.end
										)}
									</span>
								</Cell>
								<Cell
									className="sun-table-twilight"
									style={{ minWidth: "100px" }}
								>
									<span>
										{this.invalidDate(
											elem.civil_twilight.start
										)}
									</span>
									<span>
										{this.invalidDate(
											elem.civil_twilight.end
										)}
									</span>
								</Cell>
								<Cell>
									<b>
										{this.meridianPassingDistance(
											elem.distance_to_the_sun
										)}
									</b>
								</Cell>
							</Row>
						))}
					</StickyTable>

					{moment(calendarDateTableSunReducer).isBefore(
						"2039-01-01"
					) ? (
						<p className="geotimedate-table_sun-notice">
							*Showing local times for{" "}
							<Link
								to={
									this.state.currentCityUrl
										? this.state.currentCityUrl
										: "/"
								}
							>
								{this.emptyName(sunCalcReducer.name)}{" "}
								{this.emptyAbr(
									sunCalcReducer.country_name_abbr,
									sunCalcReducer.country_name
								)}
							</Link>{" "}
							adjusted for DST, if applicable.
						</p>
					) : (
						<p className="geotimedate-table_sun-notice">
							Showing local times for{" "}
							{this.emptyName(sunCalcReducer.name)}{" "}
							{this.emptyAbr(
								sunCalcReducer.country_name_abbr,
								sunCalcReducer.country_name
							)}{" "}
							based on standard time due to{" "}
							<span title="Many Unix and other operating systems store Unix time as a signed 32-bit integer. After January 19, 2038, the 32 bit signed UNIX time will overflow, taking the count negative. For more information, please visit - https://en.wikipedia.org/wiki/Year_2038_problem">
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								"Year 2038 Problem"
							</span>
							.
						</p>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer,
		sunRiseTableListReducer: state.sunRiseTableListReducer,
		sunRiseDateReducer: state.sunRiseDateReducer,
		sunRiseTimeReducer: state.sunRiseTimeReducer,
		calendarDateTableSunReducer: state.calendarDateTableSunReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		sunRiseTableList: bindActionCreators(sunRiseTableList, dispatch),
		sunRiseDate: bindActionCreators(sunRiseDate, dispatch),
		calendarDateTableSun: bindActionCreators(calendarDateTableSun, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SunData);
