import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import "./PlanetInfo.css";
import Panchang from "../Panchang";
import Loader from "../../../../../../components/Main/Loader/Loader";
import PlanetRetrogradesLinks from "./PlanetRetrogradesLinks";
import PlanetSignsLinks from "./PlanetSignsLinks";
import PoveredBy from "./PoveredBy/PoveredBy";

import Api from "../../../../../../services/api.js";

class PlanetInfo extends Component {
  state = {
    nextPlanetRetrograde: {},
    panchang: {
      current_date: "",
      sun_sign: { name: "", image_url: "" },
      moon_sign: { name: "", image_url: "" },
      shaka_samvat: "",
      vikram_samvat: "",
      amanta: "",
      purnimanta: "",
      nakshatra: { name: "", start: "", end: "" },
      yoga: { name: "", start: "", end: "" },
      tithi: { name: "", start: "", end: "" },
      karana: { name: "", start: "", end: "" },
    },
  };
  async componentDidMount() {
    const { currentSearchReducer } = this.props;
    const year = moment().tz(currentSearchReducer.timezone).format("YYYY");
    const data = await Api.userCityList(
      `retrograde/${currentSearchReducer.id}/${year}/?p=mercury&show_data=false`
    );
    this.setState({
      nextPlanetRetrograde: data.data.next_retrograde,
    });
  }
  componentDidUpdate() {
    // eslint-disable-next-line react/no-string-refs
    const block = this.refs.planetInfo;
    if (block) {
      if (window.location.hash !== "" && window.location.hash !== "#almanac") {
        window.scrollTo(0, block.offsetTop + block.offsetParent.offsetTop);
      }
    }
  }

  cityName(options) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    if (options === "name-country") {
      return `${name}, ${country}`;
    } else {
      return `${name}, ${state} ${country}`;
    }
  }
  titleCityName(nameType) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;

    let state = "";
    if (nameType === "cityFullName")
      state = currentSearchReducer.state
        ? `${currentSearchReducer.state},`
        : ``;
    else if (nameType === "cityShortName") {
      state =
        currentSearchReducer.country_name === "United States"
          ? `${currentSearchReducer.state},`
          : ``;
    } else state = "";
    return `${name}, ${state} ${country}`;
  }
  toFixedSeconds(sec) {
    let newSec = +sec.slice(0, -2);
    return newSec.toFixed() + sec.slice(-2);
  }
  retrograde(retrograde) {
    return retrograde === "R" ? "R" : "";
  }
  nextMercuryRetrograde() {
    const { currentSearchReducer } = this.props;
    const start = moment(this.state.nextPlanetRetrograde.date_retro).tz(
      currentSearchReducer.timezone
    );
    const end = moment(this.state.nextPlanetRetrograde.date_direct).tz(
      currentSearchReducer.timezone
    );
    const currentTime = moment().tz(currentSearchReducer.timezone);
    const datePos =
      start.format("x") <= currentTime.format("x") &&
      currentTime.format("x") <= end.format("x")
        ? "Current"
        : "Next";
    return (
      <div className="currentCityFooter">
        <b>{datePos} Mercury Retrograde</b>
        <p>
          <span>Start:</span> {start.format("llll")}
        </p>
        <p>
          <span>End:</span> {end.format("llll")}
        </p>
      </div>
    );
  }
  render() {
    const { currentSearchReducer } = this.props;

    if (!this.state.nextPlanetRetrograde.planet) {
      return (
        <div className="planet-info">
          <Loader />
        </div>
      );
    }
    const url = currentSearchReducer.url.replace("place/", "");

    return (
      <div className="planet-info" ref={`planetInfo`}>
        <h3>Current Almanac for {this.titleCityName("cityFullName")}</h3>
        <Panchang />

        <div className="ephemeris">
          <div className="planet-links">
            <div className="stations" id="main-retrogrades">
              <h4>
                Planet stations and retrogrades
                <br />
                <span>for {this.cityName()}</span>
              </h4>
              <br />
              <PlanetRetrogradesLinks />
            </div>
            <h5>
              <Link to={`/planetary-hours/${url}`}>
                Planetary hours for {currentSearchReducer.name}{" "}
                {moment.tz(currentSearchReducer.timezone).format("ll")}
              </Link>
            </h5>
            <div className="signs" id="main-planet-in-signs">
              <h4>
                Planet in signs
                <br />
                <span>for {this.cityName()}</span>
              </h4>
              <br />
              <PlanetSignsLinks />
            </div>
            <PoveredBy />
          </div>

          <div className="EphemerisForToday">
            <h3>
              Ephemeris for
              <br />
              {this.cityName()}
            </h3>
            <span>
              {moment.tz(currentSearchReducer.timezone).format("ll, LT z")}
            </span>
            <div className="currentCityInfo">
              <div className="PlanetListBlock">
                <div className="ephemeris_list_tittle ephemeris_table">
                  <b className="columnOne">Planet</b>
                  <b className="columnTwo">Degree</b>
                  <b className="columnThree"></b>
                  <b className="columnFour">Sign</b>
                </div>
                <ul className="planet_list">
                  {currentSearchReducer.ephemeris.map((elem, index) => (
                    <li key={index} className="single_planet ephemeris_table">
                      <b className="columnOne">
                        {elem.name}{" "}
                        <i className="zodiac_name" title={elem.zodiac_name}>
                          {elem.body_symbol}
                        </i>
                      </b>
                      <b className="columnTwo">
                        {elem.body_pos_deg}
                        {elem.body_pos_min}
                        {this.toFixedSeconds(elem.body_pos_sec)}
                      </b>
                      <b className="columnThree">
                        {this.retrograde(elem.retrograde)}
                      </b>
                      <b className="columnFour">
                        <img
                          src={`/${elem.zodiac_image}`}
                          alt={`${elem.zodiac_name} zodiac sign`}
                          title={elem.zodiac_name}
                        />
                      </b>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {this.nextMercuryRetrograde()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    weatherInfoReducer: state.weatherInfoReducer,
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(PlanetInfo);
