import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import advertising from '../../../../../../assets/taboola/336-280.png'

import './LinksBlock.css';

import Api from "../../../../../../services/api";

class LinksBlock extends Component {
    state = {
        sunArticles: [],
        moonArticles: []
    };
    async componentDidMount() {
        const sunData = await Api.userCityList(`news/?limit=5&category=solar-eclipse`);
        const moonData = await Api.userCityList(`news/?limit=5&category=lunar-eclipse`);
        this.setState({
            sunArticles: sunData.data,
            moonArticles: moonData.data
        });
    }
    cityNameHome(city){
        const name = city.name;
        const country = city.country_name === 'United States' ?
            city.country_name_abbr : city.country_name;
        const state = city.country_name === 'United States' ? `${city.state}, ` : '';
        return `${name}, ${state} ${country}`;
    }
    render () {
        const { currentSearchReducer, eclipsesDataReducer } = this.props;
        let city;
        if(window.location.pathname === '/solar-lunar-eclipses/world'){
            city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        }else city = eclipsesDataReducer;
        return (
            <div className='LinksBlock'>
                <div className='link-block'>
                    <h5 className="eclipse-info-header">More information<br/>for {this.cityNameHome(city)}</h5>
                    <div className="info-block">
                        <Link to={`/sun${city.url.replace('place', '')}`}>Sunrise and Sunset</Link>
                        <Link to={`/moon${city.url.replace('place', '')}`}>Moonrise and Moonset</Link>
                        <Link to='/'>Time Planer</Link>
                        <Link to='/#ephemeris-for-today'>Ephemeris for Today</Link>
                        <Link to={`/#planet-stations-retrogrades`}>Planet Stations and Retrogrades</Link>
                        <Link to={`/#planet-in-signs`}>Planet in Signs</Link>
                        <Link to={`/planetary-hours${city.url.replace('place', '')}`}>Planetary Hours</Link>
                        <Link to='/#transits'>Transits</Link>
                    </div>
                </div>
                <div className="advertising-block-main">
                    <img src={advertising} className="advertising" alt="advertising" />
                </div>
                <div className='link-block'>
                    <h5 className="eclipse-info-header">About Solar Eclipse</h5>
                    <div className="info-block">
                        {this.state.sunArticles.map((elem, index) =>                    
                            <Link to={`/articles/sun/${elem.slug}`} key={index}>{elem.title}</Link>
                        )}
                        <b><Link to={'/articles#solar_eclipse'}>...more</Link></b>
                    </div>
                </div>
                <div className='link-block'>
                    <h5 className="eclipse-info-header">About Lunar Eclipse</h5>
                    <div className="info-block">
                        {this.state.moonArticles.map((elem, index) =>
                            <Link to={`/articles/moon/${elem.slug}`} key={index}>{elem.title}</Link>
                        )}
                        <b><Link to={'/articles#lunar_eclipse'}>...more</Link></b>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        eclipsesDataReducer: state.eclipsesDataReducer
    };
};

export default connect(mapStateToProps)(LinksBlock);