import React from "react";

import Api from "../../../services/api";

class GoogleCalendarSycn extends React.Component {
  componentDidMount() {
    const uri = window.location.pathname.substr(1) + window.location.search;
    this.handleSync(uri);
  }

  handleSync = async uri => {
    const token = localStorage.getItem("token");
    await Api.syncBackendGoogleCalendar(uri, token);
  };

  render() {
    return "google sync";
  }
}

export default GoogleCalendarSycn;
