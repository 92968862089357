import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import { Link } from "react-router-dom";
import julian from "julian";

import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import ShowCurentJulian from "./ShowCurentJulian";
import ShowCurentDate from "./ShowCurentDate";

import advertisingRight from "../../../../../assets/160-600.png";
import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";
import Api from "../../../../../services/api";
import "./Calculator.css";

class Calculator extends Component {
  state = {
    time: moment(),
    currentUnix: Date.now(),
    difference: 0,
    UnixTimeStamp: "",
    DateTimeStamp: "",
    DD: "",
    MM: "",
    YYYY: "",
    hh: "",
    mm: "",
    ss: "",
    formatUnix: "ddd mmm dd yyyy HH:MM:ss",
    formatDate: "Local",
    selectDefoult: "",
    selectDefoult2: "",
    unixToDate: "",
    dateToUnix: "",
    errorUnixTodate: { display: "none" },
    errorNoNumber: { display: "none" },
    errorDateToUnix: { display: "none" },
    errorNoNumberDate: { display: "none" },
    classError: "",
    classErrorDate: "",
    timeZona: ""
  };

  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;
    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.currentTime);
  }

  setCurrentUnix = () => {
    //  / 86400) + 2440587.5).toFixed(5)
    this.setState({
      UnixTimeStamp: +(Date.now() + "").substring(0, 10) / 86400 + 2440587.5,
      errorUnixTodate: { display: "none" },
      errorNoNumber: { display: "none" },
      classError: ""
    });
  };

  setCurrentDate = () => {
    this.getDateTime();
    this.setState({
      errorDateToUnix: { display: "none" },
      errorNoNumberDate: { display: "none" },
      classErrorDate: ""
    });
  };

  unixTimeChange = event => {
    if (!isNaN(+event.target.value)) {
      this.setState({
        UnixTimeStamp: +event.target.value,
        errorNoNumber: { display: "none" },
        errorUnixTodate: { display: "none" },
        classError: ""
      });
    } else if (isNaN(+event.target.value)) {
      this.setState({
        errorNoNumber: { display: "block" },
        classError: "input-error"
      });
    }
  };

  dateTimeBlur = event => {
    if (+event.target.value < 1583 && event.target.value !== "") {
      this.setState({
        YYYY: 1583
      });
    }
  };

  dateTimeChange = event => {
    if (!isNaN(+event.target.value)) {
      switch (event.target.placeholder) {
        case "DD":
          // eslint-disable-next-line no-case-declarations
          let day = +event.target.value <= 31 ? event.target.value : "31";
          this.setState({
            DD: +event.target.value === 0 ? "" : +day.substring(0, 2)
          });
          break;
        case "MM":
          // eslint-disable-next-line no-case-declarations
          let month = +event.target.value <= 12 ? event.target.value : "12";
          this.setState({
            MM: +event.target.value === 0 ? "" : +month.substring(0, 2)
          });
          break;
        case "YYYY":
          if (event.target.value.length === 4) {
            this.setState({
              YYYY:
                +event.target.value <= 1583
                  ? "1583"
                  : +event.target.value.substring(0, 4)
            });
          } else if (event.target.value.length < 4) {
            this.setState({
              YYYY:
                event.target.value === ""
                  ? ""
                  : +event.target.value.substring(0, 4)
            });
          }
          break;
        case "hh":
          // eslint-disable-next-line no-case-declarations
          let hour = +event.target.value <= 23 ? event.target.value : "23";
          this.setState({
            hh: +event.target.value === "" ? "" : +hour.substring(0, 2)
          });
          break;
        case "mm":
          // eslint-disable-next-line no-case-declarations
          let minute = +event.target.value <= 59 ? event.target.value : "59";
          this.setState({
            mm: +event.target.value === "" ? "" : +minute.substring(0, 2)
          });
          break;
        case "ss":
          // eslint-disable-next-line no-case-declarations
          let second = +event.target.value <= 59 ? event.target.value : "59";
          this.setState({
            ss: +event.target.value === "" ? "" : +second.substring(0, 2)
          });
          break;
        default:
          this.setState({
            YYYY: "",
            MM: "",
            DD: "",
            hh: "",
            mm: "",
            ss: ""
          });
          break;
      }
      this.setState({
        errorDateToUnix: { display: "none" },
        errorNoNumberDate: { display: "none" },
        classErrorDate: ""
      });
    } else if (isNaN(+event.target.value)) {
      this.setState({
        errorNoNumberDate: { display: "block" },
        classErrorDate: "input-error"
      });
    }
  };
  formatChange = event => {
    if (event.target.getAttribute("data-id-select") === "1") {
      this.setState({
        formatUnix: event.target.value
      });
    } else if (event.target.getAttribute("data-id-select") === "2") {
      this.setState({
        formatDate: event.target.value
      });
    }
  };
  getDateTime() {
    if (this.state.currentUnix) {
      const { timezone } = this.props.currentSearchReducer;

      let a = new Date(+Date.now());
      let test = new Date(
        a.getUTCFullYear(),
        a.getUTCMonth(),
        a.getUTCDate(),
        a.getUTCHours(),
        a.getUTCMinutes(),
        a.getUTCSeconds()
      );
      let time = timezone ? moment(test).tz(timezone) : moment();

      this.setState({
        DD: moment(time._d).format("DD"),
        MM: moment(time._d).format("MM"),
        YYYY: moment(time._d).format("YYYY"),
        hh: moment(time._d).format("HH"),
        mm: moment(time._d).format("mm"),
        ss: moment(time._d).format("ss")
      });
    }
  }
  convertUnix = () => {
    if (this.state.UnixTimeStamp === "") {
      this.setState({
        errorUnixTodate: { display: "block" },
        classError: "input-error"
      });
    } else {
      this.setState({
        unixToDate: this.timeConverter(this.state.UnixTimeStamp, "UTC"),
        unixToDateUTC: this.timeConverter(this.state.UnixTimeStamp, "locale"),
        errorUnixTodate: { display: "none" },
        errorNoNumber: { display: "none" },
        classError: ""
      });
    }
  };
  convertDate = () => {
    if (
      this.state.DD === "" ||
      this.state.MM === "" ||
      this.state.YYYY === "" ||
      this.state.hh === "" ||
      this.state.mm === "" ||
      this.state.ss === ""
    ) {
      this.setState({
        errorDateToUnix: { display: "block" },
        classErrorDate: "input-error"
      });
    } else {
      const { DD, MM, YYYY, hh, mm, ss } = this.state;
      const { currentSearchReducer } = this.props;
      const tz =
        this.state.formatDate === "UTC"
          ? "Greenwich"
          : currentSearchReducer.timezone;
      const year = +YYYY < 1001 ? 1001 : +YYYY;
      const date = moment()
        .tz(tz)
        .year(year)
        .month(+MM - 1)
        .date(+DD)
        .hour(+hh)
        .minute(+mm)
        .second(+ss);
      this.setState({
        dateToUnix: date.unix(),
        errorDateToUnix: { display: "none" },
        errorNoNumberDate: { display: "none" },
        classErrorDate: "",
        YYYY: year,
        showDescriptionResult: true,
        formatDateDescrive: this.state.formatDate === "UTC" ? "UTC" : "Local"
      });
    }
  };

  timeConverter(UNIX_timestamp, CurrentDateTime) {
    let aa =
      CurrentDateTime === "CurrentDateTime"
        ? new Date(UNIX_timestamp)
        : new Date(UNIX_timestamp * 1000);
    const { offset, name } = this.props.currentSearchReducer;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const days = ["Sund", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    let timeZona = offset ? ` ${offset}` : "";
    if (CurrentDateTime === "UTC")
      this.setState({ timeZona, name: name + " " });

    let year = aa.getFullYear();
    let month = months[aa.getMonth()];
    let day = days[aa.getDay()];
    let date = aa.getDate();
    let dateFull = aa.getDate() < 10 ? "0" + aa.getDate() : aa.getDate();
    let hour = aa.getHours() < 10 ? "0" + aa.getHours() : aa.getHours();
    let min = aa.getMinutes() < 10 ? "0" + aa.getMinutes() : aa.getMinutes();
    let sec = aa.getSeconds() < 10 ? "0" + aa.getSeconds() : aa.getSeconds();
    let time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    if (CurrentDateTime === "CurrentDateTime") {
      time = `${day}, ${month} ${dateFull}, ${year} ${hour}:${min}:${sec}`;
      return time;
    }
    let test = +offset.split(":")[0];
    let test1 = +offset.split(":")[1] === 30 ? 0.5 : 0;
    let TZ = +((test + test1) * 0.0416667).toFixed(7);
    let value = 0.00001;
    if (offset.indexOf("-") !== -1) {
      value += +this.state.UnixTimeStamp - Math.abs(TZ);
    } else {
      value += +this.state.UnixTimeStamp + TZ;
    }
    let timeNoFormat =
      CurrentDateTime === "locale"
        ? moment(julian.toDate(value)).tz("Greenwich")
        : moment(julian.toDate(+this.state.UnixTimeStamp + 0.00001)).tz(
            "Greenwich"
          );

    if (CurrentDateTime === "locale") {
      return `${timeNoFormat.format(
        "dddd, MMMM DD YYYY,"
      )} ${timeNoFormat.format("LTS")}`;
    } else {
      return `${timeNoFormat.format(
        "dddd, MMMM DD YYYY,"
      )} ${timeNoFormat.format("LTS")}`;
    }
  }

  showDescriptionResult() {
    const { offset } = this.props.currentSearchReducer;
    if (this.state.formatDateDescrive === "UTC" && offset)
      return `Based on ${this.state.formatDateDescrive} time`;
    else if (this.state.formatDateDescrive === "Local" && offset)
      return `Based on your current date and time (UTC ${offset})`;
  }

  clearStateUnix = () => {
    this.setState({
      UnixTimeStamp: "",
      unixToDateUTC: "",
      unixToDate: "",
      errorUnixTodate: { display: "none" },
      errorNoNumber: { display: "none" },
      classError: ""
    });
  };
  clearStateDate = () => {
    this.setState({
      DateTimeStamp: "",
      DD: "",
      MM: "",
      YYYY: "",
      hh: "",
      mm: "",
      ss: "",
      dateToUnix: "",
      errorDateToUnix: { display: "none" },
      errorNoNumberDate: { display: "none" },
      classErrorDate: "",
      showDescriptionResult: false
    });
  };
  unixToJulian() {
    return this.state.dateToUnix !== ""
      ? (this.state.dateToUnix / 86400 + 2440587.5).toFixed(5)
      : "";
  }
  unixTimeStamp() {
    return this.state.UnixTimeStamp !== ""
      ? +(+this.state.UnixTimeStamp).toFixed(5)
      : "";
  }

  render() {
    const { currentSearchReducer } = this.props;

    if (this.state.selectDefoult === "defaultValue") {
      this.setState({
        selectDefoult: "",
        selectDefoult2: ""
      });
    }
    return (
      <div className="julian-convector">
        <div className="header">
          <h3>Julian time converter</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <div style={{ marginLeft: "5px" }}></div>
          <Link to={"/unix-time-converter"}>
            <button className="btn w-197p">Unix Time Convertor</button>
          </Link>
          <div style={{ marginLeft: "5px" }}></div>
          <button className="btn active w-197p">Julian Time Convertor</button>
          <div style={{ marginLeft: "5px" }}></div>
          <Link to="/gps-time-converter">
            <button className="btn w-197p">GPS Time Converter</button>
          </Link>
        </div>

        <div className="row_convector">
          <div className="column1">
            <div className="Calculator">
              {/*UNIX TIME*/}
              <div className="unix-general">
                <div className="unix-column">
                  <div className="unix-head">
                    <div style={{ marginTop: "25px" }}></div>
                    <h3>Julian TimeStamp to Date and Time</h3>
                    <div style={{ marginTop: "15px" }}></div>
                    <hr />
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Current Julian TimeStamp</h4>
                    </div>
                    <ShowCurentJulian
                      timezone={currentSearchReducer.timezone}
                    />
                    <div className="button">
                      <button
                        className="unix-use"
                        onClick={this.setCurrentUnix}
                      >
                        use
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Enter Julian TimeStamp</h4>
                    </div>
                    <div className="input input-enter">
                      <input
                        className={this.state.classError}
                        value={this.unixTimeStamp()}
                        onChange={this.unixTimeChange}
                      ></input>
                    </div>
                    <div className="button"></div>
                  </div>
                  <p style={this.state.errorNoNumber} className="errorUnix">
                    Invalid Date
                  </p>
                  <p style={this.state.errorUnixTodate} className="errorUnix">
                    Empty Julian TimeStamp
                  </p>

                  <div style={{ marginTop: "20px" }}></div>
                  <div className="row_select_buttom two_buttom">
                    <button className={`btn w-197p`} onClick={this.convertUnix}>
                      CONVERT
                    </button>
                    <button
                      className={`btn w-197p`}
                      onClick={this.clearStateUnix}
                    >
                      RESET
                    </button>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Date and Time (UTC)</h4>
                    </div>
                    <div className="button"></div>
                    <div className="input-result">
                      <h4>{this.state.unixToDate}</h4>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Date and Time&nbsp;</h4>
                      <h4>
                        ({currentSearchReducer.name} UTC{" "}
                        {currentSearchReducer.offset})
                      </h4>
                    </div>
                    <div className="button "></div>
                    <div className="input-result">
                      <h4>{this.state.unixToDateUTC}</h4>
                    </div>
                  </div>
                  <div style={{ marginTop: "40px" }}></div>
                </div>
              </div>
            </div>

            <div className="Calculator">
              <div className="unix-general">
                {/*DATA TIME*/}
                <div className="unix-column">
                  <div className="unix-head">
                    <div style={{ marginTop: "25px" }}></div>
                    <h3>Date and Time to Julian TimeStamp</h3>
                    <div style={{ marginTop: "15px" }}></div>
                    <hr />
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long">
                    <div className="text ">
                      <h4>Current Date and Time&nbsp;</h4>
                      <h4>
                        ({currentSearchReducer.name} UTC{" "}
                        {currentSearchReducer.offset})
                      </h4>
                    </div>
                    <ShowCurentDate timezone={currentSearchReducer.timezone} />
                    <div className="button">
                      <button
                        className="unix-use"
                        onClick={this.setCurrentDate}
                      >
                        use
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "25px" }}></div>

                  <div className="unix-row text-long text-long-height">
                    <div className="text">
                      <h4>Enter Date and Time</h4>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">DD</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="DD"
                        value={this.state.DD}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">MM</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="MM"
                        value={this.state.MM}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">YYYY</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="YYYY"
                        value={this.state.YYYY}
                        onChange={this.dateTimeChange}
                        onBlur={this.dateTimeBlur}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">hh</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="hh"
                        value={this.state.hh}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">mm</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="mm"
                        value={this.state.mm}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">ss</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="ss"
                        value={this.state.ss}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-select">
                      <select
                        onChange={this.formatChange}
                        selected={this.state.selectDefoult2}
                        data-id-select="2"
                      >
                        <option value="Local">YOUR TIME</option>
                        <option value="UTC">UTC</option>
                      </select>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="row_select_buttom two_buttom">
                    <button className={`btn w-197p`} onClick={this.convertDate}>
                      CONVERT
                    </button>
                    <button
                      className={`btn w-197p`}
                      onClick={this.clearStateDate}
                    >
                      RESET
                    </button>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>

                  <p style={this.state.errorDateToUnix} className="errorUnix">
                    Empty input
                  </p>
                  <div className="unix-row text-long-mob">
                    <div className="button convertDate"></div>
                    <div className="text-result">
                      <h4>Julian TimeStamp</h4>
                    </div>
                    <div className="text-result">
                      <h4>{this.unixToJulian()}</h4>
                    </div>
                    <div className="button empty_btn"></div>
                  </div>

                  <div style={{ marginTop: "20px" }}></div>
                  {this.state.showDescriptionResult && (
                    <div className="unix-row text-long-mob">
                      <div className="description-result">
                        <h5>{this.showDescriptionResult()}</h5>
                      </div>
                    </div>
                  )}

                  <div className="unix-row">
                    <div className="input"></div>
                    <div className="button"></div>
                  </div>
                  <div style={{ marginTop: "40px" }}></div>
                </div>
              </div>
            </div>
          </div>

          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
