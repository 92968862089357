const initState ={
  end: '1927-01-18T08:34:10Z',
  month: 'Next Quarter',
  period: 'Next 10 years',
  periodList: [],
  periodName: 'Next 10 years',
  planet: '',
  planetesInSignsList: [],
  start: '1917-01-18T08:34:10Z',
  year: '2019'
}
const planetesInSignsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'PLANETESINSIGNS':
      return action.data;
    default:
      return state;
  }
};
  
export default planetesInSignsReducer;