import React, { Component } from 'react';
import { connect } from 'react-redux';

import './ComponentTimerDisplayFormat.css'

class ComponentTimerDisplayFormat extends Component {
    state = {
        passDisplayFormat: '',
        arrList: [
            {
                id:'1', value:'Years / Months / Days / Hours / Minutes / Seconds', style: {color: '#787A77'}
            },{
                id:'2', value:'Months / Days / Hours / Minutes / Seconds', style: {color: '#787A77'}
            },{
                id:'3', value:'Weeks / Days / Hours / Minutes / Seconds', style: {color: '#787A77'}
            },{
                id:'4', value:'Days / Hours / Minutes / Seconds', style: { color: '#787A77'}
            },{
                id:'5', value:'Just Days', style: {color: '#787A77'}
            }
        ],
    }
    getTimerFormat = (event) => {
        this.props.setDisplayFormatRedux(event.target.getAttribute('value'));
        this.serTimerFormat(event.target.getAttribute('value'));
        this.changeColor(event.target.getAttribute('id'));
    }
    serTimerFormat(value){
        this.setState({
            passDisplayFormat: value,
        })
    }
    changeColor(id){
        let arr = [...this.state.arrList];
        arr.map( item =>{
            if (item.id === id) item.style = {color: '#169bd5'};
            else item.style = {color: '#787A77'};
            return item
        })
    }
    render() {
        return (
            <div className="block-countdown-comp">
                <p className="title-countdown-comp">Timer display format:</p>
                <div className="block-list">
                    <ol className="square">
                        {this.state.arrList.map( (item, index) => {
                           return(
                              <li style={item.style}key={index} id={item.id} value={item.value} onClick={this.getTimerFormat}>{item.value}</li>
                           )
                        })}
                    </ol>
                </div>
                <br/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        countdownReducer: state.countdownReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
         setDisplayFormatRedux: display_format => {
            //console.log('design_name', design_name)
            const payload = {
                display_format,
          };
          dispatch({ type: 'PASS_DISPLAY_FORMAT', payload });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentTimerDisplayFormat);
