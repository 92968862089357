import React, { Component } from 'react';
// import { StrictMode } from 'react';

import 'material-icons/css/material-icons.min.css';
import './theme/App.css';

// import Header from './components/Header/Header';
import Main from './components/Main/Main';
// import Footer from './components/Footer/Footer';

class App extends Component {
  // accountAuthorization = () => {
  //   // if (e.target.className !== 'account-preview' && e.target.className !== 'cityMainImg') {
  //   //   if (document.body.querySelector('.account-preview').style.display === 'block') {
  //   //     document.body.querySelector('.account-preview').style.display = 'none'
  //   //   }
  //   // }
  // };
  render() {
    return (
      <div className="App"
           // onClick={this.accountAuthorization}
      >
        {/* <Header /> */}
        {/* <StrictMode> */}
        <Main />
        {/* </StrictMode> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default App;
