import React, { Component } from 'react';
import { connect } from "react-redux";

import './MapWithSunDraw.css';
import Loader from "../../../../../../components/Main/Loader/Loader";
import SunDrawFunc from "./SunDrawFunc";


class MapWithSunDraw extends Component {
	render() {
		const { sunLoaderShowReducer } = this.props;
		if(sunLoaderShowReducer === true){
			return (<div className="MapWithSunDraw" style={{ height: Math.min(document.body.offsetWidth, document.body.offsetHeight) / 2 + 40 }}><Loader/></div>)
		}
		return (
			<div className='MapWithSunDraw'>
				<SunDrawFunc
					// sunLoaderShowReducer={sunLoaderShowReducer}
					sunMapStyle={this.props.sunMapStyle}
					sunRiseOpacity={this.props.sunRiseOpacity}
					sunRiseHoverStart={this.props.sunRiseHoverStart}
					sunRiseHoverEnd={this.props.sunRiseHoverEnd}
					sunSetOpacity={this.props.sunSetOpacity}
					sunSetHoverStart={this.props.sunSetHoverStart}
					sunSetHoverEnd={this.props.sunSetHoverEnd}
					sunCalcStore={this.props.sunCalcStore}
					getPixelPositionOffset={this.props.getPixelPositionOffset}
					radius={this.props.radius}
					centerX={this.props.centerX}
					centerY={this.props.centerY}
					sunPosition={this.props.sunPosition}
					sunCircleStyle={this.props.sunCircleStyle}
					sunLineColor={this.props.sunLineColor}
					sunShadowCords={this.props.sunShadowCords}
					// centerChanged={this.centerChanged}
					// mapZoom={this.state.mapZoom}
					// mapZoomResizer={this.mapZoomResizer}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunLoaderShowReducer: state.sunLoaderShowReducer
	};
};
export default connect(mapStateToProps)(MapWithSunDraw);