import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CountryCodeBlock from "./SignUpBlocks/CountryCodeBlock";
import FirstNameBlock from "./SignUpBlocks/FirstNameBlock";
import LastNameBlock from "./SignUpBlocks/LastNameBlock";
import EmailBlock from "./SignUpBlocks/EmailBlock";
import PasswordBlock from "./SignUpBlocks/PasswordBlock";

import authorization from "../../../../../actions/authorization";

import Api from "../../../../../services/api.js";

class SignUpFormValidation extends Component {
	state = {
		passwordResponse: ""
	};
	firstNameSubmit = () => {
		const firstName = this.props.form.EditPersonalInfo.values.firstName;
		if (firstName) {
			this.changeRequestArr({ first_name: firstName });
			this.props.authorization({
				...this.props.authorizationReducer,
				first_name: firstName
			});

			// update localStorage
			let auth = localStorage.getItem("authorization");
			let parsedAuth = JSON.parse(auth);
			parsedAuth.first_name = firstName;
			localStorage.setItem("authorization", JSON.stringify(parsedAuth));
		}
	};
	lastNameSubmit = () => {
		const lastName = this.props.form.EditPersonalInfo.values.lastName;
		if (lastName) this.changeRequestArr({ last_name: lastName });
	};
	countryCodeSubmit = () => {
		let data = {
			phone_country_code: ""
			//'phone': '', // не может быть пустым
		};
		if (this.props.form.EditPersonalInfo.values.countryCode)
			data.phone_country_code = this.props.form.EditPersonalInfo.values.countryCode;
		if (this.props.form.EditPersonalInfo.values.Phone)
			data.phone = this.props.form.EditPersonalInfo.values.Phone;
		this.changeRequestArr(data);
	};

	emailSubmit = () => {
		const email = this.props.form.EditPersonalInfo.values.email;
		if (email) this.changeRequestArr({ email: email });
	};

	async changeRequestArr(data) {
		await Api.changeInfo("set-user-profile/", this.props.token, data);
	}

	async changePass() {
		const passwordOld = this.props.form.EditPersonalInfo.values.passwordOld;
		const password1 = this.props.form.EditPersonalInfo.values.password1;
		const password2 = this.props.form.EditPersonalInfo.values.password2;
		const response = await Api.changeInfo(
			"rest-auth/password/change/",
			this.props.token,
			{
				old_password: passwordOld,
				new_password1: password1,
				new_password2: password2
			}
		).catch(err => console.log(err));

		if (response && response.status === 200) {
			return this.setState(
				{ passwordResponse: response.data.detail },
				() => {
					setTimeout(
						() => this.setState({ passwordResponse: "" }),
						5000
					);
				}
			);
		} else {
			return this.setState({ passwordResponse: "fail" }, () => {
				setTimeout(() => this.setState({ passwordResponse: "" }), 5000);
			});
		}
	}

	render() {
		const { authorizationReducer } = this.props;
		return (
			<div className="profile-list">
				<FirstNameBlock
					userInfo={authorizationReducer}
					onSubmit={this.firstNameSubmit}
				/>
				<LastNameBlock
					userInfo={authorizationReducer}
					onSubmit={this.lastNameSubmit}
				/>
				<CountryCodeBlock
					userInfo={authorizationReducer}
					onSubmit={this.countryCodeSubmit}
				/>
				<EmailBlock
					userInfo={authorizationReducer}
					onSubmit={this.emailSubmit}
				/>
				<PasswordBlock
					onSubmit={this.changePass.bind(this)}
					passwordResponse={this.state.passwordResponse}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form,
		authorizationReducer: state.authorizationReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUpFormValidation);
