import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Checkbox from "material-ui/Checkbox";

import "./AccountInfo.css";

import checkboxList from "./checkboxList";

import SignUpFormValidation from "./SignUpFormValidation/SignUpFormValidation";

import authorization from "../../../../actions/authorization";

import Api from "../../../../services/api.js";

class AccountInfo extends Component {
  token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage.getItem("token");
  state = {
    hr12: false,
    hr24: false,
    fahrenheit: false,
    celsius: false,
    miles: false,
    kilometers: false,
    classic: false,
    modern: false,
    dark: false,
    isSaved: false
  };

  componentDidMount() {
    const { authorizationReducer } = this.props;
    const time_format =
      authorizationReducer.time_format === "24 hr" ? "24 hr" : "12 hr";
    const temperature =
      authorizationReducer.temperature === "°C" ? "Celsius" : "Fahrenheit";
    const distance =
      authorizationReducer.long_distance === "km" ? "Kilometers" : "Miles";
    let theme;
    switch (authorizationReducer.timeplanner_theme) {
      case "Dark":
        theme = "Dark";
        break;
      case "Modern":
        theme = "Modern";
        break;
      default:
        theme = "Classic";
        break;
    }
    this.setState({
      [time_format]: true,
      [temperature]: true,
      [distance]: true,
      [theme]: true
    });
    // email: "dberkut7@gmail.com"
    // first_name: "Dima"
    // last_name: "Berkutov"
    // long_distance: "mi"
    // short_distance: "inch"
    // status: "Pro"
    // temperature: "°C"
    // time_format: "24 hr"
    // timeplanner_theme: "Dark"
    // username: "dberkut7@gmail.com"
  }

  updateCheck = e => {
    const line = e.target.getAttribute("data-info");
    const param = e.target.getAttribute("data-param");
    if (this.state[param] !== true) {
      const stateChange = (param1, param2, param3) => {
        this.setState({
          [param1]: false,
          [param2]: false,
          [param3]: false,
          [param]: true
        });
        this.paramsChangeRequest(line, param);
      };
      switch (line) {
        case "time_format":
          stateChange("12 hr", "24 hr");
          break;
        case "temperature":
          stateChange("Fahrenheit", "Celsius");
          break;
        case "long_distance":
          stateChange("Miles", "Kilometers");
          break;
        case "timeplanner_theme":
          stateChange("Classic", "Modern", "Dark");
          break;
        default:
          return;
      }
    }
  };
  saveUpdateCheck = e => {
    this.oriparamsChangeRequest();
  };
  lineParam = {};

  paramsChangeRequest(line, param) {
    let value;
    if (param === "12 hr") value = "12 hr";
    if (param === "24 hr") value = "24 hr";
    if (param === "Fahrenheit") value = "°F";
    if (param === "Celsius") value = "°C";
    if (param === "Miles") value = "mi";
    if (param === "Kilometers") value = "km";
    if (param === "Classic") value = "Classic";
    if (param === "Modern") value = "Modern";
    if (param === "Dark") value = "Dark";
    this.lineParam[line] = value;
  }

  async oriparamsChangeRequest() {
    // if (this.lineParam) {
    await Api.changeInfo("set-user-profile/", this.token, this.lineParam);
    this.lineParam = {};
    this.setState({ isSaved: true }, () =>
      setTimeout(() => this.setState({ isSaved: false }), 3000)
    );
    // }
  }

  render() {
    const { authorizationReducer } = this.props;
    const stat = authorizationReducer.status === "Free";
    return (
      <div className="account-info">
        <h4>Account Settings</h4>
        <div className="profile">
          <h5>My Profile</h5>
          {/* <MuiThemeProvider muiTheme={getMuiTheme()}> */}
          <SignUpFormValidation
            userInfo={authorizationReducer}
            token={this.token}
          />
          {/* </MuiThemeProvider> */}
        </div>
        <div className="customise">
          <h5 className="customise-title">Customize your experience</h5>
          {/* <MuiThemeProvider muiTheme={getMuiTheme()}> */}
          {checkboxList.map((elem, index) => (
            <div className="checkbox-block" key={index}>
              <span>{elem.title}</span>
              <MuiThemeProvider>
                <Checkbox
                  label={elem.param1}
                  checked={this.state[elem.param1]}
                  onCheck={this.updateCheck}
                  data-info={elem.name}
                  data-param={elem.param1}
                />
              </MuiThemeProvider>
              <MuiThemeProvider muiTheme={getMuiTheme()}>
                <Checkbox
                  label={elem.param2}
                  checked={this.state[elem.param2]}
                  onCheck={this.updateCheck}
                  disabled={stat && elem.name === "timeplanner_theme"}
                  data-info={elem.name}
                  data-param={elem.param2}
                />
              </MuiThemeProvider>
              <MuiThemeProvider muiTheme={getMuiTheme()}>
                {elem.param3 ? (
                  <Checkbox
                    label={elem.param3}
                    checked={this.state[elem.param3]}
                    onCheck={this.updateCheck}
                    disabled={stat && elem.name === "timeplanner_theme"}
                    data-info={elem.name}
                    data-param={elem.param3}
                  />
                ) : (
                  <div></div>
                )}
              </MuiThemeProvider>
            </div>
          ))}

          {authorizationReducer.status === "Free" && (
            <div className="checkbox-block">
              <span></span>
              <Link to="/upgrade-account" className="checkbox-block__upgrade">
                (upgrade to change theme)
              </Link>
            </div>
          )}

          <div className="save-button-wrapper">
            {this.state.isSaved && <p>Successfully saved</p>}
            <button className="save-button" onClick={this.saveUpdateCheck}>
              Save
            </button>
          </div>

          {/* </MuiThemeProvider> */}
        </div>
      </div>
    );
  }
}
//
// function isEmpty(obj) {
//     for (var key in obj) {
//         return false;
//     }
//     return true;
// }
const mapStateToProps = state => {
  return {
    authorizationReducer: state.authorizationReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    authorization: bindActionCreators(authorization, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
