import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EclipseInfo from "./Blocks/EclipseInfo";
import AzimuthInfo from "./Blocks/AzimuthInfo";
import Links from "./Blocks/Links";
import DayLengthInfo from "./Blocks/DayLengthInfo";
import Ads from "./Blocks/Ads";

import sunRiseShadow from "../../../../../actions/action_sunRise/action_sunRiseShadow";

import "./SunCulcRightBlockInfo.css";

class SunCulcRightBlockInfo extends Component {
	state = {
		mobileLayout: true
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		if (window.innerWidth < 480) {
			this.setState({ mobileLayout: true });
		} else {
			this.setState({ mobileLayout: false });
		}
	};

	render() {
		const { mobileLayout } = this.state;

		return (
			<div className="sunCulcCityInfoRightSide">
				{mobileLayout ? (
					<div className="sunCulcCityInfoRightSide-wrapper">
						<EclipseInfo />
						<DayLengthInfo />
						<Ads />
						<AzimuthInfo sunRiseShadow={this.props.sunRiseShadow} />
						<Links />
					</div>
				) : (
					<Fragment>
						<div className="sunCulcCityInfoRightSide-wrapper sunCulcCityInfoRightSide-wrapper__first">
							<EclipseInfo />
							<AzimuthInfo
								sunRiseShadow={this.props.sunRiseShadow}
							/>
							<Links />
						</div>
						<div className="sunCulcCityInfoRightSide-wrapper sunCulcCityInfoRightSide-wrapper__second">
							<DayLengthInfo />
							<Ads />
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer,
		sunRiseDateReducer: state.sunRiseDateReducer,
		sunRisePositionReducer: state.sunRisePositionReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		sunRiseShadow: bindActionCreators(sunRiseShadow, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SunCulcRightBlockInfo);
