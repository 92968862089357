import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Slider from "material-ui/Slider";
import moment from "moment-timezone/index.js";
import { Paper, Set, Circle, Image, Text } from "react-raphael";

import "./GoogleMapDraw.css";
import MapWithSunDraw from "./MapWithSunDraw/MapWithSunDraw";
import SunChartsDraw from "./SunChartsDraw/SunChartsDraw";
import SunRiseDayParts from "./SunRiseDayParts/SunRiseDayParts";
import SunData from "./SunData/SunData";
import advertising from "../../../../../assets/sun_page/970-90.jpg";
import taboola from "../../../../../assets/sun_page/taboola.png";
import sunCalc from "../../../../../actions/action_sunRise/action_sunCalc";
import sunRiseDate from "../../../../../actions/action_sunRise/action_sunRiseDate";
import sunRisePosition from "../../../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../../../actions/action_sunRise/action_sunRiseCoords";
import calendarDateSun from "../../../../../actions/action_sunRise/action_calendarDateSun";
import sunRiseTableList from "../../../../../actions/action_sunRise/action_sunRiseTableList";
import mapNightStyle from "./mapNightStyle";
import SunCulcRightBlockInfo from "../SunCulcRightBlockInfo/SunCulcRightBlockInfo";

import Api from "../../../../../services/api.js";

class GoogleMapDraw extends React.PureComponent {
	padding = 20;
	state = {
		sunLineColor: { stroke: "rgba(255, 249, 0, 0)", "stroke-width": 5 },
		sunCircleStyle: {
			stroke: "rgba(255, 255, 255, 0.55)",
			"stroke-width": 2,
			fill: "rgba(53, 53, 53, 0.55)"
		},
		sunRiseOpacity: 0,
		sunSetOpacity: 0,
		radius: 300,
		chartWidth: 700,
		sunPosition: {
			utc_time: "2017-10-17T04:00:59.999999Z",
			loc_time: "2017-10-17T00:00:59.999999-04:00",
			alt: -0.9565995931625366,
			az: 5.9318413734436035,
			dist: 149097178.59258798,
			xy: [0.4107469304277523, 0.2565065568583342],
			shadow: 1
		},
		latitude: 0,
		longitude: 0,
		mapLoader: false
	};
	radiusMap =
		Math.min(document.body.offsetWidth, document.body.offsetHeight) / 4;

	componentDidMount() {
		this.windowResize();
		window.addEventListener("resize", this.windowResize);

		const { sunCalcReducer, sunRiseCoordsReducer } = this.props;
		let width = 0;
		if (document.body.offsetWidth <= 991) {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth
			);
		} else {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
					document.querySelector(".GoogleMapBox").offsetWidth * 0.01
			);
		}
		this.setState(prevState => {
			return {
				radius: this.radiusMap,
				sunPosition: sunCalcReducer.sun_data.position_during_day[0],
				chartWidth: width,
				latitude: sunCalcReducer.latitude,
				longitude: sunCalcReducer.longitude
			};
		});
		const hour = moment(sunCalcReducer.sun_data.rise_loc)
			.tz(sunCalcReducer.timezone)
			.get("hour");
		const minute = moment(sunCalcReducer.sun_data.rise_loc)
			.tz(sunCalcReducer.timezone)
			.get("minute");
		const newTime = hour * 60 + minute;
		if (sunRiseCoordsReducer >= newTime) {
			this.setState(prevState => {
				return {
					sunLineColor: { stroke: "#fff900", "stroke-width": 5 },
					sunCircleStyle: {
						stroke: "#fff",
						"stroke-width": 2,
						fill: "#ffca00"
					}
				};
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}
	sunRiseHoverStart = () => {
		return this.setState(prevState => {
			return { sunRiseOpacity: 1 };
		});
	};
	sunRiseHoverEnd = () => {
		return this.setState(prevState => {
			return { sunRiseOpacity: 0 };
		});
	};
	sunSetHoverStart = () => {
		return this.setState(prevState => {
			return { sunSetOpacity: 1 };
		});
	};
	sunSetHoverEnd = () => {
		return this.setState(prevState => {
			return { sunSetOpacity: 0 };
		});
	};
	sunSlider = (e, value) => {
		const { sunCalcReducer, sunRisePosition, sunRiseCoords } = this.props;
		sunRisePosition(sunCalcReducer.sun_data.position_during_day[value]);
		sunRiseCoords(value);
		this.setState(prevState => {
			return {
				sunPosition: sunCalcReducer.sun_data.position_during_day[value]
			};
		});

		const hour = moment(sunCalcReducer.sun_data.rise_loc)
			.tz(sunCalcReducer.timezone)
			.get("hour");
		const minute = moment(sunCalcReducer.sun_data.rise_loc)
			.tz(sunCalcReducer.timezone)
			.get("minute");
		const newTime = hour * 60 + minute;
		+value >= newTime
			? this.setState(prevState => {
					return {
						sunLineColor: {
							stroke: "rgba(255, 249, 0, 1)",
							"stroke-width": 5
						},
						sunCircleStyle: {
							stroke: "#fff",
							"stroke-width": 2,
							fill: "#ffca00"
						}
					};})
			: this.setState(prevState => {
					return {
						sunLineColor: {
							stroke: "rgba(255, 249, 0, 0)",
							"stroke-width": 5
						},
						sunCircleStyle: {
							stroke: "rgba(255, 255, 255, 0.55)",
							"stroke-width": 2,
							fill: "rgba(53, 53, 53, 0.55)"
						}
					};});
	};

	sunLineColor() {
		const { sunRisePositionReducer } = this.props;
		if (sunRisePositionReducer) {
			return +sunRisePositionReducer.alt > 0
				? { stroke: "rgba(255, 249, 0, 1)", "stroke-width": 5 }
				: { stroke: "rgba(255, 249, 0, 0)", "stroke-width": 5 };
		}
	}
	sunCircleStyle() {
		const { sunRisePositionReducer } = this.props;
		if (sunRisePositionReducer) {
			return +sunRisePositionReducer.alt > 0
				? { stroke: "#fff", "stroke-width": 2, fill: "#ffca00" }
				: {
						stroke: "rgba(255, 255, 255, 0.55)",
						"stroke-width": 2,
						fill: "rgba(53, 53, 53, 0.55)"};
		}
	}
	getPixelPositionOffset = (width, height) => ({
		x: -(width / 2),
		y: -(height / 2)
	});
	coordinatCalculation(time) {
		const { sunCalcReducer } = this.props;
		const hour = moment(time)
			.tz(sunCalcReducer.timezone)
			.get("hour");
		const minute = moment(time)
			.tz(sunCalcReducer.timezone)
			.get("minute");
		return hour * 60 + minute;
	}
	sliderStart(period) {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const periodEnd = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === period
		);
		if (periodEnd[0].time === "") return { width: 0 };
		const coordinatPeriodEnd = this.coordinatCalculation(periodEnd[0].time);
		const periodEndPosition = (coordinatPeriodEnd * 100) / dotsLength;
		return { width: `${periodEndPosition}%` };
	}
	sliderPeriod(start, end) {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const periodStart = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === start
		);
		const periodEnd = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === end
		);
		if (periodStart[0].time === "" || periodEnd[0].time === "")
			return { width: 0 };
		const coordinatPeriodStart = this.coordinatCalculation(
			periodStart[0].time
		);
		const coordinatPeriodEnd = this.coordinatCalculation(periodEnd[0].time);
		const periodStartPosition =
			((coordinatPeriodEnd - coordinatPeriodStart) * 100) / dotsLength;
		return { width: `${periodStartPosition}%` };
	}
	sliderEnd(period) {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const periodStart = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === period
		);
		if (periodStart[0].time === "") return { width: 0 };
		const coordinatPeriodStart = this.coordinatCalculation(
			periodStart[0].time
		);
		const periodStartPosition =
			((dotsLength - coordinatPeriodStart) * 100) / dotsLength;
		return { width: `${periodStartPosition}%` };
	}
	sunRiseBeforeDay() {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const civilStart = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Civil start"
		);
		if (civilStart[0].time === "") return { width: 0 };
		const coordinatCivilStart = this.coordinatCalculation(
			civilStart[0].time
		);
		const civilStartPosition = (coordinatCivilStart * 100) / dotsLength;
		return { width: `${civilStartPosition}%` };
	}
	sunRiseStartDay() {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const civilStart = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Civil start"
		);
		const sunRise = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Sunrise"
		);
		if (civilStart[0].time === "" || sunRise[0].time === "")
			return { width: 0 };
		const coordinatCivilStart = this.coordinatCalculation(
			civilStart[0].time
		);
		const coordinatSunRise = this.coordinatCalculation(sunRise[0].time);
		const civilStartPosition =
			((coordinatSunRise - coordinatCivilStart) * 100) / dotsLength;
		return { width: `${civilStartPosition}%` };
	}
	sunRiseDay() {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const sunRise = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Sunrise"
		);
		const sunSet = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Sunset"
		);
		if (sunRise[0].time === "" || sunSet[0].time === "")
			return { width: 0 };
		const coordinatSunRise = this.coordinatCalculation(sunRise[0].time);
		const coordinatSunSet = this.coordinatCalculation(sunSet[0].time);
		const civilStartPosition =
			((coordinatSunSet - coordinatSunRise) * 100) / dotsLength;
		return { width: `${civilStartPosition}%` };
	}
	sunRiseEndDay() {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const sunSet = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Sunset"
		);
		const civilEnd = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Civil end"
		);
		if (sunSet[0].time === "" || civilEnd[0].time === "")
			return { width: 0 };
		const coordinatSunSet = this.coordinatCalculation(sunSet[0].time);
		const coordinatCivilEnd = this.coordinatCalculation(civilEnd[0].time);
		const civilStartPosition =
			((coordinatCivilEnd - coordinatSunSet) * 100) / dotsLength;
		return { width: `${civilStartPosition}%` };
	}
	sunRiseAfterDay() {
		const { sunCalcReducer } = this.props;
		const dotsLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		const civilEnd = sunCalcReducer.sun_data.day_parts.filter(
			elem => elem.name === "Civil end"
		);
		if (civilEnd[0].time === "") return { width: 0 };
		const coordinatCivilEnd = this.coordinatCalculation(civilEnd[0].time);
		const civilStartPosition =
			((dotsLength - coordinatCivilEnd) * 100) / dotsLength;
		return { width: `${civilStartPosition}%` };
	}
	sliderTime() {
		const { sunCalcReducer, sunRiseCoordsReducer } = this.props;
		const sliderLength = Object.values(
			sunCalcReducer.sun_data.position_during_day
		).length;
		return sunRiseCoordsReducer < sliderLength / 2
			? {
				left: `${sunRiseCoordsReducer / (sliderLength / 100)}%`,
				right: "auto"
			}
			: {
				left: "auto",
				right: `${(sliderLength - sunRiseCoordsReducer) /
				(sliderLength / 100)}%`
			};
	}
	sunShadowCords() {
		const { sunRisePositionReducer, sunRiseShadowReducer } = this.props;
		const shadowLength =
			sunRiseShadowReducer *
			Math.tan(Math.PI / 2 - sunRisePositionReducer.alt);
		const x =
			Math.cos(sunRisePositionReducer.az + Math.PI / 2) *
				shadowLength *
				0.5 +
			this.radiusMap +
			20;
		const y =
			Math.sin(sunRisePositionReducer.az + Math.PI / 2) *
				shadowLength *
				0.5 +
			this.radiusMap +
			20;
		return sunRisePositionReducer.alt > 0
			? { x: x, y: y }
			: { x: this.state.radius + 20, y: this.state.radius + 20 };
	}
	chartWidth() {
		let chartWidth = 0;
		if (document.querySelector(".GoogleMapBox")) {
			if (document.body.offsetWidth <= 991) {
				chartWidth = Math.round(
					document.querySelector(".GoogleMapBox").offsetWidth
				);
			} else {
				chartWidth = Math.round(
					document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
						document.querySelector(".GoogleMapBox").offsetWidth *
							0.01
				);
			}
		} else chartWidth = this.state.chartWidth;
		return Math.round(chartWidth);
	}
	chartSunInfoX(data) {
		if (data) {
			return data[0];
		} else return 0;
	}
	chartSunInfoY(data) {
		if (data) {
			return data[1];
		} else return 0;
	}
	chartSunInfoName(data) {
		if (data.name === "Rise" && data.xy) return "Sunrise";
		else if (data.name === "Set" && data.xy) return "Sunset";
		else return data.xy ? data.name : "";
	}
	sunRiseCoords(val) {
		return val ? val : 0;
	}
	windowResize = () => {
		let width = 0;
		if (document.querySelector(".GoogleMapBox").offsetWidth <= 991) {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth
			);
		} else {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
					document.querySelector(".GoogleMapBox").offsetWidth * 0.01
			);
		}
		this.setState(prevState => {
			return {
				radius: this.radiusMap,
				chartWidth: width
			};
		});
	};
	sliderStraightedge() {
		let time;
		if (document.body.offsetWidth <= 499) {
			time = ["0", "3", "6", "9", "12", "15", "18", "21"];
		} else if (document.body.offsetWidth <= 749) {
			time = [
				"0",
				"2",
				"4",
				"6",
				"8",
				"10",
				"12",
				"14",
				"16",
				"18",
				"20",
				"22"
			];
		} else {
			time = [
				"0",
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23"
			];
		}
		return time;
	}

	async dayPeriodClick(elem) {
		const {
			sunCalcReducer,
			sunCalc,
			sunRiseDate,
			sunRiseCoords,
			sunRisePosition,
			calendarDateSun
		} = this.props;
		const time = moment()
			.tz(sunCalcReducer.timezone)
			.format("YYYY-MM-DDTHH:mm:ss");
		sunRiseDate(time);
		// const sunInfo = await Api.userCityList(`sun_data/?geoname_id=${sunCalcReducer.id}&dt=${time}`);
		// sunRiseTableList(sunInfo.data.sun_data);
		const sunCoordinats = await Api.userCityList(
			`geo_names/${sunCalcReducer.id}/?sun_app=true&date=${time}`
		);
		sunCalc(sunCoordinats.data);
		localStorage.setItem(
			"sunCalcCityInfo",
			JSON.stringify(sunCoordinats.data)
		);
		calendarDateSun(sunCoordinats.data.time);
		const hourCurrent = moment(sunCoordinats.data.time)
			.tz(sunCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(sunCoordinats.data.time)
			.tz(sunCoordinats.data.timezone)
			.get("minute");
		for (let key in sunCoordinats.data.sun_data.position_during_day) {
			const hourDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				sunRiseCoords(+key);
				sunRisePosition(
					sunCoordinats.data.sun_data.position_during_day[+key]
				);
			}
		}
	}
	scaleDataPositive() {
		const { sunCalcReducer } = this.props;
		return sunCalcReducer.sun_data.sun_chart.sun_chart.scale_data.filter(
			elem => {
				return elem.degrees.slice(0, 1) !== "-";
			}
		);
	}
	scaleDataNegative() {
		const { sunCalcReducer } = this.props;
		return sunCalcReducer.sun_data.sun_chart.sun_chart.scale_data.filter(
			elem => {
				return elem.degrees.slice(0, 1) === "-";
			}
		);
	}
	sunMapStyle() {
		const { sunRisePositionReducer } = this.props;
		if (sunRisePositionReducer) {
			return +sunRisePositionReducer.alt < 0 ? mapNightStyle : [];
		}
	}
	render() {
		const {
			sunRiseCoordsReducer,
			sunCalcReducer,
			sunRisePositionReducer
		} = this.props;
		return (
			<div className="sunCulcCityInfoCenter">
				<MapWithSunDraw
					sunMapStyle={this.sunMapStyle()}
					sunRiseOpacity={this.state.sunRiseOpacity}
					sunRiseHoverStart={this.sunRiseHoverStart}
					sunRiseHoverEnd={this.sunRiseHoverEnd}
					sunSetOpacity={this.state.sunSetOpacity}
					sunSetHoverStart={this.sunSetHoverStart}
					sunSetHoverEnd={this.sunSetHoverEnd}
					sunCalcStore={sunCalcReducer}
					getPixelPositionOffset={this.getPixelPositionOffset}
					radius={this.radiusMap}
					centerX={this.radiusMap + this.padding}
					centerY={this.radiusMap + this.padding}
					sunPosition={sunRisePositionReducer}
					sunCircleStyle={this.sunCircleStyle()}
					sunLineColor={this.sunLineColor()}
					sunShadowCords={this.sunShadowCords()}
				/>
				<SunChartsDraw>
					<Paper width={20} height={200} className="position-percent">
						<Set>
							{this.scaleDataPositive().map((elem, index) => (
								<Text
									key={index}
									x={10}
									y={elem.y * 200 + 7}
									text={elem.degrees + ""}
									attr={{ fill: "#000" }}
								/>
							))}
							{this.scaleDataNegative().map((elem, index) => (
								<Text
									key={index}
									x={10}
									y={elem.y * 200 - 7}
									text={elem.degrees + ""}
									attr={{ fill: "#000" }}
								/>
							))}
						</Set>
					</Paper>
					<Paper width={this.chartWidth()} height={200}>
						<Set>
							<Image
								src={`data:png;base64,${sunCalcReducer.sun_data.sun_chart.sun_chart.img}`}
								x={0}
								y={0}
								width={this.chartWidth()}
								height={200}
							/>
							{
								<Text
									x={
										this.chartSunInfoX(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.rise.xy
										) * +this.chartWidth()
									}
									y={
										this.chartSunInfoY(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.rise.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartSunInfoName(
										sunCalcReducer.sun_data.sun_chart
											.sun_chart.rise_set_info.rise
									)}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.rise.time}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.rise.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										this.chartSunInfoX(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.noon.xy
										) * +this.chartWidth()
									}
									y={
										this.chartSunInfoY(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.noon.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartSunInfoName(
										sunCalcReducer.sun_data.sun_chart
											.sun_chart.rise_set_info.noon
									)}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.noon.time}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.noon.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										this.chartSunInfoX(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.set.xy
										) * +this.chartWidth()
									}
									y={
										this.chartSunInfoY(
											sunCalcReducer.sun_data.sun_chart
												.sun_chart.rise_set_info.set.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartSunInfoName(
										sunCalcReducer.sun_data.sun_chart
											.sun_chart.rise_set_info.set
									)}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.set.time}
									${sunCalcReducer.sun_data.sun_chart.sun_chart.rise_set_info.set.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							<Circle
								x={
									this.sunRiseCoords(
										sunCalcReducer.sun_data.sun_chart
											.sun_chart.xy[
											sunRiseCoordsReducer
										][0][0]
									) * +this.chartWidth()
								}
								y={
									this.sunRiseCoords(
										sunCalcReducer.sun_data.sun_chart
											.sun_chart.xy[
											sunRiseCoordsReducer
										][0][1]
									) * 200
								}
								r={10}
								attr={this.sunCircleStyle()}
							/>
						</Set>
					</Paper>
				</SunChartsDraw>
				<div className="color-slider-sunRise-wrapper">
					<ul className="color-slider-time">
						{this.sliderStraightedge().map((elem, index) => {
							return <li key={index}>{elem}</li>;
						})}
					</ul>
					<div className="color-slider-sunRise">
						<span className="slider-time" style={this.sliderTime()}>
							{moment(sunRisePositionReducer.loc_time)
								.tz(sunCalcReducer.timezone)
								.format("LT")}
						</span>
						<div
							className="night"
							style={this.sliderStart("Astro start")}
						></div>
						<div
							className="astronomical-twilight"
							style={this.sliderPeriod(
								"Astro start",
								"Nautical start"
							)}
						></div>
						<div
							className="nautical-twilight"
							style={this.sliderPeriod(
								"Nautical start",
								"Civil start"
							)}
						></div>
						<div
							className="civil-twilight"
							style={this.sliderPeriod("Civil start", "Sunrise")}
						></div>
						<div
							className="sunrise-sunset"
							style={this.sliderPeriod("Sunrise", "Sunset")}
						></div>
						<div
							className="civil-twilight"
							style={this.sliderPeriod("Sunset", "Civil end")}
						></div>
						<div
							className="nautical-twilight"
							style={this.sliderPeriod(
								"Civil end",
								"Nautical end"
							)}
						></div>
						<div
							className="astronomical-twilight"
							style={this.sliderPeriod(
								"Nautical end",
								"Astro end"
							)}
						></div>
						<div
							className="night"
							style={this.sliderEnd("Astro end")}
						></div>
						<MuiThemeProvider>
							<Slider
								className="sunRise-slider"
								name="sunRiseSet"
								min={0}
								max={
									Object.values(
										sunCalcReducer.sun_data
											.position_during_day
									).length - 1
								}
								step={1}
								value={sunRiseCoordsReducer}
								onChange={this.sunSlider}
							/>
						</MuiThemeProvider>
					</div>
				</div>
				<button
					onClick={this.dayPeriodClick.bind(this)}
					className="timeNow"
				>
					Current Time and Date
				</button>
				<SunRiseDayParts />
				<div className="advertising-block-main">
					<img
						src={advertising}
						className="advertising"
						alt="advertising"
					/>
				</div>
				<SunData />

				{window.innerWidth <= 980 ? <SunCulcRightBlockInfo /> : null}

				<div className="taboola" style={{ marginTop: 20 }}>
					<img
						src={taboola}
						alt="taboola"
						style={{ maxWidth: "100%" }}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer,
		sunRisePositionReducer: state.sunRisePositionReducer,
		sunRiseCoordsReducer: state.sunRiseCoordsReducer,
		sunRiseShadowReducer: state.sunRiseShadowReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		sunCalc: bindActionCreators(sunCalc, dispatch),
		sunRiseDate: bindActionCreators(sunRiseDate, dispatch),
		sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
		sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch),
		calendarDateSun: bindActionCreators(calendarDateSun, dispatch),
		sunRiseTableList: bindActionCreators(sunRiseTableList, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GoogleMapDraw);
