import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import Loader from "../../../../../components/Main/Loader/Loader";
import panchangImage from "../../../../../assets/panchang-box-image.jpg";
import Api from "../../../../../services/api.js";

import "./PlanetInfo/PlanetInfo.css";

class Panchang extends Component {
  state = {
    nextPlanetRetrograde: {},
    panchang: {
      current_date: "",
      sun_sign: { name: "", image_url: "" },
      moon_sign: { name: "", image_url: "" },
      shaka_samvat: "",
      vikram_samvat: "",
      amanta: "",
      purnimanta: "",
      nakshatra: { name: "", start: "", end: "" },
      yoga: { name: "", start: "", end: "" },
      tithi: { name: "", start: "", end: "" },
      karana: { name: "", start: "", end: "" },
    },
  };

  async componentDidMount() {
    const { currentSearchReducer } = this.props;
    const year = moment().tz(currentSearchReducer.timezone).format("YYYY");
    const data = await Api.userCityList(
      `retrograde/${currentSearchReducer.id}/${year}/?p=mercury&show_data=false`
    );
    await this.setPanchangToState(currentSearchReducer.id);
    this.setState({
      nextPlanetRetrograde: data.data.next_retrograde,
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { currentSearchReducer } = nextProps;
    if (this.props !== nextProps) {
      this.setPanchangToState(currentSearchReducer.id);
    }
  }

  async setPanchangToState(id) {
    const panchang = await Api.userCityList(`panchanga/${id}/brief/`);
    this.setState({
      panchang: panchang.data,
    });
  }

  cityName(options) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    if (options === "name-country") {
      return `${name}, ${country}`;
    } else {
      return `${name}, ${state} ${country}`;
    }
  }

  render() {
    const { currentSearchReducer } = this.props;
    const {
      sun_sign,
      moon_sign,
      shaka_samvat,
      vikram_samvat,
      amanta,
      purnimanta,
      nakshatra,
      yoga,
      tithi,
      karana,
      sun_all_day,
    } = this.state.panchang;

    if (!this.state.nextPlanetRetrograde.planet) {
      return (
        <div className="planet-info">
          <Loader />
        </div>
      );
    }
    const url = currentSearchReducer.url.replace("place/", "");

    return (
      <div className="panchang">
        <img src={panchangImage} alt="Panchang" />
        <div className="panchang-top">
          <h3>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Today's Panchang for
            <br />
            {this.cityName()}
          </h3>
        </div>

        {sun_all_day === "DOWN" && (
          <div className="panchang-content">
            <span className="center">
              {moment(currentSearchReducer.time).format("MMM DD, YYYY")}
            </span>
            <div className="text_error_out">
              <span>
                Due to Sun being down all day, Panchang for{" "}
                {this.cityName("name-country")} cannot be calculated.
              </span>
            </div>
          </div>
        )}
        {sun_all_day === "UP" && (
          <div className="panchang-content">
            <span className="center">
              {moment(currentSearchReducer.time).format("MMM DD, YYYY")}
            </span>
            <div className="text_error_out">
              <span>
                Due to Sun being up all day, Panchang for{" "}
                {this.cityName("name-country")} cannot be calculated.
              </span>
            </div>
          </div>
        )}
        {sun_all_day === "TRANSITION" && (
          <div className="panchang-content">
            <span className="center">
              {moment(currentSearchReducer.time).format("MMM DD, YYYY")}
            </span>
            <div className="text_error_out">
              <span>
                Due to Sun being in transition, Panchang for{" "}
                {this.cityName("name-country")} cannot be calculated.
              </span>
            </div>
          </div>
        )}
        {sun_all_day === "UP_DOWN" && (
          <div>
            <div className="panchang-content">
              <span className="center">
                {moment(currentSearchReducer.time).format("MMM DD, YYYY")}
              </span>
              <div className="row_between">
                <div className="sun_sign">
                  <span className="">Surya Rashi / Sun Sign</span>
                  <span className="sign_img ">
                    <img src={`/${sun_sign.image_url}`} alt="geotimedate" />
                    {sun_sign.name.split(" ")[0]} /{" "}
                    {sun_sign.name
                      .split(" ")[1]
                      .replace("(", "")
                      .replace(")", "")}
                  </span>
                </div>
                <div className="moon_sign">
                  <span className="">Chandra Rashi / Moon Sign</span>
                  <span className="sign_img ">
                    <img src={`/${moon_sign.image_url}`} alt="geotimedate" />
                    {moon_sign.name.split(" ")[0]} /{" "}
                    {moon_sign.name
                      .split(" ")[1]
                      .replace("(", "")
                      .replace(")", "")}
                  </span>
                </div>
              </div>
              <span>Sun and Moon sign based on sidereal zodiac</span>
              <hr />
              <div className="text">
                <span>
                  <b>Shaka Samvat</b> - {shaka_samvat}
                </span>
                <span>
                  <b>Vikram Samvat</b> - {vikram_samvat}
                </span>
                <span>
                  <b>Amanta Chandramasa</b> - {amanta}
                </span>
                <span>
                  <b>Purnimata Chandramasa</b> - {purnimanta}
                </span>
              </div>
              <hr />
              <div className="text">
                <span>
                  <b>Nakshatra</b> - {nakshatra.name} up to{" "}
                  {moment(nakshatra.end)
                    .tz(currentSearchReducer.timezone)
                    .format("hh:mm A")}
                </span>
                <span>
                  <b>Yoga</b> - {yoga.name} up to{" "}
                  {moment(yoga.end)
                    .tz(currentSearchReducer.timezone)
                    .format("hh:mm A")}
                </span>
                <span>
                  <b>Tithi</b> - {tithi.name} up to{" "}
                  {moment(tithi.end)
                    .tz(currentSearchReducer.timezone)
                    .format("hh:mm A")}
                </span>
                <span>
                  <b>Karana</b> - {karana.name} up to{" "}
                  {moment(karana.end)
                    .tz(currentSearchReducer.timezone)
                    .format("hh:mm A")}
                </span>
              </div>
              <hr />
            </div>
            <div className="panchang-bottom">
              <Link to={`/panchang/${url}`}>
                <b>
                  Click here for detailed Panchang - Auspicious and Inauspicious
                  period, Muhurats, Chandrabalam, Tarabalam, Choghadlya ...
                </b>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    weatherInfoReducer: state.weatherInfoReducer,
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(Panchang);
