import React, { Component } from "react";

import moment from "moment-timezone/index.js";

import "../../UnixConverter/Calculator/Calculator.css";
import { DatePicker, MuiThemeProvider } from "material-ui";

class SimpleDatePicker extends Component {
  state = {
    date: {
      dd: "",
      mm: "",
      yyyy: ""
    },
    openHelper: false
  };

  setDateStartComponents = e => {
    let dd = this.state.date.dd,
      mm = this.state.date.mm,
      yyyy = this.state.date.yyyy;
    if (e.target.name === "DD" && +e.target.value <= 31) {
      dd = e.target.value;
      this.setState({
        date: { ...this.state.date, dd: e.target.value }
      });
      if (e.target.value.toString().length === 2)
        e.target.parentNode.parentNode.nextSibling.firstChild.children[1].select();
    } else if (e.target.name === "MM" && +e.target.value <= 12) {
      mm = e.target.value;
      this.setState({
        date: { ...this.state.date, mm: e.target.value }
      });
      if (e.target.value.toString().length === 2)
        e.target.parentNode.parentNode.nextSibling.firstChild.children[1].select();
      else if (
        e.target.value.toString().length === 0 &&
        this.state.date.mm.toString().length === 1
      ) {
        e.target.parentNode.parentNode.previousSibling.firstChild.children[1].select();
      }
    } else if (e.target.name === "YYYY" && +e.target.value <= 9999) {
      yyyy = e.target.value;
      this.setState({
        date: { ...this.state.date, yyyy: e.target.value }
      });
      if (e.target.value.toString().length === 4) {
        e.target.blur();
      } else if (
        e.target.value.toString().length === 0 &&
        this.state.date.yyyy.toString().length === 1
      ) {
        e.target.parentNode.parentNode.previousSibling.firstChild.children[1].select();
      }
    }
    this.setDateStart(dd, mm, yyyy);
  };

  setDateStart(dd, mm, yyyy) {
    let { date, getDate } = this.props;
    date = !date ? new Date() : date;
    const dat = new Date(
      yyyy.length <= 4 ? +yyyy : 1,
      +mm ? +mm - 1 : date.getMonth(),
      +dd ? +dd : 0,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );

    dat.setFullYear(yyyy ? yyyy : date.getFullYear());
    getDate(dat);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps) {
    const { reset } = this.props;
    if (reset !== nextProps.reset) {
      this.checkReset(nextProps.reset);
    }
  }

  checkReset = reset => {
    if (
      (reset && this.state.date.dd !== "") ||
      (reset && this.state.date.mm !== "") ||
      (reset && this.state.date.yyyy !== "")
    ) {
      this.setState({
        date: {
          dd: "",
          mm: "",
          yyyy: ""
        }
      });
    }
  };

  calendarChange(date) {
    const { getDate } = this.props;
    this.setState({
      date: {
        dd: moment(date).format("D"),
        mm: moment(date).format("M"),
        yyyy: moment(date).format("YYYY")
      }
    });
    getDate(date);
  }

  setCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.calendarChange(date);
  };

  render() {
    const { minDate, maxDate } = this.props;
    return (
      <div className="simple_date_piker">
        <div className="info-block center-ai">
          <div className="image">
            <i className="material-icons">insert_invitation</i>
            <MuiThemeProvider>
              <DatePicker
                className="calendar-date-picker"
                hintText=" "
                minDate={minDate}
                maxDate={maxDate}
                value={null}
                onChange={(event, date) => this.calendarChange(date)}
                autoOk={true}
                formatDate={
                  new Intl.DateTimeFormat("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric"
                  }).format
                }
              />
            </MuiThemeProvider>
          </div>
          <div className="info-block-row">
            <div className="input-date">
              <div className="row">
                <label className="label">DD</label>
                <input
                  type="number"
                  name="DD"
                  placeholder=""
                  value={this.state.date.dd}
                  onChange={this.setDateStartComponents}
                ></input>
              </div>
            </div>
            <div className="input-date">
              <div className="row">
                <label className="label">MM</label>
                <input
                  type="number"
                  name="MM"
                  placeholder=""
                  value={this.state.date.mm}
                  onChange={this.setDateStartComponents}
                ></input>
              </div>
            </div>
            <div className="input-date">
              <div className="row">
                <label className="label">YYYY</label>
                <input
                  type="number"
                  name="YYYY"
                  placeholder=""
                  value={this.state.date.yyyy}
                  onChange={this.setDateStartComponents}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className="info">
          <div className="blockCurrentButton">
            <button className="currentButton" onClick={this.setCurrentDate}>
              CURRENT DATE/TIME
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleDatePicker;
