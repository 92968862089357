const reducer_newRegisteredEmail = (state = null, action) => {
	switch (action.type) {
		case "NEW_REGISTERED_EMAIL":
			return action.data;

		default:
			return state;
	}
};

export default reducer_newRegisteredEmail;
