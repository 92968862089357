import React, {Component} from 'react';
import {connect} from 'react-redux';


import PoveredBy from "../../../../components/Main/MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PoveredBy/PoveredBy";
import {Link} from "react-router-dom";

class ShowInfo extends Component {


    render() {
        const {name, country_name, state_code, state, id, abbr, url} = this.props.currentSearchReducer;

        let stateName = '';
        let urlHome = '';
        if (!state) {
            stateName = url.split('/')[1];
            urlHome = `/time-in/${stateName}`
        }else {
            stateName = isNaN(state_code) ? state_code : state.replace(/ /g, '_');
            urlHome = `/time-in/${country_name.replace(/ /g, '_')}/${stateName}/${name.replace(/ /g, '_')}/${id}`;
        }



        return (
            <div className='below_info'>
                <div className='local'>
                    {/*<p>*Showing local times for <span>{name}, {country_name}</span> (IST)</p>*/}
                      <p>*Showing local times for <Link to={urlHome}><span>{name}, {country_name}</span></Link> ({abbr})</p>
                </div>
                <PoveredBy/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};

export default connect(mapStateToProps)(ShowInfo);


