import React, { Component } from "react";
import { connect } from "react-redux";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MetaTags from "react-meta-tags";

import ContactFormValidation from "./ContactFormValidation";
import "./contact.css";
import Api from "../../../services/api";

class Contact extends Component {
	state = {
		reasonSelect: null,
		captcha: null
	};

	handleSelect = value => {
		this.setState({ reasonSelect: value });
	};
	handleCaptcha = value => {
		this.setState({ captcha: value });
	};

	async handleSubmit() {
		if (
			this.state.captcha &&
			this.state.reasonSelect &&
			this.props.form.ContactForm.values
		) {
			// console.log(this.state.reasonSelect);
			// console.log(this.props.form.ContactForm.values.firstName);
			// console.log(this.props.form.ContactForm.values.lastName);
			// console.log(this.props.form.ContactForm.values.email);
			// console.log(this.props.form.ContactForm.values.message);
			// console.log(this.state.captcha);

			const sendMail = await Api.authorization("send-mail/", {
				reasonSelect: this.state.reasonSelect,
				first_name: this.props.form.ContactForm.values.firstName,
				last_name: this.props.form.ContactForm.values.lastName,
				email: this.props.form.ContactForm.values.email,
				message: this.props.form.ContactForm.values.message,
				captcha: this.state.captcha
			}).catch(error => {
				if (error.response.status !== 200) {
					const errors = Object.values(error.response.data);
					this.setState({
						errorText: errors[0]
					});
				} else {
					this.setState({
						errorText: ""
					});
				}
			});
			if (sendMail) {
				if (sendMail.status && sendMail.status === 200) {
					if (sendMail.data.status) {
						this.setState({
							res: sendMail.data.res,
							ok: sendMail.data.status
						});
					} else {
						this.setState({
							res: "Error!!! " + sendMail.data.res,
							ok: sendMail.data.status
						});
					}
				} else {
					// console.log(sendMail);
					this.setState({
						res: "Unspecified error send mail see console.log",
						ok: sendMail.data.status
					});
				}
			}
		}
	}

	render() {
		return (
			<div className="contact">
				<MetaTags>
					<title>GeoTimeDate – Contact Us</title>
				</MetaTags>
				<h1 className="contact__title">Contact Us</h1>
				<div className="contact-wrapper">
					<div className="contact-left">
						<MuiThemeProvider muiTheme={getMuiTheme()}>
							<ContactFormValidation
								onSubmit={this.handleSubmit.bind(this)}
								// handleSubmit={this.handleSubmit}
								reason={this.state.reasonSelect}
								captcha={this.state.captcha}
								handleReasonSelect={this.handleSelect}
								handleCaptcha={this.handleCaptcha}
								res={this.state.res}
								ok={this.state.ok}
							/>
						</MuiThemeProvider>
					</div>
					<div className="contact-right">
						<p className="contact_detail-main">US Office</p>
						<p className="contact_detail-location">GeoTimeDate</p>
						<p className="contact_detail-location">
							2204, Oak Haven Ave, Simi Valley, CA 93063 USA
						</p>
						<p className="contact_detail-email">
							Email: help@geotimedate.org
						</p>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form
	};
};

export default connect(mapStateToProps)(Contact);
