import React, { Component } from 'react';
import { withRouter } from 'react-router'

class ScrollToTopRoute extends Component {
    state = { hasError: false };
    componentDidUpdate(prevProps){
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
    }
    // componentDidCatch(errorString, errorInfo){
    //     console.log('componentDidCatch', errorString, errorInfo)
    // }    
    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     console.log('getDerivedStateFromError', error)
    //     return { hasError: true };
    // }
    render() {
        // console.log(...document.getElementsByTagName("META"))
        return (<div className="main-wrapper">{this.props.children}</div>);
    }
}

export default withRouter(ScrollToTopRoute);