import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/index.js';

import './CityEclipses.css';


class CityEclipses extends Component {
    cityEclipse() {
        const { eclipsesDataReducer, pageType } = this.props;
        const name = eclipsesDataReducer.name;
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr : eclipsesDataReducer.country_name;
        const state = eclipsesDataReducer.country_name === 'United States' ?
            `${eclipsesDataReducer.state},` : ``;
        const tz = eclipsesDataReducer.timezone;

        const urlDateGet = window.location.pathname.split('/').reverse()[0].split('-');
        const start = pageType === 'Solar' ?
            moment(eclipsesDataReducer.solar_eclipse_data.date_start).tz(tz) :
            moment(eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_begin).tz(tz);
        const urlDate = moment().tz(tz).year(+urlDateGet[0]).month(+urlDateGet[1] - 1).date(+urlDateGet[2]);
        const caseText = urlDate.format('YYYY-MM-DD') !== start.format('YYYY-MM-DD') ?
            <span>The {pageType} Eclipse on {urlDate.format('ll')} will not be visible in {name}<br/></span> : '';
        if (pageType === 'Solar') {
            const time = start.format('ll');
            const eclipsesTime = moment(eclipsesDataReducer.solar_eclipse_data.next_visible_eclipse.next_solar_eclipse).tz(tz).format('x');
            const eclipseStart = +start.format('x') === +eclipsesTime ? 'Next' : '';
            return <h4>{caseText} {eclipseStart} {pageType} Eclipse in {name}, {state} {country} - {time} ({eclipsesDataReducer.solar_eclipse_data.eclipse_type.title})</h4>;
        } else {
            const eclipsesTime = moment(eclipsesDataReducer.lunar_eclipse_data.next_visible_eclipse.next_lunar_eclipse).tz(tz).format('x');
            const eclipseStart = +start.format('x') === +eclipsesTime ? 'Next' : '';
            const time = start.format('ll');
            return <h4>{caseText} {eclipseStart} {pageType} Eclipse in {name}, {state} {country} - {time} ({eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title})</h4>;
        }
    }
    sunMoonPicture() {
        const { eclipsesDataReducer } = this.props;
        return <img className="cityMainImg" src={`/static/flags/flags-iso/flat/64/${eclipsesDataReducer.country_code}.png`} alt="geotimedate" />

    }
    render() {
        const { pageType } = this.props;
        return (
            <section className="citySunRiseSet" style={{ background: pageType === 'Solar' ? '#fff386' : '#aee5fa' }}>
                <div className="country_flag">
                    {this.sunMoonPicture()}
                </div>
                <div className="city_name_date">
                    {this.cityEclipse()}
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer
    };
};

export default connect(mapStateToProps)(CityEclipses);