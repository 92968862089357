import React, { Component } from "react";

import Ads from "../Ads/Ads";

class DescriptionText extends Component {
  render() {
    return (
      <div className="column1">
        <div className="unix-general">
          <div className="unix-column description">
            <h4>About Julian TimeStamp</h4>
            <br />
            <div className="left_text">
              <h4>What is Julian Day?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Julian day is the continuous count of days elapsed since noon on
                Jan 1, 4713 BC. It provides a way to measure secular time
                differences over long time spans without having to be concerned
                with getting the correct calendar. It is used primarily by
                astronomers and in computer science to calculate the difference
                between days, since all numbers in the system are consecutive
                integers.
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Is Julian Date and Julian Calendar same?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Julian date is not to be confused with Julian calendar. Julian
                calendar was introduced by Julius Caesar in 45 B.C. to bring
                about reform in the Roman Empire. Julian date is the difference
                of time in days lapsed since the start of the 7,980-year cycle
                (Jan 1, 4713 BC).
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>When does the current Julian cycle end?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                The current Julian cycle started on January 1, 4713 B.C.
                (Gregorian calendar) and will end on January 22, 3268 A.D.
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Why does it start on Jan 1, 4713 BC?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                The somewhat unusual starting date derives from the Julian
                Period of 7980 Julian Years of 365.25 days each. The Julian
                Period is the time interval between coincidences of the 28-year
                Solar Cycle, the 19-year Lunar Cycle, and the 15-year Roman
                Indiction (a tax cycle). The starting date is the last time all
                three cycles were coincident.
              </p>
            </div>
            <br />
            <div className="left_text">
              <h4>How do you calculate Julian Date?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Julian date is based on the number of days and fraction of day
                that has elapsed since noon, Jan 1, 4713 BC in Universal Time.
                For example, the Julian Date for 00:30:00.0 UT January 1, 2013,
                is 2 456 293.520 833.
              </p>
            </div>
            <br />
            <div className="left_text">
              <h4>What is Julian date code for food?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                This is widely used in variety of manufactured products and food
                as a standard means of providing date of manufacture. The
                typical format is 4 or 5 digit code. Traditional date looks like
                MM/DD/YYYY, so Jan 1 2019 will be 01/01/2019. The same date in
                Julian format will be YYDDD. So Jan 1, 2019 will be 19001. The
                DDD is the number of days in a year (for leap years it is 366).
              </p>
            </div>
            <br />
            <div className="left_text">
              <p>To know more about Julian Time in depth, you can refer -</p>
            </div>
            <br />
            <div className="left_text">
              <a
                className="link_width"
                href="https://en.wikipedia.org/wiki/Unix_time"
              >
                https://en.wikipedia.org/wiki/Julian_day
              </a>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
        <Ads />
      </div>
    );
  }
}

export default DescriptionText;
