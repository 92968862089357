import React from "react";

import TopBlockCities from "../MainPage/TopBlockCities/TopBlockCities";

function MeetingPlanner() {
	return (
		<div style={{ marginBottom: "auto" }}>
			<TopBlockCities />
		</div>
	);
}

export default MeetingPlanner;
