import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

class Constellation extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise
    );
  }

  renderRowConstellation(arr) {
    const { timezone } = this.props.currentSearchReducer;
    const newArr = [...arr];

    return newArr.map(item => (
      <div className="row" key={item.name}>
        <div className="name">
          <h5>{item.name}</h5>
        </div>
        <div>
          <h5>
            <span className="arrow"> → </span>
            {moment(item.start)
              .tz(timezone)
              .format("MMM DD, hh:mm A")}{" "}
            -{" "}
            {moment(item.end)
              .tz(timezone)
              .format("MMM DD, hh:mm A")}
          </h5>
        </div>
      </div>
    ));
  }

  renderRowSign(text, arr) {
    const { timezone } = this.props.currentSearchReducer;
    const newArr = [...arr];

    if (newArr.length === 1) {
      return newArr.map(obj => {
        if (obj.name.indexOf("(") === -1) {
          return (
            <h5 key={obj.name}>
              {text} <span className="arrow">→ </span> {obj.name}{" "}
            </h5>
          );
        } else {
          const textSign = `${obj.name.split(" ")[0]} / ${obj.name
            .split(" ")[1]
            .replace("(", "")
            .replace(")", "")}`;
          return (
            <h5 key={obj.name}>
              {text} <span className="arrow">→ </span> {textSign}{" "}
            </h5>
          );
        }
      });
    } else {
      return newArr.map((obj, index) => {
        if (obj.name.indexOf("(") === -1) {
          return (
            <h5 key={obj.name}>
              {index === 0 && text}
              {index === 1 && (
                <span
                  style={{ display: "inline-block", width: "110px" }}
                ></span>
              )}
              <span className="arrow"> → </span>
              {obj.name}
              {index === 0 &&
                ` up to ${moment(obj.end)
                  .tz(timezone)
                  .format("hh:mm A")}`}
              {index === 1 &&
                ` up to ${moment(obj.end)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}`}
            </h5>
          );
        } else {
          const textSign = `${obj.name.split(" ")[0]} / ${obj.name
            .split(" ")[1]
            .replace("(", "")
            .replace(")", "")}`;
          return (
            <h5 key={obj.name}>
              {text} <span className="arrow">→ </span> {textSign}{" "}
            </h5>
          );
        }
      });
    }
  }

  renderRowSigns(arr) {
    const { timezone } = this.props.currentSearchReducer;
    if (arr) {
      const newArr = [...arr];
      if (newArr.length === 1) {
        return newArr.map(item => (
          <h5 key={item.name}>
            <span className="arrow"> → </span>{" "}
            {item.name.replace("(", " / ").replace(")", "")}
          </h5>
        ));
      } else {
        return newArr.map((item, index) => (
          <h5 key={item.name}>
            <span className="arrow"> → </span>
            {item.name.replace("(", "/ ").replace(")", " ")}
            {index === 0 &&
              `up to ${moment(item.end)
                .tz(timezone)
                .format("hh:mm A")}`}
            {index === 1 &&
              `up to ${moment(item.end)
                .tz(timezone)
                .format("MMM DD, hh:mm A")}`}
          </h5>
        ));
      }
    }
  }

  render() {
    const {
      nakshatras_nearby,
      yogas_nearby,
      tithis_nearby,
      karanas_nearby,
      sun_sign_nearby,
      moon_sign_nearby,
      sun_nakshatra,
      drik_ayana
    } = this.props.panchang;
    // console.log(this.props.panchang);
    return (
      <div className="constellation">
        <div className="row">
          <div className="col first pb-1">
            <h4>Nakshatra</h4>
            {this.renderRowConstellation(nakshatras_nearby)}
          </div>
          <div className="col second pb-1">
            <h4>Yoga</h4>
            {this.renderRowConstellation(yogas_nearby)}
          </div>
        </div>
        <div className="row">
          <div className="col first pt-1 pb-1">
            <h4>Tithi</h4>
            {this.renderRowConstellation(tithis_nearby)}
          </div>
          <div className="col second pt-1 pb-1">
            <h4>Karana</h4>
            {this.renderRowConstellation(karanas_nearby)}
          </div>
        </div>
        <div className="row">
          <div className="col first col-br-none pt-1">
            <h4>Rashi, Nakshatra and Ayana</h4>
            <div className="row">
              <div className="name">
                <h5>Surya Rashi / Sun Sign</h5>
              </div>
              <div>{this.renderRowSigns(sun_sign_nearby)}</div>
            </div>
            {this.renderRowSign("Surya Nakshatra", sun_nakshatra)}
          </div>
          <div className="col second col-br-none pt-1">
            <h4 style={{ opacity: 0 }}>00000</h4>
            <div className="row">
              <div className="name">
                <h5>Chandra Rashi / Moon Sign</h5>
              </div>
              <div>{this.renderRowSigns(moon_sign_nearby)}</div>
            </div>
            {this.renderRowSign("Drik Ayana", [{ name: drik_ayana }])}
          </div>
        </div>
        <div style={{ marginTop: "30px" }}></div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};

export default connect(mapStateToProps)(Constellation);
