import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import './Countdown.css';

class Countdown extends Component {
    state = {
        time: moment().tz(moment.tz.guess())
    }
    timer = setInterval(()=> {
        return this.setState({
            time: moment().tz(moment.tz.guess())
        });
    },1000);
    componentWillUnmount(){
        clearInterval(this.timer);
    }
    getTime(val){
        const { eclipsesCatelogNextEclipseReducer } = this.props;
        const eclipsesTime = moment(eclipsesCatelogNextEclipseReducer.calendar_date).tz('Greenwich');
        const difference = eclipsesTime.diff(this.state.time);
        const days =  eclipsesTime.diff(this.state.time, 'days');
        const hours = Math.floor((difference - days * 86400000) / 3600000);
        const minutes = Math.floor((difference - (days * 86400000) - (hours * 3600000)) / 60000);
        const seconds = Math.floor((difference - (days * 86400000) - (hours * 3600000) - (minutes * 60000)) / 1000);
        if(val === 'days') return days;
        else if(val === 'hours') return hours;
        else if(val === 'minutes') return minutes;
        else return seconds;
    }
    cityNameHome(city){
        const name = city.name;
        const country = city.country_name === 'United States' ?
            city.country_name_abbr : city.country_name;
        const state = city.country_name === 'United States' ? `${city.state}, ` : '';
        return `${name}, ${state} ${country}`;
    }
    countdownTimer(){
        const { eclipsesCatelogNextEclipseReducer } = this.props;
        const { pageType } = this.props;
        const tz = 'UTC';
        if(pageType === 'Solar'){
            return moment(eclipsesCatelogNextEclipseReducer.calendar_date).tz(tz).format('ll LTS z');
        } else moment(eclipsesCatelogNextEclipseReducer.penumbral_eclipse_begin).tz(tz).format('ll LTS z')
    }
    render () {
        const { pageType } = this.props;
        // const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        return (            
            <div className="sunCulcCityInfoRightSide ">
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">
                        Countdown the Next {pageType} Eclipse <br/>{this.countdownTimer()}
                    </h5>
                    <div className="eclipse-info-block countdown">
                        <div className='countdown-time'>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('days')}</span>
                                <span className='countdown-block-text'>Days</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('hours')}</span>
                                <span className='countdown-block-text'>Hours</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('minutes')}</span>
                                <span className='countdown-block-text'>Min</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('seconds')}</span>
                                <span className='countdown-block-text'>Sec</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer,
        currentSearchReducer: state.currentSearchReducer,
        eclipsesCatelogNextEclipseReducer: state.eclipsesCatelogNextEclipseReducer
    };
};

export default connect(mapStateToProps)(Countdown);