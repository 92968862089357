import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TextField from "material-ui/TextField";

class AzimuthInfo extends React.Component {
	state = {
		objectHeight: 1
	};

	componentDidUpdate() {
		const { sunRiseShadow, sunRisePositionReducer } = this.props;
		const shadowLength = (
			this.state.objectHeight / Math.tan(sunRisePositionReducer.alt)
		).toFixed(2);
		const shadowCords =
			!isNaN(+shadowLength) && +shadowLength > 0 ? shadowLength : 0;
		sunRiseShadow(shadowCords);
	}

	shadowLength() {
		const { sunRisePositionReducer } = this.props;
		const shadowLength = (
			this.state.objectHeight / Math.tan(sunRisePositionReducer.alt)
		).toFixed(2);
		const shadowCords =
			!isNaN(shadowLength) && +shadowLength > 0 ? shadowLength : 0;
		return shadowCords;
	}

	sunDistance() {
		const { sunRisePositionReducer } = this.props;
		return (sunRisePositionReducer.dist + "")
			.split(".")[0]
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	shadowLengthWord() {
		const { sunCalcReducer } = this.props;
		return sunCalcReducer.time_format === "AM_PM" ? "ft" : "m";
	}

	objectHeightChange = e => {
		const { sunRiseShadow, sunRisePositionReducer } = this.props;
		const value = e.target.value;
		let newNalue = value
			.split("")
			.filter(elem => !isNaN(elem) && elem !== " ")
			.join("");

		const shadowLength = (
			newNalue / Math.tan(sunRisePositionReducer.alt)
		).toFixed(2);
		const shadowCords =
			!isNaN(+shadowLength) && +shadowLength > 0 ? shadowLength : 0;

		sunRiseShadow(shadowCords);
		this.setState(prevState => {
			return { objectHeight: newNalue };
		});
	};

	render() {
		const { sunCalcReducer, sunRisePositionReducer } = this.props;

		return (
			<div className="azimuth-info">
				<h5 className="eclipse-header">Sun Now</h5>
				<div className="eclipse-info-block">
					<b>Date:</b>
					<b>
						{moment(sunRisePositionReducer.loc_time)
							.tz(sunCalcReducer.timezone)
							.format("ddd, MMM DD, YYYY")}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Time:</b>
					<b>
						{moment(sunRisePositionReducer.loc_time)
							.tz(sunCalcReducer.timezone)
							.format("LT")}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Altitude:</b>
					<b>
						{((180 / Math.PI) * sunRisePositionReducer.alt).toFixed(
							2
						)}
						&#176;
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Azimuth:</b>
					<b>
						{((180 / Math.PI) * sunRisePositionReducer.az).toFixed(
							2
						)}
						&#176;
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Distance between Earth and Sun (mi):</b>
					<b>{this.sunDistance()}</b>
				</div>
				<div className="eclipse-info-block eclipse-info-block-visible">
					<b>Object height ({this.shadowLengthWord()}):</b>
					<b>
						<MuiThemeProvider>
							<TextField
								id="sun_shadow_input"
								className="sun_shadow_input"
								value={this.state.objectHeight}
								onChange={this.objectHeightChange}
							/>
						</MuiThemeProvider>
					</b>
				</div>
				<div className="eclipse-info-block eclipse-info-block-visible">
					<b>Shadow length ({this.shadowLengthWord()}):</b>
					<b>{this.shadowLength()}</b>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer,
		sunRisePositionReducer: state.sunRisePositionReducer
	};
};

export default connect(mapStateToProps)(AzimuthInfo);
