import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";

import authorizationReducer from "./authorizationReducer";
import newRegisteredEmailReducer from "./reducer_newRegisteredEmail";
import currentCityInfoReducer from "./reducer_currentCity/reducer_currentCityInfo";
import defaultCityInfoReducer from "./reducer_currentCity/reducer_defaultCityInfo";
import mainCityInfoReducer from "./reducer_currentCity/reducer_mainCityInfo";
import searchStockCityInfoReducer from "./reducer_currentCity/reducer_searchCityInfo";
import selectedCitiesReducer from "./reducer_selectedCities";
import timePlanerSwipeBlockReducer from "./reducer_timePlanerSwipeBlock";
import currentSearchReducer from "./reducer_searchCity/reducer_currentCity";
import searchOpenReducer from "./reducer_searchCity/reducer_searchOpen";
import currentNewsReducer from "./reducer_currentNews";
import calendarDateReducerSun from "./reducer_sunRise/reducer_calendarDateSun";
import sunCalcReducer from "./reducer_sunRise/reducer_sunCalc";
import calendarDateTableSunReducer from "./reducer_sunRise/reducer_calendarDateTableSun";
import sunRisePositionReducer from "./reducer_sunRise/reducer_sunRisePosition";
import sunRiseCoordsReducer from "./reducer_sunRise/reducer_sunRiseCoords";
import sunRiseShadowReducer from "./reducer_sunRise/reducer_sunRiseShadow";
import sunRiseTableListReducer from "./reducer_sunRise/reducer_sunRiseTableList";
import sunRiseDateReducer from "./reducer_sunRise/reducer_sunRiseDate";
import sunRiseTimeReducer from "./reducer_sunRise/reducer_sunRiseTime";
import sunLoaderShowReducer from "./reducer_sunRise/reducer_sunLoaderShow";
import calendarDateReducerMoon from "./reducer_moonRise/reducer_calendarDateMoon";
import moonCalcReducer from "./reducer_moonRise/reducer_moonCalc";
import calendarDateTableMoonReducer from "./reducer_moonRise/reducer_calendarDateTableMoon";
import moonRisePositionReducer from "./reducer_moonRise/reducer_moonRisePosition";
import moonRiseCoordsReducer from "./reducer_moonRise/reducer_moonRiseCoords";
import moonRiseShadowReducer from "./reducer_moonRise/reducer_moonRiseShadow";
import moonRiseTableListReducer from "./reducer_moonRise/reducer_moonRiseTableList";
import moonRiseDateReducer from "./reducer_moonRise/reducer_moonRiseDate";
import moonLoaderShowReducer from "./reducer_moonRise/reducer_moonLoaderShow";
import eclipsesDataReducer from "./reducer_eclipsesData";
import eclipsesDataTableReducer from "./reducer_eclipsesDataTable";
import eclipsesCatelogNextEclipseReducer from "./reducer_eclipsesCatelogNextEclipse";
import weatherInfoReducer from "./reducer_weatherInfo";
import weatherInfoOpenReducer from "./reducer_weatherInfoOpen";
import airportsListReducer from "./reducer_airportsList";
import holidaysBlockReducer from "./reducer_holidaysBlock";
import flightTimeInfoReducer from "./reducer_flightTimeInfo";
import businesDTDReducer from "./reducer_businessDateToDateCalc";
import countdownReducer from "./reducer_CountdownInfo";
import timeZoneConverterReducer from "./reducer_timeZoneConverter/reducer_timeZoneConverter";
import timeZoneConverterTimeReducer from "./reducer_timeZoneConverter/reducer_timeZoneConverterTime";
import timeZoneConverterOptionsReducer from "./reducer_timeZoneConverter/reducer_timeZoneConverterOptions";
import newYearCountdownReducer from "./reducer_newYearCountdown";
import planetaryHoursReducer from "./reducer_planetaryHours/reducer_planetaryHours";
import planetaryHoursTableReducer from "./reducer_planetaryHours/reducer_planetaryHoursTable";
import nextPlanetRetrogradeReducer from "./reducer_nextPlanetRetrograde";
import planetesInSignsReducer from "./reducer_planetesInSigns";
import planetesRetrogradeReducer from "./reducer_planetesRetrograde";
import {
  reducer_stockExchangeList,
  reducer_stockExchangeSingle
} from "./reducer_stockExchange";

const rootReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  authorizationReducer: authorizationReducer,
  newRegisteredEmailReducer: newRegisteredEmailReducer,
  currentCityInfoReducer: currentCityInfoReducer,
  defaultCityInfoReducer: defaultCityInfoReducer,
  mainCityInfoReducer: mainCityInfoReducer,
  searchStockCityInfoReducer: searchStockCityInfoReducer,
  selectedCitiesReducer: selectedCitiesReducer,
  timePlanerSwipeBlockReducer: timePlanerSwipeBlockReducer,
  currentSearchReducer: currentSearchReducer,
  searchOpenReducer: searchOpenReducer,
  currentNewsReducer: currentNewsReducer,
  calendarDateReducerSun: calendarDateReducerSun,
  calendarDateTableSunReducer: calendarDateTableSunReducer,
  sunCalcReducer: sunCalcReducer,
  sunRisePositionReducer: sunRisePositionReducer,
  sunRiseCoordsReducer: sunRiseCoordsReducer,
  sunRiseShadowReducer: sunRiseShadowReducer,
  sunRiseTableListReducer: sunRiseTableListReducer,
  sunRiseDateReducer: sunRiseDateReducer,
  sunRiseTimeReducer: sunRiseTimeReducer,
  sunLoaderShowReducer: sunLoaderShowReducer,
  calendarDateReducerMoon: calendarDateReducerMoon,
  calendarDateTableMoonReducer: calendarDateTableMoonReducer,
  moonCalcReducer: moonCalcReducer,
  moonRisePositionReducer: moonRisePositionReducer,
  moonRiseCoordsReducer: moonRiseCoordsReducer,
  moonRiseShadowReducer: moonRiseShadowReducer,
  moonRiseTableListReducer: moonRiseTableListReducer,
  moonRiseDateReducer: moonRiseDateReducer,
  moonLoaderShowReducer: moonLoaderShowReducer,
  eclipsesDataReducer: eclipsesDataReducer,
  eclipsesDataTableReducer: eclipsesDataTableReducer,
  eclipsesCatelogNextEclipseReducer: eclipsesCatelogNextEclipseReducer,
  weatherInfoReducer: weatherInfoReducer,
  weatherInfoOpenReducer: weatherInfoOpenReducer,
  airportsListReducer: airportsListReducer,
  holidaysBlockReducer: holidaysBlockReducer,
  flightTimeInfoReducer: flightTimeInfoReducer,
  businesDTDReducer: businesDTDReducer,
  countdownReducer: countdownReducer,
  timeZoneConverterReducer: timeZoneConverterReducer,
  timeZoneConverterTimeReducer: timeZoneConverterTimeReducer,
  timeZoneConverterOptionsReducer: timeZoneConverterOptionsReducer,
  newYearCountdownReducer: newYearCountdownReducer,
  planetaryHoursReducer: planetaryHoursReducer,
  planetaryHoursTableReducer: planetaryHoursTableReducer,
  nextPlanetRetrogradeReducer: nextPlanetRetrogradeReducer,
  planetesInSignsReducer: planetesInSignsReducer,
  planetesRetrogradeReducer: planetesRetrogradeReducer,
  stockExchange: reducer_stockExchangeList,
  stockExchangeSing: reducer_stockExchangeSingle
});

export default rootReducer;
