import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone/index.js';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Slider from 'material-ui/Slider';

import './ContentBlock.css';
import CityBlocks from './CityBlocks/CityBlocks';
import Options from './Options/Options';
import timeZoneConverter from '../../../../../actions/action_timeZoneConverter/action_timeZoneConverter.js';
import timeZoneConverterTime from '../../../../../actions/action_timeZoneConverter/action_timeZoneConverterTime.js';
// import timeZoneConverterOptions from '../../../../../actions/action_timeZoneConverter/action_timeZoneConverterOptions.js';

class ContentBlock extends Component {
    timeChange = (event, value) => {
        const { timeZoneConverterTime, timeZoneConverterReducer } = this.props;
        if(timeZoneConverterReducer.length){
            const city = timeZoneConverterReducer[0];
            const zeroTime = +moment().tz(city.timezone).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format('x');
            
            const timeMoment = moment(zeroTime + value * 60000).tz(city.timezone);
            const year = timeMoment.get('year');
            const month = timeMoment.get('month');  // 0 to 11
            const day = timeMoment.get('date');
            const hour = timeMoment.get('hour');
            const minute = timeMoment.get('minute');
            const second = timeMoment.get('second');
            const millisecond = timeMoment.get('millisecond');
            const time = new Date(year, month, day, hour, minute, second, millisecond);
            timeZoneConverterTime({
                calendarDate: time,
                sliderDot: value
            })
        }
    };
    clearCityList = () => {
        const { timeZoneConverter } = this.props;
        timeZoneConverter([]);
        localStorage.setItem('timeZoneConverterList', JSON.stringify([]));
    };
    sliderTime(){
        const { timeZoneConverterReducer, timeZoneConverterTimeReducer, format24, format12 } = this.props
        const time = moment(timeZoneConverterTimeReducer.calendarDate);
        if(format12 === true) return time.format('LT');
        else if(format24 === true) return time.format('HH:mm');
        else{
            if(timeZoneConverterReducer[0].time_format === 'AM_PM') return time.format('LT');
            else return time.format('HH:mm');
        }
    }
    sliderPositionStyle(){
        const { timeZoneConverterTimeReducer, timeZoneConverterOptionsReducer } = this.props;
        const left = timeZoneConverterTimeReducer.sliderDot / (1440 * 0.01);
        const hours = +moment(timeZoneConverterTimeReducer.calendarDate).format('HH');
        const converterStyle = val => {
            if(hours <= 5 || hours >= 22) return val === 'background' ? '#91a1be' : '1px solid #91a1be';
            else if((6 <= hours && hours < 8) || (18 <= hours && hours < 22)) {
                return val === 'background' ? '#c0ddf0' : '1px solid #c0ddf0';
            }else if(8 <= hours && hours < 18) return val === 'background' ? '#ffed9e' : '1px solid #ffed9e';
        }
        return {
            display: timeZoneConverterOptionsReducer.checked4 === true ? 'block' : 'none',
            left: left > 50 ? 'auto' : `${left}%`,
            right: left > 50 ? `${100 - left}%` : `auto`,
            background: converterStyle('background'),
            border: converterStyle('border')
        }
    }
    sliderPositionDiscription(){
        const { timeZoneConverterTimeReducer } = this.props;
        const hours = +moment(timeZoneConverterTimeReducer.calendarDate).format('HH');
        if(hours <= 5 || hours >= 22) return 'Not good';
        else if((6 <= hours && hours < 8) || (18 <= hours && hours < 22)) return 'Not so good';
        else if(8 <= hours && hours < 18) return 'Good';
    }
        sliderPositionDiscriptionStyle(){
        const { timeZoneConverterOptionsReducer } = this.props;
        return timeZoneConverterOptionsReducer.checked4 === true ? { display: 'block'} : { display: 'none' };
    }
    render() {
        const { timeZoneConverterTimeReducer, timeZoneConverterReducer } = this.props;
        if(!timeZoneConverterReducer.length){
            return (
                <div className='empty-timezone-list'>
                    <h3>Start by adding a city to convert from</h3>
                </div>
            )
        }
        return (
            <div className='ContentBlock'>                
                <CityBlocks format24={this.props.format24} format12={this.props.format12} calendarDate={timeZoneConverterTimeReducer.calendarDate} />
                <div className='time-converter-slider'>
                    <MuiThemeProvider>
                        <Slider
                            className='slider'
                            min={0}
                            max={1439}
                            step={1}
                            value={timeZoneConverterTimeReducer.sliderDot}
                            onChange={this.timeChange}
                        />
                    </MuiThemeProvider>
                    <span style={this.sliderPositionStyle()}>{this.sliderTime()}</span>
                </div>
                <b style={this.sliderPositionDiscriptionStyle()}>{this.sliderPositionDiscription()}</b>
                <Options />
                <button className='locations-clear' onClick={this.clearCityList}>Clear all locations & start again</button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        timeZoneConverterReducer: state.timeZoneConverterReducer,
        timeZoneConverterTimeReducer: state.timeZoneConverterTimeReducer,
        timeZoneConverterOptionsReducer: state.timeZoneConverterOptionsReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        timeZoneConverter: bindActionCreators(timeZoneConverter, dispatch),
        timeZoneConverterTime: bindActionCreators(timeZoneConverterTime, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentBlock);