import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

const validate = values => {
  const errors = {};
  if (!values) errors[values] = "Required";
  if (values.password1 !== values.password2) {
    errors.password2 = "Passwords do not match";
  }
  if (values.password1) {
    if (values.password1.length < 8) {
      errors.password1 = "Password must contain at least 8 characters";
    }
  }
  return errors;
};

class PasswordBlock extends Component {
  state = {
    disabled: "disabled"
  };
  renderField = ({
    input,
    label,
    type,
    showPlaceholder,
    meta: { touched, error, warning }
  }) => {
    return (
      <div className="authorisation-form-input">
        <label>{label}</label>
        <div>
          <input
            {...input}
            placeholder={showPlaceholder ? "********" : ""}
            type={type}
            disabled={this.state.disabled}
          />
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };
  editeAvalible = e => {
    this.setState({ disabled: "" });
  };
  render() {
    const { handleSubmit, passwordResponse } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="profile-block">
          <Field
            name="passwordOld"
            type="password"
            label="Password"
            component={this.renderField}
            showPlaceholder={true}
            disabled
          />
          <button type="button" onClick={this.editeAvalible}>
            Change
          </button>
          <button
            type="submit"
            onMouseUp={() => {
              this.setState({ disabled: "disabled" });
            }}
          >
            Save
          </button>
          {passwordResponse === "fail" ? (
            <p className="profile-block__error-pass-note">
              Something went wrong, try again
            </p>
          ) : (
            <p className="profile-block__success-pass-note">
              {passwordResponse}
            </p>
          )}
        </div>
        {this.state.disabled !== "disabled" ? (
          <div>
            <div className="profile-block">
              <Field
                name="password1"
                type="password"
                label="Enter New Password"
                component={this.renderField}
                disabled={this.state}
              />
            </div>
            <div className="profile-block">
              <Field
                name="password2"
                type="password"
                label="Re-enter New Password"
                component={this.renderField}
                disabled={this.state}
              />
            </div>
          </div>
        ) : null}
      </form>
    );
  }
}

export default reduxForm({ form: "EditPersonalInfo", validate })(PasswordBlock);
