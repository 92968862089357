import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Moment from "moment-timezone";
import { extendMoment } from "moment-range";

import TitleList from "./ScheduleTable/TitleList";
import ChartItem from "./ScheduleTable/ChartItem";

import Api from "../../../../../services/api.js";
import "./HolidaysTimePlaner.css";

const moment = extendMoment(Moment);

const daysDivider = Array.apply(null, Array(24)).map(function(x, i) {
  return i;
});

class HolidaysTimePlaner extends Component {
  state = {
    googleEvents: [],
    outlookEvents: [],
    icloudEvents: [],
    exchanges: []
  };

  componentDidMount() {
    this.handleGetExchanges();
    this.handleGetEvents();
  }

  async handleGetEvents() {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");

    // this.getUserGoogleCalendars(token);

    const data = await Api.getAllEvents(token);
    if (data.data.google_sh) {
      this.setState({ googleEvents: data.data.google_sh });
    }
    if (data.data.outlook_sh) {
      this.setState({ outlookEvents: data.data.outlook_sh });
    }
    if (data.data.icloud_sh) {
      this.setState({ icloudEvents: data.data.icloud_sh });
    }
  }

  // getUserGoogleCalendars = async token => {
  // 	await Api.getGoogleCalendars(token).then(res => {
  // 		if (res.status === 200) {
  // 			if (res.data.result === "No key") {
  // 				this.setState({ googleEvents: [] });
  // 			} else {
  // 				this.setState({ googleEvents: res.data.evnts });
  // 			}
  // 		}
  // 	});
  // };

  async handleGetExchanges() {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");

    const GET_EXCHANGES = token
      ? [{ act: "get_for_user" }]
      : this.props.currentCityInfoReducer.country_code &&
        this.props.currentCityInfoReducer.country_code !== "US"
      ? [
          {
            act: "get_for_user",
            country_code: this.props.currentCityInfoReducer.country_code
          }
        ]
      : [{ act: "get_for_user" }];

    const alphabeticalSort = sourceExchanges => {
      const sortedExchanges = sourceExchanges.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return sortedExchanges;
    };

    const data = await Api.UserRequestGetPost(
      "stock-exchanges-user/",
      token,
      GET_EXCHANGES
    );

    if (
      data.data.result[0] &&
      data.data.result[0].stock_exchanged &&
      data.data.result[0].stock_exchanged.length > 0
    ) {
      this.setState({
        exchanges: alphabeticalSort(data.data.result[0].stock_exchanged)
      });
    } else {
      delete GET_EXCHANGES[0].country_code;
      // GET_EXCHANGES[0].country_code = "US";
      const data = await Api.UserRequestGetPost(
        "stock-exchanges-user/",
        token,
        GET_EXCHANGES
      );

      if (
        data.data.result[0] &&
        data.data.result[0].stock_exchanged &&
        data.data.result[0].stock_exchanged.length > 0
      ) {
        this.setState({
          exchanges: alphabeticalSort(data.data.result[0].stock_exchanged)
        });
      }
    }
  }

  handleGetTimeRange = (timeFrom, timeTo) =>
    moment.duration(moment(timeTo).diff(moment(timeFrom))).asHours();

  handleGetStartExchangeTime = (currentTime, startTime) =>
    moment(startTime).hour() +
    moment(currentTime).diff(moment(currentTime), "hours") +
    moment(startTime).minute() / 60;

  render() {
    const { googleEvents, outlookEvents, icloudEvents, exchanges } = this.state;

    if (
      exchanges.length == 0 &&
      googleEvents.length == 0 &&
      outlookEvents.length == 0 &&
      icloudEvents.length == 0
    ) {
      return null;
    }

    return (
      <div className="holidays-time-planer">
        {exchanges.length > 0 ||
        googleEvents.length > 0 ||
        outlookEvents.length > 0 ||
        icloudEvents.length > 0 ? (
          <TitleList
            exchanges={exchanges}
            googleEvents={googleEvents}
            outlookEvents={outlookEvents}
            icloudEvents={icloudEvents}
          />
        ) : null}

        <div className="timetable">
          {daysDivider.map((item, key) => (
            <span key={key}></span>
          ))}

          <div className="timetable-items-wrapper">
            {exchanges.length > 0 &&
              exchanges.map((exchange, key) => {
                const USER_TIME = moment.tz(
                  moment.tz(exchange.curentTime_stock, exchange.timezone_stock),
                  this.props.selectedCitiesReducer.cities[0].timezone
                );

                const EXCHANGE_START_TIME = moment.tz(
                  moment.tz(
                    exchange.schedule[0].sh[0].loc_time_start,
                    exchange.timezone_stock
                  ),
                  this.props.selectedCitiesReducer.cities[0].timezone
                );

                const lastTimePart = exchange.schedule[0].sh.length - 1;
                const EXCHANGE_END_TIME = moment.tz(
                  moment.tz(
                    exchange.schedule[0].sh[lastTimePart].loc_time_end,
                    exchange.timezone_stock
                  ),
                  this.props.selectedCitiesReducer.cities[0].timezone
                );

                if (
                  this.handleGetStartExchangeTime(
                    USER_TIME,
                    EXCHANGE_START_TIME
                  ) +
                    this.handleGetTimeRange(
                      EXCHANGE_START_TIME,
                      EXCHANGE_END_TIME
                    ) >
                  24
                ) {
                  return (
                    <div className="timetable_item__wrapper" key={key}>
                      <ChartItem
                        positionFromStart={true}
                        exchangeName={exchange.name}
                        exchangeStartTime={EXCHANGE_START_TIME}
                        exchangeEndTime={EXCHANGE_END_TIME}
                        userTime={USER_TIME}
                        selectedDate={this.props.selectedDate}
                        handleGetStartExchangeTime={
                          this.handleGetStartExchangeTime
                        }
                        handleGetTimeRange={this.handleGetTimeRange}
                      />
                      <ChartItem
                        exchangeName={exchange.name}
                        exchangeStartTime={EXCHANGE_START_TIME}
                        exchangeEndTime={EXCHANGE_END_TIME}
                        userTime={USER_TIME}
                        selectedDate={this.props.selectedDate}
                        handleGetStartExchangeTime={
                          this.handleGetStartExchangeTime
                        }
                        handleGetTimeRange={this.handleGetTimeRange}
                      />
                    </div>
                  );
                }

                return (
                  <div className="timetable_item__wrapper" key={key}>
                    <ChartItem
                      exchangeName={exchange.name}
                      exchangeStartTime={EXCHANGE_START_TIME}
                      exchangeEndTime={EXCHANGE_END_TIME}
                      userTime={USER_TIME}
                      selectedDate={this.props.selectedDate}
                      handleGetStartExchangeTime={
                        this.handleGetStartExchangeTime
                      }
                      handleGetTimeRange={this.handleGetTimeRange}
                    />
                  </div>
                );
              })}

            {/* Events */}
            {googleEvents.length > 0 ||
            outlookEvents.length > 0 ||
            icloudEvents.length > 0
              ? [...googleEvents, ...outlookEvents, ...icloudEvents].map(
                  (event, key) => {
                    const USER_TIME = moment.tz(
                      event.start,
                      this.props.selectedCitiesReducer.cities[0].timezone
                    );
                    const EVENT_START_TIME = moment.tz(
                      event.start,
                      this.props.selectedCitiesReducer.cities[0].timezone
                    );

                    const EVENT_END_TIME = moment.tz(
                      event.end,
                      this.props.selectedCitiesReducer.cities[0].timezone
                    );

                    if (
                      this.handleGetStartExchangeTime(
                        USER_TIME,
                        EVENT_START_TIME
                      ) +
                        this.handleGetTimeRange(
                          EVENT_START_TIME,
                          EVENT_END_TIME
                        ) >
                      24
                    ) {
                      return (
                        <Fragment key={key}>
                          <div className="timetable_item__wrapper timetable_item__wrapper-event">
                            <ChartItem
                              positionFromStart={true}
                              exchangeName={event.summary}
                              exchangeStartTime={EVENT_START_TIME}
                              exchangeEndTime={EVENT_END_TIME}
                              userTime={USER_TIME}
                              selectedDate={this.props.selectedDate}
                              handleGetStartExchangeTime={
                                this.handleGetStartExchangeTime
                              }
                              handleGetTimeRange={this.handleGetTimeRange}
                            />
                            <ChartItem
                              exchangeName={event.summary}
                              exchangeStartTime={EVENT_START_TIME}
                              exchangeEndTime={EVENT_END_TIME}
                              userTime={USER_TIME}
                              selectedDate={this.props.selectedDate}
                              handleGetStartExchangeTime={
                                this.handleGetStartExchangeTime
                              }
                              handleGetTimeRange={this.handleGetTimeRange}
                            />
                          </div>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={key}>
                          <div className="timetable_item__wrapper timetable_item__wrapper-event">
                            <ChartItem
                              exchangeName={event.summary}
                              exchangeStartTime={EVENT_START_TIME}
                              exchangeEndTime={EVENT_END_TIME}
                              userTime={USER_TIME}
                              selectedDate={this.props.selectedDate}
                              handleGetStartExchangeTime={
                                this.handleGetStartExchangeTime
                              }
                              handleGetTimeRange={this.handleGetTimeRange}
                            />
                          </div>
                        </Fragment>
                      );
                    }
                  }
                )
              : null}
            {/* Events end */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCitiesReducer: state.selectedCitiesReducer,
    currentCityInfoReducer: state.currentCityInfoReducer
  };
};

export default connect(mapStateToProps)(HolidaysTimePlaner);
