import React, { Component } from "react";

import "./MailSent.css";

class MailSent extends Component {
	render() {
		return (
			<div className="authorization-wrapper">
				<section className="authorization">
					<h4>Password recovery</h4>
					<div className="authorization-text">
						<p className="recovery-note">
							We sent you a password recovery email, please follow
							the instructions.
						</p>
					</div>
				</section>
			</div>
		);
	}
}

export default MailSent;
