import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment-timezone/index.js";

import './PlanetaryHoursTable.css';
import Loader from "../../../../components/Main/Loader/Loader";
import planetList from "../planetList";
import PoveredBy from "../../../../components/Main/MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PoveredBy/PoveredBy";
import planetaryHoursTable from '../../../../actions/action_planetaryHours/action_planetaryHoursTable';

import Api from '../../../../services/api.js';

class PlanetaryHoursTable extends Component {
    state = {
        dayNightList: {},
        planetList: []
    }
    async componentDidMount(){
        const { currentSearchReducer, planetaryHoursTable } = this.props;
        const date = moment(currentSearchReducer.time).tz(currentSearchReducer.timezone).format('YYYY-MM-DD');
        const eclipses = await Api.userCityList(`geo_names/${currentSearchReducer.id}/rise_set_rise/${date}/`);
        
        const twoPlanetList = planetList.concat(planetList);
        this.setState({ planetList: twoPlanetList.concat(twoPlanetList) });
        planetaryHoursTable(eclipses.data.data);
    }
    dayFind(){
        const { currentSearchReducer, planetaryHoursTableReducer } = this.props;
        // time find
        const rise = planetaryHoursTableReducer.rise;
        const tz = currentSearchReducer.timezone;
        // planet find
        const dayName = moment(rise).tz(tz).format('dddd');
        return this.state.planetList.find(elem => elem.day === dayName);
    }
    hourLengthDay(format){
        const { currentSearchReducer, planetaryHoursTableReducer } = this.props;
        // time find
        const rise = planetaryHoursTableReducer.rise;
        const set = planetaryHoursTableReducer.set;
        const tz = currentSearchReducer.timezone;
        if(format === 'hours'){
            let hours = Math.floor(moment(set).tz(tz).diff(moment(rise).tz(tz)) / 12 / (60 * 60 *1000));
            const minutes = moment(set).tz(tz).diff(moment(rise).tz(tz)) / 12 / (60 * 1000);
            const minutesCheck = Math.round(minutes) > 59 ? Math.round(minutes) - 60 : Math.round(minutes);
            if(59.4444444444 < minutes && minutes < 60) hours += 1;
            const minutesFormat = (minutesCheck + '').length < 2 ? `0${minutesCheck}` : minutesCheck;
            return `${hours}:${minutesFormat} hrs`;
        }else return moment(set).tz(tz).diff(moment(rise).tz(tz));
        
    }
    hourLengthNight(format){
        const { currentSearchReducer, planetaryHoursTableReducer } = this.props;
        // time find
        const set = planetaryHoursTableReducer.set;
        const nextRise = planetaryHoursTableReducer.next_rise;
        const tz = currentSearchReducer.timezone;
        if(format === 'hours'){
            let hours = Math.floor(moment(nextRise).tz(tz).diff(moment(set).tz(tz)) / 12 / (60 * 60 *1000));
            const minutes = moment(nextRise).tz(tz).diff(moment(set).tz(tz)) / 12 / (60 * 1000);
            const minutesCheck = Math.round(minutes) > 59 ? Math.round(minutes) - 60 : Math.round(minutes);
            if(59.4444444444 < minutes && minutes < 60) hours += 1;
            const minutesFormat = (minutesCheck + '').length < 2 ? `0${minutesCheck}` : minutesCheck;
            return `${hours}:${minutesFormat} hrs`;
        }else return moment(nextRise).tz(tz).diff(moment(set).tz(tz));
    }
    dayNightTable(){
        const { currentSearchReducer, planetaryHoursTableReducer } = this.props;
        // time find
        const rise = planetaryHoursTableReducer.rise;
        const set = planetaryHoursTableReducer.set;
        const tz = currentSearchReducer.timezone;
        const day = this.hourLengthDay();
        const night = this.hourLengthNight();
        
        // planet find
        const dayFind = this.dayFind();
        const dayNumm = this.state.planetList.indexOf(dayFind);
        const planetListLeft = this.state.planetList.slice(0, dayNumm);
        const planetListright = this.state.planetList.slice(dayNumm);
        const planetNameArr = planetListright.concat(planetListLeft);
        
        // array creation
        const dayArr = [];
        for(let i = 0; i < 12; i++){
            dayArr.push({
                from: moment(+moment(rise).tz(tz).format('x') + day / 12 * i).tz(tz).format(),
                to: moment(+moment(rise).tz(tz).format('x') + day / 12 * (i + 1)).tz(tz).format(),
                planet: planetNameArr[i],
                i: i
            });
        }
        const nightArr = [];
        for(let i = 0; i < 12; i++){
            nightArr.push({
                from: moment(+moment(set).tz(tz).format('x') + night / 12 * i).tz(tz).format(),
                to: moment(+moment(set).tz(tz).format('x') + night / 12 * (i + 1)).tz(tz).format(),
                planet: planetNameArr[i + 12],
                i:  i + 12
            });
        }
        const dayObj = {
            title: 'Planetary Hours of the Day',
            arr: dayArr
        };
        const nightObj = {
            title: 'Planetary Hours of the Night',
            arr: nightArr
        };
        return [dayObj, nightObj];
    }
    planetSign(element){
        if(element.planet){
            const { currentSearchReducer } = this.props;
            const planetObj = currentSearchReducer.ephemeris.filter(elem => elem.name === element.planet.name);
            return `${Api.url}${planetObj[0].body_image}`;
        }
    }
    hourRuler(elem){
        const { currentSearchReducer, planetaryHoursReducer } = this.props;
        const tz = currentSearchReducer.timezone;
        const tzNum = moment().tz(tz).format('Z');
        const arr = this.dayNightTable();
        
        const cityTime = moment().tz(tz);
        // const tzNum = cityTime.format('Z')
        const cityHour = cityTime.get('hour');
        const cityMinute = cityTime.get('minute');
        const citySecond = cityTime.get('second');
        const cityMillisecond = cityTime.get('millisecond');
        const newCityTime = moment(planetaryHoursReducer).set('hour', cityHour).set('minute', cityMinute).set('second', citySecond).set('millisecond', cityMillisecond);
        const time = newCityTime.format(`YYYY-MM-DDTHH:mm:ss${tzNum}`);


        const dayCheck = arr[0].arr.filter(elem => {
            return +moment(elem.from).tz(tz).format('x') < +moment(time).tz(tz).format('x') &&
                +moment(time).tz(tz).format('x') < +moment(elem.to).tz(tz).format('x');
        });

        const hourRulerBold = (element, dayNight) => {
            switch(dayNight){
                case 'day':
                    return elem.from === element.from && elem.to === element.to ? { fontWeight: 'bold'} : {};
                case 'night':
                    return elem.from === element.from && elem.to === element.to ? { fontWeight: 'bold'} : {};
                default:
                    return {};
            }
        }
        if(dayCheck.length) return elem ? hourRulerBold(dayCheck[0], 'day') : dayCheck[0].planet.name;
        else {
            const nightCheck = arr[1].arr.filter(elem => {
                return +moment(elem.from).tz(tz).format('x') < +moment(time).tz(tz).format('x') &&
                    +moment(time).tz(tz).format('x') < +moment(elem.to).tz(tz).format('x');
            });
            if(elem) {
                return nightCheck.length ? hourRulerBold(nightCheck[0], 'night'): hourRulerBold('-', '-');
            } else return nightCheck.length ? nightCheck[0].planet.name : '-';
        }
    }
    render() {
        const { currentSearchReducer, planetaryHoursTableReducer } = this.props;
        if(!planetaryHoursTableReducer.rise || !this.state.planetList.length) return <div className='PlanetaryHoursTable'><Loader/></div>;
        // if(this.state.planetList.length <= 7) return <div className='PlanetaryHoursTable'><Loader/></div>;
        return (
            <div className='PlanetaryHoursTable'>
                <span><i>Current Time:</i> {moment().tz(currentSearchReducer.timezone).format('LT')}</span>
                <span><i>Ruler of the Day:</i> {this.dayFind().name}</span>
                <span><i>Ruler of the Hour:</i> {this.hourRuler()}</span>
                <span><i>Length of Planetary Hours (day):</i> {this.hourLengthDay('hours')}</span>
                <span><i>Length of Planetary Hours (night):</i> {this.hourLengthNight('hours')}</span>
                <div className='table'>
                    {this.dayNightTable().map((elem, index) => 
                        <div key={index} className='daily-hours'>
                            <span>{elem.title}</span>
                            <div key={index} className='daily-hours-info'>
                                <div className='table-title'>
                                    <div className='order-number'><b>Hour</b></div>
                                    <div className='from-time-to-time'><b>Start - End</b></div>
                                    <div className='sign'><b>Ruler of the Hour</b></div>
                                </div>
                                <div className='table-list'>
                                    {elem.arr.map((elemTime, indexTime) => 
                                        <div key={indexTime} className='table-line' style={this.hourRuler(elemTime)}>
                                            <div className='order-number'>{indexTime + 1}</div>
                                            <div className='from-time-to-time'>
                                                {moment(elemTime.from).tz(currentSearchReducer.timezone).format('LT')} - {moment(elemTime.to).tz(currentSearchReducer.timezone).format('LT')}
                                            </div>
                                            <div className='sign'>
                                                {/* <Link to={`Moon-retrograde`}> */}
                                                    <img src={this.planetSign(elemTime)} alt="geotimedate"/> {elemTime.planet.name}
                                                {/* </Link> */}
                                                {/* {this.planetSign(elemTime)} */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <PoveredBy />
                <div className='text-block-fish'>
                    <h4><b>Planetary Hours</b></h4>
                    <h4>What are ‘Planetary Hours’?</h4>
                    <p>‘Planetary hours’ is an ancient system in which one of the planets is ‘Ruler of the day’ and each planetary hour is ruled by a different planet. The planets are Saturn, Jupiter, Mars, Sun, Venus, Mercury and the Moon and they repeat in this order. The planet that rules the first hour of the day is also the ruler of the whole day and gives the day its name.</p>
                    <h4>Which planets rule which days?</h4>
                    <p>
                        Sunday is ruled by the Sun <br/>
                        Monday is ruled by the Moon<br/>
                        Tuesday is ruled by Mars<br/>
                        Wednesday is ruled by Mercury<br/>
                        Thursday is ruled by Jupiter<br/>
                        Friday is ruled by Venus<br/>
                        Saturday is ruled by Saturn.
                    </p>
                    <h4>How are Planetary Hours calculated?</h4>
                    <p>‘Planetary hours’ are calculated using the Chaldean order to divide time.  The day is split into two periods -day time and night time. Sunrise to sunset is considered daytime and sunset to next day`s sunrise is night time. These two periods are each divided into twelve equal length hours called planetary hours. Depending upon the location and day of the year, the planetary hours of the day and night will be of different lengths and start-end at different time.</p>
                    <p>To know more about planet retrogrades in depth, you can visit - 
                        <a href={`https://en.wikipedia.org/wiki/Planetary_hours`}>https://en.wikipedia.org/wiki/Planetary_hours</a>
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetaryHoursReducer: state.planetaryHoursReducer,
        planetaryHoursTableReducer: state.planetaryHoursTableReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        planetaryHoursTable: bindActionCreators(planetaryHoursTable, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanetaryHoursTable);