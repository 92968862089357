import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone/index.js';
import MetaTags from 'react-meta-tags';
// import { Link, BrowserRouter } from 'react-router-dom';
// import { Paper, Set, Circle, Image, Text, Path, Rect } from "react-raphael";
// import moment from 'moment-timezone/index.js';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import SelectField from 'material-ui/SelectField';
// import MenuItem from 'material-ui/MenuItem';
// import { StickyTable, Row, Cell } from 'react-sticky-table';
import 'react-sticky-table/dist/react-sticky-table.css';

import './Holidays.css';
import Loader from "../../../../../../components/Main/Loader/Loader";
import HeadHolidays from './HeadHolidays/HeadHolidays';
import TableHolidays from './TableHolidays/TableHolidays';
import Panel from './Panel/Panel';
import currentSearch from "../../../../../../actions/action_searchCity/action_currentSearch";
import weatherInfo from "../../../../../../actions/action_weatherInfo";

import Api from '../../../../../../services/api.js';


class Holidays extends Component {
    state = {};
    componentDidMount(){
        this.pareReset();
    }
    // componentDidUpdate(prevProps){
    //     if(prevProps.currentSearchReducer !== this.props.currentSearchReducer) this.opgPicture();
    // }
    // async opgPicture(){
    //     const { currentSearchReducer } = this.props;
    //     if(currentSearchReducer.name){
    //         const newId = currentSearchReducer.url.replace('place/', '').replace(/\//g, '__');
    //         const opgYear = moment().tz(currentSearchReducer.timezone).format('YYYY');
    //         const opgPicture = await Api.userCityList(`opg/holidays/get-url/?geo_name=${newId}&current_year=${opgYear}`);
    //         this.setState(prevState => {
    //             return { opgPicture: opgPicture.data.url}
    //         })
    //     }
    // }
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        if(nextProps.location !== this.props.location) this.pareReset();
    }
    async pareReset(){
        const newId = window.location.pathname.split('/').slice(2).join('__');
        const { currentSearch } = this.props;
        const data = await Api.userCityList(`geo_names/${newId}/`);
        currentSearch(data.data);
        // let corectUrl =  url.split('/').slice(1);
        // corectUrl.splice(1, 1);
        // const holidaysUrl = url.length ? `/holidays/${corectUrl.join('/')}` : `/holidays/null/`;
        // this.props.history.push(holidaysUrl);
    }

    metaCityName(nameType){
        const { currentSearchReducer } = this.props;
            if(currentSearchReducer.name){
            const country = currentSearchReducer.country_name === 'United States' ?
                currentSearchReducer.country_name_abbr : currentSearchReducer.country_name;
            switch(nameType){
                case 'title':
                    return ` ${country} in  ${moment.tz(currentSearchReducer.timezone).format('YYYY')}`;
                case 'description':
                    return ` ${country}`;
                case 'keywords':
                    return `, ${country},`;
                default:
                    return '';
            }
        } else return '';
    }
    render() {
        const {currentSearchReducer} = this.props;
        if (!currentSearchReducer.time) return (<div className="Retrograde"><Loader/></div>);

        return (
            <div className="Retrograde Holidays">
                <MetaTags>
                    <title>List of Holidays in{this.metaCityName('title')}</title>
                    {/*<meta name="description" content={`Find a complete list of holidays in${this.metaCityName('description')} Find a complete list of holidays in ${moment.tz(currentSearchReducer.timezone).format('YYYY')}`} />*/}
                    {/*<meta name="keywords" content={`holidays${this.metaCityName('keywords')} holidays in ${moment.tz(currentSearchReducer.timezone).format('YYYY')}`} />*/}
                    {/*<meta property="og:image" content={`${Api.url}${this.state.opgPicture}`} />*/}
                </MetaTags>
                <div style={{width: '100%'}}>
                    <HeadHolidays/>
                    <div className='retrograde-table holiday_main_container'>
                        <TableHolidays/>
                        <Panel data={currentSearchReducer}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        weatherInfoReducer: state.weatherInfoReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
        weatherInfo: bindActionCreators(weatherInfo, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);