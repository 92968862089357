export default function(values) {
  const errors = {};
  const requiredFields = [
    "reason",
    "firstName",
    "lastName",
    "email",
    "message"
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  // if (!values.reason) {
  //   errors.reason = "Select message reason";
  // }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (values.message && values.message.length < 10) {
    errors.message = "Message must be longer";
  }

  return errors;
}
