import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone';

import PlanetRetrogradesLinks from "../../MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PlanetRetrogradesLinks";
import PlanetSignsLinks from "../../MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PlanetSignsLinks";
import Articles from "./Articles";
import advertisingRight from "../../../../assets/waters.jpg";

class Panel extends Component {

    shouldComponentUpdate(nextProps) {
        return this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise;
    }

    formateTime(time, timezone){
        if(time) return moment(time).tz(timezone).format('hh:mm A');
        return 'N/A';
    }

    formateHinduMoon(time, timezone){
        if(time) return moment(time).tz(timezone).format('MMM DD, hh:mm A');
        return 'N/A';
    }

    render() {
        // console.log('state', this.state)
        const { name, country_name, timezone } = this.props.currentSearchReducer;
        const { panchang } = this.props;
        return (
            <div>
                {panchang.sun_all_day === 'UP_DOWN' &&
                <div>
                    <div className='planet-signs'>
                        <div className='head' style={{background: '#ffb61e'}}>
                            <h5><b>Sun and Moon for {name}, {country_name}</b></h5>
                        </div>
                        <div className="hindu">
                            <div className="text">
                                <p><b>Hindu Sunrise:</b></p>
                                <p><b>Hindu Sunset:</b></p>
                                <p><b>Noon:</b></p>
                                <p><b>Day Length (HH:MM:SS):</b></p>
                                <p><b>Night Length (HH:MM:SS):</b></p>
                                <p><b>Moonrise:</b></p>
                                <p><b>Moonset:</b></p>
                            </div>
                            <div className="value">
                                <p><b>{this.formateTime(panchang.hindu_sunrise, timezone)}</b></p>
                                <p><b>{this.formateTime(panchang.hindu_sunset, timezone)}</b></p>
                                <p><b>{this.formateTime(panchang.hindu_noon, timezone)}</b></p>
                                <p><b>{panchang.hindu_day_length || 'N/A'}</b></p>
                                <p style={{paddingLeft: '1px'}}>
                                    <b>{panchang.hindu_night_length || 'N/A'}</b>
                                </p>
                                <p style={{paddingLeft: '1px'}}>
                                    <b>{this.formateHinduMoon(panchang.hindu_moonrise, timezone)}</b>
                                </p>
                                <p style={{paddingLeft: '1px'}}>
                                    <b>{this.formateHinduMoon(panchang.hindu_moonset, timezone)}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='planet-signs'>
                        <div className='head' style={{background: '#ffb61e'}}>
                            <h5><b>Planet Retrogrades for {name}, {country_name}</b></h5>
                        </div>
                        <PlanetRetrogradesLinks/>
                    </div>
                    <div className="block_baner">
                        <img className="baner" src={advertisingRight} alt="альтернативный текст"></img>
                    </div>
                    <div className='planet-signs'>
                        <div className='head' style={{background: '#ffb61e'}}>
                            <h5><b>Planet in Signs for {name}, {country_name}</b></h5>
                        </div>
                        <PlanetSignsLinks/>
                    </div>
                </div>
                }
                <Articles />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
    };
};

export default connect(mapStateToProps)(Panel);

