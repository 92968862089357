import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TextField from "material-ui/TextField";
import DatePicker from "material-ui/DatePicker";
import TimePicker from "material-ui/TimePicker";
import moment from "moment";

import FlightDetails from "../FlightDetails/FlightDetails";
import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import Ads from "../../Ads/Ads";

import flightTimeInfo from "../../../../../actions/action_flightTimeInfo.js";
import Api from "../../../../../services/api.js";
import "./Calculator.css";

class Calculator extends Component {
	state = {
		departureDate: "",
		departureTime: "",
		arrivalDate: "",
		arrivalTime: "",
		dateStart: "",
		cities: [],
		selectedCity1: "",
		selectedCity2: "",
		selectedCityId1: 0,
		selectedCityId2: 0,
		selectedCityInfo: [],
		selectedCityInfo1: [],
		selectedCityInfo2: [],
		options: [],
		searchPlaceholder1: "",
		searchPlaceholder2: "",
		selectedCityIndex: 0,
		searchOpen: { border: "none", height: 0, margin: "0" },
		addedCity: {},
		listElemIndex: "none",
		selectedElemIndex: "null",
		mousePosTop: 0,
		mousePosLeft: 0,
		departureDateDate: "",
		departureDateTime: "",
		arriveDateDate: "",
		arriveDateTime: "",
		showBlock: false,
		flightTimeInfoReducer: this.props.flightTimeInfoReducer,
		reset: false,
		calculationError: { display: "none" }
	};
	searchClose = document.addEventListener("click", e => {
		if (
			(e.target.className !== "search_form_list1" ||
				e.target.className !== "search_form_list2") &&
			e.target.className !== "search_field"
		) {
			this.setState(prevState => {
				return {
					searchOpen: { border: "none", height: 0, margin: "0" }
				};
			});
		}
	});

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps) {
		this.setState({
			flightTimeInfoReducer: nextProps.flightTimeInfoReducer
		});
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.searchClose);
	}

	async componentDidMount() {
		document.body.addEventListener("keydown", e => {
			if (e.keyCode === 27) {
				this.setState(prevState => {
					return {
						searchOpen: { border: "none", height: 0, margin: "0" }
					};
				});
			}
		});
		this.searchKeyPress();
		this.searchKeyEnter();
	}
	async searchValue(searchNumm, e) {
		const value = e.target.value;
		this.setState(prevState => {
			return searchNumm === 1
				? { selectedCity1: value, selectedCityNumm: 1 }
				: { selectedCity2: value, selectedCityNumm: 2 };
		});
		if (e.target.value.length > 2) {
			const data = await Api.userCityList(
				`search/?q=${e.target.value}&feature_classes=P`
			);
			this.setState(prevState => {
				if (data.data.locations_list.length > 0) {
					document.body
						.querySelector(
							`.search_form_list${this.state.searchActive}`
						)
						.scrollTo(0, 0);
					return {
						listElemIndex: 0,
						cities: data.data.locations_list,
						searchOpen: {
							border: "1px solid rgba(210, 213, 213, 1)",
							height: "auto",
							margin: "5px 0 0"
						}
					};
				} else {
					return {
						cities: [],
						searchOpen: { border: "none", height: 0, margin: "0" }
					};
				}
			});
		} else {
			this.setState(prevState => {
				return {
					cities: [],
					searchOpen: { border: "none", height: 0, margin: "0" }
				};
			});
		}
	}
	selectedCity(
		id,
		name,
		state,
		county,
		country,
		feature,
		index,
		searchNumm,
		e
	) {
		if (e) {
			if (e.button === 1 || e.button === 2) return;
		}
		let newName = "",
			newState = "",
			newCounty = "",
			newCountry = "",
			newFeature = "";
		name ? (newName = `${name}, `) : (newName = "");
		state ? (newState = `${state}, `) : (newState = "");
		county ? (newCounty = `${county}, `) : (newCounty = "");
		country ? (newCountry = `${country}`) : (newCountry = "");
		feature ? (newFeature = `(${feature})`) : (newFeature = "");
		this.setState(prevState => {
			return this.state.selectedCityNumm === 1
				? {searchPlaceholder1: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`}
				: {searchPlaceholder2: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`};
		});
		this.setState(prevState => {
			return {
				selectedCity1: ``,
				selectedCity2: ``,
				selectedCityId: id,
				selectedCityIndex: index,
				searchPlaceholder: "select another city or country",
				cities: [],
				searchOpen: { border: "none", height: 0, margin: "0" }
			};
		});
		this.selectedCityInfo(id, searchNumm);
	}
	async selectedCityInfo(id, searchNumm) {
		const data = await Api.userCityList(`geo_names/${id}/`);
		if (data) {
			this.setState(prevState => {
				return searchNumm === 1
					? { selectedCityInfo1: data.data }
					: { selectedCityInfo2: data.data };
			});
		}
	}
	// search: handling of pressing the arrows (up, down)
	searchKeyPress() {
		document.body.addEventListener("keydown", e => {
			const cityList = [
				...document.body.querySelectorAll(
					`.selectCityWrapper${this.state.searchActive}`
				)
			];
			const scrollBlock = document.body.querySelector(
				`.search_form_list${this.state.searchActive}`
			);
			let height;
			const pressNext = newIndex => {
				const selectedCity = cityList.filter(
					(elem, index) => newIndex === index
				);
				const top = selectedCity[0].offsetTop;
				height = selectedCity[0].offsetHeight;
				return top <= scrollBlock.offsetHeight - height
					? 0
					: top - scrollBlock.offsetHeight + height;
			};
			const pressPrev = newIndex => {
				const selectedCity = cityList.filter(
					(elem, index) => newIndex === index
				);
				const top = selectedCity[0].offsetTop;
				height = selectedCity[0].offsetHeight;
				return top;
			};
			if (
				(this.state.cities.length &&
					e.keyCode === 40 &&
					this.state.listElemIndex === "none") ||
				(this.state.cities.length &&
					e.keyCode === 40 &&
					this.state.listElemIndex >= cityList.length - 1)
			) {
				this.setState(prevState => {
					scrollBlock.scrollTo(0, pressNext(0));
					return { listElemIndex: 0 };
				});
			} else if (
				this.state.cities.length &&
				e.keyCode === 40 &&
				this.state.listElemIndex !== "none"
			) {
				this.setState(prevState => {
					const topPos = pressNext(prevState.listElemIndex + 1);
					if (scrollBlock.scrollTop < topPos) {
						scrollBlock.scrollTo(0, topPos);
					}
					return { listElemIndex: prevState.listElemIndex + 1 };
				});
			} else if (
				(this.state.cities.length &&
					e.keyCode === 38 &&
					this.state.listElemIndex === "none") ||
				(this.state.cities.length &&
					e.keyCode === 38 &&
					this.state.listElemIndex <= 0)
			) {
				this.setState(prevState => {
					const topPos = pressPrev(cityList.length - 1);
					scrollBlock.scrollTo(0, topPos);
					return { listElemIndex: cityList.length - 1 };
				});
			} else if (
				this.state.cities.length &&
				e.keyCode === 38 &&
				this.state.listElemIndex !== "none"
			) {
				this.setState(prevState => {
					const topPos = pressPrev(prevState.listElemIndex - 1);
					if (topPos < scrollBlock.scrollTop) {
						scrollBlock.scrollTo(0, topPos);
					}
					return { listElemIndex: prevState.listElemIndex - 1 };
				});
			}
		});
	}
	// search: enter press
	searchKeyEnter() {
		document.body.addEventListener("keydown", e => {
			if (this.state.cities.length > 0 && e.keyCode === 13) {
				const selectedCity = this.state.cities.filter((elem, index) => {
					return index === this.state.listElemIndex;
				});
				const city = selectedCity[0];
				this.selectedCity(
					city.id,
					city.name,
					city.state,
					city.county,
					city.country,
					city.feature,
					city.index,
					this.state.selectedCityNumm
				);

				this.setState(prevState => {
					return {
						searchOpen: { border: "none", height: 0, margin: "0" }
					};
				});
			}
		});
	}
	// search: mouse pointer processing
	searchMouseEventMouseOver = (index, e) => {
		let mouseTop = e.screenY;
		let mouseLeft = e.screenX;
		this.setState(prevState => {
			if (
				mouseTop !== prevState.mousePosTop ||
				mouseLeft !== prevState.mousePosLeft
			) {
				return {
					listElemIndex: index,
					mousePosTop: mouseTop,
					mousePosLeft: mouseLeft
				};
			}
		});
	};
	searchListHover = (index, e) => {
		this.setState(prevState => {
			return {
				selectedElemIndex: index
			};
		});
	};
	searchStyle(index) {
		if (this.state.selectedElemIndex === index) {
			return { background: "#7ea7d8", color: "#fff" };
		} else if (this.state.listElemIndex === index) {
			return { background: "rgba(103, 150, 206, 1)", color: "#fff" };
		} else {
			return { background: "#fff", color: "rgba(68, 68, 68, 1)" };
		}
	}
	highlight(highlight) {
		return highlight === true ? {} : { fontWeight: "normal" };
	}
	notEmptyName(param) {
		return param ? `${param}` : "";
	}
	notEmptyState(param) {
		return param ? `${param}, ` : "";
	}
	notEmptyFeature(param) {
		return param ? ` (${param})` : "";
	}
	searchFocus = e => {
		if (this.state.cities.length > 0 && e.button === 0) {
			this.setState(prevState => {
				return {
					searchOpen: {
						border: "1px solid rgba(210, 213, 213, 1)",
						height: "auto",
						margin: "5px 0 0"
					}
				};
			});
		}
	};

	searchListOpen(searchNumm) {
		return this.state.searchActive === searchNumm
			? this.state.searchOpen
			: { display: "none" };
	}

	searchListFocus(searchNumm) {
		this.setState(prevState => {
			if (searchNumm === 1) {
				return {
					searchActive: 1,
					selectedCity1: "",
					selectedCity2: "",
					cities: [],
					searchOpen: { border: "none", height: 0, margin: "0" }
				};
			} else {
				return {
					searchActive: 2,
					selectedCity1: "",
					selectedCity2: "",
					cities: [],
					searchOpen: { border: "none", height: 0, margin: "0" }
				};
			}
		});
	}

	handleDepartureDate = (event, date) => {
		this.setState({
			departureDate: date
		});
	};

	handleDepartureTime = (event, time) => {
		this.setState({
			departureTime: time
		});
	};

	handleArrivalDate = (event, date) => {
		this.setState({
			arrivalDate: date
		});
	};

	handleArrivalTime = (event, time) => {
		this.setState({
			arrivalTime: time
		});
	};

	startCalculation = () => {
		if (
			this.state.departureDate &&
			this.state.departureTime &&
			this.state.arrivalDate &&
			this.state.arrivalTime &&
			this.state.selectedCityInfo1.id &&
			this.state.selectedCityInfo2.id
		) {
			this.requestAwait();

			this.setState(prevState => {
				return {
					calculationError: { display: "none" }
				};
			});
		} else {
			this.setState(prevState => {
				return {
					calculationError: { display: "block" }
				};
			});
		}
	};

	async requestAwait() {
		let result = await Api.userCityList(
			`flight_time_calculation/?departure_date=${moment(
				this.state.departureDate
			).format("YYYY-M-D")}T${
				this.state.departureDate === ""
					? "00:00:00"
					: moment(this.state.departureTime).format("H:m:s")
			}&arrival_date=${moment(this.state.arrivalDate).format(
				"YYYY-M-D"
			)}T${
				this.state.arrivalDate === ""
					? "00:00:00"
					: moment(this.state.arrivalTime).format("H:m:s")
			}&departure_geo_name=${
				this.state.selectedCityInfo1.id
			}&arrival_geo_name=${this.state.selectedCityInfo2.id}`
		);
		if (result.data) {
			this.setState(prevState => {
				return {
					result: result.data.data,
					showBlock: true
				};
			});
		}
	}

	render() {
		if (this.state.showBlock === true) {
			this.setState(prevState => {
				return {
					showBlock: false
				};
			});
			this.props.resultFlightCalc(
				this.state.result,
				this.state.showBlock
			);
		}
		return (
			<div className="CalculatorBlock">
				<div className="header">
					<h3>Flight time Calculator</h3>
				</div>

				<div className="Calculator-wrapper">
					<div className="Calculator-content">
						<div className="Calculator">
							<div className="content">
								<h4>
									Please enter the departure and arrival
									details.
								</h4>

								<div className="content-info">
									<div className="content-info-left">
										<MuiThemeProvider>
											<div className="Search">
												<div className="search_form">
													<div className="search_form_select">
														<TextField
															className="city_input"
															floatingLabelStyle={{
																margin: 0
															}}
															floatingLabelFixed={
																this.state
																	.searchPlaceholder1
																	? true
																	: false
															}
															floatingLabelText="City of Departure"
															value={
																this.state
																	.selectedCity1
															}
															hintText={
																this.state
																	.searchPlaceholder1
																	? this.state
																			.searchPlaceholder1
																	: "City or Country"
															}
															onChange={this.searchValue.bind(
																this,
																1
															)}
															onMouseUp={
																this.searchFocus
															}
															onFocus={this.searchListFocus.bind(
																this,
																1
															)}
														/>
														<ul
															style={this.searchListOpen(
																1
															)}
															className="search_form_list search_form_list1"
														>
															{this.state.cities.map(
																(
																	elem,
																	index
																) => (
																	<li
																		key={
																			index
																		}
																	>
																		<div
																			className="selectCityWrapper selectCityWrapper1"
																			style={this.searchStyle(
																				index
																			)}
																			onMouseDown={this.selectedCity.bind(
																				this,
																				elem.id,
																				elem.name,
																				elem.state,
																				elem.county,
																				elem.country_name,
																				elem.feature,
																				elem.index,
																				1
																			)}
																			onClick={this.searchListHover.bind(
																				this,
																				index
																			)}
																			onMouseMove={this.searchMouseEventMouseOver.bind(
																				this,
																				index
																			)}
																		>
																			<div className="mainNameWrapper">
																				<b
																					className="cityMainName"
																					style={this.highlight(
																						elem.highlight
																					)}
																				>
																					{this.notEmptyName(
																						elem.name
																					)}
																				</b>
																				{this.notEmptyFeature(
																					elem.feature
																				)}
																				<img
																					className="cityMainImg"
																					src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
																					alt="citydateandtime"
																				/>
																			</div>
																			{this.notEmptyState(
																				elem.state
																			)}
																			{this.notEmptyState(
																				elem.county
																			)}
																			{this.notEmptyName(
																				elem.country_name
																			)}
																		</div>
																	</li>
																)
															)}
														</ul>
													</div>
												</div>
											</div>
											<DatePicker
												floatingLabelText="Departure Date"
												floatingLabelStyle={{
													margin: 0
												}}
												onChange={
													this.handleDepartureDate
												}
												maxDate={this.state.arrivalDate}
												value={this.state.departureDate}
											/>
											<TimePicker
												floatingLabelText="Departure Time"
												floatingLabelStyle={{
													margin: 0
												}}
												format="24hr"
												onChange={
													this.handleDepartureTime
												}
												value={this.state.departureTime}
											/>
										</MuiThemeProvider>
									</div>

									<div className="content-info-right">
										<MuiThemeProvider>
											<div className="Search">
												<div className="search_form">
													<div className="search_form_select">
														<TextField
															className="city_input"
															floatingLabelStyle={{
																margin: 0
															}}
															floatingLabelFixed={
																this.state
																	.searchPlaceholder2
																	? true
																	: false
															}
															floatingLabelText="City of Arrival"
															value={
																this.state
																	.selectedCity2
															}
															hintText={
																this.state
																	.searchPlaceholder2
																	? this.state
																			.searchPlaceholder2
																	: "City or Country"
															}
															onChange={this.searchValue.bind(
																this,
																2
															)}
															onMouseUp={
																this.searchFocus
															}
															onFocus={this.searchListFocus.bind(
																this,
																2
															)}
														/>

														<ul
															style={this.searchListOpen(
																2
															)}
															className="search_form_list search_form_list2"
														>
															{this.state.cities.map(
																(
																	elem,
																	index
																) => (
																	<li
																		key={
																			index
																		}
																	>
																		<div
																			className="selectCityWrapper selectCityWrapper2"
																			style={this.searchStyle(
																				index
																			)}
																			onMouseDown={this.selectedCity.bind(
																				this,
																				elem.id,
																				elem.name,
																				elem.state,
																				elem.county,
																				elem.country_name,
																				elem.feature,
																				elem.index,
																				2
																			)}
																			onClick={this.searchListHover.bind(
																				this,
																				index
																			)}
																			onMouseMove={this.searchMouseEventMouseOver.bind(
																				this,
																				index
																			)}
																		>
																			<div className="mainNameWrapper">
																				<b
																					className="cityMainName"
																					style={this.highlight(
																						elem.highlight
																					)}
																				>
																					{this.notEmptyName(
																						elem.name
																					)}
																				</b>
																				{this.notEmptyFeature(
																					elem.feature
																				)}
																				<img
																					className="cityMainImg"
																					src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
																					alt="citydateandtime"
																				/>
																			</div>
																			{this.notEmptyState(
																				elem.state
																			)}
																			{this.notEmptyState(
																				elem.county
																			)}
																			{this.notEmptyName(
																				elem.country_name
																			)}
																		</div>
																	</li>
																)
															)}
														</ul>
													</div>
												</div>
											</div>

											<DatePicker
												floatingLabelText="Arrival Date"
												floatingLabelStyle={{
													margin: 0
												}}
												value={this.state.arrivalDate}
												minDate={
													this.state.departureDate
												}
												onChange={
													this.handleArrivalDate
												}
											/>

											<TimePicker
												floatingLabelText="Arrival Time"
												floatingLabelStyle={{
													margin: 0
												}}
												format="24hr"
												onChange={
													this.handleArrivalTime
												}
												value={this.state.arrivalTime}
											/>
										</MuiThemeProvider>
									</div>
								</div>
							</div>

							<div className="row_select_buttom">
								<button
									className={`btn w-197p ${this.state.activeCalcClass}`}
									onClick={this.startCalculation}
								>
									CALCULATE
								</button>
								<b style={this.state.calculationError}>
									Fill in all the fields
								</b>
							</div>
						</div>

						<FlightDetails />

						<Ads />
					</div>
					<CalcSidebar />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		flightTimeInfoReducer: state.flightTimeInfoReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		flightTimeInfo: bindActionCreators(flightTimeInfo, dispatch),
		resultFlightCalc: (result, show) => {
			const payload = {
				result,
				show
			};
			dispatch({ type: "ADD_RESULT_FLIGHT", payload });
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
