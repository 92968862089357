import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone/index.js';

import './Calculator.css';
import currentSearch from '../../../../../actions/action_searchCity/action_currentSearch';
import {bindActionCreators} from "redux";



class ShowCurentDate extends Component {

    state={
        time: moment(),
    }

    currentTime = setInterval(() => {
        const { timezone } = this.props;

        let a = new Date(+Date.now());
        let test = new Date(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate(), a.getUTCHours(), a.getUTCMinutes(), a.getUTCSeconds());
        let time = timezone ? moment(test).tz(timezone) : moment();

        !this.isCancelled && this.setState(prevState => {
            return {
                time,
            };
        });
    }, 1000);

     componentWillUnmount(){
        window.clearTimeout(this.currentTime);
        this.isCancelled = true;
    }

      timeConverter(UNIX_timestamp, CurrentDateTime) {
        let a = CurrentDateTime === 'CurrentDateTime' ? new Date(UNIX_timestamp) : new Date(UNIX_timestamp * 1000);

        const monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let year = a.getFullYear();
        let monthFull = monthsFull[a.getMonth()];
        let dayFull = daysFull[a.getDay()];
        let date = a.getDate();
        let dateFull = a.getDate() < 10 ? '0' + a.getDate() : a.getDate();
        let hour = a.getHours() < 10 ? '0' + a.getHours() : a.getHours();
        let min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
        let sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
        let time = date + ' ' + monthFull + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        if (CurrentDateTime === 'CurrentDateTime') {
            time = `${dayFull}, ${monthFull} ${dateFull}, ${year} ${hour}:${min}:${sec}`;
            return time;
        }
    }

    render() {
        return (
            <div className='input input_your_curent'>
                <p><b>{this.timeConverter(this.state.time._d, 'CurrentDateTime')}</b><span></span></p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCurentDate);