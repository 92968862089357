import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MetaTags from "react-meta-tags";

import Loader from "../../../components/Main/Loader/Loader";
import PagesSelect from "./PagesSelect/PagesSelect";
import AccountInfo from "./AccountInfo/AccountInfo";
import EventsCalendars from "./EventsCalendars/EventsCalendars";
import Groups from "./Groups/Groups";

import authorization from "../../../actions/authorization";
import "./AccountPages.css";

class AccountPages extends Component {
	state = {
		block: "account"
	};
	async componentDidMount() {
		const tabPosition = window.location.hash;
		if (tabPosition === "#groups") this.setState({ block: "groups" });
		else if (tabPosition === "#events") this.setState({ block: "groups" });
	}
	tabSelect = block => {
		if (block === "sign-out") {
			const { authorization } = this.props;
			authorization({});
			if (sessionStorage.getItem("authorization")) {
				sessionStorage.removeItem("authorization");
				sessionStorage.removeItem("token");
			}
			if (localStorage.getItem("authorization")) {
				localStorage.removeItem("authorization");
				localStorage.removeItem("token");
			}
			this.props.history.push("/");
		} else this.setState({ block: block });
	};
	blockChange() {
		if (this.state.block === "account") return <AccountInfo />;
		else if (this.state.block === "events-calendars")
			return <EventsCalendars />;
		else if (this.state.block === "groups") return <Groups />;
		else return <div></div>;
	}
	render() {
		const { authorizationReducer } = this.props;
		if (!authorizationReducer.username)
			return (
				<div className="account-pages">
					<MetaTags>
						<title>GeoTimeDate – My Account</title>
					</MetaTags>
					<Loader />
				</div>
			);
		return (
			<div className="account-pages">
				<MetaTags>
					<title>GeoTimeDate – My Account</title>
				</MetaTags>
				<PagesSelect
					tabSelect={this.tabSelect}
					blockSelect={this.state.block}
				/>
				{this.blockChange()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authorizationReducer: state.authorizationReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPages);
