import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	MuiThemeProvider,
	DatePicker,
	IconButton,
	RaisedButton
} from "material-ui";
import moment from "moment-timezone/index.js";

import "./CityCalendarBlock.css";
import moonCalc from "../../../../actions/action_moonRise/action_moonCalc";
import moonRisePosition from "../../../../actions/action_moonRise/action_moonRisePosition";
import moonRiseCoords from "../../../../actions/action_moonRise/action_moonRiseCoords";
import moonRiseTableList from "../../../../actions/action_moonRise/action_moonRiseTableList";
import moonRiseDate from "../../../../actions/action_moonRise/action_moonRiseDate";
import moonLoaderShow from "../../../../actions/action_moonRise/action_moonLoaderShow";
import calendarDateMoon from "../../../../actions/action_moonRise/action_calendarDateMoon";

import Api from "../../../../services/api.js";

class CityCalendarBlock extends Component {
	async newData(date) {
		const { moonRiseDate } = this.props;
		moonRiseDate(date);
	}
	previousMonth = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { moonRiseDateReducer, moonCalcReducer } = this.props;
			const month = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.get("month");
			const momentTime = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.set("month", month - 1);
			this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
			this.newData(momentTime.format());
		}, 1000);
	};
	previousDay = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { moonRiseDateReducer, moonCalcReducer } = this.props;
			const day = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.get("date");
			const momentTime = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.set("date", day - 1);
			this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
			this.newData(momentTime.format());
		}, 1000);
	};
	currentDay = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { moonCalcReducer } = this.props;
			this.dateChange(
				moment()
					.tz(moonCalcReducer.timezone)
					.format("YYYY-MM-DDTHH:mm:ss")
			);
			this.newData(
				moment()
					.tz(moonCalcReducer.timezone)
					.format()
			);
		}, 1000);
	};
	nextDay = e => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { moonRiseDateReducer, moonCalcReducer } = this.props;
			const day = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.get("date");
			const momentTime = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.set("date", day + 1);
			this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
			this.newData(momentTime.format());
		}, 1000);
	};
	nextMonth = () => {
		window.clearTimeout(this.debounce);
		this.debounce = window.setTimeout(() => {
			const { moonRiseDateReducer, moonCalcReducer } = this.props;
			const month = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.get("month");
			const momentTime = moment(moonRiseDateReducer)
				.tz(moonCalcReducer.timezone)
				.set("month", month + 1);
			this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
			this.newData(momentTime.format());
		}, 1000);
	};
	async dateChange(date) {
		const {
			moonCalcReducer,
			moonCalc,
			moonRisePosition,
			calendarDateMoon,
			moonRiseCoords,
			moonLoaderShow
		} = this.props;
		moonLoaderShow(true);
		const moonCoordinats = await Api.userCityList(
			`geo_names/${moonCalcReducer.id}/?moon_app=true&date=${date}`
		);
		moonCalc(moonCoordinats.data);
		localStorage.setItem(
			"moonCalcCityInfo",
			JSON.stringify(moonCoordinats.data)
		);
		calendarDateMoon(moonCoordinats.data.time);
		const hourCurrent = moment(moonCoordinats.data.time)
			.tz(moonCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(moonCoordinats.data.time)
			.tz(moonCoordinats.data.timezone)
			.get("minute");
		for (let key in moonCoordinats.data.moon_data.position_during_day) {
			const hourDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				moonRiseCoords(+key);
				moonRisePosition(
					moonCoordinats.data.moon_data.position_during_day[+key]
				);
			}
		}
		moonLoaderShow(false);
	}
	equinoxSolstice(date) {
		const { moonCalcReducer } = this.props;
		const momentTime = moment(date).tz(moonCalcReducer.timezone);
		this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
		this.newData(momentTime.format());
	}
	month(date) {
		const { moonCalcReducer } = this.props;
		return moment(date)
			.tz(moonCalcReducer.timezone)
			.format("ll");
	}
	monthTitle(date) {
		const { moonCalcReducer } = this.props;
		return `
            ${moment(date)
				.tz("Greenwich")
				.format("MM-DD-YYYY")} ${moment(date)
			.tz("Greenwich")
			.format("HH:mm")} UTC
${moment(date)
	.tz(moonCalcReducer.timezone)
	.format("MM-DD-YYYY")} ${moment(date)
			.tz(moonCalcReducer.timezone)
			.format("HH:mm")} Local Time ${moment(date)
			.tz(moonCalcReducer.timezone)
			.format("Z")}
        `;
	}
	calendarChange(event, date) {
		const { moonCalcReducer } = this.props;
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const momentTime = moment(moonCalcReducer.time)
			.tz(moonCalcReducer.timezone)
			.set("year", year)
			.set("date", day)
			.set("month", month);
		this.dateChange(momentTime.format("YYYY-MM-DDTHH:mm:ss"));
		this.newData(momentTime.format());
	}
	calendarValue() {
		const { moonRiseDateReducer, moonCalcReducer } = this.props;
		const year = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("year");
		const month = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("month");
		const day = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("date");
		const hour = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("hour");
		const minute = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("minute");
		const second = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("second");
		const millisecond = moment(moonRiseDateReducer)
			.tz(moonCalcReducer.timezone)
			.get("millisecond");
		const time = new Date(
			year,
			month,
			day,
			hour,
			minute,
			second,
			millisecond
		);
		return time;
	}
	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}
	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}

	render() {
		const { moonCalcReducer } = this.props;
		return (
			<Fragment>
				<section className="CalendarPickerBlock CalendarPickerBlockMoon">
					<div className="left-calendar-block">
						<MuiThemeProvider>
							<div className="calendar-date-picker-wrapper">
								<DatePicker
									className="calendar-date-picker"
									hintText="Select Date"
									value={this.calendarValue()}
									onChange={this.calendarChange.bind(this)}
									autoOk={true}
									formatDate={
										new Intl.DateTimeFormat("en-US", {
											day: "2-digit",
											month: "long",
											year: "numeric"
										}).format
									}
								/>
								<i className="material-icons">today</i>
							</div>
						</MuiThemeProvider>
						<div className="calendar-date-picker-buttons">
							<MuiThemeProvider>
								<IconButton
									onClick={this.previousMonth}
									tooltip="Previous Month"
									tooltipPosition="bottom-right"
								>
									<i className="material-icons">
										fast_rewind
									</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.previousDay}
									tooltip="Previous Day"
									tooltipPosition="bottom-right"
								>
									<i className="material-icons prev_date">
										play_arrow
									</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.currentDay}
									tooltip="Current Day"
									tooltipPosition="bottom-center"
								>
									<i className="material-icons">stop</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.nextDay}
									tooltip="Next Day"
									tooltipPosition="bottom-left"
								>
									<i className="material-icons">play_arrow</i>
								</IconButton>
							</MuiThemeProvider>
							<MuiThemeProvider>
								<IconButton
									onClick={this.nextMonth}
									tooltip="Next Month"
									tooltipPosition="bottom-left"
								>
									<i className="material-icons">
										fast_forward
									</i>
								</IconButton>
							</MuiThemeProvider>
						</div>
					</div>
					<div className="right-calendar-block">
						{moonCalcReducer.moon_data.moon_events.map(
							(elem, index) => (
								<div
									className="equinox-solstice-name"
									key={index}
									title={this.monthTitle(elem[1])}
								>
									<span>{elem[0]}</span>
									<MuiThemeProvider>
										<RaisedButton
											onClick={this.equinoxSolstice.bind(
												this,
												elem[1]
											)}
											className="RaisedButton"
											label={this.month(elem[1])}
											primary={true}
										/>
									</MuiThemeProvider>
								</div>
							)
						)}
					</div>
				</section>

				{moment(this.props.moonRiseDateReducer).isBefore(
					"2039-01-01"
				) ? null : (
					<p className="geotimedate-table_moon-notice geotimedate-table_moon-notice__under-datepicker">
						Showing local times for{" "}
						{this.emptyName(moonCalcReducer.name)}{" "}
						{this.emptyAbr(
							moonCalcReducer.country_name_abbr,
							moonCalcReducer.country_name
						)}{" "}
						based on standard time due to{" "}
						<span title="Many Unix and other operating systems store Unix time as a signed 32-bit integer. After January 19, 2038, the 32 bit signed UNIX time will overflow, taking the count negative. For more information, please visit - https://en.wikipedia.org/wiki/Year_2038_problem">
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							"Year 2038 Problem"
						</span>
						.
					</p>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer,
		moonRiseDateReducer: state.moonRiseDateReducer,
		moonRisePositionReducer: state.moonRisePositionReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		moonCalc: bindActionCreators(moonCalc, dispatch),
		moonRisePosition: bindActionCreators(moonRisePosition, dispatch),
		moonRiseCoords: bindActionCreators(moonRiseCoords, dispatch),
		moonRiseTableList: bindActionCreators(moonRiseTableList, dispatch),
		moonRiseDate: bindActionCreators(moonRiseDate, dispatch),
		calendarDateMoon: bindActionCreators(calendarDateMoon, dispatch),
		moonLoaderShow: bindActionCreators(moonLoaderShow, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CityCalendarBlock);
