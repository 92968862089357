import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { StickyTable, Row, Cell } from "react-sticky-table";
import moment from "moment-timezone/index.js";
import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";
import planetesRetrograde from "../../../../../actions/action_planetesRetrograde";
import nextPlanetRetrograde from "../../../../../actions/action_nextPlanetRetrograde";
import "./RetrogradeData.css";
import Api from "../../../../../services/api.js";
import { Link } from "react-router-dom";
import { TableFooter } from "../../../../shared/Common";

class RetrogradeData extends Component {
  tableHeader = [
    ["Shadow", "Start Date"],
    ["Number of days in", "Shadow"],
    ["Retrograde Start", "Date"],
    ["Zodiac", "Degree"],
    ["Days in", "Retrograde"],
    ["Date Direct", ""],
    ["Zodiac", "Degree"],
    ["Shadow", " End Date"],
    ["Number of days", " in Shadow"],
  ];
  state = {
    rowSelect: 9999999999,
    seoData: {},
  };

  UNSAFE_componentWillMount() {
    // UNSAFE_componentWillMount only for this.setDataFromUrl
    this.setDataFromUrl();
  }

  componentDidUpdate() {
    const newData = this.checkUrlData();

    if (JSON.stringify(this.state.seoData) !== JSON.stringify(newData)) {
      this.setDataFromUrl(newData);
    }
  }

  setDataFromUrl = (newData) => {
    if (newData) {
      this.setState({ seoData: newData });
    }
    const data = this.checkUrlData();
    this.setState({ seoData: data });
  };

  checkUrlData = () => {
    const parsedUrl = window.location.pathname.split("/");
    const normalUrlsParamsAmount = 5; // empty str, page type, city,state,country, location id

    const currentPlanet = this.capitalizeString(parsedUrl[1].split("-")[0]);
    let currentCountry = "";
    let currentCity = "";
    let currentState = "";

    if (parsedUrl.length < normalUrlsParamsAmount) {
      const parsedCountry = parsedUrl[parsedUrl.length - 1]; // set last url's param
      currentCountry =
        parsedCountry === "usa"
          ? parsedCountry.toUpperCase()
          : this.capitalizeString(parsedCountry.replace(/[-,_]/g, " "));
    } else {
      const parsedCountry = parsedUrl[2];
      currentCountry =
        parsedCountry === "usa"
          ? parsedCountry.toUpperCase()
          : this.capitalizeString(parsedCountry.replace(/[-,_]/g, " "));
      currentCity = this.capitalizeWholeString(
        parsedUrl[4].replace(/[-,_]/g, " ")
      );
      const parsedState = parsedUrl[3].replace(/[-,_]/g, " ");
      currentState =
        parsedState.length <= 2
          ? parsedState.toUpperCase()
          : this.capitalizeWholeString(parsedState);
    }
    return {
      planet: currentPlanet,
      country: currentCountry,
      state: currentState,
      city: currentCity,
    };
  };

  capitalizeString = (str) => str[0].toUpperCase() + str.slice(1);

  capitalizeWholeString = (str) => {
    const strArray = str.split(" ");
    const capitalizeStr = strArray.map(
      (item) => item[0].toUpperCase() + item.slice(1)
    );
    return capitalizeStr.join(" ");
  };

  getCityName() {
    const { currentSearchReducer } = this.props;
    const name =
      currentSearchReducer.name !== null ? `${currentSearchReducer.name}` : "";
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    return `${name}, ${state} ${country}`;
  }

  getTitle() {
    const { planetesRetrogradeReducer } = this.props;
    return `${
      planetesRetrogradeReducer.planet
    } retrogrades for ${this.getCityName()}`;
  }
  geotimedateTable() {
    const { planetesRetrogradeReducer } = this.props;
    if (document.body.offsetWidth > 1199) {
      return planetesRetrogradeReducer.retrogradeList.length * 35 + 47 + 48;
    } else
      return document.body.offsetHeight < 1260
        ? document.body.offsetHeight
        : 1260;
  }
  tableDateClass(elem, arr, index) {
    const indexSelect = this.state.indexSelecItemInTable;
    if (indexSelect === index) return "hoverRow selectRow";
    return "hoverRow";
  }
  rangeDegreeRetrograde() {
    const { planetesRetrogradeReducer } = this.props;
    if (planetesRetrogradeReducer.retrogradeList[0]) {
      const retro_degree = this.state.retrogradeElem.retro_degree;
      const direct_degree = this.state.retrogradeElem.direct_degree;
      const retro_degreeTable = this.state.degreeRetroFromTable.retro_degree;
      const direct_degreeTable = this.state.degreeRetroFromTable.direct_degree;
      if (retro_degreeTable) {
        if (retro_degreeTable > direct_degreeTable) {
          return this.getIntegerRenge(direct_degreeTable, retro_degreeTable);
        } else {
          return this.getIntegerRengeDifferentZodiac(
            direct_degreeTable,
            retro_degreeTable
          );
        }
      }
      if (retro_degree > direct_degree) {
        return this.getIntegerRenge(direct_degree, retro_degree);
      } else {
        return this.getIntegerRengeDifferentZodiac(direct_degree, retro_degree);
      }
    }
    return [0];
  }
  retrogradeZodiac(sign, degree) {
    const newDegree = (degree + "").split(".");
    const deg = newDegree[0] + String.fromCharCode(176);
    const min = newDegree[1] ? (60 / (100 / +newDegree[1])).toFixed(0) : "00";
    return `${deg} ${sign} ${min}'`;
  }
  periodShow(elem) {
    const { currentSearchReducer } = this.props;
    if (elem.split(" ")[0] === "Next") return elem;
    else {
      const tz = currentSearchReducer.timezone;
      const period = elem.split(" - ");
      const start = moment(period[0]).tz(tz).format("YYYY");
      const end = moment(period[1]).tz(tz).format("YYYY");
      return `${start} - ${end}`;
    }
  }
  periodChange = (event, index, value) => {
    const { planetesRetrogradeReducer, planetesRetrograde } = this.props;
    planetesRetrograde({
      planet: planetesRetrogradeReducer.planet,
      period: value,
      periodes: planetesRetrogradeReducer.periodes,
      retrogradeList: planetesRetrogradeReducer.retrogradeList,
      current_zodiac: planetesRetrogradeReducer.current_zodiac,
    });
  };
  async planetesRetrogradeGet() {
    const {
      currentSearchReducer,
      planetesRetrograde,
      planetesRetrogradeReducer,
    } = this.props;
    const tz = currentSearchReducer.timezone;
    let start;
    if (planetesRetrogradeReducer.period.split(" ")[0] === "Next") {
      start = moment().tz(tz).format("YYYY");
    } else {
      start = moment(planetesRetrogradeReducer.period.split(" - ")[0])
        .tz(tz)
        .format("YYYY");
    }
    let retrogradeRequest;
    if (planetesRetrogradeReducer.period === "Next 10 Years") {
      retrogradeRequest = await Api.userCityList(
        `retrograde/${currentSearchReducer.id}/${start}/?p=${planetesRetrogradeReducer.planet}&exclude_past=true`
      );
    } else
      retrogradeRequest = await Api.userCityList(
        `retrograde/${currentSearchReducer.id}/${start}/?p=${planetesRetrogradeReducer.planet}`
      );
    planetesRetrograde({
      planet: planetesRetrogradeReducer.planet,
      period: planetesRetrogradeReducer.period,
      periodes: planetesRetrogradeReducer.periodes,
      retrogradeList: retrogradeRequest.data.data,
      current_zodiac: planetesRetrogradeReducer.current_zodiac,
    });
  }
  selectFromTable(elem, index) {
    const {
      nextPlanetRetrograde,
      planetesRetrograde,
      planetesRetrogradeReducer,
    } = this.props;
    this.setState({ rowSelect: index });
    nextPlanetRetrograde({
      retroList: elem,
      type: "Selected",
    });
    planetesRetrograde({
      planet: planetesRetrogradeReducer.planet,
      period: planetesRetrogradeReducer.period,
      periodes: planetesRetrogradeReducer.periodes,
      retrogradeList: planetesRetrogradeReducer.retrogradeList,
    });
  }
  tableStyle(elem, index) {
    const { nextPlanetRetrogradeReducer } = this.props;
    const { currentSearchReducer, planetaryStyle } = this.props;
    const tz = currentSearchReducer.timezone;
    const start = moment(nextPlanetRetrogradeReducer.retroList.date_retro)
      .tz(tz)
      .format("x");
    const end = moment(nextPlanetRetrogradeReducer.retroList.date_direct)
      .tz(tz)
      .format("x");
    const startNext = moment(elem.date_retro).tz(tz).format("x");
    const endNext = moment(elem.date_direct).tz(tz).format("x");
    if (this.state.rowSelect === index)
      return { background: "rgba(0,255,0,.4)" };
    else if (start === startNext && end === endNext) return planetaryStyle;
    else return {};
  }
  render() {
    const { seoData } = this.state;
    const { currentSearchReducer, planetesRetrogradeReducer } = this.props;
    return (
      <div className="table-data-period">
        <div className="rowRetrogradetitleTable ">
          <h4>{this.getTitle()}</h4>
          <div className="select-change">
            <MuiThemeProvider>
              <SelectField
                className="year-select"
                value={planetesRetrogradeReducer.period}
                onChange={this.periodChange}
                maxHeight={200}
              >
                {planetesRetrogradeReducer.periodes.map((elem, index) => (
                  <MenuItem
                    value={elem}
                    key={index}
                    primaryText={this.periodShow(elem)}
                  />
                ))}
              </SelectField>
            </MuiThemeProvider>
            <button
              className="month-chnge-request"
              onClick={this.planetesRetrogradeGet.bind(this)}
            >
              Go
            </button>
          </div>
        </div>
        <div
          className="geotimedate-table"
          style={{
            width: "100%",
            // height: this.geotimedateTable() + 'px'
          }}
        >
          <StickyTable stickyColumnCount={0} stickyHeaderCount={1}>
            <Row>
              {this.tableHeader.map((elem, index) => (
                <Cell key={index}>
                  <div className="cell-wrapper">
                    <b>
                      <em>{elem[0]}</em>
                      <em>{elem[1]}</em>
                    </b>
                  </div>
                </Cell>
              ))}
            </Row>
            <Row>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
              <Cell></Cell>
            </Row>
            {planetesRetrogradeReducer.retrogradeList.map(
              (elem, index, arr) => (
                <Row
                  key={index}
                  className="hoverRow"
                  style={this.tableStyle(elem, index)}
                  onClick={() => this.selectFromTable(elem, index)}
                >
                  <Cell>
                    {moment(elem.date_shadow_start)
                      .tz(currentSearchReducer.timezone)
                      .format("LL")}
                  </Cell>
                  <Cell>{elem.shadow_start_length}</Cell>
                  <Cell>
                    <p>
                      {moment(elem.date_retro)
                        .tz(currentSearchReducer.timezone)
                        .format("LL")}
                    </p>
                    <p>
                      {moment(elem.date_retro)
                        .tz(currentSearchReducer.timezone)
                        .format("LT")}
                    </p>
                  </Cell>
                  <Cell>
                    {this.retrogradeZodiac(elem.retro_sign, elem.retro_degree)}
                  </Cell>
                  <Cell>{elem.retro_len}</Cell>
                  <Cell>
                    <p>
                      {moment(elem.date_direct)
                        .tz(currentSearchReducer.timezone)
                        .format("LL")}
                    </p>
                    <p>
                      {moment(elem.date_direct)
                        .tz(currentSearchReducer.timezone)
                        .format("LT")}
                    </p>
                  </Cell>
                  <Cell>
                    {this.retrogradeZodiac(
                      elem.direct_sign,
                      elem.direct_degree
                    )}
                  </Cell>
                  <Cell>
                    {moment(elem.date_shadow_direct)
                      .tz(currentSearchReducer.timezone)
                      .format("LL")}
                  </Cell>
                  <Cell>{elem.shadow_end_length}</Cell>
                </Row>
              )
            )}
          </StickyTable>
        </div>
        <TableFooter
          url={currentSearchReducer.url}
          cityName={this.getCityName()}
        />
        <div
          className="text-block-fish"
          style={{
            paddingTop: "30px",
          }}
        >
          <h4>
            <b>
              {seoData.planet} Retrograde in{" "}
              {currentSearchReducer.name && currentSearchReducer.state
                ? `${currentSearchReducer.name}, ${currentSearchReducer.state}, ${currentSearchReducer.country_name}`
                : `${currentSearchReducer.country_name}`}
            </b>
          </h4>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <h4>What does '{seoData.planet} is in retrograde' mean?</h4>
          <p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            '{seoData.planet} is in Retrograde', refers to the movement of{" "}
            {seoData.planet}, as seen from Earth, where it appears to stop
            briefly and move in reverse direction at certain times. Though
            actually in reality, {seoData.planet} is orbiting around the Sun in
            the same direction but at different speeds. Earth completes its
            orbit in a different period of time than {seoData.planet}. It is the
            relative motion between Earth and {seoData.planet} that creates the
            perception of backward motion.
          </p>
          <h4>What is the origin of the word retrograde?</h4>
          <p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            The word Retrograde is from the Latin word 'retrogrades’ – 'Retro’-
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            meaning 'backwards’ and 'gradus’ meaning 'step’. Retrograde in this
            context, refers to the movement of planets, as seen from Earth,
            where they appear to stop briefly and move in reverse direction at
            certain times.
          </p>
          <h4>What is {seoData.planet} Retrograde?</h4>
          <p>
            It is like two trains moving in the same direction but at different
            speeds. When both the trains are moving alongside each other, a
            person sitting on the fast moving train will feel that both the
            trains are moving in the same direction but when the fast moving
            train overtakes the slow moving train, the person would get a
            feeling that the slow moving train is moving` backwards`. This is
            the apparent retrograde period. The same happens with Earth and{" "}
            {seoData.planet}. As Earth completes its orbit in a different period
            of time than {seoData.planet}’s orbit, it periodically overtakes
            them. When this occurs, the planet being passed will first appear to
            stop its eastward movement, and then drift back toward the west.
            Then, as Earth swings past {seoData.planet} in its orbit, it appears
            to resume its normal motion west to east.
          </p>
          <h4>How often does planet retrograde occur?</h4>
          <p>
            All planets go retrograde periodically but with different frequency.
            The more distant planets retrograde more frequently, as they do not
            move as much in their orbits while Earth completes an orbit itself.
            The most observed is Mercury Retrograde which happens three to four
            times a year for about 20 days. Venus goes retrograde every 18
            months. The period of retrograde increases as you move from Mercury
            to Pluto. Mars is retrograde every two years for about two months.
            Jupiter, Saturn, Uranus, Neptune and Pluto retrograde from four to
            five months every year.
          </p>
          <h4>What is direct or prograde and shadow period?</h4>
          <p>
            After the retrograde, when Earth completely passes the other planet
            in its orbit, the motion appears normal again, and the planet is
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            said to be 'direct’ or 'prograde’. Each retrograde cycle has a
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            'shadow period’—during which the retrograde planet apparently
            changes direction from forward motion to backward motion and
            vice-versa.
          </p>

          <p>
            To know more about planet retrogrades in depth, you can view this
            video –
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={`https://youtu.be/FtV0PV9MF88`} target="_blank">
              https://youtu.be/FtV0PV9MF88
            </a>
          </p>
          <p>
            or read this article -
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a target="_blank"
              href={`https://en.wikipedia.org/wiki/Apparent_retrograde_motion`}

            >
              https://en.wikipedia.org/wiki/Apparent_retrograde_motion
            </a>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    planetesRetrogradeReducer: state.planetesRetrogradeReducer,
    nextPlanetRetrogradeReducer: state.nextPlanetRetrogradeReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    planetesRetrograde: bindActionCreators(planetesRetrograde, dispatch),
    nextPlanetRetrograde: bindActionCreators(nextPlanetRetrograde, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetrogradeData);
