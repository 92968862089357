import moment from 'moment-timezone/index.js';
const defaultCity = {
  name: moment.tz.guess(),
  county: null,
  country_code: 'UA',
  highlight: true,
  state: '',
  feature: '',
  feature_code: '',
  population: 0,
  timezone: moment.tz.guess(),
  country_name: '',
  country_name_abbr: '',
  state_code: ''
}
const defaultCityInfoReducer = (state = defaultCity, action) => {
  switch (action.type) {
    case 'ADD_DEFAULT_CITY_INFO':
      return action.data;
      // return action.data === undefined ? state : action.data;
    default:
      return state;
  }
};
  
export default defaultCityInfoReducer;