import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import TimePicker from "material-ui/TimePicker";

import Loader from "../../../../components/Main/Loader/Loader";

import selectedCities from "../../../../actions/action_selectedCities";

import GroupList from "./GroupList";
import Api from "../../../../services/api.js";
import "./Groups.css";

class Groups extends Component {
	state = {
		groupList: [],
		groupsNumbers: [],
		citiesListFull: [],
		currentNumbGroup: 0,
		group_error: ""
	};

	async componentDidMount() {
		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");
		if (token) {
			const data = await Api.userInfo("main-cities-reg/0/get/", token);
			//-------------------
			/*const groupList = data.data.map(elem => elem.group_name); //
            const groups = [...new Set(groupList)];*/
			//-------------------
			let groups = [];
			let groupsSubList = [];
			let groupsNumbers = [];
			data.data.map(elem => {
				groups[elem.group_number] = elem.group_name;

				if (!groupsNumbers.includes(elem.group_number)) {
					groupsNumbers.push(elem.group_number);
				}

				if (groupsSubList[elem.group_number]) {
					groupsSubList[elem.group_number].push(elem);
				} else {
					groupsSubList[elem.group_number] = [elem];
				}

				return null;
			});

			const filtered = groupsSubList.filter(el => el != null);
			const groupFiltered = groups.filter(el => el != null);

			this.setState({
				groupList: groupFiltered,
				groupsNumbers: groupsNumbers,
				groupSubList: filtered,
				citiesListFull: data.data
			});
		}
	}

	emptyName = city => {
		const cityName = city.name_city_replace
			? city.name_city_replace
			: city.name;
		return cityName !== null ? `${cityName} ` : "";
	};

	emptyAbr = elem => {
		if (
			elem.country_name === "United States" &&
			elem.country_name_abbr !== null &&
			elem.state_code !== null
		) {
			return `${elem.state_code}, ${elem.country_name_abbr}`;
		} else if (elem.country_name !== null)
			return `(${elem.state}, ${elem.country_name})`;
		else return "";
	};

	daysSelected = (city, dayIndex) => {
		const day = city.weekend.filter(elem => {
			return (
				(+elem + 1 === dayIndex && +elem !== 6) ||
				(+elem === 6 && dayIndex === 0)
			);
		});
		return day.length
			? {
				background: "#ff925d",
				border: "1px solid #ff925d",
				color: "#fff"
			}
			: {};
	};

	handleGroupOpen = index => this.setState({ currentNumbGroup: index });

	groupTitleStyle = index => {
		this.state.currentNumbGroup !== index ? { display: "none" } : {};
	};

	citiesShowStyle = index =>
		this.state.currentNumbGroup === index
			? { height: "auto" }
			: { height: 0 };

	blockOpenStyle = index =>
		this.state.currentNumbGroup === index
			? { transform: "rotate(90deg)" }
			: {};

	handleCityNameSelect = e => {
		e.target.parentElement.querySelector("p").focus();
	};

	handleCityNameChange = (city, e) => {
		const text = e.target.value;
		const newList = this.state.citiesListFull.map(elem => {
			if (elem === city) elem.name_city_replace = text;
			return elem;
		});
		this.setState({ citiesListFull: newList });
	};

	handleNameChange = async (city, cityIndex, param, e) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			name_city_replace: `${e.target.innerText}`
		};
		this.requestCityChange(newElem);
	};

	handleNameRefresh = (city, cityIndex) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			name_city_replace: ``
		};
		this.requestCityChange(newElem);
	};

	handleWeekendsChange = (city, cityIndex, day) => {
		const { group_number, city_id, id } = city;
		const dayClick = day === -1 ? 6 : day;
		const weekendCheck = city.weekend.filter(elem => +elem === dayClick);
		const weekendCalc = weekendCheck.length
			? city.weekend.filter(elem => +elem !== dayClick)
			: [...city.weekend, `${dayClick}`];
		const newWeekend = !weekendCalc.length ? "no" : weekendCalc.join("-");
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			weekend: newWeekend
		};
		this.requestCityChange(newElem);
	};

	handleWeekendsRefresh = (city, cityIndex) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			weekend: ""
		};
		this.requestCityChange(newElem);
	};

	requestCityChange = async (elem, resetActiveGroup) => {
		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");

		if (token) {
			let get_all = { act: "get", group: "0" };
			const data = await Api.changeInfo(`main-cities-reg-gr/`, token, [
				elem,
				get_all
			]);
			this.resetCityList(data, 0, resetActiveGroup); // 0 - в массиве ответа(data.data.result) какой по счету
		}
	};

	handleCityDell = async city => {
		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");
		if (token) {
			let dt = [
				{ act: "delete", city_id: city.city_id.toString() },
				{ act: "get", group: "0" }
			];
			const data = await Api.changeInfo(`main-cities-reg-gr/`, token, dt);
			this.resetCityList(data, 0); // 0 - в массиве ответа(data.data.result) какой по счету
		}
	};

	resetCityList = async (data, nun_result, resetActiveGroup) => {
		//-------------------
		// const groupList = data.data.main_city_reg.map(elem => elem.group_name); //group_number
		// const groups = [...new Set(groupList)];
		//-------------------
		let groups = [];
		let groupsSubList = [];
		let groupsNumbers = [];
		data.data.main_city_reg[0].map(elem => {
			groups[elem.group_number] = elem.group_name;

			if (!groupsNumbers.includes(elem.group_number)) {
				groupsNumbers.push(elem.group_number);
			}

			if (groupsSubList[elem.group_number]) {
				groupsSubList[elem.group_number].push(elem);
			} else {
				groupsSubList[elem.group_number] = [elem];
			}

			return null;
		});

		let error = "";
		data.data.result.forEach(elem => {
			if (elem.error_main !== undefined) {
				elem.error_main.forEach(elem1 => {
					if (elem1 !== null && elem1.error !== undefined)
						error = error + elem1.error + "; ";
				});
			}
			if (elem.error_params !== undefined) {
				elem.error_params.forEach(elem1 => {
					if (elem1 !== null && elem1.error !== undefined)
						error = error + elem1.error + "; ";
				});
			}
		});

		const filtered = groupsSubList.filter(el => el != null);
		const groupFiltered = groups.filter(el => el != null);

		this.setState({
			groupList: groupFiltered,
			groupsNumbers: groupsNumbers,
			groupSubList: filtered,
			currentNumbGroup: resetActiveGroup
				? 0
				: this.state.currentNumbGroup,
			citiesListFull: data.data.main_city_reg[0],
			group_error: error
		});
		if (error !== "") {
			setTimeout(() => {
				this.setState({ group_error: "" });
			}, 5000);
		}
	};

	handleTimeStartChange = (city, cityIndex, e, date) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			working_hours_start: `${date.getHours()}:${date.getMinutes()}`
		};
		this.requestCityChange(newElem);
	};

	handleTimeEndChange = (city, cityIndex, e, date) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			working_hours_end: `${date.getHours()}:${date.getMinutes()}`
		};
		this.requestCityChange(newElem);
	};

	handleTimeReset = (city, cityIndex) => {
		const { group_number, city_id, id } = city;
		const newElem = {
			act: "upd",
			city_id: `${city_id}`,
			geoname_id: `${id}`,
			group: `${group_number}`,
			sort_by: `${cityIndex}`,
			working_hours_start: ``,
			working_hours_end: ``
		};
		this.requestCityChange(newElem);
	};

	groupNameChange = cityList => {
		//-------------------
		// const groupList = cityList.map(elem => elem.group_name); //group_number
		// const groups = [...new Set(groupList)];
		//-------------------
		let groups = [];
		let groupsSubList = [];
		let groupsNumbers = [];
		cityList.map(elem => {
			groups[elem.group_number] = elem.group_name;

			if (!groupsNumbers.includes(elem.group_number)) {
				groupsNumbers.push(elem.group_number);
			}

			if (groupsSubList[elem.group_number]) {
				groupsSubList[elem.group_number].push(elem);
			} else {
				groupsSubList[elem.group_number] = [elem];
			}

			return null;
		});

		const filtered = groupsSubList.filter(el => el != null);
		const groupFiltered = groups.filter(el => el != null);

		this.setState({
			groupList: groupFiltered,
			groupsNumbers: groupsNumbers,
			groupSubList: filtered,
			currentNumbGroup: this.state.currentNumbGroup,
			citiesListFull: cityList
		});
	};

	handleGroupNameSelect = e =>
		e.target.parentElement.querySelector("h5").focus();

	handleGroupChange = async (index, e) => {
		debugger;
		const value = e.target.innerText.toLowerCase();
		let newElem = {
			act: "rename_group",
			group: index.toString(),
			group_name: value.charAt(0).toUpperCase() + value.slice(1)
		};
		this.requestCityChange(newElem);
	};

	handleGroupDell = index => {
		const newElem = {
			act: "delete",
			city_id: `0`,
			group: `${index}`
		};
		return this.requestCityChange(newElem, true);
	};

	addNewGroup = () => {
		let i = 1;
		while (i < 100) {
			if (this.state.groupsNumbers.includes(i)) i++;
			else break;
		}

		const newElem = {
			act: "save",
			geoname_id: `${5128581}`,
			group: i.toString(),
			sort_by: `0`,
			group_name: "Group " + i
		};

		this.requestCityChange(newElem);
	};

	render() {
		if (!this.state.citiesListFull.length) {
			return (
				<div className="account-info groups">
					<Loader />
				</div>
			);
		}

		const { authorizationReducer } = this.props;

		return (
			<div className="account-info groups">
				<h4>Group Management</h4>
				<h5>
					<i style={{ color: "red" }}>{this.state.group_error}</i>
				</h5>
				<div className="groups-list">
					{this.state.groupList.map((elem, index) => (
						<GroupList
							key={index}
							index={index}
							elem={elem}
							groupsLength={this.state.groupSubList.length}
							groupSubList={this.state.groupSubList}
							currentNumbGroup={this.state.currentNumbGroup}
							handleGroupOpen={this.handleGroupOpen}
							handleGroupChange={this.handleGroupChange}
							handleCityDell={this.handleCityDell}
							groupTitleStyle={this.groupTitleStyle}
							handleGroupNameSelect={this.handleGroupNameSelect}
							handleGroupDell={this.handleGroupDell}
							groupNameChange={this.groupNameChange}
							blockOpenStyle={this.blockOpenStyle}
							citiesShowStyle={this.citiesShowStyle}
							handleCityNameSelect={this.handleCityNameSelect}
							handleTimeStartChange={this.handleTimeStartChange}
							handleTimeEndChange={this.handleTimeEndChange}
							handleTimeReset={this.handleTimeReset}
							handleWeekendsChange={this.handleWeekendsChange}
							daysSelected={this.daysSelected}
							handleWeekendsRefresh={this.handleWeekendsRefresh}
							emptyName={this.emptyName}
							emptyAbr={this.emptyAbr}
							handleNameChange={this.handleNameChange}
							handleNameRefresh={this.handleNameRefresh}
						/>
					))}

					<div className="group">
						{authorizationReducer.status === "Free" ? (
							<Fragment>
								<button className="add-group add-group__disabled">
									Add a Group
								</button>
								<Link to="/upgrade-account">
									(upgrade your account to add more groups)
								</Link>
							</Fragment>
						) : (
							<button
								className="add-group"
								onClick={this.addNewGroup}
							>
								Add a Group
							</button>
						)}
					</div>
				</div>

				<div className="customise">
					<h5>Customize your experience</h5>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authorizationReducer: state.authorizationReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		selectedCities: bindActionCreators(selectedCities, dispatch)
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Groups);
