import React, { Component } from 'react';

import './Airplane.css';

class Airplane extends Component {
    render() {
        return (
            <div className='test-design Airplane'>
                <div className='cloud cloud-1'></div>
                <div className='cloud cloud-3'></div>
                <div className='cloud cloud-2'></div>

                <div className='cloud cloud-4'></div>
                <div className="plane">
                    <div className="ver-stab"></div>
                    <div className="ver-stab-tri"></div>
                    <div className="nose">
                        <div className="window1"></div>
                        <div className="window2"></div>
                        <div className="window3"></div>
                        <div className="window4"></div>
                    </div>
                    <div className="back-body"></div>
                    <div className="hor-stab"></div>
                    <div className="hor-stab2"></div>
                    <div className="wing"></div>
                    <div className="wing-bottom"></div>
                    <div className="wing right"></div>
                    <div className="wing-bottom wing-right"></div>
                    <div className="engine">
                        <div className="fan">
                            <div className="front">
                                <div className="fan inner"></div>
                            </div>
                        </div>
                    </div>
                    <div className="engine eng-big2">
                        <div className="fan">
                            <div className="front">
                                <div className="fan inner"></div>
                            </div>
                        </div>
                    </div>
                    <div className="engine eng-small">
                        <div className="fan">
                            <div className="front">
                                <div className="fan inner"></div>
                            </div>
                        </div>
                    </div>
                    <div className="engine eng-small small2">
                        <div className="fan">
                            <div className="front">
                                <div className="fan inner"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cloud cloud-6'></div>
                <div className='cloud cloud-5'></div>
            </div>
        )
    }
}

export default Airplane;
