import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

// import PlanetRetrogradesLinks from './PlanetRetrogradesLinks';
// import PlanetSignsLinks from './PlanetSignsLinks';
// import PoveredBy from './PoveredBy/PoveredBy';
// import panchangImage from '../../../../../../assets/panchang-box-image.jpg';

class HeaderPagePanchang extends Component {

    cityName() {
        const { name, country_name, timezone, time } = this.props.currentSearchReducer;
        if (moment(time).tz(timezone)){
            return  <h4><b>Panchang for {name}, {country_name} for {moment(this.props.time).format('dddd')}, {moment(this.props.time).format('ll')}</b></h4>;
        }
    }
    render() {
        const { currentSearchReducer } = this.props;
        return (
            <div className="header_page">
               <div className="city-whith-planet-img">

                <div className="citySunRiseSet" >
                    <div className="country_flag">
                        <img className="cityMainImg"
                            src={`/static/flags/flags-iso/flat/64/${currentSearchReducer.country_code}.png`}
                            alt="citydateandtime"/>
                    </div>
                    <div className="city_name_date">
                        {this.cityName()}
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};

export default connect(mapStateToProps)(HeaderPagePanchang);