import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import airplane from "../../../../../assets/icons/airplane.png";
import aeroplane from "../../../../../assets/icons/aeroplane.png";
import FlightCard from "./FlightCard";
import "./flight_details.css";

class FlightDetails extends Component {
	render() {
		const { flightTimeInfoReducer } = this.props;

		if (!flightTimeInfoReducer) {
			return null;
		}

		const mailto =
			"mailto:name@email.com?" +
			"subject=Sharing%20flight%20details&" +
			"body=Flight%20Details%0A%0A" +
			//Departure from Kharkiv (Ukraine) Arrival in San Francisco (United States)
			"Departure%20from%20" +
			flightTimeInfoReducer.result.departure.city.name +
			"%20(" +
			flightTimeInfoReducer.result.departure.city.country +
			")%09%09%09" +
			"Arrival%20in%20" +
			flightTimeInfoReducer.result.arrival.city.name +
			"%20(" +
			flightTimeInfoReducer.result.arrival.city.country +
			")%0A%0A" +
			//Local Time - Kharkiv (UTC +02:00) Local Time - San Francisco (UTC -08:00)
			"Local%20Time%20-%20" +
			flightTimeInfoReducer.result.departure.city.name +
			"%20(UTC%20" +
			moment(flightTimeInfoReducer.result.departure.date.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("Z") +
			")%20%09%09Local%20Time%20-%20" +
			flightTimeInfoReducer.result.arrival.city.name +
			"%20(UTC%20" +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("Z") +
			")%0A" +
			//2:29PM Date>             3:15PM Date>
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("h:mm") +
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("A") +
			"%20" +
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("MMM, D") +
			";%20%09%09%09%09%09" +
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("h:mm") +
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("A") +
			"%20" +
			moment(flightTimeInfoReducer.result.departure.date2.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("MMM, D") +
			"%0A%0A" +
			//Local Time - San Francisco (UTC -08:00) Local Time - Kharkiv (UTC +02:00)
			"Local%20Time%20-%20" +
			flightTimeInfoReducer.result.arrival.city.name +
			"%20(UTC%20" +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("Z") +
			")%20%09%09Local%20Time%20-%20" +
			flightTimeInfoReducer.result.departure.city.name +
			"%20(UTC%20" +
			moment(flightTimeInfoReducer.result.departure.date.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("Z") +
			")%0A" +
			//2:29PM Date> 3:15PM Date>
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("h:mm") +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("A") +
			"%20" +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.arrival.city.timezone)
				.format("MMM, D") +
			";%20%09%09%09%09%09" +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("h:mm") +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("A") +
			"%20" +
			moment(flightTimeInfoReducer.result.arrival.date.date)
				.tz(flightTimeInfoReducer.result.departure.city.timezone)
				.format("MMM, D") +
			"%0A%0A" +
			"Flight%20Duration%3A%20" +
			moment(
				flightTimeInfoReducer.result.arrival.date2.date.slice(0, -6)
			).diff(
				moment(
					flightTimeInfoReducer.result.departure.date.date.slice(
						0,
						-6
					)
				),
				"hours"
			) +
			"%20hours%2C%20" +
			(moment(
				flightTimeInfoReducer.result.arrival.date2.date.slice(0, -6)
			).diff(
				moment(
					flightTimeInfoReducer.result.departure.date.date.slice(
						0,
						-6
					)
				),
				"minutes"
			) %
				60) +
			"%20minutes%0A%0A" +
			"Brought%20to%20you%20by%3A%20geotimedate.org";

		return (
			<Fragment>
				<div className="flight-details">
					<div className="flight-header">
						<img src={aeroplane} alt="aeroplane icon" />
						<h3>Flight Details</h3>
					</div>
					<div className="flight-body">
						<FlightCard
							cityFrom={flightTimeInfoReducer.result.departure}
							cityTo={flightTimeInfoReducer.result.arrival}
							secondCityLocal={
								flightTimeInfoReducer.result.departure.date2
							}
							direction="from"
						/>

						<div className="flight-duration">
							<img src={airplane} alt="airplane" />
							<p className="flight-duration__title">
								Flight duration
							</p>
							<p className="flight-duration__time">
								{moment(
									flightTimeInfoReducer.result.arrival.date2.date.slice(
										0,
										-6
									)
								).diff(
									moment(
										flightTimeInfoReducer.result.departure.date.date.slice(
											0,
											-6
										)
									),
									"hours"
								)}{" "}
								hours,{" "}
								{moment(
									flightTimeInfoReducer.result.arrival.date2.date.slice(
										0,
										-6
									)
								).diff(
									moment(
										flightTimeInfoReducer.result.departure.date.date.slice(
											0,
											-6
										)
									),
									"minutes"
								) % 60}{" "}
								minutes
							</p>
						</div>

						<FlightCard
							cityFrom={flightTimeInfoReducer.result.arrival}
							cityTo={flightTimeInfoReducer.result.departure}
							secondCityLocal={
								flightTimeInfoReducer.result.arrival.date
							}
							direction="to"
						/>
					</div>
				</div>

				<div className="flight-details-share">
					<span>Share via:</span>
					{/* eslint-disable-next-line react/jsx-no-target-blank */}
					<a href={mailto} target="_blank">
						<i className="material-icons">mail_outline</i>
					</a>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		flightTimeInfoReducer: state.flightTimeInfoReducer
	};
};

export default connect(mapStateToProps)(FlightDetails);
