import React, { Component } from 'react';
import moment from 'moment-timezone/index.js';

import './TimePickerLeftInfoBlock.css';

class TimePickerLeftInfoBlock extends Component {
    timerInt = setInterval(() => {
        return this.setState({cityTime: moment()});   
    },60000);
    componentWillUnmount(){
        clearInterval(this.timerInt);
    }
    render() {
        return (
            <div className="time-picker-left-info-block">{this.props.children}</div>
        );
    }
}

export default TimePickerLeftInfoBlock;