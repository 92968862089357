import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

class Period extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise
    );
  }

  renderRowPeriod(arr, part, type) {
    const { timezone } = this.props.currentSearchReducer;
    const { current_date } = this.props.panchang;
    // let cerrentDay;
    // let offsetClient = new Date().getTimezoneOffset()/60 * -1;
    // if (offsetClient < 1){
    //     cerrentDay = moment(current_date).tz(timezone).format('DD');
    // }else{
    //     if (offset.split(':')[0] > 1) {
    //         cerrentDay = moment(current_date).tz(timezone).format('DD');
    //     } else {
    //         cerrentDay = moment(current_date).tz(timezone).add(1, 'days').format('DD');
    //     }
    // }
    //  cerrentDay = moment(current_date);
    //  cerrentDay = cerrentDay.format('DD');

    const copyArr = [...arr];
    const newArr = copyArr.filter(item => {
      // let itamEnd = moment(item.end).tz(timezone);
      let itamStart = moment(item.start).tz(timezone);

      // if (item.name === 'amrit_kaal' &&  cerrentDay < itamEnd.format('DD') && itamEnd.format('hh') <= next_hindu_sunrise.substr(11,2)){
      //     return true
      // }else if ( cerrentDay < itamEnd.format('DD') && itamEnd.format('hh') <= next_hindu_sunrise.substr(11,2)) {
      //     return item.status = 'Nil'
      // } else {
      //     return true
      // }

      /*
            if ((item.name === 'amrit_kaal2' || item.name === 'amrit_kaal' || item.name === 'varjyam' || item.name === 'varjyam2')
                && cerrentDay < itamStart.format('DD') && itamStart.format('hh') <= next_hindu_sunrise.substr(11, 2)) {
                if (itamStart.format('hh') === next_hindu_sunrise.substr(11, 2) && itamStart.format('mm') > next_hindu_sunrise.substr(14,2)){
                    return true
                }else {
                    item.status = 'Date';
                    return true
                }
                // console.log('newArr Date' , item.name, cerrentDay, '<', itamEnd.format('DD'), itamEnd.format('hh'), '<=',next_hindu_sunrise.substr(11,2) ,itamEnd.format('mm'), '<=',  next_hindu_sunrise.substr(14,2));
            }
*/

      if (
        (item.name === "amrit_kaal2" ||
          item.name === "amrit_kaal" ||
          item.name === "varjyam" ||
          item.name === "varjyam2") &&
        moment(current_date).format("DD") !== itamStart.format("DD")
      ) {
        item.status = "Date";
      }

      return true;
    });

    let accum = "123";
    if (part === "name") {
      return newArr.map(item => {
        if (accum.replace(/[0-9]/, "") === item.name.replace(/[0-9]/, "")) {
          accum = item.name;
          return <h5 key={item.name}>&emsp;</h5>;
        } else {
          accum = item.name;
          return <h5 key={item.name}>{this.fotmatText(item.name)}</h5>;
        }
      });
    } else {
      if (type === "Inauspicious") {
        return newArr.map(item => {
          // console.log('newArr item', item.name, item.status)
          // if (item.name.search(/\d/) !== -1 && item.status === 'Nil') {
          //     return <h5 key={item.name}>{' '}</h5>
          // }
          // if (item.status === 'Nil') {
          //     return <h5 key={item.name}>
          //         <span className="arrow">→ </span>
          //         Nil
          //     </h5>
          // } else
          if (item.start === "") {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>Nil
              </h5>
            );
          } else if (item.status === "Date") {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>
                {moment(item.start)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}{" "}
                -{" "}
                {moment(item.end)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}
              </h5>
            );
          } else {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>
                {moment(item.start)
                  .tz(timezone)
                  .format("hh:mm A")}{" "}
                -{" "}
                {moment(item.end)
                  .tz(timezone)
                  .format("hh:mm A")}
              </h5>
            );
          }
        });
      } else if (type === "Auspicious") {
        return newArr.map(item => {
          // if (item.name.search(/\d/) !== -1 && item.status === 'Nil') {
          //     return <h5 key={item.name}>{' '}</h5>
          // }
          // if (item.status === 'Nil' && item.name !== 'brahma_muhurat') {
          //     return <h5 key={item.name}>
          //         <span className="arrow">→ </span>
          //         Nil
          //     </h5>
          // }else {
          if (item.start === "") {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>Nil
              </h5>
            );
          } else if (
            item.name === "abhijit_muhurat" ||
            item.name === "brahma_muhurat"
          ) {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>
                {moment(item.start)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}{" "}
                -{" "}
                {moment(item.end)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}
              </h5>
            );
          } else if (item.status === "Date") {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>
                {moment(item.start)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}{" "}
                -{" "}
                {moment(item.end)
                  .tz(timezone)
                  .format("MMM DD, hh:mm A")}
              </h5>
            );
          } else {
            return (
              <h5 key={item.name}>
                <span className="arrow">→ </span>
                {moment(item.start)
                  .tz(timezone)
                  .format("hh:mm A")}{" "}
                -{" "}
                {moment(item.end)
                  .tz(timezone)
                  .format("hh:mm A")}
              </h5>
            );
          }
          // }
        });
      }
    }
  }

  fotmatText(text) {
    return text
      .replace(/[0-9]/, "")
      .replace("_", " ")
      .split(/\s+/)
      .map(word => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  }

  render() {
    const { inauspicious_periods, auspicious_periods } = this.props.panchang;
    return (
      <div className="constellation period">
        <div className="row">
          <div className="col first col-br-none pt-1 pb-1">
            <div style={{ marginTop: "10px" }}></div>
            <h4>Inauspicious Period</h4>
            <div className="row">
              <div className="name">
                {this.renderRowPeriod(
                  inauspicious_periods,
                  "name",
                  "Inauspicious"
                )}
              </div>
              <div>
                {this.renderRowPeriod(
                  inauspicious_periods,
                  "date",
                  "Inauspicious"
                )}
              </div>
            </div>
          </div>
          <div className="col second col-br-none pt-1 pb-1">
            <div style={{ marginTop: "10px" }}></div>
            <h4>Auspicious Period</h4>
            <div className="row">
              <div className="name">
                {this.renderRowPeriod(auspicious_periods, "name", "Auspicious")}
              </div>
              <div>
                {this.renderRowPeriod(auspicious_periods, "date", "Auspicious")}
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}></div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};

export default connect(mapStateToProps)(Period);
