import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";


class Table extends Component {
  renderCell(fromCell, toCell, arr, abbrStockExchange) {
    const { id, name } = this.props.searchStockCityInfoReducer
      ? this.props.searchStockCityInfoReducer
      : this.props.currentSearchReducer;
    if (arr) {
      let newARr = [...arr];
      return newARr.map((item, index) => {
        if (index >= fromCell && index <= toCell) {
          let nameWithOutSpace = "";
          let timeStart = "";
          let timeEnd = "";
          let itemAbbr = "";
          if (item.ERROR !== undefined) {
            return (
              <div
                className="cell"
                key={item.ERROR}
                style={{
                  padding: "5px",
                  color: "red",
                  textAlign: "center"
                }}
              >
                {item.ERROR}
              </div>
            );
          } else {
            nameWithOutSpace = name.replace(/ /g, "_");
            if (item.loc_time_start !== "")
              timeStart = moment(item.loc_time_start).format("hh:mm A");
            if (item.loc_time_end !== "") {
              timeEnd = moment(item.loc_time_end).format("hh:mm A");
              timeStart = timeStart + " - " + timeEnd;
            }
            itemAbbr = item.abbr;
            if (abbrStockExchange) {
              itemAbbr = abbrStockExchange;
            }
            return (
              <div className="cell" key={item.name}>
                <div className="content">
                  <img
                    className="img_flag"
                    src={`/static/flags/flags-iso/flat/32/${item.countryCode}.png`}
                    alt="citydateandtime"
                  />
                  <span className="link_table">
                    {item.type == "Stock exchanges" ? (
                      <Link
                        to={`/${item.name_slug}-operating-hours-${nameWithOutSpace}-time/${id}`}
                      >
                        {item.name}
                      </Link>
                    ) : (
                      item.name
                    )}
                  </span>
                  <span className="status">{item.status}</span>
                  <span
                    className={`time_work ${item.status === "CLOSED" &&
                      "time_work_close"}`}
                  >
                    {timeStart}
                  </span>
                  <span className="abbr">({itemAbbr})</span>
                </div>
              </div>
            );
          }
        }
      });
    }
  }

  render() {
    const { stock_exchanged_sort, abbr } = this.props.stockExchange;

    return (
      <div className="">
        <div className="row-table">
          <div className="column">
            {this.renderCell(0, 47, stock_exchanged_sort, abbr)}
          </div>
          <div className="column">
            {this.renderCell(48, 96, stock_exchanged_sort, abbr)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    stockExchange: state.stockExchange,
    currentSearchReducer: state.currentSearchReducer,
    searchStockCityInfoReducer: state.searchStockCityInfoReducer
  };
};

export default connect(mapStateToProps)(Table);
