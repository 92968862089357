import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";

import eclipsesData from "../../../../../../actions/action_eclipsesData";

class EclipseInfo extends React.Component {
	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}

	emptyState(state, abr) {
		if (isNaN(+abr) && abr !== null) return `${abr}, `;
		else if (state !== null) return `${state}, `;
		else return "";
	}

	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}

	moonRisePositionNS(value) {
		if (value > 0) return "N";
		else if (value < 0) return "S";
		else if (value === 0) return "";
	}

	moonRisePositionEW(value) {
		if (value > 0) return "E";
		else if (value < 0) return "W";
		else if (value === 0) return "";
	}

	async eclipseInfoGet() {
		const { moonCalcReducer, eclipsesData } = this.props;
		localStorage.removeItem("eclipses");
		eclipsesData({
			id: moonCalcReducer.id,
			timezone: moonCalcReducer.timezone
		});
	}

	nextVisibleEclipse() {
		const { moonCalcReducer } = this.props;
		return `${moonCalcReducer.next_lunar_eclipse[0].title} ${moment(
			moonCalcReducer.next_lunar_eclipse[1]
		)
			.tz(moonCalcReducer.timezone)
			.format("ll")}`;
	}

	render() {
		const { moonCalcReducer } = this.props;

		const url = moonCalcReducer.url
			.split("/")
			.slice(1)
			.join("/");
		const dateEclipse = moment(moonCalcReducer.next_lunar_eclipse[1])
			.tz(moonCalcReducer.timezone)
			.format("YYYY-MM-DD");

		return (
			<div className="eclipse-info">
				<h5 className="eclipse-header">
					{this.emptyName(moonCalcReducer.name)}
					{this.emptyState(
						moonCalcReducer.state,
						moonCalcReducer.state_code
					)}
					{this.emptyAbr(
						moonCalcReducer.country_name_abbr,
						moonCalcReducer.country_name
					)}
				</h5>
				<div className="eclipse-info-block">
					<b>Latitude:</b>
					<b>
						{Math.abs(moonCalcReducer.latitude.toFixed(2))}
						&deg;{" "}
						{this.moonRisePositionNS(moonCalcReducer.latitude)}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Longitude:</b>
					<b>
						{Math.abs(moonCalcReducer.longitude.toFixed(2))}
						&deg;{" "}
						{this.moonRisePositionEW(moonCalcReducer.longitude)}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Time Zone:</b>
					<b>
						{moonCalcReducer.timezone.replace(/\//g, " / ")},{" "}
						{moonCalcReducer.abbr}
					</b>
				</div>
				<div className="eclipse-info-block">
					<Link
						to={`/lunar-eclipse/${url}/${dateEclipse}`}
						onClick={this.eclipseInfoGet.bind(this)}
					>
						<b>Next Moon Eclipse:</b>
						<b>{this.nextVisibleEclipse()}</b>
					</Link>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		eclipsesData: bindActionCreators(eclipsesData, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EclipseInfo);
