import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";

class AzimuthInfo extends React.Component {
	moonDistanceWord() {
		const { moonCalcReducer } = this.props;
		return moonCalcReducer.time_format === "AM_PM" ? "mi" : "km";
	}

	moonDistance() {
		const { moonRisePositionReducer, moonCalcReducer } = this.props;
		const moonDist = (moonRisePositionReducer.dist + "").split(".")[0];
		if (moonCalcReducer.time_format === "AM_PM") {
			return moonDist.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else {
			return (+moonDist * 1.60934 + "")
				.split(".")[0]
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}

	render() {
		const { moonCalcReducer, moonRisePositionReducer } = this.props;

		return (
			<div className="azimuth-info">
				<h5 className="eclipse-header">Moon Now</h5>
				<div className="eclipse-info-block">
					<b>Date:</b>
					<b>
						{moment(moonRisePositionReducer.loc_time)
							.tz(moonCalcReducer.timezone)
							.format("ddd, MMM DD, YYYY")}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Time:</b>
					<b>
						{moment(moonRisePositionReducer.loc_time)
							.tz(moonCalcReducer.timezone)
							.format("LT")}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Altitude:</b>
					<b>
						{(
							(180 / Math.PI) *
							moonRisePositionReducer.alt
						).toFixed(2)}
						&#176;
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Azimuth:</b>
					<b>
						{((180 / Math.PI) * moonRisePositionReducer.az).toFixed(
							2
						)}
						&#176;
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>
						Distance between Earth and Moon,{" "}
						{this.moonDistanceWord()}:
					</b>
					<b>{this.moonDistance()}</b>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer,
		moonRisePositionReducer: state.moonRisePositionReducer
	};
};

export default connect(mapStateToProps)(AzimuthInfo);
