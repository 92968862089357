import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown-now";
import moment from "moment-timezone/index.js";

import { bindActionCreators } from "redux";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";

import Housing from "./Components/ComponentDesign/CollectionDesigns/Housing";
import StarWars from "./Components/ComponentDesign/CollectionDesigns/StarWars";
import Airplane from "./Components/ComponentDesign/CollectionDesigns/Airplane";
import Halloween from "./Components/ComponentDesign/CollectionDesigns/Halloween";
import Birthday from "./Components/ComponentDesign/CollectionDesigns/Birthday";
import NewYear from "./Components/ComponentDesign/CollectionDesigns/NewYear";

import "./CreatedTimer.css";

const Completionist = () => (
  <span className="created-afte_timer-format">
    Do not miss the moment, the event has already begun !
  </span>
);

class CreatedTimer extends Component {
  state = {
    differenceSeconds: 0,
    nowDateFull: undefined,
    optionsFromUrl: {
      design_name: "",
      date_event: "",
      location_event: 0,
      timer_format: "",
      title: {
        name_event: "",
        style: ""
      }
    }
  };

  async componentDidMount() {
    const { countdownReducer } = this.props;
    //console.log('did search URL',  this.props.location.pathname.length > 14 );
    // console.log('did countdownReducer', countdownReducer);
    //this.props.location.pathname.split('&')[1].split('=')[1]
    const obj = { title: { name_event: "", style: "" } };
    if (countdownReducer.design_name !== "") {
      //console.log('did design_name:',  countdownReducer.design_name );
      obj.design_name = countdownReducer.design_name;
      obj.date_event = countdownReducer.date_event;
      obj.location_event = countdownReducer.location_event;
      obj.timer_format = countdownReducer.timer_format;
      obj.timer_format = obj.timer_format.split(" ").join("_"); //rechange speace on bottom dash - timer_format
      obj.title = countdownReducer.title;
      obj.title.name_event = obj.title.name_event.split(" ").join("_"); //rechange speace on bottom dash - title.name_event
      obj.title.style = obj.title.style.split(" ").join("_"); //rechange speace on bottom dash - title.style

      const countdownUrl = `/created-timer/&design_name=${obj.design_name}&date_event=${obj.date_event}&timer_format=${obj.timer_format}&title_name=${obj.title.name_event}&title_style=${obj.title.style}&location_event=${obj.location_event}`;
      // console.log('did mount URL:', countdownUrl);
      this.props.history.push(countdownUrl);

      obj.timer_format = obj.timer_format.split("_").join(" ");
      obj.title.name_event = obj.title.name_event.split("_").join(" ");
      obj.title.style = obj.title.style.split("_").join(" ");
      //console.log('obj1', obj);
      this.setState(prevState => {
        return {
          optionsFromUrl: obj
        };
      });
    } else if (this.props.location.pathname.length > 14) {
      //console.log('did search URL:',  this.props.location.pathname.split('&') );
      obj.design_name = this.props.location.pathname
        .split("&")[1]
        .split("=")[1];
      obj.date_event = this.props.location.pathname.split("&")[2].split("=")[1];
      obj.location_event = +this.props.location.pathname
        .split("&")[6]
        .split("=")[1];
      obj.timer_format = this.props.location.pathname
        .split("&")[3]
        .split("=")[1]
        .split("_")
        .join(" ");
      obj.title.name_event = this.props.location.pathname
        .split("&")[4]
        .split("=")[1]
        .split("_")
        .join(" ");
      obj.title.style = this.props.location.pathname
        .split("&")[5]
        .split("=")[1]
        .split("_")
        .join(" ");
      // console.log('obj2', obj);
      this.setState(prevState => {
        return {
          optionsFromUrl: obj
        };
      });
    }
  }
  getComponentToRender() {
    // const {countdownReducer} = this.props;
    if (this.state.optionsFromUrl.design_name === "Housing") return <Housing />;
    else if (this.state.optionsFromUrl.design_name === "StarWars")
      return <StarWars />;
    else if (this.state.optionsFromUrl.design_name === "Airplane")
      return <Airplane />;
    else if (this.state.optionsFromUrl.design_name === "Halloween")
      return <Halloween />;
    else if (this.state.optionsFromUrl.design_name === "Birthday")
      return <Birthday />;
    else if (this.state.optionsFromUrl.design_name === "NewYear")
      return <NewYear />;
    //if (countdownReducer.design_name === 'Housing') return <Housing />;
    else return <h2> :( </h2>;
  }
  setDeadlineSeconds() {
    // const {countdownReducer} = this.props;
    // let date = countdownReducer.date_event;
    let date = this.state.optionsFromUrl.date_event;
    if (date !== "") {
      let dateNowSeconds = Date.parse(new Date());
      let year = +date.split("T")[0].split("-")[0];
      let mounth = +date.split("T")[0].split("-")[1];
      let day = +date.split("T")[0].split("-")[2];
      let hh = +date.split("T")[1].split(":")[0];
      let mm = +date.split("T")[1].split(":")[1];
      let ss = +date.split("T")[1].split(":")[2];
      let nowDateFull = new Date(year, mounth, day, hh, mm, ss);
      let dateSettSecind = Date.parse(new Date(year, mounth, day, hh, mm, ss));
      let differenceSeconds = dateSettSecind - dateNowSeconds;
      // console.log('setting', dateSettSecind);
      // console.log('nows_sec', dateNowSeconds);
      // console.log('diff_sec', differenceSeconds);
      // console.log('new_Date', nowDateFull);
      if (this.state.differenceSeconds === 0)
        this.setState({ differenceSeconds, nowDateFull });
    }
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    // const {countdownReducer} = this.props;
    if (completed) {
      return <Completionist />;
    } else {
      //if (countdownReducer.timer_format === 'Just Days') {
      if (this.state.optionsFromUrl.timer_format === "Just Days") {
        return (
          <div id="clockdiv">
            <div>
              <span className="days">{days}</span>
              <div className="smalltext">Day</div>
            </div>
          </div>
        );
        // } else if (countdownReducer.timer_format === 'Days / Hours / Minutes / Seconds') {
      } else if (
        this.state.optionsFromUrl.timer_format ===
        "Days / Hours / Minutes / Seconds"
      ) {
        return (
          <div id="clockdiv">
            <div>
              <span className="days">{days}</span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="days">{hours}</span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="days">{minutes}</span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="days">{seconds}</span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
        );
        // } else if (countdownReducer.timer_format === 'Weeks / Days / Hours / Minutes / Seconds') {
      } else if (
        this.state.optionsFromUrl.timer_format ===
        "Weeks / Days / Hours / Minutes / Seconds"
      ) {
        let weeks = (days / 7).toFixed(0) > 0 ? (days / 7).toFixed(0) : 0;
        let daysSett = days - weeks * 7;
        if ((daysSett + "")[0] === "-") {
          weeks = ((days + daysSett) / 7).toFixed(0);
          daysSett = 7 + daysSett;
        }
        return (
          <div id="clockdiv">
            <div>
              <span className="days">{weeks}</span>
              <div className="smalltext">Weeks</div>
            </div>
            <div>
              <span className="days">{daysSett}</span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="days">{hours}</span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="days">{minutes}</span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="days">{seconds}</span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
        );
        //} else if (countdownReducer.timer_format === 'Months / Days / Hours / Minutes / Seconds') {
      } else if (
        this.state.optionsFromUrl.timer_format ===
        "Months / Days / Hours / Minutes / Seconds"
      ) {
        let mounths = (days / 30).toFixed(0) > 0 ? (days / 30).toFixed(0) : 0;
        let daysSett = days - mounths * 30;
        if ((daysSett + "")[0] === "-") {
          mounths = ((days + daysSett) / 30).toFixed(0);
          daysSett = 30 + daysSett;
        }
        return (
          <div id="clockdiv">
            <div>
              <span className="days">{mounths}</span>
              <div className="smalltext">Months</div>
            </div>
            <div>
              <span className="days">{daysSett}</span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="days">{hours}</span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="days">{minutes}</span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="days">{seconds}</span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
        );
      } else if (
        this.state.optionsFromUrl.timer_format ===
        "Years / Months / Days / Hours / Minutes / Seconds"
      ) {
        let years = (days / 365).toFixed(0) > 0 ? (days / 365).toFixed(0) : 0;
        let mounths = (days / 30).toFixed(0) > 0 ? (days / 30).toFixed(0) : 0;
        let mounthsSett = mounths - years * 12;
        let daysSett = days - mounths * 30;
        if ((daysSett + "")[0] === "-") {
          mounths = ((days + daysSett) / 30).toFixed(0);
          daysSett = 30 + daysSett;
        }
        return (
          <div id="clockdiv">
            <div>
              <span className="days">{years}</span>
              <div className="smalltext">Years</div>
            </div>
            <div>
              <span className="days">{mounthsSett}</span>
              <div className="smalltext">Months</div>
            </div>
            <div>
              <span className="days">{daysSett}</span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="days">{hours}</span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="days">{minutes}</span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="days">{seconds}</span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
        );
      }
    }
  };
  getTimeUntil() {
    if (typeof this.state.nowDateFull === "object") {
      return `Time until ${moment(this.state.nowDateFull).format(
        "dddd, D MMMM, YYYY HH:mm:ss"
      )}`;
    } else {
      return ``;
    }
  }

  render() {
    // const {countdownReducer} = this.props;
    //console.log('CreatedTimer', countdownReducer);
    //console.log('optionsFromUrl', this.state.optionsFromUrl);
    //console.log('search URL', this.props.location.pathname);
    this.setDeadlineSeconds();
    return (
      <div className="created-timer">
        <div>{this.getComponentToRender()}</div>
        <div
          style={{ fontFamily: `${this.state.optionsFromUrl.title.style}` }}
          className="created-timer-title_name"
        >
          <p>{this.state.optionsFromUrl.title.name_event}</p>
        </div>
        <div className="created-timer-format">
          <Countdown
            date={Date.now() + this.state.differenceSeconds}
            renderer={this.renderer}
          />
        </div>
        <div className="created-timer-time_until">
          <p>{this.getTimeUntil()}</p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    countdownReducer: state.countdownReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatedTimer);
