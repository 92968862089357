import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";

import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import Ads from "../../Ads/Ads";
import ResultBlock from "./ResultBlock";
import SimpleDatePicker from "./SimpleDatePicker";
import SimpleTimePicker from "./SimpleTimePicker";
import SimpleRadioBtn from "./SimpleRadioBtn";
import SimpleCheckbox from "./SimpleCheckbox";

import flightTimeInfo from "../../../../../actions/action_flightTimeInfo.js";
import Loader from "../../../../../components/Main/Loader/Loader";
import Api from "../../../../../services/api.js";
import "./Calculator.css";

class Calculator extends Component {
  state = {
    selectOption: { name: "Add", value: "Add(+)" },
    optionsCheckBox: [{ name: "Include time", checked: "" }],
    inputNummListDate: ["Years", "Months", "Weeks", "Days"],
    inputNummListTime: ["Hours", "Minutes", "Seconds"],
    Years: "",
    Months: "",
    Weeks: "",
    Days: "",
    Hours: "",
    Minutes: "",
    Seconds: "",
    currentDate: "",
    style: {
      open: { display: "none" }
    },
    loadStart: false,
    isOpenTime: false,
    activeCalcClass: "",
    activeResetClass: "",
    calculationError: { display: "none" },
    reset: false,
    include: false,
    startpartTime: "am",
    starttypeTime: "12_hours"
  };

  async UNSAFE_componentWillMount() {
    this.setCurrentDate();
  }

  calendarChange(startEnd, event, date) {
    this.setState(prevState => {
      if (startEnd === "start") return { dateStart: date };
      else return { dateEnd: date };
    });
  }

  timeChange(startEnd, event, time) {
    this.setState(prevState => {
      if (startEnd === "start") return { timeStart: time };
      else return { timeEnd: time };
    });
  }

  startCalculation = () => {
    if (
      this.state.dateStart &&
      this.state.timeStart &&
      (this.state.Years.length ||
        this.state.Months.length ||
        this.state.Weeks.length ||
        this.state.Days.length ||
        this.state.Hours.length ||
        this.state.Minutes.length ||
        this.state.Seconds.length)
    ) {
      this.caslculationRequest();
      this.setState(prevState => {
        return {
          calculationError: { display: "none" },
          activeCalcClass: "active",
          activeResetClass: ""
        };
      });
      this.setState({
        style: { open: { display: "block" } },
        loadStart: true
      });
    } else {
      this.setState(prevState => {
        return {
          calculationError: { display: "block" }
        };
      });
    }
  };

  closeResult = () => {
    this.setState({
      style: { open: { display: "none" } },
      activeCalcClass: "",
      activeResetClass: "active",
      calculationError: { display: "none" },
      Years: "",
      Months: "",
      Weeks: "",
      Days: "",
      Hours: "",
      Minutes: "",
      Seconds: "",
      reset: true
    });
    this.setCurrentDate();
  };

  async caslculationRequest() {
    let dataStartRq, timeStartRq, corectDay;
    if (this.state.dateStart && this.state.timeStart) {
      dataStartRq = moment(this.state.dateStart).format("YYYY-MM-DD");
      timeStartRq = moment(this.state.timeStart).format("HH:mm:ss");
      corectDay =
        (+dataStartRq.substring(8, 10) + 1).toString().length === 1
          ? `0${+dataStartRq.substring(8, 10) + 1}`
          : +dataStartRq.substring(8, 10) + 1;
      if (!this.state.selectedCurrentDate)
        dataStartRq = `${dataStartRq.substring(
          0,
          8
        )}${corectDay}${dataStartRq.substring(10, 11)}`;
    }
    let startRq = `${dataStartRq}T${timeStartRq}`;
    let seconds = this.state.Seconds ? `&seconds=${this.state.Seconds}` : "";
    let minutes = this.state.Minutes ? `&minutes=${this.state.Minutes}` : "";
    let hours = this.state.Hours ? `&hours=${this.state.Hours}` : "";
    let days = this.state.Days ? `&days=${this.state.Days}` : "";
    let weeks = this.state.Weeks ? `&weeks=${this.state.Weeks}` : "";
    let months = this.state.Months ? `&months=${this.state.Months}` : "";
    let years = this.state.Years ? `&years=${this.state.Years}` : "";
    let action = "add";
    if (typeof this.state.selectOption.name === "string")
      action = this.state.selectOption.name === "Add" ? "add" : "sub";
    const data = await Api.userCityList(
      `add_days/?dt=${startRq}${years}${months}${weeks}${days}${hours}${minutes}${seconds}&type=${action}`
    );
    this.setState({
      result: data.data.data,
      partTime: this.state.startpartTime,
      typeTime: this.state.starttypeTime,
      reset: false,
      status: data.data.result
    });
  }

  inputNumm = event => {
    let value = event.target.value;
    let elem = event.target.getAttribute("name");
    if (elem === "Years") this.setState({ Years: value });
    else if (elem === "Months") this.setState({ Months: value });
    else if (elem === "Weeks") this.setState({ Weeks: value });
    else if (elem === "Days") this.setState({ Days: value });
    else if (elem === "Hours") this.setState({ Hours: value });
    else if (elem === "Minutes") this.setState({ Minutes: value });
    else if (elem === "Seconds") this.setState({ Seconds: value });
  };

  inputNummValue(event) {
    const elem = event.target.getAttribute("name");
    if (elem === "Years") return this.state.Years;
    else if (elem === "Months") return this.state.Months;
    else if (elem === "Weeks") return this.state.Weeks;
    else if (elem === "Days") return this.state.Days;
    else if (elem === "Hours") return this.state.Hours;
    else if (elem === "Minutes") return this.state.Minutes;
    else if (elem === "Seconds") return this.state.Seconds;
  }

  setCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    if (this.state.currentDate === "") {
      this.setState({
        dateStart: date,
        timeStart: date,
        selectedCurrentDate: true
      });
    }
  };

  setDateStartFromChild(date) {
    this.setState({
      dateStart: date,
      timeStart: date,
      selectedCurrentDate: true,
      reset: false
    });
  }

  setTimeStartFromChild(date) {
    this.setState({
      dateStart: date,
      timeStart: date,
      selectedCurrentDate: true,
      reset: false
    });
  }

  setTypeTimeFromChild(typeTime) {
    this.setState({ starttypeTime: typeTime, reset: false });
  }

  setPartTimeFromChild(partTime) {
    this.setState({ startpartTime: partTime });
  }

  returnTrueResult() {
    return this.state.result ? this.state.result : "wait";
  }

  setSelectOptionFromChild(obj) {
    this.setState({ selectOption: obj });
  }

  setCheckOptionFromChild(arr) {
    if (arr[0].checked === "checked") {
      this.setState({
        isOpenTime: true,
        time: {
          hh: "",
          mm: "",
          ss: "",
          part: "am"
        },
        include: true
      });
    } else {
      this.setState({
        isOpenTime: false,
        time: {
          hh: "00",
          mm: "00",
          ss: "00",
          part: "am"
        },
        include: false
      });
    }
    this.setState({ optionsCheckBox: arr });
  }

  startLoader() {
    if (!this.state.status && this.state.loadStart) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="CalculatorBlock">
        {this.startLoader()}
        <div className="header">
          <h3>Date calculator</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <Link to={"/date-to-date-calculator"}>
            <button className="btn">Duration between two dates</button>
          </Link>
          <button className="btn active">Add/Subtract days to a date</button>
        </div>

        <div className="Calculator-wrapper">
          <div className="Calculator-content">
            <div className="Calculator">
              <div className="content">
                <span className="calculator_title">
                  Please enter start date and days to be added or subtracted to
                  it.
                </span>
                <div className="content-info">
                  <div className="left-block ">
                    <div className="info-block">
                      <span className="calculator_title block_title">
                        Start Date
                      </span>
                    </div>

                    <SimpleDatePicker
                      getDate={e => this.setDateStartFromChild(e)}
                      date={this.state.dateStart} // required parameter
                      reset={this.state.reset} // required parameter
                    />

                    <div style={{ marginTop: "20px" }}></div>
                    {this.state.isOpenTime && (
                      <SimpleTimePicker
                        getTime={time => this.setTimeStartFromChild(time)}
                        getTypeTime={typeTime =>
                          this.setTypeTimeFromChild(typeTime)
                        }
                        getPartTime={partTime =>
                          this.setPartTimeFromChild(partTime)
                        }
                        date={this.state.dateStart} // required parameter
                        reset={this.state.reset} // required parameter
                        include={this.state.include} // only this component
                      />
                    )}

                    <div style={{ marginTop: "17px" }}></div>
                    <div className="info-block">
                      <div className="info">
                        <div className="check_include_time">
                          <SimpleCheckbox
                            options={this.state.optionsCheckBox}
                            getOptions={e => this.setCheckOptionFromChild(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="center-block">
                    <div style={{ marginTop: "85px" }}></div>
                    <div className="info-block">
                      <SimpleRadioBtn
                        dicoration="row" // required parameter
                        selectOption={this.state.selectOption} // required parameter
                        options={[
                          { name: "Add", value: "Add" },
                          { name: "Subtract", value: "Subtract" }
                        ]} // required parameter
                        select={e => this.setSelectOptionFromChild(e)}
                      />
                    </div>
                  </div>
                  <div className="right-block">
                    <div style={{ marginTop: "86px" }}></div>
                    <div className="info-block-row">
                      {this.state.inputNummListDate.map((elem, index) => (
                        <div className="input-date" key={index}>
                          <div className="row">
                            <label className="label">{elem}</label>
                            <input
                              type="number"
                              name={elem}
                              placeholder=""
                              value={this.state[elem]}
                              onChange={this.inputNumm}
                            ></input>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ marginTop: "20px" }}></div>
                    {this.state.isOpenTime && (
                      <div className="info-block-row">
                        {this.state.inputNummListTime.map((elem, index) => (
                          <div className="input-date" key={index}>
                            <div className="row">
                              <label className="label">{elem}</label>
                              <input
                                type="number"
                                name={elem}
                                placeholder=""
                                value={this.state[elem]}
                                onChange={this.inputNumm}
                              ></input>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row_select_buttom">
                <button
                  className={`btn w-197p ${this.state.activeCalcClass}`}
                  onClick={this.startCalculation}
                >
                  CALCULATE
                </button>
                <button
                  className={`btn w-197p ${this.state.activeResetClass}`}
                  onClick={this.closeResult}
                >
                  RESET
                </button>
                <b style={this.state.calculationError}>
                  Fill in all the fields
                </b>
              </div>

              <div style={this.state.style.open}>
                <ResultBlock
                  result={this.returnTrueResult()}
                  excludeTime={!this.state.include}
                  typeTime={this.state.typeTime}
                  partTime={this.state.partTime}
                  status={this.state.status}
                />
              </div>
            </div>
            <Ads />
          </div>
          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flightTimeInfoReducer: state.flightTimeInfoReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    flightTimeInfo: bindActionCreators(flightTimeInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
