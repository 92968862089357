import React, { Component } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";

class Meta extends Component {
  state = {
    // opgPicture: null,
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.currentSearchReducer !== this.props.currentSearchReducer)
  //     this.opgPicture();
  // }

  metaCityName(nameType) {
    const { currentSearchReducer } = this.props;

    const urlCity = window.location.pathname.split("/")[4];
    const parsedUrlCity =
      urlCity.charAt(0).toUpperCase() + urlCity.slice(1).replace(/-/g, " ");

    const urlCountry = window.location.pathname.split("/")[2];
    const parsedUrlCountry =
      urlCountry.charAt(0).toUpperCase() +
      urlCountry.slice(1).replace(/-/g, " ");

    const name = currentSearchReducer.name
      ? currentSearchReducer.name
      : parsedUrlCity;
    const country = currentSearchReducer.country_name
      ? currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name
      : parsedUrlCountry === "usa"
      ? "USA"
      : parsedUrlCountry;

    let state = nameType === "title" ? "" : `${currentSearchReducer.state},`;
    switch (nameType) {
      case "title":
        return ` ${name}, ${country}`;
      case "description":
        return ` ${name},${country}`;
      case "keywords":
        return `, ${name}, ${state} ${country},`;
      default:
        return "";
    }
  }

  // async opgPicture() {
  //   const { currentSearchReducer } = this.props;
  //   if (currentSearchReducer.name) {
  //     const newId = currentSearchReducer.url
  //       .replace("place/", "")
  //       .replace(/\//g, "__");
  //     const opgPicture = await Api.userCityList(
  //       `opg/panchang/get-url/?geo_name=${newId}`
  //     );
  //     this.setState({ opgPicture: opgPicture.data.url });
  //   }
  // }

  render() {
    return (
      <MetaTags>
        <title>
          Online Panchang, Panchangam and Hindu Calendar for{" "}
          {this.metaCityName("title")}
        </title>
        {/*<meta*/}
        {/*  name="og:title"*/}
        {/*  content={`Online Panchang, Panchangam and Hindu Calendar for ${this.metaCityName(*/}
        {/*    "title"*/}
        {/*  )}`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="og:description"*/}
        {/*  content={`See Panchang, Panchangam and Hindu Calendar for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. Get to know nakshatra, yoga, tithi, karana, chandrabalam, tarabalam, choghadiya and more for cities around the world.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content={`See Panchang, Panchangam and Hindu Calendar for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. Get to know nakshatra, yoga, tithi, karana, chandrabalam, tarabalam, choghadiya and more for cities around the world.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content={`panchang, panchangam, hindu calendar${this.metaCityName(*/}
        {/*    "keywords"*/}
        {/*  )} nakshatra, yoga, tithi, karana, chandrabalam, tarabalam, choghadiya, auspicious, inauspicious, rahu kalam, rahu kaal, vikram samvat, hindu sunrise, hindu sunset`}*/}
        {/*/>*/}
        {/*{this.state.opgPicture ? (*/}
        {/*  <meta*/}
        {/*    property="og:image"*/}
        {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
        {/*  />*/}
        {/*) : null}*/}
      </MetaTags>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(Meta);
