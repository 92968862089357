import React, { Component } from "react";
import Select from "react-select";

import moment from "moment-timezone/index.js";
import "../../UnixConverter/Calculator/Calculator.css";
import customStyleSelect from "./customStyleSelect";
import { MuiThemeProvider, TimePicker } from "material-ui";

class SimpleTimePicker extends Component {
  state = {
    optionsPartTime: [
      { value: "am", label: "am" },
      { value: "pm", label: "pm" }
    ],
    time: {
      hh: "12",
      mm: "00",
      ss: "00",
      part: "am"
    },
    typeTime: "12_hours",
    hours_12: "hours_12_active",
    hours_24: ""
  };

  UNSAFE_componentWillMount() {
    const { include } = this.props;
    if (include === true) {
      this.setState({
        time: {
          hh: "",
          mm: "",
          ss: "",
          part: "am"
        }
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps) {
    const { reset } = this.props;
    if (reset !== nextProps.reset) {
      this.checkReset(nextProps.reset);
    }
  }

  checkReset = reset => {
    if (
      (reset && this.state.time.hh !== "") ||
      (reset && this.state.time.mm !== "") ||
      (reset && this.state.time.ss !== "")
    ) {
      this.setState({
        time: {
          hh: "",
          mm: "",
          ss: "",
          part: "am"
        }
      });
    }
  };

  setTimeStartComponents = e => {
    // console.log(this.state.time.part, e.target.value);
    let hh = this.state.time.hh,
      mm = this.state.time.mm,
      ss = this.state.time.ss;
    if (e.target.name === "HH") {
      if (
        this.controleTypeTime() === 12 &&
        e.target.value <= 12 &&
        +e.target.value !== 0
      ) {
        if (this.state.time.part === "pm") {
          hh = +e.target.value + 12;
        } else if (this.state.time.part === "am") {
          hh = +e.target.value === 12 ? 0 : +e.target.value;
        }
        this.setState({
          time: {
            ...this.state.time,
            hh: +e.target.value === 0 ? 12 : +e.target.value
          }
        });
        if (e.target.value.toString().length === 2)
          e.target.parentNode.parentNode.nextSibling.firstChild.children[1].select();
      } else if (this.controleTypeTime() === 23 && e.target.value <= 23) {
        hh = e.target.value;
        this.setState({
          time: { ...this.state.time, hh: +e.target.value }
        });
        if (e.target.value.toString().length === 2)
          e.target.parentNode.parentNode.nextSibling.firstChild.children[1].select();
      }
    } else if (e.target.name === "MM" && +e.target.value <= 60) {
      mm = e.target.value;
      this.setState({
        time: { ...this.state.time, mm: e.target.value }
      });
      // console.log(e.target.value.toString(), e.target.value)
      if (e.target.value.toString().length === 2)
        e.target.parentNode.parentNode.nextSibling.firstChild.children[1].select();
      else if (
        e.target.value.toString().length === 0 &&
        this.state.time.mm.toString().length === 1
      ) {
        e.target.parentNode.parentNode.previousSibling.firstChild.children[1].select();
      }
    } else if (e.target.name === "SS" && +e.target.value <= 60) {
      ss = e.target.value;
      this.setState({
        time: { ...this.state.time, ss: e.target.value }
      });
      if (e.target.value.toString().length === 2) e.target.blur();
      else if (
        e.target.value.toString().length === 0 &&
        this.state.time.ss.toString().length === 1
      ) {
        e.target.parentNode.parentNode.previousSibling.firstChild.children[1].select();
      }
    }
    this.setTimeStart(hh, mm, ss);
  };

  setTimeStart = (hh, mm, ss) => {
    let { date, getTime, getTypeTime } = this.props;
    date = date === null ? new Date() : date;
    // console.log(date)

    const dat = new Date(
      date.getFullYear() ? date.getFullYear() : 0,
      date.getMonth() ? date.getMonth() : 0,
      date.getDate() ? date.getDate() : 0,
      +hh,
      +mm,
      +ss
    );
    // console.log('!!!',dat)
    getTime(dat);
    getTypeTime(this.state.typeTime);
  };

  controleTypeTime = () => {
    if (this.state.typeTime === "12_hours") return 12;
    return 23;
  };

  toggleTypeTime = e => {
    let { date, getTime, getTypeTime, getPartTime } = this.props;
    date = date === null ? new Date() : date;
    let atr = e.target.getAttribute("name");

    if (atr === "hours_12") {
      // let options = {
      //     hour: 'numeric',
      //     minute: 'numeric',
      //     second: 'numeric',
      //     hour12: true
      // };
      // let timeString = date.toLocaleString('en-US', options);
      // let part = timeString;
      // part = part.split(' ')[1] ? part.split(' ')[1].toLowerCase() : this.state.time.part;
      // let hh = timeString.split(':')[0];
      // console.log(1, timeString, 'hh', part, 'part');
      // if (+timeString.split(':')[0] === 12 && part === 'am') {
      //     hh = 12;
      //     part = 'am'
      // }
      // else if (+timeString.split(':')[0] === 12 && part === 'pm') {
      //     hh = 12;
      //     part = 'pm'
      // }

      // console.log(2, date);
      this.setState({
        hours_12: "hours_12_active",
        hours_24: "",
        typeTime: "12_hours",
        // time: {
        //     hh: hh,
        //     mm: date.getMinutes(),
        //     ss: date.getSeconds(),
        //     part: part,
        // }
        time: {
          hh: "",
          mm: "",
          ss: "",
          part: "am"
        }
      });
      getPartTime("am"); //part
      getTypeTime("12_hours");

      const dat = new Date(
        date.getFullYear() ? date.getFullYear() : 0,
        date.getMonth() ? date.getMonth() : 0,
        date.getDate() ? date.getDate() : 0,
        // +hh, date.getMinutes(), date.getSeconds()
        0,
        0,
        0
      );
      getTime(dat);
    } else if (atr === "hours_24") {
      let options = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false
      };
      let timeString = date.toLocaleString("en-US", options);
      let part = timeString;
      part = part.split(" ")[1]
        ? part.split(" ")[1].toLowerCase()
        : this.state.time.part;
      // console.log(1, '24_hours', part, timeString);
      // let hh = timeString.split(':')[0];
      if (+timeString.split(":")[0] === 12 && part === "am") {
        // console.log('24_hours', this.state.time.part, timeString.split(':')[0]);
        // hh = 0;
        part = "am";
      } else if (+timeString.split(":")[0] === 0 && part === "pm") {
        // console.log('24_hours', this.state.time.part, +timeString.split(':')[0] === 0);
        // hh = 12;
        part = "pm";
      } else if (+timeString.split(":")[0] < 12 && part === "pm") {
        // console.log('24_hours', this.state.time.part, +timeString.split(':')[0] === 0);
        // hh = +hh + 12;
        part = "pm";
      }
      // console.log(2, '24_hours', part, timeString);
      this.setState({
        hours_12: "",
        hours_24: "hours_24_active",
        typeTime: "24_hours",
        // time: {
        //     hh: hh,
        //     mm: date.getMinutes(),
        //     ss: date.getSeconds(),
        //     part: part,
        // }
        time: {
          hh: "",
          mm: "",
          ss: "",
          part: "am"
        }
      });
      getTypeTime("24_hours");
      getPartTime("am"); //part

      const dat = new Date(
        date.getFullYear() ? date.getFullYear() : 0,
        date.getMonth() ? date.getMonth() : 0,
        date.getDate() ? date.getDate() : 0,
        // +hh, date.getMinutes(), date.getSeconds()
        0,
        0,
        0
      );
      getTime(dat);
    }
  };

  searchTimePart = value => {
    // console.log(value.value, this.state.time.hh)
    const { getPartTime } = this.props;
    this.setState({
      time: { hh: 12, mm: 0, ss: 0, part: value.value }
    });
    if (value.value === "pm") {
      getPartTime("pm");
      this.setTimeStart(12, 0, 0);
    } else {
      getPartTime("am");
      this.setTimeStart(0, 0, 0);
    }
  };

  timeChange(date) {
    // console.log(date)
    // console.log(moment(date).format('YYYY'), moment(date).format('M'), moment(date).format('D'))
    // console.log(moment(date).format('HH'), moment(date).format('mm'), moment(date).format('ss'))
    const { getTime, getTypeTime, getPartTime } = this.props;
    // const {part} = this.state.time;
    const { typeTime } = this.state;
    // console.log('part',part);
    if (typeTime === "12_hours") {
      // console.log('12_hours', date);
      // console.log(moment(date).format('HH'), moment(date).format('mm'), moment(date).format('ss'))
      if (+moment(date).format("HH") > 12) {
        // console.log(+moment(date).format('HH') > 12, +moment(date).format('HH')-12)
        this.setState({
          time: {
            part: "pm",
            hh: +moment(date).format("HH") - 12,
            mm: moment(date).format("mm"),
            ss: moment(date).format("ss")
          }
        });
      } else {
        this.setState({
          time: {
            part: "am",
            hh: moment(date).format("HH"),
            mm: moment(date).format("mm"),
            ss: moment(date).format("ss")
          }
        });
      }
    } else if (typeTime === "24_hours") {
      // console.log('24_hours', date);
      // console.log(moment(date).format('HH'), moment(date).format('mm'), moment(date).format('ss'))
      this.setState({
        time: {
          part: "am",
          hh: +moment(date).format("HH"),
          mm: moment(date).format("mm"),
          ss: moment(date).format("ss")
        }
      });
    }
    // console.log('typeTime', typeTime)
    // const {getTime} = this.props;
    // this.setState({
    //     time: {
    //         part,
    //         hh: moment(date).format('HH'),
    //         mm: moment(date).format('mm'),
    //         ss: moment(date).format('ss'),
    //     }
    // });
    getTime(date);
    getTypeTime(this.state.typeTime);
    getPartTime(this.state.time.part);
  }

  render() {
    // console.log(this.state.typeTime)
    return (
      <div className="simple_time_piker">
        {/*<div className='info-block'>*/}
        {/*<h5 className="row_title ">Time</h5>*/}
        {/*</div>*/}
        <div className="info-block center-ai">
          <div className="image">
            <i className="material-icons">access_time</i>
            <MuiThemeProvider>
              <TimePicker
                className="calendar-date-picker"
                // format="ampm"
                format={this.state.typeTime === "12_hours" ? "ampm" : "24hr"}
                hintText=" "
                value={null}
                onChange={(event, date) => this.timeChange(date)}
                autoOk={true}
              />
            </MuiThemeProvider>
          </div>

          <div className="info-block-row simple_time_piker">
            <div className="input-date">
              <div className="row">
                <label className="label">HH</label>
                <input
                  type="number"
                  name="HH"
                  placeholder=""
                  value={this.state.time.hh}
                  onChange={this.setTimeStartComponents}
                ></input>
              </div>
            </div>
            <div className="input-date">
              <div className="row">
                <label className="label">MM</label>
                <input
                  type="number"
                  name="MM"
                  placeholder=""
                  value={this.state.time.mm}
                  onChange={this.setTimeStartComponents}
                ></input>
              </div>
            </div>
            <div className="input-date">
              <div className="row">
                <label className="label">SS</label>
                <input
                  type="number"
                  name="SS"
                  placeholder=""
                  value={this.state.time.ss}
                  onChange={this.setTimeStartComponents}
                ></input>
              </div>
            </div>
            {this.state.typeTime === "12_hours" && (
              <div className="input-date time_select">
                <Select
                  styles={customStyleSelect()}
                  options={this.state.optionsPartTime}
                  clearable={false}
                  name="selected-state"
                  disabled={false}
                  value={this.state.time.part}
                  onChange={this.searchTimePart}
                  searchable={false}
                  placeholder={this.state.time.part}
                />
              </div>
            )}
          </div>
        </div>
        <div className="toggle_type_time">
          <div
            className={`hours_12 ${this.state.hours_12}`}
            name="hours_12"
            onClick={this.toggleTypeTime}
          >
            <span name="hours_12">am</span>
            <span name="hours_12">pm</span>
          </div>
          <div
            className={`hours_24 ${this.state.hours_24}`}
            name="hours_24"
            onClick={this.toggleTypeTime}
          >
            24
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleTimePicker;
