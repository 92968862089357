import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";
// import {MuiThemeProvider, DatePicker} from 'material-ui';

import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import BlockSettings from "../../BusinessDateToDateCalc/Calculator/BlockSettings/BlockSettings";
import Loader from "../../../../../components/Main/Loader/Loader";
import SimpleDatePicker from "../../../Calculators/DateCalculator/Calculator/SimpleDatePicker";
import SimpleRadioBtn from "../../DateCalculator/Calculator/SimpleRadioBtn";
import Ads from "../../Ads/Ads";

import Api from "../../../../../services/api.js";
import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";

class Calculator extends Component {
  state = {
    selectOption: { name: "Add", value: "Add(+)" },
    Days: "",
    result: "",
    in_ex_daysText: "",
    id: this.props.currentSearchReducer.id,
    style: {
      open: { display: "none" }
    },
    loadStart: false,
    classError: "",
    classError2: "",
    timeState: false,
    businesDTDReducer: this.props.businesDTDReducer,
    hh: "",
    mm: "",
    ss: "",
    hh2: "",
    mm2: "",
    ss2: "",
    currentDate: "",
    activeCalcClass: "",
    calculationError: { display: "none" },
    reset: false
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps')
    // if (this.props.currentSearchReducer !== nextProps.currentSearchReducer) {
    this.setState({
      businesDTDReducer: nextProps.businesDTDReducer,
      id: nextProps.currentSearchReducer.id
    });
    // }
  }

  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;
    let data;
    // let url;
    const cityInfoLoad = cityInfo => {
      //console.log('cityInfo', cityInfo)
      // url = cityInfo.url;
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
      // localStorage.setItem('searchCityInfo', JSON.stringify(data.data));
    }

    this.setState(prevState => {
      return { id: this.props.currentSearchReducer.id };
    });
  }

  calendarChange(startEnd, event, date) {
    this.setState(prevState => {
      if (startEnd === "start") return { dateStart: date };
      else return { dateEnd: date };
    });
  }

  timeChange(startEnd, event, time) {
    this.setState(prevState => {
      if (startEnd === "start") return { timeStart: time };
      else return { timeEnd: time };
    });
  }

  startCalculation = () => {
    // console.log(this.state.dateStart, this.state.dateEnd)
    if (this.state.dateStart && this.state.Days) {
      this.setState({ loadStart: true });

      const startFormat = moment(this.state.dateStart).format("YYYY-MM-DD");
      const days = +this.state.Days;

      // let msUTCStart = Date.parse(startFormat);
      // console.log('startFormat', startFormat);
      // console.log('endFormat', endFormat);

      this.caslculationRequest(startFormat, days);

      this.setState(prevState => {
        return {
          calculationError: { display: "none" },
          style: { open: { display: "block" } },
          loadStart: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          calculationError: { display: "block" },
          classError: "input-error",
          classError2: "input-error"
        };
      });
    }
  };

  async caslculationRequest(startFormat, days) {
    const { id, businesDTDReducer } = this.state;
    let week = businesDTDReducer.standard
      ? businesDTDReducer.standard.exclude_weekends
      : false;
    let holi = businesDTDReducer.standard
      ? businesDTDReducer.standard.exclude_holidays
      : false;
    let in_ex_days = businesDTDReducer.custom
      ? `&in_ex_days=${businesDTDReducer.custom.in_ex_days}`
      : "";
    let public_holidays = businesDTDReducer.custom
      ? `&public_holidays=${businesDTDReducer.custom.public_holidays}`
      : "";
    let data;

    let in_ex_daysText = businesDTDReducer.custom
      ? businesDTDReducer.custom.in_ex_days
      : "";

    let action = "add";
    if (typeof this.state.selectOption.name === "string")
      action = this.state.selectOption.name === "Add" ? "add" : "sub";

    //console.log('-->>', businesDTDReducer.custom.exclude_days)
    if (businesDTDReducer.typeRQ === "CUSTOM SETTINGS") {
      // console.log(`API-standard work_days/?dt=${startFormat.substring(0, 10)}&dt2=${endFormat.substring(0, 10)}&geo_name=${id}&weekends=${week}&public_holidays=${holi}`);
      data = await Api.userCityList(
        `add_work_days/?dt=${startFormat.substring(
          0,
          10
        )}&days=${days}&geo_name=${id}&weekends=${week}&public_holidays=${holi}&type=${action}`
      );
    } else {
      // console.log(`API-custom work_days/?dt=${startFormat.substring(0, 10)}&dt2=${endFormat.substring(0, 10)}&geo_name=${id}${in_ex_days}${this.getCustomWeekDays()}${public_holidays}`);
      data = await Api.userCityList(
        `add_work_days/?dt=${startFormat.substring(
          0,
          10
        )}&days=${days}&geo_name=${id}${in_ex_days}${this.getCustomWeekDays()}${public_holidays}&type=${action}`
      );
    }
    // const data2 = await Api.userCityList(`work_days/?dt=2018-08-27&dt2=2018-09-08&geo_name=2643743&weekends=true&public_holidays=true`);
    // console.log('data', data.data.data);

    this.setState({
      result: data.data.data,
      loadStart: false,
      activeCalcClass: "active",
      reset: false,
      in_ex_daysText: in_ex_daysText
    });
  }

  getCustomWeekDays() {
    const { businesDTDReducer } = this.state;
    let exclude_days = businesDTDReducer.custom
      ? businesDTDReducer.custom.exclude_days
      : [];
    let arr = exclude_days.map(item => {
      return `&custom_week_days=${item}`;
    });
    return arr.join("");
  }

  setCurrentDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    if (this.state.currentDate === "") {
      this.setState({
        dateStart: date,
        timeStart: date,
        selectedCurrentDate: true
      });
    }
  };

  setTimeState() {
    let today = new Date();
    let today2 = new Date();
    //console.log('start', this.state.hh , this.state.mm, this.state.ss, 0)
    //console.log('start2', this.state.hh2, this.state.mm2, this.state.ss2, 0)
    if (this.state.timeState) {
      today.setHours(this.state.hh, this.state.mm, this.state.ss, 0);
      today2.setHours(this.state.hh2, this.state.mm2, this.state.ss2, 0);
      this.setState({
        timeStart: today,
        classError: "",
        timeEnd: today2,
        classError2: "",
        timeState: false
      });
    }
  }

  closeResult = () => {
    this.setState({
      style: { open: { display: "none" } },
      dateStart: "",
      timeStart: "",
      dateEnd: "",
      timeEnd: "",
      hh: "",
      mm: "",
      ss: "",
      hh2: "",
      mm2: "",
      ss2: "",
      classError: "",
      classError2: "",
      loadStart: false,
      activeCalcClass: "",
      reset: true
    });
  };

  getDateResult(value) {
    let date;
    if (this.state.result) {
      if (value === "from") {
        date = this.state.result.from.date;
        return `${moment(date).format(" dddd, MMMM Do YYYY")}`;
      } else if (value === "to") {
        date = this.state.result.to.date;
        return `${moment(date).format(" dddd, MMMM Do YYYY")}`;
      }
    } else return ``;
  }

  getExclud() {
    if (this.state.result) {
      const { weekends, public_holidays } = this.state.result;
      if (weekends && public_holidays) return `Weekends and Holidays`;
      else if (weekends) return `Weekends`;
      else if (public_holidays) return `Holidays`;
      else return `No days`;
    } else return `No days`;
  }

  getAllAndSkippedDays() {
    if (this.state.result) {
      const { calendar_days, skipped } = this.state.result;
      // const arrPublic_holidaysSpecialWork = [];
      // let arrWeekendsFake = [], arrWeekendsTrue = [], arrPublic_holidaysTrue = [];
      // if (public_holidays) {
      //     arrPublic_holidaysTrue = public_holidays.days.filter(item => {
      //         if (item.title === 'Special Working Day') {
      //             arrPublic_holidaysSpecialWork.push(moment(item.start_date).format('YYYY-MM-DD'));
      //             if (weekends) {
      //                 weekends.days.forEach(weekend => {
      //                     if (moment(item.start_date).format('YYYY-MM-DD') === moment(weekend.date).format('YYYY-MM-DD')) {
      //                         arrWeekendsFake.push(weekend)
      //                     } else arrWeekendsTrue.push(weekend)
      //                 })
      //             }
      //         } else {
      //             return item
      //         }
      //     });
      // }

      const calendarDays = calendar_days
        ? `${calendar_days} calendar days `
        : "";
      const skipp = skipped ? `- ${+skipped} days skipped` : "";
      return `${calendarDays}${skipp}`;
    } else return `No days`;
  }

  showExcludedWeekHoli(status) {
    const { weekends, public_holidays, result } = this.state.result;
    switch (status) {
      case "weeks":
        // eslint-disable-next-line no-case-declarations
        let saturdayCount = 0,
          sundayCount = 0,
          saturday,
          sunday;
        if (weekends) {
          weekends.days.forEach(item => {
            if (item.week_day === 5) ++saturdayCount;
            if (item.week_day === 6) ++sundayCount;
          });
          saturday = `${saturdayCount} Saturday. `;
          sunday = `${sundayCount} Sunday`;
        }
        saturday = `${saturdayCount} Saturday. `;
        sunday = `${sundayCount} Sunday`;
        if (saturdayCount !== 0 && sundayCount !== 0)
          return `Excluded ${saturday}${sunday}`;
        break;
      case "holidays":
        if (public_holidays) {
          if (public_holidays.count !== 0)
            return `Excluded ${public_holidays.days.length} holidays:`;
        }
        break;
      case "list_holidays":
        if (public_holidays) {
          return public_holidays.days.map(item => (
            <li key={item.external_id}>
              {item.title} ({moment(item.start_date).format("ll")})
            </li>
          ));
        }
        break;
      case "resultDay":
        return `${moment(result).format(" dddd, MMMM Do YYYY")}`;
      // break;
      default:
        return "";
    }
  }

  showExcludedCustomWeek(in_ex_days) {
    const { custom_week_days } = this.state.result;

    const week = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    let arrRender = [];
    if (custom_week_days) {
      if (custom_week_days.count !== 0)
        for (let key in custom_week_days) {
          if (key !== "count" && key !== "days") {
            arrRender.push(
              `${in_ex_days.charAt(0).toUpperCase() + in_ex_days.substr(1)} ${
                custom_week_days[key]
              } ${week[key]}`
            );
          }
        }
    }
    return arrRender.map(item => (
      <div key={item} className="title_result_content">
        <p>{item}</p>
      </div>
    ));
  }

  setDateStartFromChild(date, status) {
    this.setState(prevState => {
      if (status === "start") {
        return {
          dateStart: date,
          timeStart: date,
          selectedCurrentDate: true,
          reset: false
        };
      } else {
        return {
          dateEnd: date,
          timeEnd: date,
          selectedCurrentDate: true,
          reset: false
        };
      }
    });
  }

  inputNumm = event => {
    let value = event.target.value;
    let elem = event.target.getAttribute("name");
    if (elem === "Days") this.setState({ Days: value });
  };

  startLoader() {
    //const value = false;
    if (!this.state.result && this.state.loadStart) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
  }

  setSelectOptionFromChild(obj) {
    this.setState({ selectOption: obj });
  }

  render() {
    // console.log('state ', this.state.selectOption);
    //console.log('props 1', this.props);
    this.setTimeState();
    return (
      <div className="CalculatorBlock">
        {this.startLoader()}
        <div className="header">
          <h3>Business calculator</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <Link to={"/business-date-to-date-calculator/"}>
            <button className="btn">Duration between two dates</button>
          </Link>
          <button className="btn active">Add/Subtract days to a date</button>
        </div>

        <div className="Calculator-wrapper">
          <div className="Calculator-content">
            <div className="Calculator">
              <div className="content">
                <span className="calculator_title">
                  Please enter start date and days to be added or subtracted to
                  it.
                </span>
                <div className="content-info">
                  <div className="left-block ">
                    <div className="info-block">
                      <span className="calculator_title block_title">
                        Start Date
                      </span>
                    </div>
                    <SimpleDatePicker
                      getDate={e => this.setDateStartFromChild(e, "start")}
                      date={this.state.dateStart} // required parameter
                      reset={this.state.reset} // required parameter
                    />
                  </div>
                  <div className="center-block">
                    {/*<div style={{marginTop: '113px'}}></div>*/}
                    <div className="info-block add_sub">
                      <SimpleRadioBtn
                        dicoration="row" // required parameter
                        selectOption={this.state.selectOption} // required parameter
                        options={[
                          { name: "Add", value: "Add" },
                          { name: "Subtract", value: "Subtract" }
                        ]} // required parameter
                        select={e => this.setSelectOptionFromChild(e)}
                      />
                    </div>
                  </div>
                  <div className="right-block right_block_days">
                    {/*<div style={{marginTop: '86px'}}></div>*/}
                    <div className="info-block-row">
                      <div className="input-date days">
                        <div className="row">
                          <label className="label">Days</label>
                          <input
                            type="number"
                            name="Days"
                            placeholder=""
                            value={this.state.Days}
                            onChange={this.inputNumm}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BlockSettings />
              <div className="row_select_buttom">
                <button
                  className={`btn w-197p ${this.state.activeCalcClass}`}
                  onClick={this.startCalculation}
                >
                  CALCULATE
                </button>
                <button
                  className={`btn w-197p ${this.state.activeResetClass}`}
                  onClick={this.closeResult}
                >
                  RESET
                </button>
                <b style={this.state.calculationError}>
                  Fill in all the fields
                </b>
              </div>

              <div style={this.state.style.open}>
                <div className="row_result">
                  <div className="title_result">Business calculator</div>
                  <div className="content_result">
                    <div className="title_result_content">
                      <span>From: </span>&nbsp;{" "}
                      <p> {this.getDateResult("from")}</p>
                    </div>
                    <div className="title_result_content">
                      <p>{this.getAllAndSkippedDays()}</p>
                    </div>
                    {this.showExcludedCustomWeek(this.state.in_ex_daysText)}
                    <div className="title_result_content">
                      <p>{this.showExcludedWeekHoli("weeks")}</p>
                    </div>
                    <div className="title_result_content">
                      <p>{this.showExcludedWeekHoli("holidays")}</p>
                    </div>
                    <div className="title_result_content">
                      <ul>{this.showExcludedWeekHoli("list_holidays")}</ul>
                    </div>
                  </div>
                  <div className="title_result_below">
                    <span>Result:</span>&nbsp;
                    <p>{this.showExcludedWeekHoli("resultDay")}</p>
                  </div>
                </div>
              </div>
            </div>
            <Ads />
          </div>
          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    businesDTDReducer: state.businesDTDReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
