const initialState =  {
    design_name: '',
    title: {
        name_event: '',
        style: ''
    },
    date_event: '',
    location_event: '',
    timer_format: ''
};

const countdownReducer = (state = initialState, action) => {
  //console.log('reducer', action.type, action.payload); CLEAR_COUNTDOWN
  switch (action.type) {
      case 'PASS_NAME_DESIGN':
          // eslint-disable-next-line no-case-declarations
        const newObject = {...state};
        newObject.design_name = action.payload.design_name;
      return newObject;
      case 'PASS_TITLE_DESIGN':
          // eslint-disable-next-line no-case-declarations
        const newObjectTitle = {...state};
        newObjectTitle.title.name_event = action.payload.nameevent;
        newObjectTitle.title.style = action.payload.style;
      return newObjectTitle;
      case 'PASS_DATE_EVENT':
          // eslint-disable-next-line no-case-declarations
        const newObjectDateEvent = {...state};
        newObjectDateEvent.date_event = action.payload.dateEvent;
      return newObjectDateEvent;
      case 'PASS_LOCATION_EVENT':
          // eslint-disable-next-line no-case-declarations
        const newObjectLocationEvent = {...state};
        newObjectLocationEvent.location_event = action.payload.locationId;
      return newObjectLocationEvent;
      case 'PASS_DISPLAY_FORMAT':
          // eslint-disable-next-line no-case-declarations
        const newObjectDisplayFormat = {...state};
        newObjectDisplayFormat.timer_format = action.payload.display_format;
      return newObjectDisplayFormat;
      case 'CLEAR_COUNTDOWN':

      return initialState;
    default:
      return state;
  }
};

export default countdownReducer;

