export default [
	{
		title: "Time Format",
		name: "time_format",
		param1: "12 hr",
		param2: "24 hr"
	},
	// {
	// 	title: "Temperature",
	// 	name: "temperature",
	// 	param1: "Fahrenheit",
	// 	param2: "Celsius"
	// },
	// {
	// 	title: "Distance",
	// 	name: "long_distance",
	// 	param1: "Miles",
	// 	param2: "Kilometers"
	// },
	{
		title: "Time Planner Theme",
		name: "timeplanner_theme",
		param1: "Classic",
		param2: "Modern",
		param3: "Dark"
	}
];
