import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Calculator from "./Calculator/Calculator";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";

import Api from "../../../../services/api";
import "./BusinessDateToDateCalc.css";

class BusinessDateToDateCalc extends Component {
  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;
    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
      // localStorage.setItem('searchCityInfo', JSON.stringify(data.data));
    }
  }
  render() {
    return (
      <div className="DateToDateCalculator">
        <Calculator />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDateToDateCalc);
