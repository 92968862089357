import React, { Component } from "react";
import MetaTags from "react-meta-tags";

class Meta extends Component {
  replaceHyphenToSpace = (str) => str.replace(/[-,_]/g, " ");

  capitalizeString = (str) => str[0].toUpperCase() + str.slice(1);

  render() {
    const url = window.location.pathname.split("/");
    const normalUrlsParamsAmount = 5; // empty str, page type, city,state,country, location id

    let city = "";
    let country = "";
    let state = "";

    if (url.length < normalUrlsParamsAmount) {
      country = url.includes("time-in")
        ? this.replaceHyphenToSpace(this.capitalizeString(url[2]))
        : "";
    } else {
      if (url.includes("time-in")) {
        city = this.replaceHyphenToSpace(this.capitalizeString(url[4]));
        country = this.replaceHyphenToSpace(this.capitalizeString(url[2]));
        state = this.replaceHyphenToSpace(this.capitalizeString(url[3]));
      }
    }

    return url.includes("time-in") ? (
      <MetaTags>
        <title>
          Current Time, Sun, Moon, Almanac, Retrogrades, Panchang and Eclipses
          for {city ? `${city},` : ""} {country}
        </title>
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content={`${city ? city + ", " : ""}${*/}
        {/*    state ? state + "," : ""*/}
        {/*  } ${country} - Current time, schedule events, online meetings, sun, moon rise and set, solar, lunar eclipses, ephemeris, panchang, mercury and planets retrogrades, planet signs`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content={`${city ? city + ", " : ""}${*/}
        {/*    state ? state + "," : ""*/}
        {/*  } ${country}, local time, sunrise, moonrise, mercury retrograde, panchang, schedule events, meetings, ephemeris, eclipses, time zone, daylight saving, summer time, time calculators`}*/}
        {/*/>*/}
      </MetaTags>
    ) : (
      <MetaTags>
        <title>
          Find Current Time, Sun, Moon, Almanac, Retrogrades, Panchang and
          Eclipses for cities around the world
        </title>
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content="Current time, schedule events, online meetings, sun, moon rise and set, solar, lunar eclipses, ephemeris, panchang, mercury and planets retrogrades, planet in signs across cities of the world."*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content="local time, sunrise, moonrise, mercury retrograde, panchang, schedule events, meetings, ephemeris, eclipses, time zone, daylight saving, summer time, time calculators."*/}
        {/*/>*/}
      </MetaTags>
    );
  }
}

export default Meta;
