import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";

import "./PlanetaryHoursRightBlock.css";
import PlanetRetrogradesLinks from "../../MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PlanetRetrogradesLinks";
import PlanetSignsLinks from "../../MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PlanetSignsLinks";
import advertisingWeather from "../../../../assets/sun_page/336-280.png";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import planetaryHours from "../../../../actions/action_planetaryHours/action_planetaryHours";

class PlanetaryHoursRightBlock extends Component {
  planets = [
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto",
    "Chiron",
  ];
  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state}, `
          : ""
        : "";
    return `${name}, ${state} ${country}`;
  }
  planets = [
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto",
    "Chiron",
  ];
  cityNameHome(city) {
    const name = city.name;
    const country =
      city.country_name === "United States"
        ? city.country_name_abbr
        : city.country_name;
    const state =
      city.country_name === "United States"
        ? city.state
          ? `${city.state}, `
          : ""
        : "";
    return `${name}, ${state} ${country}`;
  }
  homeCityLoad(city) {
    const { currentSearch, planetaryHours } = this.props;
    currentSearch(city);
    const cityTime = moment(city.time).tz(city.timezone).format();
    planetaryHours(cityTime);
  }
  render() {
    const { currentSearchReducer, planetaryStyle } = this.props;
    const city = localStorage.getItem("mainSearchCity")
      ? JSON.parse(localStorage.getItem("mainSearchCity"))
      : currentSearchReducer;
    const newUrl = city.url
      ? `/${city.url.split("/").slice(1).join("/")}`
      : `/planetary-hours/null/`;
    return (
      <div className="PlanetaryHoursRightBlock">
        <div className="planet-signs">
          <div className="head" style={planetaryStyle}>
            <h4>
              Planet in Signs
              <br />
              for {this.cityName()}
            </h4>
          </div>
          <PlanetSignsLinks />
        </div>
        <div className="planet-signs">
          <div className="head" style={planetaryStyle}>
            <h4>
              Planet Retrogrades
              <br />
              for {this.cityName()}
            </h4>
          </div>
          <PlanetRetrogradesLinks />
        </div>
        <div className="planet-signs">
          <div className="head" style={planetaryStyle}>
            <h4>Related Links</h4>
          </div>
          <div className="body">
            <Link
              to={`/planetary-hours${newUrl}`}
              className={"planetary-hours"}
              onClick={this.homeCityLoad.bind(this, city)}
            >
              Planetary hours for {this.cityNameHome(city)}{" "}
              {moment.tz(currentSearchReducer.timezone).format("ll")}
            </Link>
            <span>Rise and set times for -</span>
            {this.planets.map((elem, index, arr) => (
              <Link to={"/"} key={index}>
                {arr.length === index + 1 ? `and ${elem}` : `${elem}, `}
              </Link>
            ))}
            <Link to={"/"} className={"equinoxes-solstices"}>
              Equinoxes & Solstices for {this.cityNameHome(city)}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    planetaryHours: bindActionCreators(planetaryHours, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanetaryHoursRightBlock);
