export default function customStyleSelect() {
  return {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    menuList: () => ({
      marginTop: 3
    }),
    menu: () => ({
      border: "1px solid #E1E4E7",
      marginTop: 2,
      width: 80,
      height: 60,
      position: "absolute"
    }),
    container: () => ({
      width: 80,
      marginLeft: 1,
      position: "relative",
      alignSelf: "flex-end"
    }),
    option: (base, state) => ({
      ...base,
      padding: 5,
      width: 80,
      color: "gray"
    }),
    control: () => ({
      // none of react-selects styles are passed to <View />
      display: "flex",
      padding: 5,
      width: 80,
      height: 28,
      border: "2px solid #E1E4E7"
    }),
    singleValue: (base, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...base, opacity, transition };
    }
  };
}
