import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './SearchCityPage.css';
import currentSearch from '../../../actions/action_searchCity/action_currentSearch';

class SearchCityPage extends Component {
    render () {
        const { currentSearchReducer } = this.props;
        // console.log(currentSearchReducer)
        return (
            <div>
                <h1>Selected city page</h1>
                <p>{JSON.stringify(currentSearchReducer)}</p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch)        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCityPage);