import React, { Component } from 'react';
import moment from 'moment-timezone/index.js';

import './CityEclipses.css';

class CityEclipses extends Component {
    render () {
        const { eclipseDate, eclipseType } = this.props;
        const page = window.location.pathname.split('/')[1] === 'solar-eclipse-country' ? 'Solar' : 'Lunar';
        return (
            <section className="citySunRiseSet" style={{ background: page === 'Solar' ? '#fff386' : '#aee5fa' }}>
                <div className="city_name_date">
                    <h4>{eclipseType.title} {page} Eclipse - {moment(eclipseDate).tz('Greenwich').format('ll')}</h4>
                </div>
            </section>
        )
    }
}

export default CityEclipses;