import moment from "moment-timezone/index.js";

const sunRiseTimeReducer = (state = moment().format('YYYY-MM-DDTHH:mm:ss'), action) => {
  switch (action.type) {
    case 'SUN_RISE_TIME':
      return action.data;
    default:
      return state;
  }
};
  
export default sunRiseTimeReducer;