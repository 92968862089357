import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";

import Meta from "./Meta";
import Loader from "../../../components/Main/Loader/Loader";
// import SunSocialIconsBlock from '../SunCulc/SunSocialIconsBlock/SunSocialIconsBlock';
import Search from "../MainPage/Search/Search";
import CityMoonRiseSet from "./CityMoonRiseSet/CityMoonRiseSet";
import CityCalendarBlock from "./CityCalendarBlock/CityCalendarBlock";
import MoonCityCalculatedBlock from "./MoonCityCalculatedBlock/MoonCityCalculatedBlock";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import moonCalc from "../../../actions/action_moonRise/action_moonCalc";
import moonRisePosition from "../../../actions/action_moonRise/action_moonRisePosition";
import moonRiseCoords from "../../../actions/action_moonRise/action_moonRiseCoords";
import moonRiseDate from "../../../actions/action_moonRise/action_moonRiseDate";
import moonLogo from "../../../assets/moon_page/moon_page_logo.jpg";

import Api from "../../../services/api.js";
import "./MoonCulc.css";

class MoonCulc extends Component {
	componentDidMount() {
		this.pareReset();
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) this.pareReset();
	}

	async pareReset() {
		const {
			moonCalc,
			moonRisePosition,
			moonRiseCoords,
			moonRiseDate
		} = this.props;
		const newId = window.location.pathname
			.split("/")
			.slice(2)
			.join("__");
		const moonCoordinats = await Api.userCityList(
			`geo_names/${newId}/?moon_app=true`
		);
		localStorage.setItem(
			"moonCalcCityInfo",
			JSON.stringify(moonCoordinats.data)
		);
		moonCalc(moonCoordinats.data);
		const hourCurrent = moment(moonCoordinats.time)
			.tz(moonCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(moonCoordinats.time)
			.tz(moonCoordinats.data.timezone)
			.get("minute");
		for (let key in moonCoordinats.data.moon_data.position_during_day) {
			const hourDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				moonRiseCoords(+key);
				moonRisePosition(
					moonCoordinats.data.moon_data.position_during_day[+key]
				);
			}
		}

		moonRiseDate(
			moment(moonCoordinats.data.time)
				.tz(moonCoordinats.data.timezone)
				.format()
		);

		// const moonUrl =  moonCoordinats.data.url ? `/moon/${moonCoordinats.data.url.split('/').slice(1).join('/')}` : `/moon/null/`;
		// this.props.history.push(moonUrl);
	}

	metaCityName(nameType) {
		const { moonCalcReducer } = this.props;
		if (moonCalcReducer.name) {
			const name = moonCalcReducer.name;
			const country =
				moonCalcReducer.country_name === "United States"
					? moonCalcReducer.country_name_abbr
					: moonCalcReducer.country_name;
			switch (nameType) {
				case "title":
					return ` ${name}, ${country}`;
				case "description":
					return ` ${name}, ${country} - `;
				case "keywords":
					return `${name}, `;
				default:
					return "";
			}
		} else return "";
	}

	render() {
		const { moonCalcReducer } = this.props;
		if (!moonCalcReducer.id) {
			return (
				<Fragment>
					<Meta />
					<div className="moonCulcCityInfoCenter">
						<Loader />
					</div>
				</Fragment>
			);
		}
		return (
			<div className="moon-calc-page">
				<Meta />
				{/* <SunSocialIconsBlock /> */}
				<div className="serarch-block">
					<b>
						Find Current Time, Moonrise, Moonset, Eclipses and more
						for
					</b>
					<Search />
				</div>
				<div className="sum-moon-pade-date">
					<div className="page-logo-block">
						<img src={moonLogo} alt="sun logo" />
					</div>
					<div className="right-block">
						<CityMoonRiseSet />
						<CityCalendarBlock />
					</div>
				</div>
				<MoonCityCalculatedBlock />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentSearchReducer: state.currentSearchReducer,
		moonCalcReducer: state.moonCalcReducer,
		moonRiseCoordsReducer: state.moonRiseCoordsReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		currentSearch: bindActionCreators(currentSearch, dispatch),
		moonCalc: bindActionCreators(moonCalc, dispatch),
		moonRisePosition: bindActionCreators(moonRisePosition, dispatch),
		moonRiseCoords: bindActionCreators(moonRiseCoords, dispatch),
		moonRiseDate: bindActionCreators(moonRiseDate, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MoonCulc);
