import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

class Balam extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise
    );
  }

  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    return `${name}, ${state} ${country}`;
  }

  renderTarabalam(arr, time_zone) {
    if (arr[0]) {
      if (arr[0].pattern) {
        return arr.map((obj) => {
          let fromTZ = moment(obj.pattern.from).tz(time_zone);
          let toTZ = moment(obj.pattern.to).tz(time_zone);
          let endTZ = moment(obj.pattern.end).tz(time_zone);
          let from = obj.pattern.from ? fromTZ.format("MMM DD, hh:mm A") : "";
          let to = obj.pattern.to ? toTZ.format("MMM DD, hh:mm A") : "";
          let end = obj.pattern.end ? endTZ.format("MMM DD, hh:mm A") : "";
          let text = obj.pattern.text
            .replace("%from", from)
            .replace("%to", to)
            .replace("%end", end);

          let list = obj.list.map((item) => (
            <div key={item} className="col">
              <h5> {item} </h5>
            </div>
          ));
          return (
            <div key={to} className="balam_block">
              <h5>{text} :</h5>
              <div style={{ marginTop: "10px" }}></div>
              <div className="balam_list"> {list}</div>
              <div style={{ marginTop: "10px" }}></div>
            </div>
          );
        });
      }
    }
  }

  renderTitle(arr, symbol, time_zone) {
    const { end, to, from } = this.props.panchang.chandrabalam.pattern;

    if (this.props.panchang.chandrabalam.pattern.list2) symbol = ":";
    else symbol = "";

    if (arr) {
      if (arr[0] && arr.length > 0) {
        return arr.map((item, index) => {
          if (item.indexOf("Next") !== -1) return false;
          let fromTZ = from ? moment(from).tz(time_zone) : "";
          let toTZ = to ? moment(to).tz(time_zone) : "";
          let endTZ = end ? moment(end).tz(time_zone) : "";

          let fromText = fromTZ ? fromTZ.format("MMM DD, hh:mm A") : "";
          let toText = toTZ ? toTZ.format("MMM DD, hh:mm A") : "";
          let endText = endTZ ? endTZ.format("MMM DD, hh:mm A") : "";
          let text = item
            .replace("%from", fromText)
            .replace("%to", toText)
            .replace("%end", endText);
          return (
            <div key={text.split(" ")[0]} className="balam_block w-100">
              <h5>
                {text} {arr.length === index + 1 && <span>{symbol}</span>}
              </h5>
            </div>
          );
        });
      }
    }
  }

  renderList(arr) {
    if (arr) {
      let list = arr.map((item) => (
        <div key={item} className="col">
          <h5> {item} </h5>
        </div>
      ));
      return (
        <div className="balam_block">
          <div style={{ marginTop: "10px" }}></div>
          <div className="balam_list"> {list}</div>
          <div style={{ marginTop: "10px" }}></div>
        </div>
      );
    }
  }

  renderText3(text) {
    if (text) {
      return (
        <div className="balam_block w-100">
          <h5>{text}</h5>
        </div>
      );
    }
  }

  render() {
    const { chandrabalam, tarabalam, time_zone } = this.props.panchang;
    return (
      <div className="constellation">
        <div className="row_center pt-2">
          <h4>Chandrabalam for {this.cityName()}</h4>
          {this.renderTarabalam([chandrabalam], time_zone)}
          {this.renderTitle(chandrabalam.pattern.text2, ":", time_zone)}
          {this.renderList(chandrabalam.pattern.list2)}
          {this.renderText3(chandrabalam.pattern.text3)}
        </div>
        <div style={{ marginTop: "30px" }}></div>
        <div className="row_center pt-2">
          <h4>Tarabalam for {this.cityName()}</h4>
          {this.renderTarabalam(tarabalam, time_zone)}
        </div>
        <div style={{ marginTop: "30px" }}></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(Balam);
