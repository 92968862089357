import React from "react";

import advertising from "../../../../../../assets/sun_page/336-280.png";

class Ads extends React.Component {
	render() {
		return (
			<div className="eclipse-info">
				<img
					src={advertising}
					className="advertising"
					alt="advertising"
				/>
			</div>
		);
	}
}

export default Ads;
