import React, { Component } from 'react';
import { connect } from 'react-redux';

import './ComponentDesign.css';


class ComponentDesign extends Component {

    state = {
        nameDesign: '',
        arrCollection : [
        {
            row:[
                { class:'', p:"Housing", name:'housing', id:'Housing'},
                { class:'', p:"StarWars", name:'starwars', id:'StarWars'},
                { class:'', p:"Airplane", name:'airplane', id:'Airplane'},
                { class:'', p:"Halloween", name:'halloween', id:'Halloween'},
                { class:'', p:"Birthday", name:'birthday', id:'Birthday'},
                { class:'', p:"NewYear", name:'newyear', id:'NewYear'}
                ],
        },
        {
            row:[
                { class:'', p:"undefined", name:'undefined', id:'unknown5'},
                { class:'', p:"undefined", name:'undefined', id:'unknown6'},
                { class:'', p:"undefined", name:'undefined', id:'unknown7'},
                { class:'', p:"undefined", name:'undefined', id:'unknown8'},
                { class:'', p:"undefined", name:'undefined', id:'unknown9'},
                ],
        }
        ],
    };

    setNameDesign = e =>{
        let nameDesign = e.target.parentNode.getAttribute('name') ? e.target.parentNode.getAttribute('name') : '';
        let idDesign = e.target.parentNode.getAttribute('id') ? e.target.parentNode.getAttribute('id') : '';
        this.setState({
            nameDesign,
        });
        this.changeClass(idDesign);
        this.passNameToRedax(idDesign);
    };
    changeClass(idDesign){
        //let arr = _.clone(this.state.arrCollection);
        let arr = [...this.state.arrCollection];
        arr.map( item =>{
            return  item.row.map( elem => {
                    if(elem.id === idDesign) elem.class = 'block-item-select';
                    else elem.class = '';
                    return elem
                })
        });
    }
    passNameToRedax(nameDesign){
        this.props.setNameDesignRedux(nameDesign)
    }

    render() {
        //console.log('ComponentDesign state:', this.state.arrCollection)
        //console.log('ComponentDesign props:', this.props.countdownReducer)
        return (
            <div className="block-countdown-comp">
                <p className="title-countdown-comp">Countdown design:</p>
                <div className="block-collection">
                    {this.state.arrCollection.map( (item, index) => {
                          return(
                              <div key={index} className="row-block-collection">
                                  {item.row.map( (elem, index) => {
                                      return(
                                          <div key={index} id={`${elem.id}`} name={`${elem.name}`} className={`block-item ${elem.class}`} onClick={this.setNameDesign}>
                                              <div className={`image-item ${elem.name}`}>
                                                  {/* IMG */}
                                              </div>
                                              <div className="name-item">
                                                  <p>{elem.p}</p>
                                              </div>
                                          </div>
                                      )
                                  })}
                              </div>
                          )
                    })}
                </div>
                <br/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        countdownReducer: state.countdownReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
         setNameDesignRedux: design_name => {
            //console.log('design_name', design_name)
            const payload = {
                design_name,
          };
          dispatch({ type: 'PASS_NAME_DESIGN', payload });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentDesign);

