import React, { Component } from "react";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Checkbox from "material-ui/Checkbox";

class WorldRadioBtn extends Component {
	state = {};

	componentDidMount() {
		const { data, defNumbBoxCheck } = this.props;
		this.setState({ [data[defNumbBoxCheck].val]: true });
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps) {
		const { data, defNumbBoxCheck } = nextProps;
		for (let key in this.state) {
			this.setState({ [key]: false });
		}
		this.setState({ [data[defNumbBoxCheck].val]: true });
	}

	handleCheck = (checked, type) => {
		const { select } = this.props;
		for (let key in this.state) {
			this.setState({ [key]: false });
		}
		this.setState({ [type]: true });
		select(type);
	};

	renderList() {
		const { data } = this.props;
		let arr = [...data];
		const listRender = arr.map((item, index) => {
			return (
				<MuiThemeProvider key={item.val + index}>
					<Checkbox
						label={item.text}
						checked={this.state[item.val]}
						onCheck={(event, checked) =>
							this.handleCheck(checked, item.val)
						}
						className="world-checkbox"
					/>
				</MuiThemeProvider>
			);
		});
		return listRender;
	}

	render() {
		const { direction } = this.props;

		return (
			<div className={`row-radio-btn ${direction}`}>
				{this.renderList()}
			</div>
		);
	}
}

export default WorldRadioBtn;
