import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";

import Loader from "../../Main/Loader/Loader";
import Header from "./components/Header";
import Table from "./components/Table";

import currentCityInfo from "../../../actions/action_currentCity/action_currentCityInfo";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import Api from "../../../services/api.js";
import "./WorldExchanges.css";

class WorldExchanges extends Component {
  state = {};

  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;

    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentSearchReducer.name !==
      nextProps.currentSearchReducer.name
    );
  }

  updateMetaTags = (exchangeType, exchangeTime) => {
    const { currentCityInfoReducer, searchStockCityInfoReducer } = this.props;
    const activeCity = searchStockCityInfoReducer
      ? searchStockCityInfoReducer
      : currentCityInfoReducer;

    const setMetaTitle = (exchangeType, exchangeTime) => {
      let title = "";
      if (exchangeType === "local") {
        if (exchangeTime == 0) {
          title = `Trading Hours of World Stock Exchanges in their Local Time`;
        } else {
          title = `Trading Hours of World Forex Exchanges in their Local Time`;
        }
      } else {
        if (exchangeTime == 0) {
          title = `Trading Hours of World Stock Exchanges as per ${
            activeCity.name
          } Time (${moment.tz(activeCity.timezone).zoneAbbr()})`;
        } else {
          title = `Trading Hours of World Forex Exchanges as per ${
            activeCity.name
          } Time (${moment.tz(activeCity.timezone).zoneAbbr()})`;
        }
      }
      return title;
    };

    // const setMetaDescription = (exchangeType, exchangeTime) => {
    //   let description = "";
    //   if (exchangeType === "local") {
    //     if (exchangeTime == 0) {
    //       description = `Get a list of trading hours (opening and closing time) of world stock exchanges in their local time. See which stock exchanges are open or closed in real time.`;
    //     } else {
    //       description = `Get a list of trading hours (opening and closing time) of world forex exchanges in their local time. See which forex exchanges are open or closed in real time.`;
    //     }
    //   } else {
    //     if (exchangeTime == 0) {
    //       description = `Get a list of trading hours (opening and closing time) of world stock exchanges as per ${activeCity.name} time. See which stock exchanges are open or closed as per ${activeCity.name} time in real time.`;
    //     } else {
    //       description = `Get a list of trading hours (opening and closing time) of world forex exchanges as per ${activeCity.name} time. See which forex exchanges are open or closed as per ${activeCity.name} time in real time.`;
    //     }
    //   }
    //   return description;
    // };

    // const setMetaKeywords = (exchangeType, exchangeTime) => {
    //   let keywords = "";
    //   if (exchangeType === "local") {
    //     if (exchangeTime == 0) {
    //       keywords = `world stock exchanges, trading hours, list of world stock exchanges, open and close time of world stock exchanges, operating hours of world stock exchanges`;
    //     } else {
    //       keywords = `world forex exchanges, trading hours, list of world forex exchanges, open and close time of world forex exchanges, operating hours of world forex exchanges`;
    //     }
    //   } else {
    //     if (exchangeTime == 0) {
    //       keywords = `world stock exchanges, trading hours, list of world stock exchanges, trading hours of world stock exchanges as per ${activeCity.name} time, open and close time of world stock exchanges as per ${activeCity.name} time, operating hours of world stock exchanges as per ${activeCity.name} time`;
    //     } else {
    //       keywords = `world forex exchanges, trading hours, list of world forex exchanges, trading hours of world forex exchanges as per ${activeCity.name} time, open and close time of world forex exchanges as per ${activeCity.name} time, operating hours of world forex exchanges as per ${activeCity.name} time`;
    //     }
    //   }
    //   return keywords;
    // };

    document.title = setMetaTitle(exchangeType, exchangeTime);
    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute("content", setMetaDescription(exchangeType, exchangeTime));
    // document
    //   .querySelector('meta[name="keywords"]')
    //   .setAttribute("content", setMetaKeywords(exchangeType, exchangeTime));
  };

  render() {
    const { currentSearchReducer, stockExchange } = this.props;

    if (!currentSearchReducer.name && !stockExchange.stock_exchanged_sort) {
      return (
        <div className="planet-info">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="world_exchanges_page">
          <Header updateMetaTags={this.updateMetaTags} />
          <Table />
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    stockExchangeSing: state.stockExchangeSing,
    currentCityInfoReducer: state.currentCityInfoReducer,
    searchStockCityInfoReducer: state.searchStockCityInfoReducer,
    stockExchange: state.stockExchange,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentCityInfo: bindActionCreators(currentCityInfo, dispatch),
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldExchanges);
