import React from "react";
import MetaTags from "react-meta-tags";

import CalculatorGPS from "./Calculator/CalculatorGPS";
import DescriptionText from "./DescriptionText";

// import Api from "../../../../services/api.js";
import "./GPSConverter.css";

function GPSConverter() {
	return (
		<div className="FlightTimeCalculator">
			<MetaTags>
				<title>GPS Time Converter</title>
				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content="Easily convert GPS Time to human readable date and time. Alternatively, convert time and date to GPS Time."*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	name="keywords"*/}
				{/*	content="GPS Time, GPS time converter, time converter, GPS time to human readable date and time, January 6 1980, second of week, gps week"*/}
				{/*/>*/}

				{/*<meta property="og:title" content="GPS Time Converter" />*/}
				{/*<meta*/}
				{/*	property="og:description"*/}
				{/*	content="Easily convert GPS Time to human readable date and time. Alternatively, convert time and date to GPS Time."*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	property="og:image"*/}
				{/*	content={`${*/}
				{/*		Api.url*/}
				{/*	}opg/image/page/gps/geotimedate-${Math.random()*/}
				{/*		.toString(36)*/}
				{/*		.substr(2)}.jpg`}*/}
				{/*/>*/}
			</MetaTags>

			<CalculatorGPS />
			<DescriptionText />
		</div>
	);
}

export default GPSConverter;
