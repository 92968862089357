import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";

import "./NextSolarLunarEclipses.css";
import LinksBlock from "./LinksBlock/LinksBlock";
import advertising from "../../../../../assets/taboola/long-ad.png";

class NextSolarLunarEclipses extends Component {
  latitude(type) {
    const { eclipsesDataReducer } = this.props;
    const latitude =
      type === "solar"
        ? eclipsesDataReducer.solar_eclipse_data.lat
        : eclipsesDataReducer.lunar_eclipse_data.lat;
    return (latitude + "").slice(0, 1) === "-"
      ? `${latitude}S`
      : `${latitude}N`;
  }
  longitude(type) {
    const { eclipsesDataReducer } = this.props;
    const longitude =
      type === "solar"
        ? eclipsesDataReducer.solar_eclipse_data.lon
        : eclipsesDataReducer.lunar_eclipse_data.lon;
    return (longitude + "").slice(0, 1) === "-"
      ? `${longitude}W`
      : `${longitude}E`;
  }
  cityList(elem, index, arr) {
    return index + 1 === arr.length
      ? `${elem.countryName}`
      : `${elem.countryName}, `;
  }
  pictureSolar() {
    const { eclipsesDataReducer } = this.props;
    const date = moment(eclipsesDataReducer.solar_eclipse_data.date_start)
      .tz("Greenwich")
      .format("YYYYMMMDD");
    const format = eclipsesDataReducer.solar_eclipse_data.eclipse_type_main.title.slice(
      0,
      1
    );
    return `SE${date}${format}`;
  }
  altSolar() {
    const { eclipsesDataReducer } = this.props;
    const date = moment(eclipsesDataReducer.solar_eclipse_data.date_start)
      .tz("Greenwich")
      .format("YYYY MMM DD");
    // const format = eclipsesDataReducer.solar_eclipse_data.eclipse_type_main.title.slice(0, 1);
    return `geotimedate.org - Solar Eclipse - ${date}`;
  }
  pictureLunar() {
    const { eclipsesDataReducer } = this.props;
    const date = moment(eclipsesDataReducer.lunar_eclipse_data.dt_max)
      .tz("Greenwich")
      .format("YYYYMMMDD");
    const format = eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title.slice(
      0,
      1
    );
    return `LE${date}${format}`;
  }
  altLunar(map = false) {
    const { eclipsesDataReducer } = this.props;
    const date = moment(eclipsesDataReducer.lunar_eclipse_data.dt_max)
      .tz("Greenwich")
      .format("YYYY MMM DD");
    // const format = eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title.slice(0, 1);
    return `geotimedate.org - Lunar Eclipse ${map ? "map" : "path"} - ${date}`;
  }
  hoursTotalDuration(type) {
    const { eclipsesDataReducer } = this.props;
    const dateStart =
      type === "total"
        ? eclipsesDataReducer.lunar_eclipse_data.dt_tot_begin
        : eclipsesDataReducer.lunar_eclipse_data.dt_pen_begin;
    const dateEnd =
      type === "total"
        ? eclipsesDataReducer.lunar_eclipse_data.dt_tot_end
        : eclipsesDataReducer.lunar_eclipse_data.dt_pen_end;
    const moonRise = eclipsesDataReducer.lunar_eclipse_data.moon_rise;
    const moonSet = eclipsesDataReducer.lunar_eclipse_data.moon_set;
    const tz = "Greenwich";
    const timeStart =
      dateStart !== null ? moment(dateStart).tz(tz) : moment(moonRise).tz(tz);
    const timeEnd = dateEnd ? moment(dateEnd).tz(tz) : moment(moonSet).tz(tz);
    const timeDiff = timeEnd.diff(timeStart);
    if (!timeDiff) return "not visible";
    const timeFormat = (time) => (time.length < 2 ? 0 + time : time);
    const hours = timeEnd.diff(timeStart, "hours");
    const minutes = Math.floor((timeDiff - hours * 3600000) / 60000);
    const seconds = Math.floor(
      (timeDiff - hours * 3600000 - minutes * 60000) / 1000
    );
    return `${timeFormat(hours + "")}:${timeFormat(minutes + "")}:${timeFormat(
      seconds + ""
    )}`;
  }
  hoursPartialDuration() {
    const { eclipsesDataReducer } = this.props;
    const datePartialStart =
      eclipsesDataReducer.lunar_eclipse_data.dt_par_begin;
    const datePartialEnd = eclipsesDataReducer.lunar_eclipse_data.dt_par_end;
    const dateTotalStart = eclipsesDataReducer.lunar_eclipse_data.dt_tot_begin;
    const dateTotalEnd = eclipsesDataReducer.lunar_eclipse_data.dt_tot_end;
    const tz = "Greenwich";
    if (datePartialStart && datePartialEnd) {
      const partialStart = moment(datePartialStart).tz(tz);
      const partialEnd = moment(datePartialEnd).tz(tz);
      let timePartial;
      if (dateTotalStart !== null && dateTotalEnd !== null) {
        const totalStart = moment(dateTotalStart).tz(tz);
        const totalEnd = moment(dateTotalEnd).tz(tz);
        const partOne =
          totalStart.diff(partialStart) < 0
            ? totalStart.diff(partialStart) * -1
            : totalStart.diff(partialStart);
        const partTwo =
          partialEnd.diff(totalEnd) < 0
            ? partialEnd.diff(totalEnd) * -1
            : partialEnd.diff(totalEnd);
        timePartial = partOne + partTwo;
      } else timePartial = partialEnd.diff(partialStart);
      const timeFormat = (time) => (time.length < 2 ? 0 + time : time);
      const hours = Math.floor(timePartial / 3600000);
      const minutes = Math.floor((timePartial - hours * 3600000) / 60000);
      const seconds = Math.floor(
        (timePartial - hours * 3600000 - minutes * 60000) / 1000
      );
      return `${timeFormat(hours + "")}:${timeFormat(
        minutes + ""
      )}:${timeFormat(seconds + "")}`;
    } else return "not visible";
  }
  cityName() {
    const { eclipsesDataReducer } = this.props;
    if (
      window.location.pathname !== "/solar-lunar-eclipses/world" &&
      eclipsesDataReducer.name
    ) {
      const name = eclipsesDataReducer.name;
      const country =
        eclipsesDataReducer.country_name === "United States"
          ? eclipsesDataReducer.country_name_abbr
          : eclipsesDataReducer.country_name;
      const state =
        eclipsesDataReducer.country_name === "United States"
          ? eclipsesDataReducer.state
            ? `${eclipsesDataReducer.state},`
            : ""
          : ``;
      return `${name}, ${state} ${country}`;
    } else return "";
  }
  nextEclipse(type) {
    const place =
      window.location.pathname === "/solar-lunar-eclipses/world"
        ? "Worldwide"
        : "";
    if (type === "solar") {
      const { eclipsesDataReducer } = this.props;
      const date = moment(eclipsesDataReducer.solar_eclipse_data.date_start)
        .tz("Greenwich")
        .format("dddd, ll");
      const type = eclipsesDataReducer.solar_eclipse_data.eclipse_type.title;
      return `Next Solar Eclipse ${place} ${this.cityName()} - ${date} (${type})`;
    } else {
      const { eclipsesDataReducer } = this.props;
      const date = moment(eclipsesDataReducer.lunar_eclipse_data.calendar_date)
        .tz("Greenwich")
        .format("dddd, ll");
      const type = eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title;
      return `Next Lunar Eclipse ${place} ${this.cityName()} - ${date} (${type})`;
    }
  }
  selectedCity(type, cityParam) {
    if (window.location.pathname === "/solar-lunar-eclipses/world") {
      const { currentSearchReducer, eclipsesDataReducer } = this.props;
      const city = localStorage.getItem("mainSearchCity")
        ? JSON.parse(localStorage.getItem("mainSearchCity"))
        : currentSearchReducer;
      const cityUrl = city.url.replace("place/", "");
      const solarEclipseStart = moment(
        eclipsesDataReducer.solar_eclipse_data.date_start
      )
        .tz("Greenwich")
        .format("YYYY-MM-DD");
      const lunarEclipseStart = moment(
        eclipsesDataReducer.lunar_eclipse_data.calendar_date
      )
        .tz("Greenwich")
        .format("YYYY-MM-DD");
      const date = type === "Solar" ? solarEclipseStart : lunarEclipseStart;
      if (cityParam === "city") {
        return (
          <Link
            to={`/${type.toLocaleLowerCase()}-eclipse/${cityUrl}/${date}#city_table`}
          >
            See catalog of all {type} Eclipses (1900-2099) for{" "}
            {this.cityNameHome(city)}
          </Link>
        );
      }
      return (
        <div className="eclipse-info-block">
          <Link to={`/${type.toLocaleLowerCase()}-eclipse/${cityUrl}/${date}`}>
            Will this {type} Eclipse be visible in {city.name}?
          </Link>
        </div>
      );
    } else return "";
  }
  cityNameHome(city) {
    const name = city.name;
    const country =
      city.country_name === "United States"
        ? city.country_name_abbr
        : city.country_name;
    const state =
      city.country_name === "United States" ? `${city.state}, ` : "";
    return `${name}, ${state} ${country}`;
  }
  detailsAboutEclipse(type, date) {
    if (window.location.pathname === "/solar-lunar-eclipses/world") {
      return (
        <Link to={`/${type}-eclipse-country/${date}`}>
          More details about this eclipse
        </Link>
      );
    } else {
      const { eclipsesDataReducer } = this.props;
      const cityUrl = eclipsesDataReducer.url.replace("place/", "");
      return (
        <Link to={`/${type}-eclipse/${cityUrl}/${date}`}>
          More details about this eclipse
        </Link>
      );
    }
  }
  render() {
    const { eclipsesDataReducer } = this.props;
    const solarEclipseDate = moment(
      eclipsesDataReducer.solar_eclipse_data.date_start
    )
      .tz("Greenwich")
      .format("YYYY-MM-DD");
    const lunarEclipseDate = moment(
      eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_begin
    )
      .tz("Greenwich")
      .format("YYYY-MM-DD");
    // const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
    return (
      <section className="solarLunarEclipses">
        <div className="eclipsesInfo">
          <div className="solarLunarEclipsesInfo">
            <div className="eclipsesWrapedBlock">
              <h5 className="eclipse-info-header">
                <img
                  src={`/static/eclipses/solar/${this.pictureSolar()}.png`}
                  alt="geotimedate"
                />
                {this.nextEclipse("solar")}
              </h5>
              <div className="eclipse-info">
                <div className="eclipse-blocks">
                  {this.selectedCity("Solar")}
                  <div className="eclipse-info-block">
                    <b>Max at:</b>
                    <b>
                      {moment(eclipsesDataReducer.solar_eclipse_data.date_max)
                        .tz("Greenwich")
                        .format(`dddd, MMM DD, YYYY LT`)}{" "}
                      UTC
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Eclipse Magnitude:</b>
                    <b>
                      {eclipsesDataReducer.solar_eclipse_data.magnitude.toFixed(
                        3
                      )}
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Saros cycle:</b>
                    <b>{eclipsesDataReducer.solar_eclipse_data.saros_cycle}</b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Central duration:</b>
                    <b>
                      {eclipsesDataReducer.solar_eclipse_data.duration_totality}
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Max duration at:</b>
                    <b>
                      Latitude: {this.latitude("solar")}&deg;, Longitude:{" "}
                      {this.longitude("solar")}&deg;
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Visible in:</b>
                    <b>
                      {eclipsesDataReducer.solar_eclipse_data.visible_in.map(
                        (elem, index, arr) => this.cityList(elem, index, arr)
                      )}{" "}
                      <Link
                        to={`/solar-eclipse-country/${solarEclipseDate}#countries`}
                      >
                        ...more
                      </Link>
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>{this.detailsAboutEclipse("solar", solarEclipseDate)}</b>
                  </div>
                </div>
                <div className="eclipses-img">
                  <img
                    src={`/static/eclipses/solar/${this.pictureSolar()}.png`}
                    alt={`${this.altSolar()}`}
                  />
                  <span className="picture-eclipse-text">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Eclipse Predictions by Fred Espenak, NASA's GSFC.
                  </span>
                </div>
              </div>
            </div>
            <Link to={`/catalog-of-all-solar-eclipses/world`}>
              See catalog of all Solar Eclipses Worldwide (1900-2099)
            </Link>
            {this.selectedCity("Solar", "city")}
          </div>
          <div className="solarLunarEclipsesInfo">
            <div className="eclipsesWrapedBlock">
              <h5 className="eclipse-info-header">
                <img
                  src={`/static/eclipses/solar/${this.pictureSolar()}.png`}
                  alt="geotimedate"
                />
                {this.nextEclipse("lunar")}
              </h5>
              <div className="eclipse-info">
                <div className="eclipse-blocks">
                  {this.selectedCity("Lunar")}
                  <div className="eclipse-info-block">
                    <b>Max at:</b>
                    <b>
                      {moment(eclipsesDataReducer.lunar_eclipse_data.dt_max_loc)
                        .tz("Greenwich")
                        .format(`dddd, MMM DD, YYYY LT`)}{" "}
                      UTC
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Umbral Magnitude:</b>
                    <b>
                      {eclipsesDataReducer.lunar_eclipse_data.magnitude.toFixed(
                        3
                      )}
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Penumbral Duration:</b>
                    <b>
                      {
                        eclipsesDataReducer.lunar_eclipse_data
                          .penumbral_duration
                      }
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Hours Partial Duration:</b>
                    <b>
                      {eclipsesDataReducer.lunar_eclipse_data.partial_duration}
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Hours Total Duration:</b>
                    <b>
                      {eclipsesDataReducer.lunar_eclipse_data.total_duration}
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Saros cycle:</b>
                    <b>{eclipsesDataReducer.lunar_eclipse_data.saros_series}</b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Max duration at:</b>
                    <b>
                      Latitude: {this.latitude("lunar")}&deg;, Longitude:{" "}
                      {this.longitude("lunar")}&deg;
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>Visible in:</b>
                    <b>
                      {eclipsesDataReducer.lunar_eclipse_data.visible_in.map(
                        (elem, index, arr) => this.cityList(elem, index, arr)
                      )}{" "}
                      <Link
                        to={`/lunar-eclipse-country/${lunarEclipseDate}#countries`}
                      >
                        ...more
                      </Link>
                    </b>
                  </div>
                  <div className="eclipse-info-block">
                    <b>{this.detailsAboutEclipse("lunar", lunarEclipseDate)}</b>
                  </div>
                </div>
                <div className="eclipses-img">
                  <div className="eclipses-img-top">
                    <img
                      src={`/${eclipsesDataReducer.lunar_eclipse_data.picture_url}`}
                      alt={this.altLunar()}
                    />
                  </div>
                  <div className="eclipses-img-bottom">
                    <img
                      src={`/${eclipsesDataReducer.lunar_eclipse_data.map_url}`}
                      alt={`${this.altLunar(true)}`}
                    />
                  </div>
                  <span className="picture-eclipse-text">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Eclipse Predictions by Fred Espenak, NASA's GSFC
                  </span>
                </div>
              </div>
            </div>
            <Link to={`/catalog-of-all-lunar-eclipses/world`}>
              See catalog of all Lunar Eclipses Worldwide (1900-2099)
            </Link>
            {this.selectedCity("Lunar", "city")}
          </div>
          <div className="advertising-block-main">
            <img src={advertising} className="advertising" alt="advertising" />
          </div>
        </div>
        <LinksBlock />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eclipsesDataReducer: state.eclipsesDataReducer,
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(NextSolarLunarEclipses);
