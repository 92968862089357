import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/index.js';

import './CitySunRiseSet.css';

class CitySunRiseSet extends Component {
    emptyName(name){
        return name !== null ? `${name}, ` : '';
    }
    emptyState(state, abr){
        if(isNaN(+abr) && abr !== null) return `${abr}, `;
        else if(state !== null) return `${state}, `;
        else return '';
    }
    emptyAbr(abr, name){
        if(name === 'United States' && abr !== null) return abr;
        else if(name !== null) return name;
        else return '';
    }
    render () {
        const { sunCalcReducer } = this.props;
        return (
            <section className="citySunRiseSet">
                <div className="country_flag">
                    <img className="cityMainImg" src={`/static/flags/flags-iso/flat/64/${sunCalcReducer.country_code}.png`} alt="citydateandtime" />
                </div>
                <div className="city_name_date">
                    <h3>
                        Sunrise, Sunset for {this.emptyName(sunCalcReducer.name)}
                        {/* {this.emptyName(sunCalcReducer.county)} */}
                        {this.emptyState(sunCalcReducer.state, sunCalcReducer.state_code)}
                        {this.emptyAbr(sunCalcReducer.country_name_abbr, sunCalcReducer.country_name)} for&nbsp;
                        {moment(sunCalcReducer.time).tz(sunCalcReducer.timezone).format('dddd, MMM D, YYYY')}
                    </h3>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        sunCalcReducer: state.sunCalcReducer
    };
};

export default connect(mapStateToProps)(CitySunRiseSet);