import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";
import ReactTooltip from "react-tooltip";
import { DatePicker, IconButton, MuiThemeProvider } from "material-ui";
import "./TopBlockCities.css";
import Loader from "../../../../components/Main/Loader/Loader";
import ChipsCarousel from "./ChipsCarousel/ChipsCarousel";
import TimePickerSearch from "./TimePickerSearch/TimePickerSearch";
import HolidaysTimePlaner from "./HolidaysTimePlaner/HolidaysTimePlaner";
import CarouselTopBlock from "./CarouselTopBlock/CarouselTopBlock";
import TimePlanerSwipeBlock from "./TimePlanerSwipeBlock/TimePlanerSwipeBlock";
import TimePickerLeftInfoBlock from "./TimePickerLeftInfoBlock/TimePickerLeftInfoBlock";
import Ticker from "../../../shared/Ticker";

import selectedCities, {
  deleteCity,
  setAsHome,
  setCurrentLocation,
  setCitiesListForTimePlaner,
  setCurrentGroup,
} from "../../../../actions/action_selectedCities";
import currentCityInfo from "../../../../actions/action_currentCity/action_currentCityInfo";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import defaultCityInfo from "../../../../actions/action_currentCity/action_defaultCityInfo";
import timePlanerSwipeBlock from "../../../../actions/action_timePlanerSwipeBlock";

// import info from "../../../../assets/info.png";
import settings from "../../../../assets/settings.png";

import Api from "../../../../services/api.js";
import { DstIcon } from "../../../shared/Common";

const RenderDate = (props) => {
  const { time, tz } = props;
  const date = moment(time).tz(tz);

  if (date.format("HH") !== "00") {
    return <React.Fragment />;
  }
  return (
    <div
      style={{
        position: "absolute",
        top: "-14px",
        color: "black",
        fontSize: "11px",
      }}
    >
      <strong>{`${date.format("DD")} ${date.format("MMM")}`}</strong>
    </div>
  );
};

const TimeDifferent = (props) => {
  const { city, time } = props;
  const timeShow = time.replace("-", "");
  const ahead = timeShow === time;

  const cityName = city.name_city_replace ? city.name_city_replace : city.name;

  let message = `${cityName} is ${timeShow} hrs ${
    ahead ? "ahead" : "behind"
  } of the home city.`;

  if (timeShow === "0:00") {
    message = `The time in ${cityName} is same as that of the home city.`;
  }

  return (
    <React.Fragment>
      <em
        className="time-difference"
        data-tip=""
        data-for={`tool-tip-id-${city.id}`}
      >
        {time}
      </em>
      <ReactTooltip id={`tool-tip-id-${city.id}`}>{message}</ReactTooltip>
    </React.Fragment>
  );
};

const getCityName = (city) => {
  return city.name_city_replace
    ? city.name_city_replace
    : city.name !== null
    ? `${city.name} `
    : "";
};

class TopBlockCities extends Component {
  state = {
    date: new Date(),
    cityTime: moment(),
    border: "1px solid #b0b1ac",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    calendarDate: "",
    timeShiftList: [],
    sliderPosition: {},
    sliderInitPosition: {},
    timePlanerOverflow: { overflowX: "hidden" },
    hoveredId: false,
  };
  timerInt = setInterval(() => {
    return this.setState({ cityTime: moment() });
  }, 60000);

  componentWillUnmount() {
    clearInterval(this.timerInt);
  }

  componentDidMount() {
    const { defaultCityInfoReducer, currentCityInfoReducer } = this.props;
    let cityTimeNoFormat;
    if (localStorage.getItem("defaultCityInfo")) {
      cityTimeNoFormat = moment().tz(
        JSON.parse(localStorage.getItem("defaultCityInfo")).timezone
      );
    } else {
      cityTimeNoFormat = moment().tz(defaultCityInfoReducer.timezone);
    }

    const year = cityTimeNoFormat.get("year");
    const month = cityTimeNoFormat.get("month");
    const day = cityTimeNoFormat.get("date");
    const hour = cityTimeNoFormat.get("hour");
    const minute = cityTimeNoFormat.get("minute");
    const second = cityTimeNoFormat.get("second");
    const millisecond = cityTimeNoFormat.get("millisecond");

    const time = new Date(year, month, day, hour, minute, second, millisecond);
    this.setState({
      calendarDate: time,
      exchanges: [], //data_res
    });
    // const { setCitiesListForTimePlaner } = this.props;
    // const token = localStorage.getItem("token")
    // 	? localStorage.getItem("token")
    // 	: sessionStorage.getItem("token");
    // setCitiesListForTimePlaner(token, time);

    this.getCurrentLocation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.calendarDate &&
      this.state.calendarDate !== prevState.calendarDate
    ) {
      const { setCitiesListForTimePlaner, selectedCitiesReducer } = this.props;
      const token = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : sessionStorage.getItem("token");
      setCitiesListForTimePlaner(
        token,
        this.state.calendarDate,
        selectedCitiesReducer.noAuthUserCities
      );
    }
  }

  getCurrentLocation = async () => {
    await Api.getLocatoinJSON().then((data) => {
      const locationData = JSON.parse(data.data);
      if (locationData.latitude && locationData.longitude  && locationData.longitude !== 'nil') {
        this.currentCityGetInfo(locationData.latitude, locationData.longitude);
        this.props.currentCityInfo(locationData);
        this.props.defaultCityInfo(locationData);
      } else {
        /* New York by default */
        this.currentCityGetInfo(40.71424, -74.00594);
      }
    });
  };

  async currentCityGetInfo(lat, lng) {
    if (!(lat && lng)) {
      lat = 40.71424;
      lng = -74.00594;
    }

    const data = await Api.getCache(Api.currentCoordinars, lat, lng);
    const {
      currentCityInfo,
      defaultCityInfo,
      airportsList,
      holidaysBlock,
      currentSearch,
      setCurrentLocation,
    } = this.props;
    const newCity = data.data.locations_list;

    const citySelect =
      newCity.reverse()[0] === undefined ? null : newCity.reverse()[0];
    const currentCityData = await Api.getCache(
      Api.userCityList,
      `geo_names/${citySelect.id}/`
    );

    citySelect.ColorSchemes = data.data.ColorSchemes;
    citySelect.ColorSchemesWeekend = data.data.ColorSchemesWeekend;
    citySelect.working_hours_start = data.data.working_hours_start;
    citySelect.working_hours_end = data.data.working_hours_end;
    citySelect.url = currentCityData.data.url;

    currentCityInfo(citySelect);
    defaultCityInfo(citySelect);

    if (!sessionStorage.getItem("sessionParam")) {
      localStorage.setItem("mainSearchCity", JSON.stringify(citySelect));
    }

    ////////////////////////////////////////////////////////
    setCurrentLocation(citySelect);
    ////////////////////////////////////////////////////////
  }

  emptyName(name) {
    return name !== null ? `${name} ` : "";
  }

  emptyAbr(elem) {
    if (
      elem.country_name === "United States" &&
      elem.country_name_abbr !== null &&
      elem.state_code !== null
    ) {
      return `${elem.state_code}, ${elem.country_name_abbr}`;
    } else if (elem.country_name !== null) return elem.country_name;
    else return "";
  }

  async dellCity(elem, elemIndex) {
    const { deleteCity, selectedCitiesReducer } = this.props;
    const { token } = selectedCitiesReducer;
    deleteCity(elem, elemIndex, token);
  }

  /* TODO: temporary disable */
  // async selectedCityInfo(id, e) {
  // 	const { currentSearch } = this.props;
  // 	if (
  // 		e.target.className !== "add-and-dell-city" &&
  // 		e.target.parentNode.className !== "add-and-dell-city"
  // 	) {
  // 		const cityInfo = await Api.userCityList(`geo_names/${id}/`);
  // 		currentSearch(cityInfo.data);
  // 	}
  // }

  async cityDell(elem, token) {
    await Api.userCityDell(
      `main-cities-reg/${elem.group_number}/delete/?city_id=${elem.city_id}`,
      token
    );
  }

  setAsDefault(element, index) {
    const { defaultCityInfo } = this.props;

    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");

    if (token) this.cityDell(element, token);

    delete element.city_id;
    defaultCityInfo(element);
    localStorage.setItem("defaultCityInfo", JSON.stringify(element));

    const cityTime = moment.tz(element.timezone).format("H");

    // eslint-disable-next-line react/no-string-refs
    const block = this.refs.timePickerSlider;
    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.timePickerRight;
    if (block && blockRight) {
      const newBlock = blockRight.offsetWidth;
      this.setState({
        sliderInitPosition: {
          width: `${newBlock / 24}px`,
          left: `${(newBlock / 24) * cityTime}px`,
          border: this.state.border,
          boxShadow: this.state.boxShadow,
        },
      });
    }

    if (token) {
      const { selectedCitiesReducer } = this.props;
      selectedCitiesReducer.cities.splice(index, 1);
      let citiesList = [element, ...selectedCitiesReducer.cities];
      this.saveCityList(citiesList);
    } else {
      const { setAsHome } = this.props;
      setAsHome(element, index);
    }
  }

  async saveCityList(cityList) {
    const { selectedCities, selectedCitiesReducer } = this.props;
    const { currentGroup, token } = selectedCitiesReducer;

    const newList = cityList.map((elem, index) => {
      if (elem.city_id) {
        return {
          act: "upd",
          city_id: `${elem.city_id}`,
          geoname_id: `${elem.id}`,
          group: `${currentGroup.id}`,
          sort_by: `${index}`,
        };
      } else {
        return {
          act: "save",
          geoname_id: `${elem.id}`,
          group: `${currentGroup.id}`,
          sort_by: `${index}`,
        };
      }
    });
    if (token) {
      await Api.changeInfo(`main-cities-reg-gr/`, token, [
        ...newList,
        {
          act: "get",
          group: `${currentGroup.id}`,
        },
      ]);
    }
    selectedCities(cityList);
  }

  cityId(id) {
    if (id) return id;
    else {
      localStorage.clear();
      window.location.reload();
    }
  }

  timeDifference(cityZone) {
    const { selectedCitiesReducer } = this.props;
    const { cities } = selectedCitiesReducer;

    const year = this.state.calendarDate.getFullYear();
    const month = this.state.calendarDate.getMonth();
    const day = this.state.calendarDate.getDate();
    const mainCityTime = moment()
      .set("year", year)
      .set("date", day)
      .set("month", month)
      .tz(cities[0].timezone)
      .format(`YYYY-MM-DDTHH:mm:ss+00:00`);
    const cityTime = moment()
      .set("year", year)
      .set("date", day)
      .set("month", month)
      .tz(cityZone)
      .format(`YYYY-MM-DDTHH:mm:ss+00:00`);
    const timeDiffHours = moment(mainCityTime).diff(moment(cityTime), "hours");
    let timeDiffMinutes =
      timeDiffHours * 60 -
      moment(mainCityTime).diff(moment(cityTime), "minutes");
    if (timeDiffMinutes === 0) timeDiffMinutes = "00";
    else if (timeDiffMinutes < 0) timeDiffMinutes *= -1;
    return `${timeDiffHours * -1}.${timeDiffMinutes}`;
  }

  timePickerSliderScale() {
    // eslint-disable-next-line react/no-string-refs
    const block = this.refs.timePickerSlider;
    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.timePickerRight;
    if (block && blockRight) {
      const newBlock = blockRight.offsetWidth;
      return { width: newBlock / 24 };
    }
  }

  sliderStraightedge(elem, index, defaultTimeZone = null) {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");
    const { defaultCityInfoReducer } = this.props;
    if (defaultTimeZone === null) {
      defaultTimeZone = defaultCityInfoReducer.timezone;
    }

    if (typeof elem === "string") {
      const time = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];

      const newMainCityArr = time.map((elemHours) => {
        const timezone = moment(this.state.calendarDate)
          .tz(defaultTimeZone)
          .format(`Z`);
        const timeNoFormat = moment(this.state.calendarDate).format(
          `YYYY-MM-DDT${elemHours}:mm:ss${timezone}`
        );
        const cityTime = moment(timeNoFormat).tz(defaultTimeZone);
        return { time: cityTime.format() };
      });
      return newMainCityArr;
    } else if (token) {
      const { selectedCitiesReducer } = this.props;
      const timeMainTz = moment(this.state.calendarDate)
        .tz(selectedCitiesReducer.cities[0].timezone)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      const timeMinutes = timeMainTz
        .tz(elem.timezone)
        .format("HH.mm")
        .split(".");
      const m = timeMinutes[1];

      const timeArr = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
      const newMainCityArr = timeArr.map((elemHours) => {
        const year = this.state.calendarDate.getFullYear();
        const month = this.state.calendarDate.getMonth() + 1;
        const momentMonth = (month + "").length === 1 ? `0${month}` : month;
        const day = this.state.calendarDate.getDate();
        const momentDay = (day + "").length === 1 ? `0${day}` : day;
        const tz = moment(this.state.calendarDate)
          .tz(selectedCitiesReducer.cities[0].timezone)
          .format(`Z`);
        const cityTime = `${year}-${momentMonth}-${momentDay}T${elemHours}:00:00${tz}`;
        return {
          time: moment(cityTime).tz(elem.timezone).format(),
          minutes: m,
        };
      });
      return newMainCityArr;
    } else {
      const timeMainTz = moment(this.state.calendarDate)
        .tz(defaultTimeZone)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      const timeMinutes = timeMainTz
        .tz(elem.timezone)
        .format("HH.mm")
        .split(".");
      const m = timeMinutes[1];

      const timeArr = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
      const newMainCityArr = timeArr.map((elemHours) => {
        const year = this.state.calendarDate.getFullYear();
        const month = this.state.calendarDate.getMonth() + 1;
        const momentMonth = (month + "").length === 1 ? `0${month}` : month;
        const day = this.state.calendarDate.getDate();
        const momentDay = (day + "").length === 1 ? `0${day}` : day;
        const tz = moment(this.state.calendarDate)
          .tz(defaultTimeZone)
          .format(`Z`);
        const cityTime = `${year}-${momentMonth}-${momentDay}T${elemHours}:00:00${tz}`;
        return {
          time: moment(cityTime).tz(elem.timezone).format(),
          minutes: m,
        };
      });
      return newMainCityArr;
    }
  }

  timePickerColor(time, elem, param, source) {
    //source - city or exchange

    let colorSchemes, colorSchemesWeekend;
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token");
    if (token) {
      const { authorizationReducer } = this.props;
      if (
        !authorizationReducer.ColorSchemes ||
        !authorizationReducer.ColorSchemesWeekend
      )
        return {};
      colorSchemes = authorizationReducer.ColorSchemes;
      colorSchemesWeekend = authorizationReducer.ColorSchemesWeekend;
    } else {
      if (!elem.ColorSchemes || !elem.ColorSchemesWeekend) return {};
      colorSchemes = elem.ColorSchemes;
      colorSchemesWeekend = elem.ColorSchemesWeekend;
    }

    const value = +moment(time.time).tz(elem.timezone).format("HH");
    let day = +moment(time.time).tz(elem.timezone).weekday();

    if (day === 0) day = 6;
    else day--; //0-MONDAY ... 6-SUNDAY
    if (source === "exchange") {
      let weekendSelect = colorSchemesWeekend;
      for (let i = 0; i < elem.schedule.length; i++) {
        if ((elem.schedule[i].weekday & (1 << day)) > 0) {
          weekendSelect = colorSchemes;
          let open_hours = elem.schedule[i].open_hours.split("-");
          let start = +open_hours[0].split(":")[0];
          let end = +open_hours[1].split(":")[0];
          if (open_hours[0].indexOf("PM") !== -1) {
            start += 12;
          }
          if (open_hours[1].indexOf("PM") !== -1) {
            end += 12;
          }
          return this.cityStyleSet(start, end, weekendSelect, value);
        }
      }
      if (value === 0)
        return {
          background: "#fcc238",
          border: "1px solid #fcc238",
          color: "#fff",
        };
      else return {};
    } else {
      const start = +elem.working_hours_start.split(":")[0];
      let end = +elem.working_hours_end.split(":")[0];
      if (elem.working_hours_end.split(":")[1].indexOf("PM") !== -1) {
        end += 12;
      }
      let weekendSelect = colorSchemes;
      if ((elem.weekend_bit & (1 << day)) > 0)
        weekendSelect = colorSchemesWeekend;

      return this.cityStyleSet(start, end, weekendSelect, value);
    }
  }

  cityStyleSet(start, end, weekendSelect, value) {
    const {
      rest_background,
      rest_color,
      worke_background,
      worke_color,
    } = weekendSelect;
    if (value === 0)
      return {
        background: "#fcc238",
        border: "1px solid #fcc238",
        color: "#fff",
      };
    else if (start <= value && value < end)
      return {
        background: worke_background,
        border: `1px solid ${worke_background}`,
        color: worke_color,
      };
    else if (value < start)
      return {
        background: rest_background,
        border: `1px solid ${rest_background}`,
        color: rest_color,
      };
    // eslint-disable-next-line no-constant-condition
    else if (value => end)
      return {
        background: rest_background,
        border: `1px solid ${rest_background}`,
        color: rest_color,
      };
    else
      return {
        background: "#008854",
        border: `1px solid #008854`,
        color: "#008854",
      };
  }

  cityTimeLeftBlock(elem, city) {
    if (!city.time_format) {
      localStorage.clear();
      window.location.reload();
    }
    const time = +moment(elem.time).tz(city.timezone).format("HH");
    const { selectedCitiesReducer } = this.props;
    const { format12, format24 } = selectedCitiesReducer.timeFormat;
    // if (time === 0) return moment(elem.time).tz(city.timezone).format('DD');
    // else
    if (format12 === true)
      return time > 12 ? time - 12 : time === 0 ? 12 : time;
    else if (format24 === true) return time;
    else {
      if (city.time_format === "AM_PM") return time > 12 ? time - 12 : time;
      else return time;
    }
  }

  // cityMonthLeftBlock(elem, city) {
  //     const time = +moment(elem.time).tz(city.timezone).format('HH');
  //     if (time === 0) return moment(elem.time).tz(city.timezone).format('MMM');
  // }

  cityTimeRightTopBlock(elem) {
    const time = +elem.minutes;
    return time ? `:${time}` : ":00";
  }

  cityTimeRightBottomBlock(elem, city) {
    const time = +moment(elem.time).tz(city.timezone).format("HH");
    const { selectedCitiesReducer } = this.props;
    const { format12, format24 } = selectedCitiesReducer.timeFormat;
    if (format12 === true) {
      return time >= 12 ? "pm" : "am";
    } else if (
      format12 === false &&
      format24 === false &&
      city.time_format === "AM_PM"
    ) {
      return time >= 12 ? "pm" : "am";
    }
  }

  minutesStyleFormat(time, elem) {
    const { selectedCitiesReducer } = this.props;
    const { format12, format24 } = selectedCitiesReducer.timeFormat;
    if (format12 === true) {
      return { fontSize: "10px", lineHeight: "12px" };
    } else if (format24 === true) {
      return { fontSize: "14px", lineHeight: "16px" };
    } else if (elem.time_format === "am-pm") {
      return { fontSize: "12px", lineHeight: "14px" };
    } else if (elem.time_format === "T24") {
      return { fontSize: "14px", lineHeight: "16px" };
    }
  }

  cityTimeCalendar(elem, attr) {
    const { defaultCityInfoReducer } = this.props;
    const hours = moment.tz(defaultCityInfoReducer.timezone).format("HH");
    const minutes = moment.tz(defaultCityInfoReducer.timezone).format("mm");
    const seconds = moment.tz(defaultCityInfoReducer.timezone).format("ss");
    const tz = moment.tz(defaultCityInfoReducer.timezone).format("Z");
    const noZoneTime = moment(this.state.calendarDate)
      .set("hour", hours)
      .set("minute", minutes)
      .set("second", seconds)
      .format(`YYYY-MM-DDTHH:mm:ss${tz}`);
    const time = moment(noZoneTime).tz(elem.timezone);
    switch (attr) {
      case "time":
        return time.format("LT");
      case "date":
        return time.format("ddd, MMM DD");
      case "zoneAbr":
        return time.format("z");
      case "zone":
        return time.format("Z");
      case "month":
        return time.format("w");
      default:
        return time.format();
    }
  }

  toRaiseTheCity(elem, index, arr) {
    if (0 < index < arr.length) {
      const newArr = arr.filter((elemArr, indexArr) => indexArr !== index);
      newArr.splice(index - 1, 0, elem);
      this.saveCityList(newArr);
    }
  }

  omitTheCity(elem, index, arr) {
    if (0 < index < arr.length) {
      const newArr = arr.filter((elemArr, indexArr) => indexArr !== index);
      newArr.splice(index + 1, 0, elem);
      this.saveCityList(newArr);
    }
  }

  calendarChange = (event, date) => {
    this.setState({
      calendarDate: date,
    });
  };

  dateChangeNextPrev(action) {
    window.clearTimeout(this.debounce);
    this.debounce = window.setTimeout(() => {
      const { defaultCityInfoReducer } = this.props;
      const time = this.state.calendarDate;
      const dateTime =
        action === "||"
          ? moment().tz(defaultCityInfoReducer.timezone)
          : moment(time);
      const year = dateTime.get("year");
      const month = dateTime.get("month");
      const day = dateTime.get("date");
      const hour = dateTime.get("hour");
      const minute = dateTime.get("minute");
      const second = dateTime.get("second");
      const millisecond = dateTime.get("millisecond");
      let newTime;
      if (action === "-")
        newTime = new Date(
          year,
          month,
          day - 1,
          hour,
          minute,
          second,
          millisecond
        );
      else if (action === "+")
        newTime = new Date(
          year,
          month,
          day + 1,
          hour,
          minute,
          second,
          millisecond
        );
      else
        newTime = new Date(year, month, day, hour, minute, second, millisecond);
      this.setState({ calendarDate: newTime });
    }, 1000);
  }

  timePickerSliderCalc() {
    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.timePickerRight;
    return blockRight ? blockRight.offsetWidth : 0;
  }

  timezone(zone) {
    return zone ? zone : moment.tz.guess();
  }

  timePlanerSwipe = (style) => {
    return this.setState({ timePlanerOverflow: style });
  };

  render() {
    const {
      selectedCitiesReducer,
      defaultCityInfoReducer,
      authorizationReducer,
    } = this.props;
    const { currentGroup } = selectedCitiesReducer;

    if (
      !defaultCityInfoReducer.timezone ||
      !selectedCitiesReducer.cities ||
      !selectedCitiesReducer.cities.length ||
      !currentGroup ||
      this.state.calendarDate === ""
    ) {
      return (
        <section className="citiesRender timePlaner">
          <div className="timePicker timePlanerLoader">
            <Loader />
          </div>
        </section>
      );
    }

    return (
      <section className="citiesRender timePlaner">
        <div className="timePicker">
          <div className="time-picker-header">
            <div className="currentCityTittle">
              <div className="title-block">
                <h3>Time Planner</h3>
                <div className="time-format-date">
                  <MuiThemeProvider>
                    <div className="schedulerCalendarWrapper">
                      <div className="schedulerCalendarBlock">
                        <DatePicker
                          hintText="Controlled Date Input"
                          value={this.state.calendarDate}
                          onChange={this.calendarChange}
                          className="schedulerCalendar"
                          formatDate={(time) => moment(time).format("LL")}
                        />
                        <i className="material-icons">today</i>
                      </div>
                      <div className="calendar-date-picker-buttons">
                        <MuiThemeProvider>
                          <IconButton
                            onClick={this.dateChangeNextPrev.bind(this, "-")}
                            tooltip="Previous Day"
                            tooltipPosition="bottom-right"
                          >
                            <i className="material-icons prev_date">
                              play_arrow
                            </i>
                          </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                          <IconButton
                            onClick={this.dateChangeNextPrev.bind(this, "||")}
                            tooltip="Current Day"
                            tooltipPosition="bottom-center"
                          >
                            <i className="material-icons">stop</i>
                          </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                          <IconButton
                            onClick={this.dateChangeNextPrev.bind(this, "+")}
                            tooltip="Next Day"
                            tooltipPosition="bottom-left"
                          >
                            <i className="material-icons">play_arrow</i>
                          </IconButton>
                        </MuiThemeProvider>
                      </div>
                    </div>
                  </MuiThemeProvider>
                </div>
              </div>
              <TimePickerSearch
                key={currentGroup.id}
                currentGroup={currentGroup.id}
              />
              <div className="time-planner-icons">
                {/* <Link to={"#"}>
									<img src={info} width="30px" alt="info" />
								</Link> */}
                <Link to={"/account"}>
                  <img src={settings} width="30px" alt="settings" />
                </Link>
              </div>
            </div>
          </div>
          <div className="timePickerSlider" ref={`timePickerSlider`}>
            <div className="group-list" id="groupList">
              <ChipsCarousel />

              {!authorizationReducer.username ? (
                <Link to="/signin" className="group-list-signin">
                  Sign in to edit events/calendars/groups
                </Link>
              ) : null}
            </div>

            <HolidaysTimePlaner selectedDate={this.state.calendarDate} />
            <CarouselTopBlock>
              <div className="topBlockCities">
                <div className="cityBlock">
                  <div className="time-picker-left">
                    <div className="time-picker-left-block">
                      <div className="time-picker-left-name-block">
                        <div className="add-and-dell-city" />
                        <div
                          className="time-picker-left-flag"
                          // onClick={this.selectedCityInfo.bind(
                          // 	this
                          // )}
                        >
                          <img
                            src={`/static/flags/flags-iso/flat/64/${selectedCitiesReducer.cities[0].country_code}.png`}
                            alt="citydateandtime"
                          />
                        </div>
                        <div
                          className="time-picker-left-city"
                          // onClick={this.selectedCityInfo.bind(
                          // 	this
                          // )}
                        >
                          <Ticker
                            key={getCityName(selectedCitiesReducer.cities[0])}
                            text={getCityName(selectedCitiesReducer.cities[0])}
                          />
                          <span>
                            {this.emptyAbr(selectedCitiesReducer.cities[0])}
                          </span>
                          <span>
                            {this.cityTimeCalendar(
                              selectedCitiesReducer.cities[0],
                              "zoneAbr"
                            )}
                            (
                            {this.cityTimeCalendar(
                              selectedCitiesReducer.cities[0],
                              "zone"
                            )}
                            )
                          </span>
                        </div>
                      </div>
                      <TimePickerLeftInfoBlock>
                        <div className="time-picker-left-time">
                          <p>
                            {this.cityTimeCalendar(
                              selectedCitiesReducer.cities[0],
                              "time"
                            )}
                          </p>
                          <span>
                            {this.cityTimeCalendar(
                              selectedCitiesReducer.cities[0],
                              "date"
                            )}
                          </span>
                          <span>
                            Week -{" "}
                            {this.cityTimeCalendar(
                              selectedCitiesReducer.cities[0],
                              "month"
                            )}
                          </span>
                          <DstIcon
                            city={selectedCitiesReducer.cities[0]}
                            dst_data={selectedCitiesReducer.cities[0].dst}
                            calendarDate={this.state.calendarDate}
                          />
                        </div>
                      </TimePickerLeftInfoBlock>
                    </div>

                    {selectedCitiesReducer.cities.map((elem, index, arr) => {
                      if (index !== 0) {
                        return (
                          <div key={index} className="time-picker-left-block">
                            <div className="time-picker-left-name-block">
                              <div className="add-and-dell-city reorder-cities">
                                <i
                                  className="material-icons raise"
                                  onClick={this.toRaiseTheCity.bind(
                                    this,
                                    elem,
                                    index,
                                    arr
                                  )}
                                >
                                  play_arrow
                                </i>
                                <i
                                  className="material-icons omit"
                                  onClick={this.omitTheCity.bind(
                                    this,
                                    elem,
                                    index,
                                    arr
                                  )}
                                >
                                  play_arrow
                                </i>
                              </div>
                              <div className="add-and-dell-city">
                                <i
                                  className="dell_city material-icons"
                                  onClick={this.dellCity.bind(
                                    this,
                                    elem,
                                    index
                                  )}
                                >
                                  clear
                                </i>
                                <i
                                  className="as_default_city material-icons"
                                  onClick={this.setAsDefault.bind(
                                    this,
                                    elem,
                                    index
                                  )}
                                >
                                  home
                                </i>
                              </div>
                              <div
                                className="time-picker-left-flag"
                                // onClick={this.selectedCityInfo.bind(
                                // 	this,
                                // 	this.cityId(
                                // 		elem.id
                                // 	)
                                // )}
                              >
                                <TimeDifferent
                                  city={elem}
                                  time={this.timeDifference(
                                    elem.timezone
                                  ).replace(".", ":")}
                                />

                                <img
                                  src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                                  alt="geotimedate"
                                />
                              </div>
                              <div
                                className="time-picker-left-city"
                                // onClick={this.selectedCityInfo.bind(
                                // 	this,
                                // 	this.cityId(
                                // 		elem.id
                                // 	)
                                // )}
                              >
                                <Ticker
                                  key={getCityName(elem)}
                                  text={getCityName(elem)}
                                />
                                <span>{this.emptyAbr(elem)}</span>
                                <span>
                                  {this.cityTimeCalendar(elem, "zoneAbr")}(
                                  {this.cityTimeCalendar(elem, "zone")})
                                </span>
                              </div>
                            </div>
                            <TimePickerLeftInfoBlock>
                              <div className="time-picker-left-time">
                                <p>{this.cityTimeCalendar(elem, "time")}</p>
                                <span>
                                  {this.cityTimeCalendar(elem, "date")}
                                </span>
                                <span>
                                  Week - {this.cityTimeCalendar(elem, "month")}
                                </span>
                                <DstIcon
                                  city={elem}
                                  dst_data={elem.dst}
                                  calendarDate={this.state.calendarDate}
                                />
                              </div>
                            </TimePickerLeftInfoBlock>
                          </div>
                        );
                      }
                      return "";
                    })}
                  </div>
                  <div
                    className="time-picker-right"
                    ref={`timePickerRight`}
                    style={this.state.timePlanerOverflow}
                  >
                    <TimePlanerSwipeBlock
                      key={currentGroup.id}
                      timePickerSlider={this.timePickerSliderCalc()}
                      calendarDate={this.state.calendarDate}
                      sliderInitPosition={this.state.sliderInitPosition}
                      timePlanerSwipe={this.timePlanerSwipe}
                    />
                    {/* eslint-disable-next-line react/no-string-refs */}
                    <div ref="timePickerTopSliderScale"
                      className="time-picker-slider-scale"


                    >
                      {this.sliderStraightedge(
                        this.timezone(selectedCitiesReducer.cities[0].timezone),
                        null,
                        selectedCitiesReducer.cities[0].timezone
                      ).map((time, indexTime) => {
                        return (
                          <div
                            className="time-picker-time-block"
                            key={indexTime}
                            style={{
                              width: "43.1667px",
                            }}
                          >
                            <div
                              className="color-block"
                              style={this.timePickerColor(
                                time,
                                selectedCitiesReducer.cities[0],
                                "default",
                                "city"
                              )}
                            >
                              <RenderDate
                                time={time.time}
                                tz={selectedCitiesReducer.cities[0].timezone}
                              />
                              <div className="time-picker-hours">
                                <span>
                                  {this.cityTimeLeftBlock(
                                    time,
                                    selectedCitiesReducer.cities[0]
                                  )}
                                </span>
                                {/*<span>{this.cityMonthLeftBlock(time, selectedCitiesReducer.cities[0])}</span>*/}
                              </div>
                              <div
                                className="time-picker-minutes"
                                style={this.minutesStyleFormat(
                                  time,
                                  selectedCitiesReducer.cities[0]
                                )}
                              >
                                <div>:00</div>
                                <div>
                                  {this.cityTimeRightBottomBlock(
                                    time,
                                    selectedCitiesReducer.cities[0]
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {selectedCitiesReducer.cities.map((elem, index, arr) => {
                      if (index !== 0) {
                        return (
                            // eslint-disable-next-line react/no-string-refs
                          <div ref="timePickerSliderScale"
                            className="time-picker-slider-scale"
                            key={index}

                          >
                            {this.sliderStraightedge(
                              elem,
                              index,
                              selectedCitiesReducer.cities[0].timezone
                            ).map((time, indexTime) => {
                              return (
                                <div
                                  className="time-picker-time-block"
                                  key={indexTime}
                                  style={this.timePickerSliderScale(time)}
                                >
                                  <div
                                    className="color-block"
                                    style={this.timePickerColor(
                                      time,
                                      elem,
                                      "",
                                      "city"
                                    )}
                                  >
                                    <RenderDate
                                      time={time.time}
                                      tz={elem.timezone}
                                    />
                                    <div className="time-picker-hours">
                                      <span>
                                        {this.cityTimeLeftBlock(time, elem)}
                                      </span>
                                      {/*<span>{this.cityMonthLeftBlock(time, elem)}</span>*/}
                                    </div>
                                    <div
                                      className="time-picker-minutes"
                                      style={this.minutesStyleFormat(
                                        time,
                                        elem
                                      )}
                                    >
                                      <div>
                                        {this.cityTimeRightTopBlock(time)}
                                      </div>
                                      <div>
                                        {this.cityTimeRightBottomBlock(
                                          time,
                                          elem
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                      return "";
                    })}
                  </div>
                </div>
              </div>
            </CarouselTopBlock>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCitiesReducer: state.selectedCitiesReducer,
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    authorizationReducer: state.authorizationReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    selectedCities: bindActionCreators(selectedCities, dispatch),
    currentCityInfo: bindActionCreators(currentCityInfo, dispatch),
    defaultCityInfo: bindActionCreators(defaultCityInfo, dispatch),
    setCurrentLocation: bindActionCreators(setCurrentLocation, dispatch),
    currentSearch: bindActionCreators(currentSearch, dispatch),
    timePlanerSwipeBlock: bindActionCreators(timePlanerSwipeBlock, dispatch),
    setCitiesListForTimePlaner: bindActionCreators(
      setCitiesListForTimePlaner,
      dispatch
    ),
    deleteCity: bindActionCreators(deleteCity, dispatch),
    setAsHome: bindActionCreators(setAsHome, dispatch),
    setCurrentGroup: bindActionCreators(setCurrentGroup, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBlockCities);
