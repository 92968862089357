import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
// import {DatePicker, MuiThemeProvider} from "material-ui";

import Api from "../../../../services/api";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";

import Head from './Components/Head';
import Title from './Components/Title';
import ComponentDesign from './Components/ComponentDesign/ComponentDesign';
import ComponentTitle from './Components/ComponentTitle/ComponentTitle';
import ComponentDateEvent from './Components/ComponentDateEvent/ComponentDateEvent';
import ComponentLocationEvent from './Components/ComponentLocationEvent/ComponentLocationEvent';
import ComponentTimerDisplayFormat from './Components/ComponentTimerDisplayFormat/ComponentTimerDisplayFormat';
import CreateButton from './Components/CreateButton/CreateButton';

import './CountdownTimer.css';


class CountdownTimer extends Component {
    async componentDidMount() {
        const {currentSearch, currentSearchReducer} = this.props;
        // let url, data;
        let data;
        const cityInfoLoad = cityInfo => {
            //console.log('cityInfo', cityInfo)
            // url = cityInfo.url;
            currentSearch(cityInfo);
        };
        if (localStorage.getItem('mainSearchCity')) {
            data = await Api.userCityList(`geo_names/${JSON.parse(localStorage.getItem('mainSearchCity')).id}/`);
            cityInfoLoad(data.data);
        } else {
            data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
            cityInfoLoad(data.data);
            // localStorage.setItem('searchCityInfo', JSON.stringify(data.data));
        }

        //const countdownTimer = url.length ? `/countdown-timer/${url.split('/').slice(1).join('/')}` : `/countdown-timer/null/`;
        //this.props.history.push(countdownTimer);
    }

    render() {
        //console.log('CountdownTimer', this.props.countdownReducer);
        return (
            <div className='Calculator'>
                <Head/>
                <div className='content'>
                    <Title/>
                    <ComponentDesign/>
                    <ComponentTitle/>
                    <ComponentDateEvent/>
                    <div className="block-countdown-event">
                        <p className="title-countdown-comp">Event countdown location:</p>
                        <ComponentLocationEvent/>
                    </div>
                    <ComponentTimerDisplayFormat/>
                    <CreateButton/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        countdownReducer: state.countdownReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch)
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
