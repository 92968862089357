
const reducer_sunRiseTableList = (state = {}, action) => {
  switch (action.type) {
    case 'SUN_RISE_TABLE_LIST':
      return action.data;
    default:
      return state;
  }
};
  
export default reducer_sunRiseTableList;