import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MuiThemeProvider, DatePicker, IconButton } from 'material-ui'; //RaisedButton
import moment from 'moment-timezone/index.js';

import './CityCalendarBlock.css';
import currentSearch from '../../../../actions/action_searchCity/action_currentSearch';
import planetaryHours from '../../../../actions/action_planetaryHours/action_planetaryHours';
import planetaryHoursTable from '../../../../actions/action_planetaryHours/action_planetaryHoursTable';

import Api from '../../../../services/api.js';

class CityCalendarBlock extends Component {
    calendarChange(event, date) {
        const { currentSearchReducer, planetaryHours } = this.props;
        const cityTime = moment().tz(currentSearchReducer.timezone);
        const tzNum = moment().tz(currentSearchReducer.timezone).format('Z')
        const cityHour = cityTime.get('hour');
        const cityMinute = cityTime.get('minute');
        const citySecond = cityTime.get('second');
        const cityMillisecond = cityTime.get('millisecond');
        const newCityTime = moment(date).set('hour', cityHour).set('minute', cityMinute).set('second', citySecond).set('millisecond', cityMillisecond);
        planetaryHours(newCityTime.format(`YYYY-MM-DDTHH:mm:ss${tzNum}`));
        this.calendarInfo(newCityTime.format(`YYYY-MM-DDTHH:mm:ss${tzNum}`));
    }
    dateChangeNextPrev(action){
        window.clearTimeout(this.debounce);
        this.debounce = window.setTimeout(() => {            
            const { currentSearchReducer, planetaryHours, planetaryHoursReducer } = this.props;
            const tz = currentSearchReducer.timezone;
            let newCityTime = '';
            if(action === '--') newCityTime = moment(planetaryHoursReducer).tz(tz).subtract(1, 'months').format();
            else if(action === '-') newCityTime = moment(planetaryHoursReducer).tz(tz).subtract(1, 'days').format();
            else if(action === '++') newCityTime = moment(planetaryHoursReducer).tz(tz).add(1, 'months').format();
            else if(action === '+') newCityTime = moment(planetaryHoursReducer).tz(tz).add(1, 'days').format();
            else newCityTime = moment().tz(tz).format();         
            planetaryHours(newCityTime);
            this.calendarInfo(newCityTime);
        }, 1000);
    }    
    async calendarInfo(newTime){
        const { currentSearchReducer, planetaryHoursTable } = this.props;
        const date = moment(newTime).tz(currentSearchReducer.timezone).format('YYYY-MM-DD');
        const planetaryHours = await Api.userCityList(`geo_names/${currentSearchReducer.id}/rise_set_rise/${date}/`);
        planetaryHoursTable(planetaryHours.data.data);
    }
    planetHoursCalendar(){
        const { currentSearchReducer, planetaryHoursReducer } = this.props;
        const cityTimeNoFormat = moment(planetaryHoursReducer).tz(currentSearchReducer.timezone);
        const year = cityTimeNoFormat.get('year');
        const month = cityTimeNoFormat.get('month');
        const day = cityTimeNoFormat.get('date');
        const hour = cityTimeNoFormat.get('hour');
        const minute = cityTimeNoFormat.get('minute');
        const second = cityTimeNoFormat.get('second');
        const millisecond = cityTimeNoFormat.get('millisecond');
        return new Date(year, month, day, hour, minute, second, millisecond);
    }
    render () {
        return (
            <section className="CalendarPickerBlock">
                <div className="left-calendar-block">
                    <MuiThemeProvider>
                        <div className='calendar-date-picker-wrapper'>
                            <DatePicker
                                className="calendar-date-picker"
                                hintText="Select Date"
                                value={this.planetHoursCalendar()}
                                onChange={this.calendarChange.bind(this)}
                                autoOk={true}
                                formatDate={new Intl.DateTimeFormat('en-US', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                }).format}
                            />
                            <i className="material-icons">today</i>
                        </div>
                    </MuiThemeProvider>
                    <div className="calendar-date-picker-buttons">
                        <MuiThemeProvider>                    
                            <IconButton onClick={this.dateChangeNextPrev.bind(this, '--')} tooltip="Previous Month" tooltipPosition="bottom-right">
                                <i className="material-icons">fast_rewind</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>                    
                            <IconButton onClick={this.dateChangeNextPrev.bind(this, '-')} tooltip="Previous Day" tooltipPosition="bottom-right">
                                <i className="material-icons prev_date">play_arrow</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>                    
                            <IconButton onClick={this.dateChangeNextPrev.bind(this, '||')} tooltip="Current Day" tooltipPosition="bottom-center">
                            <i className="material-icons">stop</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>                    
                            <IconButton onClick={this.dateChangeNextPrev.bind(this, '+')} tooltip="Next Day" tooltipPosition="bottom-left">
                                <i className="material-icons">play_arrow</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>                    
                            <IconButton onClick={this.dateChangeNextPrev.bind(this, '++')} tooltip="Next Month" tooltipPosition="bottom-left">                    
                                <i className="material-icons">fast_forward</i>
                            </IconButton>
                        </MuiThemeProvider>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetaryHoursReducer: state.planetaryHoursReducer,
        planetaryHoursTableReducer: state.planetaryHoursTableReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
        planetaryHours: bindActionCreators(planetaryHours, dispatch),
        planetaryHoursTable: bindActionCreators(planetaryHoursTable, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CityCalendarBlock);