
const currentNewsReducer = (state = '', action) => {
  switch (action.type) {
    case 'CURRENT_NEWS':
      return action.data;
    default:
      return state;
  }
};
  
export default currentNewsReducer;