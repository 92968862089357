import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { createBrowserHistory } from "history";
import ReactTooltip from "react-tooltip";

import TimePickerSearch from "../../MainPage/TopBlockCities/TimePickerSearch/TimePickerSearch";
import CarouselTopBlock from "../../MainPage/TopBlockCities/CarouselTopBlock/CarouselTopBlock";
import TimePickerLeftInfoBlock from "../../MainPage/TopBlockCities/TimePickerLeftInfoBlock/TimePickerLeftInfoBlock";
import TimePlanerSwipeBlock from "../../MainPage/TopBlockCities/TimePlanerSwipeBlock/TimePlanerSwipeBlock";
import AboutExchange from "./AboutExchange";

import { stockExchangeSingle } from "../../../../actions/action_stockExchanges";
import Api from "../../../../services/api";

const history = createBrowserHistory();

const TimeDifferent = props => {
  const { defaultStock, city, time } = props;
  const timeShow = time.replace("-", "");
  const ahead = timeShow === time;

  let message = `${city.name} is ${timeShow} hrs ${
    ahead ? "ahead" : "behind"
  } of ${defaultStock.city}.`;

  if (timeShow === "0:00") {
    message = `The time in ${city.name} is same as that of ${defaultStock.city}.`;
  }

  return (
    <Fragment>
      <div
        className="difference_hours"
        data-tip=""
        data-for={`tool-tip-id-${city.id}`}
      >
        {time}
      </div>
      <ReactTooltip id={`tool-tip-id-${city.id}`}>{message}</ReactTooltip>
    </Fragment>
  );
};

class TopBlockCities extends Component {
  state = {
    format12: true,
    format24: false,
    timePlanerOverflow: { overflowX: "hidden" },
    selectCity: this.props.searchStockCityInfoReducer
      ? this.props.searchStockCityInfoReducer
      : this.props.currentCityInfoReducer.name
      ? this.props.currentCityInfoReducer
      : null,
    sliderInitPosition: {},
    groupNumm: 1,
    calendarDate: null
  };

  componentDidMount() {
    const parsedUrl = window.location.pathname.split("/");
    const selectedCityId = parsedUrl[parsedUrl.length - 1];
    (async () => {
      const data = await Api.userCityList(`geo_names/${selectedCityId}/`);
      const timeData = await Api.getCache(
        Api.currentCoordinars,
        data.data.latitude,
        data.data.longitude
      );

      const fullData = {
        ...data.data,
        working_hours_start: timeData.data.working_hours_start,
        working_hours_end: timeData.data.working_hours_end,
        colorSchemes: timeData.data.ColorSchemes,
        colorSchemesWeekend: timeData.data.ColorSchemesWeekend
      };

      if (!this.props.currentCityInfoReducer.name) {
        this.setState({ selectCity: fullData });
      }
    })();
  }

  componentDidUpdate() {
    if (
      !this.state.calendarDate &&
      this.props.stockExchangeSing.stock_exchanged[0]
    ) {
      const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
      defaultStock.timezone = defaultStock.timezone_stock;
      let cityTimeNoFormat = moment().tz(defaultStock.timezone);

      const year = cityTimeNoFormat.get("year");
      const month = cityTimeNoFormat.get("month");
      const day = cityTimeNoFormat.get("date");
      const hour = cityTimeNoFormat.get("hour");
      const minute = cityTimeNoFormat.get("minute");
      const second = cityTimeNoFormat.get("second");
      const millisecond = cityTimeNoFormat.get("millisecond");

      const time = new Date(
        year,
        month,
        day,
        hour,
        minute,
        second,
        millisecond
      );
      this.setState({
        calendarDate: time
      });
    }
  }

  emptyAbr(elem) {
    if (
      elem.country_name === "United States" &&
      elem.country_name_abbr !== null &&
      elem.state_code !== null
    ) {
      return `${elem.state_code}, ${elem.country_name_abbr}`;
    } else if (elem.country_name !== null) return elem.country_name;
    else return "";
  }

  timeDifference(cityZone) {
    // const { selectedCitiesReducer } = this.props;
    // const { cities } = selectedCitiesReducer;

    const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
    defaultStock.timezone = defaultStock.timezone_stock;
    if (this.state.calendarDate) {
      const year = this.state.calendarDate.getFullYear();
      const month = this.state.calendarDate.getMonth();
      const day = this.state.calendarDate.getDate();

      const mainCityTime = moment()
        .set("year", year)
        .set("date", day)
        .set("month", month)
        // .tz(cities[0].timezone)
        .tz(defaultStock.timezone)
        .format(`YYYY-MM-DDTHH:mm:ss+00:00`);
      const cityTime = moment()
        .set("year", year)
        .set("date", day)
        .set("month", month)
        .tz(cityZone)
        .format(`YYYY-MM-DDTHH:mm:ss+00:00`);
      const timeDiffHours = moment(mainCityTime).diff(
        moment(cityTime),
        "hours"
      );
      let timeDiffMinutes =
        timeDiffHours * 60 -
        moment(mainCityTime).diff(moment(cityTime), "minutes");
      if (timeDiffMinutes === 0) timeDiffMinutes = "00";
      else if (timeDiffMinutes < 0) timeDiffMinutes *= -1;
      return `${timeDiffHours * -1}.${timeDiffMinutes}`;
    }
  }

  cityTimeCalendar(elem, attr) {
    const timeZone = this.parseTimezone(elem);

    const hours = moment.tz(timeZone).format("HH");
    const minutes = moment.tz(timeZone).format("mm");
    const seconds = moment.tz(timeZone).format("ss");
    const tz = moment.tz(timeZone).format("Z");
    const calendarDate = new Date();
    const noZoneTime = moment(calendarDate)
      .set("hour", hours)
      .set("minute", minutes)
      .set("second", seconds)
      .format(`YYYY-MM-DDTHH:mm:ss${tz}`);
    const time = moment(noZoneTime).tz(timeZone);
    if (time) {
      switch (attr) {
        case "time":
          return time.format("LT");
        case "date":
          return time.format("ddd, MMM DD");
        case "zoneAbr":
          return time.format("z");
        case "zone":
          return time.format("Z");
        case "month":
          return time.format("w");
          // defadefault: return time.format();
      }
      return time.format();
    }
  }

  sliderStraightedge(elem) {
    const calendarDate = new Date();
    const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
    if (defaultStock.timezone_stock) {
      if (typeof elem === "string") {
        const time = [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ];
        const newMainCityArr = time.map(elemHours => {
          const timezone = moment(calendarDate)
            .tz(defaultStock.timezone_stock)
            .format(`Z`);
          const timeNoFormat = moment(calendarDate).format(
            `YYYY-MM-DDT${elemHours}:mm:ss${timezone}`
          );
          const cityTime = moment(timeNoFormat).tz(defaultStock.timezone_stock);
          return { time: cityTime.format() };
        });
        return newMainCityArr;
      } else {
        const timeZone = this.parseTimezone(elem);

        const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
        const timeMainTz = moment(calendarDate)
          .tz(defaultStock.timezone_stock)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0);
        const timeMinutes = timeMainTz
          .tz(timeZone)
          .format("HH.mm")
          .split(".");
        const m = timeMinutes[1];

        const timeArr = [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ];
        const newMainCityArr = timeArr.map(elemHours => {
          const year = calendarDate.getFullYear();
          const month = calendarDate.getMonth() + 1;
          const momentMonth = (month + "").length === 1 ? `0${month}` : month;
          const day = calendarDate.getDate();
          const momentDay = (day + "").length === 1 ? `0${day}` : day;
          const tz = moment(calendarDate)
            .tz(defaultStock.timezone_stock)
            .format(`Z`);
          const cityTime = `${year}-${momentMonth}-${momentDay}T${elemHours}:00:00${tz}`;
          return {
            time: moment(cityTime)
              .tz(timeZone)
              .format(),
            minutes: m
          };
        });
        return newMainCityArr;
      }
    } else {
      return [];
    }
  }

  parseTimezone = item =>
    item.timezone_stock ? item.timezone_stock : item.timezone;

  timezone = zone => (zone ? zone : moment.tz.guess());

  timePickerSliderCalc() {
    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.timePickerRight;
    return blockRight ? blockRight.offsetWidth : 0;
  }

  timePlanerSwipe = style => this.setState({ timePlanerOverflow: style });

  timePickerColor(time, elem) {
    const timeZone = this.parseTimezone(elem);

    let colorSchemes, startWork, endWork;

    if (this.state.optionsCountry) {
      colorSchemes = this.state.optionsCountry.ColorSchemes;
      startWork = +this.state.optionsCountry.working_hours_start.replace(
        ":",
        "."
      );
      endWork = +this.state.optionsCountry.working_hours_end.replace(":", ".");
    } else if (this.props.currentCityInfoReducer.ColorSchemes) {
      colorSchemes = this.props.currentCityInfoReducer.ColorSchemes;
      startWork = +this.props.currentCityInfoReducer.working_hours_start.replace(
        ":",
        "."
      );
      endWork = +this.props.currentCityInfoReducer.working_hours_end.replace(
        ":",
        "."
      );
    } else {
      colorSchemes = this.state.selectCity.colorSchemes;
      startWork = +this.state.selectCity.working_hours_start.replace(":", ".");
      endWork = +this.state.selectCity.working_hours_end.replace(":", ".");
    }

    const { worke_background, rest_background } = colorSchemes;

    const value = +moment(time.time)
      .tz(timeZone)
      .format("HH");
    const currentTimeHours = moment(time.time)
      .tz(timeZone)
      .format("HH");
    const prevTimeHours = moment(time.time)
      .tz(timeZone)
      .subtract(1, "hours")
      .format(`HH`);
    if (+currentTimeHours - +prevTimeHours === 2)
      return {
        background: "#ff8236",
        color: "#fff"
      };
    else if (currentTimeHours === prevTimeHours)
      return {
        background: "#ff8236",
        color: "#fff"
      };
    else if (value === 0)
      return {
        background: "#fcc238",
        color: "#fff"
      };
    else if (value < startWork || value > endWork)
      return {
        background: rest_background
      };
    else if (startWork <= value && value <= endWork)
      return {
        background: worke_background
      };
  }

  timePickerColorStock(time, elem, indexTime) {
    const timeZone = this.parseTimezone(elem);

    const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
    const colorStock = defaultStock.status === "OPEN" ? "#ffed9e" : "#91a1be";
    let startWork = 0;
    let startMinutesWork = 0;
    let endWork = 0;
    let endMinutesWork = 0;

    if (defaultStock.schedule[0] !== undefined) {
      startWork = +defaultStock.schedule[0].open_hours.slice(0, 2);
      endWork = +defaultStock.schedule[0].open_hours.slice(6, 8);

      startMinutesWork = +defaultStock.schedule[0].open_hours.slice(3, 5);
      endMinutesWork = +defaultStock.schedule[0].open_hours.slice(-2);
    }
    const value = +moment(time.time)
      .tz(timeZone)
      .format("HH");
    const currentTimeHours = moment(time.time)
      .tz(timeZone)
      .format("HH");
    const prevTimeHours = moment(time.time)
      .tz(timeZone)
      .subtract(1, "hours")
      .format(`HH`);
    if (+currentTimeHours - +prevTimeHours === 2) {
      return {
        background: "#ff8236",
        color: "#fff"
      };
    } else if (currentTimeHours === prevTimeHours) {
      return {
        background: "#ff8236",
        color: "#fff"
      };
    } else if (value === 0) {
      return {
        background: "#fcc238",
        color: "#fff"
      };
    } else if (0 <= value && value < startWork) {
      return { background: "#ffffff" };
    } else if (startWork <= value && value <= endWork) {
      /* start work */
      if (startWork === indexTime && startMinutesWork !== 0) {
        return {
          background: `linear-gradient(90deg, #fff ${(startMinutesWork * 100) /
            60}%, ${colorStock} ${(startMinutesWork * 100) / 60}%)`
        };
      }
      /* start work */
      /* end work */
      if (endWork === indexTime && endMinutesWork !== 0) {
        return {
          background: `linear-gradient(90deg, ${colorStock} ${(endMinutesWork *
            100) /
            60}%, #fff ${(endMinutesWork * 100) / 60}%)`
        };
      }
      /* end work */
      return {
        background: colorStock
      };
    }
  }

  cityTimeLeftBlock(elem, city) {
    const timeZone = this.parseTimezone(city);

    if (!city.time_format) {
      localStorage.clear();
      window.location.reload();
    }
    const time = +moment(elem.time)
      .tz(timeZone)
      .format("HH");
    if (time === 0)
      return moment(elem.time)
        .tz(timeZone)
        .format("DD");
    else if (this.state.format12 === true) return time > 12 ? time - 12 : time;
    else if (this.state.format24 === true) return time;
    else {
      if (city.time_format === "AM_PM") return time > 12 ? time - 12 : time;
      else return time;
    }
  }

  cityMonthLeftBlock(elem, city) {
    const timeZone = this.parseTimezone(city);

    const time = +moment(elem.time)
      .tz(timeZone)
      .format("HH");
    if (time === 0)
      return moment(elem.time)
        .tz(timeZone)
        .format("MMM");
  }

  minutesStyleFormat(time, elem) {
    const timeZone = this.parseTimezone(elem);

    const hours = +moment(time.time)
      .tz(timeZone)
      .format("HH");
    if (hours === 0) return { display: "none" };
    if (this.state.format12 === true) {
      return { fontSize: "10px", lineHeight: "12px" };
    } else if (this.state.format24 === true) {
      return { fontSize: "14px", lineHeight: "16px" };
    } else if (elem.time_format === "am-pm") {
      return { fontSize: "12px", lineHeight: "14px" };
    } else if (elem.time_format === "T24") {
      return { fontSize: "14px", lineHeight: "16px" };
    }
  }

  cityTimeRightBottomBlock(elem, city) {
    const timeZone = this.parseTimezone(city);

    const time = +moment(elem.time)
      .tz(timeZone)
      .format("HH");
    if (this.state.format12 === true) {
      return time >= 12 ? "pm" : "am";
    } else if (
      this.state.format12 === false &&
      this.state.format24 === false &&
      city.time_format === "AM_PM"
    ) {
      return time >= 12 ? "pm" : "am";
    }
  }

  timePickerSliderScale() {
    // eslint-disable-next-line react/no-string-refs
    const block = this.refs.timePickerSlider;
    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.timePickerRight;
    if (block && blockRight) {
      const newBlock = blockRight.offsetWidth;
      return { width: newBlock / 24 };
    }
  }

  changeUrl = (cityId, cityName) => {
    const validCityName = cityName.replace(/ /g, "_"); // replace spaces
    const dividedUrl = window.location.pathname.split("-");
    const index = dividedUrl.indexOf("hours");
    dividedUrl[index + 1] = validCityName;
    const updatedUrl = dividedUrl.join("-");
    // eslint-disable-next-line no-useless-escape
    const newUrl = updatedUrl.replace(/\/[^\/]*$/, `/${cityId}`);
    return history.push(newUrl);
  };

  setSelectCityFromChild(elem) {
    if (elem) {
      this.changeUrl(elem.id, elem.name);
      elem.timezone_stock = elem.timezone;
      this.setState({ selectCity: elem });
    }
  }

  differenceHours(first, second) {
    let firstPart, secondPart, diffHoursResult;
    if (first && second) {
      firstPart = +this.cityTimeCalendar(first, "zone").replace(":", ".");
      secondPart = +this.cityTimeCalendar(second, "zone").replace(":", ".");
      firstPart = 14 - firstPart;
      secondPart = 14 - secondPart;
      diffHoursResult = firstPart - secondPart;
      diffHoursResult =
        diffHoursResult < 0 ? diffHoursResult * -1 : diffHoursResult;
      diffHoursResult = diffHoursResult.toFixed(2).replace(".", ":");
      return diffHoursResult;
    }
  }

  returArreyForSwipe(currentExchange, selected) {
    const allLocation = [];

    if (currentExchange.name) allLocation.push(currentExchange);

    if (
      !!selected &&
      Object.keys(selected).length !== 0 &&
      selected.constructor === Object
    )
      allLocation.push(selected);

    return allLocation;
  }

  getOptionsFromChild(options) {
    if (options) {
      this.setState({ optionsCountry: options });
    }
  }

  render() {
    if (!this.props.stockExchangeSing.stock_exchanged[0].name) {
      return <p>Lodaing</p>;
    }

    const defaultStock = this.props.stockExchangeSing.stock_exchanged[0];
    defaultStock.timezone = defaultStock.timezone_stock;
    const { selectCity } = this.state;
    const {
      name,
      symbol,
      country_code
    } = this.props.stockExchangeSing.stock_exchanged[0];

    return (
      <section className="citiesRender timePlaner">
        <div className="timePicker">
          <div className="time-picker-header">
            <div className="currentCityTittle">
              <div className="title-block">
                {!selectCity && (
                  <h3>
                    {name} Operating Hours as per{" "}
                    {this.props.currentCityInfoReducer.name} Time (
                    {this.cityTimeCalendar(
                      this.props.currentCityInfoReducer,
                      "zoneAbr"
                    )}
                    )
                  </h3>
                )}
                {selectCity && (
                  <h3>
                    {name} Operating Hours as per {selectCity.name} Time (
                    {this.cityTimeCalendar(selectCity, "zoneAbr")})
                  </h3>
                )}
                <div className="time-format-date">
                  <TimePickerSearch
                    getSelectCity={elem => this.setSelectCityFromChild(elem)}
                    getOptionsFromCountry={elem =>
                      this.getOptionsFromChild(elem)
                    }
                    currentGroup={this.state.groupNumm}
                    dontUpdateSelectedCities={true}
                    stockExchangePage={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="timePickerSlider" ref={`timePickerSlider`}>
            <CarouselTopBlock>
              <div className="topBlockCities">
                <div className="cityBlock">
                  <div className="time-picker-left">
                    <div>
                      <div className="time-picker-left-name-block">
                        <div className="add-and-dell-city"></div>
                        <div className="time-picker-left-flag">
                          <img
                            src={`/static/flags/flags-iso/flat/64/${country_code}.png`}
                            alt="citydateandtime"
                          />
                        </div>
                        <div className="time-picker-left-city">
                          <p>{symbol}</p>
                          <span>{this.emptyAbr(defaultStock)}</span>
                        </div>
                        <div className="time-picker-left-city">
                          <p>
                            {this.cityTimeCalendar(defaultStock, "zoneAbr")}
                          </p>
                          <span>
                            ({this.cityTimeCalendar(defaultStock, "zone")})
                          </span>
                        </div>
                      </div>
                      <TimePickerLeftInfoBlock>
                        <div className="time-picker-left-time">
                          <p>{this.cityTimeCalendar(defaultStock, "time")}</p>
                          <span>
                            {this.cityTimeCalendar(defaultStock, "date")}
                          </span>
                        </div>
                      </TimePickerLeftInfoBlock>
                    </div>

                    {/*SECOND CITY*/}
                    {defaultStock.name && selectCity && (
                      <div>
                        <div className="time-picker-left-name-block">
                          <div className="add-and-dell-city"></div>
                          <div className="time-picker-left-flag">
                            <img
                              src={`/static/flags/flags-iso/flat/64/${selectCity.country_code}.png`}
                              alt="citydateandtime"
                            />
                          </div>
                          <div className="time-picker-left-city">
                            <p>{selectCity.name}</p>
                            <span>{this.emptyAbr(selectCity)}</span>
                          </div>
                          <div className="time-picker-left-city">
                            <p>
                              {this.cityTimeCalendar(selectCity, "zoneAbr")}
                            </p>
                            <span>
                              ({this.cityTimeCalendar(selectCity, "zone")})
                            </span>
                          </div>
                        </div>
                        <TimePickerLeftInfoBlock>
                          <div className="time-picker-left-time">
                            {defaultStock.name &&
                              selectCity &&
                              this.state.calendarDate && (
                                <TimeDifferent
                                  defaultStock={defaultStock}
                                  city={selectCity}
                                  time={this.timeDifference(
                                    selectCity.timezone
                                  ).replace(".", ":")}
                                />
                              )}
                            <p>{this.cityTimeCalendar(selectCity, "time")}</p>
                            <span>
                              {this.cityTimeCalendar(selectCity, "date")}
                            </span>
                          </div>
                        </TimePickerLeftInfoBlock>
                      </div>
                    )}
                    {/*SECOND CITY END*/}
                  </div>

                  <div
                    className="time-picker-right"
                    ref={`timePickerRight`}
                    style={this.state.timePlanerOverflow}
                  >
                    {defaultStock.name && selectCity && (
                      <TimePlanerSwipeBlock
                        timePickerSlider={this.timePickerSliderCalc()}
                        sliderInitPosition={this.state.sliderInitPosition}
                        timePlanerSwipe={this.timePlanerSwipe}
                        setStockExchangeCities={this.returArreyForSwipe(
                          defaultStock,
                          selectCity
                        )}
                        defaultStock={defaultStock}
                        selectCity={selectCity}
                      />
                    )}
                    {/* eslint-disable-next-line react/no-string-refs */}
                    <div ref="timePickerTopSliderScale"
                      className="time-picker-slider-scale"
                    >
                      {defaultStock.name &&
                        this.sliderStraightedge(
                          this.timezone(
                            defaultStock.timezone
                              ? defaultStock.timezone
                              : defaultStock.timezone_stock
                          )
                        ).map((time, indexTime) => {
                          return (
                            <div
                              className="time-picker-time-block"
                              key={indexTime}
                              style={{
                                width: "43.1667px"
                              }}
                            >
                              <div
                                className="color-block"
                                style={this.timePickerColorStock(
                                  time,
                                  defaultStock,
                                  indexTime
                                )}
                              >
                                <div className="time-picker-hours">
                                  <span>
                                    {this.cityTimeLeftBlock(time, defaultStock)}
                                  </span>
                                  <span>
                                    {this.cityMonthLeftBlock(
                                      time,
                                      defaultStock
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="time-picker-minutes"
                                  style={this.minutesStyleFormat(
                                    time,
                                    defaultStock
                                  )}
                                >
                                  <div>:00</div>
                                  <div>
                                    {this.cityTimeRightBottomBlock(
                                      time,
                                      defaultStock
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    {/*SECOND CITY*/}
                    {selectCity && (
                        // eslint-disable-next-line react/no-string-refs
                      <div ref="timePickerTopSliderScale"
                        className="time-picker-slider-scale"
                      >
                        {this.sliderStraightedge(selectCity).map(
                          (time, indexTime) => {
                            return (
                              <div
                                className="time-picker-time-block"
                                key={indexTime}
                                style={this.timePickerSliderScale(time)}
                              >
                                <div
                                  className="color-block"
                                  style={this.timePickerColor(time, selectCity)}
                                >
                                  <div className="time-picker-hours">
                                    <span>
                                      {this.cityTimeLeftBlock(time, selectCity)}
                                    </span>
                                    <span>
                                      {this.cityMonthLeftBlock(
                                        time,
                                        selectCity
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="time-picker-minutes"
                                    style={this.minutesStyleFormat(
                                      time,
                                      selectCity
                                    )}
                                  >
                                    <div>:00</div>
                                    <div>
                                      {this.cityTimeRightBottomBlock(
                                        time,
                                        selectCity
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                    {/*SECOND CITY END*/}
                  </div>
                </div>
              </div>
            </CarouselTopBlock>
          </div>
        </div>
        <div className="timePicker-note">
          <p>
            * The colored portion of the {name} bar shows the trading hours.
          </p>
          <p className="timePicker-note_align">
            Yellow color denotes that the exchange is open. Blue color denotes
            that the exchange is closed.
          </p>
        </div>
        <AboutExchange selectCity={selectCity} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    stockExchangeSing: state.stockExchangeSing,
    currentCityInfoReducer: state.currentCityInfoReducer,
    searchStockCityInfoReducer: state.searchStockCityInfoReducer
  };
};

export default connect(mapStateToProps, { stockExchangeSingle })(
  TopBlockCities
);
