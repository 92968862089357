import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { StickyTable, Row, Cell } from "react-sticky-table";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import moment from "moment-timezone/index.js";
import MetaTags from "react-meta-tags";

import "./SunCatalogEclipses.css";
import Loader from "../../../../components/Main/Loader/Loader";
import Search from "../../MainPage/Search/Search";
import Countdown from "./Countdown/Countdown";
import eclipsesData from "../../../../actions/action_eclipsesData";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import eclipsesCatelogNextEclipse from "../../../../actions/action_eclipsesCatelogNextEclipse";
import globe from "../../../../assets/globe.png";
import advertising from "../../../../assets/taboola/336-280.png";
import advertisingLong from "../../../../assets/taboola/long-ad.png";

import Api from "../../../../services/api.js";

class SunCatalogEclipses extends Component {
	months = [
		"Jan.",
		"Feb.",
		"Mar.",
		"Apr.",
		"May",
		"June",
		"July",
		"Aug.",
		"Sept.",
		"Oct.",
		"Nov.",
		"Dec."
	];
	eclipseTypeSolar = ["all", "partial", "annular", "total", "hybrid"];
	eclipseTypeLunar = ["all", "partial", "total", "penumbral"];
	eclipseTableSun = [
		"Date",
		"Time",
		"Eclipse type",
		"Saros Series",
		"Eclipse Magnitude",
		"Latitude",
		"Longitude",
		"Sun Zodiac",
		"Moon Zodiac",
		"Path"
	];
	eclipseTableMoon = [
		"Date",
		"Time",
		"Eclipse type",
		"Saros Series",
		"Umbral Magnitude",
		"Sun Zodiac",
		"Moon Zodiac",
		"Path"
	];
	state = {
		period: "Next 10 Years",
		periodTitle: "Next 10 Years",
		periodList: ["Next 10 Years"],
		eclipseType: "all",
		eclipseTypeList: ["all"],
		monthNumm: 0,
		country: "All countries",
		countryName: "All countries",
		countryList: [{ countryName: "All countries" }],
		sunArticles: [],
		moonArticles: []
	};
	async componentDidMount() {
		const pageType = window.location.pathname.split("/")[1].split("-")[3];
		this.setState({
			pageType: pageType.charAt(0).toUpperCase() + pageType.slice(1),
			eclipseTypeList:
				pageType === "solar"
					? this.eclipseTypeSolar
					: this.eclipseTypeLunar
		});
		// this.opgPicture(pageType);
		const { eclipsesDataReducer, eclipsesCatelogNextEclipse } = this.props;
		const periodList = ["Next 10 Years"];
		for (let i = 1900; i < 2099; i += 10) {
			periodList.push(`${i} - ${i + 9}`);
		}
		const newPeriod = this.startPeriod(eclipsesDataReducer);
		const countryList = await Api.userCityList(`eclipses_catalog/`);
		const periodChanged = newPeriod
			.split(" ")
			.slice(1)
			.join("");
		const eclipseInfo = await Api.userCityList(
			`eclipses_catalog/0/${pageType.toLowerCase()}/?period=${periodChanged}&type=${
				this.state.eclipseType
			}`
		);
		const newCountryList = [{ countryName: "All countries" }].concat(
			countryList.data.results
		);

		const sunData = await Api.userCityList(
			`news/?limit=5&category=solar-eclipse`
		);
		const moonData = await Api.userCityList(
			`news/?limit=5&category=lunar-eclipse`
		);
		this.setState({
			eclipseData: eclipseInfo.data.catalog,
			periodList: periodList,
			countryList: newCountryList,
			sunArticles: sunData.data,
			moonArticles: moonData.data
		});
		eclipsesCatelogNextEclipse(eclipseInfo.data.next_visible_eclipse);
	}
	// componentDidUpdate(prevProps){
	//     console.log(prevProps)
	//     if(prevProps.planetaryHoursReducer !== this.props.planetaryHoursReducer) this.opgPicture();
	// }
	// async opgPicture(pageType) {
	// 	let opgPicture;
	// 	if (pageType === "solar") {
	// 		opgPicture = await Api.userCityList(`opg/eclipse-page-2/get-url/`);
	// 	} else {
	// 		opgPicture = await Api.userCityList(`opg/eclipse-page-7/get-url/`);
	// 	}
	// 	this.setState({ opgPicture: opgPicture.data.url });
	// }
	startPeriod() {
		const time = moment()
			.tz("Greenwich")
			.get("year");
		return `${time} - ${time + 9}`;
	}
	periodChange = (event, index, value) => {
		this.setState({ period: value });
	};
	typeChange = (event, index, value) => {
		this.setState({ eclipseType: value });
	};
	countryChange = (event, index, value) => {
		this.setState({ country: { countryName: value } });
	};
	async newEclipseList() {
		const { eclipsesDataReducer, eclipsesCatelogNextEclipse } = this.props;
		const { period, eclipseType, country } = this.state;
		const newCountry =
			country.countryName === undefined ? "0" : country.countryName;
		const type = this.state.pageType.toLowerCase();
		const newPeriod =
			period === "Next 10 Years"
				? this.startPeriod(eclipsesDataReducer)
				: period;
		const periodChanged =
			period === "Next 10 Years"
				? newPeriod
						.split(" ")
						.slice(1)
						.join("")
				: newPeriod.replace(/ /g, "");
		const eclipseInfo = await Api.userCityList(
			`eclipses_catalog/${newCountry}/${type}/?period=${periodChanged}&type=${eclipseType}`
		);
		await this.setState({
			eclipseData: eclipseInfo.data.catalog,
			countryName:
				newCountry !== "0"
					? this.state.countryList.filter(elem => elem.geonameId === newCountry)[0].countryName
					: "All countries",
			periodTitle: period
		});
		eclipsesCatelogNextEclipse(eclipseInfo.data.next_visible_eclipse);
	}
	moonRisePositionNS(val) {
		return val < 0
			? `${val * -1}${String.fromCharCode(176)}S`
			: `${val}${String.fromCharCode(176)}N`;
	}
	moonRisePositionEW(val) {
		return val < 0
			? `${val * -1}${String.fromCharCode(176)}W`
			: `${val}${String.fromCharCode(176)}E`;
	}
	pictureLunar(elem) {
		const { pageType } = this.state;
		const date = moment(elem.calendar_date).format("YYYYMMMDD");
		const format = elem.eclipse_type.title.slice(0, 1);
		return pageType === "Solar"
			? `SE${date}${format}`
			: `LE${date}${format}`;
	}
	cityEclipseData() {
		const { pageType } = this.state;
		const time = moment()
			.tz("Greenwich")
			.get("year");
		const periodTitle =
			this.state.periodTitle === "Next 10 Years"
				? `${time} - ${time + 9}`
				: this.state.periodTitle;

		if (this.state.countryName === "All countries") {
			return `Catalog of all ${pageType} Eclipses Worldwide (${periodTitle})`;
		} else
			return `Catalog of all ${pageType} Eclipses in ${this.state.countryName} (${periodTitle})`;
	}
	geotimedateTable() {
		const tableLength = this.state.eclipseData.length * 55 + 29 + 30;
		const bodyHeight = document.body.offsetHeight;
		if (document.body.offsetWidth > 1199) return tableLength;
		else return bodyHeight > tableLength ? tableLength : bodyHeight;
	}
	selectedCityEclipse() {
		const { eclipsesDataReducer } = this.props;
		const name = eclipsesDataReducer.name;
		const state =
			eclipsesDataReducer.country_name === "United States"
				? eclipsesDataReducer.state_code
				: eclipsesDataReducer.state;
		const country =
			eclipsesDataReducer.country_name === "United States"
				? eclipsesDataReducer.country_name_abbr
				: eclipsesDataReducer.country_name;
		return `${name}, ${state}, ${country}`;
	}
	cityNameHome(city) {
		const name = city.name;
		const country =
			city.country_name === "United States"
				? city.country_name_abbr
				: city.country_name;
		const state =
			city.country_name === "United States" ? `${city.state}, ` : "";
		return `${name}, ${state} ${country}`;
	}
	solarLunarLinks() {
		const { pageType } = this.state;
		if (pageType === "Solar") {
			return (
				<div className="eclipse-info">
					<h5 className="eclipse-info-header">About Solar Eclipse</h5>
					{this.state.sunArticles.map((elem, index) => (
						<div className="eclipse-info-block" key={index}>
							<b>
								<Link to={`/articles/sun/${elem.slug}`}>
									{elem.title}
								</Link>
							</b>
						</div>
					))}
					<b>
						<Link to={"/articles#sun"}>...more</Link>
					</b>
				</div>
			);
		} else {
			return (
				<div className="eclipse-info">
					<h5 className="eclipse-info-header">About Lunar Eclipse</h5>
					{this.state.moonArticles.map((elem, index) => (
						<div className="eclipse-info-block" key={index}>
							<b>
								<Link to={`/articles/moon/${elem.slug}`}>
									{elem.title}
								</Link>
							</b>
						</div>
					))}
					<b>
						<Link to={"/articles#moon"}>...more</Link>
					</b>
				</div>
			);
		}
	}
	solarLunarTableTitle() {
		const { pageType } = this.state;
		if (pageType === "Solar") {
			return (
				<Row>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
				</Row>
			);
		} else {
			return (
				<Row>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
					<Cell></Cell>
				</Row>
			);
		}
	}
	tablePicture(elem) {
		const { pageType } = this.state;
		if (pageType === "Solar") {
			return (
				<b>
					<img
						src={`/${elem.picture_url}`}
						alt="geotimedate"
					/>
				</b>
			);
		} else {
			return (
				<b>
					<img src={`/${elem.map_url}`} alt="geotimedate" />
				</b>
			);
		}
	}
	cityUrlPage(type, city) {
		const id = city.url.replace("place/", "");
		const date = moment()
			.tz(city.timezone)
			.format("YYYY-MM-DD");
		return `/${type}-eclipse/${id}/${date}#city_table`;
	}
	metaTegsBlock() {
		if (this.state.pageType === "Solar") {
			return (
				<MetaTags>
					<title>
						See catalog of all Solar Eclipses Worldwide from
						1900-2099
					</title>
					{/*<meta*/}
					{/*	name="description"*/}
					{/*	content={`See catalog of all Solar Eclipses Worldwide from 1900-2099 with eclipse path. Find a list of all Solar Eclipses for your country with a countdown to the next Solar Eclipse.`}*/}
					{/*/>*/}
					{/*<meta*/}
					{/*	name="keywords"*/}
					{/*	content={`solar, eclipse, eclipses, sun, total eclipse, partial eclipse, annular eclipse, occultation`}*/}
					{/*/>*/}
					{/*{this.state.opgPicture ? (*/}
					{/*	<meta*/}
					{/*		property="og:image"*/}
					{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
					{/*	/>*/}
					{/*) : null}*/}
				</MetaTags>
			);
		} else {
			return (
				<MetaTags>
					<title>
						See catalog of all Lunar Eclipses Worldwide from
						1900-2099
					</title>
					{/*<meta*/}
					{/*	name="description"*/}
					{/*	content={`See catalog of all Lunar Eclipses Worldwide from 1900-2099 with eclipse path. Find a list of all Lunar Eclipses for your country with a countdown to the next Lunar Eclipse.`}*/}
					{/*/>*/}
					{/*<meta*/}
					{/*	name="keywords"*/}
					{/*	content={`lunar, eclipse, eclipses, moon, total eclipse, partial eclipse, penumbral eclipse, occultation`}*/}
					{/*/>*/}
					{/*{this.state.opgPicture ? (*/}
					{/*	<meta*/}
					{/*		property="og:image"*/}
					{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
					{/*	/>*/}
					{/*) : null}*/}
				</MetaTags>
			);
		}
	}
	render() {
		const { currentSearchReducer } = this.props;
		if (!this.state.eclipseData || !this.state.pageType)
			return (
				<div className="SunCatalogEclipses">
					{this.metaTegsBlock()}
					<Loader />
				</div>
			);
		// if(!this.state.eclipseData.length || !this.state.pageType) return <div className='SunCatalogEclipses'><Loader/></div>;
		// if(!this.state.eclipseData) return <div className='CatalogTableEclipses'><Loader/></div>;
		const { pageType } = this.state;
		const linkType = pageType.toLowerCase();
		const city = localStorage.getItem("mainSearchCity")
			? JSON.parse(localStorage.getItem("mainSearchCity"))
			: currentSearchReducer;
		return (
			<div className="SunCatalogEclipses">
				{this.metaTegsBlock()}
				<div className="serarch-block">
					<b>
						See the catalog of all {pageType} Eclipses (1900 - 2099)
						for
					</b>
					<Search />
				</div>

				<section className="eclipsesSolarLunar eclipsesCitySolarLunar">
					<div className="eclipsesSolarLunarInfo">
						<div className="sunCulcCityInfoRightSide">
							<div className="CityTableEclipses CatalogTableEclipses">
								<div className="catalog-title-block">
									<div className="eclipse_flag">
										<img
											className="cityMainImg"
											src={globe}
											alt="geotimedate"
										/>
									</div>
									<div className="catalogEclipses">
										<div
											className="citySunRiseSet"
											style={{
												background:
													pageType === "Solar"
														? "#fff386"
														: "#aee5fa"
											}}
										>
											<div className="country_flag">
												<img
													className="cityMainImg"
													src={globe}
													alt="geotimedate"
												/>
											</div>
											<div className="city_name_date">
												<h3>
													{this.cityEclipseData()}
												</h3>
											</div>
										</div>
										<div className="month-year-change">
											<MuiThemeProvider>
												<SelectField
													className="sun-country-select"
													value={
														this.state.country
															.countryName
													}
													onChange={
														this.countryChange
													}
													maxHeight={200}
												>
													{this.state.countryList.map(
														(elem, index) => (
															<MenuItem
																value={
																	elem.geonameId
																}
																key={index}
																primaryText={
																	elem.countryName
																}
															/>
														)
													)}
												</SelectField>
											</MuiThemeProvider>
											<MuiThemeProvider>
												<SelectField
													className="sun-year-select"
													value={this.state.period}
													onChange={this.periodChange}
													maxHeight={200}
												>
													{this.state.periodList.map(
														(elem, index) => (
															<MenuItem
																value={elem}
																key={index}
																primaryText={
																	elem
																}
															/>
														)
													)}
												</SelectField>
											</MuiThemeProvider>
											<MuiThemeProvider>
												<SelectField
													className="sun-month-select"
													value={
														this.state.eclipseType
													}
													onChange={this.typeChange}
													maxHeight={200}
												>
													{this.state.eclipseTypeList.map(
														(elem, index) => (
															<MenuItem
																value={elem}
																key={index}
																primaryText={
																	elem
																}
															/>
														)
													)}
												</SelectField>
											</MuiThemeProvider>
											<button
												className="month-chnge"
												onClick={this.newEclipseList.bind(
													this
												)}
											>
												Go
											</button>
										</div>
									</div>
								</div>
								<div className="SunData">
									<div className="eclipse-content">
										<div
											className="geotimedate-table eclipse-table"
											style={{
												height:
													this.geotimedateTable() +
													"px"
											}}
										>
											<StickyTable
												stickyColumnCount={1}
												stickyHeaderCount={1}
											>
												<Row>
													{(pageType === "Solar"
														? this.eclipseTableSun
														: this.eclipseTableMoon
													).map((elem, index) => (
														<Cell
															key={index}
															style={{
																background:
																	pageType ===
																	"Solar"
																		? "#fff386"
																		: "#aee5fa"
															}}
														>
															<div className="cell-wrapper">
																<b>{elem}</b>
															</div>
														</Cell>
													))}
												</Row>
												{this.solarLunarTableTitle()}
												{this.state.eclipseData.map(
													(elem, index) => (
														<Row key={index}>
															<Cell>
																<b className="moon-event-block">
																	<Link
																		to={`/${linkType}-eclipse-country/${moment(
																			elem.calendar_date
																		)
																			.tz(
																				"Greenwich"
																			)
																			.format(
																				"YYYY-MM-DD"
																			)}`}
																	>
																		{moment(
																			elem.calendar_date
																		)
																			.tz(
																				"Greenwich"
																			)
																			.format(
																				"ll"
																			)}
																	</Link>
																</b>
															</Cell>
															<Cell>
																<p>
																	{moment(
																		elem.calendar_date
																	)
																		.tz(
																			"Greenwich"
																		)
																		.format(
																			"LT"
																		)}
																</p>
															</Cell>
															<Cell>
																<b>
																	{
																		elem
																			.eclipse_type
																			.title
																	}
																</b>
															</Cell>
															<Cell>
																<b>
																	{
																		elem.saros_number
																	}
																</b>
															</Cell>
															<Cell>
																<b>
																	{(+elem.eclipse_magnitude).toFixed(
																		3
																	)}
																</b>
															</Cell>
															<Cell
																style={{
																	display:
																		pageType ===
																		"Solar"
																			? "table-cell"
																			: "none"
																}}
															>
																<b>
																	{this.moonRisePositionNS(
																		+elem.latitude
																	)}
																</b>
															</Cell>
															<Cell
																style={{
																	display:
																		pageType ===
																		"Solar"
																			? "table-cell"
																			: "none"
																}}
															>
																<b>
																	{this.moonRisePositionEW(
																		+elem.longitude
																	)}
																</b>
															</Cell>
															<Cell>
																<p>
																	{
																		elem
																			.zodiac_position[0]
																			.body_pos_deg
																	}
																	{
																		elem
																			.zodiac_position[0]
																			.body_pos_min
																	}
																</p>
																<p>
																	{
																		elem
																			.zodiac_position[0]
																			.zodiac_name
																	}
																</p>
															</Cell>
															<Cell>
																<p>
																	{
																		elem
																			.zodiac_position[1]
																			.body_pos_deg
																	}
																	{
																		elem
																			.zodiac_position[1]
																			.body_pos_min
																	}
																</p>
																<p>
																	{
																		elem
																			.zodiac_position[1]
																			.zodiac_name
																	}
																</p>
															</Cell>
															<Cell
																className="picture-lunar"
																style={{
																	width:
																		"70px"
																}}
															>
																{this.tablePicture(
																	elem
																)}
															</Cell>
														</Row>
													)
												)}
											</StickyTable>
										</div>
										<Link
											to={this.cityUrlPage("solar", city)}
										>
											See catalog of all Solar Eclipses
											(1900 - 2099) for{" "}
											{this.cityNameHome(city)}
										</Link>
										<Link
											to={this.cityUrlPage("lunar", city)}
										>
											See catalog of all Lunar Eclipses
											(1900 - 2099) for{" "}
											{this.cityNameHome(city)}
										</Link>
										<div className="advertising-block-main">
											<img
												src={advertisingLong}
												className="advertising"
												alt="advertising"
											/>
										</div>
									</div>
									<div className="eclipses-img">
										<Countdown pageType={pageType} />
										<div className="sunCulcCityInfoRightSide">
											<div className="eclipse-info">
												<h5 className="eclipse-info-header">
													More information
													<br />
													for{" "}
													{this.cityNameHome(city)}
												</h5>
												<div className="eclipse-info-block">
													<b>
														<Link
															to={`/sun${city.url.replace(
																"place",
																""
															)}`}
														>
															Sunrise and Sunset
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link
															to={`/moon${city.url.replace(
																"place",
																""
															)}`}
														>
															Moonrise and Moonset
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link to="/">
															Time Planer
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link to="/#ephemeris-for-today">
															Ephemeris for Today
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link
															to={`/#planet-stations-retrogrades`}
														>
															Planet Stations and
															Retrogrades
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link
															to={`/#planet-in-signs`}
														>
															Planet in Signs
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link
															to={`/planetary-hours${city.url.replace(
																"place",
																""
															)}`}
														>
															Planetary Hours
														</Link>
													</b>
												</div>
												<div className="eclipse-info-block">
													<b>
														<Link to="/#transits">
															Transits
														</Link>
													</b>
												</div>
											</div>
										</div>
										<div className="advertising-block-main">
											<img
												src={advertising}
												className="advertising"
												alt="advertising"
											/>
										</div>
										<div className="sunCulcCityInfoRightSide">
											{this.solarLunarLinks()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentSearchReducer: state.currentSearchReducer,
		eclipsesDataReducer: state.eclipsesDataReducer,
		eclipsesCatelogNextEclipseReducer:
			state.eclipsesCatelogNextEclipseReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		currentSearch: bindActionCreators(currentSearch, dispatch),
		eclipsesData: bindActionCreators(eclipsesData, dispatch),
		eclipsesCatelogNextEclipse: bindActionCreators(
			eclipsesCatelogNextEclipse,
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SunCatalogEclipses);
