import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Drawer from "material-ui/Drawer";

import "./mobile-menu.css";

class mobileMenu extends Component {
  state = {
    currentLocation: window.location.pathname
  };

  componentDidMount() {
    this.props.history.listen((location, action) => {
      if (
        this.props.isOpen &&
        this.state.currentLocation !== location.pathname
      ) {
        this.props.handleToggle();
      }
    });
  }

  render() {
    const {
      isLoggedIn,
      handleToggle,
      isOpen,
      url,
      authorizationReducer,
      currentSearchReducer,
      signOut
    } = this.props;
    const auth = authorizationReducer;

    return (
      <div className="mobile-menu">
        <Drawer open={isOpen} docked={false} onRequestChange={handleToggle}>
          <div className="top-menu">
            {auth.first_name || isLoggedIn ? (
              <div>
                <p className="auth-title">
                  Hi, {auth.first_name || JSON.parse(isLoggedIn).first_name}
                </p>
                <div>
                  <Link to="/account">My Account</Link>
                  <p onClick={signOut}>Sign Out</p>
                </div>
              </div>
            ) : (
              <div>
                <Link to="/signin">Sign in</Link>
                <Link to="/signup">Register</Link>
              </div>
            )}
          </div>

          <div className="bottom-menu">
            <div className="bottom-menu-wrapper">
              <Link to="/" className="menu-item-title">
                Home
              </Link>
              <Link to="/meeting-planner" className="menu-item-title">
                Meeting Planner
              </Link>
            </div>

            <div className="bottom-menu-wrapper">
              <ul>
                <p className="menu-item-title">Almanac</p>
                <li>
                  <Link to={`/sun/${url}`}>Sun</Link>
                </li>
                <li>
                  <Link to={`/moon/${url}`}>Moon</Link>
                </li>
                <li>
                  <Link to="/solar-lunar-eclipses/world">Eclipses</Link>
                </li>
                <li className="sub-menu-action">
                  <div className="sub-menu__title">
                    <p>Planet Retrogrades</p>
                    <i className="material-icons">keyboard_arrow_down</i>
                  </div>
                  <ul className="sub-menu">
                    <li>
                      <Link to={`/Mercury-retrograde/${url}`}>
                        Mercury Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Venus-retrograde/${url}`}>
                        Venus Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Mars-retrograde/${url}`}>
                        Mars Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Jupiter-retrograde/${url}`}>
                        Jupiter Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Saturn-retrograde/${url}`}>
                        Saturn Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Uranus-retrograde/${url}`}>
                        Uranus Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Neptune-retrograde/${url}`}>
                        Neptune Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Pluto-retrograde/${url}`}>
                        Pluto Retrograde
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Chiron-retrograde/${url}`}>
                        Chiron Retrograde
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={`/planetary-hours/${url}`}>Planetary Hours</Link>
                </li>
                <li className="sub-menu-action">
                  <div className="sub-menu__title">
                    <p>Planet in Signs</p>
                    <i className="material-icons">keyboard_arrow_down</i>
                  </div>
                  <ul className="sub-menu">
                    <li>
                      <Link to={`/Mercury-in-signs/${url}`}>
                        Mercury in signs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Venus-in-signs/${url}`}>Venus in signs</Link>
                    </li>
                    <li>
                      <Link to={`/Mars-in-signs/${url}`}>Mars in signs</Link>
                    </li>
                    <li>
                      <Link to={`/Jupiter-in-signs/${url}`}>
                        Jupiter in signs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Saturn-in-signs/${url}`}>
                        Saturn in signs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Uranus-in-signs/${url}`}>
                        Uranus in signs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Neptune-in-signs/${url}`}>
                        Neptune in signs
                      </Link>
                    </li>
                    <li>
                      <Link to={`/Pluto-in-signs/${url}`}>Pluto in signs</Link>
                    </li>
                    <li>
                      <Link to={`/Chiron-in-signs/${url}`}>
                        Chiron in signs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={`/panchang/${url}`}>Panchang</Link>
                </li>
                <li>
                  <Link to="/articles">Articles</Link>
                </li>
              </ul>
            </div>

            <div className="bottom-menu-wrapper">
              <ul>
                <li>
                  <p className="menu-item-title">Time zones</p>
                </li>
                <li>
                  <Link to="/world-clock">World Clock</Link>
                </li>
                <li>
                  <Link to="/unix-time-converter">Time Converters</Link>
                </li>
                <li>
                  <Link to="/timezones">Worldwide Time Zones</Link>
                </li>
                <li>
                  <Link
                    to={`/trading-hours-of-world-stock-exchanges-${"local"}-time-type0/${
                      currentSearchReducer.id
                    }`}
                  >
                    Stock Exchanges
                  </Link>
                </li>
                <li className="sub-menu-action">
                  <div className="sub-menu__title">
                    <p>Calculators</p>
                    <i className="material-icons">keyboard_arrow_down</i>
                  </div>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/date-to-date-calculator">Date Calculator</Link>
                    </li>
                    <li>
                      <Link to="/business-date-to-date-calculator">
                        Business Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to="/flight-time-calculator">
                        Flight Time Calculator
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/* <div className="bottom-menu-wrapper">
              <Link
                to="/premium"
                className="menu-item-title menu-item-title_single"
              >
                Pricing
              </Link>
            </div> */}
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authorizationReducer: state.authorizationReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};

// export default connect(mapStateToProps)(mobileMenu);
export default withRouter(connect(mapStateToProps)(mobileMenu));
