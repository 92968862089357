import Api from "../services/api.js";

export const stockExchangesList = value => {
	return dispatch => {
		Api.getCache(Api.simpleRequest, `stockexchanges/${value}`).then(
			data => {
				dispatch({
					type: "ADD_STOCK_EXCHANGE_LIST",
					data: data.data
				});
			}
		);
	};
};

export const stockExchangeSingle = (nameStock, idMainCity) => {
	return dispatch => {
		Api.userCityList(`stockexchanges-single/?stock_name=${nameStock}`).then(
			data => {
				dispatch({
					type: "ADD_STOCK_EXCHANGE_SINGLE",
					data: data.data
				});
			}
		);
	};
};
