import moment from "moment-timezone/index.js";

const sunRiseDateReducer = (state = moment().format('YYYY-MM-DDTHH:mm:ss'), action) => {
  switch (action.type) {
    case 'SUN_RISE_DATE':
      return action.data;
    default:
      return state;
  }
};
  
export default sunRiseDateReducer;