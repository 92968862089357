
const airportsListReducer = (state = [], action) => {
  switch (action.type) {
    case 'AIRPORTS':
      return action.data;
    default:
      return state;
  }
};
  
export default airportsListReducer;