import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Checkbox from "material-ui/Checkbox";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import MetaTags from "react-meta-tags";

import "./WorldClock.css";

import Loader from "../../../components/Main/Loader/Loader";
import advertising from "../../../assets/sun_page/970-90.jpg";
import dst from "../../../assets/dst.png";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";

import Api from "../../../services/api.js";
import { DstIcon, DstIconMassage } from "../../shared/Common";

class WorldClock extends Component {
  sortList = ["City", "Country"];
  checkboxStyles = {
    color: "#fff"
  };
  state = {
    popularCities: true,
    capitals: false,
    sortBy: "City",
    worldList: []
  };
  async componentDidMount() {
    const place =
      this.state.popularCities === true ? "populated-place" : "capitals";
    const order = this.state.sortBy === "City" ? "city" : "country";
    const data = await Api.userCityList(`geo_names/${place}/${order}/`);
    this.setState({ worldList: data.data.data });
    // this.opgPicture();
  }
  // async opgPicture() {
  //   const opgPicture = await Api.userCityList(`opg/world-clock/get-url/`);
  //   this.setState({ opgPicture: opgPicture.data.url });
  // }
  worldListArr() {
    const { worldList, sortBy } = this.state;
    let newWorldList;
    switch (sortBy) {
      case "City":
        newWorldList = worldList.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        break;
      case "Country":
        newWorldList = worldList.sort((a, b) => {
          if (a.country_name > b.country_name) {
            return 1;
          }
          if (a.country_name < b.country_name) {
            return -1;
          }
          return 0;
        });
        break;
      default:
        newWorldList = this.state.worldList;
        break;
    }

    let firstArr = [];
    let secondArr = [];
    let thirdArr = [];
    if (document.body.offsetWidth > 1199) {
      const third = newWorldList.length / 3;
      const addElem = newWorldList.length % 3 === 1 ? 1 : 0;
      firstArr = newWorldList.slice(0, third + addElem);
      secondArr = newWorldList.slice(third + addElem, (third + addElem) * 2);
      thirdArr = newWorldList.slice((third + addElem) * 2);
    } else if (document.body.offsetWidth > 769) {
      const third = newWorldList.length / 2;
      const addElem = newWorldList.length % 2 === 1 ? 1 : 0;
      firstArr = newWorldList.slice(0, third + addElem);
      secondArr = newWorldList.slice(third + addElem);
    } else firstArr = newWorldList;

    return [
      {
        numm: "first",
        arr: firstArr
      },
      {
        numm: "second",
        arr: secondArr
      },
      {
        numm: "third",
        arr: thirdArr
      }
    ];
  }
  plaseOfWorld() {
    return this.state.popularCities === true ? "Popular Cities" : "Capitals";
  }
  popularCitiesCheck() {
    this.setState({
      popularCities: true,
      capitals: false
    });
    this.worldListGet("populated-place");
  }
  capitalsCheck() {
    this.setState({
      capitals: true,
      popularCities: false
    });
    this.worldListGet("capitals");
  }
  async worldListGet(place) {
    const order = this.state.sortBy === "City" ? "city" : "country";
    const data = await Api.userCityList(`geo_names/${place}/${order}/`);
    this.setState({ worldList: data.data.data });
  }
  sortChange = (event, index, value) => {
    this.setState({ sortBy: value });
  };
  placeShowStyle(worldElem) {
    if (worldElem.numm === "second" && !worldElem.arr.length)
      return { display: "none" };
    if (worldElem.numm === "third" && !worldElem.arr.length)
      return { display: "none" };
    else return {};
  }
  async mainPageCity(elem) {
    sessionStorage.removeItem("sessionParam");
    const { currentSearch } = this.props;
    currentSearch({});
    const data = await Api.userCityList(`geo_names/${elem.geonameId}/`);
    currentSearch(data.data);
  }
  placeShow(worldElem, elem, index) {
    if (worldElem.numm === "second" && !worldElem.arr.length) return "";
    else if (worldElem.numm === "third" && !worldElem.arr.length) return "";
    const tz = elem.timezone;
    const name =
      this.state.sortBy === "City"
        ? `${elem.name} - ${elem.country_name}`
        : `${elem.country_name} - ${elem.name}`;
    let img;
    if (elem.dst_change.date) {
      img = (
        <div className="imgBlock">
          <img src={dst} alt="geotimedate" />
          <div>
            <DstIconMassage city={elem} dst_data={elem.dst_change} />
          </div>
        </div>
      );
    } else img = "";
    return (
      <div key={index}>
        <Link
          to={`/time-in/${elem.url.replace("place/", "")}`}
          title={name}
          onClick={this.mainPageCity.bind(this, elem)}
        >
          {name}
        </Link>
        <span>
          {img}{" "}
          {moment(elem.dt)
            .tz(tz)
            .format("MMM DD")}
          &nbsp;&nbsp;
          {moment(elem.dt)
            .tz(tz)
            .format("LT")}
        </span>
      </div>
    );
  }
  meta = () => {
    return (
      <MetaTags>
        <title>
          World Clock – Current local time and date in cities and capitals of
          the world adjusted for DST
        </title>
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content={`Know what time and date is it in popular cities and capitals of the world adjusted for DST across time zones.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content={`world clock, local time, what time is it in, time in, DST, daylight savings time, popular cities, capitals of the world, current time in, clock, time zone, time now`}*/}
        {/*/>*/}
        {/*{this.state.opgPicture ? (*/}
        {/*  <meta*/}
        {/*    property="og:image"*/}
        {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
        {/*  />*/}
        {/*) : null}*/}
      </MetaTags>
    );
  };

  render() {
    if (!this.state.worldList.length) {
      return (
        <div className="WorldClock">
          {this.meta()}
          <Loader />
        </div>
      );
    }
    return (
      <div className="WorldClock">
        {this.meta()}
        <div className="world-clock-header">
          <h1>World Clock</h1>
          <div className="select-block">
            <h3>
              Current Local Time and Date in {this.plaseOfWorld()} of the World
            </h3>
            <div className="check-block">
              <MuiThemeProvider>
                <Checkbox
                  label="Popular Cities"
                  checked={this.state.popularCities}
                  onCheck={this.popularCitiesCheck.bind(this)}
                  style={this.checkboxStyles}
                  className="world-clock-checkbox"
                />
              </MuiThemeProvider>
              <MuiThemeProvider>
                <Checkbox
                  label="Capitals"
                  checked={this.state.capitals}
                  onCheck={this.capitalsCheck.bind(this)}
                  style={this.checkboxStyles}
                  className="world-clock-checkbox"
                />
              </MuiThemeProvider>
              <MuiThemeProvider>
                <SelectField
                  className="sort-list"
                  value={this.state.sortBy}
                  onChange={this.sortChange}
                >
                  {this.sortList.map((elem, index) => (
                    <MenuItem value={elem} key={index} primaryText={elem} />
                  ))}
                </SelectField>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
        <div className="world-list">
          {this.worldListArr().map((worldElem, worldIndex) => (
            <div
              className={`${worldElem.numm}-block`}
              key={worldIndex}
              style={this.placeShowStyle(worldElem)}
            >
              {worldElem.arr.map((elem, index) =>
                this.placeShow(worldElem, elem, index)
              )}
            </div>
          ))}
        </div>
        <div className="adjasted-dst">
          <img src={dst} alt="geotimedate" />
          <span>Adjusted for DST</span>
        </div>
        <div className="advertising-block-main">
          <img src={advertising} className="advertising" alt="advertising" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldClock);
