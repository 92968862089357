import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";

import eclipsesData from "../../../../../../actions/action_eclipsesData";

class EclipseInfo extends React.Component {
	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}
	emptyState(state, abr) {
		if (isNaN(+abr) && abr !== null) return `${abr}, `;
		else if (state !== null) return `${state}, `;
		else return "";
	}
	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}
	sunRisePositionNS(value) {
		if (value > 0) return "N";
		else if (value < 0) return "S";
		else if (value === 0) return "";
	}
	sunRisePositionEW(value) {
		if (value > 0) return "E";
		else if (value < 0) return "W";
		else if (value === 0) return "";
	}

	async eclipseInfoGet() {
		const { sunCalcReducer, eclipsesData } = this.props;
		localStorage.removeItem("eclipses");
		eclipsesData({
			id: sunCalcReducer.id,
			timezone: sunCalcReducer.timezone
		});
	}

	nextVisibleEclipse() {
		const { sunCalcReducer } = this.props;
		if (sunCalcReducer.next_visible_eclipse) {
			const eclipse = sunCalcReducer.next_visible_eclipse;
			return (
				eclipse.eclipse_type[0] +
				" " +
				moment(eclipse.next_solar_eclipse)
					.tz(sunCalcReducer.timezone)
					.format("ll")
			);
		} else return "n/a";
	}

	render() {
		const { sunCalcReducer } = this.props;

		const url = sunCalcReducer.url
			.split("/")
			.slice(1)
			.join("/");
		const dateEclipse = moment(
			sunCalcReducer.next_visible_eclipse.next_solar_eclipse
		)
			.tz(sunCalcReducer.timezone)
			.format("YYYY-MM-DD");

		return (
			<div className="eclipse-info">
				<h5 className="eclipse-header">
					{this.emptyName(sunCalcReducer.name)}
					{this.emptyState(
						sunCalcReducer.state,
						sunCalcReducer.state_code
					)}
					{this.emptyAbr(
						sunCalcReducer.country_name_abbr,
						sunCalcReducer.country_name
					)}
				</h5>
				<div className="eclipse-info-block">
					<b>Latitude:</b>
					<b>
						{Math.abs(sunCalcReducer.latitude.toFixed(2))}
						&deg; {this.sunRisePositionNS(sunCalcReducer.latitude)}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Longitude:</b>
					<b>
						{Math.abs(sunCalcReducer.longitude.toFixed(2))}
						&deg; {this.sunRisePositionEW(sunCalcReducer.longitude)}
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Time Zone:</b>
					<b>
						{sunCalcReducer.timezone.replace(/\//g, " / ")},{" "}
						{sunCalcReducer.abbr}
					</b>
				</div>
				<div className="eclipse-info-block">
					<Link
						to={`/solar-eclipse/${url}/${dateEclipse}`}
						onClick={this.eclipseInfoGet.bind(this)}
					>
						<b>Next Solar Eclipse:</b>
						<b>{this.nextVisibleEclipse()}</b>
					</Link>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		eclipsesData: bindActionCreators(eclipsesData, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EclipseInfo);
