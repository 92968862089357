import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone/index.js';

// import './Calculator.css';
import currentSearch from '../../../../../actions/action_searchCity/action_currentSearch';
import {bindActionCreators} from "redux";
// import julian from "julian";
// import gpsTime from "gps-time";



class ShowCurentDate extends Component {

    state={
        time: moment(),
    }

    currentTime = setInterval(() => {
        const { timezone } = this.props;

        let a = new Date(+Date.now());
        let test = new Date(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate(), a.getUTCHours(), a.getUTCMinutes(), a.getUTCSeconds());
        let time = timezone ? moment(test).tz(timezone) : moment();

        !this.isCancelled && this.setState(prevState => {
            return {
                time,
            };
        });
    }, 1000);

     componentWillUnmount(){
        window.clearTimeout(this.currentTime);
        this.isCancelled = true;
    }

     timeConverter(UNIX_timestamp, CurrentDateTime){
        let aa = CurrentDateTime === 'CurrentDateTime' ? new Date(UNIX_timestamp) : new Date(UNIX_timestamp*1000);
        // const { offset} = this.props.currentSearchReducer;
        const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        const days = ['Sund', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

        let year = aa.getFullYear();
        let month = months[aa.getMonth()];
        let day = days[aa.getDay()];
        let date = aa.getDate();
        let dateFull = aa.getDate() < 10 ? '0'+aa.getDate() : aa.getDate();
        let hour = aa.getHours() < 10 ? '0'+aa.getHours() : aa.getHours();
        let min = aa.getMinutes() < 10 ? '0'+aa.getMinutes() : aa.getMinutes();
        let sec = aa.getSeconds() < 10 ? '0'+aa.getSeconds() : aa.getSeconds();
        let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        if(CurrentDateTime === 'CurrentDateTime'){
            time = `${day}, ${month} ${dateFull}, ${year} ${hour}:${min}:${sec}`;
            return time;
        }

    }

    render() {
        // const {timezone} = this.props;
        return (
            <div className='input input_your_curent'>
                <p><b>{this.timeConverter(this.state.time._d, 'CurrentDateTime')}</b><span></span></p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCurentDate);