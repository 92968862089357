import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import Meta from "./Meta";
import Search from "./Search/Search";
// import TopBlockCities from "./TopBlockCities/TopBlockCities";
import InformationBlocks from "./InformationBlocks/InformationBlocks";
import currentCityInfo from "../../../actions/action_currentCity/action_currentCityInfo";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import defaultCityInfo from "../../../actions/action_currentCity/action_defaultCityInfo";
import airportsList from "../../../actions/action_airportsList";
import holidaysBlock from "../../../actions/action_holidaysBlock";

import Api from "../../../services/api.js";
import selectedCities, {
  setCurrentLocation
} from "../../../actions/action_selectedCities";
import "./MainPage.css";

class MainPage extends Component {
  state = {
    // opgPicture: ""
  };

  async componentDidMount() {
    await Api.getLocatoinJSON().then(data => {
      const locationData = JSON.parse(data.data);
      if (locationData.latitude && locationData.longitude && locationData.longitude !== 'nil') {
        this.currentCityGetInfo(locationData.latitude, locationData.longitude);
        this.props.currentCityInfo(locationData);
        this.props.defaultCityInfo(locationData);
      } else {
        /* New York by default */
        this.currentCityGetInfo(40.71424, -74.00594);
      }
    });

    /* TODO: geolocation detect old version) */
    // const locationError = error => this.currentCityGetInfo();
    // if (navigator.geolocation) {
    // 	if (this.props.currentSearchReducer) {
    // 		return null;
    // 	} else {
    // 		navigator.geolocation.getCurrentPosition(
    // 			pos => {
    // 				this.currentCityGetInfo(
    // 					pos.coords.latitude,
    // 					pos.coords.longitude
    // 				);
    // 			},
    // 			() => {
    // 				locationError("The Geolocation service failed");
    // 				// Default city is New Your
    // 				this.currentCityGetInfo(40.71424, -74.00594);
    // 			}
    // 		);
    // 	}
    // } else {
    // 	// Browser doesn't support Geolocation
    // 	locationError("Your browser doesn't support geolocation");
    // }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.currentSearchReducer !== this.props.currentSearchReducer)
  //     this.opgPicture();
  // }

  // async opgPicture() {
  //   const { currentSearchReducer } = this.props;
  //   if (currentSearchReducer.url) {
  //     const newId = currentSearchReducer.url
  //       .replace("place/", "")
  //       .replace(/\//g, "__");
  //     const opgPicture = await Api.getCache(
  //       Api.userCityList,
  //       `opg/homepage/get-url/?geo_name=${newId}`
  //     );
  //     this.setState({ opgPicture: opgPicture.data.url });
  //   }
  // }

  async currentCityGetInfo(lat, lng) {
    if (!(lat && lng)) {
      lat = 40.71424;
      lng = -74.00594;
    }

    const data = await Api.getCache(Api.currentCoordinars, lat, lng);
    const {
      currentCityInfo,
      defaultCityInfo,
      airportsList,
      holidaysBlock,
      currentSearch,
      setCurrentLocation
    } = this.props;
    const newCity = data.data.locations_list;

    const citySelect =
      newCity.reverse()[0] === undefined ? null : newCity.reverse()[0];
    const currentCityData = await Api.getCache(
      Api.userCityList,
      `geo_names/${citySelect.id}/`
    );

    citySelect.ColorSchemes = data.data.ColorSchemes;
    citySelect.ColorSchemesWeekend = data.data.ColorSchemesWeekend;
    citySelect.working_hours_start = data.data.working_hours_start;
    citySelect.working_hours_end = data.data.working_hours_end;
    citySelect.url = currentCityData.data.url;

    currentCityInfo(citySelect);
    defaultCityInfo(citySelect);
    if (
      !localStorage.getItem("searchCityInfo") &&
      window.location.pathname === "/"
    ) {
      const airportsData = await Api.getCache(
        Api.userCityList,
        `airports/${citySelect.id}/get_nearby/?dist=100`
      );
      const holidaysData = await Api.getCache(
        Api.userCityList,
        `holidays/?country=${citySelect.country_code}`
      );
      airportsList(airportsData.data);
      holidaysBlock(holidaysData.data);
      currentSearch(citySelect);
    }
    if (!sessionStorage.getItem("sessionParam")) {
      localStorage.setItem("mainSearchCity", JSON.stringify(citySelect));
    }
    this.currentSearchSetCity(citySelect);

    ////////////////////////////////////////////////////////
    setCurrentLocation(citySelect);
    ////////////////////////////////////////////////////////
  }

  async currentSearchSetCity() {
    const { currentSearch, currentSearchReducer } = this.props;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (window.location.pathname !== "/") {
      if (window.location.pathname.split("/")[1] === "time-in") {
        const pathname = window.location.pathname;
        const newUrl = pathname.replace("/time-in/", "").replace(/\//g, "__");
        const data = await Api.getCache(
          Api.userCityList,
          `geo_names/${newUrl}/`
        );
        cityInfoLoad(data.data);
      }
    } else if (
      localStorage.getItem("mainSearchCity") &&
      sessionStorage.getItem("sessionParam")
    ) {
      const data = await Api.getCache(
        Api.userCityList,
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else if (localStorage.getItem("searchCityInfo")) {
      const data = await Api.getCache(
        Api.userCityList,
        `geo_names/${JSON.parse(localStorage.getItem("searchCityInfo")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      const data = await Api.getCache(
        Api.userCityList,
        `geo_names/${currentSearchReducer.id}/`
      );
      cityInfoLoad(data.data);
    }
  }

  render() {
    return (
      <div className="MainPage">
        <Meta currentCity={this.props.currentSearchReducer} />
        {/* <TopBlockCities /> */}
        <div className="meeting-note">
          <span>
            Easy way to schedule meetings, phone calls, and webinars across
            multiple time zones. <Link to="/meeting-planner">Click here.</Link>
          </span>
        </div>
        <div className="search-block">
          <h4>Find Current Time, Weather, Sun, Moon, Almanac and more for</h4>
          <Search homePageAdvancedSearch />
        </div>
        <InformationBlocks />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentCityInfoReducer: state.currentCityInfoReducer,
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    selectedCities: bindActionCreators(selectedCities, dispatch),
    setCurrentLocation: bindActionCreators(setCurrentLocation, dispatch),
    currentCityInfo: bindActionCreators(currentCityInfo, dispatch),
    defaultCityInfo: bindActionCreators(defaultCityInfo, dispatch),
    currentSearch: bindActionCreators(currentSearch, dispatch),
    airportsList: bindActionCreators(airportsList, dispatch),
    holidaysBlock: bindActionCreators(holidaysBlock, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
