import Api from "./api";
import moment from "moment-timezone/index.js";
// const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');


export const timePlanerService = {
    getCitiesList,
    updateCitiesList,
    getUserCitiesList,
    deleteCity,
    setAsDefault,
    addMainCityToGroup,
};

function getCitiesList(date) {
    return Api.userCityList(`main_cities/?date=${moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS')}`);
}

function updateCitiesList(date, cities) {
    return Api.updateCityList(`main_cities/`, {
        date: moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
        cities
    });
}


function getUserCitiesList(token, date) {
    // console.log('moment(date).format()', moment(date).format());
    return Api.userInfo(`main-cities-reg/0/get/?date=${moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS')}`, token);
}

function deleteCity(city, token) {
    return Api.userCityDell(`main-cities-reg/${city.group_number}/delete/?city_id=${city.city_id}`, token);
}

function addMainCityToGroup(token, city, group, position) {
    return Api.changeInfo(`main-cities-reg-gr/`, token, [{
        act: 'save',
        geoname_id: `${city.id}`,
        group: `${group}`,
        sort_by: `${position}`
    },]);
}

function setAsDefault(city) {
    // const newList = cityList.map((elem, index) => {
    //         if (elem.city_id) {
    //             return {
    //                 act: 'upd',
    //                 city_id: `${elem.city_id}`,
    //                 geoname_id: `${elem.id}`,
    //                 group: `${this.state.groupNumm}`,
    //                 sort_by: `${index}`
    //             }
    //         } else {
    //             return {
    //                 act: 'save',
    //                 geoname_id: `${elem.id}`,
    //                 group: `${this.state.groupNumm}`,
    //                 sort_by: `${index}`
    //             }
    //         }
    //     });
    // return Api.changeInfo(`main-cities-reg-gr/`, token, [...newList, {
    //         act: 'get',
    //         group: `${this.state.groupNumm}`
    //     }]);
}
//
// function handleResponse(response) {
//     debugger
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 window.location.reload();
//             }
//
//             const error = (data && data.message) || (data && data.non_field_errors[0]) || response.statusText;
//
//             return Promise.reject(error);
//         }
// debugger
//         return data;
//     });
// }
