import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper, Set, Text, Path, Rect, Image } from "react-raphael";
import moment from 'moment-timezone/index.js';

import './RetrogradeRaphaelDraw.css';
import nextPlanetRetrograde from '../../../../../actions/action_nextPlanetRetrograde';

import {createMarkup} from "../../../../shared/Common";

class RetrogradeRaphaelDraw extends Component {
    state = {
        degreeRetroFromTable: {},
        retrogradeElem: {},
        degreeZodiacSignFromTable: {},
    }
    nextRetrogradeTitle(){
        const { currentSearchReducer, planetesRetrogradeReducer } = this.props;
            const tz = currentSearchReducer.timezone;
            if(planetesRetrogradeReducer.current_zodiac){
            const date = moment().tz(tz).format('ll');
            const planet = planetesRetrogradeReducer.planet;
            const deg = planetesRetrogradeReducer.current_zodiac.body_pos_deg;
            const min = planetesRetrogradeReducer.current_zodiac.body_pos_min;
            const zodiac_name = planetesRetrogradeReducer.current_zodiac.zodiac_name;
            const retro = planetesRetrogradeReducer.current_zodiac.retrograde === 'R' ? 'Retrograde' : 'Direct';
            return `${planet} Today: ${date}, ${deg} ${zodiac_name} ${min} (${retro})`;
        } else return '';
    }
    degreesOfScale(param){
        const { nextPlanetRetrogradeReducer } = this.props;
        const directDeg = nextPlanetRetrogradeReducer.retroList.direct_degree;
        const retroDeg = nextPlanetRetrogradeReducer.retroList.retro_degree;
        const start = param ? Math.floor(directDeg) : Math.round(directDeg);
        const end = param ? Math.floor(retroDeg) : Math.round(retroDeg);        
        const firstList = [];
        const secondList = [];
        if(nextPlanetRetrogradeReducer.retroList.direct_sign === nextPlanetRetrogradeReducer.retroList.retro_sign){
            for(let i = start; i <= end; i++){
                firstList.push(i);
            }
        } else {
            for(let i = start; i < 30; i++){
                firstList.push(i);
            }
            for(let i = 0; i <= end; i++){
                secondList.push(i);
            }
        }
        return firstList.concat(secondList);
    }
    getDisplacementStart(){
        const { nextPlanetRetrogradeReducer } = this.props;
        const retro_degree = nextPlanetRetrogradeReducer.retroList.retro_degree;
        const direct_degree = nextPlanetRetrogradeReducer.retroList.direct_degree;
        return (
            (direct_degree - Math.floor(direct_degree)) * (550 / this.degreesOfScale().length) +
            (Math.ceil(retro_degree) - retro_degree) * (550 / (this.degreesOfScale().length)) - 500
        );
    }
    getDisplacementEnd(){
        const { nextPlanetRetrogradeReducer } = this.props;
        const retro_degree = nextPlanetRetrogradeReducer.retroList.retro_degree;
        return (677 - (Math.ceil(retro_degree) - retro_degree) * (550 / (this.degreesOfScale().length)));
    }
    scaleColorBlocksX(step, index, zeroIndex){
        const { nextPlanetRetrogradeReducer } = this.props;
        const direct_degree = nextPlanetRetrogradeReducer.retroList.direct_degree % 1;
        if(index === 0 && direct_degree > 0) return 150 + (550 / this.degreesOfScale().length * direct_degree);
        if(index < zeroIndex) return (-24 + this.getDisplacementEnd() + this.getDisplacementStart()) + (step * index);
        else return 150 + (550 / this.degreesOfScale().length * index);
    }
    getColorFromGraphic(index, zeroIndex){
        const { planetesRetrogradeReducer } = this.props;
        if(index >= zeroIndex) return '#F0E68C';
        else {
            switch(planetesRetrogradeReducer.planet) {
                case 'Mercury':
                    return '#800000';
                case 'Venus':
                    return '#008000';
                case 'Mars':
                    return '#FF0000';
                case 'Jupiter':
                    return '#FFA500';
                case 'Saturn':
                    return '#808080';
                case 'Uranus':
                    return '#0000CD';
                case 'Neptune':
                    return '#800080'
                case 'Pluto':
                    return '#000000';
                default:
                    return '#F5DEB3';
            }
        }
    }
    elemRetrograde(step, index, zeroIndex, arr){
        if(index === arr.length - 1){
            return ((25 + this.getDisplacementEnd()) - this.scaleColorBlocksX(step, index, zeroIndex));
        } else return step;
    }
    getPlanetImage(){
        const { currentSearchReducer, planetesRetrogradeReducer } = this.props;
        const symbolElem = currentSearchReducer.ephemeris.filter(elem => {
            return elem.name === planetesRetrogradeReducer.planet;
        });
        return symbolElem[0].body_image;
    }
    getSD(value){
        const { currentSearchReducer, nextPlanetRetrogradeReducer } = this.props;
            const tz = currentSearchReducer.timezone;
        if (value === 'start') {
            const date = moment(nextPlanetRetrogradeReducer.retroList.date_retro).tz(tz).format('LL');
            return `${this.retrogradeZodiac(nextPlanetRetrogradeReducer.retroList.retro_sign, nextPlanetRetrogradeReducer.retroList.retro_degree)}\n${date}`;
        }else if (value === 'end'){
            const date = moment(nextPlanetRetrogradeReducer.retroList.date_direct).tz(tz).format('LL');
            return `${this.retrogradeZodiac(nextPlanetRetrogradeReducer.retroList.direct_sign, nextPlanetRetrogradeReducer.retroList.direct_degree)}\n${date}`;
        } else return '';
    }
    retrogradeZodiac(sign, degree){
        const newDegree = (degree + '').split('.');
        const deg = newDegree[0] + String.fromCharCode(176);
        let min;
        if(newDegree[1]){
            const minFormat = newDegree[1].length > 1 ? newDegree[1] : newDegree[1] + '0';
            min = Math.round(60 / (100 / +minFormat));
        } else min = '00';
        return `${deg} ${sign} ${min}'`;
    }
    planetPositionX(arrClear){
        const { planetesRetrogradeReducer, nextPlanetRetrogradeReducer } = this.props;
        if(planetesRetrogradeReducer.current_zodiac){
            const tz = 'Greenwich';
            const shadowStart = moment(nextPlanetRetrogradeReducer.retroList.date_shadow_start).tz(tz).format('x');
            const dateRetro = moment(nextPlanetRetrogradeReducer.retroList.date_retro).tz(tz).format('x');
            const dateDirect = moment(nextPlanetRetrogradeReducer.retroList.date_direct).tz(tz).format('x');
            const shadowDirect = moment(nextPlanetRetrogradeReducer.retroList.date_shadow_direct).tz(tz).format('x');
            const currentTime = moment().tz(tz).format('x');
            const degree = planetesRetrogradeReducer.current_zodiac.body_pos_deg.replace(String.fromCharCode(176), '');
            const minute = planetesRetrogradeReducer.current_zodiac.body_pos_min.replace("'", '');
            const degreeStep = 550 / (arrClear.length); // pixels in 1 degree
            const degreeIndexPosition = arrClear.indexOf(+degree); // index of degree in arr
            const degreeIndexPositionReverse = arrClear.reverse().indexOf(+degree); // index of degree in arr
            const minutePercentFromDegree = (100 / 60 * +minute) / 100; // percente of minutes in one degreeStep
            const minutePercentFromDegreeReverse = 1 - (100 / 60 * +minute) / 100; // percente of minutes in one degreeStep
            if(currentTime < shadowStart){
                return 80;
            }else if(shadowStart <= currentTime && currentTime < dateRetro){
                const startPos = 150 - 25 / 2; // left padding + imgWidth / 2
                return startPos + degreeIndexPosition * degreeStep + degreeStep * minutePercentFromDegree;
            }else if(dateRetro <= currentTime && currentTime < dateDirect){
                const startPos = (150 + degreeStep * arrClear.length) - 25 / 2;
                const a = startPos - (degreeIndexPositionReverse * degreeStep + degreeStep * minutePercentFromDegreeReverse);
                return a;
            }else if(dateDirect <= currentTime && currentTime < shadowDirect){
                const startPos = 150 - 25 / 2;
                return startPos + (degreeIndexPosition * degreeStep + degreeStep * minutePercentFromDegree);
            }else{
                const startPos = (150 + degreeStep * arrClear.length) - 25 / 2;
                return startPos + 50; // left padding + imgWidth / 2
            }
        }else return 80;
    }
    planetPositionY(arrClear){
        const { planetesRetrogradeReducer, nextPlanetRetrogradeReducer } = this.props;
        if(planetesRetrogradeReducer.current_zodiac){
            const tz = 'Greenwich';
            const shadowStart = moment(nextPlanetRetrogradeReducer.retroList.date_shadow_start).tz(tz).format('x');
            const dateRetro = moment(nextPlanetRetrogradeReducer.retroList.date_retro).tz(tz).format('x');
            const dateDirect = moment(nextPlanetRetrogradeReducer.retroList.date_direct).tz(tz).format('x');
            const shadowDirect = moment(nextPlanetRetrogradeReducer.retroList.date_shadow_direct).tz(tz).format('x');
            const currentTime = moment().tz(tz).format('x');   
            const degreeStep = 550 / (arrClear.length); // pixels in 1 degree
            const x = this.planetPositionX(arrClear); //  x on circle
            const r = 20; //radius
            const rightCircleA = ((150 + degreeStep * arrClear.length) - 25 / 2) - 20;  // percente of minutes in one degreeStep:
            const leftCircleA = -24 + this.getDisplacementEnd() + this.getDisplacementStart() + 20 - 25 / 2;
            
            if(currentTime < shadowStart){
                // before retrograde
                return 30 - 25 / 2; // top pos - imgWidth / 2
            }else if(shadowStart <= currentTime && currentTime < dateRetro){
                // retrograde shadow
                const a = rightCircleA;  // x of circle
                const b = (30 - 25 / 2) + r; //  top pos - imgWidth / 2 + radius : y of circle
                const y = -Math.sqrt(Math.pow(r, 2) - Math.pow(x - a, 2)) + b;
                return x >= rightCircleA ? y : 30 - 25 / 2;
            }else if(dateRetro <= currentTime && currentTime < dateDirect){
                // retrograde start
                if(x >= rightCircleA){
                    // circle start
                    const a = rightCircleA;  // x of circle
                    const b = (30 - 25 / 2) + r; //  top pos - imgWidth / 2 + radius : y of circle
                    const y = Math.sqrt(Math.pow(r, 2) - Math.pow(x - a, 2)) + b;
                    return y;
                }else if(x <= leftCircleA){
                    // circle end
                    const a = leftCircleA;  // x of circle
                    const b = (30 - 25 / 2) + r + 40; //  top pos - imgWidth / 2 + radius + padding to second line : y of circle
                    const y = -Math.sqrt(Math.pow(r, 2) - Math.pow(x - a, 2)) + b;
                    return y;
                } else {
                    return (30 - 25 / 2) + 40; //first line + padding to second line
                }
            }else if(dateDirect <= currentTime && currentTime < shadowDirect){
                // after retrograde shadow
                if(x <= leftCircleA){
                    // circle start
                    const a = leftCircleA;  // x of circle
                    const b = (30 - 25 / 2) + r + 40; //  top pos - imgWidth / 2 + radius + padding to second line : y of circle
                    const y = Math.sqrt(Math.pow(r, 2) - Math.pow(x - a, 2)) + b;
                    return y;
                } else {
                    // circle end
                    return (30 - 25 / 2) + 40 + 40; //first line + padding to second line + padding to third line
                }
            }else{
                // before retrograde
                return 110 - 25 / 2; // top pos - imgWidth / 2
            }
        } else return 30 - 25 / 2;
    }

    render() {
        const step = 550 / this.degreesOfScale().length;
        const arrClear = this.degreesOfScale('numm');
        const zeroIndex = arrClear.indexOf(0);
        const scaleBlocksStep = 550 / arrClear.length;
        const start = this.getDisplacementStart();
        const end = this.getDisplacementEnd();
        const color = this.getColorFromGraphic();
        const { planetesRetrogradeReducer} = this.props;
        return (<div>
            <div className='raphael-block'>
                <h4 className="titleOnScale">
                    {this.nextRetrogradeTitle()}
                </h4>
                <Paper width={end - 30 + start + 660} height={45} >
                    <Set >
                        {this.degreesOfScale().map((elem, index, arr) => {
                            return <Rect key={index} x={this.scaleColorBlocksX(step, index, zeroIndex)} y={27} width={this.elemRetrograde(step, index, zeroIndex, arr)} height={15} attr={{"fill":this.getColorFromGraphic(index, zeroIndex), 'stroke-width': 0,}}/>
                        })}
                        {/* degrees */}
                        {arrClear.map((elem, index) =>
                            <Text key={index} x={150 + (scaleBlocksStep * (index))} y={20} text={elem+'°'} attr={{"font-size":"12px","fill":"#000"}}/>
                        )}
                        {/* degrees last*/}
                            <Text key={1413} x={150 + (scaleBlocksStep * arrClear.length )} y={20} text={(arrClear[arrClear.length - 1] + 1)+'°'} attr={{"font-size":"12px","fill":"#000"}}/>
                        {/* long lines degrees*/}
                        {arrClear.map((elem, index) =>
                            <Path key={index + '12'} d={'M ' + (150 + scaleBlocksStep * index) + ' 27 l 0 15'} x={20 * (index + 1)} y={20} attr={{"fill":"#000"}}/>
                        )}
                        {/* long lines between degrees*/}
                        {arrClear.map((elem, index) =>
                            <Path key={index + '123'} d={'M ' + (scaleBlocksStep / 2 + 150 + scaleBlocksStep * index) + ' 27 l 0 10'} x={20 * (index + 1)} y={20} attr={{"fill":"#000"}}/>
                        )}
                        {/* short lines left */}
                        {arrClear.map((elem, index) =>
                            <Path key={index + '123'} d={'M ' + (scaleBlocksStep / 4 + 150 + scaleBlocksStep * index) + ' 27 l 0 6'} x={20 * (index + 1)} y={20} attr={{"fill":"#000"}}/>
                        )}
                        {/* short lines right */}
                        {arrClear.map((elem, index) =>
                            <Path key={index + '123'} d={'M ' + ((scaleBlocksStep / 4 * 3) + 150 + scaleBlocksStep * index) + ' 27 l 0 6'} x = {20 * (index + 1 )} y={20} attr={{"fill":"#000"}}/>
                        )}
                        {/* long line last */}
                        <Path key={122342352} d={'M ' + (150 + scaleBlocksStep * arrClear.length ) + ' 27 l 0 15'} x={20 * (arrClear.length + 1)} y={20} text={(arrClear[arrClear.length-1] + 1) +'°'} attr={{"fill":"#000"}}/>
                         {/* top line */}   
                        <Path  d={'M 125 27 l 600 0'} attr={{"fill":"#000", 'stroke': 'black'}}/>
                         {/* bottom line */}
                        <Path d={'M 125 42 l 600 0 '} attr={{"fill":"#000"}}/>
                    </Set>
                    <Set>
                        {/* frame top */}
                        <Rect x={0} y={0} width={end - 30 + start + 660} height={45} attr={{"stroke": "#cccccc", "stroke-width":  2,}}/>
                    </Set>
                </Paper>
                <div style={{marginTop: -4 + 'px'}}>
                    <Paper width={end - 30 + start + 660} height={170} >
                        <Set style={{ padding: '10px'}}>
                            {/* end of retrograde line */}
                            <Path  d={`M `+ (26 + end) + ` 0 l 0 55`} attr={{ 'stroke-width': 1}}/>
                            {/* right text retro */}
                            <Text  x={55  + end} y={17} text={this.getSD('start')} attr={{"font-size": "10px", 'text-anchor': 'start',"fill": 'black'}}/>
                            {/* left D */}
                            <Rect x={32 + end} y={30} width={18} height={18} attr={{"stroke": "#f0c620", "stroke-width": 3,}}/>
                            <Text  className='Text' x={46 + end} y={39} text='R' attr={{"font-size": "14px", 'text-anchor': 'end', "fill": "#f0c620", 'font-weight': 'bold' }}/>
                            {/* right arrow */}
                            <Path  d={`M `+ (24 + end)+` 100 l 30 10 l -30 10 z`} attr={{ 'fill': color, 'stroke': color, 'stroke-width': 2, 'fill-opacity': '1'}} />
                            {/* end of retrograde line */}
                            <Path  d={`M `+ (-26 + end + start) + ` 85 l 0 60`} attr={{ 'stroke-width': 1,}}/>
                            {/* left text retro */}
                            <Text  x={(-55 + end + start)} y={133} text={this.getSD('end')} attr={{"font-size":"10px", 'text-anchor': 'end',"fill":"black"}}/>
                            {/* left D */}
                            <Rect x={(-50 + end + start)} y={145} width={18} height={18} attr={{"stroke": "#f0c620", "stroke-width": 3,}}/>
                            <Text  className='Text' x={(-36 + end + start)} y={154} text='D' attr={{"font-size": "14px", 'text-anchor': 'end', "fill":"#f0c620", 'font-weight': 'bold' }}/>
                            {/* left arrow */}
                            <Path  d={`M `+ (-70 + end + start) +` 20 l 30 10 l -30 10 z`} attr={{ 'fill': color, 'stroke': color, 'stroke-width': 2, 'fill-opacity':'1'}} />
                            {/* planet way */}
                            <Path d={`M 30 30 l `+ (end - 30) + ` 0 a 13 10 0 0 1 0 40 l ` + start + ` 0 a 13 10 0 0 0 0 40 l 590 0 `}
                                attr={{'fill':'green', 'stroke': color, 'stroke-width': 2.7, 'fill-opacity':'0'}}
                            />
                            {/* planet position */}
                            <Image src={`/${this.getPlanetImage()}`} x={this.planetPositionX(arrClear)} y={this.planetPositionY(arrClear)} width={25} height={25} />
                        </Set>
                        <Set>
                            {/* frame bottom */}
                            <Rect x={0} y={0} width={end - 30 + start + 660} height={170} attr={{"stroke": "#cccccc", "stroke-width":  2,}}/>
                        </Set>
                    </Paper>
                </div>
            </div>
            <div style={{
                    width: "823px",
                    outline: "2px solid #d1d1d1",
                    margin: "auto",
                    padding: "15px",
                    fontSize: "17px", lineHeight: "20px",
                }} dangerouslySetInnerHTML={createMarkup(planetesRetrogradeReducer.individualText)} />
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetesRetrogradeReducer: state.planetesRetrogradeReducer,
        nextPlanetRetrogradeReducer: state.nextPlanetRetrogradeReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        nextPlanetRetrograde: bindActionCreators(nextPlanetRetrograde, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrogradeRaphaelDraw);