import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./NotFound.css";

class NotFound extends Component {
	render() {
		return (
			<section className="NotFound">
				<h1>404</h1>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				<h2>We're sorry...</h2>
				<p>The page you are looking for cannot be found</p>
				<Link to="/">Return to main page</Link>
			</section>
		);
	}
}

export default NotFound;
