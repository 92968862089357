import React, { Component } from "react";
import { connect } from "react-redux";
// import {MuiThemeProvider, RaisedButton} from "material-ui";
import moment from "moment-timezone";

import Api from "../../../../services/api.js";

class Dashboard extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.panchang.sun_all_day !== nextProps.panchang.sun_all_day ||
      this.props.panchang.hindu_sunrise !== nextProps.panchang.hindu_sunrise
    );
  }

  renderBackgroundNearby(nearby, background, top) {
    let backgroundLast = "";
    nearby.forEach((item, index) => {
      backgroundLast = index % 2 === 0 ? background[1] : background[0];
    });
    return (
      <div
        className="background_nearby"
        style={{ background: backgroundLast, top: top }}
      ></div>
    );
  }

  renderNearby(current_date, nearby, background, top) {
    const { timezone } = this.props.currentSearchReducer;
    const arrTest = [];
    let left = "";
    let start = "";
    let width = "";
    let widthOld = 0;
    let widthIncreStart = 0;

    const date = +current_date.split("-")[2];
    const month = +current_date.split("-")[1] - 1;
    const year = +current_date.split("-")[0];

    nearby.forEach((item, index) => {
      let currentStart = moment().tz(timezone);

      if (
        index === 0 &&
        moment(item.start).isAfter(
          currentStart
            .set("year", year)
            .set("month", month)
            .set("date", date)
            .subtract(1, "days")
            .set({
              hour: 12,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
        )
      ) {
        let start = currentStart
          .set("year", year)
          .set("month", month)
          .set("date", date)
          .subtract(1, "days")
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        let start2 = moment(start).tz(timezone);
        let end = moment(item.start).tz(timezone);
        let width2 = (
          ((100 / 48) * Math.abs(start2.diff(end, "minutes"))) /
          60
        ).toFixed(2);
        widthIncreStart = width2;
        left = left === "" ? +width2 : +left + +widthOld;
      } else {
        widthIncreStart = 0;
      }
      start =
        start !== ""
          ? moment(item.start).tz(timezone)
          : currentStart
              .set("year", year)
              .set("month", month)
              .set("date", date)
              .subtract(1, "days")
              .set({
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

      let start2 = moment(start).tz(timezone);
      let end = moment(item.end).tz(timezone);
      width = (
        ((100 / 48) * Math.abs(start2.diff(end, "minutes"))) / 60 -
        +widthIncreStart
      ).toFixed(2);
      left = left === "" ? 0 : +left + +widthOld;

      let borderRight = "0px solid black";
      let justifyContent = "center";
      let paddingLeft = "0px";
      if (nearby.length - 1 === index && left > 70 && width > 25) {
        justifyContent = "flex-start";
        paddingLeft = "10px";
      } else if (nearby.length - 1 === index && left > 60 && width > 50) {
        justifyContent = "flex-start";
        paddingLeft = "10px";
      }
      if (nearby.length - 1 === index) {
        borderRight = "1px solid black";
      }

      if (index <= 1 && left < -11 && left > -32 && width > 30 && width < 53) {
        justifyContent = "flex-end";
      }

      let objName = item.name;
      let objStart = moment(item.start).tz(timezone).format("MMM DD, hh:mm A");
      let objEnd = moment(item.end).tz(timezone).format("MMM DD, hh:mm A");

      if (
        (index <= 1 && left < -30 && width > 30 && width < 51) ||
        width < 20
      ) {
        objName = "";
        objStart = "";
        objEnd = "";
      }

      arrTest.push({
        name: objName,
        width: `${+width}%`,
        left: `${+left}%`,
        background: index % 2 === 0 ? background[0] : background[1],
        start: objStart,
        end: objEnd,
        borderRight,
        justifyContent,
        paddingLeft,
      });
      widthOld = +width;
    });

    return arrTest.map((elem, index) => (
      <div
        key={elem.name + index}
        className="nearby"
        style={{
          paddingLeft: elem.paddingLeft,
          justifyContent: elem.justifyContent,
          borderRight: elem.borderRight,
          top: top,
          width: elem.width,
          left: elem.left,
          background: elem.background,
        }}
      >
        <div className="title">
          <span className="name">
            <b>{elem.name}</b>
          </span>
          {elem.start && (
            <span>
              ({elem.start} - {elem.end})
            </span>
          )}
        </div>
      </div>
    ));
  }

  renderSunRiseLine(current_date, sunrise) {
    const { timezone, offset } = this.props.currentSearchReducer;

    let offSet;

    if (offset[0] === "-") {
      if (offset.split(":")[0].length < 3) {
        offSet = "-0" + offset.substring(1);
      } else {
        offSet = offset;
      }
    } else {
      offSet = "+" + offset;
    }

    let start = moment(`${current_date}T00:00:00${offSet}`).tz(timezone);
    let end = moment(sunrise).tz(timezone);

    let width = (
      ((100 / 48) * Math.abs(start.diff(end, "minutes"))) / 60 +
      25
    ).toFixed(2);

    return (
      <div className="sunrise_line" style={{ left: `${width}%` }}>
        {" "}
        <span>
          Sunrise <br />({moment(sunrise).tz(timezone).format("hh:mm A")})
        </span>
      </div>
    );
  }

  renderSunRiseLineTop(current_date, sunrise) {
    const { timezone, offset } = this.props.currentSearchReducer;

    let offSet;

    if (offset[0] === "-") {
      if (offset.split(":")[0].length < 3) {
        offSet = "-0" + offset.substring(1);
      } else {
        offSet = offset;
      }
    } else {
      offSet = "+" + offset;
    }

    let start = moment(`${current_date}T00:00:00${offSet}`).tz(timezone);
    let end = moment(sunrise).tz(timezone);
    let width = (
      ((100 / 48) * Math.abs(start.diff(end, "minutes"))) / 60 +
      25
    ).toFixed(2);

    return (
      <div className="sunrise_line_top" style={{ left: `${width}%` }}></div>
    );
  }

  cityName(options) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    if (options === "name-country") {
      return `${name}, ${country}`;
    } else {
      return `${name}, ${state} ${country}`;
    }
  }

  render() {
    const {
      current_date,
      nakshatras_nearby,
      yogas_nearby,
      tithis_nearby,
      karanas_nearby,
      hindu_sunrise,
      next_hindu_sunrise,
      sun_all_day,
    } = this.props.panchang;

    return (
      <div className="dashboard">
        <div className="main_block">
          {sun_all_day === "DOWN" && (
            <div className="panchang-content">
              <div className="text_error_out">
                <span>
                  Due to Sun being down all day, Panchang for{" "}
                  {this.cityName("name-country")} cannot be calculated.
                </span>
              </div>
            </div>
          )}
          {sun_all_day === "UP" && (
            <div className="panchang-content">
              <div className="text_error_out">
                <span>
                  Due to Sun being up all day, Panchang for{" "}
                  {this.cityName("name-country")} cannot be calculated.
                </span>
              </div>
            </div>
          )}
          {sun_all_day === "TRANSITION" && (
            <div className="panchang-content">
              <div className="text_error_out">
                <span>
                  Due to Sun being in transition, Panchang for{" "}
                  {this.cityName("name-country")} cannot be calculated.
                </span>
              </div>
            </div>
          )}
          {sun_all_day === "UP_DOWN" && (
            <div className="top">
              <div className="date">
                <div>
                  <span>
                    {moment(current_date).subtract(1, "days").format("ll")}
                  </span>
                </div>
                <div>
                  <span className="middle_data">
                    {moment(current_date).format("ll")}
                  </span>
                </div>
                <div>
                  <span>
                    {moment(current_date).add(1, "days").format("ll")}
                  </span>
                </div>
              </div>
              <div className="shedule">
                {this.renderSunRiseLineTop(current_date, hindu_sunrise)}
                {this.renderSunRiseLineTop(current_date, next_hindu_sunrise)}
                <div className="six_hour_top ">
                  <label>12:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>18:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>00:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>06:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>12:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>18:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>24:00</label>
                </div>
                <div className="six_hour_top ">
                  <label>06:00</label>
                </div>
                <div className="six_hour_top_last">
                  <label>12:00</label>
                </div>
              </div>
            </div>
          )}
          {sun_all_day === "UP_DOWN" && (
            <div className="middle">
              <div className="board">
                <div className="left_bar">
                  <h5>Nakshatra</h5>
                  <h5>Yoga</h5>
                  <h5>Tithi</h5>
                  <h5>Karana</h5>
                </div>
                <div className="schedule">
                  {this.renderSunRiseLine(current_date, hindu_sunrise)}
                  {this.renderSunRiseLine(current_date, next_hindu_sunrise)}
                  {this.renderBackgroundNearby(
                    nakshatras_nearby,
                    ["#9980fa", "#5758bb"],
                    "15px"
                  )}
                  {this.renderBackgroundNearby(
                    yogas_nearby,
                    ["#1d89ff", "#5c89b2"],
                    "75px"
                  )}
                  {this.renderBackgroundNearby(
                    tithis_nearby,
                    ["#f39c12", "#ff7530"],
                    "135px"
                  )}
                  {this.renderBackgroundNearby(
                    karanas_nearby,
                    ["#2ecc71", "#27ae60"],
                    "195px"
                  )}
                  {/*<div className="background_nearby nakhsatra"></div>*/}
                  {/*<div className="background_nearby yoga"></div>*/}
                  {/*<div className="background_nearby tithi"></div>*/}
                  {/*<div className="background_nearby karana"></div>*/}
                  {this.renderNearby(
                    current_date,
                    nakshatras_nearby,
                    ["#9980fa", "#5758bb"],
                    "15px"
                  )}
                  {this.renderNearby(
                    current_date,
                    yogas_nearby,
                    ["#1d89ff", "#5c89b2"],
                    "75px"
                  )}
                  {this.renderNearby(
                    current_date,
                    tithis_nearby,
                    ["#f39c12", "#ff7530"],
                    "135px"
                  )}
                  {this.renderNearby(
                    current_date,
                    karanas_nearby,
                    ["#2ecc71", "#27ae60"],
                    "195px"
                  )}
                  <div className="six_hour blue"></div>
                  <div className="six_hour blue"></div>
                  <div className="six_hour yellow"></div>
                  <div className="six_hour yellow"></div>
                  <div className="six_hour yellow"></div>
                  <div className="six_hour yellow"></div>
                  <div className="six_hour blue"></div>
                  <div className="six_hour blue "></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};

export default connect(mapStateToProps)(Dashboard);
