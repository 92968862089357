import React from "react";
import ReactDOM from "react-dom";
// react redux router
import { BrowserRouter as Router } from "react-router-dom";
// import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from 'history';
// import { ConnectedRouter } from "react-router-redux";
// redux store
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
// redux Middleware
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import "./theme/App.css";
import "./theme/index.css";
import rootReducer from "./reducers/index";
import App from "./App";
import { loadState, saveState } from "./utils/localStorage";
// import registerServiceWorker from './services/registerServiceWorker';
import throttle from "lodash.throttle";
// import { createLogger } from 'redux-logger';
// const loggerMiddleware = createLogger();

// const history = createHistory();
const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const persistedState = loadState();
const store = createStore(
	rootReducer,
	persistedState,
	composeWithDevTools(
		applyMiddleware(
			middleware,
			thunk
			// loggerMiddleware,
		)
	)
);

// Throttle: invokes a function at most once per every X milliseconds.
store.subscribe(
	throttle(() => {
		const { selectedCitiesReducer } = store.getState();

		saveState({
			selectedCitiesReducer: {
				...selectedCitiesReducer,
				// эти данные мы не хотим сохранять
				token: false,
				cities: [],
				authUserCities: []
			}
		});
	}, 1000)
);

const render = Component => {
	ReactDOM.render(
		<Provider store={store}>
			{/*<ConnectedRouter history={history}>*/}
				<Router history={history}>
					<Component />
				</Router>
			{/*</ConnectedRouter>*/}
		</Provider>,
		document.getElementById("root")
	);
};

if (module.hot) {
	module.hot.accept("./App", () => {
		render(App);
	});
}

render(App);
// registerServiceWorker();
