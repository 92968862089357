import React from "react";

import Api from "../../../services/api.js";
import "./sitemap.css";
import {Link} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkM from '@material-ui/core/Link';

class Sitemap extends React.Component {
    state = {
        smStyle: {columnCount: 6},
        name: '',
        title: '',
        site_map: [],
        typography: '',
        breadcrumbs: [],
    };

    componentDidMount() {
        // console.log('componentDidMount', this.props);
        this.getForPath(this.props.location.pathname);
    }

    /*
        componentDidUpdate(nextProps, prevProps) {
            console.log('componentDidUpdate', this.props, nextProps, nextProps);
            this.getStates(this.props.location.pathname);
        }
    */

    shouldComponentUpdate(nextProps, nextState) {
        //console.log('shouldComponentUpdate', this.props, nextProps, nextState);
        if (this.state.site_map !== nextState.site_map) {
            return true;
        } else {
            if (this.props.location.pathname !== nextProps.location.pathname) {
                this.getForPath(nextProps.location.pathname);
            }
            return false;
        }
    }

    getForPath(path) {
        let sub_code = '';
        let code = path.split('/');
        let name = code[3];
        if (code[2]) {
            sub_code = code[2].split('.');
            if (sub_code[3]) {
                this.getUrls(sub_code[3], name);
            } else if (sub_code[2]) {
                if (sub_code[0] === 'US') this.getCitiesUS(code[2], name);
                else this.getUrls(sub_code[2], name);
            } else if (sub_code[1]) {
                if (sub_code[0] === 'US') this.getCounty(code[2], name);
                else this.getCities(code[2], name);
            } else {
                this.getStates(code[2], name);
            }
        } else this.getCountry();
    }

    /*getForCode(code, name) {
        if (code !== '') {
            console.log('getForCode = ' + code);
            let sub_code = code.split('.');
            if (sub_code[2]) this.getUrls(sub_code[2], name);
            else if (sub_code[1]) this.getCities(code, name);
            else this.getStates(code, name);
        }
    }*/

    async getCountry() {
        // let location = await Api.getLocatoin();
        // console.log("location",location.data);
        let result = await Api.getCountry();
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        this.setState({
            name: '',
            smStyle: st,
            title: 'Countries',
            site_map: result.data,
            typography: 'Countries',
            breadcrumbs: [],
            // location: location.data
        });
    }

    async getStates(code, name) {
        this.setState({country: name});
        if (name) sessionStorage.setItem("sitemapCountry", name);
        sessionStorage.setItem("sitemapCountryCode", code);
        let result = await Api.getState(code);
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        this.setState({
            name: name,
            smStyle: st,
            title: sessionStorage.getItem("sitemapCountry") + ' | States',
            site_map: result.data,
            typography: sessionStorage.getItem("sitemapCountry"),
            breadcrumbs: [{'name': 'Countries', 'url': ''}],
        });
    }

    async getCities(code, name) {
        let result = await Api.getCities(code);
        if (name) sessionStorage.setItem("sitemapState", name);
        sessionStorage.setItem("sitemapStateCode", code);
        sessionStorage.setItem("sitemapCounty", '');
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        this.setState({
            name: name,
            smStyle: st,
            title: sessionStorage.getItem("sitemapCountry") + ' | ' + sessionStorage.getItem("sitemapState") + ' | Сities',
            site_map: result.data,
            typography: sessionStorage.getItem("sitemapState"),
            breadcrumbs: [{'name': 'Countries', 'url': ''},
                {
                    'name': sessionStorage.getItem("sitemapCountry"),
                    'url': sessionStorage.getItem("sitemapCountryCode")
                }],
        });
    }

    async getCounty(code, name) {
        if (name) sessionStorage.setItem("sitemapState", name);
        sessionStorage.setItem("sitemapStateCode", code);
        let result = await Api.getCounty(code);
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        let title = sessionStorage.getItem("sitemapCountry") + ' | ' + sessionStorage.getItem("sitemapState") + ' | Counties';
        this.setState({
            name: name,
            smStyle: st,
            title: title,
            site_map: result.data,
            typography: sessionStorage.getItem("sitemapState"),
            breadcrumbs: [{'name': 'Countries', 'url': ''},
                {
                    'name': sessionStorage.getItem("sitemapCountry"),
                    'url': sessionStorage.getItem("sitemapCountryCode")
                }],
        });
    }

    async getCitiesUS(code, name) {
        if (name) sessionStorage.setItem("sitemapCounty", name);
        sessionStorage.setItem("sitemapCountyCode", code);
        let result = await Api.getCitiesUS(code);
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        let title = sessionStorage.getItem("sitemapCountry") + ' | ' +
            sessionStorage.getItem("sitemapState") + ' | ' +
            sessionStorage.getItem("sitemapCounty") + ' | Сities';
        this.setState({
            name: name,
            smStyle: st,
            title: title,
            site_map: result.data,
            typography: sessionStorage.getItem("sitemapCounty"),
            breadcrumbs: [{'name': 'Countries', 'url': ''},
                {
                    'name': sessionStorage.getItem("sitemapCountry"),
                    'url': sessionStorage.getItem("sitemapCountryCode")
                },
                {
                    'name': sessionStorage.getItem("sitemapState"),
                    'url': sessionStorage.getItem("sitemapStateCode")
                }],
        });
    }

    async getUrls(code, name) {
        let result = await Api.getUrls(code);
        let ttt = Math.min(8, parseInt(result.data.length / 20) + 1);
        let st = {columnCount: ttt};
        let title = sessionStorage.getItem("sitemapCountry") + ' | ' + sessionStorage.getItem("sitemapState") + ' | '
            + (sessionStorage.getItem("sitemapCounty") === '' ? '' : sessionStorage.getItem("sitemapCounty") + ' | ')
            + name;
        let breadcrumbs = [{'name': 'Countries', 'url': ''}];
        breadcrumbs.push({'name': sessionStorage.getItem("sitemapCountry"), 'url': sessionStorage.getItem("sitemapCountryCode")});
        breadcrumbs.push({'name': sessionStorage.getItem("sitemapState"), 'url': sessionStorage.getItem("sitemapStateCode")});
        if(sessionStorage.getItem("sitemapCounty") !== '')
            breadcrumbs.push({'name': sessionStorage.getItem("sitemapCounty"), 'url': sessionStorage.getItem("sitemapCountyCode")});
        this.setState({
            name: title,
            smStyle: st,
            title: title,
            site_map: result.data,
            typography: name,
            breadcrumbs: breadcrumbs,
        });
    }

    render() {
        let sym = '';
        let url = '';
        let name = '';
        let code = '';
        let timein = '';
        document.title = 'Geotimedate Sitemap | ' + this.state.title;
        // console.log('this.state.breadcrumbs', this.state.breadcrumbs);
        return (
            <div className="site-map">
                {/*<span>{this.state.location}</span><hr/>*/}
                <div>
                    <Paper elevation={0}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {this.state.breadcrumbs.map((item, index) => {
                                url = `/sitemap/${item.url}`;
                                name = item.name;
                                return (
                                    <div key={index}>
                                        <LinkM color="inherit" href={url}>
                                            {name}
                                        </LinkM>
                                    </div>
                                );
                            })};
                            <Typography color="textPrimary">{this.state.typography}</Typography>
                        </Breadcrumbs>
                    </Paper>
                </div>

                <h1>{this.state.title}</h1>
                <div className="sm-column" style={this.state.smStyle}>
                    {this.state.site_map.map((item, index) => {
                        if (item.code === undefined) code = ''; else code = item.code;
                        if (item.name === '') name = 'noname' + code; else name = item.name;

                        if (item.timein !== undefined) {
                            url = `/sitemap/${code}/${name}`;
                            timein = `/time-in/${item.timein}`;
                            let lsim = '';
                            if (sym !== name.substring(0, 1)) {
                                sym = name.substring(0, 1);
                                lsim = <b>{sym}</b>
                            }
                            return (
                                <div key={index}>
                                    {lsim}
                                    <Link to={timein}>
                                        {name}
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;
                                    <Link to={url}>
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        >>>
                                    </Link>
                                </div>
                            );
                        } else if (item.url !== undefined) {
                            url = `/${item.url}`;
                            code = '';
                        } else {
                            url = `/sitemap/${code}/${name}`;
                            if (sym !== name.substring(0, 1)) {
                                sym = name.substring(0, 1);
                                return (
                                    <div key={index}>
                                        <b>{sym}</b>
                                        <Link to={url}>
                                            {name}
                                        </Link>
                                    </div>
                                );
                            }
                        }
                        return (
                            <div key={index}>
                                <Link to={url}>
                                    {name}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Sitemap;
