import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import MetaTags from "react-meta-tags";

import "./PlanetaryHours.css";
import Loader from "../../../components/Main/Loader/Loader";
import Search from "../MainPage/Search/Search";
import CityName from "./CityName/CityName";
import CityCalendarBlock from "./CityCalendarBlock/CityCalendarBlock";
import PlanetaryHoursTable from "./PlanetaryHoursTable/PlanetaryHoursTable";
import PlanetaryHoursRightBlock from "./PlanetaryHoursRightBlock/PlanetaryHoursRightBlock";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import planetaryHours from "../../../actions/action_planetaryHours/action_planetaryHours";

import Api from "../../../services/api.js";

class PlanetaryHours extends Component {
	state = {};
	componentDidMount() {
		this.pareReset();
	}
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) this.pareReset();
	}
	// componentDidUpdate(prevProps) {
	// 	if (
	// 		prevProps.planetaryHoursReducer !== this.props.planetaryHoursReducer
	// 	)
	// 		this.opgPicture();
	// }
	// async opgPicture() {
	// 	const { currentSearchReducer } = this.props;
	// 	if (currentSearchReducer.name) {
	// 		const newId = currentSearchReducer.url
	// 			.replace("place/", "")
	// 			.replace(/\//g, "__");
	// 		const opgPicture = await Api.userCityList(
	// 			`opg/sun/get-url/?geo_name=${newId}`
	// 		);
	// 		this.setState({ opgPicture: opgPicture.data.url });
	// 	}
	// }
	async pareReset() {
		const { currentSearch, planetaryHours } = this.props;
		const newId = window.location.pathname
			.split("/")
			.slice(2)
			.join("__");
		// console.log(window.location.pathname)
		const data = await Api.userCityList(`geo_names/${newId}/`);
		// console.log(data)
		currentSearch(data.data);
		const cityTime = moment().format();
		// const cityTime = moment(data.data.time).tz(data.data.timezone).format();
		planetaryHours(cityTime);
	}
	planetaryStyle() {
		const { planetaryHoursReducer } = this.props;
		const day = moment(planetaryHoursReducer).format("dddd");
		switch (day) {
			case "Monday":
				return { background: "#000", color: "#fff" };
			case "Tuesday":
				return { background: "#ff040f", color: "#fff" };
			case "Wednesday":
				return { background: "#7f0103", color: "#fff" };
			case "Thursday":
				return { background: "#ff7321", color: "#fff" };
			case "Friday":
				return { background: "#079d28", color: "#fff" };
			case "Saturday":
				return { background: "#a6a6a6", color: "#fff" };
			case "Sunday":
				return { background: "#caca22", color: "#fff" };
			default:
				return { background: "#fff", color: "#000" };
		}
	}
	metaCityName(nameType) {
		const { currentSearchReducer } = this.props;

		if (window.location.pathname.includes("planetary-hours")) {
			const urlCity = window.location.pathname.split("/")[4];
			const parsedUrlCity =
				urlCity.charAt(0).toUpperCase() +
				urlCity.slice(1).replace(/-/g, " ");

			const urlCountry = window.location.pathname.split("/")[2];
			const parsedUrlCountry =
				urlCountry.charAt(0).toUpperCase() +
				urlCountry.slice(1).replace(/-/g, " ");

			const name = currentSearchReducer.name
				? currentSearchReducer.name
				: parsedUrlCity;
			const country = currentSearchReducer.country_name
				? currentSearchReducer.country_name === "United States"
					? currentSearchReducer.country_name_abbr
					: currentSearchReducer.country_name
				: parsedUrlCountry === "usa"
				? "USA"
				: parsedUrlCountry;

			let state =
				nameType === "title" ? "" : `${currentSearchReducer.state},`;
			switch (nameType) {
				case "title":
					return ` ${name}, ${country}`;
				case "description":
					return ` ${name}, ${country}`;
				case "keywords":
					return `${name}, ${state} ${country}, `;
				default:
					return "";
			}
		}
		return "";
	}
	meta = () => {
		return (
			<MetaTags>
				<title>
					Planetary Hours table for{this.metaCityName("title")}
				</title>
				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content={`Find planetary hours table for${this.metaCityName(*/}
				{/*		"description"*/}
				{/*	)}. Easy to use planetary hours calculator. See ruler of the day, ruler of the hour, length of planetary hours, planetary hours of the day and night.`}*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	name="keywords"*/}
				{/*	content={`Planetary hours, table, planetary hours calculator, planetary hours software, ruler of the hour, ruler of the day, length of planetary hours, days of week, which planets rule which days`}*/}
				{/*/>*/}
				{/*{this.state.opgPicture ? (*/}
				{/*	<meta*/}
				{/*		property="og:image"*/}
				{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
				{/*	/>*/}
				{/*) : null}*/}
			</MetaTags>
		);
	};
	render() {
		const { currentSearchReducer } = this.props;
		if (!currentSearchReducer.time)
			return (
				<div>
					{this.meta()}
					<Loader />
				</div>
			);
		return (
			<section className="PlanetaryHours">
				{this.meta()}

				<div className="serarch-block">
					<b>Find Planetary Hours for</b>
					<Search />
				</div>
				<CityName planetaryStyle={this.planetaryStyle()} />
				<CityCalendarBlock />
				<div className="planetary-hours-content">
					<PlanetaryHoursTable
						planetaryStyle={this.planetaryStyle()}
					/>
					<PlanetaryHoursRightBlock
						planetaryStyle={this.planetaryStyle()}
					/>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentSearchReducer: state.currentSearchReducer,
		planetaryHoursReducer: state.planetaryHoursReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		currentSearch: bindActionCreators(currentSearch, dispatch),
		planetaryHours: bindActionCreators(planetaryHours, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanetaryHours);
