import React, { Component } from 'react';

import './Loader.css';

class Loader extends Component {
    render(){
        return (
            <div className="loader-spinner">
                <div className="cssload-loader"></div>
            </div>
        )
    }
}

export default Loader;
