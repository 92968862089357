import React, { Component } from "react";

import Ads from "../Ads/Ads";

class DescriptionText extends Component {
  render() {
    return (
      <div className="column1">
        <div className="unix-general">
          <div className="unix-column description">
            <h4>About GPS TimeStamp</h4>
            <br />
            <div className="left_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Asperiores autem blanditiis consequatur, distinctio dolor
                ducimus eligendi error eum fugit incidunt ipsum laborum
                laudantium magni modi natus nobis officiis quasi quidem quos
                ratione reiciendis rem repudiandae. Alias, asperiores autem
                consequuntur culpa dicta ipsa itaque nostrum nulla odio officiis
                placeat quasi quisquam quos tenetur, unde. Cum, nam?
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Is Unix time in milliseconds?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam amet consequatur corporis modi, nostrum quam repellendus
                repudiandae sunt voluptate voluptates. Itaque neque officiis
                quae ratione.
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Is Unix time always in UTC?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam amet consequatur corporis modi, nostrum quam repellendus
                repudiandae sunt voluptate voluptates. Itaque neque officiis
                quae ratione.
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Does Unix time include seconds?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam amet consequatur corporis modi, nostrum quam repellendus
                repudiandae sunt voluptate voluptates. Itaque neque officiis
                quae ratione.
              </p>
            </div>
            <br />
            <br />
          </div>
        </div>

        <Ads />
      </div>
    );
  }
}

export default DescriptionText;
