import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import asideAds from "../../../../assets/taboola/336-280.png";
import "./calc_sidebar.css";

let calcNav = [
  { url: "/date-to-date-calculator", title: "Date Calculators" },
  { url: "/business-date-to-date-calculator", title: "Business Calculators" },
  { url: "/flight-time-calculator", title: "Flight Time Calculator" },
  { url: "/unix-time-converter", title: "Unix Time Converter" },
  { url: "/julian-time-converter", title: "Julian Time Converter" },
  { url: "/gps-time-converter", title: "GPS Time Converter" }
];

function CalcSidebar(props) {
  const parseUrl = (sourceUrl, page) => {
    if (sourceUrl) {
      const parse = sourceUrl.split("/");
      parse[0] = page;
      return parse.join("/");
    } else {
      return "/";
    }
  };

  let almanacNav = [
    {
      url: parseUrl(props.defaultCityInfoReducer.url, "sun"),
      title: "Sun Map and Calculator"
    },
    {
      url: parseUrl(props.defaultCityInfoReducer.url, "moon"),
      title: "Moon Movement and Phases"
    },
    { url: "/", title: "Time Planner" },
    {
      url: "/solar-lunar-eclipses/world",
      title: "Catalog of all Solar and Lunar Eclipses (1900-2099)"
    },
    {
      url: "/#planet-stations-retrogrades",
      title: "Planet Stations and Retrogrades"
    },
    { url: "/#planet-in-signs", title: "Planet in Zodiac Signs" },
    {
      url: parseUrl(props.defaultCityInfoReducer.url, "planetary-hours"),
      title: "Planetary Hours"
    },
    {
      url: parseUrl(props.defaultCityInfoReducer.url, "panchang"),
      title: "Detailed Panchang"
    }
  ];

  return (
    <aside className="calc-sidebar">
      <div className="calc-sidebar-block">
        <p>Related Calculators and Timers</p>
        <ul>
          {calcNav.map(item => (
            <li key={item.title}>
              <Link to={item.url}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="calc-sidebar-block calc-sidebar-block_ads">
        <img src={asideAds} alt="Ads" />
      </div>
      
      <div className="calc-sidebar-block">
        <p>Find Complete Almanac for any City</p>
        <ul>
          {almanacNav.map(item => (
            <li key={item.title}>
              <Link to={item.url}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}

const mapStateToProps = state => {
  return {
    defaultCityInfoReducer: state.defaultCityInfoReducer
  };
};

export default connect(mapStateToProps)(CalcSidebar);
