import React from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView } from "react-google-maps";
import { Paper, Set, Circle, Image, Text, Line } from "react-raphael";

import './MapWithMoonDraw.css';

const MoonDrawFunc = compose(
	withProps({
		googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAQLc6w5KmdVjL-RdS5wkZeIQ5IAOslk1w&v=3.24&libraries=geometry,drawing,places",
		// googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAQLc6w5KmdVjL-RdS5wkZeIQ5IAOslk1w&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div className="sunMapWrapper" style={{ height: Math.min(document.body.offsetWidth, document.body.offsetHeight) / 2 + 40 }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap
		// options={{ styles: props.sunMapStyle }}
		options={{ styles: props.sunMapStyle, draggable: false }}
		zoom={10}
		center={{
			lat: props.moonCalcStore.latitude,
			lng: props.moonCalcStore.longitude
		}}
	>
		<OverlayView
			position={{
				lat: props.moonCalcStore.latitude,
				lng: props.moonCalcStore.longitude
			}}
			mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
			getPixelPositionOffset={props.getPixelPositionOffset}>
			<Paper width={props.centerX * 2} height={props.centerY * 2}>
				<Set>
					<Image src={`data:png;base64,${props.moonCalcStore.moon_data.moon_chart.diagram}`}
						x={props.centerX - props.radius} y={props.centerY - props.radius}
						width={props.radius * 2} height={props.radius * 2}
					/>
					<Text x={props.centerX} y={10} text="N" attr={{"fill":"#000"}}/>
					<Text x={props.centerX + props.radius + 10} y={props.centerY} text="E" attr={{"fill":"#000"}}/>
					<Text x={props.centerX} y={props.centerY + props.radius + 10} text="S" attr={{"fill":"#000"}}/>
					<Text x={10} y={props.centerY} text="W" attr={{"fill":"#000"}}/>
					{/* <Image right_sector
						src={`data:png;base64,${props.moonCalcStore.moon_data.moon_chart.right_sector}`}
						x={props.centerX - props.radius}
						y={props.centerY - props.radius}
						width={props.radius * 2}
						height={props.radius * 2}
						attr={{ opacity: props.moonRiseOpacity }}
					/>
					<Image
						src={`data:png;base64,${props.moonCalcStore.moon_data.moon_chart.left_sector}`}
						x={props.centerX - props.radius}
						y={props.centerY - props.radius}
						width={props.radius * 2}
						height={props.radius * 2}
						attr={{ opacity: props.moonSetOpacity }}
					/> */}
					<Line
						x1={props.centerX}
						y1={props.centerY}
						x2={props.moonCalcStore.moon_data.moon_chart.moon_rise[0] * (props.radius * 2)  + 20}
						y2={props.moonCalcStore.moon_data.moon_chart.moon_rise[1] * (props.radius * 2)  + 20}
						attr={{ stroke: "#ffca00", "stroke-width": 5 }}
						mouseover={props.moonRiseHoverStart}
						mouseout ={props.moonRiseHoverEnd}
					/>
					<Line
						x1={props.centerX}
						y1={props.centerY}
						x2={props.moonCalcStore.moon_data.moon_chart.moon_set[0] * (props.radius * 2) + 20}
						y2={props.moonCalcStore.moon_data.moon_chart.moon_set[1] * (props.radius * 2) + 20}
						attr={{ stroke: "#fd6262", "stroke-width": 5 }}
						mouseover={props.moonSetHoverStart}
						mouseout ={props.moonSetHoverEnd}
					/>


					{/* <Line
						x1={props.centerX}
						y1={props.centerY}
						x2={props.moonShadowCords.x}
						y2={props.moonShadowCords.y}
						attr={{ stroke: 'rgba(0, 0, 0, 0.5)', "stroke-width": 5}}
					/> */}
					<Line
						x1={props.centerX}
						y1={props.centerY}
						x2={props.moonPosition.xy[0] * (props.radius * 2) + 20}
						y2={props.moonPosition.xy[1] * (props.radius * 2) + 20}
						attr={props.moonLineColor}
					/>
					<Circle 
						x={props.moonPosition.xy[0] * (props.radius * 2) + 20}
						y={props.moonPosition.xy[1] * (props.radius * 2) + 20}
						r={10}
						attr={props.moonCircleStyle}
					/>
				</Set>
			</Paper>
		</OverlayView>
		<Marker
			key={props.moonCalcStore.id}
			position={{
				lat: props.moonCalcStore.latitude,
				lng: props.moonCalcStore.longitude
			}}
		/>
	</GoogleMap>
));

export default MoonDrawFunc;