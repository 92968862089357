import Api from "../services/api.js";

const resendMail = async email => {
	const result = await Api.resendEmailVerification(email);
	return result;
};

const getCityUrlById = async (cityId, requestQuery) => {
	const city = await Api.userCityList(
		`geo_names/${cityId}/?${requestQuery}`
	);
	return city.data.url.replace("place", "/time-in");
};

export { resendMail, getCityUrlById };

export function getattr(obj, field, def = undefined) {
    if (obj === undefined || obj === null){
        return def
    }
    if (field in obj) {
        return obj[field]
    }
    return def
}