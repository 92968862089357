import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import MobileMenu from "./mobileMenu";
import eclipsesData from "../../actions/action_eclipsesData";
import authorization from "../../actions/authorization";

import logo from "../../assets/logo_white.png";
import advertising from "../../assets/taboola/header-add.jpeg";

import Api from "../../services/api.js";
import "./Header.css";

class Header extends Component {
	state = {
		menuIsOpen: false,
		showMobileMenu: false,
		city: {}
	};

	async setCity() {
		const { currentSearchReducer } = this.props;
		let cityId;
		if (localStorage.getItem("sunCalcCityInfo")) {
			cityId = localStorage.getItem("mainSearchCity")
				? JSON.parse(localStorage.getItem("mainSearchCity")).id
				: JSON.parse(localStorage.getItem("sunCalcCityInfo")).id;
		} else {
			cityId = localStorage.getItem("mainSearchCity")
				? JSON.parse(localStorage.getItem("mainSearchCity")).id
				: currentSearchReducer.id;
		}
		if (cityId !== undefined) {
			const city = await Api.userCityList(
				`geo_names/${cityId}/?sun_app=true`
			);
			this.setState({ city: city.data });
		}
	}

	async componentDidMount() {
		const { authorization } = this.props;

		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");
		if (token) {
			const userInfo = await Api.userInfo("user-profile/", token);
			authorization({ ...userInfo.data.data, token: token });
		}

		this.setCity();

		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		if (window.innerWidth <= 991) {
			this.setState({ showMobileMenu: true });
		} else {
			this.setState({ showMobileMenu: false });
		}
	};

	handleToggle = () => this.setState({ menuIsOpen: !this.state.menuIsOpen });

	signOut = () => {
		const { authorization } = this.props;
		authorization({});
		if (sessionStorage.getItem("authorization")) {
			sessionStorage.removeItem("authorization");
			sessionStorage.removeItem("token");
		}
		if (localStorage.getItem("authorization")) {
			localStorage.removeItem("authorization");
			localStorage.removeItem("token");
		}
		if (window.location.pathname === "/") {
			// console.log("!!! window.location.reload signOut");
			window.location.reload();
		}
		window.location.pathname = "/";
	};

	async eclipsesLoad() {
		if (
			window.location.pathname.split("/")[1] === "solar-lunar-eclipses" &&
			window.location.pathname !== "/solar-lunar-eclipses/world"
		) {
			const { eclipsesData } = this.props;
			const eclipses = await Api.userCityList(`eclipse_city/`);
			eclipsesData(eclipses.data.data);
		}
	}

	render() {
		const { showMobileMenu } = this.state;
		const isLoggedIn =
			localStorage.getItem("authorization") ||
			sessionStorage.getItem("authorization");
		const auth = this.props.authorizationReducer;

		let url = "";
		if (this.state.city.url) {
			url = `${this.state.city.url
				.split("/")
				.slice(1)
				.join("/")}`;
		} else {
			this.setCity();
		}

		return (
			<MuiThemeProvider>
				<header>
					<div className="header_wrapper">
						{showMobileMenu && (
							<Fragment>
								<span
									onClick={() => this.handleToggle()}
									className="nav_button"
								></span>
								<MobileMenu
									handleToggle={this.handleToggle}
									isOpen={this.state.menuIsOpen}
									isLoggedIn={isLoggedIn}
									url={url}
									signOut={this.signOut}
								/>
							</Fragment>
						)}

						<Link to="/" className="logo_wrapper">
							<div className="logo_wrapper_block">
								<img
									src={logo}
									className="App-logo"
									alt="GeoTimeDate – Logo"
								/>
							</div>
						</Link>
						<div className="header-blocks">
							<div className="header-top">
								<div className="advertising-block-header">
									<div className="advertising-header">
										<img
											src={advertising}
											className="advertising"
											alt="advertising"
										/>
									</div>
								</div>
							</div>
							<div className="header-bottom">
								<ul className="header-menu">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/meeting-planner">
											Meeting Planner
										</Link>
									</li>

									<li className="dropdown-title">
										<p>
											Almanac{" "}
											<i className="material-icons">
												arrow_drop_down
											</i>
										</p>
										<div>
											<Link to={`/sun/${url}`}>Sun</Link>
											<Link to={`/moon/${url}`}>
												Moon
											</Link>
											<Link to="/solar-lunar-eclipses/world">
												Eclipses
											</Link>
											<div className="second-lvl-menu">
												<p>Planet Retrogrades</p>
												<i className="material-icons">
													arrow_drop_down
												</i>
												<ul className="sub-menu">
													<li>
														<Link
															to={`/Mercury-retrograde/${url}`}
														>
															Mercury Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Venus-retrograde/${url}`}
														>
															Venus Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Mars-retrograde/${url}`}
														>
															Mars Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Jupiter-retrograde/${url}`}
														>
															Jupiter Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Saturn-retrograde/${url}`}
														>
															Saturn Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Uranus-retrograde/${url}`}
														>
															Uranus Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Neptune-retrograde/${url}`}
														>
															Neptune Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Pluto-retrograde/${url}`}
														>
															Pluto Retrograde
														</Link>
													</li>
													<li>
														<Link
															to={`/Chiron-retrograde/${url}`}
														>
															Chiron Retrograde
														</Link>
													</li>
												</ul>
											</div>

											<Link
												to={`/planetary-hours/${url}`}
											>
												Planetary Hours
											</Link>

											<div className="second-lvl-menu">
												<p>Planet in Signs</p>
												<i className="material-icons">
													arrow_drop_down
												</i>
												<ul className="sub-menu">
													<li>
														<Link
															to={`/Mercury-in-signs/${url}`}
														>
															Mercury in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Venus-in-signs/${url}`}
														>
															Venus in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Mars-in-signs/${url}`}
														>
															Mars in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Jupiter-in-signs/${url}`}
														>
															Jupiter in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Saturn-in-signs/${url}`}
														>
															Saturn in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Uranus-in-signs/${url}`}
														>
															Uranus in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Neptune-in-signs/${url}`}
														>
															Neptune in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Pluto-in-signs/${url}`}
														>
															Pluto in signs
														</Link>
													</li>
													<li>
														<Link
															to={`/Chiron-in-signs/${url}`}
														>
															Chiron in signs
														</Link>
													</li>
												</ul>
											</div>
											<Link to={`/panchang/${url}`}>
												Panchang
											</Link>
											<Link to="/articles">Articles</Link>
										</div>
									</li>

									<li className="dropdown-title">
										<p>
											Time Zones{" "}
											<i className="material-icons">
												arrow_drop_down
											</i>
										</p>
										<div>
											<Link to="/world-clock">
												World Clock
											</Link>
											<Link to="/unix-time-converter">
												Time Converters
											</Link>
											<Link to="/timezones">
												Worldwide Time Zones
											</Link>
											<Link
												to={`/trading-hours-of-world-stock-exchanges-${"local"}-time-type0/${
													this.props
														.currentSearchReducer.id
												}`}
											>
												Stock Exchanges
											</Link>

											<div className="second-lvl-menu">
												<p>Calculators</p>
												<i className="material-icons">
													arrow_drop_down
												</i>
												<ul className="sub-menu">
													<li>
														<Link to="/date-to-date-calculator">
															Date Calculator
														</Link>
													</li>
													<li>
														<Link to="/business-date-to-date-calculator">
															Business Calculator
														</Link>
													</li>
													<li>
														<Link to="/flight-time-calculator">
															Flight Time
															Calculator
														</Link>
													</li>
												</ul>
											</div>
										</div>
									</li>

									{/* TODO: add later */}
									{/* <li>
                    <Link to="/premium">Pricing</Link>
                  </li> */}

									{auth.first_name || isLoggedIn ? (
										<li className="dropdown-title">
											<span>
												Hi,{" "}
												{auth.first_name ||
													JSON.parse(isLoggedIn)
														.first_name}
											</span>
											<div>
												<Link to="/account">
													My Account
												</Link>
												<button onClick={this.signOut}>
													Sign Out
												</button>
											</div>
										</li>
									) : (
										<li className="dropdown-title">
											<Link
												to="/signin"
												className="account-preview-button"
											>
												Sign in{" "}
												<i className="material-icons">
													arrow_drop_down
												</i>
											</Link>
											<div>
												<Link to="/signup">
													Register
												</Link>
											</div>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
				</header>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form,
		currentSearchReducer: state.currentSearchReducer,
		authorizationReducer: state.authorizationReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch),
		eclipsesData: bindActionCreators(eclipsesData, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
