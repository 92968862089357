import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Prompt } from "react-router";
import { createBrowserHistory } from "history";
import moment from "moment-timezone";

import WorldRadioBtn from "./WorldRadioBtn";
import Search from "../../MainPage/Search/Search";
import { stockExchangesList } from "../../../../actions/action_stockExchanges";

const history = createBrowserHistory();

class Header extends Component {
  radioBtn = [
    { text: "Local Time", val: "local" },
    { text: "Show operating hours as per", val: "other" }
  ];
  radioBtnEx = [
    { text: "Stock Exchanges", val: "0" },
    { text: "Forex Exchanges", val: "1" }
  ];

  state = {
    titlePart: "in their Local Time",
    titleExchanges: "Stock Exchanges",
    radioBtnExVal: "0",
    redirect: false
  };

  componentDidMount() {
    const { name, abbr, id } = this.props.searchStockCityInfoReducer
      ? this.props.searchStockCityInfoReducer
      : this.props.currentSearchReducer;

    //components behavior depending on URL
    if (window.location.href.indexOf("-local-") !== -1) {
      if (window.location.href.indexOf("type0") !== -1)
        this.setTitlePart("local", "0", name, abbr, id, true);
      else this.setTitlePart("local", "1", name, abbr, id, true);
    } else {
      if (window.location.href.indexOf("type0") !== -1)
        this.setTitlePart("other", "0", name, abbr, id, true);
      else this.setTitlePart("other", "1", name, abbr, id, true);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const prevCity = this.props.searchStockCityInfoReducer
      ? this.props.searchStockCityInfoReducer
      : this.props.currentSearchReducer;

    const newCity = nextProps.searchStockCityInfoReducer
      ? nextProps.searchStockCityInfoReducer
      : nextProps.currentSearchReducer;

    const { name, abbr, id } = newCity;
    if (newCity.name !== prevCity.name) {
      this.setTitlePart(
        this.state.radioBtn,
        this.state.radioBtnExVal,
        name,
        abbr,
        id,
        true
      );
    }
  }

  getValueFromChild(value) {
    if (value !== this.state.radioBtn) {
      const { name, abbr, id } = this.props.searchStockCityInfoReducer
        ? this.props.searchStockCityInfoReducer
        : this.props.currentSearchReducer;
      this.setTitlePart(value, this.state.radioBtnExVal, name, abbr, id, true);
    }
  }
  getValueFromChildEx(value) {
    if (value !== this.state.radioBtnExVal) {
      const { name, abbr, id } = this.props.searchStockCityInfoReducer
        ? this.props.searchStockCityInfoReducer
        : this.props.currentSearchReducer;
      this.setTitlePart(this.state.radioBtn, value, name, abbr, id, true);
    }
  }

  setTitlePart(value, valueEx, name, abbr, id, pushTrue) {
    let url;
    const { stockExchangesList } = this.props;

    if (value === "local") {
      this.setState({
        titlePart: "in their Local Time",
        radioBtn: "local",
        titleExchanges: this.radioBtnEx[valueEx].text,
        radioBtnExVal: valueEx
      });
      valueEx == 0
        ? (url = `/trading-hours-of-world-stock-exchanges-${"local"}-time-type${valueEx}/${id}/`)
        : (url = `/trading-hours-of-world-forex-exchanges-${"local"}-time-type${valueEx}/${id}/`);
      if (pushTrue) history.replace(url);

      stockExchangesList(`?type=${valueEx}`);
    } else if (value === "other") {
      this.setState({
        titlePart: `- ${name} Time (${abbr})`,
        radioBtn: "other",
        titleExchanges: this.radioBtnEx[valueEx].text,
        radioBtnExVal: valueEx
      });
      let nameWithOutSpace = name.replace(/ /g, "_");
      valueEx == 0
        ? (url = `/trading-hours-of-world-stock-exchanges-${nameWithOutSpace}-time-type${valueEx}/${id}/`)
        : (url = `/trading-hours-of-world-forex-exchanges-${nameWithOutSpace}-time-type${valueEx}/${id}/`);
      if (pushTrue) history.replace(url);

      stockExchangesList(`?local=true&geoname_id=${id}&type=${valueEx}`);
    }

    this.props.updateMetaTags(value, valueEx);
  }

  firstSetCheckbox() {
    if (window.location.href.indexOf("-local-") !== -1) {
      return 0;
    } else {
      return 1;
    }
  }
  firstSetCheckboxEx() {
    if (window.location.href.indexOf("type1") !== -1) {
      return 1;
    } else {
      return 0;
    }
  }

  setRedirect = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { time, timezone } = this.props.searchStockCityInfoReducer
      ? this.props.searchStockCityInfoReducer
      : this.props.currentSearchReducer;
    const date = moment(time).tz(timezone);

    if (this.state.redirect) {
      return <Redirect push to="/" />;
    } else {
      return (
        <div>
          <Prompt message={() => this.setRedirect()} />
          <div className="world_header">
            <div className="content">
              <div className="row">
                <h1>List of World {this.state.titleExchanges}</h1>
                <WorldRadioBtn
                  data={this.radioBtnEx} // required
                  //direction='column'                                // not required
                  defNumbBoxCheck={this.firstSetCheckboxEx()} // required
                  select={value => this.getValueFromChildEx(value)} // required
                />
              </div>
              <div className="row">
                <h3>
                  Trading Hours of World {this.state.titleExchanges}{" "}
                  {this.state.titlePart}
                </h3>
                <WorldRadioBtn
                  data={this.radioBtn} // required
                  //direction='column'                                // not required
                  defNumbBoxCheck={this.firstSetCheckbox()} // required
                  select={value => this.getValueFromChild(value)} // required
                />
                <Search stockExchangesPage={true} />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="header-bottom-row">
              <span className="date-current">
                {date.format("dddd, MMMM D, YYYY")}
              </span>
              <div className="link">
                {this.props.authorizationReducer.username ? (
                  this.state.titleExchanges == "Stock Exchanges" ? (
                    <Link to="/account">
                      Click here to add Stock Exchange trading hours to your
                      events
                    </Link>
                  ) : (
                    <Link to="/account">
                      Click here to add Forex Exchange trading hours to your
                      events
                    </Link>
                  )
                ) : this.state.titleExchanges == "Stock Exchanges" ? (
                  <Link to="/signin">
                    Register / Sign in to add Stock Exchange trading hours to
                    your events
                  </Link>
                ) : (
                  <Link to="/signin">
                    Register / Sign in to add Forex Exchange trading hours to
                    your events
                  </Link>
                )}
              </div>
            </div>
            <div className="header-bottom-row">
              <h5>Note - Excluding Holidays</h5>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    authorizationReducer: state.authorizationReducer,
    stockExchange: state.stockExchange,
    currentSearchReducer: state.currentSearchReducer,
    searchStockCityInfoReducer: state.searchStockCityInfoReducer
  };
};

export default connect(mapStateToProps, { stockExchangesList })(Header);
