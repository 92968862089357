import React from "react";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";
import "./terms.css";

class Terms extends React.Component {
	componentDidMount() {
		Api.getTerms().then(result => {
			document.querySelector("#termsContent").innerHTML = result.data;
		});
	}

	render() {
		return (
			<div className="terms">
				<MetaTags>
					<title>GeoTimeDate – Terms of Service</title>
				</MetaTags>
				<div id="termsContent"></div>
			</div>
		);
	}
}

export default Terms;
