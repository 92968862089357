const options= {
  checked1: false,
  checked2: false,
  checked3: false,
  checked4: false
}
const timeZoneConverterOptionsReducer = (state = options, action) => {
  switch (action.type) {
    case 'TIME_ZONE_CONVERTER_OPTIONS':
      // return 
        switch(action.data){
          case '1':
            return {
              checked1: !state.checked1,
              checked2: state.checked2,
              checked3: state.checked3,
              checked4: state.checked4
          } ;
          case '2':
            return {
              checked1: state.checked1,
              checked2: !state.checked2,
              checked3: state.checked3,
              checked4: state.checked4
            };
          case '3':
            return {
              checked1: state.checked1,
              checked2: state.checked2,
              checked3: !state.checked3,
              checked4: state.checked4
            };
          case '4':
            return {
              checked1: state.checked1,
              checked2: state.checked2,
              checked3: state.checked3,
              checked4: !state.checked4
            };
          default:
              return state
        }
    default: 
      return state;
  }
};
  
export default timeZoneConverterOptionsReducer;