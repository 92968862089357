import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import moment from 'moment-timezone/index.js';

import './InformationBlocks.css';
import CityInfoMapBlocks from './CityInfoMapBlocks/CityInfoMapBlocks';
// import CurrentDateAndTime from './CurrentDateAndTime/CurrentDateAndTime';
// import TimeZones from './TimeZones/TimeZones';
// import SoonMoonPlanets from './SoonMoonPlanets/SoonMoonPlanets';
// import CalculatorsAndTimers from './CalculatorsAndTimers/CalculatorsAndTimers';
// import EphemerisForToday from './EphemerisForToday/EphemerisForToday';
// import News from './News/News';
import Loader from "../../../../components/Main/Loader/Loader";
import mainCityInfo from '../../../../actions/action_currentCity/action_mainCityInfo';
import currentCityInfo from '../../../../actions/action_currentCity/action_currentCityInfo';
import currentSearch from '../../../../actions/action_searchCity/action_currentSearch';

// import Api from '../../../../services/api.js';

class InformationBlocks extends Component {
    async componentDidMount(){
        // const { currentSearch, currentSearchReducer, mainCityInfo } = this.props;
        const { mainCityInfo } = this.props;
        if(localStorage.getItem('searchCityInfo')){
            mainCityInfo(JSON.parse(localStorage.getItem('searchCityInfo')));
        }
        // const cityInfoLoad = cityInfo => currentSearch(cityInfo);
        // if(window.location.pathname !== '/'){
        //     if(window.location.pathname.split('/')[1] === 'time-in'){
        //         const pathname = window.location.pathname;
        //         const newUrl = pathname.replace('/time-in/', '').replace(/\//g, '__');
        //         const data = await Api.userCityList(`geo_names/${newUrl}/`);
        //         cityInfoLoad(data.data);
        //     }
        // }else if(localStorage.getItem('mainSearchCity') && sessionStorage.getItem('sessionParam')){
        //     const data = await Api.userCityList(`geo_names/${JSON.parse(localStorage.getItem('mainSearchCity')).id}/`);
        //     cityInfoLoad(data.data);
        // }else if(localStorage.getItem('searchCityInfo')){
        //     const data = await Api.userCityList(`geo_names/${JSON.parse(localStorage.getItem('searchCityInfo')).id}/`);
        //     cityInfoLoad(data.data);
        // }else{
        //     const data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
        //     cityInfoLoad(data.data);
        // }
    }
    cityEclipse(){
        const { currentSearchReducer } = this.props;
        const name = currentSearchReducer.name;
        const country = currentSearchReducer.country_name === 'United States' ?
            currentSearchReducer.country_name_abbr : currentSearchReducer.country_name;
        const state = currentSearchReducer.state ? `${currentSearchReducer.state},` : ``;
        return `Current Time and Date in ${name}, ${state} ${country}`;
    }
    homeLocation(city){
        const { currentSearchReducer, mainCityInfoReducer } = this.props;
        if(mainCityInfoReducer.name){
            if(mainCityInfoReducer.name !== currentSearchReducer.name) {
                return city === 'city' ? mainCityInfoReducer.name : ` (change home location to ${currentSearchReducer.name})`;
            }else return city === 'city' ? mainCityInfoReducer.name : '';
        }
        else {
            const homeCity = currentSearchReducer.name;
            return city === 'city' ? 'None' : ` (set ${homeCity} as home location)`;
        }
    }
    setAsHomeCity = () => {
        const { currentSearch, currentSearchReducer, mainCityInfo } = this.props;
        localStorage.setItem('searchCityInfo', JSON.stringify(currentSearchReducer));
        currentSearch(currentSearchReducer);
        mainCityInfo(currentSearchReducer);
    };
    render () {
        const { currentSearchReducer } = this.props;
		if(!currentSearchReducer.time){
			return (
				<div className="InformationBlocks"><Loader/></div>
			)
        }
        return (
            <section className="InformationBlocks">
                <h3>{this.cityEclipse()}</h3>
                <span className='home-city'>
                    Current Home Location: {this.homeLocation('city')}<i onMouseUp={this.setAsHomeCity}>{this.homeLocation('set city')}</i>
                </span>
                <CityInfoMapBlocks />
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        currentCityInfoReducer: state.currentCityInfoReducer,
        mainCityInfoReducer: state.mainCityInfoReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
        currentCityInfo: bindActionCreators(currentCityInfo, dispatch),
        mainCityInfo: bindActionCreators(mainCityInfo, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationBlocks);