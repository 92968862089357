import React, { Component } from "react";
import { connect } from "react-redux";
import { stockExchangeSingle } from "../../../../actions/action_stockExchanges";
import advertisingRight from "../../../../assets/waters.jpg";

const arrEmpty = [
	{
		working_days: "",
		open_hours: "",
		lunch_time: "",
		sh: [
			{
				time_start: "",
				time_end: "",
				loc_time_start: "",
				loc_time_end: ""
			}
		]
	}
];
class Header extends Component {
	componentDidMount() {
		const { stockExchangeSingle, nameStock } = this.props;
		stockExchangeSingle(nameStock);
	}

	renderRowSchedule(arr) {
		//if (arr) {
		if (arr.length === 0) arr = arrEmpty;
		const {
			mic_code,
			abbr_stock,
			curentTime_stock
		} = this.props.stockExchangeSing.stock_exchanged[0];
		const newARr = [...arr];
		return newARr.map((item, index) => {
			return (
				<div className="row row-schedule" key={mic_code + index}>
					<div className="col-schedule">
						<h4>MIC Code</h4>
						<hr />
						<h4>{mic_code}</h4>
					</div>
					<div className="col-schedule">
						<h4>Trading Days</h4>
						<hr />
						<h4>{item.working_days}</h4>
					</div>
					<div className="col-schedule">
						<h4>Time Zone</h4>
						<hr />
						<h4>
							{abbr_stock} ({" "}
							{curentTime_stock && curentTime_stock.substring(26)}{" "}
							)
						</h4>
					</div>
					<div className="col-schedule">
						<h4>Opening Bell</h4>
						<hr />
						<h4>{item.sh[0].time_start.substring(0, 5)}</h4>
					</div>
					<div className="col-schedule">
						<h4>Closing Bell</h4>
						<hr />
						{!item.lunch_time && (
							<h4>{item.sh[0].time_end.substring(0, 5)}</h4>
						)}
						{item.lunch_time && (
							<h4>{item.sh[1].time_end.substring(0, 5)}</h4>
						)}
					</div>
					<div className="col-schedule">
						<h4>Lunch Break</h4>
						<hr />
						<h4>{item.lunch_time.replace("-", " - ") || "None"}</h4>
					</div>
				</div>
			);
		});
		//}
	}

	render() {
		const {
			name,
			abbr_stock,
			logo,
			symbol,
			city,
			state,
			country_name,
			schedule,
			status,
			web_site
		} = this.props.stockExchangeSing.stock_exchanged[0];

		return (
			<div className="world_exchanges_page">
				<div className="world_header">
					<div className="content">
						<h1>
							{name} Operating Hours ({abbr_stock})
						</h1>
					</div>
				</div>
				<div className="world_header world_header_gray">
					<div className="content">
						<div className="row start-v">
							<div className="info_stock">
								<div className="row center-g title_stock_row">
									<img
										className="logo_stock_exchange"
										src={`/${logo}`}
										alt="logo"
									/>
									<div className="title_stock">
										<h2 className="title_name">
											{name} ({symbol})
										</h2>
										<h3 className="title_location text-center">
											{country_name == "United States"
												? `${city}, ${state}, USA`
												: `${city}, ${country_name}`}
										</h3>
									</div>
									<div className="row">
										<i className="material-icons">alarm</i>
										{status === "OPEN" && (
											<h2
												className="text-center"
												style={{ color: "green" }}
											>
												{status}
											</h2>
										)}
										{status === "CLOSED" && (
											<h2
												className="text-center"
												style={{ color: "red" }}
											>
												{status}
											</h2>
										)}
										{status === "CLOSED (LUNCH)" && (
											<h2
												className="text-center"
												style={{ color: "red" }}
											>
												{status}
											</h2>
										)}
									</div>
								</div>
								{this.renderRowSchedule(schedule)}
							</div>
							<div className="block_baner">
								<img
									className="baner"
									src={advertisingRight}
									alt="альтернативный текст"
								></img>
							</div>
						</div>
						<div className="row more_info">
							<h6>
								For more information, visit -{" "}
								{/* eslint-disable-next-line react/jsx-no-target-blank */}
								<a href={`https://${web_site}`} target="_blank">
									{web_site}
								</a>
							</h6>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		stockExchangeSing: state.stockExchangeSing,
		currentSearchReducer: state.currentSearchReducer
	};
};

export default connect(mapStateToProps, { stockExchangeSingle })(Header);
