import React, { Component } from "react";

class Ticker extends Component {
	state = {
		shortText: ""
	};

	renderText = text => {
		return (
			<p
				style={{
					textOverflow: "ellipsis",
					display: "block",
					overflow: "hidden",
					whiteSpace: "nowrap",
					width: "100px",
					height: "17px"
				}}
			>
				{text}
			</p>
		);
	};

	setShortText = text => this.setState({ shortText: this.renderText(text) });

	renderLongText = () => {
		const L = 13;
		const { text } = this.props;

		if (text.length > L) {
			return (
				<p>
					<marquee>{text}</marquee>
				</p>
			);
		}
		return this.renderText(text);
	};

	render() {
		const { text } = this.props;
		const { shortText } = this.state;

		return (
			<div
				style={{
					overflowX: "hidden"
				}}
			>
				<div
					style={{
						position: "absolute",
						height: "17px",
						width: "100px",
						// display: 'none',
						cursor: "default",
						zIndex: 2
					}}
					onMouseOver={() => this.setShortText(this.renderLongText())}
					onMouseOut={() => this.setShortText(this.renderText(text))}
				></div>
				{shortText ? shortText : this.renderText(text)}
			</div>
		);
	}
}

export default Ticker;
