import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Slider from "material-ui/Slider";
import moment from "moment-timezone/index.js";
import { Paper, Set, Image, Text } from "react-raphael";

import "./GoogleMapDraw.css";
import advertising from "../../../../../assets/sun_page/970-90.jpg";
import taboola from "../../../../../assets/sun_page/taboola.png";
import MapWithMoonDraw from "./MapWithMoonDraw/MapWithMoonDraw";
import MoonChartsDraw from "./MoonChartsDraw/MoonChartsDraw";
import MoonRiseDayParts from "./MoonRiseDayParts/MoonRiseDayParts";
import MoonData from "./MoonData/MoonData";
import moonCalc from "../../../../../actions/action_moonRise/action_moonCalc";
import moonRiseDate from "../../../../../actions/action_moonRise/action_moonRiseDate";
import moonRisePosition from "../../../../../actions/action_moonRise/action_moonRisePosition";
import moonRiseCoords from "../../../../../actions/action_moonRise/action_moonRiseCoords";
import moonRiseTableList from "../../../../../actions/action_moonRise/action_moonRiseTableList";
import calendarDateMoon from "../../../../../actions/action_moonRise/action_calendarDateMoon";
import WaxingCrescentMoon from "../../../../../assets/moon_page/WaxingCrescentMoon.png";
import mapNightStyle from "../../../SunCulc/SunCityCalculatedBlock/GoogleMapDraw/mapNightStyle";
import MoonCulcRightBlockInfo from "../MoonCulcRightBlockInfo/MoonCulcRightBlockInfo";

import Api from "../../../../../services/api.js";

class GoogleMapDraw extends React.PureComponent {
	padding = 20;
	state = {
		// mapZoom: 10,
		moonLineColor: { stroke: "rgba(255, 249, 0, 0)", "stroke-width": 5 },
		moonCircleStyle: {
			stroke: "rgba(255, 255, 255, 0.55)",
			"stroke-width": 2,
			fill: "rgba(53, 53, 53, 0.55)"
		},
		radius: 300,
		chartWidth: 700,
		moonSliderValue: 1,
		moonPosition: {
			utc_time: "2017-10-17T04:00:59.999999Z",
			loc_time: "2017-10-17T00:00:59.999999-04:00",
			alt: -0.9565995931625366,
			az: 5.9318413734436035,
			dist: 149097178.59258798,
			xy: [0.4107469304277523, 0.2565065568583342],
			shadow: 1
		},
		latitude: 0,
		longitude: 0
	};
	radiusMap =
		Math.min(document.body.offsetWidth, document.body.offsetHeight) / 4;
	componentDidMount() {
		this.windowResize();
		window.addEventListener("resize", this.windowResize);

		const { moonCalcReducer, moonRiseCoordsReducer } = this.props;
		let width = 0;
		if (document.body.offsetWidth <= 991) {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth
			);
		} else {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
					document.querySelector(".GoogleMapBox").offsetWidth * 0.01
			);
		}
		this.setState(prevState => {
			return {
				radius: this.radiusMap,
				moonPosition: moonCalcReducer.moon_data.position_during_day[0],
				chartWidth: width,
				latitude: moonCalcReducer.latitude,
				longitude: moonCalcReducer.longitude
			};
		});
		const hour = moment(moonCalcReducer.moon_data.rise_loc)
			.tz(moonCalcReducer.timezone)
			.get("hour");
		const minute = moment(moonCalcReducer.moon_data.rise_loc)
			.tz(moonCalcReducer.timezone)
			.get("minute");
		const newTime = hour * 60 + minute;
		if (moonRiseCoordsReducer >= newTime) {
			this.setState(prevState => {
				return {
					moonLineColor: { stroke: "#fff900", "stroke-width": 5 },
					moonCircleStyle: {
						stroke: "#fff",
						"stroke-width": 2,
						fill: "#ffca00"
					}
				};
			});
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.windowResize);
	}
	componentDidUpdate() {
		const { moonRiseCoordsReducer } = this.props;
		this.setState(prevState => {
			return {
				moonSliderValue: moonRiseCoordsReducer
			};
		});
	}
	moonSlider = (e, value) => {
		const {
			moonCalcReducer,
			moonRisePosition,
			moonRiseCoords
		} = this.props;
		const moonPosition =
			moonCalcReducer.moon_data.position_during_day[value];
		if (moonPosition) {
			moonRisePosition(
				moonCalcReducer.moon_data.position_during_day[value]
			);
			moonRiseCoords(value);
			this.setState(prevState => {
				return {
					moonSliderValue: value,
					moonPosition:
						moonCalcReducer.moon_data.position_during_day[value]
				};
			});

			const hour = moment(moonCalcReducer.moon_data.rise_loc)
				.tz(moonCalcReducer.timezone)
				.get("hour");
			const minute = moment(moonCalcReducer.moon_data.rise_loc)
				.tz(moonCalcReducer.timezone)
				.get("minute");
			const newTime = hour * 60 + minute;
			return +value >= newTime
				? this.setState(prevState => {
					return {
						moonLineColor: {
							stroke: "rgba(255, 249, 0, 1)",
							"stroke-width": 5
						},
						moonCircleStyle: {
							stroke: "#fff",
							"stroke-width": 2,
							fill: "#ffca00"
						}
					};
				})
				: this.setState(prevState => {
					return {
						moonLineColor: {
							stroke: "rgba(255, 249, 0, 0)",
							"stroke-width": 5
						},
						moonCircleStyle: {
							stroke: "rgba(255, 255, 255, 0.55)",
							"stroke-width": 2,
							fill: "rgba(53, 53, 53, 0.55)"
						}
					};
				});
		}
	};
	// circleRadius(){
	// 	let circleRadius = 0;
	// 	if(document.querySelector('.GoogleMapBox')){
	// 		if(document.body.offsetWidth <= 991){
	// 			circleRadius =  Math.round((document.querySelector('.GoogleMapBox').offsetWidth) / 2 * 0.6);
	// 		}else{
	// 			circleRadius = Math.round((document.querySelector('.GoogleMapBox').offsetWidth * 0.76 -
	// 				document.querySelector('.GoogleMapBox').offsetWidth * 0.01) / 2 * 0.6);
	// 		}
	// 	} else circleRadius =  this.state.radius;
	// 	return Math.round(this.radiusMap);
	// }
	moonLineColor() {
		const {
			moonCalcReducer,
			moonRisePositionReducer,
			moonRiseCoordsReducer
		} = this.props;
		const hour = moment(moonCalcReducer.moon_data.rise_loc)
			.tz(moonCalcReducer.timezone)
			.get("hour");
		const minute = moment(moonCalcReducer.moon_data.rise_loc)
			.tz(moonCalcReducer.timezone)
			.get("minute");
		const newTime = hour * 60 + minute;
		if (moonRisePositionReducer) {
			return +moonRiseCoordsReducer >= newTime
				? { stroke: "rgba(255, 249, 0, 1)", "stroke-width": 5 }
				: { stroke: "rgba(255, 249, 0, 0)", "stroke-width": 5 };
		}
	}

	moonCircleStyle() {
		const {moonRisePositionReducer} = this.props;
		if (moonRisePositionReducer) {
			return +moonRisePositionReducer.alt > 0
				? {stroke: "#fff", "stroke-width": 2, fill: "#ffca00"}
				: {
					stroke: "rgba(255, 255, 255, 0.55)",
					"stroke-width": 2,
					fill: "rgba(53, 53, 53, 0.55)"
				};
		}
	}
	getPixelPositionOffset = (width, height) => ({
		x: -(width / 2),
		y: -(height / 2)
	});
	sliderTimeStart() {
		const { moonCalcReducer } = this.props;
		const nightParts = moonCalcReducer.moon_data.night_parts.filter(
			elem => elem
		);
		const timestamp = val =>
			moment(val)
				.tz(moonCalcReducer.timezone)
				.format("x");
		nightParts.sort((a, b) => {
			if (timestamp(a.time) > timestamp(b.time)) {
				return 1;
			}
			if (timestamp(a.time) < timestamp(b.time)) {
				return -1;
			}
			return 0;
		});
		const date = time =>
			moment(time)
				.tz(moonCalcReducer.timezone)
				.get("date");
		return nightParts.filter(elem => {
			return (
				date(elem.time) === date(moonCalcReducer.time) &&
				elem.name !== "Moon Culmination"
			);
		});
	}
	coordinatCalculation(time) {
		const { moonCalcReducer } = this.props;
		const hour = moment(time)
			.tz(moonCalcReducer.timezone)
			.get("hour");
		const minute = moment(time)
			.tz(moonCalcReducer.timezone)
			.get("minute");
		return hour * 60 + minute;
	}
	sliderStart() {
		const { moonCalcReducer } = this.props;
		const dotsLength = Object.values(
			moonCalcReducer.moon_data.position_during_day
		).length;
		const sliderTimeStart = this.sliderTimeStart();
		if (!sliderTimeStart[0]) return { width: 0 };
		const background =
			sliderTimeStart[0].name === "Moonset"
				? "#ffed9e"
				: "rgb(181, 181, 181)";
		if (!sliderTimeStart.length) return { width: 0 };
		const coordinatMoonStart = this.coordinatCalculation(
			sliderTimeStart[0].time
		);
		const moonStartPosition = (coordinatMoonStart * 100) / dotsLength;
		return { width: `${moonStartPosition}%`, background: background };
	}
	sliderPeriod() {
		const { moonCalcReducer } = this.props;
		const dotsLength = Object.values(
			moonCalcReducer.moon_data.position_during_day
		).length;
		const sliderTimeStart = this.sliderTimeStart();
		// console.log(sliderTimeStart)
		const background =
			sliderTimeStart[0].name === "Moonrise"
				? "#ffed9e"
				: "rgb(181, 181, 181)";
		switch (sliderTimeStart.length) {
			case 0:
				// console.log('0', sliderTimeStart)
				// eslint-disable-next-line no-case-declarations
				const culmination = moonCalcReducer.moon_data.night_parts.filter(
					elem => elem.name === "Moon Culmination"
				);
				return culmination[0].alt > 0
					? { width: "100%", background: "#ffed9e" }
					: { width: "100%", background: "rgb(181, 181, 181)" };
			case 1:
				// console.log('1', sliderTimeStart)
				// if(sliderTimeStart[0].name === 'Moonrise'){
				// eslint-disable-next-line no-case-declarations
				const coordinatMoon = this.coordinatCalculation(
					sliderTimeStart[0].time
				);
				// eslint-disable-next-line no-case-declarations
				const moonPosition =
					((dotsLength - coordinatMoon) * 100) / dotsLength;
				return { width: `${moonPosition}%`, background: background };
			// }else if(sliderTimeStart[0].name === 'Moonset'){
			// 	const coordinatMoonSet = this.coordinatCalculation(sliderTimeStart[0].time);
			// 	const moonPosition = coordinatMoonSet * 100 / dotsLength;
			// 	return { width: `${moonPosition}%`, background: background };
			// }
			case 2:
				// console.log('2', sliderTimeStart)
				// const moonRise = sliderTimeStart[0].name === 'Moonrise' ? sliderTimeStart[0].name : sliderTimeStart[1].name;
				// const moonSet = sliderTimeStart[0].name === 'Moonset' ? sliderTimeStart[0].name : sliderTimeStart[1].name;
				// eslint-disable-next-line no-case-declarations
				const coordinatMoonRise = this.coordinatCalculation(
					sliderTimeStart[0].name === "Moonrise"
						? sliderTimeStart[0].time
						: sliderTimeStart[1].time
				);
				// console.log(coordinatMoonRise)
				// eslint-disable-next-line no-case-declarations
				const coordinatMoonSet = this.coordinatCalculation(
					sliderTimeStart[0].name === "Moonset"
						? sliderTimeStart[0].time
						: sliderTimeStart[1].time
				);
				// console.log(coordinatMoonSet)
				// eslint-disable-next-line no-case-declarations
				const moonCenter =
					sliderTimeStart[0].name === "Moonrise"
						? coordinatMoonSet - coordinatMoonRise
						: coordinatMoonRise - coordinatMoonSet;
				// eslint-disable-next-line no-case-declarations
				const moonRiseSetPosition = (moonCenter * 100) / dotsLength;
				// console.log(moonCenter, moonRiseSetPosition)
				return {
					width: `${moonRiseSetPosition}%`,
					background: background
				};
			default:
				return { width: `0` };
		}
		// return { width: `100%`, background: background };
		// if(sliderTimeStart.length < 1) {
		// 	const culmination = moonCalcReducer.moon_data.night_parts.filter(elem => elem.name === 'Moon Culmination');
		// 	return culmination[0].alt > 0 ? { width: '100%', background: '#ffed9e' } : { width: '100%', background: 'rgb(181, 181, 181)' }
		// }
		// const moonRise = sliderTimeStart.filter(elem => elem.name === 'Moonrise');
		// const moonSet = sliderTimeStart.filter(elem => elem.name === 'Moonset');
		// if(moonRise.length && moonSet.length){
		// 	const coordinatMoonRise = this.coordinatCalculation(moonRise[0].time);
		// 	const coordinatMoonSet = this.coordinatCalculation(moonSet[0].time);
		// 	const moonCenter = sliderTimeStart[0].name === 'Moonrise' ? coordinatMoonSet - coordinatMoonRise : coordinatMoonRise - coordinatMoonSet;
		// 	const moonPosition = moonCenter * 100 / dotsLength;
		// 	return { width: `${moonPosition}%`, background: background };
		// }else if(moonRise.length){
		// 	const coordinatMoonRise = this.coordinatCalculation(moonRise[0].time);
		// 	const moonPosition = (dotsLength - coordinatMoonRise) * 100 / dotsLength;
		// 	return { width: `${moonPosition}%`, background: background };
		// }else if(moonSet.length){
		// 	const coordinatMoonSet = this.coordinatCalculation(moonSet[0].time);
		// 	const moonPosition = coordinatMoonSet * 100 / dotsLength;
		// 	return { width: `${moonPosition}%`, background: background };
		// }else return { width: `100%`, background: background };
	}
	sliderEnd() {
		const { moonCalcReducer } = this.props;
		const dotsLength = Object.values(
			moonCalcReducer.moon_data.position_during_day
		).length;
		const sliderTimeStart = this.sliderTimeStart();
		if (!sliderTimeStart[0]) return { width: 0 };
		const background =
			sliderTimeStart[0].name === "Moonset"
				? "#ffed9e"
				: "rgb(181, 181, 181)";
		if (!sliderTimeStart.length) return { width: 0 };
		else if (sliderTimeStart.length === 2) {
			const coordinatMoonStart = this.coordinatCalculation(
				sliderTimeStart[1].time
			);
			const moonStartPosition =
				((dotsLength - coordinatMoonStart) * 100) / dotsLength;
			return { width: `${moonStartPosition}%`, background: background };
		} else return { width: 0 };
	}
	sliderTime() {
		const { moonCalcReducer, moonRiseCoordsReducer } = this.props;
		const sliderLength = Object.values(
			moonCalcReducer.moon_data.position_during_day
		).length;
		return moonRiseCoordsReducer < sliderLength / 2
			? {
				left: `${moonRiseCoordsReducer / (sliderLength / 100)}%`,
				right: "auto"
			}
			: {
				left: "auto",
				right: `${(sliderLength - moonRiseCoordsReducer) /
				(sliderLength / 100)}%`
			};
	}
	chartWidth() {
		let chartWidth = 0;
		if (document.querySelector(".GoogleMapBox")) {
			if (document.body.offsetWidth <= 991) {
				chartWidth = Math.round(
					document.querySelector(".GoogleMapBox").offsetWidth
				);
			} else {
				chartWidth = Math.round(
					document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
						document.querySelector(".GoogleMapBox").offsetWidth *
							0.01
				);
			}
		} else chartWidth = this.state.chartWidth;
		return Math.round(chartWidth);
	}
	chartMoonInfoX(data) {
		if (data) {
			return data[0];
		} else return 0;
	}
	chartMoonInfoY(data) {
		if (data) {
			return data[1];
		} else return 0;
	}
	chartMoonSunInfoY() {
		const { moonCalcReducer } = this.props;
		const data = Object.values(
			moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info
		).filter(elem => elem.xy);
		if (data.length) return data[1].xy[1];
		else return 0;
	}
	chartMoonInfoName(data) {
		if (data.name === "Rise" && data.xy) return "Moonrise";
		else if (data.name === "Set" && data.xy) return "Moonset";
		else return data.xy ? data.name : "";
	}
	chartSunInfoName(name) {
		const { moonCalcReducer } = this.props;
		if (name === "Rise" && moonCalcReducer.sun_data.rise_loc)
			return "Sunrise";
		else if (name === "Set" && moonCalcReducer.sun_data.set_loc)
			return "Sunset";
		else return "";
	}
	moonRiseCoords(val) {
		return val ? val : 0;
	}
	windowResize = () => {
		let width = 0;
		if (document.querySelector(".GoogleMapBox").offsetWidth <= 991) {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth
			);
		} else {
			width = Math.round(
				document.querySelector(".GoogleMapBox").offsetWidth * 0.76 -
					document.querySelector(".GoogleMapBox").offsetWidth * 0.01
			);
		}
		this.setState(prevState => {
			return {
				radius: this.radiusMap,
				chartWidth: width
			};
		});
	};
	sliderStraightedge() {
		let time;
		if (document.body.offsetWidth <= 499) {
			time = ["0", "3", "6", "9", "12", "15", "18", "21"];
		} else if (document.body.offsetWidth <= 749) {
			time = [
				"0",
				"2",
				"4",
				"6",
				"8",
				"10",
				"12",
				"14",
				"16",
				"18",
				"20",
				"22"
			];
		} else {
			time = [
				"0",
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23"
			];
		}
		return time;
	}
	// mapZoomResizer = (zoom, latitude, longitude) => {
	// 	if(latitude === this.state.latitude && longitude === this.state.longitude) return zoom;
	// 	else {
	// 		this.setState(prevState => {
	// 			return {
	// 				latitude: latitude,
	// 				longitude: longitude
	// 			}
	// 		})
	// 		return this.state.mapZoom;
	// 	};
	// }
	async dayPeriodClick(elem) {
		const {
			moonCalcReducer,
			moonCalc,
			moonRiseDate,
			moonRiseCoords,
			moonRisePosition,
			calendarDateMoon
		} = this.props;
		const time = moment()
			.tz(moonCalcReducer.timezone)
			.format("YYYY-MM-DDTHH:mm:ss");
		moonRiseDate(time);
		// const moonInfo = await Api.userCityList(`moon_data/?geoname_id=${moonCalcReducer.id}&dt=${time}`);
		// moonRiseTableList(moonInfo.data.moon_data);

		const moonCoordinats = await Api.userCityList(
			`geo_names/${moonCalcReducer.id}/?moon_app=true&date=${time}`
		);
		moonCalc(moonCoordinats.data);
		localStorage.setItem(
			"moonCalcCityInfo",
			JSON.stringify(moonCoordinats.data)
		);
		calendarDateMoon(moonCoordinats.data.time);
		const hourCurrent = moment(moonCoordinats.data.time)
			.tz(moonCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(moonCoordinats.data.time)
			.tz(moonCoordinats.data.timezone)
			.get("minute");
		for (let key in moonCoordinats.data.moon_data.position_during_day) {
			const hourDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				moonCoordinats.data.moon_data.position_during_day[key].loc_time
			)
				.tz(moonCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				moonRiseCoords(+key);
				moonRisePosition(
					moonCoordinats.data.moon_data.position_during_day[+key]
				);
			}
		}
	}
	scaleDataPositive() {
		const { moonCalcReducer } = this.props;
		return moonCalcReducer.moon_data.moon_chart.moon_chart.scale_data.filter(
			elem => {
				return elem.degrees.slice(0, 1) !== "-";
			}
		);
	}
	scaleDataNegative() {
		const { moonCalcReducer } = this.props;
		return moonCalcReducer.moon_data.moon_chart.moon_chart.scale_data.filter(
			elem => {
				return elem.degrees.slice(0, 1) === "-";
			}
		);
	}
	sunMapStyle() {
		const { moonRisePositionReducer, moonCalcReducer } = this.props;
		const sunRiseTime = +moment(moonCalcReducer.sun_data.rise_loc)
			.tz(moonCalcReducer.timezone)
			.format("x");
		const sunSetTime = +moment(moonCalcReducer.sun_data.set_loc)
			.tz(moonCalcReducer.timezone)
			.format("x");
		const locTime = +moment(moonRisePositionReducer.loc_time)
			.tz(moonCalcReducer.timezone)
			.format("x");
		if (sunRiseTime < locTime && locTime < sunSetTime) return [];
		else return mapNightStyle;
	}
	render() {
		const {
			moonRiseCoordsReducer,
			moonCalcReducer,
			moonRisePositionReducer
		} = this.props;
		return (
			<div className="sunCulcCityInfoCenter">
				<MapWithMoonDraw
					sunMapStyle={this.sunMapStyle()}
					moonCalcStore={moonCalcReducer}
					getPixelPositionOffset={this.getPixelPositionOffset}
					radius={this.radiusMap}
					centerX={this.radiusMap + this.padding}
					centerY={this.radiusMap + this.padding}
					moonPosition={moonRisePositionReducer}
					moonCircleStyle={this.moonCircleStyle()}
					moonLineColor={this.moonLineColor()}
					// mapZoom={this.state.mapZoom}
				/>
				<MoonChartsDraw>
					<Paper width={20} height={200}>
						<Set>
							{this.scaleDataPositive().map((elem, index) => (
								<Text
									key={index}
									x={10}
									y={elem.y * 200 + 7}
									text={elem.degrees + ""}
									attr={{ fill: "#000" }}
								/>
							))}
							{this.scaleDataNegative().map((elem, index) => (
								<Text
									key={index}
									x={10}
									y={elem.y * 200 - 7}
									text={elem.degrees + ""}
									attr={{ fill: "#000" }}
								/>
							))}
						</Set>
					</Paper>
					<Paper width={this.chartWidth()} height={200}>
						<Set>
							<Image
								src={`data:png;base64,${moonCalcReducer.moon_data.moon_chart.moon_chart.img}`}
								x={0}
								y={0}
								width={this.chartWidth()}
								height={200}
							/>
							{
								<Text
									x={
										this.chartMoonInfoX(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.rise
												.xy
										) * +this.chartWidth()
									}
									y={
										this.chartMoonInfoY(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.rise
												.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartMoonInfoName(
										moonCalcReducer.moon_data.moon_chart
											.moon_chart.rise_set_info.rise
									)}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.rise.time}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.rise.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										this.chartMoonInfoX(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.noon
												.xy
										) * +this.chartWidth()
									}
									y={
										this.chartMoonInfoY(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.noon
												.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartMoonInfoName(
										moonCalcReducer.moon_data.moon_chart
											.moon_chart.rise_set_info.noon
									)}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.noon.time}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.noon.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										this.chartMoonInfoX(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.set.xy
										) * +this.chartWidth()
									}
									y={
										this.chartMoonInfoY(
											moonCalcReducer.moon_data.moon_chart
												.moon_chart.rise_set_info.set.xy
										) *
											200 +
										5
									}
									text={`
									${this.chartMoonInfoName(
										moonCalcReducer.moon_data.moon_chart
											.moon_chart.rise_set_info.set
									)}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.set.time}
									${moonCalcReducer.moon_data.moon_chart.moon_chart.rise_set_info.set.ang}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										moonCalcReducer.sun_data.coordinates
											.rise * +this.chartWidth()
									}
									y={this.chartMoonSunInfoY() * 200 + 5 - 46}
									text={`
									${this.chartSunInfoName("Rise")}
									${moment(moonCalcReducer.sun_data.rise_loc)
										.tz(moonCalcReducer.timezone)
										.format("HH:mm")}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							{
								<Text
									x={
										moonCalcReducer.sun_data.coordinates
											.set * +this.chartWidth()
									}
									y={this.chartMoonSunInfoY() * 200 + 5 - 46}
									text={`
									${this.chartSunInfoName("Set")}
									${moment(moonCalcReducer.sun_data.set_loc)
										.tz(moonCalcReducer.timezone)
										.format("HH:mm")}
								`}
									attr={{
										fill: "#000",
										"font-size": "12px",
										"font-weight": "bold"
									}}
								/>
							}
							<Image
								src={WaxingCrescentMoon}
								x={
									this.moonRiseCoords(
										moonCalcReducer.moon_data.moon_chart
											.moon_chart.xy[
											moonRiseCoordsReducer
										][0][0]
									) *
										+this.chartWidth() -
									10
								}
								y={
									this.moonRiseCoords(
										moonCalcReducer.moon_data.moon_chart
											.moon_chart.xy[
											moonRiseCoordsReducer
										][0][1]
									) *
										200 -
									10
								}
								width={20}
								height={20}
							/>
						</Set>
					</Paper>
				</MoonChartsDraw>
				<div className="color-slider-sunRise-wrapper">
					<ul className="color-slider-time">
						{this.sliderStraightedge().map((elem, index) => {
							return <li key={index}>{elem}</li>;
						})}
					</ul>
					<div className="color-slider-sunRise">
						<span className="slider-time" style={this.sliderTime()}>
							{moment(moonRisePositionReducer.loc_time)
								.tz(moonCalcReducer.timezone)
								.format("LT")}
						</span>
						{/* <div className="sunRise-before-day" style={this.moonRise()}></div>
						<div className="sunRise-day" style={this.moonCulmination()}></div>				
						<div className="sunRise-after-day" style={this.moonSet()}></div> */}
						<div
							className="sunRise-before-day"
							style={this.sliderStart()}
						></div>
						<div
							className="sunRise-day"
							style={this.sliderPeriod()}
						></div>
						<div
							className="sunRise-after-day"
							style={this.sliderEnd()}
						></div>
						<MuiThemeProvider>
							<Slider
								className="sunRise-slider"
								name="moonRiseSet"
								min={0}
								max={
									Object.values(
										moonCalcReducer.moon_data
											.position_during_day
									).length - 1
								}
								step={1}
								value={this.state.moonSliderValue}
								onChange={this.moonSlider}
							/>
						</MuiThemeProvider>
					</div>
				</div>
				<button
					onClick={this.dayPeriodClick.bind(this)}
					className="timeNow"
				>
					Current Time and Date
				</button>
				<MoonRiseDayParts />
				<div className="advertising-block-main">
					<img
						src={advertising}
						className="advertising"
						alt="advertising"
					/>
				</div>

				<MoonData />

				{window.innerWidth <= 980 ? <MoonCulcRightBlockInfo /> : null}

				<div className="taboola">
					<img
						src={taboola}
						alt="taboola"
						style={{ maxWidth: "100%" }}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer,
		moonRisePositionReducer: state.moonRisePositionReducer,
		moonRiseCoordsReducer: state.moonRiseCoordsReducer,
		moonRiseShadowReducer: state.moonRiseShadowReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		moonCalc: bindActionCreators(moonCalc, dispatch),
		moonRiseDate: bindActionCreators(moonRiseDate, dispatch),
		moonRisePosition: bindActionCreators(moonRisePosition, dispatch),
		moonRiseCoords: bindActionCreators(moonRiseCoords, dispatch),
		moonRiseTableList: bindActionCreators(moonRiseTableList, dispatch),
		calendarDateMoon: bindActionCreators(calendarDateMoon, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GoogleMapDraw);
