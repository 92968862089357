import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { components } from "react-select";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";

import authorization from "../../../../actions/authorization";
import Api from "../../../../services/api";
import "./EventsCalendars.css";

const customStyles = {
	control: (provided, state) => ({
		...provided,
		border: state.isFocused ? "1px solid #dddddd" : "1px solid #dddddd",
		boxShadow: "0 !important",
		"&:hover": {
			border: "1px solid #777777"
		},
		borderRadius: "5",
		height: "40px"
	}),
	indicatorSeparator: base => ({
		...base,
		display: "none"
	})
};

class EventsCalendars extends Component {
	state = {
		inputText: "",
		google: false,
		outlook: false,
		icloud: false,
		tabName: "",
		errorStyle: { opacity: 0 },

		exchanges_error: "",
		exchanges_for_sel: [
			{ abbr: "1", name: "Error Country Code" },
			{ abbr: "2", name: "Error Country Code 2" }
		],
		exchanges_for_sel_forex: [
			{ abbr: "1", name: "Error Country Code" },
			{ abbr: "2", name: "Error Country Code 2" }
		],
		exchanges: [],
		outlookEvents: [],
		icloudEvents: [],
		googleCalendarLink: true,
		googleCalendarData: []
	};

	imgOption = props => (
		<components.Option {...props}>
			<div style={{ display: "inline-flex" }}>
				<img
					src={props.data.flagPath}
					alt="geotimedate"
					style={{ height: "30px", margin: "-7px 10px" }}
				/>
				<span>{" " + props.data.title}</span>
			</div>
		</components.Option>
	);

	imgValue = props => (
		<components.SingleValue {...props}>
			<div style={{ display: "inline-flex" }}>
				<img
					src={props.data.flagPath}
					alt="geotimedate"
					style={{ height: "30px", margin: "-7px 10px" }}
				/>
				<span>{" " + props.data.title}</span>
			</div>
		</components.SingleValue>
	);

	componentDidMount() {
		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");

		this.getUserGoogleCalendars(token);
		this.getUserExchanges(token);
		this.getUserEvents(token);
	}

	async getUserExchanges(token) {
		if (token) {
			const data1 = await Api.getCache(
				await Api.UserRequestGetPost,
				"stockexchanges-for-sel/",
				token
			);
			const sel_auto = [];
			const sel_auto_forex = [];
			let flag = "";
			let dis = "";

			data1.data.forEach(elem => {
				if (elem.country_code === "") {
					flag = "";
					dis = true;
				} else {
					flag = `${Api.url}static/flags/flags-iso/flat/64/${elem.country_code}.png`;
					dis = false;
				}
				if (elem.type === "Stock exchanges") {
					sel_auto[elem.id] = {
						value: elem.id,
						title: elem.name,
						label: elem.name + " (" + elem.symbol + ")",
						flagPath: flag,
						isDisabled: dis
					};
				} else {
					sel_auto_forex[elem.id] = {
						value: elem.id,
						title: elem.name,
						label: elem.name + " (" + elem.symbol + ")",
						flagPath: flag,
						isDisabled: dis
					};
				}
			});
			this.setState({
				exchanges_for_sel: sel_auto,
				exchanges_for_sel_forex: sel_auto_forex
			});

			let dt = [{ act: "get_for_user" }];
			this.req_user_exchanged(dt, 0);
		}
	}

	async getUserEvents(token) {
		if (token) {
			const data = await Api.getAllEvents(token);
			if (data.data.outlook_sh) {
				this.setState({ outlookEvents: data.data.outlook_sh });
			}
			if (data.data.icloud_sh) {
				this.setState({ icloudEvents: data.data.icloud_sh });
			}
		}
	}

	async handleExchangeEditList(id, act) {
		let dt = [
			{
				act: act,
				stock_id: id
			},
			{ act: "get_for_user" }
		];

		this.req_user_exchanged(dt, 1);
	}

	async req_user_exchanged(dt, num_result) {
		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");

		if (token) {
			const data = await Api.UserRequestGetPost(
				"stock-exchanges-user/",
				token,
				dt
			);
			let data_res = [];
			let error = "";
			data.data.result.forEach(elem => {
				if (elem.ERROR !== undefined) error += " - " + elem.ERROR;
			});
			if (data.data.result[num_result].stock_exchanged !== undefined) {
				data_res = data.data.result[num_result].stock_exchanged;
			}

			this.setState({
				exchanges: data_res,
				exchanges_error: error
			});
			if (error !== "") {
				setTimeout(() => {
					this.setState({ exchanges_error: "" });
				}, 5000);
			}
		}
	}

	tabContent(serviceName) {
		const { authorizationReducer } = this.props;
		return (
			<Dialog
				title="Please use one of the methods."
				actions={[
					<FlatButton
						key={0}
						label="Cancel"
						primary={true}
						onClick={this.handleClose.bind(this, serviceName)}
					/>
				]}
				modal={false}
				open={this.state[serviceName]}
				onRequestClose={this.handleClose.bind(this, serviceName)}
			>
				<div className="signin-account-block">
					<label htmlFor="signin-link">Sign in with link</label>
					<input
						id="signin-link"
						onChange={this.inputTextChange}
					></input>
					<span className="error" style={this.state.errorStyle}>
						Please, enter correct link
					</span>
					<button
						data-account={serviceName}
						onClick={this.handleTabOpen.bind(this)}
					>
						Go
					</button>
				</div>

				{serviceName === "google" && (
					<p>
						You can sync your calendar using the link in{" "}
						{/* eslint-disable-next-line react/jsx-no-target-blank */}
						<a href="https://support.google.com/calendar/answer/37648" target="_blank">
							iCal
						</a>{" "}
						format
					</p>
				)}

				{/* <div className="signin-account-block">
          {serviceName !== "icloud" ? (
            <a href={authorizationReducer.calendar[`${serviceName}_auth`]}>
              Sign in with <span>{serviceName}</span>
            </a>
          ) : (
            ""
          )}
        </div> */}
			</Dialog>
		);
	}
	inputTextChange = e => {
		const text = e.target.value;
		this.setState({ inputText: text });
	};
	handleOpen = serviceName => {
		this.setState({ [serviceName]: true });
	};

	handleClose = serviceName => {
		this.setState({
			[serviceName]: false,
			inputText: "",
			errorStyle: { opacity: 0 }
		});
	};
	async handleTabOpen(e) {
		const { authorization } = this.props;
		const service = e.target.getAttribute("data-account");

		const token = localStorage.getItem("token")
			? localStorage.getItem("token")
			: sessionStorage.getItem("token");

		if (token) {
			const dataInfo = {
				[service]: this.state.inputText
			};
			const data = await Api.changeInfo(
				"set-calendars/",
				token,
				dataInfo
			);
			if (data.data.verify[service] !== "OK") {
				this.setState({
					errorStyle: { opacity: 1 }
				});
			}
			if (
				data.data.verify[service] === "OK" ||
				!Object.keys(data.data.verify).length
			) {
				const userInfo = await Api.userInfo("user-profile/", token);
				authorization({ ...userInfo.data.data, token: token });
				this.setState({
					[service]: false,
					inputText: "",
					errorStyle: { opacity: 0 }
				});
			}
		}
	}

	getUserGoogleCalendars = async token => {
		await Api.getGoogleCalendars(token).then(res => {
			if (res.status === 200) {
				if (res.data.result === "No key") {
					this.setState({ googleCalendarLink: res.data.url });
				} else {
					this.setState({ googleCalendarData: res.data.evnts });
				}
			}
		});
	};

	render() {
		const {
			exchanges,
			outlookEvents,
			icloudEvents,
			googleCalendarLink
		} = this.state;
		const { authorizationReducer } = this.props;
		const calendar = authorizationReducer.calendar;

		return (
			<div className="account-info events-calendars">
				<h4>
					<p>Add/Edit Events & Calendars</p>{" "}
					<span>
						{exchanges.length +
							outlookEvents.length +
							icloudEvents.length}{" "}
						of {authorizationReducer.status === "Free" ? "3" : "10"}{" "}
						events/calendars selected
					</span>
				</h4>
				<div className="calendars">
					<h5>Calendars</h5>
					<div className="calendar-block">
						<h5>Google Calendar</h5>

						{googleCalendarLink ? (
							// eslint-disable-next-line react/jsx-no-target-blank
							<a target="_blank"
								href={googleCalendarLink}
								className="google-sync-link"
							>
								SYNC
							</a>
						) : (
							<p className="google-synchronized">Synchronized</p>
						)}

						{/* {calendar.google_link === true ? (
							<button
								data-account="google"
								onClick={this.handleTabOpen.bind(this)}
							>
								X
							</button>
						) : (
							<MuiThemeProvider>
								<div className="modal-wrapper">
									<RaisedButton
										label="SYNC"
										onClick={this.handleOpen.bind(
											this,
											"google"
										)}
									/>
									{this.tabContent("google")}
								</div>
							</MuiThemeProvider>
						)} */}
					</div>
					<div className="calendar-block">
						<h5>Microsoft Outlook</h5>
						{calendar.outlook_link === true ? (
							<button
								data-account="outlook"
								onClick={this.handleTabOpen.bind(this)}
							>
								X
							</button>
						) : (
							<MuiThemeProvider>
								<div className="modal-wrapper">
									<RaisedButton
										label="SYNC"
										onClick={this.handleOpen.bind(
											this,
											"outlook"
										)}
									/>
									{this.tabContent("outlook")}
								</div>
							</MuiThemeProvider>
						)}
					</div>
					<div className="calendar-block">
						<h5>Apple Calendar</h5>
						{calendar.icloud_link === true ? (
							<button
								data-account="icloud"
								onClick={this.handleTabOpen.bind(this)}
							>
								X
							</button>
						) : (
							<MuiThemeProvider>
								<div className="modal-wrapper">
									<RaisedButton
										label="SYNC"
										onClick={this.handleOpen.bind(
											this,
											"icloud"
										)}
									/>
									{this.tabContent("icloud")}
								</div>
							</MuiThemeProvider>
						)}
					</div>
				</div>

				<div className="customise">
					<div
						style={{
							display: "inline-flex",
							width: "100%",
							marginTop: "30px"
						}}
					>
						<h5>Stock Exchanges</h5>
						<button
							className="add-exchanged"
							onClick={this.handleExchangeEditList.bind(
								this,
								0,
								"clear_all"
							)}
						>
							Clear all Exchanges
						</button>
					</div>

					{this.state.exchanges_error &&
					this.state.exchanges_error !==
						" - Exchanges for this user IS NOT FOUND" ? (
						<h5>
							<i style={{ color: "red" }}>
								{this.state.exchanges_error ===
									" - Can't add exchanges to the user. Maximum number = 10" ||
								this.state.exchanges_error ===
									" - Can't add exchanges to the user. Maximum number = 3" ? (
									authorizationReducer.status === "Free" ? (
										<span>
											The maximum number of exchanges /
											calendars have been selected for
											this account.{" "}
											<Link to="/upgrade-account">
												Please upgrade your account to
												add more.
											</Link>
										</span>
									) : (
										<span>
											The maximum number of exchanges /
											calendars have been selected for
											this account.
										</span>
									)
								) : (
									this.state.exchanges_error
								)}
							</i>
						</h5>
					) : null}

					<Select
						name="exchange_name"
						components={{
							Option: this.imgOption,
							SingleValue: this.imgValue
						}}
						classNamePrefix="Select Stock Exchanges"
						value={this.state.exchange_name}
						onChange={event =>
							this.handleExchangeEditList(event.value, "add")
						}
						options={this.state.exchanges_for_sel}
						placeholder="Select Stock Exchanges"
						styles={customStyles}
					/>

					<div>
						{this.state.exchanges.map((item, index) => {
							if (item.type === "Stock exchanges") {
								return (
									<div key={index}>
										<div
											style={{
												display: "inline-flex",
												margin: "10px",
												width: "100%"
											}}
										>
											<span style={{ width: "18px" }}>
												{index + 1}.
											</span>
											<img
												src={`/static/flags/flags-iso/flat/64/${item.country_code}.png`}
												alt="geotimedate"
												style={{
													height: "30px",
													margin: "-7px 10px"
												}}
											/>
											<div style={{ width: "78%" }}>
												<p>
													<Link
														to={`/${item.name_slug}-operating-hours-local-time/1111`}
													>
														{item.name} (
														{item.symbol})
													</Link>
												</p>
											</div>
											<i
												className="material-icons"
												style={{
													lineHeight: "0.5",
													cursor: "pointer",
													width: "20px"
												}}
												onClick={this.handleExchangeEditList.bind(
													this,
													item.id,
													"del"
												)}
											>
												delete_outline
											</i>
										</div>
									</div>
								);
							}
						})}
					</div>
					<div
						style={{
							display: "inline-flex",
							width: "100%",
							marginTop: "10px"
						}}
					>
						<h5>Forex Exchanges</h5>
					</div>
					<Select
						name="exchange_name_forex"
						components={{
							Option: this.imgOption,
							SingleValue: this.imgValue
						}}
						classNamePrefix="Select Forex Exchanges"
						value={this.state.exchange_name_forex}
						onChange={event =>
							this.handleExchangeEditList(event.value, "add")
						}
						options={this.state.exchanges_for_sel_forex}
						placeholder="Select Forex Exchanges"
						styles={customStyles}
					/>
					<div>
						{this.state.exchanges.map((item, index) => {
							if (item.type === "Forex exchanges") {
								return (
									<div key={index}>
										<div
											style={{
												display: "inline-flex",
												margin: "10px",
												width: "100%"
											}}
										>
											<span style={{ width: "18px" }}>
												{index + 1}.
											</span>
											<img
												src={`/static/flags/flags-iso/flat/64/${item.country_code}.png`}
												alt="geotimedate"
												style={{
													height: "30px",
													margin: "-7px 10px"
												}}
											/>
											<div style={{ width: "78%" }}>
												<p>
													<Link
														to={`/${item.name_slug}-operating-hours-local-time/1111`}
													>
														{item.name} (
														{item.symbol})
													</Link>
												</p>
											</div>
											<i
												className="material-icons"
												style={{
													lineHeight: "0.5",
													cursor: "pointer",
													width: "20px"
												}}
												onClick={this.handleExchangeEditList.bind(
													this,
													item.id,
													"del"
												)}
											>
												delete_outline
											</i>
										</div>
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authorizationReducer: state.authorizationReducer,
		exchanges: state.stockExchanges,
		markets: state.forexMarkets
	};
};
const mapDispatchToProps = dispatch => {
	return {
		authorization: bindActionCreators(authorization, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsCalendars);
