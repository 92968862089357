import {constants} from "../constants";
import {timePlanerService} from "../services/timePlaner.services";

const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');

function successSetCities() {return {type: constants.SET_CITIES,}}

const selectedCities = (value) => {
    return {
        type: 'SELECTED_CITIES',
        data: value
    };
};
export default selectedCities;

export const setCurrentLocation = (value) => {
    return dispatch => {
        dispatch(request(value));
        dispatch(successSetCities());
    };

    function request(value) {return {
        type: constants.SET_CURRENT_LOCATION,
        data: value
    }}
};

// export const addToEndCityList1 = (value, group) => {
//     return {
//         type: constants.ADD_TO_END_CITY_LIST,
//         data: {value, group}
//     };
// };
export const addToEndCityList = (token, value, group, position) => {
    return dispatch => {
        if (token) {
            timePlanerService.addMainCityToGroup(token, value, group, position)
            .then(
                () => {dispatch(setCitiesListForTimePlaner(token))},
                error => {dispatch(failure(error.toString()));}
            )
        } else {
            dispatch(successAddToEndCityList({value, group}))
        }
    };

    function successAddToEndCityList(data) {return {type: constants.ADD_TO_END_CITY_LIST, data}}
    function failure(error) {return {type: constants.GET_CITIES_FAILURE, error}}
};

export const setCurrentGroup = (groupId) => {
    return {
        type: constants.SET_CURRENT_GROUP,
        data: {groupId}
    };
};

export const setCitiesListForTimePlaner = (token, date, noAuthUserCities) => {
    return dispatch => {
        // dispatch(request());

        if (token){
            timePlanerService.getUserCitiesList(token, date)
            .then(
                data => {dispatch(successUserCitiesList(data));},
                error => {dispatch(failure(error.toString()));}
            ).then(()=>{dispatch(successSetCities())});
        } else {
            if (noAuthUserCities.length === 0) {
                timePlanerService.getCitiesList(date)
                    .then(
                        data => {
                            dispatch(successCitiesList(data));
                        },
                        error => {
                            dispatch(failure(error.toString()));
                        }
                    ).then(() => {
                    dispatch(successSetCities())
                });
            } else {
                timePlanerService.updateCitiesList(date, noAuthUserCities.map(e => e.id))
                    .then(
                        data => {
                            dispatch(updateCitiesList(data));
                        },
                        error => {
                            dispatch(failure(error.toString()));
                        }
                    ).then(() => {dispatch(successSetCities())})
            }

        }
    };

    // function request() {return {type: constants.GET_CITIES_REQUEST,}}
    function successUserCitiesList(data) {return {type: constants.GET_USER_CITIES_LIST_SUCCESS, data}}
    function successCitiesList(data) {return {type: constants.SET_DEFAULT_MAIN_CITIES, data}}
    function updateCitiesList(data) {return {type: constants.UPDATE_NO_AUTH_USER_CITY_LIST, data}}

    function failure(error) {return {type: constants.GET_CITIES_FAILURE, error}}
};


export const deleteCity = (city, index, token) => {
    return dispatch => {
        if (token){
            timePlanerService.deleteCity(city, token)
            .then(
                () => {dispatch(deleteCityFromUserCitiesList({...city, index}));},
                error => {dispatch(failure(error.toString()));}
            ).then(()=>{dispatch(successSetCities())});
        } else {
            dispatch(deleteCityFromNoAuthUserCitiesList({...city, index}));
            dispatch(successSetCities())
        }
    };

    function deleteCityFromNoAuthUserCitiesList(data) {return {type: constants.DELETE_CITY_FROM_NO_AUTH_USER_CITY_LIST, data}}
    function deleteCityFromUserCitiesList(data) {return {type: constants.DELETE_CITY_FROM_USER_CITY_LIST, data}}

    function failure(error) {return {type: constants.GET_CITIES_FAILURE, error}}
};


export const setAsHome = (city, index) => {
    return dispatch => {
        if (token){
            timePlanerService.setAsDefault(city)
            .then(
                () => {dispatch(setAsDefaultForAuth({...city, index}));},
                error => {dispatch(failure(error.toString()));}
            ).then(()=>{dispatch(successSetCities())});
        } else {
            dispatch(setAsDefaultForNoAuth({...city, index}));
            dispatch(successSetCities())
        }
    };

    function setAsDefaultForNoAuth(data) {return {type: constants.SET_AS_DEFAULT_FOR_NO_AUTH, data}}
    function setAsDefaultForAuth(data) {return {type: constants.SET_AS_DEFAULT_FOR_AUTH, data}}

    function failure(error) {return {type: constants.GET_CITIES_FAILURE, error}}
};

