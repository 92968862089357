import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone/index.js';
import gpsTime from "gps-time";

// import './Calculator.css';
import currentSearch from '../../../../../actions/action_searchCity/action_currentSearch';
import {bindActionCreators} from "redux";



class ShowCurentGPS extends Component {

    state={
        kay:0
    }

    currentTime = setInterval(() => {
         let a = this.state.kay;
        a += a+1;
        this.setState({kay: a})
    }, 1000);

     componentWillUnmount(){
        window.clearTimeout(this.currentTime);
        this.isCancelled = true;
    }

    render() {
         const {timezone} = this.props;
        return (
            <div className='input'>
                <p><b>
                    {(Math.round(gpsTime.toGPSMS(+moment.tz(timezone).format('x')))/1000).toFixed(0)}
                </b><span></span></p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCurentGPS);