import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import moment from 'moment-timezone/index.js';
// import { MuiThemeProvider, DatePicker } from 'material-ui';

import './BlockSettings.css';
// import flightTimeInfo from '../../../../../../actions/action_flightTimeInfo.js';


class BlockSettings extends Component {

    state={
        exclude_weekends: false,
        exclude_holidays: false,
        public_holidays: false,
        exclude_days: [],
        checkedWeek: '',
        checkedHoli: '',
        checkedPublicHoli: '',
        checkedDateSpecial: '',
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: '',
        Sunday: '',
        selectDefoult: '',
        openSpecial: {display : 'none'},
        openSettBlock: {display : 'none'},
        openExclude: {display : 'block'},
        nameButton: 'CUSTOM SETTINGS',
        in_ex_days: 'exclude',
        dateSpecial: '',
        value: false,
    };
    UNSAFE_componentWillMount(){

    }
    calendarChange(startEnd, event, date) {
        //this.setState({value: true});
        this.setState(prevState => {
            if(startEnd === 'start') return { dateSpecial: date, value: true };
        });
    }
    changeExclude = (e) => {
        this.setState({value: true});
        //console.log(e.target.name, e.target.checked)
        const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        if (e.target.name === 'Weekends' && e.target.checked === true){
            this.setState({
                exclude_weekends: true,
                checkedWeek: 'checked',
            })
        }else if (e.target.name === 'Weekends' && e.target.checked === false){
            this.setState({
                exclude_weekends: false,
                checkedWeek: '',
            })
        }else if (e.target.name === 'Holidays' && e.target.checked === true){
            this.setState({
                exclude_holidays: true,
                checkedHoli: 'checked',
            })
        }else if (e.target.name === 'Holidays' && e.target.checked === false){
            this.setState({
                exclude_holidays: false,
                checkedHoli: '',
            })
        }else if (e.target.name === 'Public Holiday' && e.target.checked === true){
            this.setState({
                public_holidays: true,
                checkedPublicHoli: 'checked',
            })
        }else if (e.target.name === 'Public Holiday' && e.target.checked === false){
            this.setState({
                public_holidays: false,
                checkedPublicHoli: '',
            })
        }else if (e.target.name === 'Specific day' && e.target.checked === true){
            this.setState({
                 openSpecial: {display : 'block'},
                 checkedDateSpecial: 'checked',
            })
        }else if (e.target.name === 'Specific day' && e.target.checked === false){
            this.setState({
                openSpecial: {display : 'none'},
                checkedDateSpecial: '',
                dateSpecial: '',
            })
        }

        let setExcludeDays = this.state.exclude_days;
        let positiveArr = [];
        week.forEach((item, index) => {
            if (e.target.name === item && e.target.checked === true ){
                setExcludeDays.push(index);
                this.setState({
                    exclude_days: [...setExcludeDays],
                    [item]: 'checked',
                })
            }else if (e.target.name === item && e.target.checked === false ){
                positiveArr = setExcludeDays.filter(function(number) {
                  return number !== index;
                });
                this.setState({
                    exclude_days: [...positiveArr],
                    [item]: '',
                })
            }
        });
    };
    inExDaysChange = event => {
        this.setState({value: true})
        this.setState({in_ex_days: event.target.value,})
    };
    toggleBlockSettings = () =>{
        this.setState({value: true})
        if(this.state.nameButton === 'CUSTOM SETTINGS'){
            this.setState({
                nameButton: 'LESS OPTIONS',
                openSettBlock: {display : 'block'},
                openExclude: {display : 'none'},
                exclude_weekends: false,
                exclude_holidays: false,
                public_holidays: false,
                checkedWeek: '',
                checkedHoli: '',
                Monday: '',
                Tuesday: '',
                Wednesday: '',
                Thursday: '',
                Friday: '',
                Saturday: '',
                Sunday: '',
                checkedPublicHoli: '',
                checkedDateSpecial: '',
                dateSpecial: '',
                selectDefoult: 'defaultValue',
            });
        }
        else{
            this.setState({
                nameButton: 'CUSTOM SETTINGS',
                openSettBlock: {display : 'none'},
                openExclude: {display : 'block'},
                in_ex_days: 'exclude',
                exclude_days: [],
            })
        }
    };
    setParamsInReducer(){
        let standard, custom, typeRQ;
        standard = {
            exclude_weekends: this.state.exclude_weekends,
            exclude_holidays: this.state.exclude_holidays,
        };
        custom = {
            exclude_days: this.state.exclude_days,
            in_ex_days: this.state.in_ex_days,
            dateSpecial: this.state.dateSpecial,
            public_holidays: this.state.public_holidays,
        };
        typeRQ = this.state.nameButton;
        this.props.setSettings(standard, custom, typeRQ);
        this.setState({value: false})
    }
    render() {
        //console.log('props 2', this.props);
        //console.log('state 2', this.state);
        if(this.state.selectDefoult === 'defaultValue') {
            this.setState({
                selectDefoult: '',
            })
        }
        if(this.state.value) this.setParamsInReducer();
        return (
            <div className='blockSettings'>
                <div className='row align-start'>
                    <div style={this.state.openExclude} className='check'>
                        <label>
                                <input type="checkbox" name="Weekends" checked={this.state.checkedWeek} onChange={this.changeExclude}/>
                                <span className="pseudocheckbox">Exclude Weekends</span>
                        </label>
                    </div>
                    <div style={this.state.openExclude} className='check'>
                        <label>
                                <input type="checkbox" name="Holidays" checked={this.state.checkedHoli} onChange={this.changeExclude}/>
                                <span className="pseudocheckbox">Exclude Public Holidays</span>
                        </label>
                    </div>
                </div>
                <div style={this.state.openSettBlock} className='row center'>
                    <div className='blockShowSettings'>
                        <div className='row align-start'>
                            <div className='column'>
                                <div className='input-select'>
                                     <select onChange={this.inExDaysChange} selected={this.state.selectDefoult}>
                                          <option  value="exclude">Exclude</option>
                                          <option  value="include">Include only</option>
                                    </select>
                                 </div>
                            </div>
                            <div className='column'>
                                <div className='check daySelect'>
                                    <label>
                                            <input type="checkbox" name="Monday" checked={this.state.Monday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Monday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Tuesday" checked={this.state.Tuesday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Tuesday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Wednesday" checked={this.state.Wednesday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Wednesday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Thursday" checked={this.state.Thursday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Thursday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Friday" checked={this.state.Friday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Friday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Saturday" checked={this.state.Saturday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Saturday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Sunday" checked={this.state.Sunday}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Sunday</span>
                                    </label>
                                    <label>
                                            <input type="checkbox" name="Public Holiday" checked={this.state.checkedPublicHoli}  onChange={this.changeExclude}/>
                                            <span className="pseudocheckbox">Public Holiday</span>
                                    </label>
                                    {/*<label>*/}
                                        {/*<input type="checkbox" name="Specific day" checked={this.state.checkedDateSpecial}  onChange={this.changeExclude}/>*/}
                                        {/*<span className="pseudocheckbox specific_day">Specific day</span>*/}
                                        {/*<div style={this.state.openSpecial}>*/}
                                            {/*<MuiThemeProvider>*/}
                                                {/*<DatePicker*/}
                                                    {/*hintText="Specific day"*/}
                                                    {/*value={this.state.dateSpecial !== '' ? this.state.dateSpecial : null}*/}
                                                    {/*onChange={this.calendarChange.bind(this, 'start')}*/}
                                                    {/*autoOk={true}*/}
                                                    {/*formatDate={new Intl.DateTimeFormat('en-US', {*/}
                                                        {/*day: '2-digit',*/}
                                                        {/*month: 'long',*/}
                                                        {/*year: 'numeric',*/}
                                                    {/*}).format}*/}
                                                {/*/>*/}
                                            {/*</MuiThemeProvider>*/}
                                        {/*</div>*/}
                                    {/*</label>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row align-start '>
                    <div className='blockCurrentButton'>
                        <button className='currentButton' onClick={this.toggleBlockSettings}>{this.state.nameButton}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        businesDTDReducer: state.businesDTDReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
         setSettings: (standard, custom, typeRQ) => {
            //console.log('value', result)
            const payload = {
                standard,
                custom,
                typeRQ,
          };
          dispatch({ type: 'PASS_SETTINGS', payload });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockSettings);