import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";
import sunCalc from "../../../../../actions/action_sunRise/action_sunCalc";
import sunRisePosition from "../../../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../../../actions/action_sunRise/action_sunRiseCoords";
import selectedCities, {
  addToEndCityList
} from "../../../../../actions/action_selectedCities";

import Api from "../../../../../services/api.js";
import "./TimePickerSearch.css";

class TimePickerSearch extends Component {
  state = {
    cities: [],
    selectedCity: "",
    selectedCityInfo: [],
    border: "none",
    searchOpen: { border: "none", height: 0, margin: "0" },
    addedCity: {},
    notification: { opacity: 0, height: 0, padding: 0, zIndex: "-1" },
    listElemIndex: "none",
    selectedElemIndex: "null",
    mousePosTop: 0,
    mousePosLeft: 0
  };
  displayNotification = () => {
    const searchNotification = window.setTimeout(() => {
      this.setState(prevState => {
        return {
          notification: {
            opacity: 0,
            height: 0,
            padding: 0,
            zIndex: "-1"
          }
        };
      });
    }, 5000);
    window.clearTimeout(this.notification);
    this.notification = searchNotification;
  };
  searchClose = document.body.addEventListener("click", e => {
    if (
      e.target.className !== "search_form_list_timepicker" &&
      e.target.className !== "search_field_timepicker"
    ) {
      this.setState(prevState => {
        return {
          searchOpen: { border: "none", height: 0, margin: 0 }
        };
      });
    }
  });
  keydownSearchClose = document.body.addEventListener("keydown", e => {
    if (e.keyCode === 27) {
      this.setState(prevState => {
        return {
          searchOpen: { border: "none", height: 0, margin: 0 }
        };
      });
    }
  });
  componentWillUnmount() {
    window.clearTimeout(this.notification);
    document.removeEventListener("click", this.searchClose);
    document.removeEventListener("keydown", this.keydownSearchClose);
  }
  async componentDidMount() {
    this.searchKeyPress();
    this.searchKeyEnter();
  }
  searchValue = e => {
    const value = e.target.value.replace(/\s+/g, " ");
    this.setState({ selectedCity: value });
    this.searchValueList(value);
  };
  async searchValueList(value) {
    const { getOptionsFromCountry } = this.props;
    if (value.length > 2) {
      const data = await Api.userCityList(
        `search/?q=${value}&feature_classes=P&add_dst=True`
      );

      if (getOptionsFromCountry) {
        getOptionsFromCountry(data.data);
      }
      document.body
        .querySelector(".search_form_list_timepicker")
        .scrollTo(0, 0);
      if (this.state.selectedCity.length > 2) {
        this.setState({
          listElemIndex: 0,
          cities: data.data.locations_list,
          ColorSchemes: data.data.ColorSchemes,
          ColorSchemesWeekend: data.data.ColorSchemesWeekend,
          working_hours_start: data.data.working_hours_start,
          working_hours_end: data.data.working_hours_end,
          searchOpen: { height: "auto", margin: "5px 0 0" },
          searchErrorStyle: !data.data.locations_list.length
            ? { display: "block" }
            : {}
        });
      }
    } else {
      this.setState({
        listElemIndex: 0,
        cities: [],
        searchOpen: { border: "none", height: 0, margin: "0" },
        searchErrorStyle: {}
      });
    }
  }
  citySelection(elem) {
    const {
      selectedCitiesReducer,
      authorizationReducer,
      getSelectCity,
      addToEndCityList
    } = this.props;
    if (getSelectCity) {
      getSelectCity(elem);
    }

    let newName = "",
      newState = "",
      newCounty = "",
      newCountry = "",
      newFeature = "";
    elem.name ? (newName = `${elem.name}, `) : (newName = "");
    elem.state ? (newState = `${elem.state}, `) : (newState = "");
    elem.county ? (newCounty = `${elem.county}, `) : (newCounty = "");
    elem.country ? (newCountry = `${elem.country}`) : (newCountry = "");
    elem.feature ? (newFeature = `(${elem.feature})`) : (newFeature = "");

    elem.ColorSchemes = this.state.ColorSchemes;
    elem.ColorSchemesWeekend = this.state.ColorSchemesWeekend;
    elem.working_hours_start = this.state.working_hours_start;
    elem.working_hours_end = this.state.working_hours_end;

    // console.log('elem!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', elem)
    this.setState(prevState => {
      return {
        selectedCity: `${newName}${newState}${newCounty}${newCountry} ${newFeature}`,
        border: "none"
      };
    });
    let citiesValue;
    if (authorizationReducer.username) {
      switch (authorizationReducer.status) {
        case "Pro":
          citiesValue = 10;
          break;
        case "Basic":
          citiesValue = 10;
          break;
        default:
          citiesValue = 4;
          break;
      }
    } else citiesValue = 4;
    if (selectedCitiesReducer.cities.length < citiesValue) {
      const { currentGroup, dontUpdateSelectedCities } = this.props;
      if (!dontUpdateSelectedCities) {
        addToEndCityList(
          selectedCitiesReducer.token,
          elem,
          currentGroup,
          selectedCitiesReducer.cities.length
        );
      }
    } else if (selectedCitiesReducer.cities.length >= citiesValue) {
      this.setState({
        notification: { opacity: "1", height: "58px", padding: "20px" }
      });
      this.displayNotification();
    }
  }
  selectedCity(elem, e) {
    if (e.button === 0) this.citySelection(elem);
  }
  // search: handling of pressing the arrows (up, down)
  searchKeyPress() {
    document.body.addEventListener("keydown", e => {
      const cityList = [
        ...document.body.querySelectorAll(".selectCityWrapperTimepicker")
      ];
      const scrollBlock = document.body.querySelector(
        ".search_form_list_timepicker"
      );
      let height;
      const pressNext = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top <= scrollBlock.offsetHeight - height
          ? 0
          : top - scrollBlock.offsetHeight + height;
      };
      const pressPrev = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top;
      };
      if (
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex >= cityList.length - 1)
      ) {
        this.setState(prevState => {
          scrollBlock.scrollTo(0, pressNext(0));
          return { listElemIndex: 0 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 40 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressNext(prevState.listElemIndex + 1);
          if (scrollBlock.scrollTop < topPos) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex + 1 };
        });
      } else if (
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex <= 0)
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(cityList.length - 1);
          scrollBlock.scrollTo(0, topPos);
          return { listElemIndex: cityList.length - 1 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 38 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(prevState.listElemIndex - 1);
          if (topPos < scrollBlock.scrollTop) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex - 1 };
        });
      }
    });
  }
  // search: enter press
  searchKeyEnter() {
    document.body.addEventListener("keydown", e => {
      if (this.state.cities.length > 0 && e.keyCode === 13) {
        const selectedCity = this.state.cities.filter((elem, index) => {
          return index === this.state.listElemIndex;
        });
        this.citySelection(selectedCity[0]);
        this.setState(prevState => {
          return {
            searchOpen: { border: "none", height: 0, margin: "0" },
            cities: [],
            selectedCity: ""
          };
        });
      }
    });
  }
  // search: mouse pointer processing
  searchMouseEventMouseOver = (index, e) => {
    let mouseTop = e.screenY;
    let mouseLeft = e.screenX;
    this.setState(prevState => {
      if (
        mouseTop !== prevState.mousePosTop ||
        mouseLeft !== prevState.mousePosLeft
      ) {
        return {
          listElemIndex: index,
          mousePosTop: mouseTop,
          mousePosLeft: mouseLeft
        };
      }
    });
  };

  searchListHover = (index, e) => {
    this.setState(prevState => {
      return {
        selectedElemIndex: index,
        cities: [],
        selectedCity: ""
      };
    });
  };
  searchStyle(index) {
    if (this.state.listElemIndex === index) {
      return { background: "rgba(103, 150, 206, 1)", color: "#fff" };
    } else {
      return { background: "#fff", color: "rgba(68, 68, 68, 1)" };
    }
  }
  highlight(highlight) {
    return highlight === true ? {} : { fontWeight: "normal" };
  }
  notEmptyName(param) {
    return param ? `${param}` : "";
  }
  notEmptyState(param) {
    return param ? `${param}, ` : "";
  }
  notEmptyFeature(param) {
    return param ? ` (${param})` : "";
  }
  searchFocus = e => {
    if (this.state.cities.length > 0) {
      this.setState(prevState => {
        return {
          searchOpen: { height: "auto", margin: "5px 0 0" }
        };
      });
    }
  };
  render() {
    const { stockExchangePage } = this.props;

    return (
      <section className="Search">
        <div className="search_form">
          <div className="search_form_select">
            {!stockExchangePage ? (
              <div
                className="timepicker-notification"
                style={this.state.notification}
              >
                The maximum number of cities have been added to this group
              </div>
            ) : null}

            <input
              className="search_field_timepicker"
              type="text"
              value={this.state.selectedCity}
              placeholder={stockExchangePage ? "CHANGE CITY" : "ADD CITY"}
              onChange={this.searchValue}
              onMouseUp={this.searchFocus}
            />
            <div
              className="timepickerSearchError"
              style={this.state.searchErrorStyle}
            >
              <span>
                We couldn’t find any results for {this.state.selectedCity}
              </span>
            </div>
            <ul
              style={this.state.searchOpen}
              className="search_form_list_timepicker"
            >
              {this.state.cities.map((elem, index) => (
                <li key={index}>
                  <div
                    className="selectCityWrapperTimepicker"
                    style={this.searchStyle(index)}
                    onMouseDown={this.selectedCity.bind(this, elem)}
                    onClick={this.searchListHover.bind(this, index)}
                    onMouseMove={this.searchMouseEventMouseOver.bind(
                      this,
                      index
                    )}
                  >
                    <div className="mainNameWrapper">
                      <b
                        className="cityMainName"
                        style={this.highlight(elem.highlight)}
                      >
                        {this.notEmptyName(elem.name)}
                      </b>
                      {this.notEmptyFeature(elem.feature)}
                      <img
                        className="cityMainImg"
                        src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                        alt="citydateandtime"
                      />
                    </div>
                    {this.notEmptyState(elem.state)}
                    {this.notEmptyState(elem.county)}
                    {this.notEmptyName(elem.country_name)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    sunCalcReducer: state.sunCalcReducer,
    selectedCitiesReducer: state.selectedCitiesReducer,
    authorizationReducer: state.authorizationReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    sunCalc: bindActionCreators(sunCalc, dispatch),
    sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
    sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch),
    selectedCities: bindActionCreators(selectedCities, dispatch),
    addToEndCityList: bindActionCreators(addToEndCityList, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePickerSearch);
