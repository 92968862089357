export default function(values) {
	const errors = {};
	const requiredFields = ["new_password1", "new_password2"];
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = "Required";
		}
	});
	if (values.new_password1 !== values.new_password2) {
		errors.new_password2 = "Passwords do not match";
	}
	if (values.new_password1) {
		if (values.new_password1.length < 8) {
			errors.new_password1 =
				"Password must contain at least 8 characters";
		}
	}
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = "Invalid email address";
	}
	return errors;
}
