export const constants = {
    SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
    ADD_TO_END_CITY_LIST: 'ADD_TO_END_CITY_LIST',
    SET_DEFAULT_MAIN_CITIES: 'SET_DEFAULT_MAIN_CITIES',

    GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',

    GET_USER_CITIES_LIST_SUCCESS: 'GET_USER_CITIES_LIST_SUCCESS',

    SET_CITIES: 'SET_CITIES',
    DELETE_CITY_FROM_USER_CITY_LIST: 'DELETE_CITY_FROM_USER_CITY_LIST',
    DELETE_CITY_FROM_NO_AUTH_USER_CITY_LIST: 'DELETE_CITY_FROM_NO_AUTH_USER_CITY_LIST',
    SET_AS_DEFAULT_FOR_NO_AUTH: 'SET_AS_DEFAULT_FOR_NO_AUTH',
    SET_AS_DEFAULT_FOR_AUTH: 'SET_AS_DEFAULT_FOR_AUTH',

    AUTHORIZATION: 'AUTHORIZATION',
    SET_CURRENT_GROUP: 'SET_CURRENT_GROUP',
    UPDATE_NO_AUTH_USER_CITY_LIST: 'UPDATE_NO_AUTH_USER_CITY_LIST',

};
