import React, { Component } from 'react';

import './PlanetesInSignsData.css';

class PlanetesInSignsData extends Component {
    render () {
        return (
            <div className="RetrogradeData Planet-In-Signs">{this.props.children}</div>
        )
    }
}

export default PlanetesInSignsData;