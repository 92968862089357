import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import gpsTime from "gps-time";

import "./CityInfoMapBlocks.css";
import WorldClock from "./WorldClock/WorldClock";
import WorldClockUTC from "./WorldClockUTC/WorldClockUTC";
import PlacesOfInterestMap from "./PlacesOfInterestMap/PlacesOfInterestMap";
import WeatherBlock from "./WeatherBlock/WeatherBlock";
import Panchang from "./Panchang";
import PlanetInfo from "./PlanetInfo/PlanetInfo";
import LinksBlock from "./LinksBlock/LinksBlock";
import HolidaysBlock from "./HolidaysBlock/HolidaysBlock";
import AirportsBlock from "./AirportsBlock/AirportsBlock";
import dstzonesImg from "../../../../../assets/CityPage/geotimedate-dst-world-map.png";
import timezonesImg from "../../../../../assets/CityPage/timezone.jpg";
import sunLogo from "../../../../../assets/sun_page/sun.jpg";
import moonLogo from "../../../../../assets/moon_page/moon_page_logo.jpg";
import advertisingWeatherOff from "../../../../../assets/sun_page/798-90.png";
import advertisingWeatherOn from "../../../../../assets/sun_page/336-280.png";
import weatherInfo from "../../../../../actions/action_weatherInfo.js";
import weatherInfoOpen from "../../../../../actions/action_weatherInfoOpen.js";
import eclipsesData from "../../../../../actions/action_eclipsesData";
import sunCalc from "../../../../../actions/action_sunRise/action_sunCalc";
import moonCalc from "../../../../../actions/action_moonRise/action_moonCalc";
import holidays from "../../../../../assets/main_page_blocks/dummy_1.jpg";
import dummy from "../../../../../assets/main_page_blocks/dummy_2.jpg";
import meetingPlanner from "../../../../../assets/main_page_blocks/meeting_planner_box.jpg";

import Api from "../../../../../services/api.js";

class CityInfoMapBlocks extends Component {
  planets = [
    "Mercury",
    "Venus",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto",
    "Chiron",
  ];
  state = {
    languagesModal: false,
    showPanchangForMobile: true,
  };

  async componentDidMount() {
    const { currentSearchReducer, weatherInfoOpen } = this.props;
    if (currentSearchReducer.expand_weather === true) weatherInfoOpen(true);

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // weather loading
    const {
      weatherInfo,
      weatherInfoReducer,
      weatherInfoOpenReducer,
    } = this.props;
    if (!weatherInfoReducer.latitude) {
      const weather = await Api.userCityList(
        `weather/${currentSearchReducer.latitude},${currentSearchReducer.longitude}/`
      );
      weatherInfo(weather.data);
    }
    weatherInfoOpen(!weatherInfoOpenReducer);
  }
  currentTime = setInterval(() => {
    this.setState({ time: moment() });
  }, 1000);

  componentWillUnmount() {
    clearInterval(this.currentTime);
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth > 1080) {
      this.setState({ showPanchangForMobile: false });
    } else {
      this.setState({ showPanchangForMobile: true });
    }
  };

  julianTime(timezone) {
    const dayMs = 60 * 60 * 24; //86400
    let J1970 = 2440587.5;
    return (moment().tz(timezone).unix() / dayMs + J1970).toFixed(5);
  }

  sunRisePositionNS() {
    const { currentSearchReducer } = this.props;
    const lat = currentSearchReducer.latitude;
    if (lat > 0) return `${lat.toFixed(2)}${String.fromCharCode(176)} N`;
    else if (lat < 0) return `${lat.toFixed(2)}${String.fromCharCode(176)} S`;
    else if (lat === 0) return "";
  }

  sunRisePositionEW() {
    const { currentSearchReducer } = this.props;
    const lon = currentSearchReducer.longitude;
    if (lon > 0) return `${lon.toFixed(2)}${String.fromCharCode(176)} E`;
    else if (lon < 0)
      return `${Math.abs(lon.toFixed(2))}${String.fromCharCode(176)} W`;
    else if (lon === 0) return "";
  }
  timeDifference() {
    const { currentSearchReducer, defaultCityInfoReducer } = this.props;
    if (currentSearchReducer.id || defaultCityInfoReducer.id) {
      const homeCityTz = moment().tz(defaultCityInfoReducer.timezone);
      const searchCityTz = moment().tz(currentSearchReducer.timezone);
      const hours =
        +homeCityTz.format("Z").split(":")[0] -
        +searchCityTz.format("Z").split(":")[0];
      let minutes =
        homeCityTz.get("minutes") - searchCityTz.get("minutes") + "";
      if (minutes === "0") minutes = "00";
      const time =
        minutes.slice(0, 1) === "-"
          ? `${hours}.${minutes.slice(1)}`
          : `${hours}.${minutes}`;

      const city = currentSearchReducer.name;
      const country =
        currentSearchReducer.country_name === "United States"
          ? currentSearchReducer.country_name_abbr
          : currentSearchReducer.country_name;

      const homeCityDiff = homeCityTz.format("Z").split(":");
      const earchCityDiff = searchCityTz.format("Z").split(":");
      if (
        +homeCityDiff[0] * 60 + +homeCityDiff[1] >
        +earchCityDiff[0] * 60 + +homeCityDiff[1]
      ) {
        return `${time} hours Ahead of ${city}, ${country}`;
      } else if (
        +homeCityDiff[0] * 60 + +homeCityDiff[1] ===
        +earchCityDiff[0] * 60 + +homeCityDiff[1]
      ) {
        return "home city has same Time Zone";
      } else {
        return `${time} hours Before of ${city}, ${country}`;
      }
    }
  }
  getLenguages(langElem, langIndex, langArr) {
    const name = langElem.title.split(/\W/)[0];
    if (langIndex + 1 === langArr.length) return `${name}.`;
    else if (langIndex <= 2) return `${name}, `;
  }
  modalLanguage() {
    const { currentSearchReducer } = this.props;
    if (currentSearchReducer.country_data.languages.length > 10) return "...";
  }
  languagesModalContent() {
    const { currentSearchReducer } = this.props;
    let arr = currentSearchReducer.country_data.languages.map(
      (elem, index) => elem.title.split(/\W/)[0]
    );
    return arr.join(", ");
  }
  languagesModalOpen = () => {
    this.setState({ languagesModal: true });
  };

  languagesModalClose = () => {
    this.setState({ languagesModal: false });
  };
  getPhoneNumber() {
    const { currentSearchReducer } = this.props;
    let arr = currentSearchReducer.country_data.phone_codes.map((elem) => {
      return "+" + elem.code;
    });
    arr = arr.join(", ");
    return arr;
  }

  getBusinesWeek() {
    const { currentSearchReducer } = this.props;
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let arr = [];
    currentSearchReducer.country_data.weekends.forEach((elem) => {
      days.forEach((item, index) => {
        if (+elem === index) arr.push(item);
      });
    });
    arr = arr.join("/");
    return arr;
  }
  cityName(nameType) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;

    let state = "";
    if (nameType === "cityFullName")
      state = currentSearchReducer.state
        ? `${currentSearchReducer.state},`
        : ``;
    else if (nameType === "cityShortName") {
      state =
        currentSearchReducer.country_name === "United States"
          ? currentSearchReducer.state
            ? `${currentSearchReducer.state},`
            : ""
          : ``;
    } else state = "";
    return `${name}, ${state} ${country}`;
  }
  advertisingBlockStyle() {
    const { weatherInfoOpenReducer } = this.props;
    return weatherInfoOpenReducer === true ? { display: "none" } : {};
  }
  stateHeading() {
    const { currentSearchReducer } = this.props;
    return currentSearchReducer.feature_code_name === "mountain"
      ? "Mountain"
      : "State";
  }
  eclipseInfoGet() {
    // const { eclipsesData, currentSearchReducer } = this.props;
    const { eclipsesData } = this.props;
    localStorage.removeItem("eclipses");
    // eclipsesData({id: currentSearchReducer.id, timezone: currentSearchReducer.timezone});
    eclipsesData({});
  }
  titleCityName(nameType) {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;

    let state = "";
    if (nameType === "cityFullName")
      state = currentSearchReducer.state
        ? `${currentSearchReducer.state},`
        : ``;
    else if (nameType === "cityShortName") {
      state =
        currentSearchReducer.country_name === "United States"
          ? `${currentSearchReducer.state},`
          : ``;
    } else state = "";
    return `${name}, ${state} ${country}`;
  }
  dstCheck(start) {
    const { currentSearchReducer } = this.props;
    const tz = currentSearchReducer.timezone;
    const from = currentSearchReducer.date_from.date;
    const to = currentSearchReducer.date_to.date;
    if (from && to) {
      switch (start) {
        case "from":
          return moment(from).tz(tz).format("ll");
        case "from add":
          return moment(from).tz(tz).format(`z (UTC Z)`);
        case "to":
          return moment(to).tz(tz).format("ll");
        case "title":
          if (
            +moment(from).tz(tz).format("x") < +moment().tz(tz).format("x") &&
            +moment().tz(tz).format("x") < +moment(to).tz(tz).format("x")
          ) {
            return "Current DST";
          } else return "Next DST";
        default:
          return moment().tz(tz).format();
      }
    } else if (!from && to) {
      return `No DST sinse ${moment(from).tz(tz).format("ll")}`;
    } else return "No DST";
  }
  startsStyle(date) {
    return date ? {} : { display: "none" };
  }
  setSunInfo = (e) => {
    // if(e.button === 0 || e.button === 1){
    const { sunCalc } = this.props;
    localStorage.removeItem("sunCalcCityInfo");
    sunCalc({});
    // }
  };
  setMoonInfo = (e) => {
    // if(e.button === 0 || e.button === 1){
    const { moonCalc } = this.props;
    localStorage.removeItem("moonCalcCityInfo");
    moonCalc({});
    // }
  };
  render() {
    const { showPanchangForMobile } = this.state;
    const { currentSearchReducer } = this.props;
    const url = currentSearchReducer.url
      ? currentSearchReducer.url.split("/").slice(1).join("/")
      : `/sun/null/`;
    return (
        // eslint-disable-next-line react/no-string-refs
      <section className="CityInfoMapBlocks" ref="almanac" id="almanac">
        <div className="city-info-map-blocks InformationBlocks">
          <div className="enlarge_clock">
            <div className="clock_block">
              <div className="clock">
                <div className="city-name">
                  <h4>Local Time in</h4>
                  <h4>{currentSearchReducer.name}</h4>
                </div>
                <WorldClock />
                <div className="hover_effect">
                  <b>
                    {moment.tz(currentSearchReducer.timezone).format("LTS (z)")}
                  </b>
                  <em>
                    {moment
                      .tz(currentSearchReducer.timezone)
                      .format("dddd, MMMM DD, YYYY")}
                  </em>
                </div>
              </div>
              <div className="clock">
                <div className="city-name">
                  <h4>UTC Time</h4>
                </div>
                <WorldClockUTC />
                <div className="hover_effect">
                  <b>{moment.tz("Greenwich").format("LTS (UTC)")}</b>
                  <em>
                    {moment.tz("Greenwich").format("dddd, MMMM DD, YYYY")}
                  </em>
                </div>
              </div>
            </div>
            <div className="timezone_block">
              <div className="clock">
                <div className="hover_effect time-difference">
                  <img src={timezonesImg} alt="Time Zone world map" />
                  <strong>
                    <Link
                      to="/timezones"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Time Zone &#9656;
                    </Link>
                  </strong>
                  <em>{currentSearchReducer.timezone}</em>
                  <em>(IANA Zone Identifier)</em>
                  <em>
                    {moment
                      .tz("Greenwich")
                      .tz(currentSearchReducer.timezone)
                      .format("z")}{" "}
                    (UTC {moment.tz(currentSearchReducer.timezone).format("Z")})
                  </em>
                </div>
              </div>
              <div className="clock">
                <div className="hover_effect time-difference">
                  <img
                    src={dstzonesImg}
                    alt="Daylight Savings Time (DST) world map"
                  />
                  <strong>{this.dstCheck("title")}</strong>
                  {this.dstCheck("from") !== "No DST" && (
                    <em
                      style={this.startsStyle(
                        currentSearchReducer.date_from.date
                      )}
                    >
                      Starts: {this.dstCheck("from")}
                    </em>
                  )}
                  {this.dstCheck("from add") !== "No DST" && (
                    <em
                      style={this.startsStyle(
                        currentSearchReducer.date_from.date
                      )}
                    >
                      {this.dstCheck("from add")}
                    </em>
                  )}
                  <em
                    style={this.startsStyle(currentSearchReducer.date_to.date)}
                  >
                    Ends: {this.dstCheck("to")}
                  </em>
                </div>
              </div>
            </div>
            <div className="currentCityInfo world-clock-info">
              <div className="hover_effect">
                <b>
                  <strong>Unix Time:</strong>{" "}
                  {moment.tz(currentSearchReducer.timezone).unix()}
                </b>
                <Link to="/unix-time-converter">
                  <b>Unix Time Converter</b>
                </Link>
              </div>
              <div className="hover_effect">
                <b>
                  <strong>Julian Time:</strong>{" "}
                  {this.julianTime(currentSearchReducer.timezone)}
                </b>
                <Link to="/julian-time-converter">
                  <b>Julian Time Converter</b>
                </Link>
              </div>
              <div className="hover_effect">
                <b>
                  <strong>GPS Time:</strong>{" "}
                  {Math.round(
                    gpsTime.toGPSMS(
                      +moment.tz(currentSearchReducer.timezone).format("x")
                    ) / 1000
                  )}
                </b>
                <Link to="/gps-time-converter">
                  <b>GPS Time Converter</b>
                </Link>
              </div>
            </div>
            <div className="currentCityFooter">
              <Link to={`/world-clock`}>World Clock</Link>
              <Link
                to={`/trading-hours-of-world-stock-exchanges-${"local"}-time-type0/${
                  currentSearchReducer.id
                }`}
              >
                World Stock Exchanges
              </Link>
            </div>
          </div>

          <div className="sun-moon-info">
            <div className="sun-moon-block-wrapper">
              <div className="sun-moon-block">
                <div className="sun-block">
                  <div className="info-block">
                    <div className="img-block">
                      <img src={sunLogo} alt="Sun Almanac" />
                    </div>
                    <div className="info">
                      <b>
                        <span>Sunrise:</span>{" "}
                        {moment(currentSearchReducer.sun_moon.sun.rise_loc)
                          .tz(currentSearchReducer.timezone)
                          .format("LT")}
                      </b>
                      <b>
                        <span>Sunset :</span>{" "}
                        {moment(currentSearchReducer.sun_moon.sun.set_loc)
                          .tz(currentSearchReducer.timezone)
                          .format("LT")}
                      </b>
                      <b>
                        <span>Day Length (hrs):</span>
                        {currentSearchReducer.sun_moon.sun.day_length}
                      </b>
                      <b>
                        <span>Solar Noon:</span>{" "}
                        {moment(currentSearchReducer.sun_moon.sun.noon_time)
                          .tz(currentSearchReducer.timezone)
                          .format("LT")}
                      </b>
                    </div>
                  </div>
                  <div className="info-block">
                    <Link
                      to={`/sun/${url}`}
                      onMouseUp={this.setSunInfo}
                      className="img-block"
                    >
                      Sun Almanac &#9656;
                    </Link>
                    <Link
                      to={`/solar-eclipse/${url}/${moment(
                        currentSearchReducer.sun_moon.sun.next_visible_eclipse
                          .next_solar_eclipse
                      )
                        .tz(currentSearchReducer.timezone)
                        .format("YYYY-MM-DD")}`}
                      className="info"
                      onClick={this.eclipseInfoGet.bind(this)}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      <b>Next Solar Eclipse &#9656;</b>
                      <b>in {this.cityName("cityShortName")}</b>
                      <b>
                        {moment(
                          currentSearchReducer.sun_moon.sun.next_visible_eclipse
                            .next_solar_eclipse
                        )
                          .tz(currentSearchReducer.timezone)
                          .format("ll")}
                      </b>
                    </Link>
                  </div>
                </div>
                <div className="sunMoonLine"></div>
                <div className="moon-block">
                  <div className="info-block">
                    <div className="img-block">
                      <img src={moonLogo} alt="Moon Almanac" />
                    </div>
                    <div className="info">
                      <b>
                        <span>Moonrise:</span>{" "}
                        {moment(currentSearchReducer.sun_moon.moon.rise_loc)
                          .tz(currentSearchReducer.timezone)
                          .format("LT")}
                      </b>
                      <b>
                        <span>Moonset:</span>{" "}
                        {moment(currentSearchReducer.sun_moon.moon.set_loc)
                          .tz(currentSearchReducer.timezone)
                          .format("LT")}
                      </b>
                      <b>
                        <span>Full Moon:</span>{" "}
                        {currentSearchReducer.sun_moon.moon.full_moon}
                      </b>
                      <b>
                        <span>New Moon:</span>{" "}
                        {currentSearchReducer.sun_moon.moon.new_moon}
                      </b>
                    </div>
                  </div>
                  <div className="info-block">
                    <Link
                      to={`/moon/${url}`}
                      onMouseUp={this.setMoonInfo}
                      className="img-block"
                    >
                      Moon Almanac &#9656;
                    </Link>
                    <Link
                      to={`/lunar-eclipse/${url}/${moment(
                        currentSearchReducer.sun_moon.moon
                          .next_visible_eclipse[1]
                      )
                        .tz(currentSearchReducer.timezone)
                        .format("YYYY-MM-DD")}`}
                      className="info"
                      onClick={this.eclipseInfoGet.bind(this)}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      <b>Next Lunar Eclipse &#9656;</b>
                      <b>{this.cityName("cityShortName")}</b>
                      <b>
                        {moment(
                          currentSearchReducer.sun_moon.moon
                            .next_visible_eclipse[1]
                        )
                          .tz(currentSearchReducer.timezone)
                          .format("ll")}
                      </b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="currentCityFooter">
              <b>Coming Soon</b>
              <span>Rise and set times for -</span>
              {this.planets.map((elem, index, arr) => (
                <Link to={"/"} key={index}>
                  {arr.length === index + 1 ? `and ${elem}` : `${elem}, `}
                </Link>
              ))}
              {/* <Link to={'/'} className={'equinoxes-solstices'}>Equinoxes & Solstices for {this.cityName('cityFullName')}</Link> */}
            </div>
          </div>

          <h3 className="almahac-title almahac-title_desk">
            Current Almanac for {this.titleCityName("cityFullName")}
          </h3>

          <div className="meeting-planner">
            <div className="meeting-planner-box meeting-planner-box_top">
              <h2 className="meeting-planner__title">Meeting Planner</h2>
              <p className="meeting-planner__desc">
                The most convenient and easy way to schedule meeting, calls, and
                webinars across multiple time zones.
              </p>
              <img src={meetingPlanner} alt="Meeting planner" />
            </div>
            <div className="meeting-planner-box meeting-planner-box_bottom">
              <p className="meeting-planner-features__title">Features</p>
              <ul className="meeting-planner-features">
                <li>
                  Add cities across multiple time zones adjusted for Daylight
                  Saving Time.
                </li>
                <li>
                  Sync multiple calendars without having to keep a tab on
                  important events.
                </li>
                <li>
                  See the trading hours of Stock/Forex exchanges across the
                  globe.
                </li>
                <li>Create groups with multiple cities.</li>
                <li>
                  Set custom working hours and mark weekends for you business
                  contacts and friends.
                </li>
              </ul>
              <a
                href="/meeting-planner"
                target="_blank"
                className="meeting-planner__button"
              >
                Click here to explore
              </a>
            </div>
          </div>

          <h3 className="almahac-title almahac-title_mob">
            Current Almanac for {this.titleCityName("cityFullName")}
          </h3>

          {showPanchangForMobile && <Panchang />}

          <PlanetInfo />

          <div className="city-info">
            <div className="currentCityInfo">
              <div className="hover_effect">
                <b>
                  <span>Latitude:</span> <span>{this.sunRisePositionNS()}</span>
                </b>
                <b>
                  <span>Longitude:</span>{" "}
                  <span>{this.sunRisePositionEW()}</span>
                </b>
                <b>
                  <span>{this.stateHeading()}:</span>{" "}
                  <span>{currentSearchReducer.state}</span>
                </b>
                <b>
                  <span>Country:</span>{" "}
                  <span>{currentSearchReducer.country_name}</span>
                </b>
                <b>
                  <span>Capital:</span>{" "}
                  <span>{currentSearchReducer.country_data.capital}</span>
                </b>
              </div>
              <div className="hover_effect currency_block">
                <b>
                  <span>Currency:</span>
                  <span className="currency">
                    <i>
                      <span>
                        {currentSearchReducer.country_data.currency_name.replace(
                          "_",
                          " "
                        )}{" "}
                        <em className="symbol">
                          {currentSearchReducer.country_data.currency_symbol}
                        </em>
                      </span>
                    </i>
                    <i>
                      <span>
                        ({currentSearchReducer.country_data.currency})
                      </span>
                    </i>
                  </span>
                </b>
                {/*
                                <b className='under_link_city-info'>
                                    <span></span>
                                    <span className='currency'>
                                        <i>
                                            <span></span>
                                            <em>
                                                <Link to={'/'}>Currency Convertor</Link>
                                            </em>
                                        </i>
                                    </span>
                                </b>
*/}
                <b>
                  <span>Languages:</span>
                  <span>
                    {currentSearchReducer.country_data.languages.map(
                      (langElem, langIndex, langArr) => (
                        <i key={langIndex}>
                          {this.getLenguages(langElem, langIndex, langArr)}
                        </i>
                      )
                    )}
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={this.languagesModalOpen}
                    >
                      {this.modalLanguage()}
                    </i>
                  </span>
                </b>
                <MuiThemeProvider>
                  <Dialog
                    title="Languages"
                    actions={[
                      <FlatButton
                          key={0}
                        label="Cancel"
                        primary={true}
                        keyboardFocused={true}
                        onClick={this.languagesModalClose}
                      />,
                    ]}
                    modal={false}
                    open={this.state.languagesModal}
                    onRequestClose={this.languagesModalClose}
                  >
                    {this.languagesModalContent()}
                  </Dialog>
                </MuiThemeProvider>

                <b>
                  <span>Dial codes:</span>
                  <span className="currency">
                    <i>
                      <span>{this.getPhoneNumber()}</span>
                    </i>
                  </span>
                </b>

                {/*<b>
                                    <span>Dial codes:</span>
                                    <span>{this.getPhoneNumber()}</span>
                                </b>
                                <div className='under_link_city-info'>
                                    <Link to={'/'}><em>Dialling Guide</em></Link>
                                </div>*/}
              </div>
              <PlacesOfInterestMap
                currentSearchReducer={currentSearchReducer}
              />
            </div>
          </div>

          <div className="weather-collapse">
            <div className="weather-block-header">
              <div className="weather-name">
                <h3>Current Weather in {this.cityName("cityShortName")}</h3>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a target="_blank"
                  className="darksky"
                  href={`https://darksky.net/poweredby`}

                >
                  Powered by Dark Sky
                </a>
              </div>
            </div>
            <div className="city-info-map-blocks">
              <WeatherBlock />
            </div>
          </div>

          <div className="city-info-map-blocks  city-info-map-blocks_updated">
            <div className="upcoming-holidays">
              <img src={holidays} alt="Holidays" />
              <HolidaysBlock />
            </div>
          </div>

          {showPanchangForMobile && (
            <div className="city-info-map-blocks world-clock-links-block">
              <LinksBlock />
              <img src={dummy} className="dummy-img" alt="Ads" />
              <AirportsBlock />
            </div>
          )}
        </div>

        {/* <div
          className="advertising-block-header"
          style={this.advertisingBlockStyle()}
        >
          <div className="advertising-header">
            <img
              src={advertisingWeatherOff}
              className="advertising"
              alt="advertising"
            />
          </div>
        </div> */}

        {!showPanchangForMobile && (
          <div className="city-info-map-blocks world-clock-links-block">
            <LinksBlock />
            <img src={dummy} className="dummy-img" alt="Ads" />
            <AirportsBlock />
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    weatherInfoReducer: state.weatherInfoReducer,
    weatherInfoOpenReducer: state.weatherInfoOpenReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    weatherInfo: bindActionCreators(weatherInfo, dispatch),
    weatherInfoOpen: bindActionCreators(weatherInfoOpen, dispatch),
    eclipsesData: bindActionCreators(eclipsesData, dispatch),
    sunCalc: bindActionCreators(sunCalc, dispatch),
    moonCalc: bindActionCreators(moonCalc, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CityInfoMapBlocks);
