import React, { Component } from "react";
import { connect } from "react-redux";

import "./MoonCityCalculatedBlock.css";
import GoogleMapDraw from "./GoogleMapDraw/GoogleMapDraw";
import MoonCulcRightBlockInfo from "./MoonCulcRightBlockInfo/MoonCulcRightBlockInfo";

class MoonCityCalculatedBlock extends Component {
	state = {
		showSidebar: true
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		if (window.innerWidth > 980) {
			this.setState({ showSidebar: true });
		} else {
			this.setState({ showSidebar: false });
		}
	};
	
	render() {
		const { moonCalcReducer } = this.props;
		if (!moonCalcReducer.id) {
			return <div></div>;
		}
		return (
			<section className="GoogleMapBox">
				<div className="sunCulcCityInfo">
					<GoogleMapDraw />
					{this.state.showSidebar ? <MoonCulcRightBlockInfo /> : null}
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer
	};
};

export default connect(mapStateToProps)(MoonCityCalculatedBlock);
