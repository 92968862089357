import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import { Link } from "react-router-dom";

import { StickyTable, Row, Cell } from "react-sticky-table";
import "react-sticky-table/dist/react-sticky-table.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import moonRiseTableList from "../../../../../../actions/action_moonRise/action_moonRiseTableList";
import moonRiseDate from "../../../../../../actions/action_moonRise/action_moonRiseDate";
import calendarDateTableMoon from "../../../../../../actions/action_moonRise/action_calendarDateTableMoon";
import NewMoon from "../../../../../../assets/moon_page/NewMoon.png";
import FirstQuarter from "../../../../../../assets/moon_page/FirstQuarter.png";
import FullMoon from "../../../../../../assets/moon_page/FullMoon.png";
import ThirdQuarter from "../../../../../../assets/moon_page/ThirdQuarter.png";

import "./MoonData.css";

import { getCityUrlById } from "../../../../../../utils/sharedFunctions";
import Api from "../../../../../../services/api.js";

class MoonData extends Component {
	monthsList = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];
	moonData = [
		"Date",
		"Moonrise",
		"Moonset",
		"Moonrise",
		"Time (Culmination)",
		"Distance, mi (Culmination)",
		"Illumination (Culmination)"
	];
	state = {
		years: [2016, 2017, 2018]
	};
	async componentDidMount() {
		const {
			moonCalcReducer,
			moonRiseTableList,
			calendarDateTableMoon
		} = this.props;
		const time = moment(moonCalcReducer.time)
			.tz(moonCalcReducer.timezone)
			.format("YYYY-MM-DDTHH:mm:ss");
		calendarDateTableMoon(time);
		const yearList = [];
		for (let i = 1917; i < 2099; i++) {
			yearList.push(i);
		}
		const moonInfo = await Api.userCityList(
			`moon_data/?geoname_id=${moonCalcReducer.id}&dt=${time}`
		);
		moonRiseTableList(moonInfo.data);
		this.setState({ years: yearList });

		this.getCityUrl();
	}

	async getCityUrl() {
		const { moonCalcReducer } = this.props;
		const cityUrl = await getCityUrlById(moonCalcReducer.id, "moon_app=true");
		this.setState({ currentCityUrl: cityUrl });
	}

	emptyName(name) {
		return name !== null ? `${name}, ` : "";
	}
	emptyState(state, abr) {
		if (isNaN(+abr) && abr !== null) return `${abr}, `;
		else if (state !== null) return `${state}, `;
		else return "";
	}
	emptyAbr(abr, name) {
		if (name === "United States" && abr !== null) return abr;
		else if (name !== null) return name;
		else return "";
	}
	yearChange = (event, index, value) => {
		const {
			calendarDateTableMoon,
			calendarDateTableMoonReducer,
			moonCalcReducer
		} = this.props;
		const time = moment(calendarDateTableMoonReducer)
			.tz(moonCalcReducer.timezone)
			.set("year", value)
			.format();
		calendarDateTableMoon(time);
		this.setState({
			year: value
		});
	};
	monthChange = (event, index, value) => {
		const {
			calendarDateTableMoon,
			calendarDateTableMoonReducer,
			moonCalcReducer
		} = this.props;
		const time = moment(calendarDateTableMoonReducer)
			.tz(moonCalcReducer.timezone)
			.set("month", index)
			.format();
		calendarDateTableMoon(time);
		this.setState({ month: value });
	};
	async tableChange() {
		const {
			moonCalcReducer,
			moonRiseTableList,
			calendarDateTableMoonReducer
		} = this.props;
		const date = calendarDateTableMoonReducer;
		const sunInfo = await Api.userCityList(
			`moon_data/?geoname_id=${moonCalcReducer.id}&dt=${moment(
				date
			).format("YYYY-MM-DDTHH:mm:ss")}`
		);
		moonRiseTableList(sunInfo.data);
	}
	meridianPassing(elem) {
		const { moonCalcReducer } = this.props;
		const mPassingTime = moment(elem.time)
			.tz(moonCalcReducer.timezone)
			.format("LT");
		if (elem.time === null) return "";
		// if (elem.time === null ) return 'N/A' ;
		else if (elem.altitude !== null) return mPassingTime;
	}
	meridianPassingDeg(elem) {
		return elem.time !== null
			? `(${elem.altitude.toFixed(1)}${String.fromCharCode(176)})`
			: "";
	}
	meridianPassingDistance(elem, rowNum) {
		switch (rowNum) {
			case 1:
				// eslint-disable-next-line no-case-declarations
				const { moonCalcReducer } = this.props;
				if (elem.distance !== null) {
					const moonDist = (elem.distance + "").split(".")[0];
					if (moonCalcReducer.time_format === "AM_PM") {
						return moonDist.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					} else
						return (+moonDist * 1.60934 + "")
							.split(".")[0]
							.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				} else return "Moon does not pass";
			case 2:
				return !elem.distance ? "the meridian on this day" : "";
			default:
				return;
		}
	}
	meridianPassingIllumination(elem) {
		const illumination = (elem.illumination * 100).toFixed(1);
		if (elem.illumination !== null) return illumination + "%";
		else return "";
	}
	moonDistanceWord(elem) {
		const { moonCalcReducer } = this.props;
		return moonCalcReducer.time_format === "AM_PM"
			? elem.replace(/_/g, " ")
			: elem.replace(" mi ", " km ");
	}
	tableDateStyle(elem) {
		const { moonCalcReducer } = this.props;
		const currentDate = moment()
			.tz(moonCalcReducer.timezone)
			.format("DD MM YYYY");
		const elemDate = moment(elem.date).format("DD MM YYYY");
		return elemDate === currentDate ? { background: "#e9e9e9" } : {};
	}
	geotimedateTable() {
		const { moonRiseTableListReducer } = this.props;
		if (document.body.offsetWidth > 1199) {
			return moonRiseTableListReducer.moon_data.length * 35 + 29 + 30;
		} else
			return document.body.offsetHeight < 1260
				? document.body.offsetHeight
				: 1260;
	}
	degMoon(deg) {
		return deg !== null
			? `${Math.round(deg)}${String.fromCharCode(176)}`
			: "";
	}
	moonEventsIcon(elem, style) {
		const { moonCalcReducer, moonRiseTableListReducer } = this.props;
		const date = moment(elem.date)
			.tz(moonCalcReducer.timezone)
			.format("DD-MM");
		const dateEvent = Object.keys(
			moonRiseTableListReducer.moon_phase
		).filter(elem => date === elem);
		if (dateEvent.length) {
			if (style === "style") return { display: "block" };
			const phaseDate = moonRiseTableListReducer.moon_phase[dateEvent[0]];
			if (phaseDate === "New moon") return NewMoon;
			else if (phaseDate === "First quarter") return FirstQuarter;
			else if (phaseDate === "Full moon") return FullMoon;
			else if (phaseDate === "Third quarter") return ThirdQuarter;
		}
	}
	moonRiseSetTime(elem, moonPos, index) {
		const { moonCalcReducer } = this.props;
		const allDayUpDown = elem.rise_set.filter(elem => elem.date !== "");
		if (
			allDayUpDown.length === 0 &&
			moonPos === "set1" &&
			elem.whole_date_up === true
		)
			return "Up all day";
		else if (
			allDayUpDown.length === 0 &&
			moonPos === "set1" &&
			elem.whole_date_up === false
		)
			return "Down all day";
		switch (moonPos) {
			case "rise1":
				return elem.rise_set[0].date !== ""
					? moment(elem.rise_set[0].date)
							.tz(moonCalcReducer.timezone)
							.format("LT")
					: "-";
			case "rise2":
				return elem.rise_set[2].date !== ""
					? moment(elem.rise_set[2].date)
							.tz(moonCalcReducer.timezone)
							.format("LT")
					: "-";
			case "set1":
				return elem.rise_set[1].date !== ""
					? moment(elem.rise_set[1].date)
							.tz(moonCalcReducer.timezone)
							.format("LT")
					: "-";
			case "set2":
				return elem.rise_set[3].date !== ""
					? moment(elem.rise_set[3].date)
							.tz(moonCalcReducer.timezone)
							.format("LT")
					: "";
			default:
				return "state";
		}
	}
	sunRiseSetArrowStyle(elem, moonPos) {
		switch (moonPos) {
			case "rise1":
				return elem.rise_set[0].date !== ""
					? { transform: `rotate(${elem.rise_set[0].az}deg)` }
					: { display: "none" };
			case "rise2":
				return elem.rise_set[2].date !== ""
					? { transform: `rotate(${elem.rise_set[2].az}deg)` }
					: { display: "none" };
			case "set1":
				return elem.rise_set[1].date !== ""
					? { transform: `rotate(${elem.rise_set[1].az}deg)` }
					: { display: "none" };
			case "set2":
				return elem.rise_set[3].date !== ""
					? { transform: `rotate(${elem.rise_set[3].az}deg)` }
					: { display: "none" };
			default:
				return { display: "none" };
		}
	}
	sunRiseSetAzimut(elem, moonPos) {
		switch (moonPos) {
			case "rise1":
				return elem.rise_set[0].date !== ""
					? `(${Math.round(
						elem.rise_set[0].az
					)} ${String.fromCharCode(176)})`
					: "";
			case "rise2":
				return elem.rise_set[2].date !== ""
					? `(${Math.round(
						elem.rise_set[2].az
					)} ${String.fromCharCode(176)})`
					: "";
			case "set1":
				return elem.rise_set[1].date !== ""
					? `(${Math.round(
						elem.rise_set[1].az
					)} ${String.fromCharCode(176)})`
					: "";
			case "set2":
				return elem.rise_set[3].date !== ""
					? `(${Math.round(
						elem.rise_set[3].az
					)} ${String.fromCharCode(176)})`
					: "";
			default:
				return {display: "none" };
		}
	}
	moonEventRiseSet(elem) {
		if (elem === "Moonrise" || elem === "Moonset")
			return { minWidth: "120px", maxWidth: "120px" };
	}
	render() {
		const {
			moonCalcReducer,
			moonRiseTableListReducer,
			calendarDateTableMoonReducer
		} = this.props;
		if (!moonRiseTableListReducer.moon_data.length) {
			return <div></div>;
		}
		return (
			<div className="SunData MoonData">
				<div className="sun-month-year-change">
					<h4>
						Monthly Moon Rise, Moon Set, Moon Culmination in{" "}
						{this.emptyName(moonCalcReducer.name)}
						{this.emptyState(
							moonCalcReducer.state,
							moonCalcReducer.state_code
						)}
						{this.emptyAbr(
							moonCalcReducer.country_name_abbr,
							moonCalcReducer.country_name
						)}
					</h4>
					<div className="month-year-change">
						<MuiThemeProvider>
							<SelectField
								className="sun-year-select"
								// value={this.moonListYear()}
								value={moment(calendarDateTableMoonReducer).get(
									"year"
								)}
								onChange={this.yearChange}
								maxHeight={200}
							>
								{this.state.years.map((elem, index) => (
									<MenuItem
										value={elem}
										key={index}
										primaryText={elem}
									/>
								))}
							</SelectField>
						</MuiThemeProvider>
						<MuiThemeProvider>
							<SelectField
								className="sun-month-select"
								// value={this.moonListMonth()}
								value={moment(
									calendarDateTableMoonReducer
								).format("MMMM")}
								onChange={this.monthChange}
								maxHeight={200}
							>
								{this.monthsList.map((elem, index) => (
									<MenuItem
										value={elem}
										key={index}
										primaryText={elem}
									/>
								))}
							</SelectField>
						</MuiThemeProvider>
						<button
							className="month-chnge"
							onClick={this.tableChange.bind(this)}
						>
							Go
						</button>
					</div>
				</div>

				<div
					className="geotimedate-table"
					style={{
						width: "100%",
						height: this.geotimedateTable() + "px"
					}}
				>
					<StickyTable stickyColumnCount={1} stickyHeaderCount={1}>
						<Row>
							{this.moonData.map((elem, index) => (
								<Cell
									key={index}
									style={this.moonEventRiseSet(elem)}
								>
									<div className="cell-wrapper">
										<b>{this.moonDistanceWord(elem)}</b>
									</div>
								</Cell>
							))}
						</Row>
						<Row>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
						</Row>
						{moonRiseTableListReducer.moon_data.map(
							(elem, index) => (
								<Row
									key={index}
									style={this.tableDateStyle(elem)}
								>
									<Cell>
										<b className="moon-event-block">
											<img
												src={this.moonEventsIcon(elem)}
												style={this.moonEventsIcon(
													elem,
													"style"
												)}
												alt="moon logo"
											/>
											{index + 1}
										</b>
									</Cell>
									<Cell className="moon-event-RiseSet">
										<b>
											{this.moonRiseSetTime(
												elem,
												"rise1"
											)}
											<i
												className="material-icons"
												style={this.sunRiseSetArrowStyle(
													elem,
													"rise1"
												)}
											>
												arrow_upward
											</i>
											{this.sunRiseSetAzimut(
												elem,
												"rise1"
											)}
										</b>
									</Cell>
									<Cell className="moon-event-RiseSet">
										<p>
											{this.moonRiseSetTime(elem, "set1")}
											<i
												className="material-icons"
												style={this.sunRiseSetArrowStyle(
													elem,
													"set1"
												)}
											>
												arrow_upward
											</i>
											{this.sunRiseSetAzimut(
												elem,
												"set1"
											)}
										</p>
										<p>
											{this.moonRiseSetTime(
												elem,
												"set2",
												index
											)}
											<i
												className="material-icons"
												style={this.sunRiseSetArrowStyle(
													elem,
													"set2"
												)}
											>
												arrow_upward
											</i>
											{this.sunRiseSetAzimut(
												elem,
												"set2"
											)}
										</p>
									</Cell>
									<Cell className="moon-event-RiseSet">
										<b>
											{this.moonRiseSetTime(
												elem,
												"rise2"
											)}
											<i
												className="material-icons"
												style={this.sunRiseSetArrowStyle(
													elem,
													"rise2"
												)}
											>
												arrow_upward
											</i>
											{this.sunRiseSetAzimut(
												elem,
												"rise2"
											)}
										</b>
									</Cell>
									<Cell style={{ with: "10px" }}>
										<p>{this.meridianPassing(elem)}</p>
										<p>{this.meridianPassingDeg(elem)}</p>
									</Cell>
									<Cell>
										<p>
											{this.meridianPassingDistance(
												elem,
												1
											)}
										</p>
										<p>
											{this.meridianPassingDistance(
												elem,
												2
											)}
										</p>
									</Cell>
									<Cell>
										<p>
											{this.meridianPassingIllumination(
												elem
											)}
										</p>
									</Cell>
								</Row>
							)
						)}
					</StickyTable>

					{moment(calendarDateTableMoonReducer).isBefore(
						"2039-01-01"
					) ? (
						<p className="geotimedate-table_moon-notice">
							*Showing local times for{" "}
							<Link to={
									this.state.currentCityUrl
										? this.state.currentCityUrl
										: "/"
								}>
								{this.emptyName(moonCalcReducer.name)}{" "}
								{this.emptyAbr(
									moonCalcReducer.country_name_abbr,
									moonCalcReducer.country_name
								)}
							</Link>{" "}
							adjusted for DST, if applicable.
						</p>
					) : (
						<p className="geotimedate-table_moon-notice">
							Showing local times for{" "}
							{this.emptyName(moonCalcReducer.name)}{" "}
							{this.emptyAbr(
								moonCalcReducer.country_name_abbr,
								moonCalcReducer.country_name
							)}{" "}
							based on standard time due to{" "}
							<span title="Many Unix and other operating systems store Unix time as a signed 32-bit integer. After January 19, 2038, the 32 bit signed UNIX time will overflow, taking the count negative. For more information, please visit - https://en.wikipedia.org/wiki/Year_2038_problem">
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								"Year 2038 Problem"
							</span>
							.
						</p>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer,
		moonRiseTableListReducer: state.moonRiseTableListReducer,
		moonRiseDateReducer: state.moonRiseDateReducer,
		calendarDateTableMoonReducer: state.calendarDateTableMoonReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		moonRiseTableList: bindActionCreators(moonRiseTableList, dispatch),
		moonRiseDate: bindActionCreators(moonRiseDate, dispatch),
		calendarDateTableMoon: bindActionCreators(
			calendarDateTableMoon,
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MoonData);
