import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import "./flight_card.css";

class FlightCard extends Component {
	render() {
		const { cityFrom, cityTo, secondCityLocal, direction } = this.props;

		return (
			<div className="flight-card">
				<div className="flight-card-header">
					{direction === "from" ? (
						<i className="material-icons mat_iconFtF">
							flight_takeoff
						</i>
					) : (
						<i className="material-icons mat_iconFtF">
							flight_land
						</i>
					)}
					<p className="flight-card__title">
						{direction === "from" ? "Departure" : "Arrival"}
					</p>
				</div>
				<div className="flight-card-body">
					<div className="flight-card-body-title">
						<p className="flight-card-body__city">
							{cityFrom.city.name}
						</p>
						<p className="flight-card-body__country">
							{cityFrom.city.country}
						</p>
					</div>

					<div className="flight-card-body-info">
						<div className="flight-card-timing">
							<p
								className="flight-card-timing__title"
								title={cityFrom.city.name}
							>
								Local time - {cityFrom.city.name}
							</p>
							<p className="flight-card-timing__timezone">
								(UTC{" "}
								{moment(cityFrom.date.date)
									.tz(cityFrom.city.timezone)
									.format("Z")}
								)
							</p>
						</div>
						<div className="flight-card-timing flight-card-timing_center">
							<p className="flight-card-timing__time">
								{moment(cityFrom.date.date)
									.tz(cityFrom.city.timezone)
									.format("h:mm")}
								<span>
									{moment(cityFrom.date.date)
										.tz(cityFrom.city.timezone)
										.format("A")}
								</span>
							</p>
							<p className="flight-card-timing__date">
								{moment(cityFrom.date.date)
									.tz(cityFrom.city.timezone)
									.format("MMM, D")}
							</p>
						</div>
					</div>

					<div className="flight-card-body-info">
						<div className="flight-card-timing">
							<p
								className="flight-card-timing__title"
								title={cityTo.city.name}
							>
								Local time - {cityTo.city.name}
							</p>
							<p className="flight-card-timing__timezone">
								(UTC{" "}
								{moment(secondCityLocal.date)
									.tz(secondCityLocal.city.timezone)
									.format("Z")}
								)
							</p>
						</div>
						<div className="flight-card-timing flight-card-timing_center">
							<p className="flight-card-timing__time">
								{moment(secondCityLocal.date)
									.tz(secondCityLocal.city.timezone)
									.format("h:mm")}
								<span>
									{moment(secondCityLocal.date)
										.tz(secondCityLocal.city.timezone)
										.format("A")}
								</span>
							</p>
							<p className="flight-card-timing__date">
								{moment(secondCityLocal.date)
									.tz(secondCityLocal.city.timezone)
									.format("MMM, D")}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		flightTimeInfoReducer: state.flightTimeInfoReducer
	};
};

export default connect(mapStateToProps)(FlightCard);
