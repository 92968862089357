import React from "react";
import moment from "moment-timezone";

import Ticker from "../../../../../shared/Ticker";

function TitleList({ exchanges, googleEvents, outlookEvents, icloudEvents }) {
	const getCurrentTimeForExchange = exchange => {
		const validTime = moment.tz(
			exchange.curentTime_stock,
			exchange.timezone_stock
		);
		return moment(validTime).format("LT MMM DD");
	};

	return (
		<div className="title-list">
			{exchanges &&
				exchanges.map((exchange, key) => (
					<div className="title-list-item" key={key}>
						<div className="title-wrapper">
							<Ticker
								text={`${exchange.name} (${exchange.country_name})`}
							/>
						</div>
						<span className="title-item title-item_small">
							{getCurrentTimeForExchange(exchange)}
						</span>
					</div>
				))}

			{googleEvents.length > 0 &&
				googleEvents.map((event, key) => {
					if (key === 0) {
						return (
							<div className="title-list-item">
								<p className="title-wrapper">Google</p>
							</div>
						);
					} else {
						return (
							<div className="title-list-item title-list-item__hidden">
								<p className="title-wrapper">Google</p>
							</div>
						);
					}
				})}

			{outlookEvents.length > 0 &&
				outlookEvents.map((event, key) => {
					if (key === 0) {
						return (
							<div className="title-list-item">
								<p className="title-wrapper">
									Microsoft Outlook
								</p>
							</div>
						);
					} else {
						return (
							<div className="title-list-item title-list-item__hidden">
								<p className="title-wrapper">
									Microsoft Outlook
								</p>
							</div>
						);
					}
				})}

			{icloudEvents.length > 0 &&
				icloudEvents.map((event, key) => {
					if (key === 0) {
						return (
							<div className="title-list-item">
								<p className="title-wrapper">Apple</p>
							</div>
						);
					} else {
						return (
							<div className="title-list-item title-list-item__hidden">
								<p className="title-wrapper">Apple</p>
							</div>
						);
					}
				})}
		</div>
	);
}

export default TitleList;
