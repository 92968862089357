import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./ApisToolsBlock.css";
import CalculatorsAndTimers from "../../../MainPage/InformationBlocks/CalculatorsAndTimers/CalculatorsAndTimers";
import flightTimeInfo from "../../../../../actions/action_flightTimeInfo.js";
import toolsApis from "../../../../../assets/main_page_blocks/toolsApis.png";

class ApisToolsBlock extends Component {
  render() {
    return (
      <div className="InformationBlocks">
        <CalculatorsAndTimers />
        <div className="tools-and-apis">
          <div className="currentCityHeader">
            <div className="currentCityImg">
              <img src={toolsApis} alt="Tools and Api's" />
            </div>
            <div className="currentCityTittle">
              <div className="title-block">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h3>TOOLS AND API's</h3>
              </div>
            </div>
          </div>
          <div className="currentCityInfo">
            <div className="hover_effect">
              {/* <Link to={'/'}><b>Countdown to New Year {+moment().format('YYYY') + 1}</b></Link> */}
            </div>
            <div className="hover_effect">
              {/* <Link to={'/'}><b>Countdown timer</b></Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flightTimeInfoReducer: state.flightTimeInfoReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    flightTimeInfo: bindActionCreators(flightTimeInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApisToolsBlock);
