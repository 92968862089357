import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import DatePicker from "material-ui/DatePicker";

import "./TimeZoneConverter.css";
import currentCity from "../../../../assets/main_page_blocks/currentCity.png";
import Search from "../../MainPage/Search/Search";
import ContentBlock from "./ContentBlock/ContentBlock";
import timeZoneConverter from "../../../../actions/action_timeZoneConverter/action_timeZoneConverter.js";
import timeZoneConverterTime from "../../../../actions/action_timeZoneConverter/action_timeZoneConverterTime.js";

class TimeZoneConverter extends Component {
	state = {
		format24: false,
		format12: false
	};
	componentDidMount() {
		const {
			timeZoneConverter,
			timeZoneConverterReducer,
			timeZoneConverterTime
		} = this.props;
		let cityList = [];
		if (timeZoneConverterReducer.length) {
			cityList = timeZoneConverterReducer;
			localStorage.setItem(
				"timeZoneConverterList",
				JSON.stringify(timeZoneConverterReducer)
			);
		} else if (localStorage.getItem("timeZoneConverterList")) {
			cityList = JSON.parse(
				localStorage.getItem("timeZoneConverterList")
			);
			timeZoneConverter(cityList);
		}
		if (cityList.length) {
			const city = cityList[0];
			const zeroTime = +moment()
				.tz(city.timezone)
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.set("millisecond", 0)
				.format("x");
			const cityTime = +moment()
				.tz(city.timezone)
				.format("x");
			const timeMoment = moment().tz(cityList[0].timezone);
			const year = timeMoment.get("year");
			const month = timeMoment.get("month"); // 0 to 11
			const day = timeMoment.get("date");
			const hour = timeMoment.get("hour");
			const minute = timeMoment.get("minute");
			const second = timeMoment.get("second");
			const millisecond = timeMoment.get("millisecond");
			const time = new Date(
				year,
				month,
				day,
				hour,
				minute,
				second,
				millisecond
			);
			timeZoneConverterTime({
				calendarDate: time,
				sliderDot: Math.round((cityTime - zeroTime) / 60000)
			});
		}
	}
	calendarChange = (event, date) => {
		const { timeZoneConverterTime, timeZoneConverterReducer } = this.props;
		if (timeZoneConverterReducer.length) {
			const city = timeZoneConverterReducer[0];
			const zeroTime = +moment()
				.tz(city.timezone)
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.set("millisecond", 0)
				.format("x");
			const cityTime = +moment()
				.tz(city.timezone)
				.format("x");
			const timeMoment = moment().tz(
				timeZoneConverterReducer[0].timezone
			);
			const year = date.getFullYear();
			const month = date.getMonth(); // 0 to 11
			const day = date.getDate();
			const hour = timeMoment.get("hour");
			const minute = timeMoment.get("minute");
			const second = timeMoment.get("second");
			const millisecond = timeMoment.get("millisecond");
			const time = new Date(
				year,
				month,
				day,
				hour,
				minute,
				second,
				millisecond
			);
			timeZoneConverterTime({
				calendarDate: time,
				sliderDot: Math.round((cityTime - zeroTime) / 60000)
			});
		} else {
			timeZoneConverterTime({
				calendarDate: date,
				sliderDot: 0
			});
		}
	};

	timeFormatStyle(format) {
		if (format === "am-pm" && this.state.format12 === true) {
			return {
				border: "1px solid rgb(252, 194, 56)",
				background: "rgb(252, 194, 56)"
			};
		} else if (format === "24" && this.state.format24 === true) {
			return {
				border: "1px solid rgb(252, 194, 56)",
				background: "rgb(252, 194, 56)"
			};
		} else if (
			format === "MX" &&
			this.state.format12 === false &&
			this.state.format24 === false
		) {
			return {
				border: "1px solid rgb(252, 194, 56)",
				background: "rgb(252, 194, 56)"
			};
		}
	}

	render() {
		const { timeZoneConverterTimeReducer } = this.props;
		return (
			<div className="TimeZoneConverter">
				<div className="time-picker-header">
					<div className="currentCityImg">
						<img src={currentCity} alt="timeZoneConverter" />
					</div>
					<div className="currentCityTittle">
						<div className="title-block">
							<h3>Time Zone Converter</h3>
							<div className="time-format-date">
								<MuiThemeProvider>
									<div className="converterCalendarBlock">
										<DatePicker
											hintText="Controlled Date Input"
											value={
												timeZoneConverterTimeReducer.calendarDate
											}
											onChange={this.calendarChange}
											className="converterCalendar"
										/>
										<i className="material-icons">today</i>
									</div>
								</MuiThemeProvider>
							</div>
						</div>
						<Search />
					</div>
				</div>
				<ContentBlock
					format24={this.state.format24}
					format12={this.state.format12}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		timeZoneConverterReducer: state.timeZoneConverterReducer,
		timeZoneConverterTimeReducer: state.timeZoneConverterTimeReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		timeZoneConverter: bindActionCreators(timeZoneConverter, dispatch),
		timeZoneConverterTime: bindActionCreators(
			timeZoneConverterTime,
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimeZoneConverter);
