import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

// import PlanetRetrogradesLinks from './PlanetRetrogradesLinks';
// import PlanetSignsLinks from './PlanetSignsLinks';
// import PoveredBy from './PoveredBy/PoveredBy';
// import panchangImage from '../../../../../../assets/panchang-box-image.jpg';

// import Api from '../../../../services/api.js';
import {DatePicker, IconButton, MuiThemeProvider, RaisedButton} from "material-ui";

class CalendarPage extends Component {

    state = {
        calendarValue : moment()._d,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.panchang !== nextProps.panchang;
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        if (this.props.currentSearchReducer !== nextProps.currentSearchReducer) {
            const calendarValue = moment()._d;
            this.setState({calendarValue});
        }
    }

    // componentDidMount(){
    //     const calendarValue = moment()._i;
    //     this.setState({calendarValue});
    // }

     calendarValue(){
        const { time } = this.props.currentSearchReducer;
        const calendarValue  = this.getFormateTime(time);
        this.setState({calendarValue})
    }

    calendarChange = (event, date) => {
        const {getDate} = this.props;
        const { timezone } = this.props.currentSearchReducer;
        // const calendarValue = this.getFormateTime(date);
        const momentTime = moment(date).tz(timezone);
        const calendarValue = momentTime._i;
        this.setState({calendarValue});
        getDate(date);
    };

    previousMonth = () => {
        const {getDate} = this.props;
        const month = moment(this.state.calendarValue).get('month');
        const momentTime = moment(this.state.calendarValue).set('month', month - 1);
        const calendarValue = momentTime._d;
        this.setState({calendarValue});
        getDate(calendarValue);
    };

    previousDay = () => {
        const {getDate} = this.props;
        const day = moment(this.state.calendarValue).get('date');
        const momentTime = moment(this.state.calendarValue).set('date', day - 1);
        const calendarValue = momentTime._d;
        this.setState({calendarValue});
        getDate(calendarValue);
    };

    currentDay = () => {
        const {getDate} = this.props;
        const momentTime = moment();
        const calendarValue = momentTime._d;
        this.setState({calendarValue});
        getDate(calendarValue);
    };

    nextDay = () => {
        // console.log('nextDay', this.state.calendarValue)
        const {getDate} = this.props;
        const day = moment(this.state.calendarValue).get('date');
        const momentTime = moment(this.state.calendarValue).set('date', day + 1);
        const calendarValue = momentTime._d;
        this.setState({calendarValue});
        getDate(calendarValue);
    };

    nextMonth = () => {
        const {getDate} = this.props;
        const month = moment(this.state.calendarValue).get('month');
        const momentTime = moment(this.state.calendarValue).set('month', month + 1);
        const calendarValue = momentTime._d;
        this.setState({calendarValue});
        getDate(calendarValue);
    };


    renderBtnSamvat() {
        // console.log('renderBtnSamvat', this.props.panchang);
        const creatorArr = [
            {attr: 'shaka_samvat', name: 'Shaka Samvat'},
            {attr: 'vikram_samvat', name: 'Vikram Samvat'},
            {attr: 'amanta', name: 'Amanta Chandramasa'},
            {attr: 'purnimanta', name: 'Purnimanta Chandramasa'}
        ];
        const objPanchang = this.props.panchang ? this.props.panchang : false;
        let renderArr = [];
        if (objPanchang) {
            for (let key in objPanchang) {
                creatorArr.forEach(item=>{
                    if (item.attr === key) renderArr.push({name : item.name, value: objPanchang[key]});
                })
            }
        }

        return renderArr.map( elem =>
            <div className="equinox-solstice-name" key={elem.name}>
                <span>{elem.name}</span>
                <MuiThemeProvider>
                    <RaisedButton
                        // onClick={this.equinoxSolstice.bind(this, elem)}
                        className="RaisedButton" label={elem.value} primary={true}
                        disabled
                    />
                </MuiThemeProvider>
            </div>
        );
    }

    getMaxDate() {
        const maxDate = new Date();
        maxDate.setFullYear(2099,5,30);
        return maxDate;
    }

    render() {
        // console.log('calendarValue', this.state.calendarValue)
        return (
            <div className="calendar_page">
                <section className="CalendarPickerBlock CalendarPickerBlockMoon">
                <div className="left-calendar-block">
                    <MuiThemeProvider>
                        <div className='calendar-date-picker-wrapper'>
                            <DatePicker
                                className="calendar-date-picker"
                                hintText="Select Date"
                                value={this.state.calendarValue}
                                onChange={this.calendarChange}
                                autoOk={true}
                                maxDate={this.getMaxDate()}
                                formatDate={new Intl.DateTimeFormat('en-US', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                }).format}
                            />
                            <i className="material-icons">today</i>
                        </div>
                    </MuiThemeProvider>
                    <div className="calendar-date-picker-buttons">
                        <MuiThemeProvider>
                            <IconButton onClick={this.previousMonth} tooltip="Previous Month" tooltipPosition="bottom-right">
                                <i className="material-icons">fast_rewind</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                            <IconButton onClick={this.previousDay} tooltip="Previous Day" tooltipPosition="bottom-right">
                                <i className="material-icons prev_date">play_arrow</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                            <IconButton onClick={this.currentDay} tooltip="Current Day" tooltipPosition="bottom-center">
                            <i className="material-icons">stop</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                            <IconButton onClick={this.nextDay} tooltip="Next Day" tooltipPosition="bottom-left">
                                <i className="material-icons">play_arrow</i>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider>
                            <IconButton onClick={this.nextMonth} tooltip="Next Month" tooltipPosition="bottom-left">
                                <i className="material-icons">fast_forward</i>
                            </IconButton>
                        </MuiThemeProvider>
                    </div>
                </div>
                <div className="right-calendar-block">
                    {this.renderBtnSamvat()}
                </div>
            </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};

export default connect(mapStateToProps)(CalendarPage);
