// import React, { Component } from 'react';
import { Component } from 'react';

import './CarouselTopBlock.css';

class CarouselTopBlock extends Component {
    render () {
        return (this.props.children);          
    }
}

export default CarouselTopBlock;