import React, { Component } from "react";

import Calculator from "./Calculator/Calculator";
import "./DateCalculator.css";

class DateCalculator extends Component {
  async componentDidMount() {}
  render() {
    return (
      <div className="DateCalculator">
        <Calculator />
      </div>
    );
  }
}

export default DateCalculator;
