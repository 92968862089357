import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Autocomplete from "react-autocomplete";
import Api from "../../../../../../services/api";

const validate = values => {
	const errors = {};
	if (!values.countryCode) errors.countryCode = "Required";
	// console.log(values);
	return errors;
};

/*
getCountryCode();
const CountryCode = localStorage.getItem('CountryInfo') ? JSON.parse(localStorage.getItem('CountryInfo')) : [];
let sel_auto = []; let tmp = '';
CountryCode.forEach(elem => {
   tmp = elem.country_phone_codes.replace(/[^0-9]/g, '');
   if(sel_auto[tmp]){
       sel_auto[tmp].name = sel_auto[tmp].name +  " / " + elem.country_name;
   } else {
   sel_auto[tmp] = {
       abbr: '+ ' + elem.country_phone_codes, name: "(+" + elem.country_phone_codes.replace(/[^0-9-]/g, '') + ") - " + elem.country_name
   };
   }
});
*/

class CountryCodeBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: "disabled",
			value: this.props.userInfo.phone_country_code,
			disabled1: "disabled",
			value1: this.props.userInfo.phone,
			arrayaphoneCode: [{ abbr: "+", name: "Error Country Code" }]
		};
	}

	UNSAFE_componentWillMount() {
		//console.log('UNSAFE_componentWillMount_CountryCode');
		// eslint-disable-next-line no-async-promise-executor
		var promise = new Promise(async (resolve, reject) => {
			let CountryInfo = await Api.getCache(
				Api.simpleRequest,
				`get-country-info/`
			);
			if (CountryInfo.data !== undefined) resolve(CountryInfo.data);
			else reject();
		});

		promise.then(
			result => {
				// console.log("ACC_UNSAFE_componentWillMount result");
				let sel_auto = [];
				let tmp = "";
				result.forEach(elem => {
					tmp = elem.country_phone_codes.replace(/[^0-9]/g, "");
					if (sel_auto[tmp]) {
						sel_auto[tmp].name =
							sel_auto[tmp].name + " / " + elem.country_name;
					} else {
						sel_auto[tmp] = {
							abbr: "+ " + elem.country_phone_codes,
							name:
								"(+" +
								elem.country_phone_codes.replace(
									/[^0-9-]/g,
									""
								) +
								") - " +
								elem.country_name
						};
					}
				});
				this.setState({ arrayaphoneCode: sel_auto });
			},
			function(error) {
				//this.setState({arrayaphoneCode: []});
			}
		);
	}

	renderField = ({
		input,
		label,
		type,
		meta: { touched, error, warning }
	}) => {
		const { userInfo } = this.props;
		return (
			<div className="authorisation-form-input">
				<label>{label}</label>
				<div>
					<input
						{...input}
						placeholder={userInfo.phone}
						type={type}
						disabled={this.state.disabled1}
						value={this.state.value1}
						onChange={this.handleChange}
						// ref={(input) => {this.textInput = input; }}
					/>
					{touched &&
						((error && <span>{error}</span>) ||
							(warning && <span>{warning}</span>))}
				</div>
			</div>
		);
	};
	editeAvalible = e => {
		this.setState({ disabled: "" });
		this.setState({ disabled1: "" });
		this.props.dispatch(
			this.props.change(
				"countryCode",
				this.props.userInfo.phone_country_code
			)
		);
		this.props.dispatch(
			this.props.change("Phone", this.props.userInfo.phone)
		);
	};
	handleChange = e => {
		this.setState({ value1: e.target.value });
	};
	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<div className="profile-block">
					<div>
						<div className="authorisation-form-input">
							<label>Country Code</label>
							<Autocomplete
								value={this.state.value}
								inputProps={{
									id: "countryCode",
									name: "countryCode",
									placeholder: "Country Code",
									disabled: this.state.disabled
								}}
								wrapperStyle={{
									position: "relative",
									display: "inline-block"
								}}
								items={this.state.arrayaphoneCode}
								//getItemValue={item => item.name}
								getItemValue={item => item.abbr}
								// shouldItemRender={matchCountryCode}
								shouldItemRender={(state, value) => {
									return (
										state.name
											.toLowerCase()
											.indexOf(value.toLowerCase()) !==
											-1 ||
										state.abbr
											.toLowerCase()
											.indexOf(value.toLowerCase()) !== -1
									);
								}}
								onChange={(event, value) => {
									this.setState({ value });
									let res = value.replace(/[^0-9]/g, "");
									this.props.dispatch(
										this.props.change("countryCode", res)
									);
								}}
								onSelect={value => {
									this.setState({ value });
									let res = value.replace(/[^0-9]/g, "");
									this.props.dispatch(
										this.props.change("countryCode", res)
									);
								}}
								renderMenu={children => (
									<div className="menu">{children}</div>
								)}
								renderItem={(item, isHighlighted) => (
									<div
										className={`item ${
											isHighlighted
												? "item-highlighted"
												: ""
										}`}
										key={item.abbr}
									>
										{item.name}
									</div>
								)}
							/>
						</div>
						<Field
							name="Phone"
							type="text"
							label="Phone"
							component={this.renderField}
							disabled={this.state}
						/>
					</div>

					<button type="button" onClick={this.editeAvalible}>
						Edit
					</button>
					<button
						type="submit"
						onMouseUp={() => {
							this.setState({ disabled: "disabled" });
							this.setState({ disabled1: "disabled" });
						}}
					>
						Save
					</button>
				</div>
			</form>
		);
	}
}

export default reduxForm({ form: "EditPersonalInfo", validate })(
	CountryCodeBlock
);
