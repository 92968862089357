import React, {Component} from 'react';

import moment from 'moment-timezone/index.js';


import '../../UnixConverter/Calculator/Calculator.css';
// import customStyleSelect from './customStyleSelect';


class ResultBlock extends Component {

    getNameDay(value) {
        return `${moment(value).format('dddd D MMMM YYYY')}`;
    }

    getFromResult(dateISO) {
        const {result, excludeTime, typeTime} = this.props;
        if (typeof result === "object") {
            let date;
            if (dateISO === 'from') date = result ? result.from.date : '';
            else if (dateISO === 'result') date = result ? result.result_date : '';
            let str, time;
            if (result) {
                // console.log('date', date)
                str = `
                    ${this.getNameDay(moment(date).tz('Greenwich'))} 
                `;
                let timer = moment(date).tz('Greenwich');
                time = typeTime === '12_hours' ? `${timer.format('LTS')}` : `${timer.format('HH:mm:ss')}`
            }
            if (excludeTime) return ` ${str ? str : ''}`;
            return ` ${str ? str + time : ''}`
        }
    }

    getAddedResult() {
        const {result} = this.props;
        if (typeof result === "object") {
            let years = Math.abs(result.added.years) > 0 ? ` ${result.added.years} years,` : '';
            let mounths = Math.abs(result.added.months) > 0 ? ` ${result.added.months} months,` : '';
            let weeks = Math.abs(result.added.weeks) > 0 ? ` ${result.added.weeks} weeks,` : '';
            let days = Math.abs(result.added.days) > 0 ? ` ${result.added.days} days,` : '';
            let hours = Math.abs(result.added.hours) > 0 ? ` ${result.added.hours} hours,` : '';
            let minutes = Math.abs(result.added.minutes) > 0 ? ` ${result.added.minutes} minutes,` : '';
            let seconds = Math.abs(result.added.seconds) > 0 ? ` ${result.added.seconds} seconds` : '';
            let typeCalc = 'Added';
            if (
                result.added.years < 0 ||
                result.added.months < 0 ||
                result.added.weeks < 0 ||
                result.added.days < 0 ||
                result.added.hours < 0 ||
                result.added.minutes < 0 ||
                result.added.seconds < 0
            ) {
                typeCalc = 'Subtracted';
            }
            return (
                <div className="title_result_content">
                    <span>{`${typeCalc}:`}</span><p>{` ${years} ${mounths} ${weeks} ${days} ${hours} ${minutes} ${seconds}`}</p>
                </div>
            )
        }
    }

    render() {
        let { status } = this.props;
        status = status === 'error' ? false : true;
        return (
            <div>
                {status && <div className="row_result">
                    <div className="title_result">Calculated Date</div>
                    <div className="content_result">
                        <div className="title_result_content">
                            <span>From:</span><p> {this.getFromResult('from')}</p>
                        </div>
                        {this.getAddedResult()}
                    </div>
                    <div className="title_result_below">
                        <span>Result:</span><p>{this.getFromResult('result')}</p>
                    </div>
                </div>}
                {!status && <div className="row_result">
                    <div className="title_result">We are sorry.</div>

                        <div className="title_result_content">
                        </div>

                    <div className="title_result_below">
                        <p>The result is out of calculator range.</p>
                    </div>
                     <div style={{marginTop: '5px'}}></div>
                    <div className="title_result_below">
                        <p>Please use it between 1000 A.D. and 3000 A.D.</p>
                    </div>
                </div>}
            </div>
        )
    }
}


export default ResultBlock;