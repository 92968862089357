import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
// import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Checkbox from "material-ui/Checkbox";
// import SelectField from 'material-ui/SelectField';
// import MenuItem from 'material-ui/MenuItem';
import validate from "./validate";

class SignInFormValidation extends Component {
	renderTextField = ({
		input,
		label,
		meta: { touched, error },
		...custom
	}) => (
		<TextField
			hintText={label}
			floatingLabelText={label}
			errorText={touched && error}
			{...input}
			{...custom}
		/>
	);
	renderCheckbox = ({ input, label }) => (
		<Checkbox
			label={label}
			defaultChecked={true}
			checked={input.value ? true : false}
			// checked={ true }
			onCheck={input.onChange}
		/>
	);
	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		//console.log(handleSubmit, pristine, submitting)
		return (
			<form onSubmit={handleSubmit}>
				<Field
					className="authorisation-form-input"
					name="email"
					component={this.renderTextField}
					label="Email"
				/>
				{/* <Field className='authorisation-form-input' name="email" component={this.renderTextField} label="Email" /> */}
				<Field
					className="authorisation-form-input"
					name="password"
					type="password"
					component={this.renderTextField}
					label="Password"
				/>
				{/* <Field name="firstName" component={renderTextField} label="First Name" />
                    <Field name="lastName" component={renderTextField} label="Last Name" /> */}

				{/* <Field name="sex" component={renderRadioGroup}>
                        <RadioButton value="male" label="male" />
                        <RadioButton value="female" label="female" />
                    </Field> */}

				{/* <Field name="favoriteColor" component={renderSelectField} label="Favorite Color">
                    <MenuItem value="ff0000" primaryText="Red" />
                    <MenuItem value="00ff00" primaryText="Green" />
                    <MenuItem value="0000ff" primaryText="Blue" />
                    </Field> */}

				{/* <Field name="notes" component={renderTextField} label="Notes" multiLine={true} rows={2} /> */}

				<div className="authorization-links">
					<Field
						name="employed"
						component={this.renderCheckbox}
						label="Remember me"
					/>
					<Link to="/forgot-password">Forgot Password</Link>
				</div>
				<div className="authorization-button">
					<button type="submit" disabled={pristine || submitting}>
						Sign in
					</button>
					{/* <button type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button> */}
				</div>
			</form>
		);
	}
}
export default reduxForm({ form: "SignInForm", validate })(
	SignInFormValidation
);
