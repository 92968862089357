import React, { Component } from 'react';

class Title extends Component {
    render() {
        return (
            <div>
                <h4>
                    <b>What are you looking forward to?</b> See the seconds tick down to your vacation, wedding, or
                    retirement. Share your countdown by copying the web address (URL). The countdown automatically
                    adjusts for DST changes in the selected location.
                </h4>
                <br/>
            </div>
        )
    }
}

export default Title;
