import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/index.js';

import './CityMoonRiseSet.css';


class CityMoonRiseSet extends Component {
    emptyName(name){
        return name !== null ? `${name}, ` : '';
    }
    emptyState(state, abr){
        if(isNaN(+abr) && abr !== null) return `${abr}, `;
        else if(state !== null) return `${state}, `;
        else return '';
    }
    emptyAbr(abr, name){
        if(name === 'United States' && abr !== null) return abr;
        else if(name !== null) return name;
        else return '';
    }
    render () {
        const { moonCalcReducer } = this.props;
        return (
            <section className="citySunRiseSet">
                <div className="country_flag">
                    <img className="cityMainImg" src={`/static/flags/flags-iso/flat/64/${moonCalcReducer.country_code}.png`} alt="citydateandtime" />
                </div>
                <div className="city_name_date">
                    <h3>
                        Moonrise, Moonset for {this.emptyName(moonCalcReducer.name)}
                        {/* {this.emptyName(moonCalcReducer.county)} */}
                        {this.emptyState(moonCalcReducer.state, moonCalcReducer.state_code)}
                        {this.emptyAbr(moonCalcReducer.country_name_abbr, moonCalcReducer.country_name)} for&nbsp;
                        {moment(moonCalcReducer.time).tz(moonCalcReducer.timezone).format('dddd, MMM D, YYYY')}
                    </h3>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        moonCalcReducer: state.moonCalcReducer
    };
};

export default connect(mapStateToProps)(CityMoonRiseSet);