import React, { Fragment } from "react";
import { connect } from "react-redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TimePicker from "material-ui/TimePicker";

import Search from "../../MainPage/Search/Search";

const days = ["S", "M", "T", "W", "T", "F", "S"];

function GroupList({
	authorizationReducer,
	index,
	elem,
	groupSubList,
	groupsLength,
	currentNumbGroup,
	handleGroupOpen,
	handleGroupChange,
	groupTitleStyle,
	handleGroupNameSelect,
	groupNameChange,
	handleGroupDell,
	blockOpenStyle,
	citiesShowStyle,
	handleNameChange,
	handleNameRefresh,
	emptyName,
	emptyAbr,
	handleCityNameSelect,
	handleCityDell,
	handleTimeStartChange,
	handleTimeEndChange,
	handleTimeReset,
	handleWeekendsChange,
	daysSelected,
	handleWeekendsRefresh
}) {
	return (
		<div className="group">
			<div
				className="group-name"
				onClick={() => handleGroupOpen(index)}
				style={currentNumbGroup !== index ? { cursor: "pointer" } : {}}
			>
				<div className="name" style={{ width: "250px" }}>
					<span>{index + 1}. </span>
					<h5
						contentEditable="true"
						suppressContentEditableWarning={true}
						onBlur={() => handleGroupChange(index)}
					>
						{elem}
					</h5>

					{currentNumbGroup === index && groupsLength > 1 ? (
						<Fragment>
							<i
								className="material-icons"
								onClick={handleGroupNameSelect}
							>
								edit
							</i>
							<i
								className="material-icons"
								onClick={() =>
									handleGroupDell(
										groupSubList[index][0].group_number
									)
								}
							>
								delete_outline
							</i>
						</Fragment>
					) : null}
				</div>
				<div className="search">
					<Search
						groupNumm={index + 1}
						groupNameChange={groupNameChange}
						groutPlaceholder="Add city"
						style={groupTitleStyle(index)}
					/>
					<i className="material-icons" style={blockOpenStyle(elem)}>
						keyboard_arrow_right
					</i>
				</div>
			</div>
			<div className="cities-list" style={citiesShowStyle(index)}>
				{groupSubList[index].map((city, cityIndex, cityArray) => (
					<div key={cityIndex} className="city-block">
						<div className="main-info">
							<span>{cityIndex + 1}.</span>
							<img
								src={`/static/flags/flags-iso/flat/64/${city.country_code}.png`}
								alt="geotimedate"
							/>
							<div className="cityName">
								<p
									contentEditable="true"
									suppressContentEditableWarning={true}
									onBlur={(e) =>
										handleNameChange(
											city,
											cityIndex,
											"name",
											e
										)
									}
								>
									{emptyName(city)}
								</p>
								<span>{emptyAbr(city)}</span>
							</div>
							<i
								className="material-icons"
								onClick={handleCityNameSelect}
							>
								edit
							</i>
							<i
								className="material-icons"
								style={{
									transform: "scale(-1, 1)"
								}}
								onClick={() =>
									handleNameRefresh(city, cityIndex)
								}
							>
								refresh
							</i>
							{cityArray.length > 1 && (
								<i
									className="material-icons"
									onClick={() => handleCityDell(city)}
								>
									delete_outline
								</i>
							)}
						</div>
						<div className="other-info">
							<div className="working-hours">
								<b>Working Hours</b>
								<div className="input-block">
									<MuiThemeProvider>
										<TimePicker
											placeholder={
												city.working_hours_start
											}
											disabled={false}
											format={
												authorizationReducer.time_format ===
												"24 hr"
													? "24hr"
													: "ampm"
											}
											value={city.working_hours_start}
											onChange={(e, date) =>
												handleTimeStartChange(
													city,
													cityIndex,
													e,
													date
												)
											}
											className="time-picker"
										/>
									</MuiThemeProvider>
									&nbsp; - &nbsp;
									<MuiThemeProvider>
										<TimePicker
											placeholder={city.working_hours_end}
											format={
												authorizationReducer.time_format ===
												"24 hr"
													? "24hr"
													: "ampm"
											}
											value={city.working_hours_end}
											onChange={(e, date) =>
												handleTimeEndChange(
													city,
													cityIndex,
													e,
													date
												)
											}
											className="time-picker"
										/>
									</MuiThemeProvider>
									<i
										className="material-icons"
										style={{
											transform: "scale(-1, 1)"
										}}
										onClick={() =>
											handleTimeReset(city, cityIndex)
										}
									>
										refresh
									</i>
								</div>
							</div>
							<div className="weekends">
								<b>Weekends</b>
								<div className="days-block">
									{days.map((day, dayIndex) => {
										return (
											<button
												key={dayIndex}
												style={daysSelected(
													city,
													dayIndex
												)}
												onClick={() =>
													handleWeekendsChange(
														city,
														cityIndex,
														dayIndex - 1
													)
												}
											>
												{day}
											</button>
										);
									})}
									<i
										className="material-icons"
										style={{
											transform: "scale(-1, 1)"
										}}
										onClick={() =>
											handleWeekendsRefresh(
												city,
												cityIndex
											)
										}
									>
										refresh
									</i>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		authorizationReducer: state.authorizationReducer
	};
};

export default connect(mapStateToProps)(GroupList);
