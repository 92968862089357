import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./PagesSelect.css";

class PagesSelect extends Component {
	buttonStyle(block) {
		const { blockSelect } = this.props;
		return blockSelect === block
			? { background: "#0b6f49", color: "#fff" }
			: {};
	}

	checkStatus() {
		if (this.props.authorizationReducer.status === "Pro") {
			this.upgradeAccount = null;
		} else {
			return (
				<Link to="/upgrade-account" className="upgradeAccount">
					Upgrade to Premium
				</Link>
			);
		}
	}

	render() {
		const { authorizationReducer, tabSelect } = this.props;
		return (
			<aside className="pages-select">
				<div className="user-name">
					<div>
						<h4>Hi, {authorizationReducer.first_name}</h4>
					</div>
					<div>
						<span>
							Account Status: {authorizationReducer.status}
						</span>
						{this.checkStatus()}
					</div>
				</div>
				<div className="page-types">
					<button
						onClick={tabSelect.bind(this, "account")}
						style={this.buttonStyle("account")}
					>
						<i className="material-icons page-types_icon">person</i>
						Account Settings
						<i className="material-icons">chevron_right</i>
					</button>
					<button
						onClick={tabSelect.bind(this, "events-calendars")}
						style={this.buttonStyle("events-calendars")}
					>
						<i className="material-icons page-types_icon">event</i>
						Events & Calendars
						<i className="material-icons">chevron_right</i>
					</button>
					<button
						onClick={tabSelect.bind(this, "groups")}
						style={this.buttonStyle("groups")}
					>
						<i className="material-icons page-types_icon">group</i>
						Groups
						<i className="material-icons">chevron_right</i>
					</button>
					<button onClick={tabSelect.bind(this, "sign-out")}>
						<i className="material-icons page-types_icon">
							exit_to_app
						</i>
						Sign out
						<i className="material-icons">chevron_right</i>
					</button>
				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		authorizationReducer: state.authorizationReducer
	};
};

export default connect(mapStateToProps)(PagesSelect);
