import React, { Component } from 'react';

import './Halloween.css';

class Halloween extends Component {
    render() {
        return (
            <div className='test-design Halloween'>
                <div className="css_lab">
                    <div className="witch">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Halloween;
