import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";

class DayLengthInfo extends React.Component {
	moonPhases(elem, format, cap) {
		const { moonCalcReducer } = this.props;
		return elem.time
			? moment(elem.time)
					.tz(moonCalcReducer.timezone)
					.format(format)
			: cap;
	}

	nextMoonInfo(time, format) {
		const { moonCalcReducer } = this.props;
		return moment(time)
			.tz(moonCalcReducer.timezone)
			.format(format);
	}

	render() {
		const { moonCalcReducer, moonRisePositionReducer } = this.props;

		return (
			<div className="day-length-info">
				<h5 className="eclipse-header">
					Moonrise, Moonset and Moon Phase
				</h5>
				<div className="eclipse-info-block">
					<b>Moon Phase</b>
					<b>
						<span>{moonCalcReducer.moon_data.moon_phase}</span>
						<span>
							{moonRisePositionReducer.moon.percent.toFixed(1)}%
						</span>
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Moon Age</b>
					<b>
						<span>
							{moonRisePositionReducer.moon.current_day.toFixed(
								2
							)}{" "}
							from{" "}
							{moonCalcReducer.moon_data.moon_days.toFixed(2)}{" "}
							Days
						</span>
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Next New Moon</b>
					<b>
						<span>
							{this.nextMoonInfo(
								moonCalcReducer.moon_data.next_new_moon,
								"LL"
							)}
						</span>
						<span>
							{this.nextMoonInfo(
								moonCalcReducer.moon_data.next_new_moon,
								"LT"
							)}
						</span>
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>Next Full Moon</b>
					<b>
						<span>
							{this.nextMoonInfo(
								moonCalcReducer.moon_data.next_full_moon,
								"LL"
							)}
						</span>
						<span>
							{this.nextMoonInfo(
								moonCalcReducer.moon_data.next_full_moon,
								"LT"
							)}
						</span>
					</b>
				</div>
				{moonCalcReducer.moon_data.night_parts.map((elem, index) => (
					<div className="eclipse-info-block" key={index}>
						<b>
							{elem.name === "Moon Culmination"
								? "Moon Meridian Passing"
								: elem.name}
						</b>
						<b>
							<span>{this.moonPhases(elem, "LL", "n/a")}</span>
							<span>{this.moonPhases(elem, "LT", "")}</span>
						</b>
					</div>
				))}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer,
		moonRisePositionReducer: state.moonRisePositionReducer
	};
};

export default connect(mapStateToProps)(DayLengthInfo);
