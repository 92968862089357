

export const reducer_stockExchangeList = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_STOCK_EXCHANGE_LIST':
        // eslint-disable-next-line no-case-declarations
        let newObj = {...action.data};
        newObj.stock_exchanged_sort = sortAlphabeticalOrder(newObj.stock_exchanged);
      return newObj;
    default:
      return state;
  }
};
const stock_exchanged = [{name : null, abbr_stock: null, logo: null, symbol: null, name_slug: null, schedule: []}];
export const reducer_stockExchangeSingle = (state = {stock_exchanged}, action) => {
    switch (action.type) {
        case 'ADD_STOCK_EXCHANGE_SINGLE':
            return {...action.data};
        default:
            return state;
    }
};

function sortAlphabeticalOrder(arr) {
    let newObj = [...arr];
 return newObj.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
}
