
const searchOpenReducer = (state = false, action) => {
  switch (action.type) {
    case 'SEARCHOPEN':
      return action.data;
    default:
      return state;
  }
};
  
export default searchOpenReducer;