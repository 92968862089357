import React, { Component } from "react";
import { Link } from "react-router-dom";

// import "./PasswordResetConfirmation.css";

class PasswordResetConfirmation extends Component {
	render() {
		return (
			<div className="authorization-wrapper">
				<section className="authorization">
					<h4>Reset Successful</h4>
					<div className="authorization-text">
						<p className="no-bottom-margin">
							Your password has been successfully reset. Click on
							the button below to sign in to tour account.
						</p>
					</div>
					<div className="authorization-button">
						<Link to="/signin">
							<button type="button">Sign in</button>
						</Link>
					</div>
				</section>
			</div>
		);
	}
}

export default PasswordResetConfirmation;
