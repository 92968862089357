import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";

class DayLengthInfo extends React.Component {
	dayLengthDifference() {
		const { sunCalcReducer } = this.props;
		return sunCalcReducer.sun_data.day_length_difference
			? sunCalcReducer.sun_data.day_length_difference
			: "";
	}

	sunPhases(elem) {
		const { sunCalcReducer } = this.props;
		if (elem.time && sunCalcReducer.timezone)
			return moment(elem.time)
				.tz(sunCalcReducer.timezone)
				.format("LT");
		else return "n/a";
	}

	render() {
		const { sunCalcReducer } = this.props;

		return (
			<div className="day-length-info">
				<h5 className="eclipse-header">
					Sunrise, Sunset, Solar Noon and Day length
				</h5>
				<div className="eclipse-info-block">
					<b>
						<span>Day length</span>
						<span>(HH:MM:SS):</span>
					</b>
					<b>
						<span>{sunCalcReducer.sun_data.day_length}</span>
						<span>{this.dayLengthDifference()}</span>
					</b>
				</div>
				<div className="eclipse-info-block">
					<b>
						<span>Difference between Solar noon and noon</span>
						<span>(HH:MM:SS):</span>
					</b>
					<b>{sunCalcReducer.sun_data.difference_solar_noon}</b>
				</div>
				{sunCalcReducer.sun_data.day_parts.map((elem, index) => (
					<div className="eclipse-info-block" key={index}>
						<b>{elem.title}</b>
						<b>
							<span>{this.sunPhases(elem)}</span>
						</b>
					</div>
				))}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer
	};
};

export default connect(mapStateToProps)(DayLengthInfo);
