import React, { Component } from "react";
import { connect } from "react-redux";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Link } from "react-router-dom";

import Api from "../../../../services/api";
import { resendMail } from "../../../../utils/sharedFunctions";
import EmailConfirmationFormValidation from "./EmailConfirmationFormValidation";
import validate from "./validate";
import "./EmailConfirmation.css";

class EmailConfirmation extends Component {
	state = {
		apiResponse: "wait",
		isResent: false,
		successSent: false
	};
	async componentDidMount() {
		let data = window.location.pathname;
		let arr = data.split("/");
		let key = { key: arr[3] };

		await Api.autoRegistKey("rest-auth/registration/verify-email/", key)
			.then(res => {
				if (res.status !== 200) {
					this.setState({ apiResponse: false });
				} else {
					this.setState({ apiResponse: true });
				}
			})
			.catch(e => this.setState({ apiResponse: false }));
	}

	resendVerificationMail = async () => {
		const result = await resendMail(
			this.props.form.EmailConfirmationForm.values.email
		);

		if (result.status === 200) {
			this.setState({
				isResent: "The letter sent successfully",
				successSent: true
			});
		} else {
			this.setState({
				isResent: "A user with this email does not exist.",
				successSent: false
			});
		}
	};

	render() {
		const { apiResponse, isResent, successSent } = this.state;

		if (apiResponse === "wait") return null;

		return (
			<div className="authorization-wrapper">
				{apiResponse ? (
					<section className="authorization">
						<h4>Verification successful</h4>
						<div className="authorization-text">
							<p>
								Your email has been successfully verified. Click
								on the button below to sign in to your account.
							</p>
							<div className="authorization-button">
								<Link to="/signin">
									<button type="button">Sign in</button>
								</Link>
							</div>
						</div>
					</section>
				) : (
					<section className="authorization">
						<h4>Verification link expired</h4>
						<div className="authorization-text">
							<p>
								This verification link has expired. Please enter
								your email and click on the button below to
								resend the link.
							</p>

							<MuiThemeProvider muiTheme={getMuiTheme()}>
								<EmailConfirmationFormValidation
									onSubmit={this.resendVerificationMail}
								/>
							</MuiThemeProvider>

							{isResent && (
								<p
									className={
										successSent
											? "authorization-success"
											: "authorization-error"
									}
								>
									{isResent}
								</p>
							)}
						</div>
					</section>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form
	};
};

export default connect(mapStateToProps)(EmailConfirmation);
