import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import validate from "./validate";

class ResetPassConfirmFormValidation extends Component {
	renderTextField = ({
		input,
		label,
		meta: { touched, error },
		...custom
	}) => (
		<TextField
			hintText={label}
			floatingLabelText={label}
			errorText={touched && error}
			{...input}
			{...custom}
		/>
	);
	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<Field
					id="pass"
					className="authorisation-form-input"
					name="new_password1"
					type="password"
					component={this.renderTextField}
					label="New Password"
				/>
				<Field
					className="authorisation-form-input"
					name="new_password2"
					type="password"
					component={this.renderTextField}
					label="Re-enter New Password"
				/>
				<div className="authorization-button">
					<button type="submit" disabled={pristine || submitting}>
						Reset Password
					</button>
				</div>
			</form>
		);
	}
}
export default reduxForm({ form: "ResetPassConfirmForm", validate })(
	ResetPassConfirmFormValidation
);
