import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone/index.js';

import './NextQuarterStartEnd.css';
import flightTimeInfo from '../../../../../actions/action_flightTimeInfo.js';

class NextQuarterStartEnd extends Component {
    quarterStyle(numm){
        if(+moment().format('Q') + 1 === numm){
            return { boxShadow: '0 1px 6px rgba(204, 153, 0, 1), 0 -1px 6px rgba(204, 153, 0, 1)' };
        }
    }
    render() {
        return (
            <div className='Calculator'>
                <div className='header'>
                    <h3>NEXT QUARTER START-END</h3>
                </div>
                <div className='content'>
                    <div className='quarter' style={this.quarterStyle(1)}>
                        <b>First quarter runs from January 01 – March 31.</b>
                    </div>
                    <div className='quarter' style={this.quarterStyle(2)}>
                        <b>Second quarter runs from April 01 – June 30.</b>
                    </div>
                    <div className='quarter' style={this.quarterStyle(3)}>
                        <b>Third quarter runs from July 01 – September 30.</b>
                        {/* <button>NEXT ONE</button> */}
                    </div>
                    <div className='quarter' style={this.quarterStyle(4)}>
                        <b>Fourth quarter runs from October 01 – December 31.</b>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        flightTimeInfoReducer: state.flightTimeInfoReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        flightTimeInfo: bindActionCreators(flightTimeInfo, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextQuarterStartEnd);