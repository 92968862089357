import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

class AdvancedSearch extends Component {
	state = {
		value: null
	};

	handleChange = (event, index, value) => this.setState({ value });

	render() {
		return (
			<MuiThemeProvider>
				<SelectField
					hintText="Advanced Search"
					value={this.state.value}
					onChange={this.handleChange}
					underlineStyle={{ display: 'none' }}
                    hintStyle={{ color: '#fff' }}
                    labelStyle={{ color: '#fff' }}
                    menuStyle={{maxWidth: 200}}
				>
					<MenuItem value={1} primaryText="City" />
					<MenuItem value={2} primaryText="Country, State, Region" />
					<MenuItem value={3} primaryText="Lakes" />
					<MenuItem value={4} primaryText="Parks" />
					<MenuItem value={5} primaryText="Buildings" />
					<MenuItem value={6} primaryText="Mountains" />
					<MenuItem value={7} primaryText="Forest" />
					<MenuItem value={8} primaryText="All" />
				</SelectField>
			</MuiThemeProvider>
		);
	}
}
export default AdvancedSearch;
