import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

// Google Analytics Module
import ReactGA from "react-ga";

import ScrollToTopRoute from "./ScrollToTopRoute";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import SignIn from "../components/Main/Authorization/SignIn/SignIn";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import SignUp from "../components/Main/Authorization/SignUp/SignUp";
import ForgotPass from "../components/Main/Authorization/ForgotPass/ForgotPass";
import ResetPassConfirm from "../components/Main/Authorization/ResetPassConfirm/ResetPassConfirm";
import ThankYouForRegistration from "../components/Main/Authorization/ThankYouForRegistration/ThankYouForRegistration";
import EmailConfirmation from "../components/Main/Authorization/EmailConfirmation/EmailConfirmation";
import PasswordResetConfirmation from "../components/Main/Authorization/PasswordResetConfirmation/PasswordResetConfirmation";
import MailSent from "../components/Main/Authorization/MailSent/MailSent";

import AccountPages from "../components/Main/AccountPages/AccountPages";

import NotFound from "../components/Main/NotFound/NotFound";
import MainPage from "../components/Main/MainPage/MainPage";
import SearchCityPage from "../components/Main/SearchCityPage/SearchCityPage";
import MainArticlePage from "../components/Main/Articles/MainArticlePage/MainArticlePage";
import ArticlePage from "../components/Main/Articles/ArticlePage/ArticlePage";
import SunCulc from "../components/Main/SunCulc/SunCulc";
import MoonCulc from "../components/Main/MoonCulc/MoonCulc";
import EclipsesWorld from "../components/Main/Eclipses/EclipsesWorld/EclipsesWorld";
import SunCityEclipses from "../components/Main/Eclipses/SunCityEclipses/SunCityEclipses";
import SunCatalogEclipses from "../components/Main/Eclipses/SunCatalogEclipses/SunCatalogEclipses";
import SunDetailsEclipses from "../components/Main/Eclipses/SunDetailsEclipses/SunDetailsEclipses";
import WorldClock from "../components/Main/WorldClock/WorldClock";
import Timezones from "../components/Main/Timezones/Timezones";
import Retrograde from "../components/Main/Ephemeris/Retrograde/Retrograde";
import PlanetesInSigns from "../components/Main/Ephemeris/PlanetesInSigns/PlanetesInSigns";
import FlightTimeCalculator from "../components/Main/Calculators/FlightTimeCalculator/FlightTimeCalculator";
import NextQuarter from "../components/Main/Calculators/NextQuarter/NextQuarter";
import DateToDateCalculator from "../components/Main/Calculators/DateToDateCalculator/DateToDateCalculator";
import BusinessDateToDateCalc from "../components/Main/Calculators/BusinessDateToDateCalc/BusinessDateToDateCalc";
import BusinessDateCalc from "../components/Main/Calculators/BusinessDateCalc/BusinessDateCalc";
import DateCalculator from "../components/Main/Calculators/DateCalculator/DateCalculator";
import Holidays from "../components/Main/MainPage/InformationBlocks/CityInfoMapBlocks/Holidays/Holidays";
import UnixConverter from "../components/Main/Calculators/UnixConverter/UnixConverter";
import JulianConverter from "../components/Main/Calculators/JulianConverter/JulianConverter";
import GPSConverter from "../components/Main/Calculators/GPSConverter/GPSConverter";
import CountdownTimer from "../components/Main/Calculators/CountdownTimer/CountdownTimer";
import TimeZoneConverter from "../components/Main/Calculators/TimeZoneConverter/TimeZoneConverter";
import CreatedTimerNewYear from "../components/Main/Calculators/CountdownTimer/CreatedTimerNewYear";
import CreatedTimer from "../components/Main/Calculators/CountdownTimer/CreatedTimer";
import PlanetaryHours from "../components/Main/PlanetaryHours/PlanetaryHours";
import MeetingPlanner from "../components/Main/MeetingPlanner/MeetingPlanner";
import Privacy from "../components/Main/Privacy/Privacy";
import Terms from "../components/Main/Terms/Terms";
import About from "../components/Main/About/About";
import Contact from "../components/Main/Contact/Contact";
import Sitemap from "../components/Main/Sitemap/Sitemap";
import GoogleCalendarSycn from "../components/Main/GoogleCalendarSycn/GoogleCalendarSycn";
import Panchang from "../components/Main/Panchang/Panchang";
import WorldExchanges from "../components/Main/WorldExchanges/WorldExchanges";
import WorldExchangeSingle from "../components/Main/WorldExchangeSingle/WorldExchangeSingle";

// GA init
ReactGA.initialize("UA-149596420-1");

class Router extends Component {
	componentDidMount() {
		/* homepage detect */
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		// console.log("this.props.location.pathname==============",this.props.location.pathname);
		/*if (this.props.location.pathname.substring(0,8) === "/sitemap") {
			// console.log("1");
			return (
				<ScrollToTopRoute>
                    <Header/>
					<main>
						<Switch>
							<Route path="/sitemap/:date" component={Sitemap}/>
							<Route path="/sitemap" component={Sitemap}/>
						</Switch>
					</main>
                    <Footer/>
				</ScrollToTopRoute>
			);
		}else*/
		return (
			<ScrollToTopRoute>
				<Header />
				<main>
					<Switch>
						<Route exact path="/" component={MainPage} />
						<Route path="/time-in/:city" component={MainPage} />

						<Route path="/signin" component={SignIn} />
						<Route path="/signup" component={SignUp} />
						<Route path="/linkedin" component={LinkedInPopUp} />
						<Route
							path="/set-new-password"
							component={ResetPassConfirm}
						/>
						<Route path="/forgot-password" component={ForgotPass} />
						<Route
							path="/thank-you-for-registration"
							component={ThankYouForRegistration}
						/>
						<Route
							path="/accounts/confirm-email"
							component={EmailConfirmation}
						/>
						<Route
							path="/reset-password-confirmation"
							component={PasswordResetConfirmation}
						/>
						<Route path="/mail-sent" component={MailSent} />

						<Route path="/account" component={AccountPages} />

						<Route path="/place/:city" component={SearchCityPage} />

						<Route
							path="/articles/:category/:name"
							component={ArticlePage}
						/>
						<Route path="/articles" component={MainArticlePage} />

						<Route path="/sun/:name" component={SunCulc} />
						<Route path="/moon/:name" component={MoonCulc} />

						<Route
							path="/catalog-of-all-solar-eclipses/world"
							component={SunCatalogEclipses}
						/>
						<Route
							path="/solar-eclipse-country/:date"
							component={SunDetailsEclipses}
						/>
						<Route
							path="/solar-eclipse/:city"
							component={SunCityEclipses}
						/>
						<Route
							path="/catalog-of-all-lunar-eclipses/world"
							component={SunCatalogEclipses}
						/>
						<Route
							path="/lunar-eclipse-country/:date"
							component={SunDetailsEclipses}
						/>
						<Route
							path="/lunar-eclipse/:city"
							component={SunCityEclipses}
						/>
						<Route
							path="/solar-lunar-eclipses/world"
							component={EclipsesWorld}
						/>
						<Route
							path="/solar-lunar-eclipses/:city"
							component={EclipsesWorld}
						/>
						<Route path="/panchang/" component={Panchang} />

						<Route path="/world-clock" component={WorldClock} />
						<Route
							path="/trading-hours-of-world-stock-exchanges-:name-time-type0/:id"
							component={WorldExchanges}
						/>
						<Route
							path="/trading-hours-of-world-stock-exchanges-:name-time-type1/:id"
							component={WorldExchanges}
						/>
						<Route
							path="/trading-hours-of-world-forex-exchanges-:name-time-type0/:id"
							component={WorldExchanges}
						/>
						<Route
							path="/trading-hours-of-world-forex-exchanges-:name-time-type1/:id"
							component={WorldExchanges}
						/>
						<Route
							path="/:nameStock-operating-hours-:name-time/:id"
							component={WorldExchangeSingle}
						/>
						<Route path="/timezones/" component={Timezones} />
						<Route path="/holidays" component={Holidays} />
						<Route
							path="/:namePlanet-retrograde/"
							component={Retrograde}
						/>
						<Route
							path="/:namePlanet-in-signs/"
							component={PlanetesInSigns}
						/>

						<Route
							path="/flight-time-calculator/"
							component={FlightTimeCalculator}
						/>
						<Route path="/next-quarter/" component={NextQuarter} />
						<Route
							path="/business-date-to-date-calculator/"
							component={BusinessDateToDateCalc}
						/>
						<Route
							path="/business-date-calculator/"
							component={BusinessDateCalc}
						/>
						<Route
							path="/date-to-date-calculator/"
							component={DateToDateCalculator}
						/>
						<Route
							path="/date-calculator/"
							component={DateCalculator}
						/>
						<Route
							path="/unix-time-converter/"
							component={UnixConverter}
						/>
						<Route
							path="/julian-time-converter/"
							component={JulianConverter}
						/>
						<Route
							path="/gps-time-converter/"
							component={GPSConverter}
						/>
						<Route
							path="/countdown-timer/"
							component={CountdownTimer}
						/>
						<Route
							path="/time-zone-converter/"
							component={TimeZoneConverter}
						/>
						<Route
							path="/created-timer/"
							component={CreatedTimer}
						/>
						<Route
							path="/countdown/newyear/"
							component={CreatedTimerNewYear}
						/>

						<Route
							path="/planetary-hours/"
							component={PlanetaryHours}
						/>

						<Route
							path="/meeting-planner/"
							component={MeetingPlanner}
						/>

						<Route
							path="/premium"
							render={() => <p>Premium page</p>}
						/>
						<Route path="/privacy" component={Privacy} />
						<Route path="/terms-of-service" component={Terms} />
						<Route path="/about-us" component={About} />
						<Route path="/contact-us" component={Contact} />
						<Route path="/sitemap/:date" component={Sitemap} />
						<Route path="/sitemap" component={Sitemap} />
						<Route path="/oauth2callback" component={GoogleCalendarSycn} />
						<Route component={NotFound} />
					</Switch>
				</main>
				<Footer />
			</ScrollToTopRoute>
		);
	}
}

export default withRouter(Router);
