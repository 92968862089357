import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import Api from "../../../../services/api";
import ForgotPassFormValidation from "./ForgotPassFormValidation";
import authorization from "../../../../actions/authorization";
import "./ForgotPass.css";

class ForgotPass extends Component {
  resetPass = async () => {
    const { form } = this.props;
    const email = form.ForgotPassForm.values.email;
    await Api.passwordReset(email);
    this.props.history.push("/mail-sent");
  };

  render() {
    return (
      <div className="authorization-wrapper">
        <section className="authorization">
          <h4>Forgot your password?</h4>
          <MuiThemeProvider muiTheme={getMuiTheme()}>
            <p className="authorization-forgot-note">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Please confirm your email address and we'll send you a link to
              reset password.
            </p>
            <div style={{ paddingBottom: 15 }}>
              <ForgotPassFormValidation onSubmit={this.resetPass} />
            </div>
          </MuiThemeProvider>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form
  };
};
const mapDispatchToProps = dispatch => {
  return {
    authorization: bindActionCreators(authorization, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
