import React, {Component} from 'react';

import '../../UnixConverter/Calculator/Calculator.css';

class SimpleCheckbox extends Component {

    // searchOption = (elem) =>{
    //     const {select} = this.props;
    //     select({name: elem.target.name, value: elem.target.value})
    // };
    //
     changeInclude = e => {
         const {options, getOptions} = this.props;

         let arr = options.map( item => {
             if (item.name === e.target.name && e.target.checked === true){
                 item.checked = 'checked';
                 return item;
             }else if (item.name === e.target.name && e.target.checked === false){
                 item.checked = '';
                 return item;
             }
             return item;
         });
         getOptions(arr);
    };

    render() {
        const {options} = this.props;
        const listRender = options.map( (item, index) =>
            <div className='check mb-8px' key={index}>
                <label>
                    <input type="checkbox" name={item.name} checked={item.checked} onChange={this.changeInclude}/>
                    <span className="pseudocheckbox">{item.name}</span>
                </label>
            </div>
        );

        return(
        <div>
            {listRender}
        </div>
    )
}
}


export default SimpleCheckbox;

//     height: 40px;
//     margin-right: 12px;
