import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import moonRiseShadow from "../../../../../actions/action_moonRise/action_moonRiseShadow";

import EclipseInfo from "./Blocks/EclipseInfo";
import AzimuthInfo from "./Blocks/AzimuthInfo";
import Links from "./Blocks/Links";
import DayLengthInfo from "./Blocks/DayLengthInfo";
import Ads from "./Blocks/Ads";

import "./MoonCulcRightBlockInfo.css";

class MoonCulcRightBlockInfo extends Component {
	state = {
		objectHeight: 1,
		mobileLayout: true
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	}

	componentDidUpdate() {
		const { moonRiseShadow, moonRisePositionReducer } = this.props;
		const shadowLength = (
			this.state.objectHeight / Math.tan(moonRisePositionReducer.alt)
		).toFixed(2);
		const shadowCords =
			!isNaN(+shadowLength) && +shadowLength > 0 ? shadowLength : 0;
		moonRiseShadow(shadowCords);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {
		if (window.innerWidth < 480) {
			this.setState({ mobileLayout: true });
		} else {
			this.setState({ mobileLayout: false });
		}
	};

	render() {
		const { mobileLayout } = this.state;
		const { moonRisePositionReducer } = this.props;

		if (!moonRisePositionReducer.moon) return <div></div>;

		return (
			<div className="sunCulcCityInfoRightSide">
				{mobileLayout ? (
					<div className="sunCulcCityInfoRightSide-wrapper">
						<EclipseInfo />
						<DayLengthInfo />
						<Ads />
						<AzimuthInfo />
						<Links />
					</div>
				) : (
					<Fragment>
						<div className="sunCulcCityInfoRightSide-wrapper sunCulcCityInfoRightSide-wrapper__first">
							<EclipseInfo />
							<AzimuthInfo />
							<Links />
						</div>
						<div className="sunCulcCityInfoRightSide-wrapper sunCulcCityInfoRightSide-wrapper__second">
							<DayLengthInfo />
							<Ads />
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonRisePositionReducer: state.moonRisePositionReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		moonRiseShadow: bindActionCreators(moonRiseShadow, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MoonCulcRightBlockInfo);
