import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createBrowserHistory } from "history";
import moment from "moment-timezone/index.js";
import Select from "react-select";
// import 'react-select/dist/react-select.css';

import "./Search.css";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import stockSearchCity from "../../../../actions/action_searchCity/action_stockSearchCity";
import calendarDateTableSun from "../../../../actions/action_sunRise/action_calendarDateTableSun";
import sunCalc from "../../../../actions/action_sunRise/action_sunCalc";
import sunRisePosition from "../../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../../actions/action_sunRise/action_sunRiseCoords";
import sunRiseTableList from "../../../../actions/action_sunRise/action_sunRiseTableList";
import sunRiseDate from "../../../../actions/action_sunRise/action_sunRiseDate";
import sunLoaderShow from "../../../../actions/action_sunRise/action_sunLoaderShow";
import calendarDateTableMoon from "../../../../actions/action_moonRise/action_calendarDateTableMoon";
import moonCalc from "../../../../actions/action_moonRise/action_moonCalc";
import moonRisePosition from "../../../../actions/action_moonRise/action_moonRisePosition";
import moonRiseCoords from "../../../../actions/action_moonRise/action_moonRiseCoords";
import moonRiseTableList from "../../../../actions/action_moonRise/action_moonRiseTableList";
import moonRiseDate from "../../../../actions/action_moonRise/action_moonRiseDate";
import moonLoaderShow from "../../../../actions/action_moonRise/action_moonLoaderShow";
import eclipsesData from "../../../../actions/action_eclipsesData";
import eclipsesDataTable from "../../../../actions/action_eclipsesDataTable";
import weatherInfo from "../../../../actions/action_weatherInfo";
import weatherInfoOpen from "../../../../actions/action_weatherInfoOpen";
import airportsList from "../../../../actions/action_airportsList.js";
import holidaysBlock from "../../../../actions/action_holidaysBlock";
import timeZoneConverter from "../../../../actions/action_timeZoneConverter/action_timeZoneConverter.js";
import timeZoneConverterTime from "../../../../actions/action_timeZoneConverter/action_timeZoneConverterTime.js";
import newYearCountdown from "../../../../actions/action_newYearCountdown.js";
import planetaryHours from "../../../../actions/action_planetaryHours/action_planetaryHours";
import planetaryHoursTable from "../../../../actions/action_planetaryHours/action_planetaryHoursTable";
import nextPlanetRetrograde from "../../../../actions/action_nextPlanetRetrograde";
import planetesRetrograde from "../../../../actions/action_planetesRetrograde";
import planetesInSigns from "../../../../actions/action_planetesInSigns";
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import Api from "../../../../services/api.js";

const history = createBrowserHistory();

class Search extends Component {
  state = {
    url: "/",
    cities: [],
    selectedCity: "",
    selectedCityInfo: [],
    options: [],
    selectOption: "x",
    searchPlaceholder: this.props.groutPlaceholder
      ? this.props.groutPlaceholder
      : "City", //'City or Country',
    selectedCityIndex: 0,
    searchOpen: { border: "none", height: 0, margin: "0" },
    addedCity: {},
    listElemIndex: "none",
    selectedElemIndex: "null",
    mousePosTop: 0,
    mousePosLeft: 0,
    searchArea: "none",
    searchErrorStyle: {},
    additionalResults: false,
    notification: { opacity: 0, height: 0, padding: 0, zIndex: "-1" },
    prevSearchCity: null
  };

  UNSAFE_componentWillMount() {
    this.searchClose = document.addEventListener("click", e => {
      if (
        e.target.className !== "search_form_list" &&
        e.target.className !== "search_field"
      ) {
        this.setState({
          searchOpen: { border: "none", height: 0, margin: "0" },
          searchErrorStyle: {}
        });
      }
    });
    this.keydownSearchClose = document.body.addEventListener("keydown", e => {
      if (e.keyCode === 27) {
        this.setState({
          searchOpen: { border: "none", height: 0, margin: "0" },
          searchErrorStyle: {}
        });
      }
    });
  }

  componentWillUnmount() {
    window.clearTimeout(this.notification);
    document.removeEventListener("click", this.searchClose);
    document.removeEventListener("keydown", this.keydownSearchClose);

    if (this.props.stockExchangesPage) {
      /* set prev. search city */
      this.props.currentSearch(this.state.prevSearchCity);
      localStorage.setItem(
        "mainSearchCity",
        JSON.stringify(this.state.prevSearchCity)
      );
    }
  }

  async componentDidMount() {
    let searchArea = "none";
    if (
      window.location.pathname.split("/")[1] === "eclipses" ||
      window.location.pathname.split("/")[1] === "time-zone-converter" ||
      window.location.pathname === "/countdown/newyear"
    )
      searchArea = "P";
    const featureClasses = await Api.userCityList(`feature_classes/`);
    this.setState({
      options: featureClasses.data.results,
      searchArea: searchArea
    });
    // document.body.addEventListener('click', e => {
    //     if(e.target.className !== 'search_form_list' && e.target.className !== 'search_field'){
    //         this.setState(prevState => {
    //             return {
    //                 searchOpen: {border: 'none', height: 0}
    //             };
    //         });
    //     }
    // });
    this.searchKeyPress();
    this.searchKeyEnter();

    if (this.props.stockExchangesPage) {
      /* remembre current search city */
      this.setState({ prevSearchCity: this.props.currentSearchReducer });
    }
  }

  displayNotification = () => {
    const searchNotification = window.setTimeout(() => {
      this.setState(prevState => {
        return {
          notification: {
            opacity: 0,
            height: 0,
            padding: 0,
            zIndex: "-1"
          }
        };
      });
    }, 5000);
    window.clearTimeout(this.notification);
    this.notification = searchNotification;
  };

  searchValue = e => {
    const value = e.target.value.replace(/\s+/g, " ");
    this.setState({ selectedCity: value });
    this.searchValueList(value);
  };

  async searchValueList(value) {
    if (value.length > 2) {
      let data = {};
      if (
        window.location.pathname.split("/")[1] === "eclipses" ||
        window.location.pathname.split("/")[1] === "time-zone-converter" ||
        window.location.pathname === "/countdown/newyear"
      ) {
        data = await Api.userCityList(
          `search/?q=${value}&feature_classes=${this.state.searchArea}`
        );
      } else if (window.location.pathname === "/account") {
        data = await Api.userCityList(`search/?q=${value}&feature_classes=P`);
      } else {
        data = await Api.userCityList(
          `search/?q=${value}&feature_classes=${this.state.selectOption}`
        );
      }
      const stylsAdditionalResults =
        data.data.additional_results === true ? "calc(100% + 42px)" : "100%";

      document.body.querySelector(".search_form_list").scrollTo(0, 0);
      if (this.state.selectedCity.length > 2) {
        this.setState({
          listElemIndex: 0,
          cities: data.data.locations_list,
          searchOpen: {
            height: "auto",
            margin: "0",
            top: stylsAdditionalResults
          },
          // searchOpen: { border: '1px solid rgba(210, 213, 213, 1)', height: 'auto', margin: '0', top: stylsAdditionalResults },
          searchErrorStyle:
            data.data.additional_results === true ? { display: "block" } : {},
          additionalResults:
            data.data.additional_results === true ? true : false
        });
      }
    } else {
      this.setState({
        listElemIndex: 0,
        cities: [],
        searchOpen: { border: "none", height: 0, margin: "0" }
      });
    }
  }
  async selectedCity(city, e) {
    // if(e.button === 0){
    this.setState({
      selectedCity: "",
      selectedCityIndex: city.index,
      cities: [],
      searchOpen: { border: "none", height: 0, margin: "0" },
      searchErrorStyle: {}
    });
    this.selectedCityInfo(city, city.id);
    // }
  }
  async selectedCityInfo(city, id) {
    const { currentSearchReducer } = this.props;
    if (
      window.location.href
        .replace(`${window.location.origin}/`, "")
        .split("/")[0] === "sun"
    ) {
      const {
        sunCalc,
        sunRisePosition,
        sunRiseCoords,
        sunRiseTableList,
        sunRiseDate,
        calendarDateTableSun,
        sunLoaderShow
      } = this.props;

      sunLoaderShow(true);
      const sunCoordinats = await Api.userCityList(
        `geo_names/${id}/?sun_app=true`
      );
      sunCalc(sunCoordinats.data);
      sunRiseDate(sunCoordinats.data.time);
      calendarDateTableSun(sunCoordinats.data.time);

      const timeNoFormat = moment(sunCoordinats.data.time).tz(
        sunCoordinats.data.timezone
      );
      const time = timeNoFormat.format("YYYY-MM-DDTHH:mm:ss");
      const sunInfo = await Api.userCityList(
        `sun_data/?geoname_id=${sunCoordinats.data.id}&dt=${time}`
      );
      sunRiseTableList(sunInfo.data.sun_data);

      localStorage.setItem(
        "sunCalcCityInfo",
        JSON.stringify(sunCoordinats.data)
      );
      const hourCurrent = moment()
        .tz(sunCoordinats.data.timezone)
        .get("hour");
      const minuteCurrent = moment()
        .tz(sunCoordinats.data.timezone)
        .get("minute");
      // const hourCurrent = moment().tz(moment.tz.guess()).get('hour');
      // const minuteCurrent = moment().tz(moment.tz.guess()).get('minute');
      for (let key in sunCoordinats.data.sun_data.position_during_day) {
        const hourDot = moment(
          sunCoordinats.data.sun_data.position_during_day[key].loc_time
        )
          .tz(sunCoordinats.data.timezone)
          .get("hour");
        const minuteDot = moment(
          sunCoordinats.data.sun_data.position_during_day[key].loc_time
        )
          .tz(sunCoordinats.data.timezone)
          .get("minute");
        if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
          sunRiseCoords(+key);
          sunRisePosition(
            sunCoordinats.data.sun_data.position_during_day[+key]
          );
        }
      }

      let sunUrl = "";
      sunCoordinats.data.url
        ? (sunUrl = `/sun/${sunCoordinats.data.url
            .split("/")
            .slice(1)
            .join("/")}`)
        : (sunUrl = `/sun/null/`);
      history.push(sunUrl);
      sunLoaderShow(false);
    } else if (
      window.location.href
        .replace(`${window.location.origin}/`, "")
        .split("/")[0] === "moon"
    ) {
      const {
        moonCalc,
        moonRisePosition,
        moonRiseCoords,
        moonRiseTableList,
        moonRiseDate,
        calendarDateTableMoon,
        moonLoaderShow
      } = this.props;
      moonLoaderShow(true);
      const moonCoordinats = await Api.userCityList(
        `geo_names/${id}/?moon_app=true`
      );
      moonCalc(moonCoordinats.data);
      moonRiseDate(moonCoordinats.data.time);
      calendarDateTableMoon(moonCoordinats.data.time);

      const timeNoFormat = moment(moonCoordinats.data.time).tz(
        moonCoordinats.data.timezone
      );
      const time = timeNoFormat.format("YYYY-MM-DDTHH:mm:ss");
      const moonInfo = await Api.userCityList(
        `moon_data/?geoname_id=${moonCoordinats.data.id}&dt=${time}`
      );
      moonRiseTableList(moonInfo.data);

      localStorage.setItem(
        "moonCalcCityInfo",
        JSON.stringify(moonCoordinats.data)
      );
      const hourCurrent = moment()
        .tz(moment.tz.guess())
        .get("hour");
      const minuteCurrent = moment()
        .tz(moment.tz.guess())
        .get("minute");
      for (let key in moonCoordinats.data.moon_data.position_during_day) {
        const hourDot = moment(
          moonCoordinats.data.moon_data.position_during_day[key].loc_time
        )
          .tz(moonCoordinats.data.timezone)
          .get("hour");
        const minuteDot = moment(
          moonCoordinats.data.moon_data.position_during_day[key].loc_time
        )
          .tz(moonCoordinats.data.timezone)
          .get("minute");
        if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
          moonRiseCoords(+key);
          moonRisePosition(
            moonCoordinats.data.moon_data.position_during_day[+key]
          );
        }
      }

      let moonUrl = "";
      moonCoordinats.data.url
        ? (moonUrl = `/moon/${moonCoordinats.data.url
            .split("/")
            .slice(1)
            .join("/")}`)
        : (moonUrl = `/moon/null/`);
      history.push(moonUrl);
      moonLoaderShow(false);
    } else if (
      window.location.pathname.split("/")[1] === "solar-lunar-eclipses"
    ) {
      const { eclipsesData } = this.props;
      const date = moment()
        .tz(city.timezone)
        .format("YYYY-MM-DD");
      const eclipses = await Api.userCityList(
        `eclipse_city/${id}/?date=${date}`
      );
      history.push(
        `/solar-lunar-eclipses/${eclipses.data.url.replace("place/", "")}`
      );
      eclipsesData(eclipses.data);
      localStorage.setItem("eclipses", JSON.stringify(eclipses.data));
    } else if (
      window.location.pathname.split("/")[1] === "solar-eclipse-country" ||
      window.location.pathname.split("/")[1] === "lunar-eclipse-country" ||
      window.location.pathname.split("/")[1] ===
        "catalog-of-all-solar-eclipses" ||
      window.location.pathname.split("/")[1] === "catalog-of-all-lunar-eclipses"
    ) {
      const solarType = window.location.pathname
        .split("/")[1]
        .split("-")
        .filter(elem => elem === "solar");
      const type = solarType.length ? "solar" : "lunar";
      const eclipses = await Api.userCityList(`eclipse_city/${city.id}/`);
      window.location.assign(
        `/${type}-eclipse/${eclipses.data.url.replace("place/", "")}/${moment(
          eclipses.data.time
        )
          .tz(eclipses.data.timezone)
          .format("YYYY-MM-DD")}`
      );
    } else if (
      window.location.pathname.split("/")[1] === "solar-eclipse" ||
      window.location.pathname.split("/")[1] === "lunar-eclipse"
    ) {
      const { eclipsesData, eclipsesDataTable } = this.props;
      const location = window.location.pathname.split("/");
      const solarType = location[1].split("-").filter(elem => elem === "solar");
      const type = solarType.length ? "solar" : "lunar";
      const date = location.reverse()[0];
      const time = moment()
        .tz(city.timezone)
        .get("year");
      const newPeriod = `${time} - ${time + 9}`;
      const periodList = ["Next 10 Years"];
      for (let i = 1900; i < 2099; i += 10) {
        periodList.push(`${i} - ${i + 9}`);
      }
      const eclipses = await Api.userCityList(
        `eclipse_city/${city.id}/?date=${date}`
      );
      const eclipseInfo = await Api.userCityList(
        `visible_eclipse_city/${city.id}/${type}/?period=${newPeriod}&type=all`
      );
      eclipsesDataTable({
        eclipseData: eclipseInfo.data.eclipses_data,
        periodList: periodList,
        period: periodList[0],
        eclipseType: "all"
      });
      eclipsesData(eclipses.data);
      history.push(
        `/${type}-eclipse/${eclipses.data.url.replace("place/", "")}/${date}`
      );
    } else if (
      window.location.pathname.split("/")[1].split("-")[1] === "retrograde"
    ) {
      const { currentSearch, stockSearchCity, planetesRetrograde } = this.props;
      const data = await Api.userCityList(`geo_names/${id}/`);

      currentSearch(data.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(data.data);
      }

      const tz = data.data.timezone;
      const year = moment()
        .tz(tz)
        .get("year");
      const planet = window.location.pathname.split("/")[1].split("-")[0];
      const retrogradeRequest = await Api.userCityList(
        `retrograde/${data.data.id}/${year}/?p=${planet}&exclude_past=true`
      );

      const yearList = ["Next 10 Years"];
      for (let i = 1900; i < 2099; i += 10) {
        yearList.push(
          `${moment()
            .tz(tz)
            .set("year", i)
            .format()} - ${moment()
            .tz(tz)
            .set("year", i + 10)
            .format()}`
        );
      }

      planetesRetrograde({
        planet: planet,
        period: "Next 10 Years",
        periodes: yearList,
        individualText: retrogradeRequest.data.individual_text,
        retrogradeList: retrogradeRequest.data.data,
        current_zodiac: retrogradeRequest.data.current_zodiac
      });
      nextPlanetRetrograde({
        retroList: retrogradeRequest.data.next_retrograde,
        type: "Current"
      });

      // const retrogradeUrl = data.data.url ?
      //     `/${planet}-retrograde/${data.data.url.split('/').slice(1).join('/')}` :
      //     `/${planet}-retrograde/null/`;
      const retrogradeUrl = `/${planet}-retrograde/${data.data.url
        .split("/")
        .slice(1)
        .join("/")}`;
      history.push(retrogradeUrl);
    } else if (
      window.location.pathname.split("/")[1].split("-")[2] === "signs"
    ) {
      const {
        currentSearch,
        stockSearchCity,
        planetesInSigns,
        planetesInSignsReducer
      } = this.props;

      const data = await Api.userCityList(`geo_names/${id}/`);

      currentSearch(data.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(data.data);
      }

      const periodVal = planet => {
        if (planet === "Sun") return 1;
        else if (planet === "Moon") return 10;
        else if (
          planet === "Mercury" ||
          planet === "Venus" ||
          planet === "Mars"
        )
          return 2;
        else if (planet === "Jupiter") return 5;
        else if (planet === "Saturn" || planet === "Uranus") return 10;
        else if (
          planet === "Neptune" ||
          planet === "Pluto" ||
          planet === "Chiron"
        )
          return 20;
      };
      const periodCalc = planet => {
        const tz = "Greenwich";
        const yearListCreation = years => {
          const yearList = [];
          yearList.push(`Next ${years} years`);
          for (let i = 1917; i < 2089; i += years) {
            yearList.push(
              `${moment()
                .tz(tz)
                .set("year", i)
                .format()} - ${moment()
                .tz(tz)
                .set("year", i + years)
                .format()}`
            );
          }
          return yearList;
        };
        return yearListCreation(periodVal(planet));
      };
      const planet = window.location.pathname.split("/")[1].split("-")[0];
      const periodList = periodCalc(planet);

      const start = periodList[1].split(" - ")[0];
      const end = periodList[1].split(" - ")[1];

      const tz = "Greenwich";
      let planetesInSignsRequest;
      const startDate = moment()
        .tz(tz)
        .format();
      if (planet === "Moon") {
        const endDate = moment()
          .tz(tz)
          .add(3, "months")
          .format();
        planetesInSignsRequest = await Api.userCityList(
          `planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`
        );
      } else {
        const endDate = moment()
          .tz(tz)
          .add(periodVal(planet), "years")
          .format();
        planetesInSignsRequest = await Api.userCityList(
          `planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`
        );
      }
      planetesInSigns({
        planet: planet,
        start: start,
        month: planetesInSignsReducer.month,
        end: end,
        period: periodList[0],
        periodName: periodList[0],
        periodList: periodList,
        year: moment()
          .tz(tz)
          .format("YYYY"),
        planetesInSignsList: planetesInSignsRequest.data.data,
        current_sign: planetesInSignsRequest.data.current_sign,
        next_sign: planetesInSignsRequest.data.next_sign,
        individualText: planetesInSignsRequest.data.individual_text,
      });

      const planetSignsUrl = `/${planet}-in-signs/${data.data.url
        .split("/")
        .slice(1)
        .join("/")}`;
      // const planetSignsUrl = data.data.url ?
      //     `/${planet}-in-signs/${data.data.url.split('/').slice(1).join('/')}` :
      //     `/${planet}-in-signs/null/`;
      history.push(planetSignsUrl);
    } else if (window.location.pathname.split("/")[1] === "holidays") {
      const { currentSearch, stockSearchCity } = this.props;
      const data = await Api.userCityList(`geo_names/${id}/`);

      currentSearch(data.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(data.data);
      }

      // let corectUrl =  url.split('/').slice(1);
      // corectUrl.splice(1, 1);
      // const holidaysUrl = url.length ? `/holidays/${corectUrl.join('/')}` : `/holidays/null/`;
      // this.props.history.push(holidaysUrl);

      const holidaysUrl = `/holidays/${data.data.url
        .split("/")
        .slice(1)
        .join("/")}`;
      // const holidaysUrl = data.data.url ?
      //     `/holidays/${data.data.url.split('/').slice(1).join('/')}` :
      //     `/holidays/null/`;
      history.push(holidaysUrl);
    } else if (
      window.location.pathname.split("/")[1] === "time-zone-converter"
    ) {
      const {
        timeZoneConverter,
        timeZoneConverterReducer,
        timeZoneConverterTime
      } = this.props;
      const city = await Api.userCityList(`geo_names/${id}/`);

      const addedCity = timeZoneConverterReducer.filter(elem => id === elem.id);
      if (!addedCity.length) {
        let cityList = timeZoneConverterReducer.concat([city.data]);
        if (!timeZoneConverterReducer.length) {
          const city = cityList[0];
          const zeroTime = +moment()
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .set("millisecond", 0)
            .format("x");
          const cityTime = +moment()
            .tz(city.timezone)
            .format("x");
          const timeMoment = moment().tz(cityList[0].timezone);
          const year = timeMoment.get("year");
          const month = timeMoment.get("month"); // 0 to 11
          const day = timeMoment.get("date");
          const hour = timeMoment.get("hour");
          const minute = timeMoment.get("minute");
          const second = timeMoment.get("second");
          const millisecond = timeMoment.get("millisecond");
          const time = new Date(
            year,
            month,
            day,
            hour,
            minute,
            second,
            millisecond
          );
          timeZoneConverterTime({
            calendarDate: time,
            sliderDot: Math.round((cityTime - zeroTime) / 60000)
          });
        }
        timeZoneConverter(cityList);
        localStorage.setItem("timeZoneConverterList", JSON.stringify(cityList));
      }
    } else if (window.location.pathname === "/countdown/newyear") {
      const { newYearCountdown } = this.props;
      const city = await Api.userCityList(`geo_names/${id}/`);
      newYearCountdown(city.data);
    } else if (window.location.pathname.split("/")[1] === "planetary-hours") {
      const {
        currentSearch,
        stockSearchCity,
        planetaryHoursTable,
        planetaryHours
      } = this.props;
      const citySearch = await Api.userCityList(`geo_names/${id}/`);

      currentSearch(citySearch.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(citySearch.data);
      }

      // planetaryHours time
      const cityTimeNoFormat = moment(citySearch.data.time).tz(
        citySearch.data.timezone
      );
      const year = cityTimeNoFormat.get("year");
      const month = cityTimeNoFormat.get("month");
      const day = cityTimeNoFormat.get("date");
      planetaryHours(cityTimeNoFormat.format());

      const planetaryHoursData = await Api.userCityList(
        `geo_names/${id}/rise_set_rise/${year}-${month + 1}-${day}/`
      );
      planetaryHoursTable(planetaryHoursData.data.data);
      const planetaryHoursUrl = `/planetary-hours/${citySearch.data.url
        .split("/")
        .slice(1)
        .join("/")}`;
      // const holidaysUrl = data.data.url ?
      //     `/holidays/${data.data.url.split('/').slice(1).join('/')}` :
      //     `/holidays/null/`;
      history.push(planetaryHoursUrl);
      // }else if(window.location.pathname === '/'){
    } else if (window.location.pathname.split("/")[1] === "panchang") {
      const { currentSearch, stockSearchCity } = this.props;
      const citySearch = await Api.userCityList(`geo_names/${id}/`);

      currentSearch(citySearch.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(citySearch.data);
      }

      const panchangUrl = `/panchang/${citySearch.data.url
        .split("/")
        .slice(1)
        .join("/")}`;
      // const panchangUrl = citySearch.data.url ?
      //     `/panchang/${citySearch.data.url.split('/').slice(1).join('/')}` :
      //     `/panchang/null/`;
      history.push(panchangUrl);
    } else if (window.location.pathname === "/account") {
      const { groupNumm, groupNameChange } = this.props;
      const newElem = {
        act: "save",
        geoname_id: `${id}`,
        group: `${groupNumm}`,
        sort_by: `0`,
        name_city_replace: ``
      };
      const token = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : sessionStorage.getItem("token");

      if (token) {
        const updateData = await Api.changeInfo(`main-cities-reg-gr/`, token, [
          newElem
        ]);
        if (
          updateData.data.result[0].error_params &&
          updateData.data.result[0].error_params[0].error.includes(
            "TOO MANY CITIES"
          )
        ) {
          this.setState({
            notification: {
              opacity: "1",
              height: "58px",
              padding: "20px"
            }
          });
          this.displayNotification();
        }
      }
      const data = await Api.userInfo("main-cities-reg/0/get/", token);
      groupNameChange(data.data);
    } else {
      const {
        currentSearch,
        currentSearchReducer,
        stockSearchCity,
        nextPlanetRetrograde,
        weatherInfo,
        weatherInfoOpen,
        airportsList,
        holidaysBlock
      } = this.props;

      airportsList([]);
      holidaysBlock({
        holidays_data: true,
        holidays_list: []
      });

      const data = await Api.userCityList(`geo_names/${id}/`);
      this.setState({ selectedCityInfo: data.data });

      this.props.stockExchangesPage
        ? history.push(
            `/trading-hours-of-world-stock-exchanges-${data.data.name}`
          )
        : history.push(`/time-in${data.data.url.replace("place", "")}`);

      currentSearch(data.data);
      if (this.props.stockExchangesPage) {
        stockSearchCity(data.data);
      }

      // localStorage.setItem('searchCityInfo', JSON.stringify(data.data));
      localStorage.setItem("mainSearchCity", JSON.stringify(data.data));
      sessionStorage.setItem("sessionParam", true);

      if (currentSearchReducer.expand_weather === true) {
        const weather = await Api.userCityList(
          `weather/${data.data.latitude},${data.data.longitude}/`
        );
        weatherInfo(weather.data);
      } else {
        const newCity = await Api.userCityList(`geo_names/${id}/`);
        const weather = await Api.userCityList(
          `weather/${newCity.data.latitude},${newCity.data.longitude}/`
        );
        weatherInfo(weather.data);
      }

      const year = moment()
        .tz(data.data.timezone)
        .format("YYYY");
      const dataRetro = await Api.userCityList(
        `retrograde/${id}/${year}/?p=mercury&show_data=false`
      );
      nextPlanetRetrograde(dataRetro.data.next_retrograde);

      const airports = await Api.userCityList(
        `airports/${id}/get_nearby/?dist=100`
      );
      airportsList(airports.data);

      const holidays = await Api.userCityList(
        `holidays/?country=${data.data.country_code}`
      );
      holidaysBlock(holidays.data);
    }
  }
  // search: handling of pressing the arrows (up, down)
  searchKeyPress() {
    document.body.addEventListener("keydown", e => {
      const cityList = [
        ...document.body.querySelectorAll(".selectCityWrapper")
      ];
      const scrollBlock = document.body.querySelector(".search_form_list");
      let height;
      const pressNext = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top <= scrollBlock.offsetHeight - height
          ? 0
          : top - scrollBlock.offsetHeight + height;
      };
      const pressPrev = newIndex => {
        const selectedCity = cityList.filter(
          (elem, index) => newIndex === index
        );
        const top = selectedCity[0].offsetTop;
        height = selectedCity[0].offsetHeight;
        return top;
      };
      if (
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 40 &&
          this.state.listElemIndex >= cityList.length - 1)
      ) {
        this.setState(prevState => {
          scrollBlock.scrollTo(0, pressNext(0));
          return { listElemIndex: 0 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 40 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressNext(prevState.listElemIndex + 1);
          if (scrollBlock.scrollTop < topPos) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex + 1 };
        });
      } else if (
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex === "none") ||
        (this.state.cities.length &&
          e.keyCode === 38 &&
          this.state.listElemIndex <= 0)
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(cityList.length - 1);
          scrollBlock.scrollTo(0, topPos);
          return { listElemIndex: cityList.length - 1 };
        });
      } else if (
        this.state.cities.length &&
        e.keyCode === 38 &&
        this.state.listElemIndex !== "none"
      ) {
        this.setState(prevState => {
          const topPos = pressPrev(prevState.listElemIndex - 1);
          if (topPos < scrollBlock.scrollTop) {
            scrollBlock.scrollTo(0, topPos);
          }
          return { listElemIndex: prevState.listElemIndex - 1 };
        });
      }
    });
  }
  // search: enter press
  searchKeyEnter() {
    document.body.addEventListener("keydown", e => {
      if (this.state.cities.length > 0 && e.keyCode === 13) {
        const selectedCity = this.state.cities.filter((elem, index) => {
          return index === this.state.listElemIndex;
        });
        const city = selectedCity[0];
        this.selectedCity(city);

        this.setState({
          searchOpen: { border: "none", height: 0, margin: "0" },
          searchErrorStyle: {}
        });
      }
    });
  }
  // search: mouse pointer processing
  searchMouseEventMouseOver = (index, e) => {
    let mouseTop = e.screenY;
    let mouseLeft = e.screenX;
    this.setState(prevState => {
      if (
        mouseTop !== prevState.mousePosTop ||
        mouseLeft !== prevState.mousePosLeft
      ) {
        return {
          listElemIndex: index,
          mousePosTop: mouseTop,
          mousePosLeft: mouseLeft
        };
      }
    });
  };
  // search: click/select
  searchListHover = index => {
    this.setState({
      selectedElemIndex: index
    });
  };
  searchStyle(index) {
    // if(this.state.selectedElemIndex === index){
    //     return { background: '#7ea7d8', color: '#fff' };
    // }else
    if (this.state.listElemIndex === index) {
      return { background: "rgba(103, 150, 206, 1)", color: "#fff" };
    } else {
      return { background: "#fff", color: "rgba(68, 68, 68, 1)" };
    }
  }
  highlight(highlight) {
    return highlight === true ? {} : { fontWeight: "normal" };
  }
  notEmptyName(param) {
    return param ? `${param}` : "";
  }
  notEmptyState(param) {
    return param ? `${param}, ` : "";
  }
  notEmptyFeature(param) {
    return param ? ` (${param})` : "";
  }
  searchFocus = e => {
    if (this.state.cities.length > 0 && e.button === 0) {
      const styleAdditionalResults =
        this.state.additionalResults === true ? "calc(100% + 42px)" : "100%";
      this.setState(prevState => {
        return {
          searchOpen: {
            height: "auto",
            margin: "0",
            top: styleAdditionalResults
          },
          // searchOpen: { border: '1px solid rgba(210, 213, 213, 1)', height: 'auto', margin: '0', top: styleAdditionalResults },
          searchErrorStyle:
            this.state.additionalResults === true ? { display: "block" } : {}
        };
      });
    }
  };
  searchOption = value => {
    this.setState(prevState => {
      return {
        selectOption: value.value,
        searchPlaceholder: value.label,
        selectedCity: "",
        cities: [],
        searchOpen: { border: "none", height: 0, margin: "0" },
        searchErrorStyle: {}
      };
    });
  };
  // searchErrorStyle(){
  //     return this.state.additionalResults === false ? { display: 'none' } : {};
  // }
  searchBlock(numm) {
    if (
      window.location.pathname.split("/")[1] === "eclipses" ||
      window.location.pathname === "/countdown/newyear"
    ) {
      switch (numm) {
        case 1:
          return { width: "400px" };
        case 2:
          return { margin: 0 };
        default:
          return {};
      }
    } else if (window.location.pathname === "/account") {
      const { style } = this.props;
      return style;
    }
  }
  searchClassName() {
    if (
      window.location.pathname.split("/")[1] === "eclipses" ||
      window.location.pathname.split("/")[1] === "time-zone-converter" ||
      window.location.pathname === "/countdown/newyear" ||
      window.location.pathname === "/account"
    )
      return "search_options close_options";
    else return "search_options";
  }
  render() {
    const { homePageAdvancedSearch } = this.props;

    return (
      <section className="Search" style={this.searchBlock(1)}>
        <div className="search_form">
          <div className="search_form_select" style={this.searchBlock(2)}>
            <div
              className="timepicker-notification"
              style={this.state.notification}
            >
              The maximum number of cities have been added to this group
            </div>

            <input
              className="search_field"
              type="text"
              value={this.state.selectedCity}
              placeholder={this.state.searchPlaceholder.toUpperCase()}
              onChange={this.searchValue}
              onMouseUp={this.searchFocus}
            />
            <div
              className="searchError"
              style={
                this.state.cities.length ? this.state.searchErrorStyle : {}
              }
            >
              <span>Nothing found on your request</span>
              But there are results in other areas
            </div>
            <ul style={this.state.searchOpen} className="search_form_list">
              {this.state.cities.map((elem, index) => (
                <li key={index}>
                  <div
                    className="selectCityWrapper"
                    style={this.searchStyle(index)}
                    onMouseDown={this.selectedCity.bind(this, elem, elem.id)}
                    onClick={this.searchListHover.bind(this, index)}
                    onMouseMove={this.searchMouseEventMouseOver.bind(
                      this,
                      index
                    )}
                  >
                    <div className="mainNameWrapper">
                      <b
                        className="cityMainName"
                        style={this.highlight(elem.highlight)}
                      >
                        {this.notEmptyName(elem.name)}
                      </b>
                      {this.notEmptyFeature(elem.feature)}
                      <img
                        className="cityMainImg"
                        src={`/static/flags/flags-iso/flat/64/${elem.country_code}.png`}
                        alt="citydateandtime"
                      />
                    </div>
                    {this.notEmptyState(elem.state)}
                    {this.notEmptyState(elem.county)}
                    {this.notEmptyName(elem.country_name)}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {homePageAdvancedSearch ? (
            <AdvancedSearch />
          ) : (
            <Select
              autosize={true}
              className={this.searchClassName()}
              // styles={this.searchArea()}
              options={this.state.options}
              clearable={false}
              name="selected-state"
              disabled={false}
              value={this.state.selectOption}
              onChange={this.searchOption}
              searchable={false}
              placeholder={this.state.searchPlaceholder}
              // placeholder="Search options"
            />
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    sunCalcReducer: state.sunCalcReducer,
    sunRiseTableListReducer: state.sunRiseTableListReducer,
    moonCalcReducer: state.moonCalcReducer,
    moonRiseTableListReducer: state.moonRiseTableListReducer,
    timeZoneConverterReducer: state.timeZoneConverterReducer,
    planetaryHoursReducer: state.planetaryHoursReducer,
    eclipsesDataReducer: state.eclipsesDataReducer,
    planetesInSignsReducer: state.planetesInSignsReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    stockSearchCity: bindActionCreators(stockSearchCity, dispatch),
    calendarDateTableSun: bindActionCreators(calendarDateTableSun, dispatch),
    sunCalc: bindActionCreators(sunCalc, dispatch),
    sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
    sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch),
    sunRiseTableList: bindActionCreators(sunRiseTableList, dispatch),
    sunRiseDate: bindActionCreators(sunRiseDate, dispatch),
    sunLoaderShow: bindActionCreators(sunLoaderShow, dispatch),
    calendarDateTableMoon: bindActionCreators(calendarDateTableMoon, dispatch),
    moonCalc: bindActionCreators(moonCalc, dispatch),
    moonRisePosition: bindActionCreators(moonRisePosition, dispatch),
    moonRiseCoords: bindActionCreators(moonRiseCoords, dispatch),
    moonRiseTableList: bindActionCreators(moonRiseTableList, dispatch),
    moonRiseDate: bindActionCreators(moonRiseDate, dispatch),
    moonLoaderShow: bindActionCreators(moonLoaderShow, dispatch),
    eclipsesData: bindActionCreators(eclipsesData, dispatch),
    eclipsesDataTable: bindActionCreators(eclipsesDataTable, dispatch),
    weatherInfo: bindActionCreators(weatherInfo, dispatch),
    weatherInfoOpen: bindActionCreators(weatherInfoOpen, dispatch),
    airportsList: bindActionCreators(airportsList, dispatch),
    holidaysBlock: bindActionCreators(holidaysBlock, dispatch),
    timeZoneConverter: bindActionCreators(timeZoneConverter, dispatch),
    timeZoneConverterTime: bindActionCreators(timeZoneConverterTime, dispatch),
    newYearCountdown: bindActionCreators(newYearCountdown, dispatch),
    planetaryHours: bindActionCreators(planetaryHours, dispatch),
    planetaryHoursTable: bindActionCreators(planetaryHoursTable, dispatch),
    nextPlanetRetrograde: bindActionCreators(nextPlanetRetrograde, dispatch),
    planetesRetrograde: bindActionCreators(planetesRetrograde, dispatch),
    planetesInSigns: bindActionCreators(planetesInSigns, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
