// const initCity = {
//   id: 5128581,
//   url: 'place/USA/NY/New_York_City'
// };
const currentSearchReducer = (state = false, action) => {
  switch (action.type) {
    case 'ADD_SEARCH_CITY':
      // if(!action.data.id || action.data.length <= 0 || action.data === {} || action.data === '{}') return state;
      return action.data;
    default:
      return state;
  }
};
  
export default currentSearchReducer;