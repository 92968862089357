const holidaysBlock = {
  holidays_data: true,
  holidays_list: []
};
const holidaysBlockReducer = (state = holidaysBlock, action) => {
  switch (action.type) {
    case 'HOLIDAYSBLOCK':
      return action.data;
    default:
      return state;
  }
};
  
export default holidaysBlockReducer;