import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import Autocomplete from "react-autocomplete";
import { validate } from "./validate";
import Api from "../../../../services/api";

//---------------------------------
//https://alligator.io/react/react-autocomplete/
//https://appdividend.com/2018/08/23/react-autocomplete-example-tutorial/
//---------------------------------

/*
getCountryCode();
const CountryCode = localStorage.getItem('CountryInfo') ? JSON.parse(localStorage.getItem('CountryInfo')) : [];
let sel_auto = [];
let tmp = '';
CountryCode.forEach(elem => {
    tmp = elem.country_phone_codes.replace(/[^0-9]/g, '');
    if (sel_auto[tmp]) {
        sel_auto[tmp].name = sel_auto[tmp].name + " / " + elem.country_name;
    } else {
        sel_auto[tmp] = {
            abbr: '+ ' + elem.country_phone_codes,
            name: "(+" + elem.country_phone_codes.replace(/[^0-9-]/g, '') + ") - " + elem.country_name
        };
    }
});
*/

class SignUpFormValidation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//countryCode: '',
			arrayaphoneCode: [{ abbr: "+", name: "Error Country Code" }]
		};
		//this.countryChange = this.countryChange.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
	}

	UNSAFE_componentWillMount() {
		//console.log('UNSAFE_componentWillMount');
		// eslint-disable-next-line no-async-promise-executor
		var promise = new Promise(async (resolve, reject) => {
			let CountryInfo = await Api.getCache(
				Api.simpleRequest,
				`get-country-info/`
			);
			if (CountryInfo.data !== undefined) resolve(CountryInfo.data);
			else reject();
		});

		promise.then(
			result => {
				// console.log("REG_componentWillMount_CountryCode");
				let sel_auto = [];
				let tmp = "";
				result.forEach(elem => {
					tmp = elem.country_phone_codes.replace(/[^0-9]/g, "");
					if (sel_auto[tmp]) {
						sel_auto[tmp].name =
							sel_auto[tmp].name + " / " + elem.country_name;
					} else {
						sel_auto[tmp] = {
							abbr: "+ " + elem.country_phone_codes,
							name:
								"(+" +
								elem.country_phone_codes.replace(
									/[^0-9-]/g,
									""
								) +
								") - " +
								elem.country_name
						};
					}
				});
				this.setState({ arrayaphoneCode: sel_auto });
			},
			function(error) {
				//this.setState({arrayaphoneCode: []});
			}
		);
	}

	renderTextField = ({
		input,
		label,
		floatingLabel,
		meta: { touched, error },
		...custom
	}) => (
		<TextField
			hintText={floatingLabel || label}
			floatingLabelText={label}
			errorText={touched && error}
			{...input}
			{...custom}
		/>
	);

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<Field
					className="authorisation-form-input"
					name="firstName"
					component={this.renderTextField}
					label="First Name*"
					floatingLabel="First Name"
				/>
				<Field
					className="authorisation-form-input"
					name="lastName"
					component={this.renderTextField}
					label="Last Name*"
					floatingLabel="Last Name"
				/>
				<Field
					className="authorisation-form-input"
					name="email"
					component={this.renderTextField}
					label="Email*"
					floatingLabel="Email"
				/>
				<div
					className="authorisation-form-input authorisation-form-input__inline"
					style={{ display: "inline-flex*" }}
				>
					<div
						style={{
							width: "30%",
							marginBottom: 8
						}}
					>
						<Autocomplete
							value={this.state.value}
							inputProps={{
								id: "countryCode",
								name: "countryCode",
								placeholder: "Country Code"
							}}
							wrapperStyle={{
								position: "relative",
								display: "inline-block"
							}}
							items={this.state.arrayaphoneCode}
							//getItemValue={item => item.name}
							getItemValue={item => item.abbr}
							// shouldItemRender={matchCountryCode}
							shouldItemRender={(state, value) => {
								return (
									state.name
										.toLowerCase()
										.indexOf(value.toLowerCase()) !== -1 ||
									state.abbr
										.toLowerCase()
										.indexOf(value.toLowerCase()) !== -1
								);
							}}
							onChange={(event, value) => {
								this.setState({ value });
								let res = value.replace(/[^0-9]/g, "");
								this.props.dispatch(
									this.props.change("countryCode", res)
								);
							}}
							onSelect={value => {
								this.setState({ value });
								let res = value.replace(/[^0-9]/g, "");
								this.props.dispatch(
									this.props.change("countryCode", res)
								);
							}}
							renderMenu={children => (
								<div className="menu">{children}</div>
							)}
							renderItem={(item, isHighlighted) => (
								<div
									className={`item ${
										isHighlighted ? "item-highlighted" : ""
									}`}
									key={item.abbr}
								>
									{item.name}
								</div>
							)}
						/>
					</div>
					<Field
						className="authorisation-form-input-padding"
						name="phone"
						component={this.renderTextField}
						label="Mobile No."
					/>
				</div>
				<Field
					className="authorisation-form-input"
					name="password1"
					type="password"
					component={this.renderTextField}
					label="Password*"
					floatingLabel="Password"
				/>
				<Field
					className="authorisation-form-input"
					name="password2"
					type="password"
					component={this.renderTextField}
					label="Re-enter Password*"
					floatingLabel="Re-enter Password"
				/>
				<div className="authorization-button">
					<button type="submit" disabled={pristine || submitting}>
						Create Account
					</button>
				</div>
			</form>
		);
	}
}

export default reduxForm({ form: "SignUpForm", validate })(
	SignUpFormValidation
);
