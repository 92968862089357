import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { StickyTable, Row, Cell } from "react-sticky-table";
import "react-sticky-table/dist/react-sticky-table.css";
import MetaTags from "react-meta-tags";

import "./PlanetesInSigns.css";
import Loader from "../../../../components/Main/Loader/Loader";
import Search from "../../MainPage/Search/Search";
import CityName from "./CityName/CityName";
import PlanetesInSignsData from "./PlanetesInSignsData/PlanetesInSignsData";
import PlanetesInSignsRightBlock from "./PlanetesInSignsRightBlock/PlanetesInSignsRightBlock";
import PoveredBy from "../../../../components/Main/MainPage/InformationBlocks/CityInfoMapBlocks/PlanetInfo/PoveredBy/PoveredBy";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import planetesInSigns from "../../../../actions/action_planetesInSigns";

import Api from "../../../../services/api.js";
import { createMarkup, TableFooter } from "../../../shared/Common";

class PlanetesInSigns extends Component {
  state = {
    currentPlanet: "",
  };
  planetZodiacSign = [
    ["Aries", "Mars"],
    ["Taurus", "Venus"],
    ["Gemini", "Mercury"],
    ["Cancer", "Moon"],
    ["Leo", "Sun"],
    ["Virgo", "Mercury"],
    ["Libra", "Venus"],
    ["Scorpio", "Mars and Pluto"],
    ["Sagittarius", "Jupiter"],
    ["Capricorn", "Saturn"],
    ["Aquarius", "Saturn and Uranus"],
    ["Pisces", "Jupiter	and Neptune"],
  ];
  planetChangesSigns = [
    ["Sun", "every month"],
    ["Moon", "every 2-3 days"],
    ["Mercury", "every 3-4 weeks"],
    ["Venus", "every 4-5 weeks"],
    ["Mars", "every 6-7 weeks"],
    ["Jupiter", "every 12-13 months"],
    ["Saturn", "every 2-3 years"],
    ["Uranus", "every 7 years"],
    ["Neptune", "every 10-12 years"],
    ["Pluto", "every 12-15 years (can vary)"],
  ];
  monthList = [
    "Next Quarter",
    "1st quarter",
    "2nd quarter",
    "3rd quarter",
    "4th quarter",
  ];

  UNSAFE_componentWillMount() {
    // UNSAFE_componentWillMount only for this.parseUrl
    this.parseUrl();
  }
  componentDidMount() {
    this.pareReset();
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) this.pareReset();
  }
  componentDidUpdate(prevProps) {
    const planetFromUrl = window.location.pathname.split("/")[1].split("-")[0];
    if (this.state.currentPlanet !== planetFromUrl) {
      this.parseUrl();
    }
    // if (
    //   prevProps.planetesInSignsReducer !== this.props.planetesInSignsReducer
    // ) {
    //   this.opgPicture();
    // }
  }
  parseUrl = () => {
    const parsedUrl = window.location.pathname.split("/");
    const parsedPlanet = this.capitalizeString(parsedUrl[1].split("-")[0]);
    this.setState({ currentPlanet: parsedPlanet });
  };
  capitalizeString = (str) => str[0].toUpperCase() + str.slice(1);

  // async opgPicture() {
  //   const { planetesInSignsReducer, currentSearchReducer } = this.props;
  //   if (planetesInSignsReducer.planet) {
  //     const planet = planetesInSignsReducer.planet;
  //     const newId = currentSearchReducer.url
  //       .replace("place/", "")
  //       .replace(/\//g, "__");
  //     const opgPicture = await Api.userCityList(
  //       `opg/planet-in-signs/get-url/?geo_name=${newId}&planet=${planet}`
  //     );
  //     this.setState({ opgPicture: opgPicture.data.url });
  //   }
  // }
  async pareReset() {
    const newId = window.location.pathname.split("/").slice(2).join("__");
    const {
      currentSearch,
      planetesInSigns,
      planetesInSignsReducer,
    } = this.props;

    const data = await Api.userCityList(`geo_names/${newId}/`);
    currentSearch(data.data);

    const planet = window.location.pathname.split("/")[1].split("-")[0];
    const periodList = this.periodCalc(planet);
    const start = periodList[1].split(" - ")[0];
    const end = periodList[1].split(" - ")[1];

    const tz = "Greenwich";
    let planetesInSignsRequest;
    const startDate = moment().tz(tz).format();
    if (planet === "Moon") {
      const endDate = moment().tz(tz).add(3, "months").format();
      planetesInSignsRequest = await Api.userCityList(
        `planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`
      );
    } else {
      const endDate = moment()
        .tz(tz)
        .add(this.periodVal(planet), "years")
        .format();
      planetesInSignsRequest = await Api.userCityList(
        `planetes_in_signs/?p=${planet}&detail=true&date_start=${startDate}&date_end=${endDate}&include_current=true`
      );
    }
    planetesInSigns({
      planet: planet,
      start: start,
      month: planetesInSignsReducer.month,
      end: end,
      period: periodList[0],
      periodName: periodList[0],
      periodList: periodList,
      year: moment().tz(tz).format("YYYY"),
      planetesInSignsList: planetesInSignsRequest.data.data,
      current_sign: planetesInSignsRequest.data.current_sign,
      next_sign: planetesInSignsRequest.data.next_sign,
      individualText: planetesInSignsRequest.data.individual_text,
    });
  }
  periodCalc(planet) {
    const tz = "Greenwich";
    const yearListCreation = (years) => {
      const yearList = [];
      yearList.push(`Next ${years} years`);
      for (let i = 1917; i < 2089; i += years) {
        yearList.push(
          `${moment().tz(tz).set("year", i).format()} - ${moment()
            .tz(tz)
            .set("year", i + years)
            .format()}`
        );
      }
      return yearList;
    };
    return yearListCreation(this.periodVal(planet));
  }
  periodVal(planet) {
    if (planet === "Sun") return 1;
    else if (planet === "Moon") return 10;
    else if (planet === "Mercury" || planet === "Venus" || planet === "Mars")
      return 2;
    else if (planet === "Jupiter") return 5;
    else if (planet === "Saturn" || planet === "Uranus") return 10;
    else if (planet === "Neptune" || planet === "Pluto" || planet === "Chiron")
      return 20;
  }

  getCityName() {
    const { currentSearchReducer } = this.props;
    const name =
      currentSearchReducer.name !== null ? `${currentSearchReducer.name}` : "";
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    return `${name}, ${state} ${country}`;
  }

  cityName() {
    const { planetesInSignsReducer } = this.props;
    return `${
      planetesInSignsReducer.planet
    } in Zodiac Signs for ${this.getCityName()}`;
  }
  geotimedateTable() {
    const { planetesInSignsReducer } = this.props;
    if (document.body.offsetWidth > 1199) {
      return planetesInSignsReducer.planetesInSignsList.length * 35 + 47 + 48;
    } else
      return document.body.offsetHeight < 1260
        ? document.body.offsetHeight
        : 1260;
  }
  tableDateStyle(elem, arr) {
    // const retroDate = this.nextRetrograde(arr);
    // const currentDate = this.currentRetrograde(arr);
    // if (currentDate) {
    //     return elem.dt === currentDate.dt ? {background: '#e9e9e9'} : {};
    // } else if (retroDate) {
    //     return elem.dt === retroDate.dt ? {background: '#e9e9e9'} : {};
    // }
    return {};
  }
  retrogradeZodiac(sign, angle, motion) {
    const newDegree = (angle + "").split(".");
    const deg = newDegree[0] + String.fromCharCode(176);
    const min = newDegree[1]
      ? this.transformMinute(newDegree[1]).toFixed(0)
      : "00";
    if (motion === "Retro")
      return `${30 + String.fromCharCode(176)} ${sign} 00'`;
    return `${deg} ${sign} ${min}'`;
  }
  periodShow(elem) {
    if (elem.split(" ")[0] === "Next") return elem;
    else {
      const tz = "Greenwich";
      const period = elem.split(" - ");
      const start = moment(period[0]).tz(tz).format("YYYY");
      const end = moment(period[1]).tz(tz).format("YYYY");
      return `${start} - ${end}`;
    }
  }
  yearsList() {
    const { planetesInSignsReducer } = this.props;
    const yearsArr = [];
    const tz = "Greenwich";
    const periodArr = planetesInSignsReducer.period.split(" ");
    if (periodArr[0] === "Next") {
      const start = +moment().tz(tz).format("YYYY");
      for (let i = start; i < start + +periodArr[1]; i++) {
        yearsArr.push([i] + "");
      }
    } else {
      const start = +moment(planetesInSignsReducer.period.split(" - ")[0])
        .tz(tz)
        .format("YYYY");
      const end = +moment(planetesInSignsReducer.period.split(" - ")[1])
        .tz(tz)
        .format("YYYY");
      for (let i = start; i < end; i++) {
        yearsArr.push([i] + "");
      }
    }
    return yearsArr;
  }
  periodChange = (event, index, value) => {
    const { planetesInSigns, planetesInSignsReducer } = this.props;
    const tz = "Greenwich";
    const year =
      value.split(" ")[0] === "Next"
        ? moment().tz(tz).format("YYYY")
        : moment(value.split(" - ")[0]).tz("Greenwich").format("YYYY");
    planetesInSigns({
      planet: planetesInSignsReducer.planet,
      start: planetesInSignsReducer.start,
      month: planetesInSignsReducer.month,
      end: planetesInSignsReducer.end,
      period: value,
      periodName: planetesInSignsReducer.periodName,
      periodList: planetesInSignsReducer.periodList,
      year: year,
      planetesInSignsList: planetesInSignsReducer.planetesInSignsList,
      current_sign: planetesInSignsReducer.current_sign,
      next_sign: planetesInSignsReducer.next_sign,
    });
  };
  yearChange = (event, index, value) => {
    const { planetesInSigns, planetesInSignsReducer } = this.props;
    planetesInSigns({
      planet: planetesInSignsReducer.planet,
      start: planetesInSignsReducer.start,
      month: planetesInSignsReducer.month,
      end: planetesInSignsReducer.end,
      period: planetesInSignsReducer.period,
      periodName: planetesInSignsReducer.periodName,
      periodList: planetesInSignsReducer.periodList,
      year: value,
      planetesInSignsList: planetesInSignsReducer.planetesInSignsList,
      current_sign: planetesInSignsReducer.current_sign,
      next_sign: planetesInSignsReducer.next_sign,
    });
  };
  monthChange = (event, index, value) => {
    const { planetesInSigns, planetesInSignsReducer } = this.props;
    planetesInSigns({
      planet: planetesInSignsReducer.planet,
      start: planetesInSignsReducer.start,
      month: value,
      end: planetesInSignsReducer.end,
      period: planetesInSignsReducer.period,
      periodName: planetesInSignsReducer.periodName,
      periodList: planetesInSignsReducer.periodList,
      year: planetesInSignsReducer.year,
      planetesInSignsList: planetesInSignsReducer.planetesInSignsList,
      current_sign: planetesInSignsReducer.current_sign,
      next_sign: planetesInSignsReducer.next_sign,
    });
  };
  async planetesInSignsGet() {
    const { planetesInSigns, planetesInSignsReducer } = this.props;
    const tz = "Greenwich";
    let planetesInSignsRequest = [];
    let start, end;
    const year = planetesInSignsReducer.year;
    const month = planetesInSignsReducer.month;
    const period = this.periodVal(planetesInSignsReducer.planet);
    let startNow = false;
    if (planetesInSignsReducer.planet === "Moon") {
      if (month === "Next Quarter") {
        start = moment().tz(tz).year(year).format();
        end = moment().tz(tz).year(year).add(3, "month").format();
        startNow = true;
      } else if (month === "1st quarter") {
        start = moment().tz(tz).year(year).month(0).date(1).format();
        end = moment().tz(tz).year(year).month(2).date(31).format();
      } else if (month === "2nd quarter") {
        start = moment().tz(tz).year(year).month(3).date(1).format();
        end = moment().tz(tz).year(year).month(5).date(30).format();
      } else if (month === "3rd quarter") {
        start = moment().tz(tz).year(year).month(6).date(1).format();
        end = moment().tz(tz).year(year).month(8).date(30).format();
      } else if (month === "4th quarter") {
        start = moment().tz(tz).year(year).month(9).date(1).format();
        end = moment().tz(tz).year(year).month(11).date(31).format();
      }
    } else {
      if (planetesInSignsReducer.period.split(" ")[0] === "Next") {
        start = moment()
          .tz(tz)
          .year(+year)
          .format();
        end = moment()
          .tz(tz)
          .year(+year + period)
          .format();
        startNow = true;
      } else {
        start = moment(planetesInSignsReducer.period.split(" - ")[0])
          .tz(tz)
          .month(0)
          .date(1)
          .format();
        end = moment(planetesInSignsReducer.period.split(" - ")[1])
          .tz(tz)
          .month(11)
          .date(31)
          .format();
      }
    }
    const includeCurrent = startNow === true ? "&include_current=true" : "";
    planetesInSignsRequest = await Api.userCityList(
      `planetes_in_signs/?p=${planetesInSignsReducer.planet}&detail=true&date_start=${start}&date_end=${end}${includeCurrent}`
    );
    // this.setState({
    //     planetesInSignsList: planetesInSigns.data,
    //     periodName: planetesInSignsReducer.period
    // });
    planetesInSigns({
      planet: planetesInSignsReducer.planet,
      start: planetesInSignsReducer.start,
      month: planetesInSignsReducer.month,
      end: planetesInSignsReducer.end,
      period: planetesInSignsReducer.period,
      periodName: planetesInSignsReducer.period,
      periodList: planetesInSignsReducer.periodList,
      year: planetesInSignsReducer.year,
      planetesInSignsList: planetesInSignsRequest.data.data,
      current_sign: planetesInSignsRequest.data.current_sign,
      next_sign: planetesInSignsRequest.data.next_sign,
      individualText: planetesInSignsRequest.data.individual_text,
    });
  }
  planetaryStyle() {
    const { planetesInSignsReducer } = this.props;
    switch (planetesInSignsReducer.planet) {
      case "Mercury":
        return { background: "#800103", color: "#fff" };
      case "Venus":
        return { background: "#009d28", color: "#fff" };
      case "Mars":
        return { background: "#ff040f", color: "#fff" };
      case "Jupiter":
        return { background: "#ff7421", color: "#fff" };
      case "Saturn":
        return { background: "#a6a6a6", color: "#fff" };
      case "Uranus":
        return { background: "#0078f9", color: "#fff" };
      case "Neptune":
        return { background: "#800080", color: "#fff" };
      case "Pluto":
        return { background: "#618ab5", color: "#fff" };
      case "Chiron":
        return { background: "#d635c9", color: "#fff" };
      case "Sun":
        return { background: "#caca22", color: "#fff" };
      case "Moon":
        return { background: "#000", color: "#fff" };
      default:
        return { background: "#fff", color: "#000" };
    }
  }
  metaCityName(nameType) {
    const { currentSearchReducer } = this.props;

    if (window.location.pathname.includes("-in-signs")) {
      const parsedUrl = window.location.pathname.split("/");
      const normalUrlsParamsAmount = 5; // empty str, page type, city,state,country, location id

      let urlCity = "";
      let parsedUrlCity = "";
      let urlCountry = "";
      let parsedUrlCountry = "";

      if (parsedUrl.length < normalUrlsParamsAmount) {
        urlCountry = window.location.pathname.split("/")[2];
        parsedUrlCountry =
          urlCountry.charAt(0).toUpperCase() +
          urlCountry.slice(1).replace(/-/g, " ");
      } else {
        urlCity = window.location.pathname.split("/")[4];
        parsedUrlCity =
          urlCity.charAt(0).toUpperCase() + urlCity.slice(1).replace(/-/g, " ");
        urlCountry = window.location.pathname.split("/")[2];
        parsedUrlCountry =
          urlCountry.charAt(0).toUpperCase() +
          urlCountry.slice(1).replace(/-/g, " ");
      }

      const name = currentSearchReducer.name
        ? currentSearchReducer.name
        : parsedUrlCity;
      const country = currentSearchReducer.country_name
        ? currentSearchReducer.country_name === "United States"
          ? currentSearchReducer.country_name_abbr
          : currentSearchReducer.country_name
        : parsedUrlCountry === "usa"
        ? "USA"
        : parsedUrlCountry;

      switch (nameType) {
        case "title":
          return name ? `${name}, ${country} ` : `${country}`;
        case "description":
          return name ? `${name}, ${country}` : `${country}`;
        case "keywords":
          return name ? `${name}, ${country}, ` : `${country}, `;
        default:
          return "";
      }
    }
    return "";
  }
  meta = () => {
    const { planetesInSignsReducer } = this.props;
    const planetName = planetesInSignsReducer.planet
      ? planetesInSignsReducer.planet
      : window.location.pathname
          .split("/")[1]
          .substr(0, window.location.pathname.split("/")[1].indexOf("-"));

    return (
      <MetaTags>
        <title>
          {planetName} in Zodiac Signs for {this.metaCityName("title")} from
          1900-2099. Find start date, time and motion type.
        </title>
        {/*<meta*/}
        {/*  name="og:title"*/}
        {/*  content={`${planetName} in Zodiac Signs for ${this.metaCityName(*/}
        {/*    "title"*/}
        {/*  )} from 1900-2099. Find start date, time and motion type.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="og:description"*/}
        {/*  content={`Planets in all Zodiac signs for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. ${planetName} in Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content={`Planets in all Zodiac signs for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. ${planetName} in Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content={`Planets in signs, ${planetName} in Zodiac Signs for ${this.metaCityName(*/}
        {/*    "keywords"*/}
        {/*  )}Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, Pisces, Zodiac signs`}*/}
        {/*/>*/}
        {/*{this.state.opgPicture ? (*/}
        {/*  <meta*/}
        {/*    property="og:image"*/}
        {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
        {/*  />*/}
        {/*) : null}*/}
      </MetaTags>
    );
  };
  render() {
    const { currentPlanet } = this.state;
    const { currentSearchReducer, planetesInSignsReducer } = this.props;
    if (
      planetesInSignsReducer.planet === "" ||
      !planetesInSignsReducer.planetesInSignsList.length
    ) {
      return (
        <div className="Retrograde">
          {this.meta()}
          <Loader />
        </div>
      );
    }
    return (
      <div className="Retrograde">
        {this.meta()}
        <div className="serarch-block">
          <b>
            Find out when Sun, Moon, Mercury, Venus, Mars, Jupiter, Saturn,{" "}
            <br />
            Uranus, Neptune, Pluto and Chiron are in different Zodiac Signs in
          </b>
          <Search />
        </div>
        <CityName planetaryStyle={this.planetaryStyle()} />
        <div className="retrograde-table">
          <PlanetesInSignsData>
            <div className="table-data-period">
              <div className="rowRetrogradetitleTable ">
                <p className="titleTable_PlanetInSigns">{this.cityName()}</p>
                <div className="select-change">
                  <MuiThemeProvider>
                    <SelectField
                      className="year-select period"
                      value={planetesInSignsReducer.period}
                      onChange={this.periodChange}
                      maxHeight={200}
                    >
                      {planetesInSignsReducer.periodList.map((elem, index) => (
                        <MenuItem
                          value={elem}
                          key={index}
                          primaryText={this.periodShow(elem)}
                        />
                      ))}
                    </SelectField>
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <SelectField
                      style={
                        planetesInSignsReducer.planet === "Moon"
                          ? {}
                          : { display: "none" }
                      }
                      className="year-select year"
                      value={planetesInSignsReducer.year}
                      onChange={this.yearChange}
                      maxHeight={
                        planetesInSignsReducer.planet === "Moon" ? 300 : 200
                      }
                    >
                      {this.yearsList().map((elem, index) => (
                        <MenuItem value={elem} key={index} primaryText={elem} />
                      ))}
                    </SelectField>
                  </MuiThemeProvider>
                  <MuiThemeProvider>
                    <SelectField
                      style={
                        planetesInSignsReducer.planet === "Moon"
                          ? {}
                          : { display: "none" }
                      }
                      className="year-select"
                      value={planetesInSignsReducer.month}
                      onChange={this.monthChange}
                      maxHeight={
                        planetesInSignsReducer.planet === "Moon" ? 300 : 200
                      }
                    >
                      {this.monthList.map((elem, index) => (
                        <MenuItem value={elem} key={index} primaryText={elem} />
                      ))}
                    </SelectField>
                  </MuiThemeProvider>
                  <button
                    className="month-chnge-request"
                    onClick={this.planetesInSignsGet.bind(this)}
                  >
                    Go
                  </button>
                </div>
              </div>
              <div
                className="geotimedate-table"
                style={{
                  width: "100%",
                  // height: this.geotimedateTable() + 'px'
                }}
              >
                <StickyTable stickyColumnCount={0} stickyHeaderCount={1}>
                  <Row>
                    <Cell>Date start</Cell>
                    <Cell>Zodiac sign</Cell>
                    <Cell>Motion type</Cell>
                  </Row>
                  <Row>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                  </Row>
                  {planetesInSignsReducer.planetesInSignsList.map(
                    (elem, index, arr) => (
                      <Row key={index} style={this.tableDateStyle(elem, arr)}>
                        <Cell>
                          <p>
                            {moment(elem.dt)
                              .tz(currentSearchReducer.timezone)
                              .format("lll")}
                          </p>
                        </Cell>
                        {/* <Cell><p>{moment(elem.dt).tz(currentSearchReducer.timezone).format(`MMM DD, YYYY LT`)} </p></Cell> */}
                        <Cell>
                          {this.retrogradeZodiac(
                            elem.sign.name,
                            elem.angle,
                            elem.motion_type
                          )}
                        </Cell>
                        <Cell>{elem.motion_type}</Cell>
                      </Row>
                    )
                  )}
                </StickyTable>
              </div>
              <TableFooter
                url={currentSearchReducer.url}
                cityName={this.getCityName()}
              />
            </div>
            <br />
            <br />
            <div
              style={{
                width: "100%",
                outline: "2px solid #d1d1d1",
                // margin: "auto",
                padding: "15px",
                fontSize: "17px",
                lineHeight: "20px",
              }}
              dangerouslySetInnerHTML={createMarkup(
                planetesInSignsReducer.individualText
              )}
            />

            <div
              className="text-block-fish"
              style={{
                paddingTop: "30px",
              }}
            >
              <h4>
                <b>{currentPlanet} in Zodiac Signs</b>
              </h4>
              <h4>What does ‘{currentPlanet} in Zodiac Signs’ mean?</h4>
              <p>
                The sky is divided into twelve equal parts with each area
                representing the 12 signs of the zodiac. The position of
                different planets as they orbit around the Sun is visible in the
                sky from Earth. {currentPlanet} in Zodiac sign represents where{" "}
                {currentPlanet} is currently in which part of the sky or in
                which Zodiac sign. Astrology tries to use the movements of the
                planets and their position in Zodiac signs to understand their
                influence on our lives and personalities.
              </p>
              <h4>Which Zodiac sign(s) {currentPlanet} rules?</h4>
              <p>Every Zodiac Sign has a Ruling Planet. These are:</p>
              <table className="text-block-table">
                <tr>
                  <th>Zodiac Sign</th>
                  <th>Ruling Planet</th>
                </tr>
                {this.planetZodiacSign.map((elem, index) => (
                  <tr key={index}>
                    <td>{elem[0]}</td>
                    <td>{elem[1]}</td>
                  </tr>
                ))}
              </table>
              <h4>
                How often does {currentPlanet} change in the Zodiac signs?
              </h4>
              <p>
                The planets in our Solar system orbit the Sun at different
                speeds. Each take different number of days to complete one
                revolution around the Sun based on their speed and distance from
                the Sun. The planets close to Sun like Mercury, Venus and Mars
                move quickly across the Zodiac and change zodiac signs in a few
                days to weeks. The planets beyond Mars like — Jupiter, Saturn,
                Neptune, Uranus, and Pluto move slowly, changing signs from a
                year to fifteen years. The Sun, Moon and Chiron are also
                included in astrology for their place in the Zodiac signs.
              </p>
              <table className="text-block-table">
                <tr>
                  <th>PLANET</th>
                  <th>CHANGES SIGNS</th>
                </tr>
                {this.planetChangesSigns.map((elem, index) => (
                  <tr key={index}>
                    <td>{elem[0]}</td>
                    <td>{elem[1]}</td>
                  </tr>
                ))}
              </table>
              <p>
                To read more about the zodiac –
                <a href="https://en.wikipedia.org/wiki/Zodiac">
                  https://en.wikipedia.org/wiki/Zodiac
                </a>
              </p>
            </div>
          </PlanetesInSignsData>
          <PlanetesInSignsRightBlock planetaryStyle={this.planetaryStyle()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    planetesInSignsReducer: state.planetesInSignsReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    planetesInSigns: bindActionCreators(planetesInSigns, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanetesInSigns);
