import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";

import "./HolidaysBlock.css";
import Loader from "../../../../../../components/Main/Loader/Loader";
import holidaysBlock from "../../../../../../actions/action_holidaysBlock";

import Api from "../../../../../../services/api.js";

class HolidaysBlock extends Component {
  async componentDidMount() {
    const { currentSearchReducer, holidaysBlock } = this.props;
    // const year = moment().tz(currentSearchReducer.timezone).format('YYYY');
    const holidays = await Api.userCityList(
      `holidays/?country=${currentSearchReducer.country_code}`
    );
    // const holidays = await Api.userCityList(`/holidays/?country=UA&category=Holidays in Ukraine&quantity=6&year=2019&date=2019-01-22`);
    holidaysBlock(holidays.data);
    this.setState({
      holidaysYear: holidays.data,
      name: currentSearchReducer.name,
    });
  }
  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state = currentSearchReducer.state
      ? currentSearchReducer.state
        ? `${currentSearchReducer.state},`
        : ""
      : ``;
    return `${name}, ${state} ${country}`;
  }
  render() {
    const { holidaysBlockReducer, currentSearchReducer } = this.props;
    if (holidaysBlockReducer.holidays_data === false) {
      return (
        <div className="world-clock-links">
          <h4>Upсoming Holidays in {this.cityName()}</h4>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <div className="currentCityInfo"> Sorry, we don't have data yet.</div>
        </div>
      );
    } else if (!holidaysBlockReducer.holidays_list.length) {
      return (
        <div className="world-clock-links">
          <Loader />
        </div>
      );
    }
    const url = currentSearchReducer.url.split("/").slice(1).join("/");
    return (
      <div className="world-clock-links">
        <h4>Upсoming Holidays in {this.cityName()}</h4>
        <div className="currentCityInfo">
          {holidaysBlockReducer.holidays_list.map((elem, index) => {
            if (index < 6) {
              return (
                <div className="hover_effect" key={index}>
                  <b>
                    {moment(elem.start_date).tz(elem.time_zone).format("ll")} -{" "}
                    {elem.title}
                  </b>
                </div>
              );
            } else return "";
          })}
          <div className="hover_effect">
            <Link to={`/holidays/${url}`}>
              <b>More Holidays in {this.cityName()}</b>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    holidaysBlockReducer: state.holidaysBlockReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    holidaysBlock: bindActionCreators(holidaysBlock, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysBlock);
