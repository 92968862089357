import React, { Component } from "react";
import MetaTags from "react-meta-tags";

import Calculator from "./Calculator/Calculator";
import DescriptionText from "./DescriptionText";

import Api from "../../../../services/api.js";
import "./JulianConverter.css";

class UnixConverter extends Component {
  state = {
    // opgPicture: null
  };

  // componentDidMount() {
  //   this.opgPicture();
  // }

  // async opgPicture() {
  //   const opgPicture = await Api.userCityList(`opg/julian/get-url/`);
  //   this.setState({ opgPicture: opgPicture.data.url });
  // }

  render() {
    return (
      <div className="FlightTimeCalculator">
        <MetaTags>
          <title>Julian Timestamp Converter – Julian Date Converter</title>
          {/*<meta*/}
          {/*  name="description"*/}
          {/*  content={`Easily convert Julian Date/ Julian Timestamp to human readable date and time. Alternatively, convert time and date to Julian Date/Julian Timestamp.`}*/}
          {/*/>*/}
          {/*<meta*/}
          {/*  name="keywords"*/}
          {/*  content={`Julian date, Julian timestamp converter, Julian date converter, time converter, Julian timestamp, Julian date to human readable date and time, January 1 4713 BC, calculate Julian date`}*/}
          {/*/>*/}
          {/*{this.state.opgPicture ? (*/}
          {/*  <meta*/}
          {/*    property="og:image"*/}
          {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
          {/*  />*/}
          {/*) : null}*/}
        </MetaTags>

        <Calculator />
        <DescriptionText />
      </div>
    );
  }
}

export default UnixConverter;
