import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

function AboutExchange(props) {
	const exchange = props.stockExchangeSing.stock_exchanged[0];
	// await redux store update
	if (!exchange.name) return null;

	const currentCity = props.currentCityInfoReducer;
	const { name, symbol } = exchange;
	const startWork = exchange.schedule[0].sh[0].loc_time_start;
	const firstHalfWorkDay = exchange.schedule[0].sh[0].loc_time_end;
	const endWork =
		exchange.schedule[0].sh.length > 1
			? exchange.schedule[0].sh[1].loc_time_end
			: exchange.schedule[0].sh[0].loc_time_end;

	const parseWeekdays = workDays => {
		const parsedArray = workDays.split(",");

		return parsedArray.map((item, id) => {
			if (item.length > 3) {
				return `${parseWeekday(
					item.substr(0, 3)
				)} through ${parseWeekday(item.substr(-3))}${
					parsedArray.length === 1
						? ` and is closed for official
          ${exchange.country_name} holidays`
						: ""
				}`;
			} else {
				return id > 1
					? parseWeekday(item)
					: ` and ${parseWeekday(item)}`;
			}
		});
	};

	const parseWeekday = day => {
		switch (day) {
			case "MON":
				return "Monday";
			case "TUE":
				return "Tuesday";
			case "WED":
				return "Wednesday";
			case "THU":
				return "Thursday";
			case "FRI":
				return "Friday";
			case "SAT":
				return "Saturday";
			case "SUN":
				return "Sunday";
			default:
				return "";
		}
	};

	return (
		<div className="timePicker-about">
			<p className="timePicker-about_title">
				About {name} ({symbol})
			</p>
			<p className="timePicker-about_question">
				What time does {name} ({symbol}) open and close or what are its
				trading hours?
			</p>
			<p className="timePicker-about_answer">
				The {name} ({symbol}) opens at {moment(startWork).format("LT")}{" "}
				and closes at {moment(endWork).format("LT")} (
				{moment.tz(endWork, exchange.timezone_stock).format("z")}
				).
			</p>
			<p className="timePicker-about_question">
				What are the working days of {name} ({symbol})?
			</p>
			<p className="timePicker-about_answer">
				The {name} ({symbol}) is open{" "}
				{parseWeekdays(exchange.schedule[0].working_days)}.
			</p>
			<p className="timePicker-about_question">
				What time does {name} ({symbol}) close for Lunch Break?
			</p>

			{exchange.schedule[0].lunch_time ? (
				<p className="timePicker-about_answer">
					The {name} ({symbol}) normally closes for lunch between{" "}
					{moment(firstHalfWorkDay).format("LT")} and{" "}
					{moment(exchange.schedule[0].sh[1].loc_time_start).format(
						"LT"
					)}
					.
				</p>
			) : (
				<p className="timePicker-about_answer">
					The {name} ({symbol}) does not close for Lunch Break.
				</p>
			)}

			<p className="timePicker-about_question">
				What are the {name} ({symbol}) operating hours as per{" "}
				{props.selectCity
					? `${props.selectCity.name}, ${props.selectCity.country_name}`
					: `${currentCity.name}, ${currentCity.country_name}`}{" "}
				time?
			</p>
			<p className="timePicker-about_answer">
				The normal operating hours of {name} ({symbol}) as per{" "}
				{props.selectCity
					? `${props.selectCity.name}, ${props.selectCity.country_name}`
					: `${currentCity.name}, ${currentCity.country_name}`}{" "}
				time are from{" "}
				{props.selectCity
					? moment
							.tz(
								moment.tz(startWork, exchange.timezone_stock),
								props.selectCity.timezone
							)
							.format("LT")
					: moment
							.tz(
								moment.tz(startWork, exchange.timezone_stock),
								currentCity.timezone
							)
							.format("LT")}{" "}
				to{" "}
				{props.selectCity
					? moment
							.tz(
								moment.tz(endWork, exchange.timezone_stock),
								props.selectCity.timezone
							)
							.format("LT")
					: moment
							.tz(
								moment.tz(endWork, exchange.timezone_stock),
								currentCity.timezone
							)
							.format("LT")}{" "}
				(
				{moment
					.tz(
						moment.tz(endWork, exchange.timezone_stock),
						currentCity.timezone
					)
					.format("z")}
				).
			</p>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		stockExchangeSing: state.stockExchangeSing,
		currentCityInfoReducer: state.currentCityInfoReducer
	};
};

export default connect(mapStateToProps)(AboutExchange);
