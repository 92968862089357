import React, { Component } from "react";
import { connect } from "react-redux";
import Countdown from "react-countdown-now";
import moment from "moment-timezone/index.js";

import { bindActionCreators } from "redux";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import newYearCountdown from "../../../../actions/action_newYearCountdown";
// import ComponentLocationEvent from './Components/ComponentLocationEvent/ComponentLocationEvent';
import NewYear from "./Components/ComponentDesign/CollectionDesigns/NewYear";

import "./CreatedTimer.css";
import Loader from "../../Loader/Loader";
import Search from "../../MainPage/Search/Search";
import Api from "../../../../services/api";

const Completionist = () => (
  <span className="created-afte_timer-format">
    Do not miss the moment, the event has already begun !
  </span>
);
const LoadTest = () => (
  <span className="created-afte_timer-format">Loading ...</span>
);

class CreatedTimerNewYear extends Component {
  state = {
    dateCityNow: 0,
    differenceSecondsSett: 0,
    nameCityTime: "",
    differenceSeconds: 0,
    nowDateFull: undefined,
    optionsFromDefault: {
      design_name: "",
      date_event: "",
      location_event: 0,
      timer_format: "",
      title: {
        name_event: "",
        style: ""
      }
    }
  };

  async componentDidMount() {
    const {
      currentSearch,
      currentSearchReducer,
      newYearCountdown
    } = this.props;
    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
      newYearCountdown(data.data);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);

      let date = data.data.time;
      // console.log(date);
      let year = +date.split("T")[0].split("-")[0];
      let mounth = +date.split("T")[0].split("-")[1];
      let day = +date.split("T")[0].split("-")[2];
      let hh = +date.split("T")[1].split(":")[0];
      let mm = +date.split("T")[1].split(":")[1];
      let ss = +date
        .split("T")[1]
        .split(":")[2]
        .split(".")[0];
      let date_event_secund = Date.parse(
        new Date(year, mounth, day, hh, mm, ss)
      );

      this.setState(prevState => {
        return {
          nameCityTime: data.data.name,
          dateCityNow: date_event_secund
        };
      });
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
      // localStorage.setItem('searchCityInfo', JSON.stringify(data.data));
    }

    const obj = { title: { name_event: "", style: "" } };
    if (this.props.location.pathname.length > 14) {
      //console.log('did search URL:',  this.props.location.pathname.split('&') );
      obj.design_name = "NewYear";
      obj.date_event = `${+moment().format("YYYY") + 1}-0-1T00:00:00`;
      obj.location_event = 2643743;
      obj.timer_format = "Months / Days / Hours / Minutes / Seconds";
      obj.title.name_event = "New Year Countdown";
      obj.title.style = "Lobster";
      this.setState(prevState => {
        return {
          optionsFromDefault: obj
        };
      });
    }
  }

  async setChangeLocationEvent() {
    const { countdownReducer } = this.props;
    if (countdownReducer.location_event !== "") {
      let data = await Api.userCityList(
        `geo_names/${countdownReducer.location_event}/`
      );

      let date = data.data.time;
      // console.log(date);
      let year = +date.split("T")[0].split("-")[0];
      let mounth = +date.split("T")[0].split("-")[1];
      let day = +date.split("T")[0].split("-")[2];
      let hh = +date.split("T")[1].split(":")[0];
      let mm = +date.split("T")[1].split(":")[1];
      let ss = +date
        .split("T")[1]
        .split(":")[2]
        .split(".")[0];
      let date_event_secund = Date.parse(
        new Date(year, mounth, day, hh, mm, ss)
      );

      this.setState(prevState => {
        return {
          nameCityTime: data.data.name,
          dateCityNow: date_event_secund,
          differenceSecondsSett: 1
          // differenceSeconds: 0
        };
      });
    }

    this.setDeadlineSeconds();
    this.props.clearCountdownRedux(); // закомитеть для продолжения
  }

  getComponentToRender() {
    // const {countdownReducer} = this.props;
    if (this.state.optionsFromDefault.design_name === "NewYear")
      return <NewYear />;
    //if (countdownReducer.design_name === 'Housing') return <Housing />;
    else
      return (
        <h2>
          <Loader />
        </h2>
      );
  }

  setDeadlineSeconds(start) {
    // const {countdownReducer, currentSearchReducer} = this.props;
    // let date = countdownReducer.date_event;
    let date = this.state.optionsFromDefault.date_event;
    if (date !== "") {
      // let dateNowSeconds = Date.parse(new Date());
      let dateNowSeconds =
        this.state.dateCityNow !== 0
          ? this.state.dateCityNow
          : Date.parse(new Date());
      let year = +date.split("T")[0].split("-")[0];
      let mounth = +date.split("T")[0].split("-")[1];
      let day = +date.split("T")[0].split("-")[2];
      let hh = +date.split("T")[1].split(":")[0];
      let mm = +date.split("T")[1].split(":")[1];
      let ss = +date.split("T")[1].split(":")[2];
      let nowDateFull = new Date(year, mounth, day, hh, mm, ss);
      let date_event_secund = Date.parse(
        new Date(year, mounth, day, hh, mm, ss)
      );
      let differenceSeconds = date_event_secund - dateNowSeconds;
      // console.log('setting', date_event_secund);
      // console.log('nows_sec', dateNowSeconds);
      // console.log('diff_sec', differenceSeconds);
      // console.log('new_Date', nowDateFull);
      if (
        this.state.differenceSeconds === 0 ||
        this.state.differenceSecondsSett !== 0
      ) {
        this.setState({
          differenceSeconds,
          nowDateFull,
          differenceSecondsSett: 0
        });
        // console.log('on');
      }
    }
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      if (this.state.dateCityNow === 0) return <LoadTest />;
      return <Completionist />;
    } else {
      let mounths = (days / 30).toFixed(0) > 0 ? (days / 30).toFixed(0) : 0;
      let daysSett = days - mounths * 30;
      if ((daysSett + "")[0] === "-") {
        mounths = ((days + daysSett) / 30).toFixed(0);
        daysSett = 30 + daysSett;
      }
      return (
        <div id="clockdiv">
          <div>
            <span className="days">{mounths}</span>
            <div className="smalltext">Months</div>
          </div>
          <div>
            <span className="days">{daysSett}</span>
            <div className="smalltext">Days</div>
          </div>
          <div>
            <span className="days">{hours}</span>
            <div className="smalltext">Hours</div>
          </div>
          <div>
            <span className="days">{minutes}</span>
            <div className="smalltext">Minutes</div>
          </div>
          <div>
            <span className="days">{seconds}</span>
            <div className="smalltext">Seconds</div>
          </div>
        </div>
      );
    }
  };

  getTimeUntil() {
    const { newYearCountdownReducer } = this.props;
    if (typeof this.state.nowDateFull === "object") {
      return `Time until ${moment(this.state.nowDateFull).format(
        "dddd, D MMMM, YYYY HH:mm:ss"
      )} (${newYearCountdownReducer.name} time)`;
    } else {
      return ``;
    }
  }

  startLoader() {
    //const value = false;
    if (!this.state.dateCityNow) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
  }
  timeCountdown() {
    const { newYearCountdownReducer } = this.props;
    const timestamp = moment().format("x");
    const zone =
      moment.tz.zone(newYearCountdownReducer.timezone).parse(timestamp) *
      60 *
      1000;
    const timeMoment = moment().tz(newYearCountdownReducer.timezone);
    const year = timeMoment.get("year");
    const month = timeMoment.get("month"); // 0 to 11
    const day = timeMoment.get("date");
    const hour = timeMoment.get("hour");
    const minute = timeMoment.get("minute");
    if (month === 1 && day === 0 && hour === 0 && minute <= 300)
      return +moment()
        .tz(newYearCountdownReducer.timezone)
        .format("x");
    else return +moment(`${year + 1}-01-01T00:00:00+00:00`).format("x") + zone;
  }
  render() {
    const { newYearCountdownReducer } = this.props;
    // const {countdownReducer} = this.props;
    //console.log('CreatedTimer', countdownReducer);
    //console.log('optionsFromDefault', this.state.optionsFromDefault);
    //console.log('search URL', this.props.location.pathname);

    if (!newYearCountdownReducer.id) {
      return (
        <div className="sunCulcCityInfoCenter">
          <Loader />
        </div>
      );
    }
    this.setDeadlineSeconds();
    this.setChangeLocationEvent();
    return (
      <div className="created-timer">
        <div className="header_countdown_NY">
          <div className="search-block">
            <span>
              Find out how much months, days and time left to the New Year in
            </span>
            <Search />
          </div>
          {/* <span>Find out how much months, days and time left to the New Year in </span>
                    <ComponentLocationEvent/> */}
        </div>
        {/*{this.startLoader()}*/}
        <div>{this.getComponentToRender()}</div>
        <div
          style={{ fontFamily: `${this.state.optionsFromDefault.title.style}` }}
          className="created-timer-title_name"
        >
          <p>{this.state.optionsFromDefault.title.name_event}</p>
        </div>
        <div className="created-timer-format">
          <Countdown
            // date={Date.now() + this.state.differenceSeconds}
            date={this.timeCountdown()}
            renderer={this.renderer}
          />
        </div>
        <div className="created-timer-time_until">
          <p>{this.getTimeUntil()}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    countdownReducer: state.countdownReducer,
    newYearCountdownReducer: state.newYearCountdownReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    newYearCountdown: bindActionCreators(newYearCountdown, dispatch),
    clearCountdownRedux: () => {
      const payload = {};
      dispatch({ type: "CLEAR_COUNTDOWN", payload });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatedTimerNewYear);
