import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./cookies.css";

class CookiesNotice extends Component {
  state = {
    isShow: true
  };

  componentDidMount() {
    if (localStorage.getItem("cookies")) {
      this.setState({ isShow: false });
    }
  }

  handleHide = () => {
    localStorage.setItem("cookies", "accepted");
    this.setState({ isShow: false });
  };

  render() {
    if (this.state.isShow) {
      return (
        <div className="cookies-notice">
          <p>
            This website uses cookies. By continuing to browse the website, you
            are agreeing to our <Link to="/privacy">Privacy Policy</Link> and{" "}
            <HashLink to="/privacy#cookie">Cookie Policy</HashLink>.
          </p>
          <button onClick={this.handleHide} type="button">
            Accept & Close
          </button>
        </div>
      );
    }

    return null;
  }
}

export default CookiesNotice;
