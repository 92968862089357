
const sunRiseShadowReducer = (state = 0, action) => {
  switch (action.type) {
    case 'SUN_RISE_SHADOW':
      return !action.data ? state : action.data;
    default:
      return state;
  }
};
  
export default sunRiseShadowReducer;