import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import Countdown from './Countdown/Countdown';
import Loader from "../../Loader/Loader";

import sunArticlePicture from '../../../../assets/Articles/sun.jpeg';
import moonArticlePicture from '../../../../assets/Articles/moon.jpeg';
import panchangArticlePicture from '../../../../assets/Articles/retrograde.jpeg';
import advertising from '../../../../assets/taboola/336-280.png';

import Api from '../../../../services/api';

class ArticleRightSideBar extends Component {
    state = {
        eclipse: {},
        eclipseDate: ''
    };
    async componentDidMount(){
        const { currentSearchReducer } = this.props;
        const location = window.location.pathname.split('/').reverse()[1];
        const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        // console.log(location, city)
        this.setState({
            location: window.location.pathname === '/articles' ? 'main' : location,
            city: city
        });

        const data = await Api.userCityList(`eclipse_city/`);
        if(location === 'solar-eclipse') {
            this.setState({
                eclipse: data.data.data.solar_eclipse_data,
                eclipseDate: data.data.data.solar_eclipse_data.date_start
            })
        }else if(location === 'lunar-eclipse') {
            this.setState({
                eclipse: data.data.data.lunar_eclipse_data,
                eclipseDate: data.data.data.lunar_eclipse_data.penumbral_eclipse_begin
            })
        }
        // console.log(location, data.data.data)
    }
    countdownTimer(){
        return moment(this.state.eclipseDate).tz(this.state.city.timezone).format('ll LTS z');
    }
    sidebarChoose(){
        const { city, location, eclipse, eclipseDate } = this.state;
        switch(location){
            case 'sun':
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block moon">
                            <h5 className="eclipse-header">Find out how the Sun moves across the sky in your city</h5>
                            <img src={sunArticlePicture} alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/moon${city.url.replace('place', '')}`}>Click here to find out</a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
            case 'moon':
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block sun">
                            <h5 className="eclipse-header">Do you knew how the Moon moves across the sky in your city?</h5>                
                            <img src={sunArticlePicture} alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/sun${city.url.replace('place', '')}`}>Click here to find out</a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
            case 'solar-eclipse':
                if (!this.state.eclipse) return (<div className="sunCulcCityInfoRightSide"></div>);
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block sun">
                            <h5 className="eclipse-header">Countdown the Next Solar Eclipse Worldwide<br/>{this.countdownTimer()}</h5>
                            <Countdown
                                eclipseDate={eclipseDate}
                                timezone={city.timezone}
                            />
                            <img src={`/${eclipse.picture_url}`} className="eclipse-planet" alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/solar-eclipse/${city.url.replace('place/', '')}/${moment(eclipseDate).tz(city.timezone).format('YYYY-MM-DD')}`}>
                                        Click here to see whether this eclipse will be visible in your city.
                                    </a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
            case 'lunar-eclipse':
                if (!this.state.eclipse) return (<div className="sunCulcCityInfoRightSide"></div>);
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block sun">
                            <h5 className="eclipse-header">Countdown the Next Lunar Eclipse Worldwide<br/>{this.countdownTimer()}</h5>
                            <Countdown
                                eclipseDate={eclipseDate}
                                timezone={city.timezone}
                            />
                            <img src={`/${eclipse.picture_url}`} className="eclipse-planet" alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/solar-eclipse/${city.url.replace('place/', '')}/${moment(eclipseDate).tz(city.timezone).format('YYYY-MM-DD')}`}>
                                        Click here to see whether this eclipse will be visible in your city.
                                    </a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
            case 'panchang':
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block retrograde">
                            <h5 className="eclipse-header">When is the next <br/> Mercury Retrograde?</h5>                
                            <img src={panchangArticlePicture} alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/sun${city.url.replace('place', '')}`}>Click here to find out</a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className='sunCulcCityInfoRightSide'>
                        <div className="links img-block moon">
                            <h5 className="eclipse-header">Do you knew how the Moon moves across the sky in your city?</h5>                
                            <img src={moonArticlePicture} alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/moon${city.url.replace('place', '')}`}>Click here to find out</a>
                                </b>
                            </div>
                        </div>
                        <div className="links img-block sun">
                            <h5 className="eclipse-header">Find out how the Sun moves across the sky in your city</h5>                
                            <img src={sunArticlePicture} alt="Geotimedate" />
                            <div className="eclipse-info-block">
                                <b>
                                    <a href={`/sun${city.url.replace('place', '')}`}>Click here to find out</a>
                                </b>
                            </div>
                        </div>
                        <div className="advertising-block-main">
                            <img src={advertising} className="advertising" alt="advertising" />
                        </div>
                    </div>
                );
        }
    }
    colorChoose(location){
        switch(location){
            case 'sun':
                return { background: '#ade4f9', color: '#444f4f' };
            case 'moon':
                return { background: '#fff492', color: '#444f4f' };
            case 'solar-eclipse':
                return { background: '#fff492', color: '#444f4f' };
            case 'lunar-eclipse':
                return { background: '#ade4f9', color: '#444f4f' };
            case 'panchang':
                return { background: '#ff7532', color: '#fff' };
            default:
                return { background: '#ff7532', color: '#fff' };
        }
    }
    render() {
        // if (!this.state.location) return (<div className="right-sidebar"><Loader /></div>);
        if (!this.state.city || !this.state.location) return (<div className="right-sidebar"><Loader /></div>);
        const { city, location } = this.state;
        return (
            <aside className='right-sidebar'>
                {this.sidebarChoose()}
                <div className='sunCulcCityInfoRightSide'>
                    <div className="links">
                        <h5 className="eclipse-header" style={this.colorChoose(location)}>Find Complete Almanac for any City</h5>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to={`/sun${city.url.replace('place', '')}`}>Sun Map and Calculator</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to={`/moon${city.url.replace('place', '')}`}>Moon Movement and Phases</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to='/'>Time-Planer</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to="/solar-lunar-eclipses/world">Catalog of all Solar and Lunar Eclipses (1900 - 2099)</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to={`/#planet-stations-retrogrades`}>Planet Stations and Retrogrades</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to={`/#planet-in-signs`}>Planet in Zodiac Signs</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <Link to={`/planetary-hours${city.url.replace('place', '')}`}>Planetary Hours</Link>
                            </b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>
                                <a href={`/panchang${city.url.replace('place', '')}`}>Detailed Panchang</a>
                            </b>
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer
    };
};

export default connect(mapStateToProps)(ArticleRightSideBar);