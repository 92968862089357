const businesDTDReducer = (state = {}, action) => {
  //console.log('reducer', action.type, action.payload);
  switch (action.type) {
    case 'PASS_SETTINGS':
      return action.payload;
    default:
      return state;
  }
};

export default businesDTDReducer;

