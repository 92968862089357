import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";

import "./CityName.css";

class CityName extends Component {
  cityName() {
    const { currentSearchReducer, nextPlanetRetrogradeReducer } = this.props;
    const name =
      currentSearchReducer.name !== null ? `${currentSearchReducer.name}` : "";
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.state
          ? `${currentSearchReducer.state},`
          : ""
        : ``;
    const planet = window.location.pathname.split("/")[1].split("-")[0];
    const tz = currentSearchReducer.timezone;
    const start = moment(nextPlanetRetrogradeReducer.retroList.date_retro).tz(
      tz
    );
    const end = moment(nextPlanetRetrogradeReducer.retroList.date_direct).tz(
      tz
    );
    const currentTime = moment().tz(tz);

    let datePos;
    if (nextPlanetRetrogradeReducer.type === "Current") {
      datePos =
        start.format("x") <= currentTime.format("x") &&
        currentTime.format("x") <= end.format("x")
          ? "Current"
          : "Next";
    } else datePos = "Selected";
    const teztStart =
      datePos === "Current" || end.format("x") <= currentTime.format("x")
        ? "started"
        : "starts";

    return (
      <h4>
        <b>
          {datePos} {planet} Retrograde for {name}, {state} {country}
        </b>
        <b>
          {teztStart} on {moment(start).tz(tz).format("ll")} and ends on{" "}
          {moment(end).tz(tz).format("ll")}
        </b>
      </h4>
    );
  }
  render() {
    const {
      currentSearchReducer,
      planetaryStyle,
      planetesRetrogradeReducer,
    } = this.props;
    const planetElem = planetesRetrogradeReducer.retrogradeList[0];
    return (
      <div className="city-whith-planet-img">
        <img
          src={`/static/planetes_photo/${planetElem.planet}.jpg`}
          alt={`${planetElem.planet}`}
        />
        <div className="citySunRiseSet" style={planetaryStyle}>
          <div className="country_flag">
            <img
              className="cityMainImg"
              src={`/static/flags/flags-iso/flat/64/${currentSearchReducer.country_code}.png`}
              alt="citydateandtime"
            />
          </div>
          <div className="city_name_date">{this.cityName()}</div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    planetesRetrogradeReducer: state.planetesRetrogradeReducer,
    nextPlanetRetrogradeReducer: state.nextPlanetRetrogradeReducer,
  };
};

export default connect(mapStateToProps)(CityName);
