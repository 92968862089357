import React from "react";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";
import "./about.css";

class About extends React.Component {
	componentDidMount() {
		Api.getAbout().then(result => {
			document.querySelector("#aboutContent").innerHTML = result.data;
		});
	}

	render() {
		return (
			<div className="about">
				<MetaTags>
					<title>GeoTimeDate – About Us</title>
				</MetaTags>
				<div id="aboutContent"></div>
			</div>
		);
	}
}

export default About;
