import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import './MainArticlePage.css';
import Loader from "../../Loader/Loader";
import ArticleRightSideBar from "./ArticleRightSideBar";

import currentNews from '../../../../actions/action_currentNews';

import Api from '../../../../services/api';

class MainArticlePage extends Component {
    state = {
        news: []
    };
    async componentDidMount() {
        const data = await Api.userCityList(`news/`);
        const categories = {};
        data.data.forEach(elem => {
            if (elem.category !== null) categories[elem.category.name] = true;
        });

        const newsArr = data.data.filter(elem => {
            return elem.show === true;
        });
        this.setState({
            news: newsArr,
            categories: Object.keys(categories)
        });
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        if (window.location.hash) {
            // eslint-disable-next-line react/no-string-refs
            const block = this.refs[window.location.hash.replace('#', '')];
            if (this.state !== prevState) {
                window.scrollTo(0, block.offsetTop + block.offsetParent.offsetTop)

            }
        }
    }
    selectedNews(elem) {
        const { currentNews } = this.props;
        currentNews(elem);
        localStorage.setItem('currentNews', JSON.stringify(elem));
    }
    newsRender(elemName) {
        const categoryBlock = this.state.news.filter(elem => {
            return elem.category.name === elemName;
        });
        return categoryBlock.length ? categoryBlock : [];
    }
    render() {
        if (!this.state.categories) {
            return (<div className="all_news_wrapper"><Loader /></div>)
        }
        return (
            <div className="main-article-page">
                <h1>Knew more about...</h1>
                <div className="all_news_wrapper">
                    <div className='main-articles-block'>
                        {this.state.categories.map((elem, index) =>
                            <div className="all_news_list" key={index} ref={elem.toLowerCase().replace(' ', '_')} id={elem.toLowerCase()}>
                                <h3 title={elem}>{elem}</h3>
                                <ul>
                                    {this.newsRender(elem).map((newsElem, newsIndex) =>
                                        <li key={newsIndex}>
                                            <div className="one_news_wrapper">
                                                <Link to={`/articles/${elem.replace(' ', '-').toLowerCase()}/${newsElem.slug}`}
                                                    onClick={this.selectedNews.bind(this, newsElem)}>
                                                    <div className="short_news_info">
                                                        <h4 title={newsElem.title}>
                                                            {newsElem.title}
                                                        </h4>                                                        
                                                        <div className="articles_text_info ellipsis">
                                                            <em>{newsElem.lead}</em>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <ArticleRightSideBar />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentNewsReducer: state.currentNewsReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentNews: bindActionCreators(currentNews, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainArticlePage);