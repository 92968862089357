import React, { Component } from "react";

import Calculator from "./Calculator/Calculator";
import "./DateToDateCalculator.css";

class DateToDateCalculator extends Component {
  render() {
    return (
      <div className="DateToDateCalculator">
        <Calculator />
      </div>
    );
  }
}

export default DateToDateCalculator;
