import React from "react";
import { Link } from "react-router-dom";

import Api from "../../../../../../services/api.js";

class Links extends React.Component {
	state = {
		articles: []
	};

	async componentDidMount() {
		const data = await Api.userCityList(`news/?limit=5&category=moon`);
		this.setState({ articles: data.data });
	}

	render() {
		return (
			<div className="links">
				<h5 className="eclipse-header">Articles</h5>
				{this.state.articles.map((elem, index) => (
					<div className="eclipse-info-block" key={index}>
						<b>
							<Link to={`/articles/moon/${elem.slug}`}>
								{elem.title}
							</Link>
						</b>
					</div>
				))}
				<b>
					<Link to={"/articles#moon"}>...more</Link>
				</b>
			</div>
		);
	}
}

export default Links;
