import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";
import "react-sticky-table/dist/react-sticky-table.css";
import "../../../../theme/fonts/zodiac-font/zodiac.css";

import "./Retrograde.css";
import Loader from "../../../../components/Main/Loader/Loader";
import Search from "../../MainPage/Search/Search";
import CityName from "./CityName/CityName";
import RetrogradeRaphaelDraw from "./RetrogradeRaphaelDraw/RetrogradeRaphaelDraw";
import RetrogradeData from "./RetrogradeData/RetrogradeData";
import advertising from "../../../../assets/sun_page/970-90.jpg";
import RetrogradeRightBlock from "./RetrogradeRightBlock/RetrogradeRightBlock";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";
import planetesRetrograde from "../../../../actions/action_planetesRetrograde";
import nextPlanetRetrograde from "../../../../actions/action_nextPlanetRetrograde";
import MetaTags from "react-meta-tags";

import Api from "../../../../services/api.js";

class Retrograde extends Component {
  state = {};
  planets = [
    "mercury",
    "venus",
    "mars",
    "jupiter",
    "saturn",
    "uranus",
    "neptune",
    "pluto",
    "chiron",
  ];
  tableHeader = [
    ["Shadow", "Start Date"],
    ["Number of days in", "Shadow"],
    ["Retrograde Start", "Date"],
    ["Zodiac", "Degree"],
    ["Days in", "Retrograde"],
    ["Date Direct", ""],
    ["Zodiac", "Degree"],
    ["Shadow", " End Date"],
    ["Number of days", " in Shadow"],
  ];
  componentDidMount() {
    this.pareReset();
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) this.pareReset();
  }
  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.planetesRetrogradeReducer !==
  //     this.props.planetesRetrogradeReducer
  //   )
  //     this.opgPicture();
  // }
  // async opgPicture() {
  //   const { planetesRetrogradeReducer, currentSearchReducer } = this.props;
  //   if (planetesRetrogradeReducer.planet) {
  //     const planet = planetesRetrogradeReducer.planet.toLowerCase();
  //     const newId = currentSearchReducer.url
  //       .replace("place/", "")
  //       .replace(/\//g, "__");
  //     const opgPicture = await Api.userCityList(
  //       `opg/retrogrades-${planet}/get-url/?geo_name=${newId}&planet=${planet}`
  //     );
  //     this.setState({ opgPicture: opgPicture.data.url });
  //   }
  // }
  async pareReset() {
    const newId = window.location.pathname.split("/").slice(2).join("__");
    const {
      currentSearch,
      planetesRetrograde,
      nextPlanetRetrograde,
    } = this.props;

    const data = await Api.userCityList(`geo_names/${newId}/`);
    currentSearch(data.data);

    const tz = data.data.timezone;
    const year = moment().tz(tz).get("year");
    const planet = window.location.pathname.split("/")[1].split("-")[0];
    const retrogradeRequest = await Api.userCityList(
      `retrograde/${data.data.id}/${year}/?p=${planet}&exclude_past=true`
    );

    const yearList = ["Next 10 Years"];
    for (let i = 1900; i < 2099; i += 10) {
      yearList.push(
        `${moment().tz(tz).set("year", i).format()} - ${moment()
          .tz(tz)
          .set("year", i + 10)
          .format()}`
      );
    }

    planetesRetrograde({
      planet: planet,
      period: "Next 10 Years",
      periodes: yearList,
      retrogradeList: retrogradeRequest.data.data,
      individualText: retrogradeRequest.data.individual_text,
      current_zodiac: retrogradeRequest.data.current_zodiac,
    });
    nextPlanetRetrograde({
      retroList: retrogradeRequest.data.next_retrograde,
      type: "Current",
    });
  }
  planetaryStyle() {
    const { planetesRetrogradeReducer } = this.props;
    switch (planetesRetrogradeReducer.planet) {
      case "Mercury":
        return { background: "#800103", color: "#fff" };
      case "Venus":
        return { background: "#009d28", color: "#fff" };
      case "Mars":
        return { background: "#ff040f", color: "#fff" };
      case "Jupiter":
        return { background: "#ff7421", color: "#fff" };
      case "Saturn":
        return { background: "#a6a6a6", color: "#fff" };
      case "Uranus":
        return { background: "#0078f9", color: "#fff" };
      case "Neptune":
        return { background: "#800080", color: "#fff" };
      case "Pluto":
        return { background: "#618ab5", color: "#fff" };
      case "Chiron":
        return { background: "#d635c9", color: "#fff" };
      case "Sun":
        return { background: "#caca22", color: "#fff" };
      case "Moon":
        return { background: "#000", color: "#fff" };
      default:
        return { background: "#fff", color: "#000" };
    }
  }
  metaCityName(nameType) {
    const { currentSearchReducer } = this.props;

    if (window.location.pathname.includes("-retrograde")) {
      const parsedUrl = window.location.pathname.split("/");
      const normalUrlsParamsAmount = 5; // empty str, page type, city,state,country, location id

      let urlCity = "";
      let parsedUrlCity = "";
      let urlCountry = "";
      let parsedUrlCountry = "";

      if (parsedUrl.length < normalUrlsParamsAmount) {
        urlCountry = window.location.pathname.split("/")[2];
        parsedUrlCountry =
          urlCountry.charAt(0).toUpperCase() +
          urlCountry.slice(1).replace(/-/g, " ");
      } else {
        urlCity = window.location.pathname.split("/")[4];
        parsedUrlCity =
          urlCity.charAt(0).toUpperCase() + urlCity.slice(1).replace(/-/g, " ");
        urlCountry = window.location.pathname.split("/")[2];
        parsedUrlCountry =
          urlCountry.charAt(0).toUpperCase() +
          urlCountry.slice(1).replace(/-/g, " ");
      }

      const name = currentSearchReducer.name
        ? currentSearchReducer.name
        : parsedUrlCity;
      const country = currentSearchReducer.country_name
        ? currentSearchReducer.country_name === "United States"
          ? currentSearchReducer.country_name_abbr
          : currentSearchReducer.country_name
        : parsedUrlCountry === "usa"
        ? "USA"
        : parsedUrlCountry;

      switch (nameType) {
        case "title":
          return name ? `${name}, ${country} ` : `${country}`;
        case "description":
          return name ? `${name}, ${country}` : `${country}`;
        case "keywords":
          return name ? `${name}, ${country}, ` : `${country}, `;
        default:
          return "";
      }
    }
    return "";
  }
  meta = () => {
    const { planetesRetrogradeReducer } = this.props;
    const planetName = planetesRetrogradeReducer.planet
      ? planetesRetrogradeReducer.planet
      : window.location.pathname
          .split("/")[1]
          .substr(0, window.location.pathname.split("/")[1].indexOf("-"));
    return (
      <MetaTags>
        <title>
          {planetName} stations and retrogrades for {this.metaCityName("title")}{" "}
          from 1900-2099.
        </title>
        {/*<meta*/}
        {/*  name="og:title"*/}
        {/*  content={`${planetName} stations and retrogrades for ${this.metaCityName(*/}
        {/*    "title"*/}
        {/*  )} from 1900-2099.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="og:description"*/}
        {/*  content={`Find the current and next ${planetName} retrograde for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. Find planet stations and retrogrades from 1900-2099. See the shadow and retrograde start-end date.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content={`Find the current and next ${planetName} retrograde for ${this.metaCityName(*/}
        {/*    "description"*/}
        {/*  )}. Find planet stations and retrogrades from 1900-2099. See the shadow and retrograde start-end date.`}*/}
        {/*/>*/}
        {/*<meta*/}
        {/*  name="keywords"*/}
        {/*  content={`Planets, stations, retrogrades, ${planetName} retrograde for ${this.metaCityName(*/}
        {/*    "keywords"*/}
        {/*  )}shadow start, retrograde start, date direct, days in shadow, shadow end date, days in retrograde`}*/}
        {/*/>*/}
        {/*<meta rel="canonical" href={window.location.href} />*/}
        {/*{this.state.opgPicture ? (*/}
        {/*  <meta*/}
        {/*    property="og:image"*/}
        {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
        {/*  />*/}
        {/*) : null}*/}
      </MetaTags>
    );
  };
  render() {
    const {
      planetesRetrogradeReducer,
      nextPlanetRetrogradeReducer,
    } = this.props;
    if (
      planetesRetrogradeReducer.planet === "" ||
      !planetesRetrogradeReducer.retrogradeList.length ||
      !nextPlanetRetrogradeReducer.retroList
    ) {
      return (
        <div className="Retrograde">
          {this.meta()}
          <Loader />
        </div>
      );
    }
    return (
      <div className="Retrograde">
        {this.meta()}
        <div className="serarch-block">
          <b>
            Find out when Mercury, Venus, Mars, Jupiter, Saturn, <br />
            Uranus, Neptune, Pluto and Chiron are retrograde in
          </b>
          <Search />
        </div>
        <CityName planetaryStyle={this.planetaryStyle()} />
        <div className="retrograde-table">
          <div className="RetrogradeData">
            <RetrogradeRaphaelDraw />
            <RetrogradeData planetaryStyle={this.planetaryStyle()} />
          </div>
          <RetrogradeRightBlock planetaryStyle={this.planetaryStyle()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    planetesRetrogradeReducer: state.planetesRetrogradeReducer,
    nextPlanetRetrogradeReducer: state.nextPlanetRetrogradeReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
    planetesRetrograde: bindActionCreators(planetesRetrograde, dispatch),
    nextPlanetRetrograde: bindActionCreators(nextPlanetRetrograde, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Retrograde);
