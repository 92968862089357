import React from "react";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import dst from "../../assets/dst.png";
import {getattr} from "../../utils/sharedFunctions";

export const createMarkup = (t) => {
    return {__html: t};
};

export const TableFooter = props => {
    const {url, cityName} = props;
    return <div
        style={{
            fontSize: "13px",
        }}
    >
        *Showing local times for <Link to={`/time-in${url.replace('place', '')}`}>
        {cityName}</Link> adjusted for DST, if applicable.
    </div>
};

export const DstIconMassage = props => {
    const {city, dst_data} = props;
    const {date, is_dst, directions} = dst_data;

    const date_moment = moment.tz(date, 'UTC');
    const date_with_tz = moment.tz(date, city.timezone);

    const dateToShow = date_with_tz.format(`MMMM Do, YYYY [at] [${date_moment.hour()}]:mm a`);
    const dstStartEnd = is_dst ? 'end' : 'start';

    return <React.Fragment>
        {`Due to DST ${dstStartEnd}, clocks in ${city.name} move ${directions} by 1 hour on ${dateToShow}`}
    </React.Fragment>
};

export const DstIcon = props => {
    const DAY_COUNT_WHEN_SHOW = 10;

    const {city, calendarDate, dst_data} = props;

    if (!dst_data) {
        return null;
    }

    const {date} = dst_data;

    if (!date) {
        return null;
    }

    const date_with_tz = moment.tz(date, city.timezone);

    const currentDate = moment(calendarDate);

    if (date_with_tz.diff(currentDate, 'days') > DAY_COUNT_WHEN_SHOW || date_with_tz.diff(currentDate, 'days') < 0) {
        return null
    }

    return (
        <div className="time-shift-warning">
            <div className="time-shift-alert">
                <DstIconMassage dst_data={dst_data} city={city} />
            </div>
            <img src={dst} alt="DST indication"/>
        </div>
    );
};