import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import moment from "moment-timezone";

import Header from "./components/Header";
import TopBlockCities from "./components/TopBlockCities";

import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import Api from "../../../services/api.js";
import "../WorldExchanges/WorldExchanges.css";

class WorldExchangeSingle extends Component {
  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;

    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };

    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else if (currentSearchReducer.id) {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
    } else {
      const parsedUrl = window.location.pathname.split("/");
      const idFromUrl = parsedUrl[parsedUrl.length - 1];
      data = await Api.userCityList(`geo_names/${idFromUrl}/`);
      cityInfoLoad(data.data);
    }

    this.updateMetaTags();
  }

  updateMetaTags = () => {
    const { name } = this.props.stockExchangeSing.stock_exchanged[0];
    const { currentCityInfoReducer } = this.props;

    const title = `Trading Hours of ${name} as per ${
      currentCityInfoReducer.name
    } Time (${moment.tz(currentCityInfoReducer.timezone).zoneAbbr()})`;
    // const description = `Find out what are the operating hours of ${name} as per ${
    //   currentCityInfoReducer.name
    // } time (${moment
    //   .tz(currentCityInfoReducer.timezone)
    //   .zoneAbbr()}). Get to know the trading hours, open and close time, working days and lunch timings of ${name}.`;
    // const keywords = `${name}, trading hours of ${name}, trading hours of ${name} as per ${currentCityInfoReducer.name} time, operating hours of ${name}, operating hours of ${name} as per ${currentCityInfoReducer.name} time, working days of ${name}, open and close time of ${name} as per ${currentCityInfoReducer.name} time, lunch break of ${name}`;

    document.title = title;
    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute("content", description);
    // document
    //   .querySelector('meta[name="keywords"]')
    //   .setAttribute("content", keywords);
  };

  render() {
    return (
      <div className="world_exchanges_page">
        <Header nameStock={this.props.match.params.nameStock} />
        <TopBlockCities />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    stockExchangeSing: state.stockExchangeSing,
    currentCityInfoReducer: state.currentCityInfoReducer,
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorldExchangeSingle);
