import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment-timezone/index.js";
import { Link } from 'react-router-dom';

import './SolarEclipsesInfo.css';


class SolarEclipsesInfo extends Component {
    timeTotallEclipse(time, tz){
        return time === null || time === '' ? '' : moment(time).tz(tz).format('LT');
    }
    durationTotallEclipse(duration){
        return duration === null || duration === '' ? '' : duration;
    }
    zodiacPosition(elem){
        return `${elem.body_pos_deg}${elem.body_pos_min} ${elem.zodiac_name}`;
    }
    timeLocalFor(tz){
        const { eclipsesDataReducer, pageType } = this.props;
        let zone;
        if(pageType === 'Solar'){
            zone = moment(eclipsesDataReducer.solar_eclipse_data.partial_eclipse_begin).tz(tz).format('z');
        }else zone = moment(eclipsesDataReducer.partial_eclipse_begin).tz(tz).format('z');
        const name = eclipsesDataReducer.name;
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr :
            eclipsesDataReducer.country_name;
        const state = eclipsesDataReducer.country_name === 'United States' ?
            `${eclipsesDataReducer.state},` : ``;
        return `${name}, ${state} ${country} (${zone})`;
    }
    pictureSolar(){
        const { eclipsesDataReducer } = this.props;
        const date = moment(eclipsesDataReducer.solar_eclipse_data.date_start).format('YYYYMMMDD');
        const format = eclipsesDataReducer.solar_eclipse_data.eclipse_type.title.slice(0, 1);
        return `SE${date}${format}`;
    }
    solarLunatInfo(){
        const { eclipsesDataReducer, pageType } = this.props;
        const tz = eclipsesDataReducer.timezone;
        if(pageType === 'Solar'){
            return (
                <div className="sunCulcCityInfoRightSide">
                    <div className="eclipse-info">
                        <div className="eclipse-info-block">
                            <b>Global Type:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.eclipse_type.title}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>In {eclipsesDataReducer.name}:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.eclipse_type.title}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Time at great Eclipse:</b>
                            <b>{moment(eclipsesDataReducer.solar_eclipse_data.date_max).tz(tz).format('lll z')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Saros Series:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.saros_cycle}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Saros number:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.saros_number}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>ECL Magnitude:</b>
                            <b>{(+eclipsesDataReducer.solar_eclipse_data.magnitude).toFixed(3)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Sun altitude at max:</b>
                            <b>{(eclipsesDataReducer.solar_eclipse_data.altitude).toFixed(2)}&#176;</b>
                            {/* <b>{((180 / Math.PI) * eclipsesDataReducer.solar_eclipse_data.altitude).toFixed(2)}&#176;</b> */}
                        </div>
                        <div className="eclipse-info-block">
                            <b>Sun Azimuth at max:</b>
                            <b>{(eclipsesDataReducer.solar_eclipse_data.azimuth).toFixed(2)}&#176;</b>
                            {/* <b>{((180 / Math.PI) * eclipsesDataReducer.solar_eclipse_data.azimuth).toFixed(2)}&#176;</b> */}
                        </div>
                        <div className="eclipse-info-block">
                            <b>Zodiac position:</b>
                            <b>
                                <span>Sun: {this.zodiacPosition(eclipsesDataReducer.solar_eclipse_data.zodiac_position[0])}</span>
                                <span>Moon: {this.zodiacPosition(eclipsesDataReducer.solar_eclipse_data.zodiac_position[1])}</span>
                            </b>
                        </div>
                    </div>
                    <div className="eclipse-info">
                        <div className="eclipse-info-block">
                            <b>Sun Rise:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.solar_eclipse_data.sun_rise, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Time for partial of Eclipse:</b>
                            <b>{moment(eclipsesDataReducer.solar_eclipse_data.date_start).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Time for max of Eclipse:</b>
                            <b>{moment(eclipsesDataReducer.solar_eclipse_data.date_max).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Time for partial end of Eclipse:</b>
                            <b>{moment(eclipsesDataReducer.solar_eclipse_data.date_end).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Sunset:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.solar_eclipse_data.sun_set, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Duration of Eclipse:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.duration}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Duration ot Partial Eclipse:</b>
                            <b>{eclipsesDataReducer.solar_eclipse_data.duration_partial}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <Link to={`/time-in/${eclipsesDataReducer.url.replace('place/', '')}`}><p>*Showing local times for {this.timeLocalFor(tz)}</p></Link>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="sunCulcCityInfoRightSide">
                    <div className="eclipse-info">
                        <div className="eclipse-info-block">
                            <b>Global Type:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>In {eclipsesDataReducer.name}:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Time at great Eclipse:</b>
                            <b>{moment(eclipsesDataReducer.lunar_eclipse_data.maximum_eclipse_swe).tz(tz).format('lll z')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Saros Series:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.saros_series}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Saros number:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.saros_number}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Umbral Magnitude:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.magnitude.toFixed(3)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Penumbral Duration:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.penumbral_duration}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Zodiac position:</b>
                            <b>
                                <span>Sun: {this.zodiacPosition(eclipsesDataReducer.lunar_eclipse_data.zodiac_position[0])}</span>
                                <span>Moon: {this.zodiacPosition(eclipsesDataReducer.lunar_eclipse_data.zodiac_position[1])}</span>
                            </b>
                        </div>
                    </div>
                    <div className="eclipse-info">
                        <div className="eclipse-info-block">
                            <b>Moon Rise:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.lunar_eclipse_data.moon_rise, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Moon Set:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.lunar_eclipse_data.moon_set, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Penumbral eclipse begines:</b>
                            <b>{moment(eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_begin).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Partial eclipse begines:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.lunar_eclipse_data.partial_eclipse_begin, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Maximum eclipse:</b>
                            <b>{moment(eclipsesDataReducer.lunar_eclipse_data.maximum_eclipse_swe).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Partial eclipse ends:</b>
                            <b>{this.timeTotallEclipse(eclipsesDataReducer.lunar_eclipse_data.partial_eclipse_end, tz)}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Penumbral eclipse ends:</b>
                            <b>{moment(eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_end).tz(tz).format('LT')}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Duration of Eclipse:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.penumbral_duration}</b>
                        </div>
                        <div className="eclipse-info-block">
                            <b>Duration ot Partial Eclipse:</b>
                            <b>{eclipsesDataReducer.lunar_eclipse_data.partial_duration}</b>
                        </div>
                        {/* <div className="eclipse-info-block">
                            <b>Duration ot total eclipse:</b>
                            <b>{this.durationTotallEclipse(eclipsesDataReducer.lunar_eclipse_data.duration_totality)}</b>
                            <b>-</b>
                        </div> */}
                        <div className="eclipse-info-block">
                            <Link to={`/time-in/${eclipsesDataReducer.url.replace('place/', '')}`}><p>*Showing local times for {this.timeLocalFor(tz)}</p></Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
    sunMoonPicture(){
        const { eclipsesDataReducer, pageType } = this.props;
        if(pageType === 'Solar'){
            return (
                <div className='eclipses-img'>
                    <img className="cityMainImg" src={`/${eclipsesDataReducer.solar_eclipse_data.picture_url}`} alt="geotimedate" />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span className="picture-eclipse-text">Eclipse Predictions by Fred Espenak, NASA's GSFC.</span>
                </div>
            )
        }else{
            return (
                <div className='eclipses-img'>
                    <img className="cityMainImg" src={`/${eclipsesDataReducer.lunar_eclipse_data.picture_url}`} alt="geotimedate" />
                    <img className="cityMainImg" src={`/${eclipsesDataReducer.lunar_eclipse_data.map_url}`} alt="geotimedate" />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span className="picture-eclipse-text">Eclipse Predictions by Fred Espenak, NASA's GSFC.</span>
                </div>
            )
        }
                
    }
    render () {
        return (
            <section className='eclipsesSolarLunar eclipsesCitySolarLunar'>
                <div className="eclipsesSolarLunarInfo">

                    {this.solarLunatInfo()}
                    {this.sunMoonPicture()}
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer
    };
};

export default connect(mapStateToProps)(SolarEclipsesInfo);