import React, { Component } from "react";
import moment from "moment-timezone/index.js";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { StickyTable, Row, Cell } from "react-sticky-table";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import MetaTags from "react-meta-tags";

import "./Timezones.css";
import Loader from "../Loader/Loader";
import advertising from "../../../assets/sun_page/970-90.jpg";

import Api from "../../../services/api.js";

class Timezones extends Component {
	sortList = ["Abbreviations", "Offset"];
	checkboxStyles = {
		color: "#fff"
	};
	state = {
		sortBy: "Abbreviations",
		timezonesList: []
	};
	async componentDidMount() {
		const data = await Api.userCityList(`time_zone_infos/`);
		this.setState({ timezonesList: data.data });
		// this.opgPicture();
	}
	// async opgPicture() {
	// 	const opgPicture = await Api.userCityList(`opg/timezone/get-url/`);
	// 	this.setState({ opgPicture: opgPicture.data.url });
	// }
	sortChange = (event, index, value) => {
		this.setState({ sortBy: value });
	};
	geotimedateTable() {
		if (document.body.offsetWidth > 1199) {
			return this.state.timezonesList.length * 35 + 47 + 48;
		} else
			return document.body.offsetHeight < 1260
				? document.body.offsetHeight
				: 1260;
	}
	timezonesListSort() {
		let newTimezonesList;
		switch (this.state.sortBy) {
			case "Abbreviations":
				newTimezonesList = this.state.timezonesList.sort((a, b) => {
					if (a.abbreviation > b.abbreviation) {
						return 1;
					}
					if (a.abbreviation < b.abbreviation) {
						return -1;
					}
					return 0;
				});
				break;
			case "Offset":
				newTimezonesList = this.state.timezonesList.sort((a, b) => {
					if (a.offset === "UTC") {
						return -2;
					}
					if (
						+a.offset.replace(":", ".").split(" ")[1] >
						+b.offset.replace(":", ".").split(" ")[1]
					) {
						return 1;
					}
					if (
						+a.offset.replace(":", ".").split(" ")[1] <
						+b.offset.replace(":", ".").split(" ")[1]
					) {
						return -1;
					}
					return 0;
				});
				break;
			default:
				newTimezonesList = this.state.timezonesList;
				break;
		}
		return newTimezonesList;
	}
	meta = () => {
		return (
			<MetaTags>
				<title>
					Worldwide time zone abbreviations, locations and UTC/GMT
					offsets
				</title>
				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content={`Detailed list of time zone abbreviations and acronyms, locations and UTC/GMT offsets around the world.`}*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	name="keywords"*/}
				{/*	content={`time zone abbreviations, time zone acronyms, time zone locations, utc offset, gmt offset, time zone list`}*/}
				{/*/>*/}
				{/*{this.state.opgPicture ? (*/}
				{/*	<meta*/}
				{/*		property="og:image"*/}
				{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
				{/*	/>*/}
				{/*) : null}*/}
			</MetaTags>
		);
	};
	render() {
		if (!this.state.timezonesList.length) {
			return (
				<div className="WorldClock">
					{this.meta()}
					<Loader />
				</div>
			);
		}
		return (
			<div className="Timezones">
				{this.meta()}
				<div className="timezones-header">
					<h1>Worldwide Time Zone Abbreviations</h1>
					<div className="select-block">
						<h3>
							Current UTC Time -{" "}
							{moment()
								.tz("Greenwich")
								.format("LT")}
						</h3>
						<div className="check-block">
							<MuiThemeProvider>
								<SelectField
									className="sort-list"
									value={this.state.sortBy}
									onChange={this.sortChange}
								>
									{this.sortList.map((elem, index) => (
										<MenuItem
											value={elem}
											key={index}
											primaryText={elem}
										/>
									))}
								</SelectField>
							</MuiThemeProvider>
						</div>
					</div>
				</div>
				<div
					className="geotimedate-table"
					style={{
						width: "100%",
						height: this.geotimedateTable() + "px"
					}}
				>
					<StickyTable stickyColumnCount={0} stickyHeaderCount={1}>
						<Row>
							<Cell>Abbreviations</Cell>
							<Cell>Time Zone Name</Cell>
							<Cell>Location</Cell>
							<Cell>Offset</Cell>
						</Row>
						<Row>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
							<Cell></Cell>
						</Row>
						{this.timezonesListSort().map((elem, index, arr) => (
							<Row key={index}>
								{/* <Cell><p>{moment(elem.dt).tz(currentSearchReducer.timezone).format('lll')}</p></Cell> */}
								{/* <Cell><p>{moment(elem.dt).tz(currentSearchReducer.timezone).format(`MMM DD, YYYY LT`)} </p></Cell> */}
								{/* <Cell>{this.retrogradeZodiac(elem.sign.name, elem.angle, elem.motion_type)}</Cell> */}
								<Cell>
									<p>{elem.abbreviation}</p>
								</Cell>
								<Cell>
									<p>{elem.name}</p>
								</Cell>
								<Cell>
									<p>{elem.location}</p>
								</Cell>
								<Cell>
									<p>{elem.offset}</p>
								</Cell>
							</Row>
						))}
					</StickyTable>
				</div>
				<div className="advertising-block-main">
					<img
						src={advertising}
						className="advertising"
						alt="advertising"
					/>
				</div>
			</div>
		);
	}
}

export default Timezones;
