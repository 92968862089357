import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";

import Meta from "./Meta";
import Loader from "../../../components/Main/Loader/Loader";
import Search from "../MainPage/Search/Search";
import CitySunRiseSet from "./CitySunRiseSet/CitySunRiseSet";
import CityCalendarBlock from "./CityCalendarBlock/CityCalendarBlock";
import SunCityCalculatedBlock from "./SunCityCalculatedBlock/SunCityCalculatedBlock";
import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import sunCalc from "../../../actions/action_sunRise/action_sunCalc";
import sunRisePosition from "../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../actions/action_sunRise/action_sunRiseCoords";
import sunLogo from "../../../assets/sun_page/sun.jpg";

import Api from "../../../services/api.js";
import "./SunCulc.css";

class SunCulc extends Component {
	componentDidMount() {
		this.pareReset();
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) this.pareReset();
	}

	async pareReset() {
		const { sunCalc, sunRisePosition, sunRiseCoords } = this.props;
		const newId = window.location.pathname
			.split("/")
			.slice(2)
			.join("__");
		const sunCoordinats = await Api.userCityList(
			`geo_names/${newId}/?sun_app=true`
		);
		localStorage.setItem(
			"sunCalcCityInfo",
			JSON.stringify(sunCoordinats.data)
		);
		sunCalc(sunCoordinats.data);
		const hourCurrent = moment(sunCoordinats.time)
			.tz(sunCoordinats.data.timezone)
			.get("hour");
		const minuteCurrent = moment(sunCoordinats.time)
			.tz(sunCoordinats.data.timezone)
			.get("minute");
		for (let key in sunCoordinats.data.sun_data.position_during_day) {
			const hourDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("hour");
			const minuteDot = moment(
				sunCoordinats.data.sun_data.position_during_day[key].loc_time
			)
				.tz(sunCoordinats.data.timezone)
				.get("minute");
			if (hourDot === hourCurrent && minuteDot === minuteCurrent) {
				sunRiseCoords(+key);
				sunRisePosition(
					sunCoordinats.data.sun_data.position_during_day[+key]
				);
			}
		}
	}

	render() {
		const { sunCalcReducer } = this.props;
		if (!sunCalcReducer.id) {
			return (
				<div className="sunCulcCityInfoCenter">
					<Meta />
					<Loader />
				</div>
			);
		}
		return (
			<div className="sun-calc-page">
				<Meta />
				<div className="serarch-block">
					<b>
						Find Current Time, Sunrise, Sunset, Eclipses and more
						for
					</b>
					<Search />
				</div>
				<div className="sum-moon-pade-date">
					<div className="page-logo-block">
						<img src={sunLogo} alt="sun logo" />
					</div>
					<div className="right-block">
						<CitySunRiseSet />
						<CityCalendarBlock />
					</div>
				</div>
				<SunCityCalculatedBlock />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentSearchReducer: state.currentSearchReducer,
		sunCalcReducer: state.sunCalcReducer,
		sunRiseCoordsReducer: state.sunRiseCoordsReducer
	};
};
const mapDispatchToProps = dispatch => {
	return {
		currentSearch: bindActionCreators(currentSearch, dispatch),
		sunCalc: bindActionCreators(sunCalc, dispatch),
		sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
		sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SunCulc);
