import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import CookiesNotice from "./cookies-notice";

import logo from "../../assets/logo_white.png";
import instagram from "../../assets/footer/instagram.png";
import linkedin from "../../assets/footer/linkedin.png";
import twitter from "../../assets/footer/twitter.png";
import facebook from "../../assets/footer/facebook.png";
import "./Footer.css";

class Footer extends Component {
  state = {
    apiLink: "https://api.geotimedate.com/"
  };

  UNSAFE_componentWillMount() {
    if (
      window.location.origin === "https://geotimedate.com" ||
      window.location.origin === "http://localhost:3000"
    ) {
      return this.setState(prevState => {
        return {
          apiLink: "https://api.geotimedate.com/"
        };
      });
    } else if (
      window.location.origin === "https://citydateandtime.stemsc.com"
    ) {
      return this.setState(prevState => {
        return {
          apiLink: "https://rest-citydateandtime.stemsc.com/"
        };
      });
    }
  }

  render() {
    return (
      <footer>
        <div className="footer-wrapper">
          <Link to="/" className="logo_wrapper">
            <div className="logo_wrapper_block">
              <img src={logo} className="App-logo" alt="GeoTimeDate – Logo" />
            </div>
          </Link>
          <span>
            Copyright &#169; {moment().format("YYYY")} geotimedate.org. All
            rights reserved.
          </span>
          <ul className="social-links">
            <li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.instagram.com/geotimedate/" target="_blank">
                <img src={instagram} alt="instagram" />
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.linkedin.com/company/geotimedate/" target="_blank">
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://twitter.com/geotimedate" target="_blank">
                <img src={twitter} alt="twitter" />
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.facebook.com/geotimedate" target="_blank">
                <img src={facebook} alt="facebook" />
              </a>
            </li>
          </ul>
          <ul className="footer-links">
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/terms-of-service">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
        </div>

        <CookiesNotice />
      </footer>
    );
  }
}

export default Footer;
