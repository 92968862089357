import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { Link } from "react-router-dom";
import Loader from "../../../../Loader/Loader";

import "./AirportsBlock.css";
import airportsList from "../../../../../../actions/action_airportsList.js";

import Api from "../../../../../../services/api.js";

class AirportsBlock extends Component {
  state = {
    altername: false,
    airports: false,
  };
  async componentDidMount() {
    const { currentSearchReducer, airportsList } = this.props;
    const data = await Api.userCityList(
      `airports/${currentSearchReducer.id}/get_nearby/?dist=100`
    );
    airportsList(data.data);
  }
  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    const state = currentSearchReducer.state
      ? currentSearchReducer.state
        ? `${currentSearchReducer.state},`
        : ""
      : ``;
    return `${name}, ${state} ${country}`;
  }
  emptyAbr() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.country_name;
    const abr = currentSearchReducer.country_name_abbr;
    if (name === "United States" && abr !== null) return abr;
    else if (name !== null) return name;
    else return "";
  }
  getElectricPlug() {
    const { currentSearchReducer } = this.props;
    // console.log(currentSearchReducer.country_data.electricity_plugs)
    // let arr = currentSearchReducer.country_data.electricity_plugs.map((elem) => {
    //     return elem
    // })
    // arr = arr[0].join(', ');
    // return arr;
    return currentSearchReducer.country_data.electricity_plugs.join(", ");
  }
  getAirports(airoport, elem, index, arr) {
    switch (airoport) {
      case "first":
        return (
          <span key={index}>
            {elem.local_code} {elem.name}
          </span>
        );
      case "all":
        if (index + 1 === arr.length)
          return (
            <span key={index}>
              {elem.local_code} {elem.name}.
            </span>
          );
        else
          return (
            <span key={index}>
              {elem.local_code} {elem.name},{" "}
            </span>
          );
      default:
        return "";
    }
  }
  airportWindowClose = () => {
    this.setState({
      altername: false,
      airports: false,
    });
  };
  openAir = () => {
    this.setState({ airports: true });
  };
  openAlterNames = () => {
    this.setState({ altername: true });
  };
  render() {
    const { currentSearchReducer, airportsListReducer } = this.props;
    if (!currentSearchReducer.time || !airportsListReducer.length) {
      return (
        <div className="world-clock-links">
          <Loader />
        </div>
      );
    }
    return (
      <div className="world-clock-links world-clock-links_bottom">
        <h4>More information about {this.cityName()}</h4>
        <div className="currentCityInfo">
          <div className="hover_effect">
            <p className="alter_names">
              Airport -{" "}
              {airportsListReducer.map((elem, index) => {
                if (index === 0) return this.getAirports("first", elem, index);
                else return this.getAirports();
              })}
              <span className="alter_names_more" onClick={this.openAir}>
                ...more
              </span>
            </p>
            <MuiThemeProvider>
              <Dialog
                title={`All Airports of ${currentSearchReducer.name}`}
                actions={[
                  <FlatButton
                      key={0}
                    className="more-button"
                    label="Ok"
                    primary={true}
                    onClick={this.airportWindowClose}
                  />,
                ]}
                modal={false}
                open={this.state.airports}
                onRequestClose={this.airportWindowClose}
              >
                <div className="alter_names_full">
                  {airportsListReducer.map((elem, index, arr) =>
                    this.getAirports("all", elem, index, arr)
                  )}
                </div>
              </Dialog>
            </MuiThemeProvider>
          </div>
          <div className="hover_effect electric-plug">
            <b>Power output - {this.getElectricPlug()}</b>
            <img
              src={`${currentSearchReducer.country_data.primary_plug_type_image}`}
              className="ElectricPlug"
              alt="ElectricPlug"
            />
          </div>
          <div className="hover_effect">
            <p className="alter_names">
              Alternate names -{" "}
              {currentSearchReducer.country_data.alter_names
                .split(", ")
                .map((elem, index) => {
                  // if (index < 1) return <span key={index}>{elem}, </span>;
                  // else if (index < 2) return <span key={index}>{elem}</span>;
                  // else return "";

                  if (index < 7) {
                    return <span key={index}>{elem}, </span>;
                  } else {
                    return "";
                  }
                })}
              <span className="alter_names_more" onClick={this.openAlterNames}>
                ...more
              </span>
            </p>
            <MuiThemeProvider>
              <Dialog
                autoScrollBodyContent
                title={`Alternate names for ${
                  currentSearchReducer.country_code === "US"
                    ? `${currentSearchReducer.name}, ${currentSearchReducer.country_name}`
                    : `${currentSearchReducer.name}, ${currentSearchReducer.state}`
                }`}
                actions={[
                  <FlatButton
                      key={0}
                    className="more-button"
                    label="Ok"
                    primary={true}
                    onClick={this.airportWindowClose}
                  />,
                ]}
                modal={false}
                open={this.state.altername}
                onRequestClose={this.airportWindowClose}
              >
                <div className="alter_names_full">
                  {currentSearchReducer.country_data.alter_names}
                </div>
              </Dialog>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    airportsListReducer: state.airportsListReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    airportsList: bindActionCreators(airportsList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AirportsBlock);
