import React, { Component } from "react";

import Ads from "../Ads/Ads";

class DescriptionText extends Component {
  render() {
    return (
      <div className="column1">
        <div className="unix-general">
          <div className="unix-column description">
            <h4>About Unix TimeStamp</h4>
            <br />
            <div className="left_text">
              <h4>What is Unix time or Unix Epoch time or POSIX time?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                The Unix time or Unix Epoch time or POSIX time is the number of
                seconds that have elapsed since 00:00:00 (Midnight) Thursday,
                January 1, 1970 (UTC), minus leap seconds. The Unix time is zero
                at the Unix Epoch, and increases by 86400 per day with
                adjustment for leap seconds.
              </p>
            </div>
            <br />
            <div className="left_text">
              <p>
                The converter on this page converts timestamps to seconds and
                human readable dates and vice versa.
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Is Unix time in seconds?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Unix timestamp is the number of seconds (not milliseconds) that
                have elapsed since 00:00:00 (Midnight) Thursday, January 1, 1970
                (UTC).
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Is Unix Timestamp always in UTC/GMT?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Yes, Unix timestamp is always in UTC format (ISO 8601:
                1970-01-01T00:00:00Z).
              </p>
            </div>
            <br />
            <br />
            <div className="left_text">
              <h4>Does Unix time have time zone?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Unix timestamps are time zone independent. The Unix timestamp
                represents a moment in time that is same anywhere in the world.
                One can convert the timestamp to a local time if needed.
              </p>
            </div>
            <br />
            <div className="left_text">
              <h4>What is the use of Unix timestamp?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                As Unix timestamp is the number of seconds elapsed after the
                Unix Epoch, this point in time is same no matter where you are
                located on the globe. This feature is very useful for computer
                systems for tracking and sorting dated information in dynamic
                applications worldwide.
              </p>
            </div>
            <br />
            <div className="left_text">
              <h4>What is The Year 2038 problem?</h4>
            </div>
            <br />
            <div className="left_text">
              <p>
                Many Unix and other operating systems store Unix time as a
                signed 32-bit integer. This representation will end after
                completion of 2147483647 seconds from start. This is going to
                happen at 3:14:08 UTC time on January 19, 2038, although due to
                leap seconds it may not be exactly this. This is commonly called
                as the 2038 Problem, when the 32 bit signed UNIX time will
                overflow, taking the count negative. Before this moment millions
                of applications will need to adopt a new convention for time
                stamps.
              </p>
            </div>
            <br />
            <div className="left_text">
              <p>To know more about Unix Time in depth, you can refer –</p>
            </div>
            <br />
            <div className="left_text">
              <a
                className="link_width"
                href="https://en.wikipedia.org/wiki/Unix_time"
              >
                https://en.wikipedia.org/wiki/Unix_time
              </a>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
        <Ads />
      </div>
    );
  }
}

export default DescriptionText;
