import moment from "moment-timezone/index.js";

const calendarDateTableSunReducer = (state = moment().format('YYYY-MM-DDTHH:mm:ss'), action) => {
  switch (action.type) {
    case 'CALENDAR_DATE_TABLE_SUN':
      return action.data;
    default:
      return state;
  }
};
  
export default calendarDateTableSunReducer;