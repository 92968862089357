import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import './Countdown.css';

class Countdown extends Component {
    state = {
        time: moment().tz(moment.tz.guess())
    }
    timer = setInterval(()=> {
        return this.setState({
            time: moment().tz(moment.tz.guess())
        });
    },1000);
    componentWillUnmount(){
        clearInterval(this.timer);
    }
    getTime(val, tz){
        const { eclipsesDataReducer, pageType } = this.props;
        let eclipsesTime;
        if(pageType === 'Solar'){
            eclipsesTime = moment(eclipsesDataReducer.solar_eclipse.partial_eclipse_begin).tz(tz);
        } else eclipsesTime = moment(eclipsesDataReducer.penumbral_eclipse_begin).tz(tz);
        const difference = eclipsesTime.diff(this.state.time);
        const days =  eclipsesTime.diff(this.state.time, 'days');
        const hours = Math.floor((difference - days * 86400000) / 3600000);
        const minutes = Math.floor((difference - (days * 86400000) - (hours * 3600000)) / 60000);
        const seconds = Math.floor((difference - (days * 86400000) - (hours * 3600000) - (minutes * 60000)) / 1000);
        if(val === 'days') return days;
        else if(val === 'hours') return hours;
        else if(val === 'minutes') return minutes;
        else return seconds;
    }
    countdownTimer(tz){
        const { eclipsesDataReducer, pageType } = this.props;
        if(pageType === 'Solar'){
            return moment(eclipsesDataReducer.solar_eclipse.partial_eclipse_begin).tz(tz).format('ll LTS z');
        } else return moment(eclipsesDataReducer.penumbral_eclipse_begin).tz(tz).format('ll LTS z')
    }
    blockShow(tz){
        const { eclipsesDataReducer, pageType } = this.props;
        let eclipseDate;
        if(pageType === 'Solar'){
            eclipseDate = moment(eclipsesDataReducer.solar_eclipse.partial_eclipse_end).tz(tz).format('x');
        } else eclipseDate = moment(eclipsesDataReducer.penumbral_eclipse_end).tz(tz).format('x');
        return +moment().tz(tz).format('x') > +eclipseDate ? { display: 'none' } : {};
    }
    render () {
        const { pageType } = this.props;
        // const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        // const tz = window.location.pathname.split('/').length > 3 ? eclipsesDataReducer.timezone : 'UTC';
        const tz = 'UTC';
        return (
            <div className="sunCulcCityInfoRightSide" style={this.blockShow(tz)}>
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">
                        Countdown to this {pageType} Eclipse <br/>{this.countdownTimer(tz)}
                    </h5>
                    <div className="eclipse-info-block countdown">
                        <div className='countdown-time'>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('days', tz)}</span>
                                <span className='countdown-block-text'>Days</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('hours', tz)}</span>
                                <span className='countdown-block-text'>Hours</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('minutes', tz)}</span>
                                <span className='countdown-block-text'>Min</span>
                            </div>
                            <div className='countdown-block'>
                                <span className='countdown-block-time'>{this.getTime('seconds', tz)}</span>
                                <span className='countdown-block-text'>Sec</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer,
        currentSearchReducer: state.currentSearchReducer,
        eclipsesCatelogNextEclipseReducer: state.eclipsesCatelogNextEclipseReducer
    };
};

export default connect(mapStateToProps)(Countdown);