import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone/index.js";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import CalcSidebar from "../../CalcSidebar/CalcSidebar";
import ShowCurentUnix from "./ShowCurentUnix";
import ShowCurentDate from "./ShowCurentDate";

import currentSearch from "../../../../../actions/action_searchCity/action_currentSearch";
import Api from "../../../../../services/api";
import "./Calculator.css";

class CalculatorUnix extends Component {
  state = {
    time: moment(),
    currentUnix: Date.now(),
    difference: 0,
    UnixTimeStamp: "",
    DateTimeStamp: "",
    DD: "",
    MM: "",
    YYYY: "",
    hh: "",
    mm: "",
    ss: "",
    formatUnix: "ddd mmm dd yyyy HH:MM:ss",
    formatDate: "Local",
    selectDefoult: "",
    selectDefoult2: "",
    unixToDate: "",
    unixToDateUTC: "",
    dateToUnix: "",
    errorUnixTodate: { display: "none" },
    errorUnixTodateUTC: { display: "none" },
    errorNoNumber: { display: "none" },
    errorDateToUnix: { display: "none" },
    errorNoNumberDate: { display: "none" },
    classError: "",
    classErrorDate: "",
    timeZona: "",
    name: "",
    showDescriptionResult: false
  };

  async componentDidMount() {
    const { currentSearch, currentSearchReducer } = this.props;
    let data;
    const cityInfoLoad = cityInfo => {
      currentSearch(cityInfo);
    };
    if (localStorage.getItem("mainSearchCity")) {
      data = await Api.userCityList(
        `geo_names/${JSON.parse(localStorage.getItem("mainSearchCity")).id}/`
      );
      cityInfoLoad(data.data);
    } else {
      data = await Api.userCityList(`geo_names/${currentSearchReducer.id}/`);
      cityInfoLoad(data.data);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.currentTime);
    this.isCancelled = true;
  }

  setCurrentUnix = event => {
    this.setState({
      UnixTimeStamp: +(Date.now() + "").substring(0, 10),
      errorUnixTodate: { display: "none" },
      errorUnixTodateUTC: { display: "none" },
      errorNoNumber: { display: "none" },
      classError: ""
    });
  };
  setCurrentDate = event => {
    this.getDateTime();
    this.setState({
      errorDateToUnix: { display: "none" },
      errorNoNumberDate: { display: "none" },
      classErrorDate: ""
    });
  };
  unixTimeChange = event => {
    if (!isNaN(+event.target.value)) {
      this.setState({
        UnixTimeStamp: +event.target.value,
        errorNoNumber: { display: "none" },
        errorUnixTodate: { display: "none" },
        errorUnixTodateUTC: { display: "none" },
        classError: ""
      });
    } else if (isNaN(+event.target.value)) {
      this.setState({
        errorNoNumber: { display: "block" },
        classError: "input-error"
      });
    }
  };
  dateTimeBlur = event => {
    if (+event.target.value < 1970 && event.target.value !== "") {
      this.setState({
        YYYY: 1970
      });
    }
  };
  dateTimeChange = event => {
    if (!isNaN(+event.target.value)) {
      switch (event.target.placeholder) {
        case "DD":
          // eslint-disable-next-line no-case-declarations
          let day = +event.target.value <= 31 ? event.target.value : "31";
          this.setState({
            DD: +event.target.value === 0 ? "" : +day.substring(0, 2)
          });
          break;
        case "MM":
          // eslint-disable-next-line no-case-declarations
          let month = +event.target.value <= 12 ? event.target.value : "12";
          this.setState({
            MM: +event.target.value === 0 ? "" : +month.substring(0, 2)
          });
          break;
        case "YYYY":
          if (event.target.value.length === 4) {
            this.setState({
              YYYY:
                +event.target.value <= 1970
                  ? "1970"
                  : +event.target.value.substring(0, 4)
            });
          } else if (event.target.value.length < 4) {
            this.setState({
              YYYY:
                event.target.value === ""
                  ? ""
                  : +event.target.value.substring(0, 4)
            });
          }
          break;
        case "hh":
          // eslint-disable-next-line no-case-declarations
          let hour = +event.target.value <= 23 ? event.target.value : "23";
          this.setState({
            hh: event.target.value === "" ? "" : +hour.substring(0, 2)
          });
          break;
        case "mm":
          // eslint-disable-next-line no-case-declarations
          let minute = +event.target.value <= 59 ? event.target.value : "59";
          this.setState({
            mm: event.target.value === "" ? "" : +minute.substring(0, 2)
          });
          break;
        case "ss":
          // eslint-disable-next-line no-case-declarations
          let second = +event.target.value <= 59 ? event.target.value : "59";
          this.setState({
            ss: event.target.value === "" ? "" : +second.substring(0, 2)
          });
          break;
        default:
          this.setState({
            YYYY: "",
            MM: "",
            DD: "",
            hh: "",
            mm: "",
            ss: ""
          });
          break;
      }
      this.setState({
        errorDateToUnix: { display: "none" },
        errorNoNumberDate: { display: "none" },
        classErrorDate: ""
      });
    } else if (isNaN(+event.target.value)) {
      this.setState({
        errorNoNumberDate: { display: "block" },
        classErrorDate: "input-error"
      });
    }
  };
  formatChange = event => {
    if (event.target.getAttribute("data-id-select") === "1") {
      this.setState({
        formatUnix: event.target.value
      });
    } else if (event.target.getAttribute("data-id-select") === "2") {
      this.setState({
        formatDate: event.target.value
      });
    }
  };

  getDateTime() {
    if (this.state.time) {
      const { offset, timezone, name } = this.props.currentSearchReducer;

      let timeZona = offset ? ` ${offset}` : "";
      this.setState({ timeZona, name: name + " " });

      let a = new Date(+Date.now());
      let test = new Date(
        a.getUTCFullYear(),
        a.getUTCMonth(),
        a.getUTCDate(),
        a.getUTCHours(),
        a.getUTCMinutes(),
        a.getUTCSeconds()
      );
      let time = timezone ? moment(test).tz(timezone) : moment();

      this.setState({
        DD: moment(time._d).format("DD"),
        MM: moment(time._d).format("MM"),
        YYYY: moment(time._d).format("YYYY"),
        hh: moment(time._d).format("HH"),
        mm: moment(time._d).format("mm"),
        ss: moment(time._d).format("ss")
      });
    }
  }

  convertUnix = event => {
    if (this.state.UnixTimeStamp === "") {
      this.setState({
        errorUnixTodate: { display: "block" },
        errorUnixTodateUTC: { display: "block" },
        classError: "input-error"
      });
    } else {
      this.setState({
        unixToDate: this.timeConverter(this.state.UnixTimeStamp, "none", "UTC"),
        unixToDateUTC: this.timeConverter(
          this.state.UnixTimeStamp,
          "none",
          "Local"
        ),
        errorUnixTodate: { display: "none" },
        errorUnixTodateUTC: { display: "none" },
        errorNoNumber: { display: "none" },
        classError: ""
      });
    }
  };
  convertDate = event => {
    if (
      this.state.DD === "" &&
      this.state.MM === "" &&
      this.state.YYYY === "" &&
      this.state.hh === "" &&
      this.state.mm === "" &&
      this.state.ss === ""
    ) {
      this.setState({
        errorDateToUnix: { display: "block" },
        classErrorDate: "input-error"
      });
    } else {
      const { DD, MM, YYYY, hh, mm, ss } = this.state;
      const { timedelta_seconds } = this.props.currentSearchReducer;
      let year, month, day, hour, minute, socend;
      if (YYYY < 1970) {
        year = 1970;
        this.setState({
          YYYY: 1970
        });
      } else {
        year = YYYY;
      }
      month = +MM < 10 ? "0" + +MM : MM;
      day = +DD < 10 ? "0" + +DD : DD;
      hour = +hh < 10 ? "0" + +hh : hh;
      minute = +mm < 10 ? "0" + +mm : mm;
      socend = +ss < 10 ? "0" + +ss : ss;

      // let msUTC;
      if (this.state.formatDate === "Local") {
        this.caslculationRequest(
          `${year}-${month}-${day}T${hour}:${minute}:${socend}`,
          timedelta_seconds
        );
      } else if (this.state.formatDate === "UTC") {
        this.caslculationRequest(
          `${year}-${month}-${day}T${hour}:${minute}:${socend}`,
          0
        );
      }
      this.setState({
        errorDateToUnix: { display: "none" },
        errorNoNumberDate: { display: "none" },
        classErrorDate: "",
        showDescriptionResult: true
      });
    }
  };

  async caslculationRequest(endFormat, deltaSec) {
    const data = await Api.userCityList(
      `time_calculation/?dt=1970-01-01T00:00:00&dt2=${endFormat}&geo_name=1261481&geo_name_2=1261481`
    );
    this.setState({
      dateToUnix: +data.data.data.delta_seconds - deltaSec,
      formatDateDescrive: this.state.formatDate === "UTC" ? "UTC" : "Local"
    });
  }

  timeConverter(UNIX_timestamp, CurrentDateTime, UTC) {
    let a =
      CurrentDateTime === "CurrentDateTime"
        ? new Date(UNIX_timestamp)
        : new Date(UNIX_timestamp * 1000);
    const { offset, timezone, name } = this.props.currentSearchReducer;

    if (UTC === "UTC") {
      let timeZona = offset ? ` ${offset}` : "";
      this.setState({ timeZona, name: name + " " });
      a = new Date(
        a.getUTCFullYear(),
        a.getUTCMonth(),
        a.getUTCDate(),
        a.getUTCHours(),
        a.getUTCMinutes(),
        a.getUTCSeconds()
      );
    } else if (UTC === "Local") {
      a =
        CurrentDateTime === "CurrentDateTime"
          ? new Date(UNIX_timestamp)
          : new Date(UNIX_timestamp * 1000);
      let test = new Date(
        a.getUTCFullYear(),
        a.getUTCMonth(),
        a.getUTCDate(),
        a.getUTCHours(),
        a.getUTCMinutes(),
        a.getUTCSeconds()
      );
      a = moment(test).tz(timezone)._d;
    }
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const monthsFull = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const daysFull = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let monthFull = monthsFull[a.getMonth()];
    let dayFull = daysFull[a.getDay()];
    let date = a.getDate();
    let dateFull = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
    let hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
    let min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
    let sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds();
    let time =
      date + " " + monthFull + " " + year + " " + hour + ":" + min + ":" + sec;
    if (CurrentDateTime === "CurrentDateTime") {
      time = `${dayFull}, ${monthFull} ${dateFull}, ${year} ${hour}:${min}:${sec}`;
      time = moment(time).format("LLLL");
      return time;
    }
    switch (this.state.formatUnix) {
      case "ddd mmm dd yyyy HH:MM:ss":
        time = `${dayFull}, ${monthFull} ${dateFull}, ${year} ${hour}:${min}:${sec}`;
        time = moment(time).format("dddd, MMMM DD YYYY, LTS");
        return time;
      case "m/d/yy":
        // eslint-disable-next-line no-case-declarations
        let str = "" + a.getFullYear();
        time = `${date}/${a.getMonth() + 1}/${str.substring(2)}`;
        return time;
      case "mmm d, yyyy":
        time = `${month} ${date}, ${year}`;
        return time;
      case "mmmm d, yyyy":
        time = `${monthFull} ${date}, ${year}`;
        return time;
      case "dddd, mmmm d, yyyy":
        time = `${dayFull}, ${monthFull} ${date}, ${year}`;
        return time;
      case "mmmm dd":
        time = `${monthFull} ${dateFull}`;
        return time;
      case "mmmm, yyyy":
        time = `${monthFull}, ${year}`;
        return time;
      default:
        return time;
    }
  }

  showDescriptionResult() {
    const { offset } = this.props.currentSearchReducer;
    if (this.state.formatDateDescrive === "UTC" && offset)
      return `Based on ${this.state.formatDateDescrive} time`;
    else if (this.state.formatDateDescrive === "Local" && offset)
      return `Based on your current date and time (UTC ${offset})`;
  }

  clearStateUnix = event => {
    this.setState({
      UnixTimeStamp: "",
      unixToDate: "",
      unixToDateUTC: "",
      errorUnixTodate: { display: "none" },
      errorUnixToDateUTC: { display: "none" },
      errorNoNumber: { display: "none" },
      classError: ""
    });
  };
  clearStateDate = event => {
    this.setState({
      DateTimeStamp: "",
      DD: "",
      MM: "",
      YYYY: "",
      hh: "",
      mm: "",
      ss: "",
      dateToUnix: "",
      errorDateToUnix: { display: "none" },
      errorNoNumberDate: { display: "none" },
      classErrorDate: "",
      showDescriptionResult: false
    });
  };

  render() {
    const { currentSearchReducer } = this.props;
    if (this.state.selectDefoult === "defaultValue") {
      this.setState({
        selectDefoult: "",
        selectDefoult2: ""
      });
    }
    return (
      <div className="unix-convector">
        <div className="header">
          <h3>Unix time converter</h3>
        </div>
        <div className="row_select_buttom row_select_buttom_top">
          <div style={{ marginLeft: "5px" }}></div>
          <button className="btn active w-197p">Unix Time Convertor</button>
          <div style={{ marginLeft: "5px" }}></div>
          <Link to={"/julian-time-converter"}>
            <button className="btn w-197p">Julian Time Convertor</button>
          </Link>
          <div style={{ marginLeft: "5px" }}></div>
          <Link to="/gps-time-converter">
            <button className="btn w-197p">GPS Time Converter</button>
          </Link>
        </div>

        <div className="row_convector">
          <div className="column1">
            <div className="Calculator">
              {/*UNIX TIME*/}
              <div className="unix-general">
                <div className="unix-column">
                  <div className="unix-head">
                    <div style={{ marginTop: "25px" }}></div>
                    <h3>Unix TimeStamp to Date and Time</h3>
                    <div style={{ marginTop: "15px" }}></div>
                    <hr />
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text ">
                      <h4>Current Unix TimeStamp</h4>
                    </div>
                    <ShowCurentUnix timezone={currentSearchReducer.timezone} />
                    <div className="button ">
                      <button
                        className="unix-use"
                        onClick={e => this.setCurrentUnix(e)}
                      >
                        use
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Enter Unix TimeStamp</h4>
                    </div>
                    <div className="input input-enter">
                      <input
                        className={this.state.classError}
                        value={this.state.UnixTimeStamp + ""}
                        onChange={this.unixTimeChange}
                      ></input>
                    </div>
                    <div className="button"></div>
                  </div>
                  <p style={this.state.errorNoNumber} className="errorUnix">
                    Invalid Date
                  </p>
                  <p style={this.state.errorUnixTodate} className="errorUnix">
                    Empty Unix TimeStamp
                  </p>

                  <div style={{ marginTop: "20px" }}></div>
                  <div className="row_select_buttom two_buttom">
                    <button className={`btn w-197p`} onClick={this.convertUnix}>
                      CONVERT
                    </button>
                    <button
                      className={`btn w-197p`}
                      onClick={this.clearStateUnix}
                    >
                      RESET
                    </button>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text">
                      <h4>Date and Time (UTC)</h4>
                    </div>
                    <div className="button"></div>
                    <div className="input-result">
                      <h4>{this.state.unixToDate}</h4>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="text ">
                      <h4>Date and Time&nbsp;</h4>
                      <h4>
                        ({currentSearchReducer.name} UTC{" "}
                        {currentSearchReducer.offset})
                      </h4>
                    </div>
                    <div className="button "></div>
                    <div className="input-result">
                      {/*<input alue={this.state.unixToDateUTC} disabled></input>*/}
                      <h4>{this.state.unixToDateUTC}</h4>
                    </div>
                  </div>
                  <div style={{ marginTop: "40px" }}></div>
                </div>
              </div>
            </div>

            <div className="Calculator">
              <div className="unix-general">
                {/*DATA TIME*/}
                <div className="unix-column">
                  <div className="unix-head">
                    <div style={{ marginTop: "25px" }}></div>
                    <h3>Date and Time to Unix TimeStamp</h3>
                    <div style={{ marginTop: "15px" }}></div>
                    <hr />
                  </div>
                  <div style={{ marginTop: "25px" }}></div>
                  <div className="unix-row text-long">
                    <div className="text">
                      <h4>Current Date and Time&nbsp;</h4>
                      <h4>
                        ({currentSearchReducer.name} UTC{" "}
                        {currentSearchReducer.offset})
                      </h4>
                    </div>
                    <ShowCurentDate timezone={currentSearchReducer.timezone} />
                    <div className="button">
                      <button
                        className="unix-use"
                        onClick={this.setCurrentDate}
                      >
                        use
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "25px" }}></div>

                  <div className="unix-row text-long text-long-height">
                    <div className="text ">
                      <h4>Enter Date and Time</h4>
                    </div>
                    <div className="input-date ">
                      <label className="placeholder_input">DD</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="DD"
                        value={this.state.DD}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">MM</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="MM"
                        value={this.state.MM}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">YYYY</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="YYYY"
                        value={this.state.YYYY}
                        onChange={this.dateTimeChange}
                        onBlur={this.dateTimeBlur}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">hh</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="hh"
                        value={this.state.hh}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">mm</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="mm"
                        value={this.state.mm}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-date">
                      <label className="placeholder_input">ss</label>
                      <input
                        className={this.state.classErrorDate}
                        placeholder="ss"
                        value={this.state.ss}
                        onChange={this.dateTimeChange}
                      ></input>
                    </div>
                    <div className="input-select">
                      <select
                        onChange={this.formatChange}
                        selected={this.state.selectDefoult2}
                        data-id-select="2"
                      >
                        <option value="Local">YOUR TIME</option>
                        <option value="UTC">UTC</option>
                      </select>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className="row_select_buttom two_buttom">
                    <button className={`btn w-197p`} onClick={this.convertDate}>
                      CONVERT
                    </button>
                    <button
                      className={`btn w-197p`}
                      onClick={this.clearStateDate}
                    >
                      RESET
                    </button>
                  </div>
                  <div style={{ marginTop: "40px" }}></div>
                  <div className="unix-row text-long-mob">
                    <div className="button empty_btn"></div>
                    <div className="text-result">
                      <h4>Unix TimeStamp</h4>
                    </div>
                    <div className="text-result">
                      <h4>{(this.state.dateToUnix + "").substring(0, 10)}</h4>
                    </div>
                    <div className="button empty_btn"></div>
                  </div>

                  <div style={{ marginTop: "20px" }}></div>
                  {this.state.showDescriptionResult && (
                    <div className="unix-row text-long-mob">
                      <div className="description-result">
                        <h5>{this.showDescriptionResult()}</h5>
                      </div>
                    </div>
                  )}

                  <div className="unix-row">
                    <div className="text">{/*<h4></h4>*/}</div>
                    <div className="input"></div>
                    <div className="button"></div>
                  </div>
                  <div style={{ marginTop: "40px" }}></div>
                </div>
              </div>
            </div>
          </div>
          <CalcSidebar />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSearchReducer: state.currentSearchReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorUnix);
