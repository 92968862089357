import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FacebookLogin from 'react-facebook-login';
import ReactLoginMS from "react-ms-login";
import { GoogleLogin } from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import './SocialButtonList.css';
import authorization from '../../../actions/authorization';
import Api from '../../../services/api.js';

class SocialButtonList extends Component {
    componentDidMount(){
        if(window.opener){
            //         window.opener.authScope.authCompletedCB(fragment);
            //         console.log('rrr close');
            //         window.close();
                }
    }
    async signInSubmit(token, url) {
        // console.log('signInSubmit url', url);
        const signIn = await Api.authorization(url, { access_token: token })
        .catch(error => {
            if(error.response.status !== 200){
                const errors = Object.values(error.response.data);
                const text = errors[0][0] === 'Unable to log in with provided credentials.' ?
                    'Please enter a correct email and password. Note that both fields may be case-sensitive.' : errors[0];
                this.setState(prevState => {
                    return {
                        errorDisplay: { display: 'block' },
                        errorText: text
                    };
                });
            }else{
                this.setState(prevState => {
                    return {
                        errorDisplay: { display: 'none' },
                        errorText: ''
                    };
                });
            }
        });
        if(signIn){
            // const { form } = this.props;
            // console.log('signIn', signIn);
            const userInfo = await Api.userInfo(
                "user-profile/",
                signIn.data.key
            );
            // console.log('userInfo', userInfo);
            authorization({ ...userInfo.data.data, token: signIn.data.key });
            // const rememberMe =  form.SignInForm.values.employed;
            // if (rememberMe === false) {
                sessionStorage.setItem(
                    "authorization",
                    JSON.stringify(userInfo.data.data)
                );
                sessionStorage.setItem("token", signIn.data.key);
/*
            } else {
                localStorage.setItem(
                    "authorization",
                    JSON.stringify(userInfo.data.data)
                );
                localStorage.setItem("token", signIn.data.key);
            }
*/
            window.location.pathname = '/';
        }
    }
    responseFacebook = (response) => {
        // console.log('Facebook', response);
        if(response.accessToken){
            this.signInSubmit(response.accessToken, 'rest-auth/facebook/')
        }
    };
    responseMicrosoft = (response) => {
        // console.log('MSLogin', response);
        if(response.access_token){
            this.signInSubmit(response.access_token, 'rest-auth/windows-live/')
        }
    };
    responseGoogle = (response) => {
        // console.log('Google', response);
        if(response.accessToken){
            this.signInSubmit(response.accessToken, 'rest-auth/google/')
        }
    };
    // logout = (social) => {
    //     if(social = 'google'){
    //         const auth2 = window.gapi.auth2.getAuthInstance();
    //         if (auth2 != null) auth2.signOut().then(this.props.onLogoutSuccess);
    //         console.log('Google logout');
    //     }
    // }

    responseLinkedIn = ({code, redirectUri}) => {
        // console.log('LinkedIn', code);
        // console.log('LinkedIn', redirectUri);
        if(code){
            // this.signInSubmit(code, 'rest-auth/linkedin/')
            this.signInSubmit(code, 'rest-auth/linked-in/')
        }
    };
    responseAmazon = (response) => {
        // Login with Amazon
        // console.log('Amazon', response);
    };
    setNodeRef (provider, node) {
      if (node) {
        this.nodes[ provider ] = node
      }
    }
    render() {
        return (
            <div className='social-login-buttons'>
                <FacebookLogin
                    // appId="425967891150892"  // 15b79ef9e57afe36de8819e57a8199dd
                    appId="636402230162915"  // c81a3b198a469d33ffca0fbdf881f641
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    cssClass="facebook-button"
                    textButton="Facebook"
                    icon="fa-facebook"
                />
                <GoogleLogin
                    // clientId="65434613855-a1cd2fmr6qclsmu7105cldj2rs57u320.apps.googleusercontent.com"
                    clientId="138240021381-cgd30aenr9kolmp5c0pinq15lpjnbusc.apps.googleusercontent.com"
                    buttonText="Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    onLogoutSuccess={this.logout}
                    className='google-button'
                    icon={true}
                />
{/*
                <ReactLoginMS
                    clientId="505c7a64-73dd-4816-906f-936719db60c0"  // ori zYC3CH1=YrOaXtq=WM[kn1Ub2RpZ-.*W
                    redirectUri={`${window.location.origin}/authComplete.html`}
                    // redirectUri="https://dev-api.geotimedate.org/rest-auth/windows-live/"
                    scopes={["user.read"]}
                    responseType="token"
                    cssClass="microsoft-button"
                    btnContent={<span>Microsoft</span>}
                    handleLogin={this.responseMicrosoft}
                    // onClick={this.microsoftClick}

                    // clientId="a157e2ad-7d43-4478-9051-541fd1b2023f" // required: 'application id/client id' obtained from https://apps.dev.microsoft.com for your app
                    // redirectUri="http://localhost:9999/authComplete.html" // required: redirectUri registered in https://apps.dev.microsoft.com for your app
                    // scopes={["user.read"]} //optional: defaults to "user.read" full list is present https://developer.microsoft.com/en-us/graph/docs/concepts/permissions_reference
                    // responseType="token" //optional: valid values are "token" for `Implicite OAuth flow` and "code" for `Authorization Code flow` defaults to "token"
                    // cssClass="some-css-class" // optional: space separated class names which are applied on the html Button element
                    // btnContent={ButtonContent} // optional: can be string or a valid react component which can be rendered inside html Button element
                    // handleLogin={(data) => console.log(data)}// required: callback to receive token/code after successful login
                />
                <button className="linkedin-button">LinkedIn</button>

                <LinkedIn
                    clientId="81tfx3txkuoku6"
                    onFailure={this.responseLinkedIn}
                    onSuccess={this.responseLinkedIn}
                    redirectUri={`${window.location.origin}/linkedin`}
                    scope="r_emailaddress w_share r_liteprofile w_member_social"
                    renderElement={({ onClick, disabled }) => (
                        <button className="linkedin-button" onClick={onClick} disabled={disabled}>LinkedIn</button>
                    )}
                    // supportIE
                    // redirectPath='/linkedin'
                />

*/}
{/*
                <LinkedIn
                    clientId="81tfx3txkuoku6"
                    onFailure={this.handleFailure}
                    onSuccess={this.handleSuccess}
                    // redirectUri={`${window.location.origin}/linkedin`}
                    redirectUri={`${window.location.origin}`}
                    scope="r_emailaddress w_share"

                    // redirectUri="http://localhost:3000"
                />
                {!code && <div>No code</div>}
                {code && <div>Code: {code}</div>}
                {errorMessage && <div>{errorMessage}</div>}
*/}



                {/*<LinkedIn*/}
                {/*    clientId='81tfx3txkuoku6' // q8l732z52lTAShBo*/}
                {/*    callback={this.callbackLinkedIn}*/}
                {/*    // callback={this.responseLinkedIn}*/}
                {/*    // className={styles.linkedin}*/}
                {/*    className='linkedin-button'*/}
                {/*    text='LinkedIn'*/}
                {/*/>*/}
                {/*<LinkedIn*/}
                {/*   clientId='868786auxwsq6e' //  eyklAg5O7awLeCH5*/}
                {/*   callback={this.responseLinkedIn}*/}
                {/*   className='linkedin-button'*/}
                {/*   text='LinkedIn'*/}
                {/*/>*/}




                {/* <adalConfig /> */}
                {/* <GoogleLogout buttonText="Google logout" onLogoutSuccess={this.logout} /> */}
                {/* <span onClick={this.logout.bind(this, 'google')}>Google logout</span> */}
                {/*<LinkedIn*/}
                    {/*clientId='868786auxwsq6e'*/}
                    {/*callback={this.responseLinkedIn}*/}
                    {/*className='linkedin-button'*/}
                    {/*text='LinkedIn'*/}
                {/*/>*/}
                {/*<SocialButton*/}
                    {/*provider='amazon'*/}
                    {/*appId='amzn1.application-oa2-client.49d24307ca444bfbb494c56da20c9401'*/}
                    {/*className='amazon-button'*/}
                    {/*onLoginSuccess={this.responseAmazon}*/}
                    {/*onLoginFailure={this.responseAmazon}*/}
                    {/*getInstance={this.setNodeRef.bind(this, 'amazon')}*/}
                    {/*key={'amazon'}*/}
                    {/*>*/}
                    {/*Login with amazon*/}
                {/*</SocialButton>*/}
                {/*<button className="linkedin-button">LinkedIn</button>*/}
                {/*<MicrosoftButton*/}
                    {/*clientId="543ee09b-301d-47ec-b2ef-adf1573855d8"*/}
                    {/*redirectUri="http://localhost:3000"*/}
                    {/*scopes={["user.read"]}*/}
                    {/*responseType="token"*/}
                    {/*cssClass="microsoft-button"*/}
                    {/*btnContent={<span>Microsoft Live</span>}*/}
                    {/*handleLogin={this.responseMicrosoft}*/}
                {/*/>*/}

                {/* <Link to='/signin/amazon' id="LoginWithAmazon">
                    <img border="0" alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width="156" height="32" />
                </Link> */}
                
          {/* <SocialButton
            provider='amazon'
            appId='amzn1.application-oa2-client.26aaf63624854cbcaa084735a0fc47ed'
            onLoginSuccess={this.onLoginSuccess}
            onLoginFailure={this.onLoginFailure}
            onLogoutSuccess={this.onLogoutSuccess}
            getInstance={this.setNodeRef.bind(this, 'amazon')}
            key={'amazon'}
          >
            Login with Amazon
          </SocialButton> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        form: state.form
    };
};
const mapDispatchToProps = dispatch => {
    return {
        authorization: bindActionCreators(authorization, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialButtonList);