import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone/index.js';

import planetesRetrograde from '../../../../../../actions/action_planetesRetrograde';
import nextPlanetRetrograde from '../../../../../../actions/action_nextPlanetRetrograde';

import Api from '../../../../../../services/api.js';

class PlanetRetrogradesLinks extends Component {
    ephemerisArr(){
        const { currentSearchReducer } = this.props;
        return currentSearchReducer.ephemeris.filter(elem => {
            return elem.name !== 'Sun' && elem.name !== 'Moon' &&
            elem.name !== 'mean Node' && elem.name !== 'true Node';
        });
    }
    currentPlanet(elem){
        const { planetesRetrogradeReducer } = this.props;
        return elem.name === planetesRetrogradeReducer.planet ? { fontWeight: 'bold', textDecoration: 'underline' } : {};
    }
    async pageNewLoad(planet) {
        const { currentSearchReducer, planetesRetrograde, planetesRetrogradeReducer, nextPlanetRetrograde } = this.props;
        const year = moment().tz('Greenwich').get('year');
        const retrogradeRequest = await Api.userCityList(`retrograde/${currentSearchReducer.id}/${year}/?p=${planet}&exclude_past=true`);
        
        planetesRetrograde({
            planet: planet,
            period: 'Next 10 Years',
            periodes:planetesRetrogradeReducer.periodes,
            retrogradeList: retrogradeRequest.data.data,
            current_zodiac: retrogradeRequest.data.current_zodiac
        });
        nextPlanetRetrograde({
            retroList: retrogradeRequest.data.next_retrograde,
            type: 'Current'
        });
    }
    clearState = () => {
        const { planetesRetrograde } = this.props;
        planetesRetrograde({
            planet: '',
            period: 'Next 10 Years',
            periodes: [],
            retrogradeList: []
        });
    }
    render() {
        const { currentSearchReducer } = this.props;
        const url = currentSearchReducer.url.split('/').slice(1).join('/');
        if(window.location.pathname.split('/')[1].split('-')[1] === 'retrograde'){
            return (
                <div className='PlanetRetrogradesSignsLinks'>
                    <div className='stations-list'>
                        {this.ephemerisArr().map((elem, index) =>
                            <Link key={index} to={`/${elem.name}-retrograde/${url}`} onClick={this.pageNewLoad.bind(this, elem.name)} style={this.currentPlanet(elem)}>                                        
                                <img src={`/${elem.body_image}`} alt={`${elem.name} astrology symbol`}/> {elem.name}
                            </Link>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className='PlanetRetrogradesSignsLinks'>
                    <div className='stations-list'>
                        {this.ephemerisArr().map((elem, index) =>
                            <Link key={index} to={`/${elem.name}-retrograde/${url}`} onClick={this.clearState}>                                        
                                <img src={`/${elem.body_image}`} alt={`${elem.name} astrology symbol`}/> {elem.name}
                            </Link>
                        )}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetesRetrogradeReducer: state.planetesRetrogradeReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        planetesRetrograde: bindActionCreators(planetesRetrograde, dispatch),
        nextPlanetRetrograde: bindActionCreators(nextPlanetRetrograde, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanetRetrogradesLinks);