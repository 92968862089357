import React, { Component } from 'react';

class Head extends Component {
    render() {
        return (
            <div className='header'>
                <h3>Live Countdown Timer</h3>
            </div>
        )
    }
}

export default Head;
