
const reducer_moonRiseTableList = (state = {
  moon_data: [],
  moon_phase: {}
}, action) => {
  switch (action.type) {
    case 'MOON_RISE_TABLE_LIST':
      return action.data;
    default:
      return state;
  }
};
  
export default reducer_moonRiseTableList;