import React, { Component } from "react";
import MetaTags from "react-meta-tags";

import CalculatorUnix from "./Calculator/CalculatorUnix";
import DescriptionText from "./DescriptionText";

import Api from "../../../../services/api.js";
import "./UnixConverter.css";

class UnixConverter extends Component {
	state = {
		// opgPicture: null
	};
	// componentDidMount() {
	// 	this.opgPicture();
	// }
	// async opgPicture() {
	// 	const opgPicture = await Api.userCityList(`opg/unix/get-url/`);
	// 	this.setState({ opgPicture: opgPicture.data.url });
	// }
	render() {
		return (
			<div className="FlightTimeCalculator">
				<MetaTags>
					<title>
						Unix Timestamp Converter – EPOCH Time Converter
					</title>
					{/*<meta*/}
					{/*	name="description"*/}
					{/*	content={`Easily convert Unix Timestamp/Epoch Time/POSIX Time to human readable date and time. Alternatively, convert time and date to Unix timestamp/Epoch Time/POSIX Time.`}*/}
					{/*/>*/}
					{/*<meta*/}
					{/*	name="keywords"*/}
					{/*	content={`unix timestamp, unix time converter, epoch time, posix time, epoch time converter, January 1 1970, unix epoch, time converter, Year 2038 problem, unix time to human readable date and time`}*/}
					{/*/>*/}
					{/*{this.state.opgPicture ? (*/}
					{/*	<meta*/}
					{/*		property="og:image"*/}
					{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
					{/*	/>*/}
					{/*) : null}*/}
				</MetaTags>

				<CalculatorUnix />
				<DescriptionText />
			</div>
		);
	}
}

export default UnixConverter;
