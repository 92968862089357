import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "react-sticky-table/dist/react-sticky-table.css";

import asideAds from "../../../../../../../assets/taboola/336-280.png";

import LocalTime from "./LocalTime";
import "./Panel.css";

const parseUrl = (sourceUrl, page) => {
	if (sourceUrl) {
		const parse = sourceUrl.split("/");
		parse[0] = `/${page}`;
		console.log("pages", parse.join("/"));
		return parse.join("/");
	} else {
		return "/";
	}
};

class Panel extends Component {
	state = {
		almanacNav: [
			{
				url: parseUrl(this.props.currentSearchReducer.url, "sun"),
				title: "Sun Map and Calculator"
			},
			{
				url: parseUrl(this.props.currentSearchReducer.url, "moon"),
				title: "Moon Movement and Phases"
			},
			{ url: "/", title: "Time Planner" },
			{
				url: "/solar-lunar-eclipses/world",
				title: "Catalog of all Solar and Lunar Eclipses (1900-2099)"
			},
			{
				url: "/#planet-stations-retrogrades",
				title: "Planet Stations and Retrogrades"
			},
			{ url: "/#planet-in-signs", title: "Planet in Zodiac Signs" },
			{
				url: parseUrl(
					this.props.currentSearchReducer.url,
					"planetary-hours"
				),
				title: "Planetary Hours"
			},
			{
				url: parseUrl(this.props.currentSearchReducer.url, "panchang"),
				title: "Detailed Panchang"
			}
		]
	};

	shouldComponentUpdate(nextProps) {
		return this.props.data !== nextProps.data;
	}

	rederLenguages(country_data) {
		const { languages } = country_data;

		let newArr = [...languages];

		newArr = newArr.filter((item, index) => {
			if (index < 4) {
				return true;
			}
		});

		newArr = newArr.map((item, index) => {
			if (index === 3) {
				return { code: " ", title: "..." };
			}
			return item;
		});

		return newArr.map((item, index) => {
			if (index < 2)
				return (
					<span key={item.title}>
						{item.title.split(" ")[0].split(";")[0]},{" "}
					</span>
				);
			else if (index <= 3)
				return (
					<span key={item.title}>
						{item.title.split(" ")[0].split(";")[0]}{" "}
					</span>
				);
		});
	}

	rederPhoneCodes(codes) {
		return codes.map((item, index) => {
			if (index === 0) return <span key={item.code}>+{item.code} </span>;
			else return <span key={item.code}>, +{item.code}</span>;
		});
	}

	render() {
		const {
			country_data,
			country_name,
			name,
			time,
			timezone
		} = this.props.data;

		return (
			<div className="panel">
				<div className="planet-signs">
					<div className="head">
						<h5>More Information about {country_name}</h5>
					</div>
					<div className="hindu">
						<div className="text">
							<p>
								<b>Capital:</b>
							</p>
							<p>
								<b>Local Time in {name}:</b>
							</p>
							<p>
								<b>Currency:</b>
							</p>
							<p>
								<b>Languages:</b>
							</p>
						</div>
						<div className="value">
							<p>
								<b>{country_data.capital}</b>
							</p>
							<p>
								<b>
									<LocalTime
										data={{
											time,
											timezone,
											format: "hh:mm A"
										}}
									/>
								</b>
							</p>
							<p>
								<b>
									{country_data.currency_name.replace(
										/_/g,
										" "
									)}{" "}
									({country_data.currency}){" "}
									{country_data.currency_symbol}
								</b>
							</p>
							<p className="code_phone">
								<b>{this.rederLenguages(country_data)}</b>
							</p>
						</div>
					</div>
				</div>

				<img src={asideAds} alt="Ads" />

				<div className="calc-sidebar-block">
					<p>Find Complete Almanac for any City</p>
					<ul>
						{this.state.almanacNav.map(item => (
							<li key={item.title}>
								<Link to={item.url}>{item.title}</Link>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentSearchReducer: state.currentSearchReducer
	};
};

export default connect(mapStateToProps)(Panel);
