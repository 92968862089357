import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from "moment-timezone/index.js";

import './SunDetailsEclipses.css';
import Loader from '../../../../components/Main/Loader/Loader';
import Search from '../../MainPage/Search/Search';
import advertising from '../../../../assets/taboola/long-ad.png'
import CityEclipses from './CityEclipses/CityEclipses';
import Countdown from './Countdown/Countdown';
import eclipsesData from '../../../../actions/action_eclipsesData';
import MetaTags from 'react-meta-tags';


import Api from '../../../../services/api.js';

class SunDetailsEclipses extends Component {
    state = {
        solarEclipse: {},
        nextEclipse: {},
        showAllPartial: false,
        showAllTotal: false,
        catalog: [],
        sunArticles: [],
        moonArticles: []
    };
    // eslint-disable-next-line react/no-deprecated
    async componentWillReceiveProps(nextProps) {
        if(nextProps.location !== this.props.location) {
            if(nextProps.location.pathname.split('/').length <= 3 && this.props.location.pathname.split('/').length > 3){
                this.setState({ catalog: [] });
                const { eclipsesData } = this.props;
                const location = window.location.pathname.split('/');
                const type = location[1].split('-')[0];
                const date = location.reverse()[0];            
                const eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/`);
                eclipsesData(eclipses.data);
                this.setState({
                    solarEclipse: type === 'solar' ? eclipses.data.solar_eclipse : eclipses.data,
                    visibleEclipse: eclipses.data.visible_eclipse,
                    nextEclipse: eclipses.data.next_visible_eclipse,
                    catalog: eclipses.data.catalog
                });
            }
        }
    }
    async componentDidMount() {
        const { eclipsesData } = this.props;
        let eclipses = {};
        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const date = location.reverse()[0];
        const url = window.location.pathname.split('/').slice(2, -1).join('__');
        const length = window.location.pathname.split('/').length;
        if (length > 3) {
            eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/?geo_id=${url}`);
            eclipsesData(eclipses.data);
        } else {
            eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/`);
            eclipsesData(eclipses.data);
        }
        
        const sunData = await Api.userCityList(`news/?limit=5&category=solar-eclipse`);
        const moonData = await Api.userCityList(`news/?limit=5&category=lunar-eclipse`);
        this.setState({
            solarEclipse: type === 'solar' ? eclipses.data.solar_eclipse : eclipses.data,
            visibleEclipse: eclipses.data.visible_eclipse,
            nextEclipse: eclipses.data.next_visible_eclipse,
            catalog: eclipses.data.catalog,
            sunArticles: sunData.data,
            moonArticles: moonData.data
        });
    }
    componentDidUpdate(prevProps){
        // eslint-disable-next-line react/no-string-refs
        const block = this.refs.countriesList;
        if (block) {
            if (window.location.hash !== '') window.scrollTo(0, block.offsetTop + block.offsetParent.offsetTop)
        }
        // if(prevProps.eclipsesDataReducer !== this.props.eclipsesDataReducer) this.opgPicture();
    }
    // async opgPicture(){
    //     const location = window.location.pathname.split('/');
    //     const type = location[1].split('-')[0];
    //     const date = location.reverse()[0];
    //     const length = window.location.pathname.split('/').length;
    //     let opgPicture;
    //     if (length > 3) {
    //         const url = window.location.pathname.split('/').slice(2, -1).join('__');
    //         if (type === 'solar') {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-4/get-url/?geo_name=${url}&date=${date}/`);
    //         } else {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-9/get-url/?geo_name=${url}&date=${date}/`);
    //         }
    //
    //     } else {
    //         if (type === 'solar') {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-3/get-url/?&date=${date}/`);
    //         } else {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-8/get-url/?&date=${date}/`);
    //         }
    //     }
    //     this.setState({ opgPicture: opgPicture.data.url})
    // }
    timeTotallEclipse(time) {
        return time === null || time === '' ? '' : `${moment(time).tz('Etc/UTC').format('LT, MMM D')}`;
    }
    durationTotallEclipse(duration) {
        return duration === null || duration === '' ? '' : duration;
    }
    timeLocalFor() {
        const { eclipsesDataReducer } = this.props;
        const tz = 'Greenwich';
        const zone = moment(eclipsesDataReducer.time).tz(tz).format('z');
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr :
            eclipsesDataReducer.country_name;
        return `${eclipsesDataReducer.name}, ${country}, ${zone}`;
    }
    nextEclipse(nextEclipse) {
        const tz = 'Greenwich';
        return moment(nextEclipse.next_solar_eclipse).tz(tz).format('ll');
    }
    zodiacPosition(elem) {
        return `${elem.body_pos_deg}${elem.body_pos_min} ${elem.zodiac_name}`;
    }
    eclipseTypeTittle() {
        const eclipseType = this.state.solarEclipse.eclipse_type.title;
        return eclipseType === 'Partial' ? 'Total' : eclipseType;
    }
    eclipseType() {
        return this.eclipseTypeTittle() === 'Hybrid' ? 'Central' : this.eclipseTypeTittle();
    }
    selectedCityEclipse() {
        const { eclipsesDataReducer } = this.props;
        const name = eclipsesDataReducer.name;
        const state = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.state_code : eclipsesDataReducer.state;
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr : eclipsesDataReducer.country_name;
        return `${name}, ${state}, ${country}`;
    }
    cityEclipse() {
        const { eclipsesDataReducer } = this.props;
        const name = eclipsesDataReducer.name;
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr : eclipsesDataReducer.country_name;
        return `${name}, ${country}, all Solar Eclipses from 1900 to 2099`;
    }
    maxCovering(val) {
        if (val > 1) return 100;
        else return (val * 100).toFixed(0)
    }
    pictureSolar() {
        const date = moment(this.state.solarEclipse.partial_eclipse_begin).format('YYYYMMMDD');
        const format = this.state.solarEclipse.eclipse_type.title.slice(0, 1);
        const param = window.location.pathname.split('/')[1] === 'solar-eclipse-country' ? 'SE' : 'LE';
        return `${param}${date}${format}`;
    }
    async eclipseList(elem, pageNumm) {
        const { eclipsesData } = this.props;
        eclipsesData({});
        this.setState({ solarEclipse: {} });
        if (pageNumm === 3) {
            let eclipses = {};
            const location = window.location.pathname.split('/');
            const type = location[1].split('-')[0];
            const date = location.reverse()[0];
            const url = elem.url;
            // const length = window.location.pathname.split('/').length;
            // if(length > 3){
            eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/?geo_id=${url}`);
            eclipsesData(eclipses.data);
            // }else{
            //     eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/`);
            //     eclipsesData(eclipses.data);
            
            // if (window.location.pathname.split('/')[1] === 'solar-eclipse-country') {
                this.setState({
                    solarEclipse: type === 'solar' ? eclipses.data.solar_eclipse : eclipses.data,
                    visibleEclipse: eclipses.data.visible_eclipse,
                    nextEclipse: eclipses.data.next_visible_eclipse,
                    catalog: eclipses.data.catalog
                });
            // }
        }

        // const periodList = ['Next 10 Years'];
        // for (let i = 1900; i < 2099; i += 10) {
        //     periodList.push(`${i} - ${i + 9}`);
        // };
        // const newPeriod = this.startPeriod(eclipses.data);
        // const eclipseInfo = await Api.userCityList(`visible_eclipse_city/${eclipses.data.id}/${type}/?period=${newPeriod.replace(/ /g, '')}&type=all`);
        // eclipsesDataTable({
        //     eclipseData: eclipseInfo.data.eclipses_data,
        //     periodList: periodList,
        //     period: periodList[0],
        //     eclipseType: 'all'
        // });

        // }else{
        //     eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/`);
        //     eclipsesData(eclipses.data);
        // }
    }
    startPeriod(eclipsesData) {
        const time = moment().tz(eclipsesData.timezone).get('year');
        return `${time} - ${time + 10}`;
    }
    eclipsePlacesList(worldList, type) {
        let firstArr = [];
        let secondArr = [];
        let thirdArr = [];
        let newArr = [];
        const sortParam = window.location.pathname.split('/').length > 3 ? 'state' : 'country';
        const newWorldList = worldList.sort((a, b) => {
            if (a[sortParam] > b[sortParam]) {
                return 1;
            }
            if (a[sortParam] < b[sortParam]) {
                return -1;
            }
            return 0;
        });
        if (document.body.offsetWidth > 1199) {
            if (newWorldList.length > 30) {
                if (type === 'partial') newArr = this.state.showAllPartial === false ? newWorldList.slice(0, 30) : newWorldList;
                else newArr = this.state.showAllTotal === false ? newWorldList.slice(0, 30) : newWorldList;
            } else newArr = newWorldList;
            const third = Math.floor(newArr.length / 3);
            let addElem;
            if (newArr.length % 3 === 1) addElem = 1;
            else if (newArr.length % 3 === 2) addElem = 1;
            else addElem = 0;
            firstArr = newArr.slice(0, third + addElem);
            secondArr = newArr.slice(third + addElem, (third + addElem) * 2);
            thirdArr = newArr.slice((third + addElem) * 2);
        } else if (document.body.offsetWidth > 769) {
            if (newWorldList.length > 30) {
                if (type === 'partial') newArr = this.state.showAllPartial === false ? newWorldList.slice(0, 20) : newWorldList;
                else newArr = this.state.showAllTotal === false ? newWorldList.slice(0, 20) : newWorldList;
            } else newArr = newWorldList;
            const third = newArr.length / 2;
            const addElem = newArr.length % 2 === 1 ? 1 : 0;
            firstArr = newArr.slice(0, third + addElem);
            secondArr = newArr.slice(third + addElem);
        } else {
            if (newWorldList.length > 30) {
                if (type === 'partial') newArr = this.state.showAllPartial === false ? newWorldList.slice(0, 10) : newWorldList;
                else newArr = this.state.showAllTotal === false ? newWorldList.slice(0, 10) : newWorldList;
            } else newArr = newWorldList;
            firstArr = newArr
        }
        return [
            {
                numm: 'first',
                arr: firstArr
            },
            {
                numm: 'second',
                arr: secondArr
            },
            {
                numm: 'third',
                arr: thirdArr
            }
        ];
    }
    solarLunarLinksBlock() {
        if (window.location.pathname.split('/')[1] === 'solar-eclipse-country') {
            return (                
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">About Solar Eclipse</h5>
                    {this.state.sunArticles.map((elem, index) =>                    
                        <div className="eclipse-info-block" key={index}>
                            <b><Link to={`/articles/sun/${elem.slug}`}>{elem.title}</Link></b>
                        </div>
                    )}
                    <b><Link to={'/articles#sun'}>...more</Link></b>
                </div>
            )
        } else {
            return (
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">About Lunar Eclipse</h5>
                    {this.state.moonArticles.map((elem, index) =>                    
                        <div className="eclipse-info-block" key={index}>
                            <b><Link to={`/articles/moon/${elem.slug}`}>{elem.title}</Link></b>
                        </div>
                    )}
                    <b><Link to={'/articles#moon'}>...more</Link></b>
                </div>
            )
        }
    }
    eclipseInfoBlocks() {
        const tz = 'Greenwich';
        if (window.location.pathname.split('/')[1] === 'solar-eclipse-country') {
            return (
                <div>
                    <div className="eclipse-info-block">
                        <b>ECL Magnitude:</b>
                        <b>{(+this.state.solarEclipse.eclipse_magnitude).toFixed(3)}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Max covered:</b>
                        <b>{this.maxCovering(+this.state.solarEclipse.max_value_covering)}%</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Partial eclipse begins:</b>
                        <b>{moment(this.state.solarEclipse.partial_eclipse_begin).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>{this.eclipseType()} eclipse begins:</b>
                        <b>{this.timeTotallEclipse(this.state.solarEclipse.full_eclipse_begin)}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Maximum eclipse:</b>
                        <b>{moment(this.state.solarEclipse.maximum_eclipse_swe).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>{this.eclipseType()} eclipse ends:</b>
                        <b>{this.timeTotallEclipse(this.state.solarEclipse.full_eclipse_end)}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Partial eclipse ends:</b>
                        <b>{moment(this.state.solarEclipse.partial_eclipse_end).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Duration of Eclipse:</b>
                        <b>{this.state.solarEclipse.central_eclipse_duration}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Duration of Partial eclipse</b>
                        <b>{this.state.solarEclipse.partial_eclipse_duration}</b>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="eclipse-info-block">
                        <b>ECL Magnitude:</b>
                        <b>{(+this.state.solarEclipse.eclipse_magnitude).toFixed(3)}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Max covered:</b>
                        <b>{this.maxCovering(+this.state.solarEclipse.eclipse_magnitude)}%</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Start of Eclipse:</b>
                        <b>{moment(this.state.solarEclipse.penumbral_eclipse_begin).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Penumbral of eclipse:</b>
                        <b>{moment(this.state.solarEclipse.penumbral_eclipse_begin).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Umbral of eclipse:</b>
                        <b>{moment(this.state.solarEclipse.partial_eclipse_begin).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Max of eclipse:</b>
                        <b>{moment(this.state.solarEclipse.maximum_eclipse_swe).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Umbral End of eclipse:</b>
                        <b>{moment(this.state.solarEclipse.partial_eclipse_end).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for Penumbral End of eclipse:</b>
                        <b>{moment(this.state.solarEclipse.penumbral_eclipse_end).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Time for End of Eclipse:</b>
                        <b>{moment(this.state.solarEclipse.penumbral_eclipse_end).tz(tz).format('LT, MMM D')}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Duration of Eclipse:</b>
                        <b>{this.state.solarEclipse.full_duration}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Duration of Penumbral Eclipse:</b>
                        <b>{this.state.solarEclipse.duration_penumbral}</b>
                    </div>
                    <div className="eclipse-info-block">
                        <b>Duration of Umbral Eclipse</b>
                        <b>{this.state.solarEclipse.duration_umbral}</b>
                    </div>
                </div>
            )
        }
    }
    solarLunarImage() {
        if (window.location.pathname.split('/')[1] === 'solar-eclipse-country') {
            return (
                <div className='eclipse-img'>
                    <img src={`/${this.state.solarEclipse.picture_url}`} alt="geotimedate" />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span className="picture-eclipse-text">Eclipse Predictions by Fred Espenak, NASA's GSFC.</span>
                </div>
            )
        } else {
            return (
                <div className='eclipse-img'>
                    <img src={`/${this.state.solarEclipse.picture_url}`} alt="geotimedate" />
                    <img src={`/${this.state.solarEclipse.map_url}`} alt="geotimedate" />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <span className="picture-eclipse-text">Eclipse Predictions by Fred Espenak, NASA's GSFC.</span>
                </div>
            )
        }

    }
    cityNameHome(city) {
        const name = city.name;
        const country = city.country_name === 'United States' ?
            city.country_name_abbr : city.country_name;
        const state = city.country_name === 'United States' ? `${city.state}, ` : '';
        return `${name}, ${state} ${country}`;
    }
    detailsLinksTitle(pageType) {
        const { eclipsesDataReducer } = this.props;
        const length = window.location.pathname.split('/').length;
        if (length > 3) {
            let cityName;
            if (window.location.pathname.split('/')[1] === 'solar-eclipse-country') cityName = eclipsesDataReducer.name;
            else cityName = eclipsesDataReducer.visible_eclipse.current_geo_name.name;
            return `Upcoming ${pageType} Eclipses in ${cityName}`;
        } else {
            return `Upcoming ${pageType} Eclipses`;
        }
    }
    detailsColor() {
        const length = window.location.pathname.split('/').length;
        if (length > 3) {
            const location = window.location.pathname.split('/');
            const type = location[1].split('-')[0];
            return type === 'solar' ? { background: '#fcf588', color: '#000' } : { background: '#4d8b88' };
        } else {
            return { background: '#4d8b88' };
        }
    }
    textChange() {
        const length = window.location.pathname.split('/').length;
        if (length > 3) {
            return 'Click on a city to see more details for this eclipse.';
        } else {
            return `Click on a country to see which states and cities of that country will see this eclipse.`;
        }
    }
    eclipseLinksUrl(elem) {
        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const date = type === 'solar' ? moment(elem.calendar_date).tz('Greenwich').format('YYYY-MM-DD') :
            moment(elem.calendar_date).tz('Greenwich').format('YYYY-MM-DD');
        const url = window.location.pathname.split('/').slice(0, -1).join('/');
        return `${url}/${date}`;
    }
    async eclipseLinksUrlDataLoad(elem) {
        const { eclipsesData } = this.props;
        this.setState({ nextEclipse: {} });
        const length = window.location.pathname.split('/').length;
        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const date = type === 'solar' ? moment(elem.calendar_date).tz('Greenwich').format('YYYY-MM-DD') :
            moment(elem.calendar_date).tz('Greenwich').format('YYYY-MM-DD');
        let eclipses;
        if (length > 3) {
            return;
            // const url = window.location.pathname.split('/').slice(2, -1).join('__');
            // eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/?geo_id=${url}`);
            // eclipsesData(eclipses.data);
        } else {
            eclipses = await Api.userCityList(`eclipses_catalog/${date}/${type}/`);
            eclipsesData(eclipses.data);
        }
        this.setState({
            solarEclipse: type === 'solar' ? eclipses.data.solar_eclipse : eclipses.data,
            visibleEclipse: eclipses.data.visible_eclipse,
            nextEclipse: eclipses.data.next_visible_eclipse,
            catalog: eclipses.data.catalog
        });
    }
    linkListRender(elemArr, indexArr, pageDetect, pageDate) {
        const length = window.location.pathname.split('/').length;
        if (length > 3) {
            return (
                <Link to={`/${pageDetect}-eclipse/${elemArr.url}/${pageDate}`}
                    className='city-name' key={indexArr} onClick={this.eclipseList.bind(this, elemArr, 4)}
                >
                    {elemArr.state} - {elemArr.name}
                </Link>
            )
        } else {
            return (
                <Link to={`/${pageDetect}-eclipse-country/${elemArr.url}/${pageDate}#cities_states`}
                    className='city-name' key={indexArr} onClick={this.eclipseList.bind(this, elemArr, 3)}
                >
                    {elemArr.country}
                </Link>
            )
        }
    }
    partialShow = () => {
        this.setState({ showAllPartial: true })
    };
    partialButtonShow() {
        return this.state.visibleEclipse.partial.length < 30 || this.state.showAllPartial === true ? { display: 'none' } : {};
    }
    totalShow = () => {
        this.setState({ showAllTotal: true })
    };
    totalButtonShow() {
        return this.state.visibleEclipse.total.length < 30 || this.state.showAllTotal === true ? { display: 'none' } : {};
    }
    eclipseTimeDetect(pageType){
        const { eclipsesDataReducer } = this.props;
        let eclipsesStart, eclipsesEnd;
        if(pageType === 'Solar'){
            eclipsesStart = +moment(eclipsesDataReducer.solar_eclipse.partial_eclipse_begin).tz('UTC').format('x');
            eclipsesEnd = +moment(eclipsesDataReducer.solar_eclipse.partial_eclipse_end).tz('UTC').format('x');
        } else {
            eclipsesStart = +moment(eclipsesDataReducer.penumbral_eclipse_begin).tz('UTC').format('x')
            eclipsesEnd = +moment(eclipsesDataReducer.penumbral_eclipse_end).tz('UTC').format('x')
        }
        const time = +moment().tz('UTC').format('x');
        if(eclipsesEnd < time) return 'was';
        else if(eclipsesStart <= time && time <= eclipsesEnd) return 'is';
        else if(time < eclipsesStart) return 'will be';
        return 'will be (was)';
    }
    metaTegsBlock() {
        const length = window.location.pathname.split('/').length;
        const type = window.location.pathname.split('/')[1].split('-')[0] === 'solar' ? 'Solar' : 'Lunar';
        const { eclipsesDataReducer } = this.props;
        const eclipseType = type === 'Solar' ? eclipsesDataReducer.solar_eclipse.eclipse_type.title : eclipsesDataReducer.eclipse_type.title;
        const eclipseDateInfo = type === 'Solar' ? eclipsesDataReducer.solar_eclipse.partial_eclipse_begin : eclipsesDataReducer.penumbral_eclipse_begin;
        const eclipseDate = moment(eclipseDateInfo).tz('UTC').format('LL');
        const eclipseZodiac = type === 'Solar' ?
            eclipsesDataReducer.solar_eclipse.zodiac_position[0].zodiac_name :
            eclipsesDataReducer.zodiac_position[1].zodiac_name;
        const deg = type === 'Solar' ?
            eclipsesDataReducer.solar_eclipse.zodiac_position[0].body_pos_deg :
            eclipsesDataReducer.zodiac_position[1].body_pos_deg;
        const min = type === 'Solar' ?
            eclipsesDataReducer.solar_eclipse.zodiac_position[0].body_pos_min :
            eclipsesDataReducer.zodiac_position[1].body_pos_min;
        const eclipseDegree = `${deg}${min}`;
        const sunMoon = eclipseType === 'Solar' ? 'sun' : 'moon';
        if (length > 3) {
            const country = eclipsesDataReducer.visible_eclipse.current_geo_name.country;
            // console.log(eclipsesDataReducer.visible_eclipse.current_geo_name.country)
            return (
                <MetaTags>
                    <title>{eclipseType} {type} Eclipse on {eclipseDate} – In which cities of {country} will it be visible</title>
                    {/*<meta name="description" content={`How to see ${eclipseDate} ${eclipseType} ${type} Eclipse in different cities of ${country}. See countdown to the next ${eclipseType} Eclipse, upcoming ${eclipseType.toLowerCase()} eclipses and eclipse path.`} />*/}
                    {/*<meta name="keywords" content={`${eclipseType.toLowerCase()} eclipse, ${sunMoon} eclipse, eclipses, ${eclipseType.toLowerCase()}, ${eclipseDate}, ${eclipseZodiac.toLowerCase()}, ${eclipseDegree}, occultation, ${eclipseType.toLowerCase()} eclipse in ${country}`} />*/}
                    {/*<meta property="og:image" content={`${Api.url}${this.state.opgPicture}`} />*/}
                </MetaTags>
            )
        } else {
            return (
                <MetaTags>
                    <title>{eclipseType} {type} Eclipse on {eclipseDate} – How to see and where will it be visible</title>
                    {/*<meta name="description" content={`${eclipseType} ${type} Eclipse on ${eclipseDate} – How to see, where will it be visible, eclipse path and when to see in local time.`} />*/}
                    {/*<meta name="keywords" content={`${eclipseType.toLowerCase()} eclipse, ${sunMoon} eclipse, eclipses, ${eclipseType}, ${eclipseDate}, ${eclipseZodiac}, ${eclipseDegree}, occultation`} />*/}
                    {/*<meta property="og:image" content={`${Api.url}${this.state.opgPicture}`} />*/}
                </MetaTags>
            )
        }
    }
    render() {
        const {currentSearchReducer} = this.props;

        if (
            !this.state.solarEclipse.calendar_date
            // || !this.state.nextEclipse.partial_eclipse_begin
            || !this.state.nextEclipse.maximum_eclipse_swe
            || !this.state.catalog.length
        ) {
            return <div><Loader/></div>;
        }

        const tz = 'Greenwich';
        const pageDetect = window.location.pathname.split('/')[1].split('-')[0];
        const pageType = `${pageDetect.charAt(0).toUpperCase()}${pageDetect.slice(1)}`;
        const pageDate = window.location.pathname.split('/').reverse()[0];
        const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        return (
            <div className='SunDetailsEclipses'>
                {this.metaTegsBlock()}
                <div className='serarch-block'>
                    <b>See the next Solar Eclipse for</b>
                    <Search />
                </div>
                <CityEclipses
                    eclipseDate={this.state.solarEclipse.calendar_date}
                    eclipseType={this.state.solarEclipse.eclipse_type}
                />
                <section className='eclipsesSolarLunar eclipsesCitySolarLunar'>
                    <div className="eclipsesSolarLunarInfo">
                        <div className="sunCulcCityInfoRightSide">
                            <div className='eclipses-details'>
                                <div className='details-block-information'>
                                    <div className='details-block'>
                                        <div className="eclipse-info-block">
                                            <b>Global Type:</b>
                                            <b>{this.state.solarEclipse.eclipse_type.title}</b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b>Time at great Eclipse:</b>
                                            <b>{moment(this.state.solarEclipse.calendar_date).tz('Etc/UTC').format('LLL')}</b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b>Saros Series:</b>
                                            <b>{this.state.solarEclipse.saros_series}</b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b>Saros number:</b>
                                            <b>{this.state.solarEclipse.saros_number}</b>
                                        </div>
                                        {this.eclipseInfoBlocks()}
                                        <div className="eclipse-info-block">
                                            <b>Zodiac position:</b>
                                            <b>
                                                <span>Sun: {this.zodiacPosition(this.state.solarEclipse.zodiac_position[0])}</span>
                                                <span>Moon: {this.zodiacPosition(this.state.solarEclipse.zodiac_position[1])}</span>
                                            </b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b>*All times are UTC</b>
                                        </div>
                                    </div>
                                </div>
                                {this.solarLunarImage()}
                            </div>
                            {/* eslint-disable-next-line react/no-string-refs */}
                            <h4 className='details-info' ref='countriesList'>
                                This {this.state.solarEclipse.eclipse_type.title} eclipse {this.eclipseTimeDetect(pageType)} visible in the following countries as:
                            </h4>
                            <div className="eclipse-info city-lists">
                                <h5 className="eclipse-info-header" style={this.detailsColor()}>{this.eclipseTypeTittle()} and Partial {pageType} Eclipse</h5>
                                <div className="eclipse-info-block eclipse-country">
                                    {this.eclipsePlacesList(this.state.visibleEclipse.total, 'total').map((elem, indexTotal, arr) =>
                                        <div className={`${elem.numm}-block`} key={indexTotal}>
                                            {elem.arr.map((elemArr, indexArr) =>
                                                this.linkListRender(elemArr, indexArr, pageDetect, pageDate)
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button className='show-more' onClick={this.totalShow} style={this.totalButtonShow()}>View More</button>
                            </div>
                            <span className='details-info'>{this.textChange()}</span>
                            <div className="eclipse-info city-lists">
                                <h5 className="eclipse-info-header" style={this.detailsColor()}>Partial {pageType} Eclipse</h5>
                                <div className="eclipse-info-block eclipse-country">
                                    {this.eclipsePlacesList(this.state.visibleEclipse.partial, 'partial').map((elem, indexPartial, arr) =>
                                        <div className={`${elem.numm}-block`} key={indexPartial}>
                                            {elem.arr.map((elemArr, indexArr) =>
                                                this.linkListRender(elemArr, indexArr, pageDetect, pageDate)
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button className='show-more' onClick={this.partialShow} style={this.partialButtonShow()}>View More</button>
                            </div>
                            <span className='details-info'>{this.textChange()}</span>
                            <Link to={`/catalog-of-all-solar-eclipses/world`} className="next-eclipse">Catalog of all Solar Eclipses Worldwide - from 1900 - 2099</Link>
                            <Link to={`/catalog-of-all-lunar-eclipses/world`} className="next-eclipse">Catalog of all Lunar Eclipses Worldwide - from 1900 - 2099</Link>
                            <div className="advertising-block-main">
                                <img src={advertising} className="advertising" alt="advertising" />
                            </div>
                        </div>
                        <div className='eclipses-img'>
                            <Countdown
                                nextEclipse={this.state.nextEclipse}
                                pageType={pageType}
                            />
                            <div className="sunCulcCityInfoRightSide">
                                <div className="eclipse-info">
                                    <div className="info-block">
                                        <h5 className="eclipse-info-header">{this.detailsLinksTitle(pageType)}</h5>
                                        {this.state.catalog.map((elem, index) =>
                                            <div className="eclipse-info-block" key={index}>
                                                <b><Link to={this.eclipseLinksUrl(elem)} onClick={this.eclipseLinksUrlDataLoad.bind(this, elem)}>
                                                    {moment(elem.calendar_date).tz(tz).format('llll')} ({elem.eclipse_type.title})
                                                </Link></b>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="eclipse-info">
                                    <div className="info-block">
                                        <h5 className="eclipse-info-header">More information<br />for {this.cityNameHome(city)}</h5>
                                        <div className="eclipse-info-block">
                                            <b><Link to={`/sun${city.url.replace('place', '')}`}>Sunrise and Sunset</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to={`/moon${city.url.replace('place', '')}`}>Moonrise and Moonset</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to='/'>Time Planer</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to='/#ephemeris-for-today'>Ephemeris for Today</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to={`/#planet-stations-retrogrades`}>Planet Stations and Retrogrades</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to={`/#planet-in-signs`}>Planet in Signs</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to={`/planetary-hours${city.url.replace('place', '')}`}>Planetary Hours</Link></b>
                                        </div>
                                        <div className="eclipse-info-block">
                                            <b><Link to='/#transits'>Transits</Link></b>
                                        </div>
                                    </div>
                                </div>
                                {this.solarLunarLinksBlock()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        eclipsesDataReducer: state.eclipsesDataReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        eclipsesData: bindActionCreators(eclipsesData, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SunDetailsEclipses);