import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone/index.js";

import defaultCityInfo from "../../../../../actions/action_currentCity/action_defaultCityInfo";
import timePlanerSwipeBlock from "../../../../../actions/action_timePlanerSwipeBlock";
import "./TimePlanerSwipeBlock.css";

class TimePlanerSwipeBlock extends Component {
  state = {
    sliderButtonPress: 0,
    sliderPosClick: 0,
    sliderResizeLeft: 0,
    sliderResizeWidth: 0,
    sliderTimeChange: true,
    sliderCurcor: "default",
    border: "1px solid #b0b1ac",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    borderCol: "1px solid rgb(253, 207, 3)",
    boxShadowCol: "rgb(253, 207, 3) 0px 1px 3px, rgb(253, 207, 3) 0px 1px 0px",
    devider: { background: "#000" },
    timePlanerOverflow: { overflowX: "hidden" },
    cursor: "default",
    timePlanerOver: false
  };
  timer = setInterval(() => {
    this.timeScrollPositionChange();
  }, 60000);

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidMount() {
    if (this.props.defaultStock) {
      this.timeScrollPositionChange(true);
    } else {
      this.timeScrollPositionChange(false);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate(nextProps) {
    const { defaultStock } = this.props;
    if (
      nextProps.defaultStock &&
      nextProps.defaultStock.name !== defaultStock.name
    ) {
      this.timeScrollPositionChange(true);
    }
  }

  timeScrollPositionChange(newDefaultCity) {
    const {
      timePlanerSwipeBlock,
      selectedCitiesReducer,
      defaultStock,
      selectCity
    } = this.props;
    const cities =
      newDefaultCity && selectCity
        ? [selectCity]
        : selectedCitiesReducer.cities;
    let cityTimeNoFormat = newDefaultCity
      ? moment().tz(defaultStock.timezone)
      : moment().tz(cities[0].timezone);

    const cityHours = cityTimeNoFormat.format("H");
    const cityMinutes = cityTimeNoFormat.format("mm");

    // eslint-disable-next-line react/no-string-refs
    const blockRight = this.refs.sliderBlockWrapper;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    if (blockRight && sliderBlock) {
      const newBlock = blockRight.offsetWidth;
      const step = newBlock / 96; // 15 min step
      const cityTime = Math.floor((+cityHours * 60 + +cityMinutes) / 15); // minutesNow / 15min - quantity of 15 min steps
      this.setState({
        sliderInitPosition: {
          width: `${step}px`,
          left: `${step * cityTime}px`,
          border: "transparent",
          boxShadow: "none"
        },
        devider: { background: "#000" }
      });

      timePlanerSwipeBlock({
        width: `${step}px`,
        left: `${step * cityTime}px`,
        border: "transparent",
        boxShadow: "none"
      });
    }
  }

  sliderMouseMove(action, e) {
    if (document.body.offsetWidth < 992 && action === "mouse") return;
    if (document.body.offsetWidth < 992 && this.state.sliderButtonPress === 0)
      return;
    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    if (blockWrapper && sliderBlock) {
      const client = e.touches ? e.touches[0].clientX : e.clientX;
      const newBlock = blockWrapper.offsetWidth;
      const slideWidth = newBlock / 96;
      const wrapperPosition =
        client - blockWrapper.getBoundingClientRect().left;
      const newBlockPosition = Math.floor(wrapperPosition / slideWidth);
      const positionPX = slideWidth * newBlockPosition;

      this.setState({ timePlanerOver: true });
      const { timePlanerSwipeBlock } = this.props;
      const blockPosition = Math.floor(wrapperPosition / slideWidth);
      const blockWidth = wrapperPosition - this.state.sliderPosClick;

      const sliderPositionState = (left, width, sliderCase) => {
        const border =
          sliderCase === "default" ? "transparent" : this.state.borderCol;
        const boxShadow =
          sliderCase === "default" ? "none" : this.state.boxShadowCol;
        timePlanerSwipeBlock({
          width: width,
          left: left,
          border: border,
          boxShadow: boxShadow
        });
      };
      switch (this.state.sliderButtonPress) {
        // slider moove
        case 1:
          this.setState({ devider: { background: "transparent" } });
          if (
            (wrapperPosition < 0 ||
              client - 15 + 5 >=
                blockWrapper.offsetLeft + blockWrapper.offsetWidth) &&
            document.body.offsetWidth < 992
          ) {
            return timePlanerSwipeBlock(null);
          } else if (
            (wrapperPosition < 0 ||
              client >
                blockWrapper.getBoundingClientRect().left +
                  blockWrapper.offsetWidth) &&
            document.body.offsetWidth >= 992
          ) {
            return timePlanerSwipeBlock(null);
          } else if (blockWidth > 0) {
            const blockFactor = Math.ceil(blockWidth / slideWidth);
            return sliderPositionState(
              `${this.state.sliderPosClick}px`,
              `${blockFactor * slideWidth}px`,
              1
            );
          } else if (blockWidth < 0) {
            const blockFactor = Math.ceil((blockWidth * -1) / slideWidth);
            return sliderPositionState(
              `${slideWidth * blockPosition}px`,
              `${blockFactor * slideWidth + slideWidth}px`,
              1
            );
          } else {
            return sliderPositionState(
              `${this.state.sliderPosClick}px`,
              `${slideWidth}px`,
              1
            );
          }
        // slider selected time
        case 2:
          // eslint-disable-next-line react/no-string-refs,no-case-declarations
          const leftBorder = sliderBlock.offsetLeft;
          // eslint-disable-next-line react/no-string-refs,no-case-declarations
          const rightBorder = sliderBlock.offsetLeft + sliderBlock.offsetWidth;
          if (
            (wrapperPosition >= leftBorder - 15 &&
              wrapperPosition <= leftBorder + 15) ||
            (wrapperPosition <= rightBorder + 15 &&
              wrapperPosition >= rightBorder - 15)
          ) {
            return this.setState({
              sliderCurcor: "e-resize"
            });
          } else {
            return this.setState({
              sliderCurcor: "default"
            });
          }
        // slider resize right
        case 3:
          this.setState({ devider: { background: "transparent" } });
          if (wrapperPosition < 0) return timePlanerSwipeBlock(null);
          else if (
            this.state.sliderPosClick - 1 >=
            this.state.sliderResizeLeft
          ) {
            const resizeFactor = Math.ceil(
              (wrapperPosition - this.state.sliderResizeLeft) / slideWidth
            );
            if (resizeFactor <= 0) {
              const newResizeFactor = Math.ceil(
                (wrapperPosition - slideWidth) / slideWidth
              );
              const width = `${this.state.sliderResizeLeft +
                slideWidth -
                newResizeFactor * slideWidth}px`;
              const left = `${newResizeFactor * slideWidth}px`;
              return sliderPositionState(left, width, 3);
            } else
              return sliderPositionState(
                `${this.state.sliderResizeLeft}px`,
                `${resizeFactor * slideWidth}px`,
                3
              );
          } else if (blockWidth > 0) {
            const blockFactor = Math.ceil(blockWidth / slideWidth);
            return sliderPositionState(
              `${this.state.sliderPosClick}px`,
              `${blockFactor * slideWidth}px`,
              3
            );
          } else if (blockWidth < 0) {
            const blockFactor = Math.ceil((blockWidth * -1) / slideWidth);
            return sliderPositionState(
              `${slideWidth * blockPosition}px`,
              `${blockFactor * slideWidth + slideWidth}px`,
              3
            );
          } else {
            return sliderPositionState(
              `${this.state.sliderResizeLeft}px`,
              `${slideWidth}px`,
              3
            );
          }
        // slider resize left
        case 4:
          // eslint-disable-next-line react/no-string-refs,no-case-declarations
          const prevWidth = this.state.sliderResizeWidth;
          if (wrapperPosition < 0) {
            timePlanerSwipeBlock(null);
            return this.setState({
              devider: { background: "transparent" }
            });
          } else if (wrapperPosition >= prevWidth - slideWidth) {
            const resizeWidth = wrapperPosition - (prevWidth - slideWidth);
            const resizeFactor = Math.ceil(resizeWidth / slideWidth);
            return sliderPositionState(
              `${prevWidth - slideWidth}px`,
              `${resizeFactor * slideWidth}px`,
              4
            );
          } else
            return sliderPositionState(
              `${slideWidth * blockPosition}px`,
              `${prevWidth - blockPosition * slideWidth}px`,
              4
            );
        default:
          this.setState({ devider: { background: "#000" } });
          if (wrapperPosition >= blockWrapper.offsetWidth - slideWidth) {
            return sliderPositionState(
              `${blockWrapper.offsetWidth - slideWidth}px`,
              `${slideWidth}px`,
              "default"
            );
          } else if (wrapperPosition <= slideWidth) {
            return sliderPositionState(`${0}px`, `${slideWidth}px`, "default");
          } else {
            return sliderPositionState(
              `${positionPX}px`,
              `${slideWidth}px`,
              "default"
            );
          }
      }
    }
  }

  sliderMouseDown(action, e) {
    if (e.button === 1 || e.button === 2) return;
    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;

    if (blockWrapper && sliderBlock) {
      const client = e.touches ? e.touches[0].clientX : e.clientX;
      const wrapperPosition =
        client - blockWrapper.getBoundingClientRect().left;

      const timerStart = () => {
        this.sliderClick = setTimeout(() => {
          this.setState({ timePlanerTimer: false });
        }, 150);
        this.setState({
          timePlanerTimer: true,
          client: client
        });
      };

      switch (this.state.sliderButtonPress) {
        case 0:
          timerStart();
          break;
        case 2:
          // eslint-disable-next-line react/no-string-refs,no-case-declarations
          const leftBorder = sliderBlock.offsetLeft;
          // eslint-disable-next-line react/no-string-refs,no-case-declarations
          const rightBorder = sliderBlock.offsetLeft + sliderBlock.offsetWidth;
          if (
            (e.target.parentNode.className === "slider-block-wrapper" ||
              e.target.parentNode.className === "slider-block") &&
            wrapperPosition <= rightBorder + 15 &&
            wrapperPosition >= rightBorder - 15
          ) {
            this.props.timePlanerSwipe({ overflowX: "hidden" });
            this.setState({
              sliderButtonPress: 3,
              sliderResizeLeft: sliderBlock.offsetLeft
            });
          } else if (
            (e.target.parentNode.className === "slider-block-wrapper" ||
              e.target.parentNode.className === "slider-block") &&
            wrapperPosition >= leftBorder - 15 &&
            wrapperPosition <= leftBorder + 15
          ) {
            this.props.timePlanerSwipe({ overflowX: "hidden" });
            this.setState({
              sliderButtonPress: 4,
              sliderResizeWidth:
                sliderBlock.offsetLeft + sliderBlock.offsetWidth
            });
          }
          break;
        default:
          break;
      }
    }
  }

  sliderMouseUp(action, e) {
    if (e.button === 1 || e.button === 2) return;
    const { timePlanerSwipeBlock } = this.props;

    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    if (blockWrapper && sliderBlock) {
      const client = this.state.client;
      const wrapperPosition =
        client - blockWrapper.getBoundingClientRect().left;
      const newBlock = blockWrapper.offsetWidth;
      const blockPosition = Math.floor(wrapperPosition / (newBlock / 96));

      switch (this.state.sliderButtonPress) {
        case 1:
          timePlanerSwipeBlock(null);
          return this.setState({ sliderButtonPress: 2 });
        case 2:
          timePlanerSwipeBlock(null);
          return this.setState({ sliderButtonPress: 2 });
        case 3:
          timePlanerSwipeBlock(null);
          return this.setState({ sliderButtonPress: 2 });
        case 4:
          timePlanerSwipeBlock(null);
          return this.setState({ sliderButtonPress: 2 });
        default:
          timePlanerSwipeBlock({
            width: newBlock / 96,
            left: blockPosition * (newBlock / 96),
            border: this.state.borderCol,
            boxShadow: this.state.boxShadowCol
          });
          this.setState({
            sliderButtonPress: 2,
            sliderPosClick: blockPosition * (newBlock / 96),
            devider: { background: "transparent" }
          });

          break;
      }
    }
  }

  sliderMouseLeave = e => {
    const { timePlanerSwipeBlock } = this.props;
    this.setState({ timePlanerOver: false });
    switch (this.state.sliderButtonPress) {
      case 1:
        timePlanerSwipeBlock(null);
        return this.setState({ sliderButtonPress: 2 });
      case 2:
        timePlanerSwipeBlock(null);
        return this.setState({ sliderButtonPress: 2 });
      case 3:
        timePlanerSwipeBlock(null);
        return this.setState({ sliderButtonPress: 2 });
      case 4:
        timePlanerSwipeBlock(null);
        return this.setState({ sliderButtonPress: 2 });
      default:
        timePlanerSwipeBlock(this.state.sliderInitPosition);
        return this.setState({
          sliderButtonPress: 0,
          sliderTimeChange: true
        });
    }
  };

  sliderBlockWrapperStyle() {
    return document.body.offsetWidth < 992 ? "calc(100% - 40px)" : "100%";
  }

  sliderBlockStyleStart() {
    const { timePlanerSwipeBlockReducer } = this.props;
    const bgColor =
      this.state.sliderButtonPress !== 0
        ? "rgba(255, 255, 255, 0.4)"
        : "transparent";
    return { width: timePlanerSwipeBlockReducer.left, background: bgColor };
  }

  sliderBlockStyleEnd() {
    const { timePlanerSwipeBlockReducer } = this.props;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;
    if (sliderBlock && blockWrapper && timePlanerSwipeBlockReducer.left) {
      const sliderPos = (timePlanerSwipeBlockReducer.left + "").replace(
        "px",
        ""
      );
      const positionWidth = sliderBlock.offsetWidth;
      const positionLeft = +sliderPos + positionWidth;
      const bgColor =
        this.state.sliderButtonPress !== 0
          ? "rgba(255, 255, 255, 0.4)"
          : "transparent";
      return {
        left: positionLeft,
        width: Math.round(blockWrapper.offsetWidth) - Math.round(positionLeft), //positionWidth - positionLeft,
        background: bgColor
      };
    }
  }
  timeCalc(elem, param, signPageCities) {
    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    const { selectedCitiesReducer, defaultStock, selectCity } = this.props;
    const cities = signPageCities
      ? signPageCities
      : defaultStock && selectCity
      ? [selectCity]
      : selectedCitiesReducer.cities;
    if (blockWrapper && sliderBlock) {
      const fifteenMinStep = blockWrapper.offsetWidth / 96; // width of 15 min step

      let blockNummStart, blockNummEnd;
      if (
        this.state.timePlanerOver === false &&
        this.state.sliderButtonPress === 0
      ) {
        const { sliderInitPosition } = this.state;
        blockNummStart = Math.round(sliderInitPosition.left / fifteenMinStep);
        blockNummEnd = Math.round(
          (sliderInitPosition.left + sliderInitPosition.width) / fifteenMinStep
        );
      } else {
        blockNummStart = Math.round(sliderBlock.offsetLeft / fifteenMinStep);
        blockNummEnd = Math.round(
          (sliderBlock.offsetLeft + sliderBlock.offsetWidth) / fifteenMinStep
        );
      }

      const tz = elem.timezone;

      const timeConvert = num => {
        const hours = num / 60;
        const rHours = Math.floor(hours) + "";
        const minutes = (hours - rHours) * 60;
        const rMinutes = Math.round(minutes) + "";
        return {
          h: rHours.padStart(2, "0"),
          m: rMinutes.padStart(2, "0")
        };
      };
      const timeCreator = (block, date, homeTZ, currentTZ) => {
        let hM = { h: "00", m: "00" };
        if (block || block === 0) {
          hM = timeConvert(block * 15);
        } else {
          const t = moment().tz(homeTZ);
          hM = { h: t.format("HH"), m: t.format("mm") };
        }

        const timezone = moment(this.props.calendarDate)
          .tz(homeTZ)
          .format(`Z`);
        const timeNoFormat = moment(this.props.calendarDate).format(
          `YYYY-MM-DDT${hM.h}:${hM.m}:ss${timezone}`
        );
        return moment(timeNoFormat).tz(currentTZ);
      };
      const start = timeCreator(
        blockNummStart,
        this.props.calendarDate,
        cities[0].timezone,
        tz
      );
      const end = timeCreator(
        blockNummEnd,
        this.props.calendarDate,
        cities[0].timezone,
        tz
      );

      const difference = end.diff(start);
      const days = end.diff(start, "days");
      const hours = Math.floor((difference - days * 86400000) / 3600000);
      const minutes = Math.floor(
        (difference - days * 86400000 - hours * 3600000) / 60000
      );

      const dateInFirstCity = start.clone().tz(cities[0].timezone);

      const a = moment([start.year(), start.month(), start.date()]);
      const b = moment([
        dateInFirstCity.year(),
        dateInFirstCity.month(),
        dateInFirstCity.date()
      ]);
      const dayDifferenceValue = a.diff(b, "days");

      let dayDifference = "";
      if (dayDifferenceValue !== 0) {
        dayDifference = `(${
          dayDifferenceValue > 0 ? "+" : ""
        }${dayDifferenceValue})`;
      }
      return param === "difference"
        ? `${hours}h ${minutes}m`
        : [`${start.format("LT")}${dayDifference}`, end.format("LT")];
    } else return [];
  }

  timeCalcStyle(index) {
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    // eslint-disable-next-line react/no-string-refs
    const blockWrapper = this.refs.sliderBlockWrapper;

    let topDist = index * (10 + 38 + 10) - 10; // 38-ширина блока 10- отступы*2

    if (sliderBlock && blockWrapper) {
      if (this.state.sliderButtonPress === 0) {
        if (index === "clear") return { display: "none" };
        return sliderBlock.offsetLeft > blockWrapper.offsetWidth / 2
          ? { top: topDist, right: "125%", textAlign: "right" }
          : { top: topDist, left: "5px" };
      } else if (sliderBlock.offsetWidth < 75) {
        if (sliderBlock.offsetLeft > blockWrapper.offsetWidth / 2) {
          return index === "clear" ? { right: "105%" } : { display: "none" };
        } else
          return index === "clear" ? { left: "105%" } : { display: "none" };
      } else
        return index === "clear"
          ? { top: "5px", right: "5px" }
          : { top: topDist, left: "5px" };
    } else
      return index === "clear"
        ? { top: "5px", right: "5px" }
        : { top: topDist, left: "5px" };
  }

  timeCalcStyleRight(indexTime) {
    // eslint-disable-next-line react/no-string-refs
    const sliderBlock = this.refs.sliderBlock;
    if (sliderBlock) {
      switch (indexTime) {
        case 0:
          if (this.state.sliderButtonPress === 0) return {};
          else if (sliderBlock.offsetWidth < 75) return { display: "none" };
          else return {};
        case 1:
          if (this.state.sliderButtonPress === 0) return { display: "none" };
          else if (sliderBlock.offsetWidth < 120) return { display: "none" };
          else {
            return {};
          }
        case 2:
          if (this.state.sliderButtonPress === 0) return { display: "none" };
          else if (sliderBlock.offsetWidth < 100) return { display: "none" };
          else return {};
        default:
          return {};
      }
    }
  }

  sliderMouseOver = e => {
    if (this.state.sliderButtonPress !== 0) {
      // eslint-disable-next-line react/no-string-refs
      const blockWrapper = this.refs.sliderBlockWrapper;
      // eslint-disable-next-line react/no-string-refs
      const sliderBlock = this.refs.sliderBlock;
      if (blockWrapper && sliderBlock) {
        const client = e.touches ? e.touches[0].clientX : e.clientX;
        const wrapperPosition =
          client - blockWrapper.getBoundingClientRect().left;
        const leftBorder = sliderBlock.offsetLeft;
        const rightBorder = sliderBlock.offsetLeft + sliderBlock.offsetWidth;
        if (
          (e.target.parentNode.className === "slider-block-wrapper" ||
            e.target.parentNode.className === "slider-block") &&
          wrapperPosition <= rightBorder + 15 &&
          wrapperPosition >= rightBorder - 15
        ) {
          return this.setState({ cursor: "e-resize" });
        } else if (
          (e.target.parentNode.className === "slider-block-wrapper" ||
            e.target.parentNode.className === "slider-block") &&
          wrapperPosition >= leftBorder - 15 &&
          wrapperPosition <= leftBorder + 15
        ) {
          return this.setState({ cursor: "e-resize" });
        } else {
          return this.setState({ cursor: "default" });
        }
      } else {
        return this.setState({ cursor: "default" });
      }
    } else {
      return this.setState({ cursor: "default" });
    }
  };
  spaceClear = () => {
    const { timePlanerSwipeBlock } = this.props;
    timePlanerSwipeBlock(this.state.sliderInitPosition);
    this.setState({
      sliderButtonPress: 0,
      devider: { background: "#000" },
      border: "transparent",
      boxShadow: "none"
    });
  };
  render() {
    const {
      timePlanerSwipeBlockReducer,
      selectedCitiesReducer,
      setStockExchangeCities,
      defaultStock,
      selectCity
    } = this.props;

    const cities =
      defaultStock && selectCity ? [selectCity] : selectedCitiesReducer.cities;

    return (
      <div
        className="slider-block-wrapper"
        onMouseLeave={this.sliderMouseLeave}
        onMouseMove={this.sliderMouseMove.bind(this, "mouse")}
        onMouseDown={this.sliderMouseDown.bind(this, "mouse")}
        onMouseUp={this.sliderMouseUp.bind(this, "mouse")}
        onTouchMove={this.sliderMouseMove.bind(this, "touch")}
        onTouchStart={this.sliderMouseDown.bind(this, "touch")}
        onTouchEnd={this.sliderMouseUp.bind(this, "touch")}
        ref={`sliderBlockWrapper`}
        style={{
          width: this.sliderBlockWrapperStyle(),
          cursor: this.state.cursor
        }}
      >
        <div
          className="slider-block-start"
          style={this.sliderBlockStyleStart()}
        />
        <div
          className="slider-block"
          style={timePlanerSwipeBlockReducer}
          ref={`sliderBlock`}
        >
          <span className="devider" style={this.state.devider} />
          <i
            className="material-icons"
            style={this.timeCalcStyle("clear")}
            onClick={this.spaceClear}
          >
            clear
          </i>
          {!setStockExchangeCities &&
            cities.map((elem, index) => (
              <div
                className="time"
                key={index}
                style={this.timeCalcStyle(index + 1)}
              >
                {this.timeCalc(elem).map((elemTime, indexTime) => (
                  <span
                    key={indexTime}
                    style={this.timeCalcStyleRight(indexTime)}
                  >
                    {elemTime}
                  </span>
                ))}
              </div>
            ))}
          {setStockExchangeCities &&
            setStockExchangeCities.map((elem, index, array) => (
              <div
                className="time"
                key={index}
                style={this.timeCalcStyle(index + 1)}
              >
                {this.timeCalc(elem, null, array).map((elemTime, indexTime) => (
                  <span
                    key={indexTime}
                    style={this.timeCalcStyleRight(indexTime)}
                  >
                    {elemTime}
                  </span>
                ))}
              </div>
            ))}
          <span className="time-diff" style={this.timeCalcStyleRight(2)}>
            {this.timeCalc(cities[0], "difference")}
          </span>
        </div>
        <div className="slider-block-end" style={this.sliderBlockStyleEnd()} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultCityInfoReducer: state.defaultCityInfoReducer,
    selectedCitiesReducer: state.selectedCitiesReducer,
    timePlanerSwipeBlockReducer: state.timePlanerSwipeBlockReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    defaultCityInfo: bindActionCreators(defaultCityInfo, dispatch),
    timePlanerSwipeBlock: bindActionCreators(timePlanerSwipeBlock, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimePlanerSwipeBlock);
