import React, { Component } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";

class Meta extends Component {
	state = {
		opgPicture: null
	};

	// componentDidUpdate(prevProps) {
	// 	if (prevProps.sunCalcReducer !== this.props.sunCalcReducer) {
	// 		this.opgPicture();
	// 	}
	// }

	metaCityName(nameType) {
		const { sunCalcReducer } = this.props;
		const urlCity = window.location.pathname.split("/")[4];
		const parsedUrlCity =
			urlCity.charAt(0).toUpperCase() +
			urlCity.slice(1).replace(/-/g, " ");

		const urlCountry = window.location.pathname.split("/")[2];
		const parsedUrlCountry =
			urlCountry.charAt(0).toUpperCase() +
			urlCountry.slice(1).replace(/-/g, " ");

		const name = sunCalcReducer.name ? sunCalcReducer.name : parsedUrlCity;
		const country = sunCalcReducer.country_name
			? sunCalcReducer.country_name === "United States"
				? sunCalcReducer.country_name_abbr
				: sunCalcReducer.country_name
			: parsedUrlCountry === "usa"
			? "USA"
			: parsedUrlCountry;

		let state = nameType === "title" ? "" : `${sunCalcReducer.state},`;
		switch (nameType) {
			case "title":
				return ` ${name}, ${state} ${country}`;
			case "description":
				return ` ${name}, ${country}`;
			case "keywords":
				return `${name}, `;
			default:
				return "";
		}
	}

	// async opgPicture() {
	// 	const { sunCalcReducer } = this.props;
	// 	if (sunCalcReducer.name) {
	// 		const newId = sunCalcReducer.url
	// 			.replace("place/", "")
	// 			.replace(/\//g, "__");
	// 		const opgPicture = await Api.userCityList(
	// 			`opg/sun/get-url/?geo_name=${newId}`
	// 		);
	// 		this.setState({ opgPicture: opgPicture.data.url });
	// 	}
	// }

	render() {
		return (
			<MetaTags>
				<title>
					Sunrise, sunset, solar noon, day length and sun map for
					{this.metaCityName("title")}
				</title>
				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content={`Calculate sunrise, sunset, solar noon, day length, solar eclipse, shadow length and twilight for${this.metaCityName(*/}
				{/*		"description"*/}
				{/*	)}. Online interactive map with sun movement, sun location and get monthly sun data for${this.metaCityName(*/}
				{/*		"description"*/}
				{/*	)}.`}*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	name="keywords"*/}
				{/*	content={`sunrise in ${this.metaCityName(*/}
				{/*		"keywords"*/}
				{/*	)}sunset in ${this.metaCityName(*/}
				{/*		"keywords"*/}
				{/*	)}day length, solar noon, shadow length, sun almanac, solar eclipse, sun movement, sunlight phases, sun calculator, sun map, sun position`}*/}
				{/*/>*/}
				{/*{this.state.opgPicture ? (*/}
				{/*	<meta*/}
				{/*		property="og:image"*/}
				{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
				{/*	/>*/}
				{/*) : null}*/}
			</MetaTags>
		);
	}
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer
	};
};

export default connect(mapStateToProps)(Meta);
