const key_state = 'state';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(key_state);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key_state, serializedState);
    } catch (e) {
        // console.log(e);
        // ignore write errors
    }
};
