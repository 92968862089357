import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';
import moment from "moment-timezone/index.js";

import CityName from "../CityName/CityName";
import Search from "../../../../../MainPage/Search/Search";

class HeadHolidays extends Component {
  cityName() {
    const { currentSearchReducer } = this.props;
    const name = currentSearchReducer.name;
    const country =
      currentSearchReducer.country_name === "United States"
        ? currentSearchReducer.country_name_abbr
        : currentSearchReducer.country_name;
    return `Holidays in ${name}, ${country} in ${moment
      .tz(currentSearchReducer.timezone)
      .format("YYYY")}`;
  }
  geotimedateTable() {
    if (document.body.offsetWidth > 1199) {
      return this.state.retrogradeList.length * 35 + 47 + 48;
    } else
      return document.body.offsetHeight < 1260
        ? document.body.offsetHeight
        : 1260;
  }
  render() {
    const { currentSearchReducer } = this.props;
    return (
      <div className="Retrograde">
        <div className="serarch-block">
          <Search />
        </div>
        <CityName>
          <div className="country_flag">
            <img
              className="cityMainImg"
              src={`/static/flags/flags-iso/flat/64/${currentSearchReducer.country_code}.png`}
              alt="citydateandtime"
            />
          </div>
          <div className="city_name_date">
            <h4>{this.cityName()}</h4>
          </div>
        </CityName>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
    weatherInfoReducer: state.weatherInfoReducer,
  };
};
export default connect(mapStateToProps)(HeadHolidays);
