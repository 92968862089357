
const moonRisePositionReducer = (state = {
  utc_time: "2017-10-17T04:00:59.999999Z",
  loc_time: "2017-10-17T00:00:59.999999-04:00",
  alt: -0.9565995931625366,
  az: 5.9318413734436035,
  dist: 149097178.59258798,
  xy: [0.4107469304277523, 0.2565065568583342],
  shadow: 1
}, action) => {
  switch (action.type) {
    case 'MOON_RISE_POSITION':
      return !action.data ? state : action.data;
    default:
      return state;
  }
};
  
export default moonRisePositionReducer;