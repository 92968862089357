import React, {Component} from 'react';

import '../../UnixConverter/Calculator/Calculator.css';

class SimpleRadioBtn extends Component {

    searchOption = (elem) =>{
        const {select} = this.props;
        select({name: elem.target.name, value: elem.target.value})
    };

     setChecked = item => {
        const {selectOption} = this.props;
        return item.name === selectOption.name ? "checked": '';
    };

    render() {
        const {dicorat, options} = this.props;
        const listRender = options.map( (item, index) =>
            <div key={index} style={{height: 35 + 'px', lineHeight: 3 + 'px'}} >
                <input type="radio"  id={`${item.name}${index}`} name={item.name} onChange={this.searchOption} value={item.value} checked={this.setChecked(item)}/>
                <label htmlFor={`${item.name}${index}`}>{item.name}</label>
            </div>
        );

        return (
            <div className="center-on-page" style={{flexDirection: dicorat}}>
                {listRender}
            </div>
        )
    }
}


export default SimpleRadioBtn;

//     height: 40px;
//     margin-right: 12px;
