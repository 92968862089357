export default [
    {
        day: 'Saturday',
        name: 'Saturn'
    },
    {
        day: 'Thursday',
        name: 'Jupiter'
    },
    {
        day: 'Tuesday',
        name: 'Mars'
    },
    {
        day: 'Sunday',
        name: 'Sun'
    },
    {
        day: 'Friday',
        name: 'Venus'
    },
    {
        day: 'Wednesday',
        name: 'Mercury'
    },
    {
        day: 'Monday',
        name: 'Moon'
    }
];