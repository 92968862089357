import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import validate from "./validate";

class ContactFormValidation extends Component {
	handleCaptcha = value => {
		// console.log(value);
		this.props.handleCaptcha(value);
	};

	renderTextField = ({
		input,
		label,
		meta: { touched, error },
		...custom
	}) => (
		<TextField
			floatingLabelText={label}
			errorText={touched && error}
			fullWidth
			{...input}
			{...custom}
		/>
	);

	renderTextAreaField = ({
		input,
		label,
		meta: { touched, error },
		...custom
	}) => (
		<TextField
			floatingLabelFixed
			floatingLabelText={label}
			errorText={touched && error}
			fullWidth
			{...input}
			{...custom}
		/>
	);

	render() {
		const { handleSubmit } = this.props;
		return (
			<div>
				<form className="conctact-form" onSubmit={handleSubmit}>
					<SelectField
						className="contact-form__input"
						floatingLabelFixed
						fullWidth
						floatingLabelText="How can we help you?*"
						hintText="Select"
						value={this.props.reason}
						onChange={(e, i, value) =>
							this.props.handleReasonSelect(value)
						}
					>
						<MenuItem
							value="General Question"
							primaryText="General Question"
						/>
						<MenuItem value="Feedback" primaryText="Feedback" />
						<MenuItem
							value="Deletion of account"
							primaryText="Deletion of account"
						/>
						<MenuItem
							value="Business Development"
							primaryText="Business Development"
						/>
						<MenuItem
							value="Technical Support"
							primaryText="Technical Support"
						/>
						<MenuItem value="Refund" primaryText="Refund" />
					</SelectField>

					<Field
						className="contact-form__input"
						name="firstName"
						component={this.renderTextField}
						label="First Name*"
					/>

					<Field
						className="contact-form__input"
						name="lastName"
						component={this.renderTextField}
						label="Last Name*"
					/>

					<Field
						className="contact-form__input"
						name="email"
						component={this.renderTextField}
						label="Email*"
					/>

					<Field
						className="contact-form__input"
						name="message"
						component={this.renderTextAreaField}
						label="Questions or comments*"
						hintText=""
						multiLine
						rows={4}
						rowsMax={10}
					/>
					{/*6LeftsMUAAAAAM7MdQDeJmC0FwtSlpLwduNejTHR  html*/}
					{/*6LeftsMUAAAAAEl8nHesouB2DdUNbccqEs5_NiuV  site*/}
					<div className="contact-form__captcha">
						<ReCAPTCHA
							sitekey="6LeftsMUAAAAAM7MdQDeJmC0FwtSlpLwduNejTHR"
							onChange={this.handleCaptcha}
						/>
					</div>

					<button className="contact__button" type="submit">
						Submit
					</button>
				</form>

				{this.props.ok ? (
					<div className="contact_ok">
						Thank you for contacting us. We will reach out to you
						shortly.
					</div>
				) : (
					<div className="contact_error">{this.props.res}</div>
				)}
			</div>
		);
	}
}

export default reduxForm({ form: "ContactForm", validate })(
	ContactFormValidation
);
