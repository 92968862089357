import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone/index.js";

import "./CalculatorsAndTimers.css";
import calculatorsAndTimersImg from "../../../../../assets/main_page_blocks/calculatorsAndTimers.png";

class CalculatorsAndTimers extends Component {
  render() {
    return (
      <div className="CalculatorsAndTimers">
        <div className="currentCityHeader">
          <div className="currentCityImg">
            <img src={calculatorsAndTimersImg} alt="Calculators and Timers" />
          </div>
          <div className="currentCityTittle">
            <div className="title-block">
              <h3>Calculators and timers</h3>
            </div>
          </div>
        </div>
        <div className="currentCityInfo">
          <div
            className="hover_effect"
            title={`Calculate the duration between two dates`}
          >
            <Link to={"/date-to-date-calculator"}>
              <b>Date to date calculators</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`Find the arrival and departure times in the city of departure and arrival`}
          >
            <Link to={"/flight-time-calculator"}>
              <b>Flight Time Calculator</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`Calculate the week start and end for business`}
          >
            <Link to={"/"}>
              <b>Next Business week start - end</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`Calculate the month start and end for business`}
          >
            <Link to={"/"}>
              <b>Next Business month start - end</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`Calculate the Quarter start and end for business`}
          >
            <Link to={"/next-quarter"}>
              <b>Next Quarter start-end</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`convert date and time to Unix time and vice versa`}
          >
            <Link to={"/unix-time-converter"}>
              <b>Unix time converter</b>
            </Link>
          </div>
          <div
            className="hover_effect"
            title={`convert date and time to Julian time and vice versa`}
          >
            <Link to={"/julian-time-converter"}>
              <b>Julian date converter</b>
            </Link>
          </div>
          {/* <div className="hover_effect">
            <Link to={`/countdown/newyear`}>
              <b>Countdown to New Year {+moment().format("YYYY") + 1}</b>
            </Link>
          </div>
          <div className="hover_effect">
            <Link to={"/countdown-timer"}>
              <b>Countdown timer</b>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}

export default CalculatorsAndTimers;
