import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MetaTags from "react-meta-tags";

import "./EclipsesWorld.css";
import Loader from "../../../../components/Main/Loader/Loader";
import Search from "../../MainPage/Search/Search";
import CityEclipses from "./CityEclipses/CityEclipses";
import NextSolarLunarEclipses from "./NextSolarLunarEclipses/NextSolarLunarEclipses";
import eclipsesData from "../../../../actions/action_eclipsesData";
import currentSearch from "../../../../actions/action_searchCity/action_currentSearch";

import Api from "../../../../services/api.js";

class EclipsesWorld extends Component {
  state = { opgPicture: null };
  async componentDidMount() {
    const { eclipsesData, currentSearch, currentSearchReducer } = this.props;
    if (window.location.pathname === "/solar-lunar-eclipses/world") {
      const eclipses = await Api.userCityList(`eclipse_city/`);
      eclipsesData(eclipses.data.data);
      localStorage.setItem("eclipses", JSON.stringify(eclipses.data.data));
      this.opgPicture();
    } else {
      const id = window.location.pathname.split("/").slice(2).join("__");
      const eclipses = await Api.userCityList(`eclipse_city/${id}/`);
      eclipsesData(eclipses.data);
    }
    const id = localStorage.getItem("searchCityInfo")
      ? JSON.parse(localStorage.getItem("searchCityInfo")).id
      : currentSearchReducer.id;
    const data = await Api.userCityList(`geo_names/${id}/`);
    currentSearch(data.data);
  }
  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.eclipsesDataReducer !== this.props.eclipsesDataReducer &&
  //     window.location.pathname !== "/solar-lunar-eclipses/world"
  //   )
  //     this.opgPicture();
  // }
  // async opgPicture() {
  //   const { eclipsesDataReducer } = this.props;
  //   let opgPicture;
  //   if (window.location.pathname === "/solar-lunar-eclipses/world") {
  //     opgPicture = await Api.userCityList(`opg/eclipse-page-1/get-url/`);
  //   } else {
  //     const newId = eclipsesDataReducer.url
  //       .replace("place/", "")
  //       .replace(/\//g, "__");
  //     opgPicture = await Api.userCityList(
  //       `opg/eclipse-page-6/get-url/?geo_name=${newId}`
  //     );
  //   }
  //   this.setState({ opgPicture: opgPicture.data.url });
  // }
  metaCityName(nameType) {
    const { eclipsesDataReducer } = this.props;
    if (eclipsesDataReducer.name) {
      const name = eclipsesDataReducer.name;
      const country =
        eclipsesDataReducer.country_name === "United States"
          ? eclipsesDataReducer.country_name_abbr
          : eclipsesDataReducer.country_name;
      let state =
        nameType === "title"
          ? ""
          : eclipsesDataReducer.state
          ? `${eclipsesDataReducer.state},`
          : "";
      switch (nameType) {
        case "title":
          return ` ${name}, ${country}`;
        case "description":
          return `${name}, ${country} `;
        case "keywords":
          return `${name}, ${state} ${country}, `;
        default:
          return "";
      }
    } else return "";
  }
  metaTegsBlock() {
    if (window.location.pathname === "/solar-lunar-eclipses/world") {
      return (
        <MetaTags>
          <title>Solar and Lunar Eclipses Worldwide</title>
          {/*<meta*/}
          {/*  name="description"*/}
          {/*  content={`Solar and Lunar Eclipses worldwide from 1900 – 2099 with eclipse path. Find eclipses for your location.`}*/}
          {/*/>*/}
          {/*<meta*/}
          {/*  name="keywords"*/}
          {/*  content={`solar, lunar, eclipse, eclipses, sun, moon, total eclipse, annular eclipse, partial eclipse, penumbral eclipse, zodiac sign, occultation`}*/}
          {/*/>*/}
          {/*{this.state.opgPicture ? (*/}
          {/*  <meta*/}
          {/*    property="og:image"*/}
          {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
          {/*  />*/}
          {/*) : null}*/}
        </MetaTags>
      );
    } else {
      return (
        <MetaTags>
          <title>
            Solar and Lunar Eclipses in {this.metaCityName("title")}
          </title>
          {/*<meta*/}
          {/*  name="description"*/}
          {/*  content={`Solar and Lunar Eclipses in ${this.metaCityName(*/}
          {/*    "description"*/}
          {/*  )}from 1900-2099 with eclipse path. Find eclipses for your location.`}*/}
          {/*/>*/}
          {/*<meta*/}
          {/*  name="keywords"*/}
          {/*  content={`solar, lunar, eclipse, eclipses, sun, moon, total eclipse, partial eclipse, annular eclipse, penumbral eclipse, zodiac sign, occultation`}*/}
          {/*/>*/}
          {/*{this.state.opgPicture ? (*/}
          {/*  <meta*/}
          {/*    property="og:image"*/}
          {/*    content={`${Api.url}${this.state.opgPicture}`}*/}
          {/*  />*/}
          {/*) : null}*/}
        </MetaTags>
      );
    }
  }
  render() {
    const { eclipsesDataReducer } = this.props;
    if (!eclipsesDataReducer.solar_eclipse_data)
      return (
        <div>
          {this.metaTegsBlock()}
          <Loader />
        </div>
      );
    return (
      <div className="Eclipses EclipsesWorld">
        {this.metaTegsBlock()}
        <div className="serarch-block">
          <b>Find next Solar and Lunar Eclipse for</b>
          <Search />
        </div>
        <CityEclipses />
        <NextSolarLunarEclipses />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eclipsesDataReducer: state.eclipsesDataReducer,
    currentSearchReducer: state.currentSearchReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eclipsesData: bindActionCreators(eclipsesData, dispatch),
    currentSearch: bindActionCreators(currentSearch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EclipsesWorld);
