import React, { Component } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import MetaTags from 'react-meta-tags';

import './ArticlePage.css';
import ArticleRightSideBar from "../MainArticlePage/ArticleRightSideBar";

import Api from '../../../../services/api';

class ArticlePage extends Component {
    state = {
        currentNews: {}
    }
    async componentDidMount(){
        const urlId = window.location.pathname.split('/').reverse()[0];
        const data = await Api.userCityList(`news/?slug=${urlId}`);
        this.setState({ currentNews: data.data });
    }
    render() {
        if (!this.state.currentNews.description) return (<div className="main-article-page"></div>)
        return (
            <article className="main-article-page current-article">
                <MetaTags>
                    <title>{this.state.currentNews.meta_title}</title>
                    <meta name="description" content={this.state.currentNews.meta_description} />
                    <meta name="keywords" content={this.state.currentNews.meta_key_words} />
                    {/* <meta property="og:image" content={this.metaCityName('ogImage')} /> */}
                </MetaTags>
                <h1 className='article-title'>{this.state.currentNews.title}</h1>
                <div className="all_news_wrapper">
                    <div className='main-articles-block'>
                        <div className="current_news_block">                    
                            {Parser(this.state.currentNews.description)}
                        </div>
                    </div>
                    <ArticleRightSideBar />
                </div>
            </article>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentNewsReducer: state.currentNewsReducer
    };
};

export default connect(mapStateToProps)(ArticlePage);