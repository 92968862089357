import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

const validate = values => {
	const errors = {};
	if (!values) errors[values] = "Required";
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = "Invalid email address";
	}
	return errors;
};

class EmailBlock extends Component {
	state = {
		value: this.props.userInfo.email
	};
	renderField = ({
		input,
		label,
		type,
		meta: { touched, error, warning }
	}) => {
		const { userInfo } = this.props;
		return (
			<div className="authorisation-form-input">
				<label>{label}</label>
				<div>
					<input
						{...input}
						placeholder={userInfo.email}
						type={type}
						disabled
						value={this.state.value}
						onChange={this.handleChange}
					/>
					{touched &&
						((error && <span>{error}</span>) ||
							(warning && <span>{warning}</span>))}
				</div>
			</div>
		);
	};

	handleChange = e => {
		this.setState({ value: e.target.value });
	};

	render() {
		const { handleSubmit } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<div className="profile-block">
					<Field
						name="email"
						type="email"
						label="Email"
						component={this.renderField}
						disabled
					/>
				</div>
			</form>
		);
	}
}

export default reduxForm({ form: "EditPersonalInfo", validate })(EmailBlock);
