
const moonCalcCoordsReducer = (state = 0, action) => {
  switch (action.type) {
    case 'MOON_RISE_COORDS':
      return isNaN(action.data) ? state : action.data;
    default:
      return state;
  }
};
  
export default moonCalcCoordsReducer;