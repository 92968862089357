export function validate(values) {
	const errors = {};
	const requiredFields = [
		"firstName",
		"lastName",
		"email",
		"password1",
		"password2"
	];

	if (values.phone !== undefined) {
		//tmp = values.phone.toString();
		values.phone = values.phone.replace(/[^0-9]/g, ""); //.replace("/\d/", "");
	}
    // export default function(values) {

	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = "Required";
		}
	});
	if (values.password1 !== values.password2) {
		errors.password2 = "Passwords do not match";
	}
	if (values.password1) {
		if (values.password1.length < 8) {
			errors.password1 = "Password must contain at least 8 characters";
		}
	}
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = "Invalid email address";
	}

	// console.log(values);

	return errors;
}

/*
export async function getCountryCode() {

    const CountryInfo = await Api.getCache(Api.simpleRequest,`get-country-info/`);
    if (CountryInfo) {
        // localStorage.setItem('CountryInfoSetupTime', now);
        localStorage.setItem('CountryInfo', JSON.stringify(CountryInfo.data));
        console.log('update CountryInfo');
        return CountryInfo.data;
    } else return [];
   //-----------------------------
    let hours = 1; // Reset when storage is more than 24hours
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('CountryInfoSetupTime');
    if (setupTime == null)  localStorage.setItem('CountryInfoSetupTime', now);

    if ( (localStorage.getItem('CountryInfo')) && ((now - setupTime) < hours * 60 * 60 * 1000) ) {
        console.log('CountryInfo - ready');
    } else {
        const CountryInfo = await Api.simpleRequest(`get-country-info/`);
        if (CountryInfo) {
            // sessionStorage.removeItem('CountryCode');
            // sessionStorage.setItem('CountryCode', JSON.stringify(CountryCode.data));
            localStorage.setItem('CountryInfoSetupTime', now);
            localStorage.setItem('CountryInfo', JSON.stringify(CountryInfo.data));
            console.log('update CountryInfo');
        }
    }
}
*/
