import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Swiper, Slide } from 'react-dynamic-swiper/lib'
import 'react-dynamic-swiper/lib/styles.css';
import moment from 'moment-timezone/index.js';

import './SunRiseDayParts.css';
import sunCalc from '../../../../../../actions/action_sunRise/action_sunCalc';
import sunRiseDate from "../../../../../../actions/action_sunRise/action_sunRiseDate";
import sunRisePosition from "../../../../../../actions/action_sunRise/action_sunRisePosition";
import sunRiseCoords from "../../../../../../actions/action_sunRise/action_sunRiseCoords";

import Api from '../../../../../../services/api.js';

class SunRiseDayParts extends Component {
    colors = ['#4b688b', '#8071a8', '#8091d6', '#ffad94', '#e5ca8d', '#ffad94', '#8091d6', '#8071a8', '#4b688b'];
    state = {
        options: {
            navigation: true,
            pagination: true,
            scrollBar: false,
        }
    };
    settings = {
        paginationClickable: true,
        slidesPerView: 9,
        slidesPerGroup: 9,
        spaceBetween: 10,
        breakpoints: {
            1439: {
              slidesPerView: 6,
              slidesPerGroup: 6
            },
            1299: {
              slidesPerView: 5,
              slidesPerGroup: 5
            },
            1099: {
              slidesPerView: 4,
              slidesPerGroup: 4
            },
            991: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            649: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            499: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            349: {
              slidesPerView: 1,
              slidesPerGroup: 1
            }
        }
    };
    async dayPeriodClick(elem){
        const { sunCalcReducer, sunCalc, sunRiseDate, sunRiseCoords, sunRisePosition } = this.props;
        const time = moment(elem).tz(sunCalcReducer.timezone).format('YYYY-MM-DDTHH:mm:ss');
        sunRiseDate(moment(time).format('YYYY-MM-DDTHH:mm:ss'));
        const sunCoordinats = await Api.userCityList(`geo_names/${sunCalcReducer.id}/?sun_app=true&date=${time}`);
        sunCalc(sunCoordinats.data);

        const hourCurrent = moment(sunCoordinats.data.time).tz(sunCoordinats.data.timezone).get('hour');
        const minuteCurrent = moment(sunCoordinats.data.time).tz(sunCoordinats.data.timezone).get('minute');
        for(let key in sunCoordinats.data.sun_data.position_during_day){         
            const hourDot = moment(sunCoordinats.data.sun_data.position_during_day[key].loc_time).tz(sunCoordinats.data.timezone).get('hour');
            const minuteDot = moment(sunCoordinats.data.sun_data.position_during_day[key].loc_time).tz(sunCoordinats.data.timezone).get('minute');
            if(hourDot === hourCurrent && minuteDot === minuteCurrent) {
                sunRiseCoords(+key)
                sunRisePosition(sunCoordinats.data.sun_data.position_during_day[+key]);
            }
        }
    }
    render () {
    const { sunCalcReducer } = this.props;
        return (
            <Swiper
                className="SunRiseDayParts"
                swiperOptions={this.settings}{...this.state.options}
                nextButton={swiper => 
                    <div className="swiper-button-next">
                        <i className="material-icons" onClick={() => swiper.slideNext()} >chevron_right</i>
                    </div>
                }
                prevButton={swiper =>
                    <div className="swiper-button-prev">
                        <i className="material-icons" onClick={() => swiper.slidePrev()}>chevron_left</i>
                    </div>
                }
            >
                {sunCalcReducer.sun_data.day_parts.map((elem, index) => (
                    <Slide className="day_parts" key={index} onClick={this.dayPeriodClick.bind(this, elem.time)}>
                        <h5 style={{ background: this.colors[index] }}>{elem.title}</h5>
                        <span>
                            {moment(elem.time).tz(sunCalcReducer.timezone).format('LT')}
                            <span style={{ background: this.colors[index] }}></span>
                        </span>
                        
                        <div className="day_parts_info">
                            <span>Alt {Math.round(elem.alt)}&deg;</span>
                            <span>Az {Math.round(elem.az)}&deg; {elem.rhumb}</span>
                        </div>
                    </Slide>
                ))}
            </Swiper>
        )
    }
}

const mapStateToProps = state => {
	return {
		sunCalcReducer: state.sunCalcReducer
	};
};
const mapDispatchToProps = dispatch => {
    return {
        sunCalc: bindActionCreators(sunCalc, dispatch),
        sunRiseDate: bindActionCreators(sunRiseDate, dispatch),
        sunRisePosition: bindActionCreators(sunRisePosition, dispatch),
        sunRiseCoords: bindActionCreators(sunRiseCoords, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SunRiseDayParts);