import React, { Component } from 'react';

import './NextQuarter.css';
import NextQuarterStartEnd from './NextQuarterStartEnd/NextQuarterStartEnd';
import ApisToolsBlock from '../FlightTimeCalculator/ApisToolsBlock/ApisToolsBlock';

class NextQuarter extends Component {
	async componentDidMount(){
	}
    render() {
        return (
            <div className='NextQuarter'>
                <NextQuarterStartEnd />
                <ApisToolsBlock />
            </div>
        )
    }
}

export default NextQuarter;