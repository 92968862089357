import React, { Component } from 'react';
import moment from 'moment-timezone';


class LocalTime extends Component {

    state = {
        count: 0
    };

    currentTime = setInterval(()=> {
         this.setState({count: this.state.count+1})
    }, 1000);

    componentWillUnmount(){
        clearInterval(this.currentTime);
    }

    formateTime(time, timezone, format){
        if(time) return moment().tz(timezone).format(format);
        return 'N/A';
    }


    render () {
        const {time, timezone, format} = this.props.data;
        // console.log('LocalTime', time, timezone);
        return (
            <span>{this.formateTime(time, timezone, format)}</span>

        )
    }
}

export default LocalTime;