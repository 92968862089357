import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from "moment-timezone/index.js";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import MetaTags from 'react-meta-tags';


import './SunCityEclipses.css';
import Loader from '../../../../components/Main/Loader/Loader';
import Search from '../../MainPage/Search/Search';
import CityEclipses from './CityEclipses/CityEclipses';
import SolarEclipsesInfo from './SolarEclipsesInfo/SolarEclipsesInfo';
import Countdown from './Countdown/Countdown';
import advertising from '../../../../assets/taboola/long-ad.png'
import eclipsesData from '../../../../actions/action_eclipsesData';
import eclipsesDataTable from '../../../../actions/action_eclipsesDataTable';

import Api from '../../../../services/api.js';

class SunCityEclipses extends Component {
    months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
    eclipseTypeSolar = ['all', 'partial', 'annular', 'total', 'hybrid'];
    eclipseTypeLunar = ['all', 'partial', 'total', 'penumbral'];
    eclipseTableSun = ['Date', 'Time', 'Eclipse type', 'Saros Series', 'Eclipse Magnitude', 'Latitude', 'Longitude', 'Sun Zodiac', 'Moon Zodiac', 'Path'];
    eclipseTableMoon = ['Date', 'Time', 'Eclipse type', 'Saros Series', 'Umbral Magnitude', 'Sun Zodiac', 'Moon Zodiac', 'Path'];

    state = {
        eclipseTypeList: ['all'],
        monthNumm: 0,
        sunArticles: [],
        moonArticles: []
    };
    UNSAFE_componentWillMount() {
        const pageType = window.location.pathname.split('/')[1].split('-')[0];
        this.setState({
            pageType: pageType.charAt(0).toUpperCase() + pageType.slice(1),
            eclipseTypeList: pageType === 'solar' ? this.eclipseTypeSolar : this.eclipseTypeLunar
        });
    }
    async componentDidMount() {
        const { eclipsesData, eclipsesDataTable } = this.props;
        eclipsesData({});
        eclipsesDataTable({});

        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const date = location.reverse()[0];
        const url = window.location.pathname.split('/').slice(2, -1).join('__');
        const eclipses = await Api.userCityList(`eclipse_city/${url}/?date=${date}`);

        const geoId = eclipses.data.id;
        const periodList = ['Next 10 Years'];
        for (let i = 1900; i < 2099; i += 10) {
            periodList.push(`${i} - ${i + 9}`);
        }
        const newPeriod = this.startPeriod(eclipses.data);
        const eclipseInfo = await Api.userCityList(`visible_eclipse_city/${geoId}/${type}/?period=${newPeriod.replace(/ /g, '')}&type=all`);
        eclipsesDataTable({
            eclipseData: eclipseInfo.data.eclipses_data,
            periodList: periodList,
            period: periodList[0],
            eclipseType: 'all'
        });
        eclipsesData(eclipses.data);
        
        const sunData = await Api.userCityList(`news/?limit=5&category=solar-eclipse`);
        const moonData = await Api.userCityList(`news/?limit=5&category=lunar-eclipse`);
        this.setState({
            sunArticles: sunData.data,
            moonArticles: moonData.data
        });
    }
    componentDidUpdate(prevProps){
        // eslint-disable-next-line react/no-string-refs
        const block = this.refs.tableInfoCity;
        if (block) {
            if (window.location.hash !== '') window.scrollTo(0, block.offsetTop + block.offsetParent.offsetTop)
        }
        // if(prevProps.eclipsesDataReducer !== this.props.eclipsesDataReducer) this.opgPicture();
    }
    // async opgPicture(){
    //     const { eclipsesDataReducer } = this.props;
    //     if(eclipsesDataReducer.name){
    //         const location = window.location.pathname.split('/');
    //         const date = location.reverse()[0];
    //         let opgPicture;
    //         const newId = eclipsesDataReducer.url.replace('place/', '').replace(/\//g, '__');
    //         if (this.state.pageType === 'Solar') {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-5/get-url/?geo_name=${newId}&date=${date}/`);
    //         } else {
    //             opgPicture = await Api.userCityList(`opg/eclipse-page-10/get-url/?geo_name=${newId}&date=${date}/`);
    //         }
    //         this.setState({ opgPicture: opgPicture.data.url});
    //     }
    // }
    solarLunarLinksBlock() {
        if (this.state.pageType === 'Solar') {
            return (                
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">About Solar Eclipse</h5>
                    {this.state.sunArticles.map((elem, index) =>                    
                        <div className="eclipse-info-block" key={index}>
                            <b><Link to={`/articles/sun/${elem.slug}`}>{elem.title}</Link></b>
                        </div>
                    )}
                    <b><Link to={'/articles#sun'}>...more</Link></b>
                </div>
            )
        } else {
            return (
                <div className="eclipse-info">
                    <h5 className="eclipse-info-header">About Lunar Eclipse</h5>
                    {this.state.moonArticles.map((elem, index) =>                    
                        <div className="eclipse-info-block" key={index}>
                            <b><Link to={`/articles/moon/${elem.slug}`}>{elem.title}</Link></b>
                        </div>
                    )}
                    <b><Link to={'/articles#moon'}>...more</Link></b>
                </div>
            )
        }
    }
    startPeriod(eclipsesData) {
        const time = moment().tz(eclipsesData.timezone).get('year');
        return `${time} - ${time + 10}`;
    }
    periodChange = (event, index, value) => {
        const { eclipsesDataTable, eclipsesDataTableReducer } = this.props;
        eclipsesDataTable({
            eclipseData: eclipsesDataTableReducer.eclipseData,
            periodList: eclipsesDataTableReducer.periodList,
            period: value,
            eclipseType: eclipsesDataTableReducer.eclipseType
        });
    };
    typeChange = (event, index, value) => {
        const { eclipsesDataTable, eclipsesDataTableReducer } = this.props;
        eclipsesDataTable({
            eclipseData: eclipsesDataTableReducer.eclipseData,
            periodList: eclipsesDataTableReducer.periodList,
            period: eclipsesDataTableReducer.period,
            eclipseType: value
        });
    };
    async newEclipseList() {
        const { eclipsesDataReducer, eclipsesDataTable, eclipsesDataTableReducer } = this.props;
        const newPeriod = eclipsesDataTableReducer.period === 'Next 10 Years' ?
            this.startPeriod(eclipsesDataReducer) : eclipsesDataTableReducer.period;
        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const geoId = eclipsesDataReducer.id;
        const eclipseInfo = await Api.userCityList(`visible_eclipse_city/${geoId}/${type}/?period=${newPeriod.replace(/ /g, '')}&type=${eclipsesDataTableReducer.eclipseType}`);
        eclipsesDataTable({
            eclipseData: eclipseInfo.data.eclipses_data,
            periodList: eclipsesDataTableReducer.periodList,
            period: eclipsesDataTableReducer.period,
            eclipseType: eclipsesDataTableReducer.eclipseType
        });
    }
    moonRisePositionNS(val) {
        return val < 0 ? `${val * -1}${String.fromCharCode(176)}S` : `${val}${String.fromCharCode(176)}N`;
    }
    moonRisePositionEW(val) {
        return val < 0 ? `${val * -1}${String.fromCharCode(176)}W` : `${val}${String.fromCharCode(176)}E`;
    }
    pictureLunar(elem, type) {
        const date = moment(elem.date_start).format('YYYYMMMDD');
        const format = type === 'solar' ? elem.eclipse_type_main.title.slice(0, 1) : elem.eclipse_type.title.slice(0, 1);
        return this.state.pageType === 'Solar' ? `SE${date}${format}` : `LE${date}${format}`;
    }
    geotimedateTable() {
        const { eclipsesDataTableReducer } = this.props;
        const tableLength = eclipsesDataTableReducer.eclipseData.length * 55 + 29 + 30;
        const bodyHeight = document.body.offsetHeight;
        if (document.body.offsetWidth > 1199) return tableLength;
        else return bodyHeight > tableLength ? tableLength : bodyHeight;
    }
    solarLunarTableTitle() {
        if (this.state.pageType === 'Solar') {
            return (
                <Row>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell></Cell>
                </Row>
            )
        }
    }
    tablePicture(elem) {
        if (this.state.pageType === 'Solar') {
            return (
                <b><img src={`/${elem.img}`} alt="geotimedate" /></b>
            )
        } else {
            return (
                <b><img src={`/${elem.map_url}`} alt="geotimedate" /></b>
            )
        }
    }
    cityEclipse(tz) {
        const { eclipsesDataTableReducer } = this.props;
        const period = eclipsesDataTableReducer.period === 'Next 10 Years' ?
            `${moment().tz(tz).get('year')} - ${moment().tz(tz).get('year') + 10}` : eclipsesDataTableReducer.period;
        const { eclipsesDataReducer } = this.props;
        const name = eclipsesDataReducer.name;
        const country = eclipsesDataReducer.country_name === 'United States' ?
            eclipsesDataReducer.country_name_abbr : eclipsesDataReducer.country_name;
        const state = eclipsesDataReducer.country_name === 'United States' ?
            `${eclipsesDataReducer.state},` : ``;
        return `Catalog of all ${this.state.pageType} Eclipses in ${name}, ${state} ${country} (${period})`;
    }
    selectedCityLink(city) {
        const { eclipsesDataReducer } = this.props;
        const tz = eclipsesDataReducer.timezone;
        const cityUrl = eclipsesDataReducer.url.replace('place/', '');
        let date;
        if (this.state.pageType === 'Solar') date = moment(city.date_start).tz(tz).format('YYYY-MM-DD');
        else date = moment(city.penumbral_eclipse_begin).tz(tz).format('YYYY-MM-DD');
        return `/${this.state.pageType.toLocaleLowerCase()}-eclipse/${cityUrl}/${date}`;
    }
    async pageDateRequest(elem) {
        const { eclipsesData, eclipsesDataReducer, eclipsesDataTable, eclipsesDataTableReducer } = this.props;
        const tz = eclipsesDataReducer.timezone;

        const location = window.location.pathname.split('/');
        const type = location[1].split('-')[0];
        const date = moment(this.state.pageType === 'Solar' ? elem.date_start : elem.penumbral_eclipse_begin).tz(tz).format('YYYY-MM-DD');

        const periodList = ['Next 10 Years'];
        for (let i = 1900; i < 2099; i += 10) {
            periodList.push(`${i} - ${i + 9}`);
        }
        const eclipses = await Api.userCityList(`eclipse_city/${eclipsesDataReducer.id}/?date=${date}`);
        const newPeriod = this.startPeriod(eclipses.data);
        const eclipseInfo = await Api.userCityList(`visible_eclipse_city/${eclipses.data.id}/${type}/?period=${newPeriod.replace(/ /g, '')}&type=${eclipsesDataTableReducer.eclipseType}`);
        eclipsesDataTable({
            eclipseData: eclipseInfo.data.eclipses_data,
            periodList: periodList,
            period: periodList[0],
            eclipseType: eclipsesDataTableReducer.eclipseType
        });

        eclipsesData(eclipses.data);
    }
    cityNameHome(city) {
        const name = city.name;
        const country = city.country_name === 'United States' ?
            city.country_name_abbr : city.country_name;
        const state = city.country_name === 'United States' ? `${city.state}, ` : '';
        return `${name}, ${state} ${country}`;
    }
    metaCityName(nameType){
        const { currentSearchReducer } = this.props;
            if(currentSearchReducer.name){
                const name = currentSearchReducer.name;
            const country = currentSearchReducer.country_name === 'United States' ?
                currentSearchReducer.country_name_abbr : currentSearchReducer.country_name;
            switch(nameType){
                case 'title':
                    return ` ${name}, ${country}`;
                case 'description':
                    return ` ${name},${country}`;
                case 'keywords':
                    return ` ${name},${country}`;
                default:
                    return '';
            }
        } else return '';
    }
    metaTegsBlock(){
        const { eclipsesDataReducer } = this.props;
        const eclipseType = this.state.pageType === 'Solar' ?
            eclipsesDataReducer.solar_eclipse_data.eclipse_type.title :
            eclipsesDataReducer.lunar_eclipse_data.eclipse_type.title;
        const eclipseDateInfo = this.state.pageType === 'Solar' ?
            eclipsesDataReducer.solar_eclipse_data.date_start :
            eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_begin;
        const eclipseDate = moment(eclipseDateInfo).tz('UTC').format('LL');
        const zodiacBlock = eclipsesDataReducer.solar_eclipse_data.zodiac_position;
        const zodiacSignDegree = this.state.pageType === 'Solar' ?
            `${zodiacBlock[0].zodiac_name} ${zodiacBlock[0].body_pos_deg}${zodiacBlock[0].body_pos_min}` :
            `${zodiacBlock[1].zodiac_name} ${zodiacBlock[1].body_pos_deg}${zodiacBlock[1].body_pos_min}`;
        if(this.state.pageType === 'Solar'){
            return (
                <MetaTags>
                    <title>{eclipseType} Solar Eclipse visible in{this.metaCityName('title')} - {eclipseDate}</title>
                    {/*<meta name="description" content={`How to see ${eclipseDate} ${eclipseType} Solar Eclipse in${this.metaCityName('description')}. See catalog of all Solar Eclipses in${this.metaCityName('description')} from 1900-2099 with eclipse path.`} />*/}
                    {/*<meta name="keywords" content={`solar eclipse, sun eclipse, eclipses, occultation, ${eclipseType.toLowerCase()}, ${eclipseDate}, ${zodiacSignDegree}, solar eclipse in${this.metaCityName('description')}`} />*/}
                    {/*<meta property="og:image" content={`${Api.url}${this.state.opgPicture}`} />*/}
                </MetaTags>
            )
        }else{
            return (
                <MetaTags>
                    <title>{eclipseType} Lunar Eclipse visible in{this.metaCityName('title')} - {eclipseDate}</title>
                    {/*<meta name="description" content={`How to see ${eclipseDate} ${eclipseType} Lunar Eclipse in${this.metaCityName('description')}. See catalog of all Lunar Eclipses in${this.metaCityName('description')} from 1900-2099 with eclipse path.`} />*/}
                    {/*<meta name="keywords" content={`lunar eclipse, moon eclipse, eclipses, occultation, ${eclipseType.toLowerCase()}, ${eclipseDate}, ${zodiacSignDegree}, lunar eclipse in${this.metaCityName('description')}`} />*/}
                    {/*<meta property="og:image" content={`${Api.url}${this.state.opgPicture}`} />*/}
                </MetaTags>
            )
        }
    }
    render() {
        const { eclipsesDataReducer, currentSearchReducer, eclipsesDataTableReducer } = this.props;
        if (!eclipsesDataReducer.id || !this.state.pageType || !eclipsesDataTableReducer.periodList) return <div><Loader /></div>;
        const tz = eclipsesDataReducer.timezone;
        const city = localStorage.getItem('mainSearchCity') ? JSON.parse(localStorage.getItem('mainSearchCity')) : currentSearchReducer;
        return (
            <div className='SunCityEclipses'>
                {this.metaTegsBlock()}
                <div className='serarch-block'>
                    <b>See the next {this.state.pageType} Eclipse for</b>
                    <Search />
                </div>
                <CityEclipses pageType={this.state.pageType} />
                <SolarEclipsesInfo pageType={this.state.pageType} />
                <section className='eclipsesSolarLunar eclipsesCitySolarLunar'>
                    <div className="eclipsesSolarLunarInfo table">
                        {/* eslint-disable-next-line react/no-string-refs */}
                        <div className="sunCulcCityInfoRightSide" ref={'tableInfoCity'}>
                            <div className='CityTableEclipses'>
                                <section className="citySunRiseSet">
                                    <div className="city_name_date">
                                        <h4>{this.cityEclipse(tz)}</h4>
                                    </div>
                                </section>
                                <div className='SunData'>
                                    <div className='sun-month-year-change'>
                                        <div className='month-year-change'>
                                            <MuiThemeProvider>
                                                <SelectField
                                                    className='sun-year-select'
                                                    value={eclipsesDataTableReducer.period}
                                                    onChange={this.periodChange}
                                                    maxHeight={200}
                                                >
                                                    {eclipsesDataTableReducer.periodList.map((elem, index) =>
                                                        <MenuItem value={elem} key={index} primaryText={elem} />
                                                    )}
                                                </SelectField>
                                            </MuiThemeProvider>
                                            <MuiThemeProvider>
                                                <SelectField
                                                    className='sun-month-select'
                                                    value={eclipsesDataTableReducer.eclipseType}
                                                    onChange={this.typeChange}
                                                    maxHeight={200}
                                                >
                                                    {this.state.eclipseTypeList.map((elem, index) =>
                                                        <MenuItem value={elem} key={index} primaryText={elem} />
                                                    )}
                                                </SelectField>
                                            </MuiThemeProvider>
                                        </div>
                                        <button className="month-chnge" onClick={this.newEclipseList.bind(this)}>Go</button>
                                    </div>
                                    <div className='geotimedate-table eclipse-table' style={{ height: this.geotimedateTable() + 'px' }}>
                                        <StickyTable stickyColumnCount={1} stickyHeaderCount={1}>
                                            <Row>
                                                {(this.state.pageType === 'Solar' ? this.eclipseTableSun : this.eclipseTableMoon).map((elem, index) =>
                                                    <Cell key={index} style={{ background: this.state.pageType === 'Solar' ? '#fff386' : '#aee5fa' }}>
                                                        <div className='cell-wrapper'>
                                                            <b>{elem}</b>
                                                        </div>
                                                    </Cell>
                                                )}
                                            </Row>
                                            {this.solarLunarTableTitle()}
                                            {eclipsesDataTableReducer.eclipseData.map((elem, index) =>
                                                <Row key={index}>
                                                    <Cell>
                                                        <b className='moon-event-block'>
                                                            <Link to={this.selectedCityLink(elem)} onClick={this.pageDateRequest.bind(this, elem)}>
                                                                {moment(this.state.pageType === 'Solar' ? elem.date_start : elem.penumbral_eclipse_begin).tz(tz).format('ll')}
                                                            </Link>
                                                        </b>
                                                    </Cell>
                                                    <Cell>
                                                        <p>{moment(this.state.pageType === 'Solar' ?
                                                            elem.date_start : elem.penumbral_eclipse_begin).tz(tz).format('LT')}
                                                        </p>
                                                    </Cell>
                                                    <Cell>
                                                        <b>{this.state.pageType === 'Solar' ? elem.eclipse_type.title : elem.type_loc.title}</b>
                                                    </Cell>
                                                    <Cell>
                                                        <b>{elem.saros_number}</b>
                                                    </Cell>
                                                    <Cell>
                                                        <b>{(+elem.magnitude).toFixed(3)}</b>
                                                    </Cell>
                                                    <Cell style={{ display: this.state.pageType === 'Solar' ? 'table-cell' : 'none' }}>
                                                        <b>{this.moonRisePositionNS(+elem.lat)}</b>
                                                    </Cell>
                                                    <Cell style={{ display: this.state.pageType === 'Solar' ? 'table-cell' : 'none' }}>
                                                        <b>{this.moonRisePositionEW(+elem.lon)}</b>
                                                    </Cell>
                                                    <Cell>
                                                        <p>
                                                            {elem.zodiac_position[0].body_pos_deg}
                                                            {elem.zodiac_position[0].body_pos_min}
                                                        </p>
                                                        <p>{elem.zodiac_position[0].zodiac_name}</p>
                                                    </Cell>
                                                    <Cell>
                                                        <p>
                                                            {elem.zodiac_position[1].body_pos_deg}
                                                            {elem.zodiac_position[1].body_pos_min}
                                                        </p>
                                                        <p>{elem.zodiac_position[1].zodiac_name}</p>
                                                    </Cell>
                                                    <Cell className="picture-lunar" style={{ width: '70px' }}>
                                                        {this.tablePicture(elem)}
                                                    </Cell>
                                                </Row>
                                            )}
                                        </StickyTable>
                                    </div>
                                </div>
                                <Link to={`/catalog-of-all-solar-eclipses/world`}>Catalog of all Solar Eclipses Worldwide from 1900 - 2099.</Link>
                                <Link to={`/catalog-of-all-lunar-eclipses/world`}>Catalog of all Lunar Eclipses Worldwide from 1900 - 2099.</Link>
                                <div className="advertising-block-main">
                                    <img src={advertising} className="advertising" alt="advertising" />
                                </div>
                            </div>
                        </div>
                        <div className='eclipses-img'>
                            <Countdown
                                nextEclipse={this.state.pageType === 'Solar' ?
                                    eclipsesDataReducer.solar_eclipse_data.date_start :
                                    eclipsesDataReducer.lunar_eclipse_data.penumbral_eclipse_begin}
                                pageType={this.state.pageType}
                            />
                            <div className="sunCulcCityInfoRightSide">
                                <div className="eclipse-info">
                                    <h5 className="eclipse-info-header">More information<br />for {this.cityNameHome(city)}</h5>
                                    <div className="eclipse-info-block">
                                        <b><Link to={`/sun${city.url.replace('place', '')}`}>Sunrise and Sunset</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to={`/moon${city.url.replace('place', '')}`}>Moonrise and Moonset</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to='/'>Time Planer</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to='/#ephemeris-for-today'>Ephemeris for Today</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to={`/#planet-stations-retrogrades`}>Planet Stations and Retrogrades</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to={`/#planet-in-signs`}>Planet in Signs</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to={`/planetary-hours${city.url.replace('place', '')}`}>Planetary Hours</Link></b>
                                    </div>
                                    <div className="eclipse-info-block">
                                        <b><Link to='/#transits'>Transits</Link></b>
                                    </div>
                                </div>
                            </div>
                            <div className="advertising-block-main">
                                <img src={advertising} className="advertising" alt="advertising" />
                            </div>
                            <div className="sunCulcCityInfoRightSide">
                                {this.solarLunarLinksBlock()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer,
        eclipsesDataTableReducer: state.eclipsesDataTableReducer,
        currentSearchReducer: state.currentSearchReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        eclipsesData: bindActionCreators(eclipsesData, dispatch),
        eclipsesDataTable: bindActionCreators(eclipsesDataTable, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SunCityEclipses);