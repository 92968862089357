import React, { Component } from "react";
import myAstro from "../../../../../../../assets/main_page_blocks/my-astro-logo.png";

import "./PoveredBy.css";

class PoveredBy extends Component {
  render() {
    return (
      <div className="povered-by">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={`https://myastro.com/`} target="_blank">
          <span>Powered by -</span>
          <img src={myAstro} alt="myastro.com"></img>
        </a>
        <span>Get your personalized forecast for free</span>
      </div>
    );
  }
}

export default PoveredBy;
