import {constants} from "../constants";

const group = {name: 'Group 1', id: null};
const initGroups = {
    groupList: [group, ], currentGroup: group,
};

const timeFormatDefault = {
    format12: true,
    format24: false,
};

const initialState = {
    cities: [],
    defaultMainCities: [],
    authUserCities: [],
    noAuthUserCities: [],
    currentLocation: null,
    currentLocationAddedToPlanerCities: false,
    defaultMainCitiesAddedToPlanner: false,
    token: false,
    timeFormat: timeFormatDefault,
    ...initGroups,
};

const deleteCityFromlist = (cityList, city) => {
    if ('uid' in city){
        return cityList.filter((value) => (
            value.uid !== city.uid
        ));
    }
    cityList.splice(city.index, 1);
    return cityList
};

const addUuidToArr = (arr) => {
    // нужно для удаления из общего списка, в котором все группы именно выбранного элемента
    for (let i = 0; i < arr.length; i++) {
        arr[i]['uid'] = generateUuid();
    }
    return arr
};

const generateUuid = () => (
    `f${(~~(Math.random()*1e8)).toString(16)}`
);

const selectedCitiesReducer = (state = initialState, action) => {

    switch (action.type) {
        case constants.SET_CITIES:

            if (state.token) {

                const groupList = state.authUserCities.map(elem => ({
                        name: elem.group_name,
                        id: elem.group_number,
                    })).filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === thing.id && t.name === thing.name
                    )));

                const currentGroup_ = (state.currentGroup && state.currentGroup.id) ? state.currentGroup : groupList[0];  // todo Проверить если groupList

                let citiesByGroup = state.authUserCities.filter(item => (
                    item.group_number === currentGroup_.id
                ));

                return {
                    ...state,
                    cities: citiesByGroup,
                    groupList: groupList,
                    currentGroup: currentGroup_,  // todo Проверить если groupList ===0
                }
            }
            if (
                state.currentLocationAddedToPlanerCities && state.defaultMainCitiesAddedToPlanner
                && state.noAuthUserCities.length === 0) {

                return {
                    ...state,
                    noAuthUserCities: [...state.defaultMainCities],
                    cities: [...state.defaultMainCities],
                }
            }

            return {
                ...state,
                cities: [...state.noAuthUserCities]
            };

        case 'SELECTED_CITIES':

            // eslint-disable-next-line no-case-declarations
            let selectedCityList = [];

            if (action.data != null) selectedCityList = addUuidToArr(action.data);

            if (state.token) {
                const currentGroup_ = state.currentGroup.id ? state.currentGroup : state.groupList[0];  // todo Проверить если groupList

                let citiesByGroup = selectedCityList.filter(item => (
                    item.group_number === currentGroup_.id
                ));
                return {
                    ...state,
                    cities: citiesByGroup,
                    authUserCities: [...selectedCityList],
                };
            } else {
                return {
                    ...state,
                    cities: [...selectedCityList],
                    noAuthUserCities: [...selectedCityList],
                };
            }

        case constants.SET_CURRENT_LOCATION:

            if (!state.currentLocationAddedToPlanerCities) {
                return {
                    ...state,
                    defaultMainCities: [action.data, ...state.defaultMainCities],
                    currentLocation: action.data,
                    currentLocationAddedToPlanerCities: true,
                };
            }

            return state;

        case constants.ADD_TO_END_CITY_LIST:

            // eslint-disable-next-line no-case-declarations
            const { value } = action.data;
            return {
                    ...state,
                    noAuthUserCities: [...state.noAuthUserCities, value],
                    cities: [...state.noAuthUserCities, value],
                };

        case constants.SET_DEFAULT_MAIN_CITIES:

            if (!state.defaultMainCitiesAddedToPlanner) {
                return {
                    ...state,
                    defaultMainCities: [...state.defaultMainCities, ...action.data.data],
                    defaultMainCitiesAddedToPlanner: true,
                };
            }
            return state;

        case constants.GET_USER_CITIES_LIST_SUCCESS:

            return {
                ...state,
                authUserCities: addUuidToArr([...action.data.data]),
            };

        case constants.DELETE_CITY_FROM_USER_CITY_LIST:

            return {
                ...state,
                authUserCities: deleteCityFromlist(state.authUserCities, action.data),
            };

        case constants.DELETE_CITY_FROM_NO_AUTH_USER_CITY_LIST:

            return {
                ...state,
                noAuthUserCities: deleteCityFromlist(state.noAuthUserCities, action.data),
            };

        case constants.SET_AS_DEFAULT_FOR_AUTH:

            return {
                ...state,
                authUserCities: [action.data, ...deleteCityFromlist(state.authUserCities, action.data)],
            };

        case constants.SET_AS_DEFAULT_FOR_NO_AUTH:

            return {
                ...state,
                noAuthUserCities: [action.data, ...deleteCityFromlist(state.noAuthUserCities, action.data)],
            };

        case constants.AUTHORIZATION:

            if ('token' in action.data) {
                const {time_format: userTimeFormat } = action.data;
                let timeFormat = {...timeFormatDefault};
                if (['12 hr', '24 hr'].includes(userTimeFormat)) {
                    timeFormat = {
                        format12: userTimeFormat === '12 hr',
                        format24: userTimeFormat === '24 hr',
                    }
                }

                return {
                    ...state,
                    token: action.data.token,
                    timeFormat: timeFormat,
                };
            }
            return {
                ...state,
                authUserCities: [],
                token: false,
                timeFormat: timeFormatDefault,
                ...initGroups
            };

        case constants.SET_CURRENT_GROUP:

            // eslint-disable-next-line no-case-declarations
            let currentGroup__ = state.groupList.find(item => item.id === action.data.groupId);

            return {
                ...state,
                currentGroup: currentGroup__ ? currentGroup__ :  state.groupList[0],  // todo Проверить если groupList ===0
                cities: state.authUserCities.filter(item => (
                    item.group_number === currentGroup__.id
                ))
            };

        case constants.UPDATE_NO_AUTH_USER_CITY_LIST:

            return {
                ...state,
                noAuthUserCities: state.noAuthUserCities.map(e=>{
                    // делаем так чтоб сохранить порядок элементонв
                    return {
                        ...e,
                        ...action.data.data.find(item => item.id === e.id),
                        city_id: e.city_id, // не меняемб
                    }
                })
            };

        default:
            return state;
    }
};

export default selectedCitiesReducer;
