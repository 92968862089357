
import React, { Component } from 'react';
import { connect } from "react-redux";

import './MapWithMoonDraw.css';
import Loader from "../../../../../../components/Main/Loader/Loader";
import MoonDrawFunc from "./MoonDrawFunc";


class MapWithMoonDraw extends Component {
	render() {
		const { moonLoaderShowReducer } = this.props;
		if(moonLoaderShowReducer === true){
			return (<div className="MapWithSunDraw" style={{ height: Math.min(document.body.offsetWidth, document.body.offsetHeight) / 2 + 40 }}><Loader/></div>)
		}
		return (
			<div className='MapWithSunDraw'>
				<MoonDrawFunc
					sunMapStyle={this.props.sunMapStyle}
					moonCalcStore={this.props.moonCalcStore}
					getPixelPositionOffset={this.props.getPixelPositionOffset}
					radius={this.props.radius}
					centerX={this.props.centerX}
					centerY={this.props.centerY}
					moonPosition={this.props.moonPosition}
					moonCircleStyle={this.props.moonCircleStyle}
					moonLineColor={this.props.moonLineColor}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonLoaderShowReducer: state.moonLoaderShowReducer
	};
};
export default connect(mapStateToProps)(MapWithMoonDraw);