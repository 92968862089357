import React, {Component} from 'react';
import moment from 'moment-timezone/index.js';
// import gpsTime from "gps-time";



class ShowTOWWeek extends Component {

    state={
        kay:0
    }

    currentTime = setInterval(() => {
        if (this.props.run === 'STOP') window.clearTimeout(this.currentTime);
        let a = this.state.kay;
        a += a+1;
        this.setState({kay: a})
    }, 1000);

     componentWillUnmount(){
        window.clearTimeout(this.currentTime);
        this.isCancelled = true;
    }

    showTOW() {
        const {timezone, offset, selectDate} = this.props;
        // console.log('timezone', timezone, offset)
        let tow;
        if (timezone) {
            let acumm = selectDate ? selectDate : moment().tz(timezone);
            const dayOfWeek = acumm.format('d');
            const secondOfDayOnWeek = dayOfWeek * 86400;
            const hour = acumm.format('H');
            const minute = acumm.format('m');

            const secOfHour = hour * 60 * 60;
            const secOfMinute = minute * 60;
            const second = +acumm.format('s');
            const offSet = +offset.split(':')[0]*3600;
            // console.log(+offset.split(':')[0])
            // console.log((secOfHour + secOfMinute + second + secondOfDayOnWeek)-offSet);
            tow = (secOfHour + secOfMinute + second + secondOfDayOnWeek)-offSet;
        }

        return tow ? tow : '...'
    }

    showWeek(type) {
        const {timezone, timedelta_seconds, selectDate} = this.props;
        // console.log('timezone', timezone, offset)
        let week, weekMod, cycle;
        if (timezone && timedelta_seconds) {
            let acumm = selectDate ? selectDate : moment().tz(timezone);
            const years = +acumm.format('YYYY') - 1980;
            const days = years*365;
            let dayOfLeapYear = 0;
            for(let i = 1980; i <= +acumm.format('YYYY'); i++){
                if((i/400 ^ 0) === i/400) dayOfLeapYear++
                if((i/4 ^ 0) === i/4 && (i/100 ^ 0) !== i/100) dayOfLeapYear++
            }

            let dayOfDifferent = 0;
            let dayOfDifferentStart = 0;
            let dayOfDifferentEnd = 0;
            let offsetHour = new Date().getTimezoneOffset()/60*3600; //на компе пояс
            let offSetTS = timedelta_seconds === offsetHour ? Math.abs(offsetHour-3600) : Math.abs(timedelta_seconds-offsetHour);
            if (timedelta_seconds < 0) dayOfDifferentStart = moment('1980-01-06T00:00:00').unix()+offSetTS;
            else dayOfDifferentStart = moment('1980-01-06T00:00:00').unix()-offsetHour+3600;
            const month = acumm.format('MM');
            const day = acumm.format('DD');
            if (timedelta_seconds < 0) dayOfDifferentEnd = moment(`1980-${month}-${day}T00:00:00`).unix()+offSetTS;
            else dayOfDifferentEnd = moment(`1980-${month}-${day}T00:00:00`).unix()-offsetHour+3600;
            dayOfDifferent = (dayOfDifferentEnd-dayOfDifferentStart)/86400;

            let allDays = days+dayOfDifferent+dayOfLeapYear;
            let timesecond = allDays*86400;

            week = (timesecond/604800).toString().split('.')[0]; //2036
            weekMod = +week-1024 > 0 ? +week-1024 : week;
            cycle = Math.floor(+week/1024);
        }

        cycle = cycle ? cycle : '0';
        weekMod = weekMod ? weekMod : '0';
        if (type === 'cycle') return cycle;
        else if (type === 'weekMod') return weekMod;
    }


    render() {
         // const {timezone} = this.props;
        return (
            <div className='input tow'>
                <p>
                    <b  style={{fontSize: 14+'px'}}>
                        (TOW: {this.showTOW()} /Week: {this.showWeek('weekMod')} /Cycle: {this.showWeek('cycle')})
                    </b>
                </p>
            </div>
        )
    }
}


export default ShowTOWWeek;