import React, { Component } from 'react';
import { connect } from 'react-redux';

import './CityEclipses.css';
import globe from '../../../../../assets/globe.png';

class CityEclipses extends Component {
    nextEclipse(){
        const { eclipsesDataReducer } = this.props;
        if(window.location.pathname !== '/solar-lunar-eclipses/world' && eclipsesDataReducer.name){
            const name = eclipsesDataReducer.name;
            const country = eclipsesDataReducer.country_name === 'United States' ?
                eclipsesDataReducer.country_name_abbr : eclipsesDataReducer.country_name;    
            const state = eclipsesDataReducer.country_name === 'United States' ? `${eclipsesDataReducer.state},` : ``;
            return `Next Solar and Lunar Eclipses in ${name}, ${state} ${country}`;
        }else return 'Next Solar and Lunar Eclipse Worldwide';
    }
    render () {
        return (
            <section className="citySunRiseSet">
                <div className="country_flag">
                    <img src={globe} className="globe" alt="globe" />
                </div>
                <div className="city_name_date">
                    <h4>{this.nextEclipse()}</h4>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        eclipsesDataReducer: state.eclipsesDataReducer
    };
};

export default connect(mapStateToProps)(CityEclipses);