import React, { Component } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import Api from "../../../services/api.js";

class Meta extends Component {
	state = {
		// opgPicture: null
	};

	// componentDidUpdate(prevProps) {
	// 	if (prevProps.moonCalcReducer !== this.props.moonCalcReducer)
	// 		this.opgPicture();
	// }

	metaCityName = nameType => {
		const { moonCalcReducer } = this.props;

		const urlCity = window.location.pathname.split("/")[4];
		const parsedUrlCity =
			urlCity.charAt(0).toUpperCase() +
			urlCity.slice(1).replace(/-/g, " ");

		const urlCountry = window.location.pathname.split("/")[2];
		const parsedUrlCountry =
			urlCountry.charAt(0).toUpperCase() +
			urlCountry.slice(1).replace(/-/g, " ");

		const name = moonCalcReducer.name
			? moonCalcReducer.name
			: parsedUrlCity;
		const country = moonCalcReducer.country_name
			? moonCalcReducer.country_name === "United States"
				? moonCalcReducer.country_name_abbr
				: moonCalcReducer.country_name
			: parsedUrlCountry === "usa"
			? "USA"
			: parsedUrlCountry;

		switch (nameType) {
			case "title":
				return ` ${name}, ${country}`;
			case "description":
				return ` ${name}, ${country} - `;
			case "keywords":
				return `${name}, `;
			default:
				return "";
		}
	};

	// async opgPicture() {
	// 	const { moonCalcReducer } = this.props;
	// 	if (moonCalcReducer.name) {
	// 		const newId = moonCalcReducer.url
	// 			.replace("place/", "")
	// 			.replace(/\//g, "__");
	// 		const opgPicture = await Api.userCityList(
	// 			`opg/moon/get-url/?geo_name=${newId}`
	// 		);
	// 		this.setState({ opgPicture: opgPicture.data.url });
	// 	}
	// }

	render() {
		return (
			<MetaTags>
				<title>
					Moonrise, moonset, moon culmination, moon phases and moon
					map for{this.metaCityName("title")}
				</title>

				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content={`Calculate moonrise, moonset, moon culmination, moon phase, next new moon, and next full moon for ${this.metaCityName(*/}
				{/*		"description"*/}
				{/*	)}. Online interactive map with moon location and get monthly moon data for${this.metaCityName(*/}
				{/*		"description"*/}
				{/*	)}.`}*/}
				{/*/>*/}

				{/*<meta*/}
				{/*	name="keywords"*/}
				{/*	content={`moonrise in ${this.metaCityName(*/}
				{/*		"keywords"*/}
				{/*	)}moonset in ${this.metaCityName(*/}
				{/*		"keywords"*/}
				{/*	)}next full moon, next new moon, lunar eclipse, lunar movement, moon phases, moon map, moon position, moon age, altitude, azimuth, moon calculator`}*/}
				{/*/>*/}

				{/*{this.state.opgPicture ? (*/}
				{/*	<meta*/}
				{/*		property="og:image"*/}
				{/*		content={`${Api.url}${this.state.opgPicture}`}*/}
				{/*	/>*/}
				{/*) : null}*/}
			</MetaTags>
		);
	}
}

const mapStateToProps = state => {
	return {
		moonCalcReducer: state.moonCalcReducer
	};
};

export default connect(mapStateToProps)(Meta);
