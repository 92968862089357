import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";

import Meta from "./Meta";
import Loader from "../../Main/Loader/Loader";
import Search from "../MainPage/Search/Search";
import HeaderPagePanchang from "./component/HeaderPagePanchang";
import CalendarPage from "./component/CalendarPage";
import Panel from "./component/Panel";
import Dashboard from "./component/Dashboard";
import ShowInfo from "./component/ShowInfo";
import Constellation from "./component/Constellation";
import Period from "./component/Period";
import Balam from "./component/Balam";
import Choghadiya from "./component/Choghadiya";

import currentSearch from "../../../actions/action_searchCity/action_currentSearch";
import Api from "../../../services/api.js";
import "./Panchang.css";

class Panchang extends Component {
  state = {
    panchang: {
      current_date: "",
    },
  };

  UNSAFE_componentWillMount() {
    this.getPanchangData();
  }

  async componentDidMount() {
    const { currentSearch } = this.props;
    const url = window.location.pathname.split("/").slice(2).join("__");
    const data = await Api.userCityList(`geo_names/${url}/`);
    currentSearch(data.data);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { currentSearchReducer } = nextProps;
    if (this.props.currentSearchReducer !== nextProps.currentSearchReducer) {
      this.setPanchangToState(currentSearchReducer.id);
    }
  }

  getPanchangData = async () => {
    const parsedUrl = window.location.pathname.split("/");
    const cityId = parsedUrl[parsedUrl.length - 1];
    const dateStart = moment().format("YYYY-MM-DD");
    const panchang = await Api.userCityList(
      `panchanga/${cityId}/full/${dateStart}`
    );
    if (panchang) {
      this.setState({ panchang: panchang.data });
    } else {
      this.setState({ panchang: { current_date: "" } });
    }
  };

  async setPanchangToState(id, date) {
    const dateStart = !date
      ? `?date=${moment().format("YYYY-MM-DD")}`
      : `?date=${date}`;
    const panchang = await Api.userCityList(
      `panchanga/${id}/full/${dateStart}`
    );
    if (panchang) this.setState({ panchang: panchang.data });
    else this.setState({ panchang: { current_date: "" } });
  }

  setDateFromChild(date) {
    const { currentSearchReducer } = this.props;
    this.setPanchangToState(
      currentSearchReducer.id,
      moment(date).format("YYYY-MM-DD")
    );
  }

  render() {
    const { currentSearchReducer } = this.props;
    if (!this.state.panchang.current_date || !currentSearchReducer.name) {
      return (
        <div className="planet-info">
          <Meta />
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="panchanga_page">
          <Meta />
          {/* <SunSocialIconsBlock/> */}
          <div className="serarch-block">
            <b>Find Panchang and more for</b>
            <Search />
          </div>
          <HeaderPagePanchang time={this.state.panchang.current_date} />
          <CalendarPage
            getDate={(e) => this.setDateFromChild(e)}
            panchang={this.state.panchang}
          />
          <div className="content">
            <div className="main">
              <Dashboard panchang={this.state.panchang} />
              {this.state.panchang.sun_all_day === "UP_DOWN" && (
                <div>
                  <ShowInfo />
                  <Constellation panchang={this.state.panchang} />
                  <Period panchang={this.state.panchang} />
                  <Balam panchang={this.state.panchang} />
                  <Choghadiya panchang={this.state.panchang} />
                </div>
              )}
            </div>
            <div className="panel">
              <Panel panchang={this.state.panchang} />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentSearchReducer: state.currentSearchReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    currentSearch: bindActionCreators(currentSearch, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Panchang);
