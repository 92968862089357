import React, { Component } from 'react';

import './Main.css';
import RouterComponent from '../../services/router';

class Main extends Component {
  render() {
    return (
      <div className='Main'>
        <RouterComponent />
      </div>
    );
  }
}

export default Main;
