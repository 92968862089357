import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone/index.js';

import './CityName.css';
import Loader from "../../../../components/Main/Loader/Loader";
import currentSearch from '../../../../actions/action_searchCity/action_currentSearch';


class CityName extends Component {
    emptyName(name){
        return name !== null ? `${name}, ` : '';
    }
    emptyState(state, abr){
        if(isNaN(+abr) && abr !== null) return `${abr}, `;
        else if(state !== null) return `${state}, `;
        else return '';
    }
    emptyAbr(abr, name){
        if(name === 'United States' && abr !== null) return abr;
        else if(name !== null) return name;
        else return '';
    }
    render () {
        const { currentSearchReducer, planetaryHoursReducer, planetaryStyle } = this.props;
		if(!currentSearchReducer.time){
			return (
				<div className="sunCulcCityInfoCenter"><Loader/></div>
			)
        }
        return (
            <section className="citySunRiseSet" style={planetaryStyle}>
                <div className="country_flag">
                    <img className="cityMainImg" src={`/static/flags/flags-iso/flat/64/${currentSearchReducer.country_code}.png`} alt="citydateandtime" />
                </div>
                <div className="city_name_date">
                    <h3>
                        Planetary Hours for {this.emptyName(currentSearchReducer.name)}
                        {this.emptyState(currentSearchReducer.state, currentSearchReducer.state_code)}
                        {this.emptyAbr(currentSearchReducer.country_name_abbr, currentSearchReducer.country_name)} for&nbsp;
                        {moment(planetaryHoursReducer).format('dddd, MMM D, YYYY')}
                    </h3>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        planetaryHoursReducer: state.planetaryHoursReducer
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityName);