const searchStockCityInfoReducer = (state = null, action) => {
  switch (action.type) {
    case "ADD_STOCK_CITY_INFO":
      return action.data;
    default:
      return state;
  }
};

export default searchStockCityInfoReducer;
