import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './CreateButton.css';
import {bindActionCreators} from "redux";
import currentSearch from "../../../../../../actions/action_searchCity/action_currentSearch";

class CreateButton extends Component {

    state = {
        calculationError: { display: 'none' },
        url: '/countdown-timer'
    };
//created Timer
    createCountdown = () =>{
        const { countdownReducer } = this.props;
        let design_name = countdownReducer.design_name;
        let title_name = countdownReducer.title.name_event;
        let title_style = countdownReducer.title.style;
        let location_event = countdownReducer.location_event;
        let timer_format = countdownReducer.timer_format;
        let date_event = countdownReducer.date_event;
        if (design_name && title_name && title_style && location_event && timer_format && date_event){
            //console.log(`almost ready ${design_name}, ${title_name}, ${title_style}, ${date_event}, ${location_event}, ${timer_format}, `)
        }else  {
            //console.log(`date undefined`);
        }
    };
    getURL(){
        const { countdownReducer } = this.props;
        let design_name = countdownReducer.design_name;
        let title_name = countdownReducer.title.name_event;
        let title_style = countdownReducer.title.style;
        let location_event = countdownReducer.location_event;
        let timer_format = countdownReducer.timer_format;
        let date_event = countdownReducer.date_event;
        if (design_name && title_name && title_style && location_event && timer_format && date_event){
            return '/created-timer';
        }else  {
            return '/countdown-timer';
        }
    }

    render() {
        //console.log('CreateButton', this.props.countdownReducer);
        //console.log(this.state.url, );
        return (
            <div>
                <Link to={this.getURL()}>
                    <button className='create-countdown-button' onClick={this.createCountdown}>
                        CREATE COUNTDOWN
                    </button>
                </Link>
                <b style={this.state.calculationError}>Fill in all the fields</b>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        currentSearchReducer: state.currentSearchReducer,
        countdownReducer: state.countdownReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        currentSearch: bindActionCreators(currentSearch, dispatch)
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateButton);
