import React, { Component } from 'react';

import './CityName.css';

class CityName extends Component {
    render () {
        return (
            <div className="citySunRiseSet">{this.props.children}</div>
        )
    }
}

export default CityName;